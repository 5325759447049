import {
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import {
  getOffencePercentage,
  getWarningGradient,
} from "features/ITO/Assessments/helpers"
import React, { useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import Webcam from "react-webcam"

import shepherdClass from "../shepherdClasses"
import OffenceCard from "./OffenceCard"
// import { GA } from "shared/utils/googleAnalytics"

type Props = {
  videoRef: React.RefObject<Webcam>
  totalWarnings: number
  maxWarningCount: number
}

const useStyles = createUseStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.colors.onSurface[800],
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  white: {
    color: theme.colors.common.white[500],
  },
  midnight: {
    color: theme.colors.onSurface[800],
  },
  cameraOn: {
    height: 22,
    width: 50,
    borderRadius: 33,
    backgroundColor: theme.colors.common.white[500],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  greenDot: {
    animation: `blink 2s infinite`,
    height: 12,
    width: 12,
    backgroundColor: theme.colors.success[400],
    borderRadius: "50%",
  },
  barWidth: {
    width: "15vw",
  },
  divider: {
    backgroundColor: theme.colors.onSurface[200],
    border: `1px solid ${theme.colors.onSurface[200]} `,
    borderRadius: 2,
    height: "100%",
  },
  animation: {
    transition: "height 0.2s ease",
    height: "190px",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "160px",
    },
  },
  shrink: {
    height: "46px",
  },
}))

const OffenceBar = ({ totalWarnings, videoRef, maxWarningCount }: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const [openDrawer, toggleOpenDrawer] = useState(false)
  const offensePercentage = getOffencePercentage(totalWarnings, maxWarningCount)

  const cameraOn = (
    <div
      className={clsx(
        classes.white,
        shepherdClass["cameraContainer"],
        "grid items-center gap-0.75 grid-flow-col"
      )}
    >
      <Typography variant="strongSmallBody">Camera</Typography>
      <span className={clsx(classes.cameraOn, classes.midnight)}>
        <Typography variant="strongSmallBody">On</Typography>
        <span className={clsx(classes.greenDot, "ml-0.5")} />
      </span>
    </div>
  )

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        "flex flex-col mt-0.75",
        classes.animation,
        classes.wrapper,
        {
          "justify-center": openDrawer,
          [classes.shrink]: !openDrawer,
        }
      )}
      onClick={() => toggleOpenDrawer(prev => !prev)}
      onBlur={() => toggleOpenDrawer(false)}
      role="button"
      tabIndex={0}
    >
      <div
        className={clsx(
          "grid grid-flow-col gap-1 items-center justify-between"
        )}
      >
        {!openDrawer && (
          <>
            {cameraOn}
            {isXs && <div className={classes.divider} />}
            <div
              className={clsx(
                classes.white,
                shepherdClass["offenseBar"],
                "grid grid-flow-col items-center gap-2"
              )}
            >
              <Typography variant="strongSmallBody">
                {totalWarnings}/{maxWarningCount} violations triggered
              </Typography>

              {!isXs && (
                <LinearProgress
                  color="warning"
                  value={offensePercentage}
                  className={classes.barWidth}
                  linearGradient={getWarningGradient(offensePercentage)}
                />
              )}
            </div>
          </>
        )}
      </div>

      <div
        style={{
          height: openDrawer ? "auto" : "1px !important",
          visibility: openDrawer ? "visible" : "hidden",
        }}
      >
        <OffenceCard
          maxWarningCount={maxWarningCount}
          videoRef={videoRef}
          totalWarnings={totalWarnings}
        />
      </div>
    </div>
  )
}

export default React.memo(OffenceBar)
