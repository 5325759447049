import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  theme,
  toast,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Project } from "api/resources/profile/types"
import LoadingOverlay from "components/LoadingOverlay"
import RemoveDialog from "features/Profile/components/RemoveDialog"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"

type Props = {
  handleClose: () => void
  id?: string
} & Omit<DialogProps, "data" | "id">

const ProjectDialog = ({ id, open, handleClose }: Props) => {
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const {
    achievements: {
      projects: { remove, add, update },
    },
  } = useContext(ProfileContext)

  const [data, setData] = useState<Project>()
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [removeDialogLoading, setRemoveDialogLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<Project>()

  const startDate = watch("startDate")
  const endDate = watch("endDate")

  useEffect(() => {
    clearErrors(["startDate", "endDate"])
  }, [startDate, endDate, clearErrors])

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.projects.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)
        reset(res.data)
      }
    }
    if (id) fetchData()
  }, [id, reset])

  const toggleRemoveDialog = () => {
    setRemoveDialogOpen(!removeDialogOpen)
  }

  const currentlyWorking = watch("currentlyWorking")

  const onSubmit = handleSubmit(
    async ({
      title,
      currentlyWorking: cw,
      startDate: startDateFormData,
      endDate: endDateFormData,
      url,
      description,
    }) => {
      const apiData = {
        title,
        currentlyWorking: cw,
        startDate: startDateFormData,
        endDate: cw ? null : endDateFormData,
        url: url || null,
        description: description || null,
      }

      if (id) {
        const res = await api.profile.projects.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          update(id, res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.projects.create({ data: apiData })
        if (res.isSuccessful) {
          add(res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    setRemoveDialogLoading(true)
    if (id) {
      const res = await api.profile.projects.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        remove(id)
        toast.success("Removed successfully.")
        toggleRemoveDialog()
        handleClose()
      }
    }
  }
  // TODO: placebo-issue checkbox doesn't get reset with reset()
  const CurrentlyWorking = () => (
    <Checkbox
      label="I am currently working on this project"
      {...register("currentlyWorking")}
    />
  )

  return (
    <>
      <Dialog
        fullScreen={isXs}
        open={open}
        width="md"
        onAfterClose={reset}
        onRequestClose={handleClose}
      >
        {id && !data && <LoadingOverlay />}

        <DialogTitle>{id ? "Edit" : "Add New"} Project</DialogTitle>

        <DialogContent>
          <form className="flex flex-col gap-3" onSubmit={onSubmit}>
            <TextField
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
              inputLabelProps={{ required: true }}
              label="Title"
              placeholder="Ex: Light detection nears its quantum limit"
              fullWidth
              charLimit={300}
              {...register("title", {
                required: { value: true, message: "Required" },
                maxLength: {
                  value: 300,
                  message: "Try to keep it short",
                },
              })}
            />

            <CurrentlyWorking />
            <div className="flex gap-3 flex-col sm:flex-row">
              <TextField
                error={Boolean(errors.startDate)}
                helperText={errors.startDate?.message}
                inputLabelProps={{ required: true }}
                label="Start Date"
                placeholder="Ex: 21 Jan 2021"
                type="date"
                fullWidth
                {...register("startDate", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                disabled={currentlyWorking}
                error={Boolean(errors.endDate)}
                helperText={errors.endDate?.message}
                inputLabelProps={{ required: !currentlyWorking }}
                label="End Date"
                placeholder="Ex: 21 Jan 2021"
                type="date"
                fullWidth
                {...register("endDate", {
                  required: { value: !currentlyWorking, message: "Required" },
                })}
              />
            </div>

            <TextField
              error={Boolean(errors.url)}
              helperText={errors.url?.message}
              label="Project Url"
              placeholder="Ex:"
              fullWidth
              {...register("url")}
            />
            {/* @ts-expect-error random-types-issue */}
            <TextField
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              label="Description"
              maxRows={5}
              placeholder="Talk about your role and experience etc..."
              rows={5}
              fullWidth
              multiLine
              charLimit={300}
              {...register("description", {
                maxLength: {
                  value: 300,
                  message: "Try to keep it short",
                },
              })}
            />
          </form>
        </DialogContent>

        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              loading: isSubmitting,
              color: "primary",
              onClick: onSubmit,
            },
            tertiary: data && {
              label: "Remove",
              variant: "text",
              type: "button",
              color: "critical",
              onClick: toggleRemoveDialog,
            },
          }}
        />
      </Dialog>

      {data && (
        <RemoveDialog
          handleClose={() => toggleRemoveDialog()}
          loading={removeDialogLoading}
          open={removeDialogOpen}
          title="Remove Project"
          onRemove={handleRemove}
        >
          <Typography variant="smallBody">
            Are you sure you want to remove&nbsp;
            <Typography display="inline" variant="strongSmallBody">
              <strong>{data.title}</strong>
            </Typography>
            &nbsp;from your profile?
          </Typography>
        </RemoveDialog>
      )}
    </>
  )
}

export default ProjectDialog
