import { MaintenanceBanner } from "@suraasa/error-pages"
import ApplicationStatusBanner from "features/GlobalBanners/ApplicationStatusBanner"
import UnverifiedEmailBanner from "features/Settings/UnverifiedEmailBanner"
import { db } from "firebaseInit"
import React from "react"
import { USER_TYPE } from "utils/constants"

const GlobalBanners = () => {
  return (
    <div id="globalTopBars">
      <MaintenanceBanner firebaseDb={db} platform={USER_TYPE} />
      <UnverifiedEmailBanner />
      <ApplicationStatusBanner />
    </div>
  )
}

export default GlobalBanners
