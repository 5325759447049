import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import styles from "./DomainReports.module.css"

const NotYetReady = () => {
  return (
    <div
      className={clsx(
        "px-2 sm:px-4 py-3 rounded-xl",
        styles.notYetReadyContainer
      )}
    >
      <div className="z-[1] relative">
        <Typography
          variant="title2"
          textAlign="center"
          className={styles.notYetReadyTitle}
        >
          Your personalised reports are arriving here soon!
        </Typography>
      </div>

      <div className="bg-gradient-to-t from-[#FCFCFC] from-30% to-[#ffffff00] to-100% w-full h-full absolute top-0 left-0" />
    </div>
  )
}

export default NotYetReady
