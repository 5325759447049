import { LinearProgress, Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import RecordIcon from "features/ITO/Assessments/assets/record-icon.svg"
import {
  getOffencePercentage,
  getWarningGradient,
} from "features/ITO/Assessments/helpers"
import React from "react"
import { createUseStyles } from "react-jss"
import Webcam from "react-webcam"

const useStyles = createUseStyles((theme: Theme) => ({
  videoFrame: {
    transform: "rotateY(180deg)",
    webkitTransform: "rotateY(180deg)",
    mozTransform: "rotateY(180deg)",
    borderRadius: "8px",
    maxWidth: "225px",
    maxHeight: "150px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "155px",
      maxHeight: "130px",
    },
  },
  recordIconContainer: {
    position: "absolute",
    top: "12px",
    right: "16px",
    animation: "blink 2s linear infinite",
    [theme.breakpoints.down("xs")]: {
      top: "8px",
      right: "8px",
    },
  },
  onSurface: {
    color: theme.colors.onSurface[600],
  },
  videoBox: {
    maxHeight: "151px",
    position: "relative",
  },
  wrapper: {
    gridAutoColumns: "1fr 4fr",
    backgroundColor: theme.colors.onSurface[800],
  },
  white: {
    color: theme.colors.common.white[500],
  },
  cameraOn: {
    height: 22,
    width: 40,
    borderRadius: 33,
    backgroundColor: theme.colors.success[100],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

type Props = {
  videoRef: React.RefObject<Webcam>
  totalWarnings: number
  maxWarningCount: number
}

const OffenceCard = ({ videoRef, totalWarnings, maxWarningCount }: Props) => {
  const classes = useStyles()

  const offensePercentage = getOffencePercentage(totalWarnings, maxWarningCount)

  return (
    <div
      className={clsx("grid grid-flow-col gap-1.25 xs:gap-3", classes.wrapper)}
    >
      <div className={classes.videoBox}>
        <Webcam
          ref={videoRef}
          className={classes.videoFrame}
          videoConstraints={{
            facingMode: "user",
          }}
        />
        <img
          src={RecordIcon}
          alt="recordIcon"
          className={classes.recordIconContainer}
        />
      </div>

      <div className={clsx("flex flex-col justify-between", classes.white)}>
        <div className="flex flex-col gap-1">
          <Typography variant="preTitle">Offence count</Typography>
          <LinearProgress
            color="warning"
            value={offensePercentage}
            linearGradient={getWarningGradient(offensePercentage)}
          />
          <div className="gap-0.25">
            <Typography variant="strongSmallBody">
              {totalWarnings} out of {maxWarningCount} times{" "}
            </Typography>
            <Typography variant="smallBody">triggered</Typography>
          </div>
        </div>
        <div
          className={clsx(classes.white, "flex-end flex items-center gap-0.75")}
        >
          <Typography variant="strongSmallBody">Camera</Typography>
          <span className={classes.cameraOn}>
            <Typography variant="strongSmallBody" color="success.800">
              On
            </Typography>
          </span>
        </div>
      </div>
    </div>
  )
}

export default OffenceCard
