import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { ElementType } from "react"
import { useTheme } from "react-jss"

export type ActionSlotProps = {
  // component determines how that itemRow is supposed to act ie Link, a
  component?: ElementType
  // Specifies the internal url for navigation. Required when component={Link}
  to?: string
  // Specifies the external url for navigation. Required when component="a"
  href?: string
  // Js Handler to give any kind of functionality to itemRow
  onClick?: () => void
  // To avoid spamming onClick on ItemRow.
  loading?: boolean
}

export type Props = {
  // class applied to the root element
  className?: string
  // Gets multiplied by `8`. Default `1`.
  padding?: 0 | 1 | 2 | 3 | 4
  // Pass `0` to remove border. Default `1`.
  border?: 0 | 1 | 2 | 3 | 4
  // Slot for the start content. Automatically aligned with the rest of the content using a space-between layout.
  infoSlot?: React.ReactNode
  // Slot for the end content. Automatically aligned with the rest of the content using a space-between layout.
  actionSlot?: JSX.Element | ((props: ActionSlotProps) => JSX.Element | null)
  // Custom child. No pre-defined layout
  children?: React.ReactNode
} & ActionSlotProps

const Card = ({
  infoSlot,
  actionSlot,
  children,
  className,
  padding = 1,
  border = 1,
  component: Component = "div",
  to,
  href,
  onClick,
  loading,
}: Props) => {
  if (children == null && actionSlot == null && infoSlot == null) {
    throw new Error(
      "Supply at least one of `infoSlot`, `actionSlot` or `children`."
    )
  }

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Component
      to={to}
      href={href}
      onClick={onClick}
      className={clsx(className, "rounded-2xl", {
        "flex items-center justify-between": !children && !isXs,
        "flex flex-col": !children && isXs,
        "p-0": padding === 0,
        "p-1": padding === 1,
        "p-2": padding === 2,
        "p-3": padding === 3,
        "p-4": padding === 4,
        "border border-surface-200": border === 1,
        "cursor-wait": loading,
        "cursor-pointer": !!onClick && !loading,
      })}
    >
      {children ? (
        children
      ) : (
        <>
          {infoSlot && infoSlot}
          {actionSlot && typeof actionSlot === "function"
            ? actionSlot({ component: Component, to, onClick, href, loading })
            : actionSlot}
        </>
      )}
    </Component>
  )
}

export default Card
