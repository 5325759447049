import {
  CircularProgress,
  Container,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Breadcrumbs from "components/Breadcrumbs"
import Instructions from "features/Assignments/Instructions"
import PreviousSubmissionsDropdown from "features/Assignments/PreviousSubmissionsDropdown"
import { buildAssignmentTitle } from "features/Assignments/utils/buildAssignmentTitle"
import VerificationProcess from "features/Assignments/VerificationProcess"
import { InfoCircle } from "iconoir-react"
import React, { useState } from "react"
import { useParams } from "react-router-dom"

import MarkingCriteriaSheet from "../Assignments/Instructions/MarkingCriteriaSheet"
import ProfessorReviewCard from "./ProfessorReviewCard"
import Submission from "./Submission"

const AssignmentResult = () => {
  const { assignmentId, submissionId } = useParams() as {
    submissionId: string
    assignmentId: string
  }

  const assignmentResponse = useQuery({
    queryFn: () =>
      api.assignments.retrieve({
        urlParams: { assignmentId: assignmentId },
      }),
    queryKey: queries.assignments.byUUID(assignmentId).queryKey,
  })

  const submissionResponse = useQuery({
    queryFn: () =>
      api.assignments.retrieveSubmission({
        urlParams: { submissionId: submissionId },
        params: { fields: "iqa_review" },
      }),
    queryKey: queries.assignments.submissionsByUUID(submissionId).queryKey,
  })

  const [showMarkingCriteria, setShowMarkingCriteria] = useState(false)
  const [showVerificationProcess, setShowVerificationProcess] = useState(false)

  if (assignmentResponse.isSuccess)
    return (
      <div>
        <Container>
          <Breadcrumbs className="mt-3 mb-3" skipLastItem={false} />

          <MarkingCriteriaSheet
            assignmentId={assignmentId}
            open={showMarkingCriteria}
            onClose={() => setShowMarkingCriteria(false)}
          />

          <div className="flex gap-2.5 mb-2 flex-wrap justify-between items-center">
            <Typography variant="title3">
              {buildAssignmentTitle(assignmentResponse.data)}
            </Typography>
            <div>
              <PreviousSubmissionsDropdown
                assignmentId={assignmentId}
                currentSubmissionId={submissionId}
              />
            </div>
          </div>

          {submissionResponse.isSuccess && (
            <ProfessorReviewCard
              review={submissionResponse.data.review}
              iqaReview={submissionResponse.data.iqaReview}
            />
          )}

          {submissionResponse.isSuccess && (
            <div className="my-2 sm:mt-1.5 flex flex-col gap-2">
              {submissionResponse.data.iqaReview && (
                <div className="flex justify-between gap-2 flex-wrap">
                  <div className="flex gap-0.5 items-center">
                    <Typography color="onSurface.600" variant="smallBody">
                      Result updated after Quality Assurance
                    </Typography>
                    {!showVerificationProcess && (
                      <Tooltip
                        maxWidth="300px"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore placebo-fix
                        title={
                          <div className="text-start">
                            We perform quality assurance checks to ensure
                            authenticity, validity, and fairness in evaluation.{" "}
                            <button
                              className="underline underline-offset-4"
                              onClick={() => setShowVerificationProcess(true)}
                            >
                              Learn More
                            </button>
                            .
                          </div>
                        }
                      >
                        <InfoCircle
                          className="text-onSurface-600"
                          fontSize={15}
                        />
                      </Tooltip>
                    )}
                  </div>
                  {/* <Button variant="text" nudge="left" className="!-ml-1">
                  Compare with Previous Review
                </Button> */}
                </div>
              )}
            </div>
          )}

          <div className="mb-3">
            <Typography variant="title3" className="mb-2">
              Your Submission
            </Typography>
            {submissionResponse.isSuccess && (
              <Submission
                references={submissionResponse.data.references}
                content={submissionResponse.data.submission}
                attachments={submissionResponse.data.attachments}
              />
            )}
            {submissionResponse.isLoading && (
              <div className="flex justify-center p-3">
                <CircularProgress />
              </div>
            )}
            {submissionResponse.isError && (
              <Submission
                content={"Some error occurred while loading submission"}
                attachments={[]}
              />
            )}
          </div>

          <Instructions {...assignmentResponse.data} />
        </Container>
        <VerificationProcess
          open={showVerificationProcess}
          onClose={() => setShowVerificationProcess(false)}
        />
      </div>
    )

  return (
    <Container>
      <div className="flex items-center justify-center p-4">
        <CircularProgress />
      </div>
    </Container>
  )
}

export default AssignmentResult
