import { Chip, Divider, IconButton, Typography } from "@suraasa/placebo-ui"
import { EmploymentType, WorkExperienceType } from "api/resources/profile/types"
import TruncatedText from "components/TruncatedText"
import { format } from "date-fns"
import Section from "features/Profile/components/Section"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import { Edit, Plus } from "iconoir-react"
import capitalize from "lodash/capitalize"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"

import ProfileContext from "../../context"
import SectionContent from "../Section/SectionContent"
import EditDialog from "./EditDialog"

const getEmploymentTypeDisplay = (value: EmploymentType | null) => {
  if (!value) return null
  return capitalize(EmploymentType[value].replace("_", " "))
}

const useStyles = createUseStyles(theme => ({
  details: {
    position: "relative",

    "& .edit-button": {
      position: "absolute",
      zIndex: "1",
      background: "white",

      [theme.breakpoints.down("sm")]: {
        right: "0px",
      },
      [theme.breakpoints.up("md")]: {
        pointerEvents: "none",
        right: "-30px",
        opacity: "0",
        transition: ".2s all",
      },
    },
    "&:hover .edit-button": {
      [theme.breakpoints.up("md")]: {
        opacity: "1",
        right: "0px",
        pointerEvents: "all",
      },
    },
  },
}))

const WorkExperience = ({ onUpdate }: { onUpdate: () => void }) => {
  const classes = useStyles()

  const [openDialog, toggle] = useState(false)

  const { workExperiences, isPublic } = useContext(ProfileContext)

  const [workExperienceToEdit, setWorkExperienceToEdit] = useState<
    WorkExperienceType["id"] | null
  >(null)

  useEffect(() => {
    // Clear workExperienceToEdit when dialog is closed
    if (workExperienceToEdit && !openDialog) {
      setWorkExperienceToEdit(null)
    }
  }, [workExperienceToEdit, openDialog])

  const handleEditWorkExperience = (id: WorkExperienceType["id"]) => {
    setWorkExperienceToEdit(id)
    toggle(true)
  }

  return (
    <>
      {openDialog && (
        <EditDialog
          id={workExperienceToEdit}
          open={openDialog}
          handleClose={() => toggle(false)}
          onUpdate={onUpdate}
        />
      )}

      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={() => toggle(true)}>
                    <Plus />
                  </IconButton>
                )
              }
              title="Work Experience"
            />
          }
          xPadding={3}
        />

        <SectionContent className="flex flex-col" xPadding={3}>
          {workExperiences.data.map((experience, index) => (
            <div className={classes.details} key={experience.id}>
              {!isPublic && (
                <IconButton
                  className="edit-button"
                  color="black"
                  onClick={() => handleEditWorkExperience(experience.id)}
                >
                  <Edit />
                </IconButton>
              )}
              <div>
                <span className="flex items-center gap-1 mb-0.5">
                  <Typography variant="strongSmallBody">
                    {experience.title}
                  </Typography>
                  {experience.curriculum && (
                    <Chip
                      color="secondary"
                      label={experience.curriculum.name}
                      size="sm"
                      variant="outlined"
                    />
                  )}
                </span>

                <Typography
                  className="mb-0.25"
                  color="onSurface.600"
                  variant="smallBody"
                >
                  {[
                    getEmploymentTypeDisplay(experience.employmentType),
                    experience.teachingLevel?.name,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </Typography>

                <Typography
                  className="mb-0.25"
                  color="onSurface.600"
                  variant="smallBody"
                >
                  {[
                    experience.organisationName,
                    [experience.state?.name, experience.country?.name]
                      .filter(Boolean)
                      .join(", "),
                  ]
                    .filter(Boolean)
                    .join(" • ")}
                </Typography>

                {experience.startDate && (
                  <Typography color="onSurface.600" variant="smallBody">
                    {format(new Date(experience.startDate), "MMMM yyyy")} -{" "}
                    {experience.currentlyWorking
                      ? "Present"
                      : experience.endDate &&
                        format(new Date(experience.endDate), "MMMM yyyy")}
                  </Typography>
                )}

                {experience.description && (
                  <TruncatedText
                    className="mt-1"
                    maxLength={300}
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                    variant="smallBody"
                  >
                    {experience.description}
                  </TruncatedText>
                )}
              </div>

              {index < workExperiences.data.length - 1 && (
                <Divider
                  className="my-2.25"
                  color="onSurface.200"
                  weight="light"
                />
              )}
            </div>
          ))}

          {workExperiences.data.length === 0 && (
            <Typography color="onSurface.400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any work experiences. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default WorkExperience
