import { create } from "jss"
import rtl from "jss-rtl"
import { JssProvider } from "react-jss"

import jssPreset from "./jssPreset"
import { root } from "./root"

export function renderWithRTL(app: any, enableRtl: boolean) {
  const jss = create({
    plugins: [...jssPreset().plugins, rtl({ enabled: enableRtl })],
  })
  root.render(<JssProvider jss={jss}>{app}</JssProvider>)
}
