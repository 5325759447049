import {
  Container,
  Divider,
  Tag,
  Theme,
  toast,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/orders/queries"
import { ClientType } from "api/resources/orders/types"
import Monitor from "assets/Fallback/monitor.svg"
import BackButton from "components/BackButton"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import { format } from "date-fns"
import React from "react"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"

import { getStatusTag } from "../utils/helpers"
import AppPurchase from "./AppPurchase"
import OrderBreakdown from "./OrderBreakdown"
import Payments from "./Payments"

const OrderDetails = () => {
  const { id } = useParams()

  // TODO(annie): revert when needed
  // const [requestInvoice, setRequestInvoice] = useState({
  //   enable: false,
  //   transactionId: "",
  // })

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const {
    isLoading,
    data: orderItem,
    isError,
  } = useQuery({
    queryFn: () => api.orders.getOrderDetails({ urlParams: { id: id ?? "" } }),
    queryKey: queries.orderItem(id ?? "").queryKey,
    onError: () => {
      toast.error("Unable to fetch order details.")
    },
  })

  if (isLoading) return <FullPageLoading />
  if (isError)
    return (
      <Fallback
        data={{
          image: Monitor,
          title: "Something went wrong.",
          description: "Please contact care@suraasa.com",
        }}
        className="md:my-8 sm:my-20 my-6"
      />
    )

  const isAppPurchase =
    orderItem.client === ClientType.ANDROID ||
    orderItem.client === ClientType.IOS

  const statusTag = getStatusTag(orderItem.status)
  return (
    <>
      <Container fullWidth={isXs}>
        <div className="grid lg:grid-cols-12 mt-4">
          <BackButton className="lg:col-span-2 self-start" />
          <div className="lg:col-span-8 lg:mt-0 mt-3">
            <div className="flex sm:flex-row flex-col sm:items-center justify-between bg-surface-50 sm:border border-y border-surface-100 sm:p-3 p-2 sm:rounded-2xl">
              <div>
                <span className="flex items-center">
                  <Typography
                    variant={isMdUp ? "title3" : "title4"}
                    className="me-1"
                  >
                    Order ID - #{orderItem.serialNumber}
                  </Typography>
                  <Tag color={statusTag.color} label={statusTag.label} />
                </span>

                <Typography
                  variant="smallBody"
                  color="onSurface.500"
                  className="mt-0.75"
                >
                  Ordered on{" "}
                  {format(new Date(orderItem.createdAt), "dd MMM yyyy")}
                </Typography>
              </div>
              {/* <Button
                variant="text"
                className="sm:mt-0 mt-1"
                onClick={() =>
                  setRequestInvoice({
                    enable: true,
                    transactionId: orderItem.id,
                  })
                }
              >
                Request Invoice
              </Button> */}
            </div>
            {isAppPurchase ? (
              <AppPurchase
                items={orderItem.items}
                currency={orderItem.currency}
              />
            ) : (
              <>
                <OrderBreakdown
                  finalAmount={orderItem.finalAmount}
                  discountAmount={orderItem.discountAmount}
                  items={orderItem.items}
                  currency={orderItem.currency}
                />
                <Divider
                  weight="light"
                  color="onSurface.200"
                  className="my-3"
                />
                <Payments transactions={orderItem.transactions} />
              </>
            )}
          </div>
        </div>
      </Container>
      {/* TODO(annie) revert when feature available Request Invoice */}
      {/* <RequestInvoice
        reset={() => setRequestInvoice({ enable: false, transactionId: "" })}
        invoiceDetails={requestInvoice}
      /> */}
    </>
  )
}

export default OrderDetails
