import { Container, Typography } from "@suraasa/placebo-ui"
import ReportsDesktop from "assets/Ito/BeforeResult/PedagogyReport/reports-desktop.webp"
import { ArrowRight } from "iconoir-react"
import React from "react"
import { useTimer } from "react-timer-hook"
import { pluralize } from "utils/helpers"

const PedagogyReports = ({
  resultDate,
  gotoAwardsAndPrize,
}: {
  resultDate: Date
  gotoAwardsAndPrize: () => void
}) => {
  const { days, hours, minutes } = useTimer({
    expiryTimestamp: resultDate,
    autoStart: false,
  })

  return (
    <div className="bg-ito-peach-900">
      <Container>
        <div className="md:py-2 flex flex-col md:flex-row gap-2 justify-between sm:items-center items-start pb-0 pt-3 sm:text-center md:text-start">
          <div>
            <Typography variant="title1" className="!text-ito-peach-500 mb-3">
              Your Pedagogy Reports are coming shortly!
            </Typography>
            <button
              onClick={gotoAwardsAndPrize}
              className="flex py-1.5 px-3 rounded-full bg-black/25 sm:mx-auto md:mx-0 mx-0"
            >
              <span className="text-[0.9375rem] font-medium leading-5 text-white">
                Look at all the rewards awaiting you
              </span>
              <ArrowRight
                className="ml-1 shrink-0 text-white"
                width={20}
                height={20}
              />
            </button>
          </div>
          <div className="shrink-0 h-[180px] sm:h-[224px] relative w-full md:w-[auto] overflow-hidden">
            <img
              src={ReportsDesktop}
              className="h-[220px] md:h-full w-full absolute md:static -bottom-1/4 object-contain"
              alt="reports"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PedagogyReports
