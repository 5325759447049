function getIndexesOfSubString(originalString: string, subString: string) {
  /*
   * The main logic of this function is to grab start indexes of the sub strings
   * from the bigger string (originalString) by iterating over the original string
   * and finding the index of substring one by one.
   * Finding sub string is done by slicing the string every time
   * We start by finding the first sub string then slicing the string till (foundIndex + subString.length)
   * and continuing the same
   */
  let currentIndex = 0 // for iterating over the string
  const stringIndexes = [0] // for capturing indexes of blanks on string

  // infinite loop for finding all the sub strings
  for (;;) {
    const index = originalString.indexOf(subString, currentIndex) // find the sub string in the original string from set current index
    if (index === -1) break // if there is no sub string found break. Assuming at the end there will be none
    stringIndexes.push(index) // push the index if any found
    currentIndex = index + subString.length // update the current index with the (foundIndex + subString.length)
  }
  // pushing the length of original string in order to generate full string later
  stringIndexes.push(originalString.length)
  return stringIndexes
}

export function getStringAsSubString(
  originalString: string,
  separator: string
) {
  /*
   * This functions gets the sub strings start indexes
   * and then combines them into a string array
   */

  // indexes of substrings with starting and ending positions of the original string
  const indexes = getIndexesOfSubString(originalString, separator)
  const subStrings = []
  // first iteration of joining the string
  const subString: string | string[] = originalString.substring(
    indexes[0],
    indexes[1]
  ) // from 0 to first found index
  // if the first index is not empty then only append the string else empty string will be added to array
  if (subString && subString.length >= 1) subStrings.push(subString)
  // push the first sub string or separator
  subStrings.push(separator)

  // loop for pushing the rest of the string slices
  // first iteration is from 1st index
  for (let i = 1; i < indexes.length - 1; i++) {
    // adding the separator length in order to escape the sub string length in the original string
    subStrings.push(
      originalString.substring(indexes[i] + separator.length, indexes[i + 1])
    )
    // this condition helps to refrain from adding separator at the end as i < 5
    // will be rendered false when i == 5 but we don't want to add separator at i = 4
    if (i + 1 !== indexes.length - 1) {
      subStrings.push(separator)
    }
  }
  return subStrings
}

export function convertSubStringArrayToObjects(
  subStrings: any,
  specialString: string,
  replaceValue: string | null = null
) {
  /*
   *
   * This function converts sub string array with separator values to js objects
   * replaceValue: in case we want to replace the special string value with some other value
   * returns: sub string objects
   *
   */
  let position = 0 // this denotes blank position and is only added when special string is found
  for (let i = 0; i < subStrings.length; i++) {
    // only special string will be treated as objects everything else is a string
    // if array string is same as special string then we check for replace value
    if (subStrings[i] === specialString) {
      subStrings[i] = {
        position,
        text: null,
        value: replaceValue ? replaceValue : specialString,
      }
      position++
    }
  }
  return subStrings
}
