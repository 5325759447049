import React from "react"

const UserCircle = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3412_54371)">
        <path
          d="M4.57217 16.4822C5.07911 15.2879 6.2627 14.4502 7.64193 14.4502H12.6419C14.0212 14.4502 15.2047 15.2879 15.7117 16.4822M13.4753 8.2002C13.4753 10.0411 11.9829 11.5335 10.1419 11.5335C8.30098 11.5335 6.80859 10.0411 6.80859 8.2002C6.80859 6.35925 8.30098 4.86686 10.1419 4.86686C11.9829 4.86686 13.4753 6.35925 13.4753 8.2002ZM18.4753 10.2835C18.4753 14.8859 14.7443 18.6169 10.1419 18.6169C5.53955 18.6169 1.80859 14.8859 1.80859 10.2835C1.80859 5.68116 5.53955 1.9502 10.1419 1.9502C14.7443 1.9502 18.4753 5.68116 18.4753 10.2835Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3412_54371">
          <rect
            width="1.5em"
            height="1.5em"
            fill="currentColor"
            transform="translate(0.142578 0.283203)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UserCircle
