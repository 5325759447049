import { Button, Typography } from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  AssignmentList,
  SUBMISSION_STATUS,
} from "api/resources/assignments/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import { buildAssignmentTitle } from "features/Assignments/utils/buildAssignmentTitle"
import { Lock } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import { formatDate } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import { PropsWithClassName } from "utils/types"

import { useCourseDetails } from ".."
import styles from "./Assignments.module.css"
import { getTags } from "./tags"

const getDueDate = (item: AssignmentList) => {
  if (!item.dueDate) return null

  const date = (
    <Typography variant="smallBody" color="onSurface.500">
      Due by {formatDate(item.dueDate, "d LLL y")}
    </Typography>
  )

  // Show due date only in case of due, overdue, saved_for_later and rejected
  if (
    !item.submissionStatus ||
    item.submissionStatus === SUBMISSION_STATUS.rejected ||
    item.submissionStatus === SUBMISSION_STATUS.savedForLater
  ) {
    return date
  }

  return null
}

const getButtonAction = (
  item: AssignmentList,
  extras: { slug?: string; parentSlug?: string }
) => {
  const state = {
    slug: extras.slug,
    parentSlug: extras.parentSlug,
  }
  if (item.submissionUuid) {
    if (item.submissionStatus === SUBMISSION_STATUS.savedForLater) {
      return (
        <Button
          variant="text"
          component={Link}
          to={`/assignments/${item.uuid}/?submissionId=${item.submissionUuid}`}
          state={state}
        >
          Resume
        </Button>
      )
    }

    return (
      <Button
        variant="text"
        component={Link}
        to={`/assignments/${item.uuid}/${item.submissionUuid}`}
        state={state}
      >
        View
      </Button>
    )
  }

  return (
    <Link to={`/assignments/${item.uuid}`} state={state}>
      <Button variant="text">Start</Button>
    </Link>
  )
}

const CourseAssignments = ({ className }: PropsWithClassName) => {
  const { course } = useCourseDetails()
  const { slug, parentSlug } = useParams()

  const { isFetching, hasNextPage, fetchNextPage, data, isLoading, isError } =
    useInfiniteQuery({
      queryKey: queries.assignments.list("course", course.id).queryKey,
      queryFn: x =>
        api.assignments.list({
          params: { page: x.pageParam || 1 },
          urlParams: {
            learningItemType: "course",
            learningItemId: course.id,
          },
        }),
      getNextPageParam: lastPage => {
        return lastPage.nextPage ?? undefined
      },
    })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const allData = data?.pages.map(page => page.data).flat() || []

  return (
    <div className={clsx(className, "pb-2")}>
      <Typography className="mb-2" variant="title3">
        Skill Evidences (Assignments)
      </Typography>

      {(isError || isLoading) && (
        <Fallback
          data={{
            image: HandHoldingPen,
            title: "Something went wrong!",
            description: "Please contact care@suraasa.com",
          }}
          className="md:my-18 sm:my-20 my-9"
          hasError={isError}
          isLoading={isLoading}
        />
      )}

      {allData.length > 0 ? (
        <ItemList>
          {allData.map(assignment => {
            const isLocked = assignment.dueDate === null
            return (
              <ItemRow
                key={assignment.uuid}
                className={clsx({
                  [styles.draft]:
                    assignment.submissionStatus ===
                    SUBMISSION_STATUS.savedForLater,
                })}
                infoSlot={
                  <div className="grow" ref={hasNextPage ? ref : null}>
                    {assignment.submissionStatus ===
                      SUBMISSION_STATUS.savedForLater && (
                      <Typography
                        variant="smallBody"
                        style={{ fontSize: 12 }}
                        className="mb-0.5"
                        color="onSurface.500"
                      >
                        Continue Writing
                      </Typography>
                    )}
                    <TruncatedText
                      maxLength={60}
                      variant="strong"
                      className="mb-0.5"
                      hideActionButton
                    >
                      {buildAssignmentTitle(assignment)}
                    </TruncatedText>

                    <div className="flex items-center gap-1">
                      {getTags(assignment)}
                      {getDueDate(assignment)}
                    </div>
                  </div>
                }
                actionSlot={() => {
                  if (isLocked) return <Lock />
                  return (
                    <span className={clsx({ "self-end": isXs })}>
                      {getButtonAction(assignment, { slug, parentSlug })}
                    </span>
                  )
                }}
              />
            )
          })}
        </ItemList>
      ) : (
        <Fallback
          data={{
            image: HandHoldingPen,
            title: "No skill evidences (assignments) found!",
            description:
              "Make progress in your courses to unlock skill evidences (assignments)!",
          }}
          className="md:my-18 sm:my-20 my-9"
        />
      )}
    </div>
  )
}

export default CourseAssignments
