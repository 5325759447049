import Image1 from "./assets/resource1.png"
import Image2 from "./assets/resource2.png"
import Image3 from "./assets/resource3.png"
import Image4 from "./assets/resource4.png"
import Image5 from "./assets/resource5.png"

export type ResourceProps = {
  id: string
  title: string
  type: ResourceType
  actionUrl: string
  thumbnail: string
  platform: ResourcePlatform
}

export enum ResourceType {
  BLOG = "blog",
  MASTERCLASS = "masterclass",
  PODCAST = "podcast",
  VIDEO = "video",
}

export enum ResourcePlatform {
  SURAASA = 1,
  YOUTUBE = 2,
  SPOTIFY,
}

export const RESOURCES_DATA = [
  {
    id: "1",
    title: "Significance of Pedagogical Skills for Teachers",
    type: ResourceType.BLOG,
    actionUrl:
      "https://www.suraasa.com/blog/significance-of-pedagogical-skills-for-teachers",
    thumbnail: Image1,
    platform: ResourcePlatform.SURAASA,
  },
  {
    id: "2",
    title: "Olympiads & self-assessing competitions are important for teachers",
    type: ResourceType.VIDEO,
    actionUrl:
      "https://www.thehansindia.com/hans/young-hans/olympiads-self-assessing-competitions-are-important-for-teachers-810427",
    thumbnail: Image2,
    platform: ResourcePlatform.YOUTUBE,
  },
  {
    id: "3",
    title: "Climbing the Career Ladder: Strategies for Teacher Promotions",
    type: ResourceType.MASTERCLASS,
    actionUrl:
      "https://www.suraasa.com/masterclasses/climbing-the-career-ladder-strategies-for-teacher-promotions",
    thumbnail: Image3,
    platform: ResourcePlatform.SURAASA,
  },
  {
    id: "4",
    title: "Empowered Educators lead to Engaged Classrooms!",
    type: ResourceType.PODCAST,
    actionUrl:
      "https://podcasters.spotify.com/pod/show/suraasa/episodes/Empowered-Educators-lead-to-Engaged-Classrooms---Ms-Paramjeet-Kaur-Dhillon--Inspiring-Educators-e27a0j6",
    thumbnail: Image4,
    platform: ResourcePlatform.SPOTIFY,
  },
  {
    id: "5",
    title: "Tips for Teacher Interviews",
    type: ResourceType.VIDEO,
    actionUrl: "https://www.youtube.com/watch?v=KxdV2ASCVos",
    thumbnail: Image5,
    platform: ResourcePlatform.YOUTUBE,
  },
] as const
