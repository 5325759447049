export enum ApplicationStatus {
  NOT_STARTED = 1,
  IN_PROGRESS,
  SUBMITTED,
  UNDER_REVIEW,
  ACCEPTED,
  REJECTED,
}

export enum ReviewStatus {
  NOT_STARTED = 1,
  REVIEW_STARTED,
  CHANGES_REQUESTED,
  CHANGES_STARTED,
  CHANGES_DONE,
  ACCEPTED,
  REJECTED,
  INTERVIEW_REQUESTED,
}

export enum InterviewStatus {
  SCHEDULED = 1,
  NO_SHOW,
  CANCELLED,
  ACCEPTED,
  REJECTED,
}
export type ApplicationOverview = {
  id: number
  questionnaireSlug: string
  status: ApplicationStatus
  review: {
    id: number
    status: ReviewStatus
  } | null
  interview: {
    id: number
    status: InterviewStatus
    meetingUrl: string // Interview joining url
    scheduledDate: string | null
  } | null
}
