import {
  Button,
  Dialog,
  Divider,
  LinearProgress,
  Typography,
} from "@suraasa/placebo-ui"
import { SubjectResponse } from "api/resources/profile/types"
import ProfileContext from "features/Profile/context"
import { Check, Plus } from "iconoir-react"
import React, { useContext, useState } from "react"

import QualificationsDialog from "../Academics/Qualifications/EditDialog"
import AddSubject from "../Subjects/AddDialog"

type Props = {
  handleOpenPersonalDetailsSheet: () => void
}
type CheckListType = { name: string; isCompleted: boolean; key?: string }

const AddSubjectDialog = ({
  open,
  onAdd,
  onRequestClose,
}: {
  onAdd: (data: SubjectResponse) => void
  open: boolean
  onRequestClose: () => void
}) => {
  return (
    <Dialog open={open} width={518} onRequestClose={onRequestClose}>
      <AddSubject open={open} onAdd={onAdd} onRequestClose={onRequestClose} />
    </Dialog>
  )
}

const CheckListBanner = ({ handleOpenPersonalDetailsSheet }: Props) => {
  const {
    profile: { country, gender, nationality, phoneNumber },
    academics: { qualifications },
    subjects: { data: dataSubjects, add: addSubjects },
  } = useContext(ProfileContext)

  const [qualificationsDialogOpen, toggleQualificationsDialog] = useState(false)
  const [subjectDialogOpen, toggleSubjectDialog] = useState(false)

  const checkList: CheckListType[] = [
    {
      name: "Phone Number",
      isCompleted: Boolean(phoneNumber),
    },
    {
      name: "Gender",
      isCompleted: Boolean(gender),
    },
    {
      name: "Nationality",
      isCompleted: Boolean(nationality),
    },
    {
      name: "Current Location",
      isCompleted: Boolean(country),
    },
    {
      name: "Subjects",
      key: "subjects",
      isCompleted: dataSubjects.length > 0,
    },
    {
      name: "Qualifications",
      key: "qualifications",
      isCompleted: qualifications.data.length > 0,
    },
  ]

  const checkListProgress = () => {
    const filterCompleted = checkList.filter(item => item.isCompleted === true)
    return (100 * filterCompleted.length) / checkList.length
  }

  const handleAction = (item: CheckListType) => {
    if ("key" in item) {
      switch (item.key) {
        case "qualifications":
          toggleQualificationsDialog(true)
          return
        case "subjects":
          toggleSubjectDialog(true)
          return
        default: {
          handleOpenPersonalDetailsSheet()
        }
      }
    } else {
      handleOpenPersonalDetailsSheet()
    }
  }

  if (checkListProgress() === 100) {
    return null
  }

  return (
    <>
      <div className="w-full bg-white border border-onSurface-200 rounded-2xl">
        <div className="px-3 py-2">
          <Typography variant="strong">Complete your Profile</Typography>
        </div>
        <Divider weight="light" color="onSurface.200" />
        <div className="px-3 py-2">
          <Typography
            variant="smallBody"
            className="mb-1.25"
            color="onSurface.400"
          >
            Please complete your profile to unlock full Suraasa services
          </Typography>
          <LinearProgress
            value={checkListProgress()}
            color="success"
            className="mb-3"
          />

          <div className="flex flex-col gap-1">
            {checkList.map((item, index) => {
              if (item.isCompleted) {
                return (
                  <div
                    key={index}
                    className="text-success-500 flex gap-1 px-1 py-0.5 w-fit items-center"
                  >
                    <Check width={20} height={20} />
                    <Typography variant="button">{item.name}</Typography>
                  </div>
                )
              }
              return (
                <Button
                  onClick={() => handleAction(item)}
                  key={index}
                  variant="text"
                  startAdornment={<Plus />}
                >
                  {item.name}
                </Button>
              )
            })}
          </div>
        </div>
      </div>
      <QualificationsDialog
        id={null}
        open={qualificationsDialogOpen}
        toggle={() => toggleQualificationsDialog(false)}
      />

      <AddSubjectDialog
        open={subjectDialogOpen}
        onAdd={addSubjects}
        onRequestClose={() => toggleSubjectDialog(false)}
      />
    </>
  )
}

export default CheckListBanner
