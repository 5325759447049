import ShareSheet from "components/ShareSheet"
import React, { useState } from "react"
import { getITOShareReferralText } from "utils/helpers"
import useCurrentCountry from "utils/hooks/useCurrentCountry"

type Props = {
  render: (handleOpen: () => void) => JSX.Element
  url: string
}

const ShareReferralSheet = ({ url, render }: Props) => {
  const [open, setOpen] = useState(false)

  const country = useCurrentCountry()
  const isIndia = country?.isoCode === "IN"

  return (
    <>
      {render(() => setOpen(true))}

      <div className="absolute">
        <ShareSheet
          data={{
            url: url,
            text: getITOShareReferralText(url, { discount: isIndia }),
          }}
          open={open}
          platforms={["whatsapp", "telegram", "linkedin", "facebook"]}
          handleClose={() => setOpen(false)}
        />
      </div>
    </>
  )
}

export default ShareReferralSheet
