import {
  Button,
  CircularProgress,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { ITOReportType } from "api/resources/ito/reports/types"
import { ITOCertificate } from "api/resources/profile/types"
import { useTheme } from "react-jss"
import { getAuthInfo } from "utils/helpers"

import ContactUs from "../BeforeRegistrationPhase/components/ContactUs"
import FAQs from "../BeforeRegistrationPhase/components/FAQs"
import DomainReports from "./DomainReports"
import GlobalParticipation from "./GlobalParticipation"
import HallOfFame from "./HallOfFame"
import LetEveryoneKnow from "./LetEveryoneKnow"
import MasterclassCard from "./MasterclassCard"
import OverallReports from "./OverallReports"
import TopPercentageBanner from "./TopPercentageBanner"
import WallOfRecognition from "./WallOfRecognition"
import YourRewards from "./YourRewards"

const authInfo = getAuthInfo()
const email = authInfo?.user.email
const link = `https://forms.suraasa.com/lestransformations/form/OlympiadNameCorrection/formperma/sHJSTW2gW0DRmO2fR3BbVQmv7mi2Zm_d5FjD2MhLJPs?email=${email}`
const faqList = [
  {
    title: "I want to change my name on my certificates/report",
    description: `Kindly update your name by clicking here. Once the name is successfully updated, please fill this <a href=${link} style="text-decoration: none; color: #3B82F6; border-bottom: 1px solid #3B82F6;" target="_blank">Name Change Request Form.</a> `,
  },
  {
    title: "I need help in understanding my ITO result.",
    description: `Here is a detailed walkthrough video of how to understand your ITO Report: <a href="https://suraasa.co/I_xJ" style="text-decoration: none; color: #3B82F6; border-bottom: 1px solid #3B82F6;" target="_blank">Watch video</a>`,
  },
  {
    title: "Can I share my report with my school?",
    description:
      "We won't be sharing your report directly with anyone else. But you can share it with your school or anywhere else on your discretion.",
  },
]

const ITO_SLUG = "international-teacher-olympiad-2023"

type Props = {
  gotoFAQ: () => void
}
const AfterResultPhase = ({ gotoFAQ }: Props) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const { data: leaderBoardList } = useQuery({
    queryKey: ["leaderBoardList"],
    queryFn: () =>
      api.ito.registrations.getLeaderBoardList({
        params: {
          page: "all",
          olympiad_slug: ITO_SLUG,
        },
      }),
  })

  // Fetch Reports
  const { data: reports } = useQuery({
    queryKey: ["reports"],
    queryFn: () =>
      api.ito.reports.listReports({
        params: {
          slug: ITO_SLUG,
        },
      }),
  })

  // Fetch User Certificates
  const { data: certificates } = useQuery({
    queryKey: ["userCertificates"],
    queryFn: () =>
      api.profile.credentials.list({ params: { types: "olympiad_award" } }),
    select: data => {
      const certificates = data as ITOCertificate[]
      if (!certificates) return null

      return certificates.filter(x => x.item.olympiad.year === 2023)
    },
  })

  const { data: userPercentile } = useQuery({
    queryKey: ["userPercentile"],
    queryFn: () =>
      api.ito.registrations.getUserPercentile({
        urlParams: {
          slug: "ito-assessment-2023",
        },
      }),
  })

  const { data: awardsOverview } = useQuery({
    queryKey: ["awardsOverview"],
    queryFn: () =>
      api.ito.registrations.getUserAwardsOverview({
        params: {
          olympiad_slug: ITO_SLUG,
        },
      }),
  })

  // Fetch Ito Masterclass
  // const { data: masterclass, isLoading: isMasterclassLoading } = useQuery({
  //   queryKey: ["itoMasterclass"],
  //   queryFn: () =>
  //     api.masterclasses.getItoMasterclass({
  //       urlParams: {
  //         slug: MasterclassSlug.domain6,
  //       },
  //     }),
  // })

  if (!reports)
    return (
      <div className="flex items-center justify-center p-5">
        <CircularProgress />
      </div>
    )

  const priorityCertificate = (() => {
    if (!certificates) return null

    const excellence = certificates.find(
      item =>
        item.item.slug === "international-teacher-olympiad-2023-excellence"
    )
    if (excellence) {
      return excellence
    }
    const merit = certificates.find(item => item.item.slug.includes("merit"))
    if (merit) {
      return merit
    }
    const participation = certificates.find(
      item =>
        item.item.slug === "international-teacher-olympiad-2023-participation"
    )
    return participation
  })()

  const overallReports = reports.find(
    report => report.reportType === ITOReportType.OVERALL
  )

  const domainReports = reports.filter(
    report => report.reportType === ITOReportType.DOMAIN
  )

  const isTop100 = awardsOverview?.itoTop100 || false

  return (
    <div>
      {priorityCertificate && (
        <TopPercentageBanner
          certificate={priorityCertificate}
          data={userPercentile}
          isTop100={isTop100}
        />
      )}
      <Container>
        <MasterclassCard className="pb-6 md:pb-9 sm:pb-5 mt-5" />
      </Container>

      {overallReports && (
        <Container fullWidth={isXs} className="pb-6 md:pb-9 sm:pb-5">
          <OverallReports data={overallReports} />
        </Container>
      )}

      <Container className="pb-6 md:pb-9 sm:pb-5">
        <DomainReports data={domainReports} />
      </Container>

      {awardsOverview && (
        <YourRewards
          isTop100={isTop100}
          isTop800={awardsOverview.itoTop800}
          isTop1Percentile={
            userPercentile?.percentile ? userPercentile.percentile >= 99 : false
          }
        />
      )}

      <WallOfRecognition data={certificates as ITOCertificate[]} />

      {priorityCertificate && (
        <Container className="py-3 sm:py-5">
          <LetEveryoneKnow certificate={priorityCertificate} />
        </Container>
      )}

      <HallOfFame list={leaderBoardList} />

      <div className="mt-3">
        {/* Container is handled inside */}
        <GlobalParticipation
          title="You have created history!"
          description="~100,000 teachers from 8,000+ Schools and 90+ countries registered for International Teachers' Olympiad 2023. You took part in the largest annual event for teacher growth & recognition!"
        />
      </div>
      <Container className="mt-5 mb-5 sm:mb-20 sm:mt-8">
        <FAQs
          data={faqList}
          endSlot={
            <Button className="mt-4" onClick={gotoFAQ} variant="ghost">
              See All FAQs
            </Button>
          }
        />
      </Container>
      <ContactUs hideWhatsapp />
    </div>
  )
}

export default AfterResultPhase
