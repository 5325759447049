import { CircularProgress, Container, Typography } from "@suraasa/placebo-ui"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  Assessment,
  AssessmentType,
  AttemptStatus,
} from "api/resources/ito/assessments/types"
import { ImportantDates } from "api/resources/ito/registrations/types"
import { APIResponse } from "api/types"
import { isAfter, isBefore, isToday } from "date-fns"
import { formatDate, getAuthInfo } from "utils/helpers"

import AfterResultPhase from "../AfterResultPhase"
import RegisteredPhase from "../BeforeRegistrationPhase"
import TestPhase from "../TestPhase"
import AwaitingResultPhase from "./AwaitingResultPhase"

const debugMode = false

const getDate = (timeline: ImportantDates[], slug: ImportantDates["slug"]) => {
  const date = timeline.find(i => i.slug === slug)?.date

  if (!date) {
    throw new Error("Timeline date not found")
  }

  return new Date(date)
}

/**
 * Don't need to handle "isRegistered" checks because the outer component handles it.
 *
 * This component will throw if any of the following conditions are not met:
 * @error Timeline date not found.
 *
 * Cause: Dates should be defined for all the following slugs:
 * "registrations-end-2023"
 * "results-announced-2023"
 * "mock-test-available-2023"
 * "attempt-the-olympiad-2023"
 *
 * @error Olympiad not found
 *
 * Cause: An assessment with assessmentType: LIVE should be present in the assessments array.
 *
 * @error Olympiad end time cannot be null
 *
 * Cause: Olympiad endTime must be defined
 *
 */
const HandleDashboardTab = ({
  gotoFAQ,
  gotoAwardsAndPrize,
  assessments,
}: {
  gotoFAQ: () => void
  gotoAwardsAndPrize: () => void
  assessments: UseQueryResult<APIResponse<Assessment[]>, unknown>
}) => {
  const now = new Date()

  const timeline = useQuery({
    enabled: Boolean(getAuthInfo()),
    queryKey: queries.itoRegistrations.timeLineDate().queryKey,
    queryFn: () =>
      api.ito.registrations.getDates({ params: { tag: "dashboard" } }),
  })

  const refreshState = () => {
    assessments.refetch()
    timeline.refetch()
  }

  if (timeline.isLoading || assessments.isLoading) {
    return (
      <Container>
        <div className="flex flex-col items-center justify-center w-full h-full p-4 mx-auto">
          <CircularProgress />
          <span className="mt-1">Loading your ITO Dashboard</span>
        </div>
      </Container>
    )
  }

  if (timeline.isError || assessments.isError) {
    return (
      <div className="mt-4 px-1 flex items-center justify-center">
        <Typography variant="title2" textAlign="center">
          We&apos;re bringing new changes to your ITO Dashboard. Stay Tuned!
        </Typography>
      </div>
    )
  }

  const dates = {
    registrationsEnd: getDate(timeline.data, "registrations-end-2023"),
    resultDate: getDate(timeline.data, "results-announced-2023"),
    // mockTestAvailable: getDate(timeline.data, "mock-test-available-2023"),
    // olympiadPhase: getDate(timeline.data, "attempt-the-olympiad-2023"),
  }

  const registrationPeriodOngoing = isBefore(now, dates.registrationsEnd)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const olympiad = assessments.data.find(
    x => x.assessmentType === AssessmentType.LIVE
  )

  if (registrationPeriodOngoing && assessments.data.length === 0) {
    return <RegisteredPhase />
  }

  if (assessments.data.length === 0) {
    return (
      <div className="mt-4 px-1 flex items-center justify-center">
        <Typography variant="title2" textAlign="center">
          We&apos;re bringing new changes to your ITO Dashboard. Stay Tuned!
        </Typography>
      </div>
    )
  }

  if (!olympiad) {
    return (
      <TestPhase
        mode="mock"
        refreshState={refreshState}
        assessments={assessments.data}
      />
    )
  }

  const olympiadStartTime = new Date(olympiad.startTime)
  if (!olympiad.endTime) throw new Error("Olympiad end time cannot be null")

  const olympiadEndTime = new Date(olympiad.endTime)

  const Debug = (props: { phase: string }) => {
    const olympiadIsToday =
      isBefore(now, olympiadStartTime) && !isToday(olympiadStartTime)

    const olympiadDate = formatDate(
      olympiadStartTime.toISOString(),
      "d MMM, yyyy - h:mm a"
    )

    const config = {
      phase: props.phase,
      olympiad: {
        olympiadTimeOver: isAfter(now, olympiadEndTime),
        isToday: olympiadIsToday,
        time: olympiadDate,
        attemptStatus: olympiad.attempt?.status
          ? AttemptStatus[olympiad.attempt.status]
          : undefined,
      },
      registration: {
        endDate: formatDate(
          dates.registrationsEnd.toISOString(),
          "d MMM, yyyy - h:mm a"
        ),
        registrationPeriodOngoing,
      },
    }

    if (!debugMode) {
      // console.log(JSON.stringify(config, null, 2))
      return null
    }

    return <pre>{JSON.stringify(config, null, 2)}</pre>
  }

  // if (
  //   isAfter(now, olympiadEndTime) &&
  //   (!olympiad.attempt ||
  //     olympiad.attempt?.status === AttemptStatus.NOT_STARTED)
  // ) {
  //   return (
  //     <>
  //       <Debug phase="You missed the Olympiad" />
  //       <AwaitingResultPhase
  //         resultDate={dates.resultDate}
  //         gotoFAQ={gotoFAQ}
  //         hasMissedIto
  //         gotoAwardsAndPrize={gotoAwardsAndPrize}
  //       />
  //     </>
  //   )
  // }

  // if (
  //   olympiad.attempt?.status === AttemptStatus.COMPLETED ||
  //   (isAfter(now, olympiadEndTime) &&
  //     olympiad.attempt?.status === AttemptStatus.IN_PROGRESS)
  // ) {
  //   return (
  //     <>
  //       <Debug phase="Awaiting Results" />
  //       <AwaitingResultPhase
  //         resultDate={dates.resultDate}
  //         gotoFAQ={gotoFAQ}
  //         gotoAwardsAndPrize={gotoAwardsAndPrize}
  //       />
  //     </>
  //   )
  // }

  // if (isBefore(now, olympiadStartTime) && !isToday(olympiadStartTime)) {
  //   return (
  //     <>
  //       <Debug phase="Mock Test Phase" />
  //       <TestPhase
  //         mode="mock"
  //         refreshState={refreshState}
  //         assessments={assessments.data}
  //       />
  //     </>
  //   )
  // }

  // if (
  //   isToday(olympiadStartTime) ||
  //   (isAfter(now, olympiadStartTime) && isBefore(now, olympiadEndTime))
  // ) {
  //   return (
  //     <>
  //       <Debug phase="Today is Olympiad" />
  //       <TestPhase
  //         mode="olympiad"
  //         refreshState={refreshState}
  //         assessments={assessments.data}
  //       />
  //     </>
  //   )
  // }

  if (
    isAfter(now, olympiadEndTime) &&
    (!olympiad.attempt ||
      olympiad.attempt?.status === AttemptStatus.NOT_STARTED)
  ) {
    return (
      <AwaitingResultPhase
        hasMissedIto
        gotoAwardsAndPrize={gotoAwardsAndPrize}
      />
    )
  }
  return <AfterResultPhase gotoFAQ={gotoFAQ} />
  // throw new Error("There was an error loading your ITO Dashboard.")
}

export default HandleDashboardTab
