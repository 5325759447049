import { Button, Typography } from "@suraasa/placebo-ui"
import { Question } from "api/resources/assessments/types"
import clsx from "clsx"
import React, { useCallback, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"

import { convertSubStringArrayToObjects, getStringAsSubString } from "./helpers"

const BLANK_SEPARATOR = "__blank__"

const useStyles = createUseStyles(theme => ({
  question: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  button: {
    minWidth: "min-content !important",
    "& p": {
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
    display: "block",
    textAlign: "left",
    padding: theme.spacing(1.25, 2.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  blanks: {
    marginLeft: theme.spacing(0.5),
    "& button": {
      width: "auto",
      minWidth: "auto",
    },
  },
  optionWrapper: {
    width: "max-content",
    maxWidth: "60vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80vw",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },

  option: {
    minWidth: "100%",
    marginBottom: theme.spacing(2),
  },
}))

type Props = {
  question: Question
  onChange: (questionId: string, value: (number | null)[]) => void
}

// provides with next blank give the question text object
function nextBlank(question: (string | BlankType)[]) {
  for (let i = 0; i < question.length; i++) {
    const chunk = question[i]
    if (typeof chunk !== "string" && typeof chunk.value === "string") {
      return chunk.position
    }
  }
}

type BlankType = {
  text: number | null
  position: number
  value: React.ReactElement<HTMLButtonElement> | string
}

// returns the response needed to be sent to the parent [questionId, responseArray]
function setResponse(questionText: (string | BlankType)[]) {
  const response = []
  for (let i = 0; i < questionText.length; i++) {
    const chunk = questionText[i]
    if (typeof chunk !== "string") {
      response.push(chunk.text)
    }
  }
  return response
}

function FillBlanks(props: Props) {
  /*
   * This component implements array of objects with strings in order to handle fill in the blanks functionality
   * EXPLANATION OF LOGIC:
   * First we create an array of strings containing every special string as a separate index in the array
   * ["__blank__", "some string", "__blank__"] this helps us separate the special string
   * Then we convert special string to objects in order to work with in an better way leading to [{}, "some string", {}]
   * Finally the trick is to create a jsx element instead of string one
   * for example we can set const a = <span>bakst</span> and render a as an element
   *
   * EXPLANATION OF FILLING THE BLANKS:
   * Once user has filled all the blanks he/she is not able to select/replace any blanks
   * unless one or more are deselected from the question text.
   *
   * HACKS:
   * 1. setLoading is used to render only when questionText has its state set
   * 2. Using previous state in setQuestionText to set the new state and set response on parent
   */
  const classes = useStyles()

  const [question, setQuestion] = useState(props.question) // converting question prop to state variable
  // converted question as array of objects and strings with total number of blanks
  const convertedQuestion = convertSubStringArrayToObjects(
    getStringAsSubString(question.question, BLANK_SEPARATOR), // get sub strings array with BLANK_separator
    BLANK_SEPARATOR,
    "___________"
  )

  // question text as another to manage question text differently
  const [questionText, setQuestionText] =
    useState<(string | BlankType)[]>(convertedQuestion)
  const [loading, setLoading] = useState(true)

  const selectAnswer = (optionIndex: string) => {
    // This function selects and replaces the string value with jsx element in questionText provided option index
    const option = question.options[optionIndex]
    const blankPosition = nextBlank(questionText)

    // this checks returns if there is no position left to fill
    if (typeof blankPosition !== "number") return

    setQuestionText(prevState => {
      const newState = prevState.map(obj => {
        // check for the blanks as they always are objects and if this is the blank we want to replace
        if (typeof obj === "object" && obj.position === blankPosition) {
          obj.text = parseInt(optionIndex)
          obj.value = (
            <Button
              onClick={() => deselectAnswer(obj.position)}
              variant="outlined"
              color="black"
              className={classes.option}
            >
              {option}
            </Button>
          ) // MAGIC
        }
        return obj
      })
      props.onChange(question.id, setResponse(newState))
      return newState
    })
    setQuestion(question)
  }

  const deselectAnswer = useCallback(
    (position: any) => {
      // replaces jsx element for the position provided in the question text object
      setQuestionText(prevState => {
        const newState = prevState.map(obj => {
          if (typeof obj === "object") {
            if (obj.position === position) {
              obj.text = null
              obj.value = "________"
            }
          }
          return obj
        })
        props.onChange(question.id, setResponse(newState))
        return newState
      })
    },
    [question.id, props]
  )

  useEffect(() => {
    setQuestion(props.question)
    setQuestionText(
      convertSubStringArrayToObjects(
        getStringAsSubString(props.question.question, BLANK_SEPARATOR), // get sub strings array with BLANK_separator
        BLANK_SEPARATOR,
        "___________"
      )
    )
    // setting the state if there is already a response provided
    setQuestionText(prevState => {
      if (question.response) {
        for (let i = 0; i < prevState.length; i++) {
          const chunk = prevState[i]
          if (typeof chunk !== "string") {
            const text = question.response[chunk.position]
            // check if there is a marked answer for the current position
            if (text != null) {
              chunk.text = text
              chunk.value = (
                <Button
                  onClick={() => deselectAnswer(chunk.position)}
                  variant="outlined"
                  color="black"
                >
                  {question.options[text]}
                </Button>
              )
            }
          }
        }
      }
      setLoading(false)
      return prevState
    })
  }, [deselectAnswer, question, props.question])

  /**
   * This method is different from deselectAnswer in the sense that it takes in the option value and then
   * finds its position in the blanks and deselects it using the deselectAnswer function.
   *
   * It is a wrapper on top of deselectAnswer.
   */
  const deselectOption = (optionKey: string) => {
    const key = parseInt(optionKey)

    for (let i = 0; i < questionText.length; i++) {
      const chunk = questionText[i]
      if (typeof chunk === "object") {
        if (chunk.text === key) {
          deselectAnswer(chunk.position)
          break
        }
      }
    }
  }

  return (
    <div className={classes.marginBottom}>
      {!loading ? (
        <>
          <div className="flex flex-col gap-2">
            <div style={{ maxWidth: "100%" }}>
              <Typography className={classes.question} variant="body">
                {questionText.map((chunk, index) => {
                  const isBlank = typeof chunk === "object"
                  return (
                    <span
                      key={index}
                      className={clsx({
                        [classes.blanks]: isBlank,
                      })}
                    >
                      {isBlank ? chunk.value : chunk}
                    </span>
                  )
                })}
              </Typography>
            </div>
          </div>

          <Typography
            variant="strongSmallBody"
            color="onSurface.500"
            className="pt-2 pb-1.5"
          >
            Options
          </Typography>

          <div className={classes.optionWrapper}>
            {Object.entries(question.options).map(([key, value]) => {
              const optionValue = Number(key)
              const isSelected = question.response?.includes(optionValue)

              return (
                <div key={key} className={classes.option}>
                  <Button
                    fullWidth
                    color="primary"
                    variant={isSelected ? "filled" : "outlined"}
                    className={classes.button}
                    onClick={() =>
                      isSelected ? deselectOption(key) : selectAnswer(key)
                    }
                  >
                    <Typography variant="body">{value as string}</Typography>
                  </Button>
                </div>
              )
            })}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default FillBlanks
