import { Button, Divider, Typography, VideoPlayer } from "@suraasa/placebo-ui"
import api from "api"
import { LearningItem } from "api/resources/learningItems/types"
import { ReactComponent as NoteAdd } from "features/LearningModule/assets/icons/note-add.svg"
import { context } from "global/Context/context"
import { useContext, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import { LearningModuleContext } from "../context"
import { useUpdateItemStatus } from "../hooks/useUpdateItemStatus"
import AddNoteDialog from "./Notes/AddNoteDialog"
import Notes from "./Notes/Notes"

type Props = {
  item: LearningItem
}

const Video = ({ item }: Props) => {
  const { slug } = useParams() as { slug: string }
  const { enrollments } = useContext(context)
  const courseId = enrollments?.find(x => x.slug === slug)?.uuid

  if (item.learningContentType !== "video") {
    throw new Error("Video component can only be used for video content type")
  }

  const { updateItemStatus } = useUpdateItemStatus(item)

  const {
    addVideoBookmarkInState,
    removeVideoBookmarkFromState,
    enableVideoNextButton,
  } = useContext(LearningModuleContext)

  const [openNotesDialog, setOpenNotesDialog] = useState(false)

  const videoPlayerRef = useRef<HTMLVideoElement>(null)

  const [currentTimestamp, setCurrentTimestamp] = useState(0)

  const [videoWasPausedWhenDialogOpened, setVideoWasPausedWhenDialogOpened] =
    useState(false)

  const handleNoteDialogClose = () => {
    if (!videoWasPausedWhenDialogOpened) {
      videoPlayerRef.current?.play()
      setVideoWasPausedWhenDialogOpened(false)
    }
    setOpenNotesDialog(false)
  }

  const handleAddNoteDialogOpen = () => {
    if (videoPlayerRef.current) {
      const alreadyPaused = videoPlayerRef.current.paused
      setVideoWasPausedWhenDialogOpened(alreadyPaused)
      if (!alreadyPaused) {
        videoPlayerRef.current.pause()
      }

      setCurrentTimestamp(videoPlayerRef.current.currentTime)
      setOpenNotesDialog(true)
    }
  }

  const gotoTimeStamp = (time: number) => {
    if (videoPlayerRef.current) videoPlayerRef.current.currentTime = time
  }

  const handleDelete = async (id: string) => {
    try {
      await api.learningItems.removeVideoBookmark({
        urlParams: {
          id,
        },
      })
      removeVideoBookmarkFromState(id)
    } catch (error) {
      console.error(error)
    }
  }

  function handleExitFullScreen() {
    if (document.exitFullscreen) document.exitFullscreen()
    else console.error(">exitFullscreen doesn't exist on document")
    // else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
    // else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
    // else if (document.msExitFullscreen) document.msExitFullscreen()
  }

  useEffect(() => {
    const videoPlayerRefCurrent = videoPlayerRef.current
    if (videoPlayerRefCurrent) {
      videoPlayerRefCurrent.autoplay = true
      videoPlayerRefCurrent.addEventListener("ended", handleExitFullScreen)
    }

    return () => {
      if (videoPlayerRefCurrent) {
        videoPlayerRefCurrent.removeEventListener("ended", handleExitFullScreen)
        videoPlayerRefCurrent.removeEventListener("ended", handleExitFullScreen)
      }
    }
  }, [])

  useEffect(() => {
    const onPlay = () => {
      updateItemStatus()
    }

    const onTimeUpdate = (x: Event) => {
      const video = x.target

      if (video && "currentTime" in video && "duration" in video) {
        const duration = video.duration as number
        const currentTime = video.currentTime as number

        if (currentTime >= duration * 0.9) {
          console.info("> Allow next")
          enableVideoNextButton()
        }
      }
    }

    const videoPlayerRefCurrent = videoPlayerRef.current
    if (videoPlayerRefCurrent) {
      videoPlayerRefCurrent.addEventListener("playing", onPlay)
      videoPlayerRefCurrent.addEventListener("timeupdate", onTimeUpdate)
    }

    return () => {
      if (videoPlayerRefCurrent) {
        videoPlayerRefCurrent.removeEventListener("playing", onPlay)
        videoPlayerRefCurrent.removeEventListener("timeupdate", onTimeUpdate)
      }
    }
  }, [updateItemStatus])

  const { title, url } = item

  const languages = item.languagesAvailable?.url

  const [currentLanguageId, setCurrentLanguageId] = useState(
    item.languagesAvailable?.url[0]?.uuid ?? ""
  )
  if (!url) throw new Error("Video url is not defined")

  return (
    <div className="mb-5">
      {courseId && (
        <AddNoteDialog
          courseId={courseId}
          videoId={item.uuid}
          open={openNotesDialog}
          timestamp={currentTimestamp}
          onAdd={data => {
            addVideoBookmarkInState(data)
            handleNoteDialogClose()
          }}
          handleClose={handleNoteDialogClose}
        />
      )}
      {item.url && (
        <VideoPlayer
          defaultLanguage={currentLanguageId}
          ref={videoPlayerRef}
          src={
            languages && currentLanguageId
              ? languages.find(i => i.uuid === currentLanguageId)?.url ??
                item.url
              : item.url
          }
          languages={
            languages
              ? languages.map(i => ({
                  name: i.language,
                  url: i.url,
                  id: i.uuid,
                }))
              : []
          }
          onLanguageChange={languageId => {
            setCurrentLanguageId(languageId)
          }}
          actions={
            <button onClick={handleAddNoteDialogOpen}>
              <NoteAdd className="fill-onSurface-200" />
            </button>
          }
        />
      )}
      <Typography variant="title3" className="mt-2">
        {title}
      </Typography>
      <Divider
        className="my-3 hidden sm:block"
        color="onSurface.200"
        weight="light"
      />
      <div className="flex justify-between items-center mt-1.5 sm:mt-0 mb-1.5">
        <Typography variant="strong">Notes</Typography>
        <Button
          variant="text"
          startAdornment={<NoteAdd className="fill-primary-500" />}
          onClick={handleAddNoteDialogOpen}
        >
          Add note
        </Button>
      </div>

      <Notes
        gotoTimeStamp={gotoTimeStamp}
        notes={item.bookmarkVideo}
        onDelete={handleDelete}
      />
    </div>
  )
}

export default Video
