import {
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Select,
  toast,
} from "@suraasa/placebo-ui"
import api from "api"
import { Subject as GlobalSubject } from "api/resources/global/types"
import { SubjectResponse } from "api/resources/profile/types"
// import { GlobalContext } from "components/GlobalState"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useResource } from "utils/hooks/useResource"

const useStyles = createUseStyles(theme => ({
  addSubjectContainer: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    "& > form": {
      display: "flex",
      gap: 24,
      flexDirection: "column",
    },
  },
}))

type Props = {
  onAdd: (data: SubjectResponse) => void
  onBack?: () => void
}

const AddDialog = ({
  onBack,
  onAdd,
  onRequestClose,
}: Props &
  Pick<DialogProps, "open" | "onRequestClose"> &
  Pick<DialogTitleProps, "onBack">) => {
  const classes = useStyles()

  const { subjects } = useResource(["subjects"])

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setError,
  } = useForm<Pick<GlobalSubject, "uuid">>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const res = await api.profile.subjects.create({
      data: {
        subjectId: data.uuid,
      },
    })

    if (res.isSuccessful) {
      setLoading(false)
      onAdd(res.data)
      toast.success("Added successfully.")
      reset()
      if (onBack) onBack()
    } else {
      if (res.errors.fieldErrors?.subjectId) {
        // @ts-expect-error TODO(profile): test this
        setError("uuid", { message: res.errors.fieldErrors.subjectId })
      }
      setLoading(false)
    }
  })

  return (
    <>
      <DialogTitle onBack={onBack}>Add New Subject</DialogTitle>
      <DialogContent className={classes.addSubjectContainer}>
        <form onSubmit={onSubmit}>
          <div>
            <Controller
              control={control}
              name="uuid"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  error={Boolean(errors.uuid)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.uuid}
                  helperText={errors.uuid?.message}
                  inputLabelProps={{ required: true }}
                  label="Subject"
                  options={subjects}
                  placeholder="Select Subject"
                  value={
                    value
                      ? subjects.find(subject => subject.uuid === value)
                      : null
                  }
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val?.uuid)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            variant: "filled",
            color: "primary",
            onClick: onSubmit,
            loading,
          },
          secondary: {
            label: "Close",
            onClick: onRequestClose,
          },
        }}
      />
    </>
  )
}

export default AddDialog
