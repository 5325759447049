import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("assessments", {
  attemptList: assessmentId => ({
    queryKey: ["attemptList", assessmentId],
  }),
  assessmentList: learningItemId => ({
    queryKey: ["assessmentList", learningItemId],
  }),
  topicWiseScore: attemptId => ({
    queryKey: ["topicWiseScore", attemptId],
  }),
  solutions: attemptId => ({
    queryKey: ["solutions", attemptId],
  }),
})
