import { Container, Typography } from "@suraasa/placebo-ui"
import React, { ReactNode } from "react"

import styles from "./TopHeadingBar.module.css"

const TopHeadingBar = ({
  heading,
  subheading,
  endSlot,
  children,
}: {
  heading: string
  subheading?: string
  endSlot?: ReactNode
  children?: ReactNode
}) => {
  return (
    <div className={styles.root}>
      <Container>
        <div className="flex justify-between items-center py-2 flex-wrap gap-1">
          <div>
            <Typography variant="title2" color="onSurface.800">
              {heading}
            </Typography>
            {subheading && (
              <Typography
                variant="largeBody"
                color="onSurface.500"
                className="mt-0.5"
              >
                {subheading}
              </Typography>
            )}
          </div>

          {endSlot && endSlot}
        </div>
        {children}
      </Container>
    </div>
  )
}

export default TopHeadingBar
