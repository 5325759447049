import { Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import ClassDetailsCard from "features/LearningItems/Course/Classes/ClassDetailsCard"
import VideoPlayerDialog from "features/LearningItems/Course/Classes/VideoPlayerDialog"
import { ArrowRight, Lock } from "iconoir-react"
import React, { useState } from "react"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"

import { useQualificationDetails } from ".."

const QualificationClasses = () => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { slug, learningItemType } = useParams() as {
    slug: string
    learningItemType: string
  }

  const [recordingURL, setRecordingURL] = useState<string | null>(null)

  const { qualification } = useQualificationDetails()

  const planner = useQuery({
    enabled: Boolean(qualification.id),
    queryFn: () =>
      api.learningItems.getPlannerList({
        params: {
          learningItemType: learningItemType,
          learningItemId: qualification.id,
        },
      }),
    queryKey: queries.learningItems.listPlanners(qualification.id).queryKey,
  })

  const extraClasses = useQuery({
    queryKey: queries.learningItems.listClasses(learningItemType, slug)
      .queryKey,
    queryFn: () =>
      api.learningItems.listClasses({
        urlParams: {
          learningItemType: learningItemType,
          learningItemId: qualification.id,
        },
        params: {
          sub_planner_items: false,
        },
      }),
  })

  const onViewRecording = (recordingUrl: string) => {
    setRecordingURL(recordingUrl)
  }
  const onClose = () => {
    setRecordingURL(null)
  }

  return (
    <>
      {extraClasses.isSuccess && extraClasses.data.length > 0 && (
        <div className="mb-6">
          <Typography variant="title3" className="mb-2">
            Extra Classes
          </Typography>

          <VideoPlayerDialog recordingURL={recordingURL} onClose={onClose} />

          <div className="flex flex-col gap-3">
            {extraClasses.data.map(item => (
              <ClassDetailsCard
                data={item}
                key={item.id}
                onViewRecording={onViewRecording}
              />
            ))}
          </div>
        </div>
      )}

      {planner.isSuccess && planner.data.length > 0 ? (
        <div>
          <Typography variant="title3" className="mb-2">
            Classes
          </Typography>
          <ItemList className="!py-0">
            {planner.data.map(item => {
              const isLocked = item.status === "locked"
              return (
                <ItemRow
                  className="!p-2"
                  key={item.uuid}
                  component={isLocked ? "div" : Link}
                  to={
                    isLocked
                      ? undefined
                      : `${routes.parentItemCourse
                          .replace(":learningItemType", learningItemType)
                          .replace(":slug", item.slug)
                          .replace(":parentSlug", slug)}/classes`
                  }
                  infoSlot={<Typography>{item.name}</Typography>}
                  actionSlot={() => {
                    if (isXs) return null
                    if (isLocked) return <Lock />
                    return <ArrowRight fontSize="0.75rem" />
                  }}
                />
              )
            })}
          </ItemList>
        </div>
      ) : (
        <div>
          <Typography variant="title3" className="mb-2">
            Classes
          </Typography>
          {planner.data?.length === 0 && (
            <Fallback
              className="mt-5"
              hasError={false}
              data={{ title: "No classes to show" }}
            />
          )}
          {planner.isLoading && (
            <Fallback isLoading={extraClasses.isLoading || planner.isLoading} />
          )}

          {planner.isError && <Fallback hasError={planner.isError} />}
        </div>
      )}
    </>
  )
}

export default QualificationClasses
