import { Container, IconButton, TextField } from "@suraasa/placebo-ui"
import Fallback from "components/Fallback"
import { useOlympiadPrices } from "features/ITO/utils/hooks/useOlympiadPrices"
import { Search, Xmark } from "iconoir-react"
import groupBy from "lodash/groupBy"
import React, { useEffect, useState } from "react"

import { getData } from "./data"
import Header from "./Header"
import PerTopicFAQ from "./PerTopicFAQ"
import Topics from "./Topics"

const FAQ = () => {
  const { getPrice } = useOlympiadPrices({ edition: "2023" })
  const faqData = getData(getPrice)
  const faqsGroupBy = groupBy(faqData, "category")

  const [search, setSearch] = useState("")
  const [faqs, setFaqs] = useState(faqsGroupBy)

  useEffect(() => {
    const searchInJSON = (search = "") => {
      const result = faqData.filter(data => {
        return (Object.keys(data) as Array<keyof typeof data>).some(key => {
          return JSON.stringify(data[key])
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase())
        })
      })
      return result
    }

    if (!search) {
      setFaqs(faqsGroupBy)
    } else {
      setFaqs(groupBy(searchInJSON(search), "category"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <div>
      <Header>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          startIcon={
            <Search className="text-onSurface-400" height={20} width={20} />
          }
          rounded
          endAdornment={
            <IconButton
              className="mr-1"
              color="secondary"
              onClick={() => setSearch("")}
            >
              <Xmark />
            </IconButton>
          }
          fullWidth
          placeholder="Search here"
          className={
            "[&>div]:border-onSurface-300 [&>div]:overflow-hidden max-w-[500px]"
          }
        />
      </Header>
      <Container className="mt-8">
        {!search && <Topics topics={Object.keys(faqsGroupBy)} />}
        {Object.values(faqs).map(item => (
          <PerTopicFAQ key={item[0].category} items={item} />
        ))}
        {Object.keys(faqs).length === 0 && (
          <Fallback
            data={{
              title: "You search returned no results",
            }}
          />
        )}
      </Container>
    </div>
  )
}

export default FAQ
