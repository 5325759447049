import { useMenuState } from "@szhsin/react-menu"
import { Enrollment } from "api/resources/learningItems/types"
import { Profile } from "api/resources/profile/types"
import { Email } from "api/resources/settings/types"
import { AuthData } from "api/resources/users/types"
import { ITOParticipationDetails } from "features/ITO/useITOAccess"
import React from "react"
import { saveAuthInfo } from "utils/helpers"

type Context = {
  authInfo: AuthData | null
  setAuthInfo: (auth: AuthData) => void
  logout: () => void
  isAuthenticated: boolean
  itoParticipationDetails: ITOParticipationDetails
  showCourseFeedbackCard: { courseId: string | null }
  emails: Email[]
  updateEmails: (emails: Email[]) => void
  setShowCourseFeedbackCard: React.Dispatch<
    React.SetStateAction<{ courseId: string | null }>
  >
  // null means loading
  enrollments: Enrollment[] | null
  profile: Profile
  fetchProfile: () => void
  notificationsPopupMenuState: ReturnType<typeof useMenuState>
}

export const defaultContextValue: Context = {
  authInfo: null,
  logout: () => {},
  setAuthInfo: saveAuthInfo,
  isAuthenticated: false,
  showCourseFeedbackCard: {
    courseId: null,
  },
  notificationsPopupMenuState: [
    { state: undefined, endTransition: () => {} },
    () => {},
  ],
  emails: [],
  updateEmails: () => {},
  setShowCourseFeedbackCard: () => {},
  enrollments: null,
  itoParticipationDetails: {
    currentITOAttemptStatus: undefined,
    pastYears: {
      isITO2022Participant: undefined,
    },
    itoRegistrationDetailsLoaded: false,
    itoRegistrationDetails: undefined,
    registrationStatus: "notStarted",
    waitlistStatus: undefined,
    timeline: undefined,
  },
  profile: {
    id: "",
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: null,
      middleName: "",
      username: "",
    },
  },
  fetchProfile: () => {},
}

export const context = React.createContext<Context>(defaultContextValue)
