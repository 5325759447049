import {
  Button,
  Divider,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { add } from "date-fns"
import { context } from "global/Context/context"
import { Xmark } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { useTimer } from "react-timer-hook"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import styles from "./Step2.module.css"

const AUTO_DISMISS_TIME = 5 // in seconds

type Props = {
  courseId: string
  onClose: () => void
}

const experienceList: { label: string; value: string }[] = [
  { label: "Awesome 😁", value: "awesome" },
  { label: "Helpful 😊", value: "helpful" },
  { label: "Interesting 🤔", value: "interesting" },
  { label: "Difficult 😣", value: "difficult" },
]

const Step2 = ({ courseId, onClose }: Props) => {
  const buttonList = Array.from({ length: 5 }, (_, i) => i + 1)
  const { setShowCourseFeedbackCard: setShowFeedbackCard } = useContext(context)
  const [rating, setRating] = useState<null | number>(null)
  const experience = useArray<string>([])
  const [comment, setComment] = useState("")

  const generateComment = () => {
    const experienceList =
      experience.array.length > 0 ? `@@@${experience.array.join(";")}@@@` : ""
    return comment.concat(experienceList)
  }

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () =>
      api.feedback.createCourseFeedback({
        data: {
          course: courseId,
          rating: rating,
          comment: generateComment(),
          platform: 1,
        },
      }),
    onSuccess: () => {
      setRating(null)
      experience.clear()
      setComment("")
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const timer = useTimer({
    expiryTimestamp: add(new Date(), { seconds: AUTO_DISMISS_TIME }),
    autoStart: false,
    onExpire: () => {
      setTimeout(() => {
        setShowFeedbackCard({ courseId: null })
      }, 1000)
    },
  })

  useEffect(() => {
    if (isSuccess) {
      timer.restart(add(new Date(), { seconds: AUTO_DISMISS_TIME }), true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, setShowFeedbackCard])

  const onSubmit = () => {
    mutate()
  }

  return (
    <div
      className={clsx("overflow-hidden", styles.container, {
        [styles.isSuccess]: isSuccess,
      })}
    >
      <div
        className={clsx(
          "flex justify-between items-center mb-2 sm:px-4 px-2 sm:pt-4 pt-2"
        )}
      >
        <Typography variant="title3" color="onSurface.800">
          Feedback
        </Typography>
        <IconButton onClick={onClose}>
          <Xmark />
        </IconButton>
      </div>
      {isSuccess ? (
        <div>
          <Typography
            variant="strong"
            color="onSurface.800"
            className="mb-4 sm:px-4 px-2"
          >
            Thank you for your feedback
          </Typography>

          <LinearProgress value={(timer.seconds / AUTO_DISMISS_TIME) * 100} />
        </div>
      ) : (
        <div className=" sm:px-4 px-2 sm:pb-4 pb-2">
          <Typography variant="strong" color="onSurface.800" className="mb-1.5">
            How would you rate this course?
          </Typography>
          <div className="flex gap-1.5 mb-1">
            {buttonList.map((item, index) => (
              <button
                onClick={() => {
                  setRating(item)
                }}
                className={clsx(styles.rateButton, {
                  [styles.selectedRateButton]: rating === item,
                })}
                key={index}
              >
                <Typography variant="strong">{item}</Typography>
              </button>
            ))}
          </div>
          <div className="flex justify-between items-center">
            <Typography variant="smallBody" color="onSurface.500">
              Not Happy
            </Typography>
            <Typography variant="smallBody" color="onSurface.500">
              Very Happy
            </Typography>
          </div>
          <div
            className={clsx(styles.bottomContainer, {
              [styles.bottomContainerActive]: rating,
            })}
          >
            <div className="overflow-hidden">
              <Divider className="my-3" />
              <Typography
                color="onSurface.800"
                variant="strong"
                className="mb-1.5"
              >
                How was your experience?
              </Typography>
              <div className="flex flex-wrap gap-1 mb-3">
                {experienceList.map((item, index) => {
                  const itemIndex = experience.array.findIndex(
                    i => i === item.value
                  )
                  const isSelected = itemIndex >= 0
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if (isSelected) {
                          experience.remove(itemIndex)
                        } else {
                          experience.push(item.value)
                        }
                      }}
                      className={clsx("p-1.25", styles.experienceButton, {
                        [styles.selectedExperienceButton]: isSelected,
                      })}
                    >
                      {item.label}
                    </button>
                  )
                })}
              </div>

              <Typography
                variant="strong"
                color="onSurface.800"
                className="mb-1"
              >
                Tell us more about your experience (optional)
              </Typography>
              <TextField
                className="mb-4"
                multiLine
                value={comment}
                rows={5}
                fullWidth
                onChange={(event: { target: { value: string } }) => {
                  setComment(event.target.value)
                }}
              />
              <Button onClick={onSubmit} loading={isLoading} fullWidth>
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Step2
