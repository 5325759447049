import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
//flags
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"

import SlidingBanner, { SlidingImagesType } from "./SlidingBanner"

const useStyles = createUseStyles((theme: Theme) => ({
  none: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  slidingBannerContainer: {
    background: theme.colors.onSurface[50],
  },
}))

const FlagList: SlidingImagesType = [
  { name: "Algeria", img: "/assets/flags/DZ.svg" },
  { name: "Australia", img: "/assets/flags/AU.svg" },
  { name: "Bahrain", img: "/assets/flags/BH.svg" },
  { name: "Bangladesh", img: "/assets/flags/BD.svg" },
  { name: "Brunei", img: "/assets/flags/BN.svg" },
  { name: "Cameroon", img: "/assets/flags/CM.svg" },
  { name: "Canada", img: "/assets/flags/CA.svg" },
  { name: "Egypt", img: "/assets/flags/EG.svg" },
  { name: "Ghana", img: "/assets/flags/GH.svg" },
  { name: "India", img: "/assets/flags/IN.svg" },
  { name: "Indonesia", img: "/assets/flags/ID.svg" },
  { name: "Ireland", img: "/assets/flags/IE.svg" },
  { name: "Italy", img: "/assets/flags/IT.svg" },
  { name: "Japan", img: "/assets/flags/JP.svg" },
  { name: "Jordan", img: "/assets/flags/JO.svg" },
  { name: "Kenya", img: "/assets/flags/KE.svg" },
  { name: "Kuwait", img: "/assets/flags/KW.svg" },
  { name: "Lebanon", img: "/assets/flags/LB.svg" },
  { name: "Malaysia", img: "/assets/flags/MY.svg" },
  { name: "Mexico", img: "/assets/flags/MX.svg" },
  { name: "New Zealand", img: "/assets/flags/NZ.svg" },
  { name: "Nigeria", img: "/assets/flags/NG.svg" },
  { name: "Oman", img: "/assets/flags/OM.svg" },
  { name: "Pakistan", img: "/assets/flags/PK.svg" },
  { name: "Peru", img: "/assets/flags/PE.svg" },
  { name: "Philippines", img: "/assets/flags/PH.svg" },
  { name: "Qatar", img: "/assets/flags/QA.svg" },
  { name: "Saudi Arabia", img: "/assets/flags/SA.svg" },
  { name: "Singapore", img: "/assets/flags/SG.svg" },
  { name: "South Africa", img: "/assets/flags/ZA.svg" },
  { name: "South Korea", img: "/assets/flags/KR.svg" },
  { name: "Sri Lanka", img: "/assets/flags/LK.svg" },
  { name: "Thailand", img: "/assets/flags/TH.svg" },
  { name: "The Netherlands", img: "/assets/flags/NL.svg" },
  { name: "Turkey", img: "/assets/flags/TR.svg" },
  { name: "United Arab Emirates", img: "/assets/flags/AE.svg" },
  { name: "United Kingdom", img: "/assets/flags/GB.svg" },
  { name: "United States of America", img: "/assets/flags/US.svg" },
  { name: "Uzbekistan", img: "/assets/flags/UZ.svg" },
]

const GlobalParticipation = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <>
      <Container>
        <div className="md:mb-8 sm:mb-5 mb-4">
          {!isXs && (
            <Typography variant="title2" className="mb-1">
              {title}
            </Typography>
          )}
          <Typography
            variant="largeBody"
            className="mb-3 max-w-[706px]"
            color="onSurface.500"
          >
            {description}
          </Typography>
        </div>
      </Container>
      <div>
        <div className={clsx("py-2 mb-1", classes.slidingBannerContainer)}>
          <SlidingBanner images={FlagList} />
        </div>
        {!isXs && (
          <div className="flex justify-center">
            <img
              src="/assets/ito/2023/participation-map.webp"
              alt="map"
              width="100%"
              className="my-3.5 px-0 lg:px-6 max-w-[1280px]"
            />
          </div>
        )}
        {isXs && (
          <>
            <div className={clsx("py-2 mb-1", classes.slidingBannerContainer)}>
              <SlidingBanner images={FlagList} reverseAnimation />
            </div>
            <div className={clsx("py-2 mb-1", classes.slidingBannerContainer)}>
              <SlidingBanner images={FlagList} />
            </div>
          </>
        )}
        <div className={clsx("py-2 mb-1", classes.slidingBannerContainer)}>
          <SlidingBanner images={FlagList} reverseAnimation />
        </div>
      </div>
    </>
  )
}

export default GlobalParticipation
