import {
  Button,
  Divider,
  Tag,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  OrderItemDetails,
  Transaction,
  TransactionStatus,
} from "api/resources/orders/types"
import { APIError } from "api/utils"
import TagFallback from "assets/Fallback/tag.svg"
import Fallback from "components/Fallback"
import { format } from "date-fns"
import React, { Fragment, useState } from "react"
import { useTheme } from "react-jss"
import { downloadFile, handleErrors } from "utils/helpers"
import useFormatPrice from "utils/hooks/useFormatPrice"

const PaymentDetails = ({
  id,
  currency,
  amount,
  createdAt,
  serialNumber,
  status,
}: Transaction) => {
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const [downloadReceiptLoading, setDownloadReceiptLoading] = useState<
    string | null
  >(null)
  const formatPrice = useFormatPrice()

  // TODO(annie): if type==="registration then show the tag

  const getReceipt = async (transactionId: string) => {
    setDownloadReceiptLoading(transactionId)
    try {
      const data = await api.orders.getInvoice({
        urlParams: { transactionId },
      })
      downloadFile(data.invoice, data.invoiceName)
      setDownloadReceiptLoading(null)
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
      setDownloadReceiptLoading(null)
    }
  }

  if (isXsDown)
    return (
      <div className="p-2">
        <div className="flex items-center justify-between">
          <span className="flex items-center">
            <Typography variant="subtitle2">
              {currency.symbol}
              {amount}
            </Typography>
            {/* TODO(annie): revert */}
            {/* {isRegistrationFee && (
              <Tag label="Registration Fee" className="ms-2" />
            )} */}
          </span>
          {status === TransactionStatus.SUCCESSFUL ? (
            <Tag color="success" label="Success" />
          ) : (
            <Tag color="critical" label="Failed" />
          )}
        </div>
        <div className="flex items-center justify-between mt-1">
          <span>
            <Typography variant="smallBody">
              {format(new Date(createdAt), "dd MMM yyyy")}
            </Typography>
            <Typography variant="strongSmallBody">{serialNumber}</Typography>
          </span>
          {status === TransactionStatus.SUCCESSFUL && (
            <Button
              variant="text"
              className="sm:self-auto self-center sm:mt-0 mt-1"
              onClick={() => getReceipt(id)}
              loading={downloadReceiptLoading === id}
            >
              Payment Receipt
            </Button>
          )}
        </div>
      </div>
    )

  return (
    <div className="flex items-center justify-between px-3 py-2">
      <div className="flex flex-col">
        <span className="flex items-center">
          <Typography variant="subtitle2">
            {currency.symbol}
            {formatPrice(amount)}
          </Typography>
          {/* TODO(annie): revert */}
          {/* {isRegistrationFee && (
            <Tag label="Registration Fee" className="ms-2" />
          )} */}
        </span>
        <span className="flex items-center sm:mt-1">
          <Typography variant="smallBody">
            {format(new Date(createdAt), "dd MMM yyyy")}
          </Typography>
          <Typography variant="strongSmallBody" className="mx-2">
            {serialNumber}
          </Typography>
          {status === TransactionStatus.SUCCESSFUL ? (
            <Tag color="success" label="Success" />
          ) : (
            <Tag color="critical" label="Failed" />
          )}
        </span>
      </div>
      {status === TransactionStatus.SUCCESSFUL && (
        <Button
          variant="text"
          className="sm:self-auto self-center sm:mt-0 mt-1"
          onClick={() => getReceipt(id)}
          loading={downloadReceiptLoading === id}
        >
          Payment Receipt
        </Button>
      )}
    </div>
  )
}

const Payments = ({ transactions }: Pick<OrderItemDetails, "transactions">) => {
  // TODO(annie): logic will change. type="registration" | "course"
  // const registrationTransaction = transactions.find(
  //   transaction => transaction.isRegistrationFee
  // )

  // const otherTransactions = transactions.filter(
  //   transaction => !transaction.isRegistrationFee
  // )

  return (
    <div className="mb-5">
      <Typography variant="preTitle" className="mb-2 sm:px-0 px-2">
        Payments
      </Typography>
      {/* 
      {registrationTransaction && (
        <div className="bg-surface-50 sm:border border-y border-surface-200 sm:rounded-2xl mb-2">
          <PaymentDetails {...registrationTransaction} />
        </div>
      )} */}

      {transactions.length > 0 ? (
        <div className="bg-surface-50 border border-surface-200 sm:rounded-2xl">
          {transactions.map((transaction, idx) => (
            <Fragment key={transaction.id}>
              <PaymentDetails {...transaction} />
              {idx < transactions.length - 1 && (
                <Divider weight="light" color="onSurface.200" />
              )}
            </Fragment>
          ))}
        </div>
      ) : (
        <Fallback
          data={{
            image: TagFallback,
            title: "No Payments done.",
          }}
          className="my-6"
          hasError={false}
        />
      )}

      {/* TODO(annie): show only when payment method is hassle free, not in V1 */}
      {/* <div className="mb-5">
        <PaymentPlanCard
          symbol={transactions[0].currency.symbol ?? "₹"}
          amount={27656}
        />
      </div> */}
    </div>
  )
}

export default Payments
