import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import TopHeadingBar from "components/TopHeadingBar"
import UpcomingTasks from "components/UpcomingTasks"
import ITORegistrationBanner from "features/ITO/Dashboard/RegistrationBanner"
import ExploreCoursesOnStoreBar from "features/Library/ExploreCoursesOnStoreBar"
import React from "react"
import { useTheme } from "react-jss"

import DynamicContainer from "./DynamicContainer"
import RecentMasterclasses from "./RecentMasterclasses"
import ResumeLearning from "./ResumeLearning"
import ScheduleBar from "./ScheduleBar"
import UpcomingMasterclass from "./UpcomingMasterclass"

const Home = () => {
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className="relative h-full min-h-screen">
      <TopHeadingBar heading="Home" />
      {isSmDown ? (
        <DynamicContainer>
          <ScheduleBar />
        </DynamicContainer>
      ) : (
        <div
          className="absolute right-0 z-10"
          style={{
            height: "100%",
            top: 0,
            overflow: "auto",
          }}
        >
          <UpcomingTasks positioned />
        </div>
      )}
      <div>
        <ITORegistrationBanner />
        {/* <DynamicContainer>
          <JobUpdates />
        </DynamicContainer> */}
        <DynamicContainer className="mt-4">
          <ResumeLearning />
          <UpcomingMasterclass />
        </DynamicContainer>

        <RecentMasterclasses />

        <DynamicContainer>
          <ExploreCoursesOnStoreBar className="mb-12 md:mb-0 md:-translate-y-4" />
        </DynamicContainer>
      </div>
    </div>
  )
}

export default Home
