import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
  container: {
    filter: "drop-shadow(0px 8px 25px rgba(0, 0, 0, 0.15))",
    borderRadius: 16,
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    backgroundPosition: "right",
    backgroundSize: "cover",
    background:
      "url('/assets/ito/2023/AfterResultPhase/YourRewards/tia-gradient.webp')",
  },
  designLogo: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  subContainer: {
    background:
      "linear-gradient(120deg, #0C0C0C 50%, rgba(0,0,0,0) 30%), linear-gradient(84.96deg, #262626 0%, #1E1E1E 97.46%)",
    borderRadius: "0px 0px 16px 16px",
    color: "white",
  },
}))
const ImpactAwardCard = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className="px-2 py-3 md:p-4" style={{ position: "relative" }}>
        <Typography className="mb-0.5" variant="preTitle">
          DIRECT NOMINATION
        </Typography>
        <Typography variant="title2">
          Teacher Impact Awards 4th edition
        </Typography>
        <img
          src="/assets/ito/2023/AfterResultPhase/YourRewards/grass-lines.svg"
          alt="beads"
          className={clsx(classes.designLogo, "hidden md:block")}
        />
        <Typography variant="body" className="max-w-[490px] mt-3">
          You’ve got a direct entry into the 4th edition of Suraasa’s global
          event, Teacher Impact Awards. Showcase your impact to the world!
        </Typography>
        <Typography color="onSurface.500" className="mt-1">
          More details to be shared shortly.
        </Typography>
      </div>
    </div>
  )
}

export default ImpactAwardCard
