import { Typography } from "@suraasa/placebo-ui"
import React from "react"

import Alert from "./Alert"
import CameraOverlay from "./CameraOverlay"
import FullscreenOverlay from "./FullscreenOverlay"
import NoFaceDetectedAlert from "./NoFaceDetectedAlert"
import UnFairMeansAlert from "./UnFairMeansAlert"

type Props = {
  /**
   * Close handler
   */
  onClose: (
    type: "fullscreen" | "tabUnfocus" | "devtools" | "noFace" | "unfairMeans"
  ) => void
  /**
   * Controls whether the "Change your settings" overlay should be displayed
   */
  showSettingsOverlay: boolean
  /**
   * Controls if the Devtools warning dialog should be displayed
   */
  showDevToolsDialog: boolean
  /**
   * Controls if the "Close devtools" overlay should be displayed
   */
  showDevToolsOverlay: boolean
  /**
   * Controls if the "Enable fullscreen" overlay should be displayed
   */
  showFullscreenOverlay: boolean
  /**
   * Controls if the unfocus warning dialog should be displayed
   */
  showTabUnfocusDialog: boolean
  /**
   * Controls if no face detected dialog should be displayed
   */
  showNoFaceDialog: boolean
  /**
   * Controls if cell phone violation dialog should be displayed
   */
  showUnfairMeansDialog: boolean
  /**
   * Total warning count. Test will end if it exceeds `maxWarningCount`
   */
  totalWarnings: number
  /**
   * Maximum warnings allowed
   */
  maxWarningCount: number | null
  /**
   * If `true` then no alerts are shown
   */
  disabled?: boolean
  /**
   * to know if its an olympiad or assessment
   */
  isOlympiad?: boolean
}

const ProctoringAlerts = ({
  isOlympiad = false,
  maxWarningCount,
  disabled,
  onClose,
  showSettingsOverlay,
  showFullscreenOverlay,
  showTabUnfocusDialog,
  showNoFaceDialog,
  showUnfairMeansDialog,
  totalWarnings: warnings,
}: Props) => {
  if (!maxWarningCount) return null
  if (disabled) return null

  const testType = isOlympiad ? "Olympiad" : "Assessment"

  if (showSettingsOverlay) return <CameraOverlay />

  if (showTabUnfocusDialog)
    return (
      <Alert
        maxWarningCount={maxWarningCount}
        onClose={() => onClose("tabUnfocus")}
        isOpen={showTabUnfocusDialog}
        title="Window Switch Detected!"
        content={
          <>
            <Typography>
              Please avoid switching between tabs or different applications.
            </Typography>
            <Typography variant="strong" className="mt-2" color="critical.500">
              If you commit 3 window switch violations, your attempt will be
              disqualified!
            </Typography>
          </>
        }
        totalWarnings={warnings}
      />
    )

  if (showFullscreenOverlay)
    return (
      <FullscreenOverlay
        onContinue={() => onClose("fullscreen")}
        testType={testType}
      />
    )

  return (
    <>
      <UnFairMeansAlert
        maxWarningCount={maxWarningCount}
        isOpen={showUnfairMeansDialog}
        title="Unfair means detected!"
        totalWarnings={warnings}
        onClose={() => onClose("unfairMeans")}
      />
      <NoFaceDetectedAlert
        maxWarningCount={maxWarningCount}
        isOpen={showNoFaceDialog}
        title="Are you there?"
        totalWarnings={warnings}
        onClose={() => onClose("noFace")}
      />
    </>
  )
}

export default ProctoringAlerts
