import { Divider, IconButton, Typography } from "@suraasa/placebo-ui"
import { Certification } from "api/resources/profile/types"
import clsx from "clsx"
import { format } from "date-fns"
import twitterVerifiedBadge from "features/Profile/assets/twitter-verified-badge.png"
import { Edit } from "iconoir-react"
import { useContext } from "react"
import { createUseStyles } from "react-jss"

import ProfileContext from "../../../context"
import EvidenceList from "../EvidenceList"

const useStyles = createUseStyles(theme => ({
  details: {
    position: "relative",

    "& .edit-button": {
      position: "absolute",
      zIndex: "1",
      background: "white",

      [theme.breakpoints.down("sm")]: {
        right: "0px",
      },
      [theme.breakpoints.up("md")]: {
        pointerEvents: "none",
        right: "-30px",
        opacity: "0",
        transition: ".2s all",
      },
    },
    "&:hover .edit-button": {
      [theme.breakpoints.up("md")]: {
        opacity: "1",
        right: "0px",
        pointerEvents: "all",
      },
    },
  },
}))

const Certifications = ({
  onEdit,
}: {
  onEdit: (id: Certification["id"]) => void
}) => {
  const classes = useStyles()

  const {
    academics: {
      certifications: { data },
    },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      {data.map((certification, index) => (
        <div className="pt-2 ml-2" key={certification.id}>
          <div className={clsx(classes.details, "pb-2")}>
            {!isPublic && !certification.isVerified && (
              <IconButton
                className={clsx("edit-button")}
                color="black"
                onClick={() => onEdit(certification.id)}
              >
                <Edit />
              </IconButton>
            )}

            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">
                {certification.name}
              </Typography>

              {certification.isVerified && (
                <img
                  alt="✅"
                  height="24px"
                  src={twitterVerifiedBadge}
                  width="24px"
                />
              )}
            </div>

            <Typography
              className="mb-0.25"
              color="onSurface.600"
              variant="smallBody"
            >
              {certification.organisationName}
            </Typography>

            {certification.completionDate && (
              <Typography
                className="mb-0.5"
                color="onSurface.600"
                variant="smallBody"
              >
                {format(new Date(certification.completionDate), "MMMM yyyy")}
              </Typography>
            )}

            {certification.evidences && certification.evidences.length > 0 && (
              <EvidenceList data={certification.evidences} />
            )}
          </div>

          {index < data.length - 1 && (
            <Divider color="onSurface.200" weight="light" />
          )}
        </div>
      ))}
    </>
  )
}

export default Certifications
