import { Divider, Typography } from "@suraasa/placebo-ui"
import { SubmissionAttachment } from "api/resources/assignments/types"
import Attachment from "features/Assignments/Attachment"
import { countWords } from "features/Assignments/TextEditor/hooks/countWords"

import Paper from "../Paper"
import styles from "./Submission.module.css"

type Props = {
  content: string
  references?: string | null
  attachments: SubmissionAttachment[]
}
const Submission = ({ references, content, attachments }: Props) => {
  const contentWordCount = countWords(content)
  const referencesWordCount = countWords(references || "")

  const noContent = contentWordCount === 0 && attachments.length === 0

  return (
    <Paper>
      {noContent && (
        <Typography>No content was posted in this submission</Typography>
      )}

      {contentWordCount > 0 && (
        <Typography variant="body">
          <div
            className={styles.contentDiv}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Typography>
      )}

      {referencesWordCount > 0 && (
        <div className="mt-4">
          <Typography variant="title3" className="mb-1">
            References
          </Typography>
          <Typography variant="body">
            <div
              className={styles.contentDiv}
              dangerouslySetInnerHTML={{ __html: references! }}
            />
          </Typography>
        </div>
      )}

      {attachments.length > 0 && (
        <>
          <Divider color="onSurface.200" weight="light" className="my-2.75" />
          <div className="flex items-center justify-between sm:justify-start flex-wrap gap-1">
            {attachments.map((attachment, index) => (
              <Attachment key={index} file={attachment} />
            ))}
          </div>
        </>
      )}
    </Paper>
  )
}

export default Submission
