import { Avatar, Typography } from "@suraasa/placebo-ui"
import { UserDetails as UserDetailsType } from "api/resources/discussions/types"
import { formatDistance } from "date-fns"
import React from "react"
import { buildUserName } from "utils/helpers"

import DiscussionMenu from "../DiscussionMenu"

type Props = {
  discussionId: string
  datePublished?: string
  user: UserDetailsType
}

const UserDetails = ({ discussionId, user, datePublished }: Props) => {
  const fullName = buildUserName(user)

  const postedTime =
    datePublished &&
    formatDistance(new Date(datePublished), new Date(), {
      addSuffix: true,
    })

  return (
    <div className="flex justify-between">
      <div className="flex">
        <div className="me-2 sm:hidden block shrink-0">
          <Avatar color="one" size="sm" name={fullName} src={user.photo} />
        </div>
        <div className="flex">
          <div>
            <Typography variant="strong">{fullName}</Typography>
            <Typography
              className="mb-1.5"
              variant="smallBody"
              color="onSurface.500"
            >
              {`Posted ${
                postedTime && postedTime !== "less than a minute ago"
                  ? postedTime
                  : "recently"
              }`}
            </Typography>
          </div>
        </div>
      </div>
      <DiscussionMenu discussionId={discussionId} user={user} />
    </div>
  )
}

export default UserDetails
