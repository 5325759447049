import { Button, Theme, Tooltip, useMediaQuery } from "@suraasa/placebo-ui"
import { AssessmentQuestion } from "api/resources/assessments/types"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { ArrowLeft, ArrowRight, NavArrowDown, NavArrowUp } from "iconoir-react"
import React, { useEffect, useRef, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"

import QuestionPanel from "../QuestionPanel"

// import { GA } from "shared/utils/googleAnalytics"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.onSurface[800],
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
    },
  },
  color: {
    color: theme.colors.common.white[500],
  },
  dialogContent: {
    position: "relative",
  },
}))

const AssessmentFooter = ({
  onNext,
  onPrevious,
  onQuestionChange,
  questions,
  currentQuestion,
}: {
  onNext: () => void
  onPrevious: () => void
  onQuestionChange: (questionNumber: number) => void
  questions: AssessmentQuestion[]
  currentQuestion: AssessmentQuestion
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const [questionsTrayOpen, toggleQuestionsTrayOpen] = useState(false)

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const ref = useRef<HTMLDivElement>(null)

  /**
   * This useEffect is responsible for closing the questions panel if user clicks outside it
   */
  useEffect(() => {
    const container = ref.current
    const listener = function (event: any) {
      if (event && container)
        if (container.contains(event.target)) {
          // Clicked inside
        } else {
          // Clicked outside
          if (questionsTrayOpen) {
            toggleQuestionsTrayOpen(false)
          }
        }
    }
    if (container) {
      document.body.addEventListener("click", listener)
    }

    return () => {
      document.body.removeEventListener("click", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsTrayOpen])

  const isFirstQuestion = currentQuestion.questionNumber === 1
  const isLastQuestion = currentQuestion.questionNumber === questions.length

  if (isMdDown) {
    return (
      <>
        <SlidingSheet
          open={questionsTrayOpen}
          onClose={() => toggleQuestionsTrayOpen(false)}
          className="max-w-[900px]"
        >
          <QuestionPanel
            onClose={() => toggleQuestionsTrayOpen(false)}
            onQuestionChange={questionNumber => {
              toggleQuestionsTrayOpen(false)
              onQuestionChange(questionNumber)
            }}
            questions={questions}
            currentQuestion={currentQuestion}
          />
        </SlidingSheet>
        <div
          ref={ref}
          className={clsx(
            classes.container,
            "sm:flex sm:justify-between sm:items-center px-2 py-1"
          )}
        >
          {!isXsDown && (
            <Button
              // {...(questionsTrayOpen
              //   ? GA.trackElement("ito-assessment-questions-tray-open-btn", {
              //       feature: "ito",
              //       purpose: "attempt_assessment",
              //     })
              //   : {})}
              variant="text"
              className={clsx(classes.color)}
              onClick={() => toggleQuestionsTrayOpen(prev => !prev)}
            >
              <div className="grid grid-flow-col gap-0.5 items-center">
                {questionsTrayOpen ? <NavArrowDown /> : <NavArrowUp />}
                Questions Tray
              </div>
            </Button>
          )}

          <div className="grid grid-flow-col items-center sm:justify-end sm:gap-1 justify-between">
            <div>
              <Tooltip
                title="This is the first question"
                disabled={!isFirstQuestion}
              >
                <div>
                  <Button
                    size="sm"
                    startAdornment={!isXsDown && <ArrowLeft />}
                    onClick={onPrevious}
                    disabled={isFirstQuestion}
                  >
                    Previous
                  </Button>
                </div>
              </Tooltip>
            </div>
            {isXsDown && (
              <Button
                //   {...(questionsTrayOpen
                //     ? {}
                //     : GA.trackElement("ito-assessment-questions-tray-open-btn", {
                //         feature: "ito",
                //         purpose: "attempt_assessment",
                //       }))}
                variant="text"
                className={clsx(classes.color)}
                onClick={() => toggleQuestionsTrayOpen(prev => !prev)}
              >
                <div className="grid grid-flow-col gap-0.5 items-center">
                  {questionsTrayOpen ? <NavArrowDown /> : <NavArrowUp />}
                  Questions
                </div>
              </Button>
            )}
            <div>
              <Tooltip
                title="This is the last question"
                disabled={!isLastQuestion}
              >
                <div>
                  <Button
                    size="sm"
                    endAdornment={!isXsDown && <ArrowRight />}
                    onClick={onNext}
                    disabled={isLastQuestion}
                  >
                    Next
                  </Button>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      className={clsx(
        classes.container,
        "grid grid-flow-col items-center justify-end gap-1 px-2 py-1 lg:mb-0.5"
      )}
    >
      <Tooltip title="This is the first question" disabled={!isFirstQuestion}>
        <div>
          <Button
            size="sm"
            startAdornment={!isXsDown && <ArrowLeft />}
            onClick={onPrevious}
            disabled={isFirstQuestion}
          >
            Previous
          </Button>
        </div>
      </Tooltip>
      <Tooltip title="This is the last question" disabled={!isLastQuestion}>
        <div>
          <Button
            size="sm"
            endAdornment={!isXsDown && <ArrowRight />}
            onClick={onNext}
            disabled={isLastQuestion}
          >
            Next
          </Button>
        </div>
      </Tooltip>
    </div>
  )
}

export default AssessmentFooter
