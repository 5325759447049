import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Shop } from "iconoir-react"
import React from "react"
import { getPlatformURL } from "utils/helpers"
import { PropsWithClassName } from "utils/types"

import styles from "./ExploreCoursesOnStoreBar.module.css"

const ExploreCoursesOnStoreBar = ({ className }: PropsWithClassName) => {
  return (
    <a
      href={getPlatformURL("suraasa", "/store")}
      className={clsx(
        styles.root,
        "sm:py-3 px-3 py-2 flex items-center justify-between rounded-2xl",
        className
      )}
    >
      <Typography color="common.white.500" variant="body" className="me-1">
        Want to see what courses we offer? Check out our store!
      </Typography>
      <Shop className={clsx("p-1 shrink-0 hidden sm:block", styles.icon)} />
    </a>
  )
}

export default ExploreCoursesOnStoreBar
