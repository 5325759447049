import api from "api"
import FullPageLoading from "components/FullPageLoading"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { getAuthInfo } from "utils/helpers"
import { GA } from "utils/tracking/ga"

const GlobalAuthObserver = () => {
  const [authIsValid, setAuthIsValid] = useState<boolean>(false)

  useEffect(() => {
    GA.init()
  }, [])

  useEffect(() => {
    const checkAuth = async () => {
      const authInfo = getAuthInfo()
      try {
        if (authInfo) {
          const res = await api.users.verifyAccessToken({
            data: {
              token: authInfo.accessToken,
            },
            headers: {
              Authorization: null,
            },
          })

          setAuthIsValid(res.verified)
        }
      } catch (e) {
        console.log("API FAILED")
      }
    }

    if (getAuthInfo()) {
      checkAuth()
    } else {
      setAuthIsValid(true)
    }
  }, [])

  return (
    <>
      {!authIsValid && <FullPageLoading />}
      {authIsValid && <Outlet />}
    </>
  )
}

export default GlobalAuthObserver
