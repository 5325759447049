import { Button, IconButton, Tooltip, Typography } from "@suraasa/placebo-ui"
import FacebookIcon from "assets/icons/facebook-icon.svg"
import LinkedinIcon from "assets/icons/linkedin-icon.svg"
import TelegramIcon from "assets/icons/telegram-icon.svg"
import WhatsAppIcon from "assets/icons/whatsapp-icon.svg"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { Mail, Xmark } from "iconoir-react"
import { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import styles from "./ShareSheet.module.css"

type Platforms = "mail" | "whatsapp" | "telegram" | "linkedin" | "facebook"
type Props<T extends Platforms> = {
  open: boolean
  handleClose: () => void
  data: {
    url: string
    text?: string
    email?: { subject: string }
  }
  platforms: T[]
}

export function popupWindow(
  windowUrl: string,
  w = 600,
  h = 500,
  windowName = "Suraasa"
) {
  if (!window.top) {
    return
  }
  const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
  const x = window.top.outerWidth / 2 + window.top.screenX - w / 1.5
  return window.open(
    windowUrl,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
}

const ShareCard = <T extends Platforms>({
  handleClose,
  data: { url, text, email },
  platforms,
}: Omit<Props<T>, "open">): JSX.Element => {
  const encodedText = encodeURIComponent(text || url)

  const [copied, setCopied] = useState(false)

  const mailText = encodedText.replaceAll("\n", "%0D%0A")

  const LINKEDIN_URL = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`

  const FACEBOOK_URL = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedText}`

  const WHATSAPP_URL = `https://api.whatsapp.com/send?text=${encodedText}`

  const TELEGRAM_URL = `https://telegram.me/share/url?url=${encodedText}`

  const EMAIL_URL = `mailto:?subject=${email?.subject}&body=${mailText}`

  function copyToClipboard() {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  const platformDetails: Record<
    Platforms,
    {
      displayText: string
      url: string
      imgSource: string
    }
  > = {
    whatsapp: {
      displayText: "WhatsApp",
      url: WHATSAPP_URL,
      imgSource: WhatsAppIcon,
    },
    telegram: {
      displayText: "Telegram",
      url: TELEGRAM_URL,
      imgSource: TelegramIcon,
    },
    linkedin: {
      displayText: "LinkedIn",
      url: LINKEDIN_URL,
      imgSource: LinkedinIcon,
    },
    facebook: {
      displayText: "Facebook",
      url: FACEBOOK_URL,
      imgSource: FacebookIcon,
    },
    mail: {
      displayText: "Mail",
      url: EMAIL_URL,
      imgSource: "s",
    },
  }

  const getPlatformIcon = (platform: Platforms) => (
    <Tooltip
      key={platform}
      color="light"
      direction="bottom"
      title={platformDetails[platform].displayText}
    >
      <IconButton
        className={clsx(
          "mr-2 flex items-center justify-center border border-onSurface-300 p-1 sm:mr-4 sm:p-2",
          styles.socialIcon
        )}
        onClick={() => popupWindow(platformDetails[platform].url, 650, 650)}
      >
        {platform === "mail" ? (
          <Mail height={36} width={36} />
        ) : (
          <img alt={platform} src={platformDetails[platform].imgSource} />
        )}
      </IconButton>
    </Tooltip>
  )
  return (
    <div>
      <div className="mb-2 flex w-full items-center justify-between sm:mb-4">
        <Typography display="block" variant="title1">
          Share via
        </Typography>
        <IconButton
          aria-label="close"
          style={{
            height: 40,
            width: 40,
          }}
          color="black"
          size="sm"
          onClick={() => handleClose()}
        >
          <Xmark height="24px" width="24px" />
        </IconButton>
      </div>

      <div className="mb-3 flex sm:mb-6">
        {platforms.map(item => getPlatformIcon(item))}
      </div>

      <Typography className="mb-1" variant="subtitle2">
        or copy & share
      </Typography>
      <div
        className={clsx(
          "copyLink flex max-h-[50vh] items-baseline justify-between overflow-y-auto border border-onSurface-300 px-2 py-1.25",
          styles.copyLink
        )}
      >
        <Typography style={{ whiteSpace: "pre-wrap" }} variant="body">
          {text || url}
        </Typography>
        <CopyToClipboard text={text || url} onCopy={() => copyToClipboard()}>
          <Button variant="text">{copied ? "Copied!" : "Copy"}</Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}

const ShareSheet = <T extends Platforms>({
  open,
  handleClose,
  data,
  platforms = ["whatsapp", "facebook", "linkedin", "telegram"],
}: Omit<Props<T>, "platforms"> & { platforms?: Platforms[] }): JSX.Element => {
  if (platforms.includes("mail") && data.email?.subject == null) {
    throw new Error("Please provide email subject")
  }

  return (
    <SlidingSheet
      containerClassName="grid grid-cols-12"
      className="col-span-12 col-start-1 w-full px-2 py-2.5 sm:col-span-10 sm:col-start-2 sm:px-4 md:col-span-8 md:col-start-3"
      open={open}
      onClose={() => handleClose()}
    >
      <ShareCard data={data} platforms={platforms} handleClose={handleClose} />
    </SlidingSheet>
  )
}

export default ShareSheet
