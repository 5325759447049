import MichelleFernandez from "./assets/michelle-fernandez.png"
import MuktaZite from "./assets/mukta-zite.png"
import NeneIbezim from "./assets/nene-ibezim.png"
import PriyaRaushan from "./assets/priya-raushan.png"
import RijuSingh from "./assets/riju-singh.png"

export const testimonials = [
  {
    profile: {
      name: "Riju Singh",
      profession: "Aspiring Teacher, India",
      picture: RijuSingh,
    },
    testimony:
      "The Olympiad's personalised report was incredibly helpful. It provided a clear understanding of my performance and where I stand not only in India but also on an international level. ",
  },
  {
    profile: {
      name: "Nene Ibezim",
      profession: "Teacher Coach, Nigeria",
      picture: NeneIbezim,
    },
    testimony:
      "The personalised report left me amazed by its level of detail & feedback. It has inspired me to be intentional about my growth. It gave me a chance to track my progress as a Teacher. ",
  },
  {
    profile: {
      name: "Priya Raushan",
      profession: "School Teacher, UAE",
      picture: PriyaRaushan,
    },
    testimony:
      "When I was at the airport, I eagerly downloaded my report. It stated I was in the top 1 percentile. I can’t even express how happy it made me.",
  },
  {
    profile: {
      name: "Mukta Zite",
      profession: "PRT Teacher, India",
      picture: MuktaZite,
    },
    testimony:
      "The assessment challenged me to think beyond theoretical knowledge. Moreover, the report given was my personal favourite. It gave me insight into how a teacher actually should be.",
  },
  {
    profile: {
      name: "Michelle Fernandez",
      profession: "English Teacher, India",
      picture: MichelleFernandez,
    },
    testimony:
      "The personalised report was insightful. I got detailed feedback and the opportunity to gauge my teaching abilities through the report. It was great.",
  },
]
