import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { urls } from "./urls"

export default {
  createCourseFeedback: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.create(), data)
      return formatSuccessResponse(res)
    } catch (error) {
      return formatErrorResponse(error)
    }
  },
}
