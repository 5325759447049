import { Dialog, DialogContent, Theme, Typography } from "@suraasa/placebo-ui"
import { addSeconds } from "date-fns"
import { CheckCircle } from "iconoir-react"
import React, { useEffect } from "react"
import { useTheme } from "react-jss"
import { useTimer } from "react-timer-hook"

import { closeWindow } from "../../helpers"

type Props = {
  open: boolean
  parentSelector?: () => void
}

const COUNTDOWN_TIMER = 5

const SubmittedSuccessfullyDialog = (props: Props) => {
  const theme = useTheme<Theme>()
  const { seconds, start } = useTimer({
    onExpire: () => closeWindow(0),
    autoStart: false,
    expiryTimestamp: new Date(addSeconds(new Date(), COUNTDOWN_TIMER)),
  })

  useEffect(() => {
    if (props.open) start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <Dialog width="sm" open={props.open} parentSelector={props.parentSelector}>
      <DialogContent className="grid m-1 content-start">
        <div className="grid grid-flow-col gap-2 items-center justify-start mb-4 mt-1">
          <CheckCircle
            height={30}
            width={30}
            style={{
              backgroundColor: theme.colors.success[500],
              borderRadius: "50%",
              color: theme.colors.common.white[500],
            }}
          />
          <Typography variant="title3">Successfully Submitted!</Typography>
        </div>
        <Typography className="mb-3">
          Congratulations! You have successfully submitted your attempt for this
          assessment.
        </Typography>
        <Typography variant="smallBody" color="onSurface.500">
          Note: This window will automatically close in {seconds} seconds.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default SubmittedSuccessfullyDialog
