import { captureException } from "@sentry/react"
import { toast } from "@suraasa/placebo-ui"
import api from "api"
import {
  AssessmentQuestion,
  Attempt,
  AttemptStatus,
  CaseStudyQuestion,
  Question,
  QuestionStatus,
  UserResponse,
} from "api/resources/assessments/types"
import { APIError } from "api/utils"
import { closeWindow } from "features/ITO/Assessments/helpers"
import { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"

import { getQuestionStatus, isCaseStudy } from "../helpers"
// import { GA } from "shared/utils/googleAnalytics"

export function useAssessment({
  assessmentId,
  onInvalidAttempt,
}: {
  onInvalidAttempt?: (reason: string) => void
  assessmentId: string
}) {
  const [questionBank, setQuestionBank] = useState<AssessmentQuestion[]>([])
  const [attempt, setAttempt] = useState<Attempt>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [endTime, setEndTime] = useState<string | null>(null)
  const [startingBeforeTime, setStartingBeforeTime] = useState(false)

  const handleInvalidAttempt = (reason?: string) => {
    if (reason && onInvalidAttempt) {
      onInvalidAttempt(reason)
    } else {
      closeWindow(3000)
    }
  }

  const getAssessmentData = async (attemptId: string | null) => {
    if (!attemptId) return
    try {
      const res = await api.assessments.listQuestions({
        urlParams: { attemptId },
      })
      if ("autoSubmitted" in res) {
        handleInvalidAttempt("Assessment already submitted.")
        return
      }
      if ("questions" in res) {
        if (res.questions.length === 0) {
          captureException(
            new Error("Assessment questions length 0"),
            scope => {
              scope.setExtras({
                attemptId,
              })
              return scope
            }
          )
          toast.error("We're having trouble getting the questions")
          handleInvalidAttempt()
        }
        setQuestionBank(res.questions)
        setEndTime(res.endTime)
      }
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err)
        handleInvalidAttempt()
      }
    }
  }

  const getAttempt = async () => {
    try {
      const data = await api.assessments.getAttempt({
        urlParams: { assessmentId },
      })
      setAttempt(data)
      if (
        data.assessmentSchedule &&
        data.assessmentSchedule.startTime &&
        new Date() < new Date(data.assessmentSchedule.startTime)
      ) {
        setStartingBeforeTime(true)
        return
      }
      if (data.status === AttemptStatus.NOT_STARTED) updateAttempt(data.uuid)
      else getAssessmentData(data.uuid)
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err)
        handleInvalidAttempt()
      }
    }
  }

  const updateAttempt = async (attemptId: string | null) => {
    if (!attemptId) return
    try {
      await api.assessments.updateAttempt({
        urlParams: { attemptId },
        data: { status: AttemptStatus.IN_PROGRESS },
      })
      getAssessmentData(attemptId)
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err.errors)
      }
    }
  }

  const submit = async ({
    autoSubmitReason,
  }: {
    autoSubmitReason?: string
  }) => {
    // For testing, don't remove
    // await new Promise(resolve => setTimeout(resolve, 3000))
    // setIsSubmitting(false)
    // return true
    if (attempt && attempt.uuid) {
      setIsSubmitting(true)
      try {
        await api.assessments.updateAttempt({
          urlParams: {
            attemptId: attempt.uuid,
          },
          data: {
            status: AttemptStatus.COMPLETED,
          },
        })
        //   const eventData = options?.autoSubmitReason
        //     ? {
        //         reason: options?.autoSubmitReason,
        //         mode: "auto",
        //       }
        //     : {}
        //   GA.trackEvent("ito_assessment_submitted", {
        //     assessment_id: attempt?.assessment.id,
        //     assessment_type: attempt?.assessment.assessmentType,
        //     mode: "self",
        //     ...eventData,
        //   })

        // Refresh the opener's state
        if (window.opener) {
          window.opener.location.reload(true)
        }
        setIsSubmitting(false)
        return true
      } catch (err) {
        if (err instanceof APIError) {
          setIsSubmitting(false)
          handleErrors(err)
          return false
        }
      }
    }
    return false
  }

  const toggleMarkForReview = (currentQuestion: AssessmentQuestion) => {
    function updateQuestionStatus(
      question: Question,
      caseStudyId?: string
    ): Question {
      const currentStatus = question.status
      const isAlreadyMarkedForReview =
        currentStatus == QuestionStatus.MARKED_FOR_REVIEW
      const newStatus = isAlreadyMarkedForReview
        ? getQuestionStatus(question, {
            skipMarkedForReviewCheck: isAlreadyMarkedForReview,
          })
        : QuestionStatus.MARKED_FOR_REVIEW

      question.status = newStatus

      saveAnswerOnBackend(question, caseStudyId)

      return question
    }

    if (isCaseStudy(currentQuestion)) {
      const firstCaseStudyQuestion = currentQuestion.questions[0]
      const updatedQuestion = updateQuestionStatus(
        firstCaseStudyQuestion,
        currentQuestion.id
      )

      setQuestionBank(qb =>
        qb.map(q => {
          if (q.id === currentQuestion.id) {
            currentQuestion.questions = currentQuestion.questions.map(e => {
              if (e.id === firstCaseStudyQuestion.id) {
                return updatedQuestion
              }
              return e
            })
            return currentQuestion
          }
          return q
        })
      )
      return
    }

    const updatedQuestion = updateQuestionStatus(currentQuestion)
    setQuestionBank(qb =>
      qb.map(q => (q.id === currentQuestion.id ? updatedQuestion : q))
    )
    return
  }

  const markAnswer = (
    question: Question,
    newResponse: UserResponse,
    options?: { caseStudyId?: string }
  ) => {
    if (newResponse.every(element => element == null)) newResponse = []

    question.response = newResponse
    question.status = getQuestionStatus(question)

    if (options?.caseStudyId) {
      const caseStudyRoot = questionBank.find(
        q => q.id === options.caseStudyId
      ) as CaseStudyQuestion

      caseStudyRoot.questions = caseStudyRoot.questions.map(q => {
        if (q.id === question.id) {
          return question
        }
        return q
      })

      setQuestionBank(qb =>
        qb.map(q => {
          if (q.id === options.caseStudyId) {
            return caseStudyRoot
          }
          return q
        })
      )
    } else
      setQuestionBank(qb =>
        qb.map(q => {
          if (q.id === question.id) {
            return question
          }
          return q
        })
      )

    saveAnswerOnBackend(question, options?.caseStudyId)
  }

  const saveAnswerOnBackend = async (
    question: Question,
    caseStudyId?: string
  ) => {
    try {
      const res = await api.assessments.markAnswer({
        urlParams: {
          questionId: question.tempAssessmentPerformanceId,
        },
        data: {
          status: question.status,
          response: question.response ?? [],
          caseStudyId: caseStudyId,
        },
      })
      if (res && "autoSubmitted" in res) {
        toast.error("Assessment already submitted.")
        handleInvalidAttempt()
        return
      }
      return res
    } catch (err) {
      if (err instanceof APIError) {
        if (err.statusCode === 404) {
          handleInvalidAttempt()
        }
      }
    }
  }

  useEffect(() => {
    getAttempt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    startingBeforeTime,
    isSubmitting,
    endTime,
    questionBank,
    toggleMarkForReview,
    submit,
    markAnswer,
    attempt,
  }
}
