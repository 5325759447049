import { Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import React from "react"
import { createUseStyles } from "react-jss"
import useCurrentCountry from "utils/hooks/useCurrentCountry"

import Email from "./assets/email.svg"
import Whatsapp from "./assets/whatsapp.svg"
import Card from "./Card"
import { phoneNumbers } from "./data"

const useStyles = createUseStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto",
    alignItems: "center",
    gap: "40px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto",
    },
  },
  gradientBackground: {
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: theme.spacing(8),
    backgroundImage:
      "radial-gradient(at 0% 200%, rgba(105, 219, 123, 0.25) 0, transparent 59%), radial-gradient(at 20% 200%, rgba(235, 150, 77, 0.25) 0, transparent 55%),radial-gradient(at 35% 200%, rgba(235, 72, 63, 0.25) 0, transparent 55%),radial-gradient(at 70% 250%, rgba(234, 89, 202, 0.5) 0, transparent 55%),radial-gradient(at 85% 200%, rgba(52, 109, 242, 0.25) 0, transparent 55%),radial-gradient(at 100% 250%, rgba(116, 63, 245, 0.5) 0, transparent 55%)",
  },
}))

const ContactUs = ({ hideWhatsapp = false }) => {
  const country = useCurrentCountry()
  const classes = useStyles()

  const getPhoneNumber = () => {
    if (!country) return phoneNumbers.IN

    const number = phoneNumbers[country.isoCode as keyof typeof phoneNumbers]

    if (!number) {
      return phoneNumbers.IN
    }
    return number
  }

  return (
    <div className={classes.gradientBackground}>
      <Container>
        <div className={clsx("mb-6", classes.container)}>
          <div className="md:text-start sm:text-center text-start">
            <SwitzerTypography
              className="!text-ito-midnight font-bold"
              variant="title1"
            >
              Contact Us
            </SwitzerTypography>
            <Typography variant="strong">
              Need help? We’re here for you.
            </Typography>
          </div>
          <div className="flex flex-col gap-2 md:flex-col sm:flex-row">
            {!hideWhatsapp && (
              <Card
                body={`+${getPhoneNumber()}`}
                href={`https://wa.me/${getPhoneNumber()}`}
                icon={Whatsapp}
                title="WhatsApp (Instant: 24x7)"
                highlight
              />
            )}

            <Card
              body="care@teachersolympiad.com"
              href="mailto:care@teachersolympiad.com"
              icon={Email}
              title="Email"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ContactUs
