import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getSiriusURL = getServiceURL(SERVICE_PREFIX.sirius)

export const urls = validateUrls({
  applicationOverview: () =>
    getSiriusURL("/v1/applications/overview/applicant/"),
})
