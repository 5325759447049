import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getHeliosUrl = getServiceURL(SERVICE_PREFIX.helios)
const getNormaUrl = getServiceURL(SERVICE_PREFIX.norma)

export const urls = validateUrls({
  getReferralOverview: () => getHeliosUrl("/v1/expenses/overview/"),
  claims: () => getHeliosUrl("/v1/claims/"),
  referrals: () => getNormaUrl("/v1/registrations/referrals/"),
})
