import { Typography } from "@suraasa/placebo-ui"
import React from "react"

import RemoveDialogWrapper from "../RemoveDialog"
type Props = {
  onClose: () => void
  loading?: boolean
  open: boolean
  onRemove: () => void
  listCount: number
  subjectName?: string
}

const RemoveDialog = ({
  onClose,
  loading,
  open,
  onRemove,
  listCount,
  subjectName,
}: Props) => {
  return (
    <RemoveDialogWrapper
      handleClose={onClose}
      loading={loading}
      open={open}
      title="Remove Subject"
      keepScrollLocked
      onRemove={onRemove}
    >
      <Typography variant="smallBody">
        Are you sure you want to remove&nbsp;
        <b>{subjectName}</b>
        &nbsp;subject from your profile?
        {listCount === 1 && (
          <>
            <br />
            <br />
            By doing so, you will lose your access to <b>Jobs Platform</b>. To
            get back your access you’ll need to have at least one subject in
            your profile.
          </>
        )}
      </Typography>
    </RemoveDialogWrapper>
  )
}

export default RemoveDialog
