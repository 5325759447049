import {
  AssessmentAnswer,
  AssessmentQuestion,
  CaseStudyQuestion,
  QUESTION_TYPE,
  QUESTION_TYPE_OLD,
  UnfetchedQuestion,
} from "features/Assessments/Result/types"
import Webcam from "react-webcam"
import { pluralize } from "utils/helpers"

export const closeWindow = (timeout = 0) => {
  // Close window after `timeout` seconds
  setTimeout(() => window.close(), timeout)
}

export const isCaseStudy = (
  question: AssessmentQuestion | UnfetchedQuestion
): question is CaseStudyQuestion => {
  if ("questionType" in question)
    return isQuestionOfType(question, "CASE_STUDY")
  return false
}

export const isQuestionOfType = (
  question: AssessmentAnswer | AssessmentQuestion,
  type: keyof typeof QUESTION_TYPE
) =>
  [QUESTION_TYPE[type], QUESTION_TYPE_OLD[type]].includes(question.questionType)

export const getQuestionTypeString = (
  type: QUESTION_TYPE | QUESTION_TYPE_OLD
) => {
  switch (type) {
    case QUESTION_TYPE.SINGLE_CORRECT:
    case QUESTION_TYPE_OLD.SINGLE_CORRECT:
      return "SINGLE CORRECT"

    case QUESTION_TYPE.MULTIPLE_CORRECT:
    case QUESTION_TYPE_OLD.MULTIPLE_CORRECT:
      return "MULTIPLE CORRECT"

    case QUESTION_TYPE.FILL_IN_THE_BLANKS:
    case QUESTION_TYPE_OLD.FILL_IN_THE_BLANKS:
      return "FILL IN THE BLANKS"

    case QUESTION_TYPE.CASE_STUDY:
    case QUESTION_TYPE_OLD.CASE_STUDY:
      return "CASE STUDY"
  }
}

/**
 * This is done to just fix a typescript error saying:
 * `Property 'webkitRequestFullscreen' does not exist on type 'HTMLElement'. Did you mean 'requestFullscreen'`
 */
type RequestFullscreen = typeof document.documentElement.requestFullscreen
declare global {
  interface HTMLElement {
    webkitRequestFullscreen: RequestFullscreen
    webkitRequestFullScreen: RequestFullscreen
    mozRequestFullScreen: RequestFullscreen
    msRequestFullscreen: RequestFullscreen
  }
}
export const triggerFullscreen = () => {
  const methods = [
    "requestFullscreen",
    "webkitRequestFullscreen",
    "webkitRequestFullScreen",
    "mozRequestFullScreen",
    "msRequestFullscreen",
  ] as const

  const ref = document.body
  if (ref) {
    for (const name of methods) {
      if (name in ref) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        ref[name]()?.catch(() => {
          // console.error(err)
        })
        ref.style.overflowY = "auto"
        break
      }
    }
  }
}

export const playVideo = (
  videoRef: React.RefObject<Webcam> | React.RefObject<HTMLVideoElement>
) => {
  navigator.mediaDevices
    .getUserMedia({
      video: { facingMode: "user" },
    })
    .then(stream => {
      let video
      if (!(videoRef.current instanceof HTMLVideoElement)) {
        video = videoRef.current?.video
      } else video = videoRef.current

      if (video) {
        video.srcObject = stream
        const playPromise = video.play()
        // handling video play promise is necessary, do not remove code
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // autoplay began
              // Show playing UI
            })
            .catch(() => {
              // Auto-play was prevented
              // Show paused UI.
            })
        }
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export const getCalculateDuration = (item: string) => {
  const numberArray = item.split(/[ :]+/).map(Number)

  let result = ""
  if (numberArray.length < 0) return ""
  if (numberArray.length === 4) {
    result += numberArray[0] ? `${pluralize("day", numberArray[0])} ` : ""
    result += numberArray[1] ? `${pluralize("hour", numberArray[1])} ` : ""
    result += numberArray[2] ? `${pluralize("minute", numberArray[2])} ` : ""
    result += numberArray[3] ? `${pluralize("second", numberArray[3])} ` : ""
  } else {
    result += numberArray[0] ? `${pluralize("hour", numberArray[0])} ` : ""
    result += numberArray[1] ? `${pluralize("minute", numberArray[1])} ` : ""
    result += numberArray[2] ? `${pluralize("second", numberArray[2])} ` : ""
  }

  return result
}

export const getWarningGradient = (offensePercentage: number) => {
  const gradient =
    offensePercentage <= 25
      ? `linear-gradient(to right,#F2D16A,#F0C868)`
      : offensePercentage <= 50
      ? "linear-gradient(to right,#F2D16A,#EAAF60)"
      : offensePercentage <= 75
      ? "linear-gradient(to right,#F2D16A,#E08553)"
      : "linear-gradient(to right,#F2D16A,#CE3E3E)"
  return gradient
}

export const getOffencePercentage = (
  incurredWarnings: number | null,
  maxWarnings: number
) => {
  if (!incurredWarnings) return 0
  return Math.min(100, (incurredWarnings / maxWarnings) * 100)
}
