import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

// import { TRACKING_DATA } from "utils/googleAnalytics"

const useStyles = createUseStyles({
  cardBody: {
    wordBreak: "break-word",
  },
})

type CardProps = {
  title: string
  body: string
  href: string
  highlight?: boolean
  icon: any
}

const Card = ({ title, body, icon, highlight = false, href }: CardProps) => {
  const classes = useStyles()

  return (
    <a
      className={clsx(
        "p-2 flex gap-2 cursor-pointer rounded-lg shadow-[0px_6px_15px_rgba(0,0,0,0.05)] text-ito-midnight no-underline w-full",
        {
          "relative bg-ito-mint-100 border-2 border-ito-mint-600": highlight,
          "bg-white": !highlight,
        }
      )}
      data-medium={title.toLowerCase()}
      href={href}
    >
      <div>
        <img alt="icon" src={icon} />
      </div>
      <div>
        <Typography color="onSurface.500" variant="smallBody">
          {title}
        </Typography>
        <Typography className={classes.cardBody} variant="strongSmallBody">
          {body}
        </Typography>
      </div>
      {/* {highlight && (
        <div className="absolute right-0 md:-top-5 md:-right-12 md:left-auto sm:-top-6 sm:-left-1 sm:right-auto -top-6">
          <img alt="chat-with-me" src={getChatIcon()} />
        </div>
      )} */}
    </a>
  )
}

export default Card
