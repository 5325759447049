import { CircularProgress, Container, Typography } from "@suraasa/placebo-ui"
import React from "react"

type Props = {
  logo?: string
  message?: string
}

export default function FullPageLoading({ message = "" }: Props) {
  return (
    <Container className="h-[100vh] flex flex-col justify-center">
      <div className="flex justify-center">
        <div className="text-center">
          <CircularProgress />
          {message && (
            <Typography variant="largeBody" textAlign="center" className="mt-1">
              {message}
            </Typography>
          )}
        </div>
      </div>
    </Container>
  )
}
