import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { ArrowSeparateVertical, Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { BROWSER_STORAGE_KEYS, UpdateProfileEnum } from "utils/constants"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"
import DraggableItem from "../ReOrderingFeatureOnboarding/DraggableItem"
import useDraggable from "../ReOrderingFeatureOnboarding/DraggableItem/useDraggable"
import Instructions from "../ReOrderingFeatureOnboarding/Instructions"
import AddDialog from "./AddDialog"
import RemoveDialog from "./RemoveDialog"

const { reorderOnboarding: updateProfileDialog } = BROWSER_STORAGE_KEYS

const WorkLocationDialog = ({
  open,
  onRequestClose,
  customAction,
}: Pick<DialogProps, "open" | "onRequestClose"> &
  Pick<DialogTitleProps, "onBack"> & { customAction?: JSX.Element }) => {
  const {
    workLocationInterest: { data, add, remove, set },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const [changePriority, setChangePriority] = useState(false)
  const [openAddWorkLocationDialog, setOpenAddWorkLocationDialog] =
    useState(false)
  const [workLocationToDelete, setWorkLocationToDelete] = useState<
    number | null
  >(null)

  const locationList = useDraggable({ data })

  const { mutate: handleRemove, isLoading: loading } = useMutation({
    mutationFn: () =>
      api.profile.locations.delete({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { id: workLocationToDelete! },
      }),
    onSuccess: () => {
      if (workLocationToDelete) {
        remove(workLocationToDelete)
        toast.success("Removed successfully.")
        setWorkLocationToDelete(null)
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })
  const { mutate: handleUpdateSequence, isLoading: loadingSequence } =
    useMutation({
      mutationFn: (data: { id: number; sequence: number }[]) =>
        api.profile.locations.updateSequence({ data: { workLocations: data } }),
      onSuccess: () => {
        toast.success("Successfully Updated")
        const newData = locationList.processData(false)
        if (newData && set) {
          set(newData)
        }
      },
      onError: err => {
        if (err instanceof APIError) {
          handleErrors(err)
        }
      },
    })

  useEffect(() => {
    if (open) setOpenAddWorkLocationDialog(hasNoItems)
  }, [open, hasNoItems])

  function selectedDeleteLocation() {
    return data.find(item => item.id === workLocationToDelete)
  }

  const changePriorityFnc = () => {
    setChangePriority(true)

    const updateStatus: string[] = JSON.parse(
      localStorage.getItem(updateProfileDialog) ?? "[]"
    )

    if (!updateStatus.includes(UpdateProfileEnum.LOCATIONS)) {
      updateStatus.push(UpdateProfileEnum.LOCATIONS)
    }

    localStorage.setItem(updateProfileDialog, JSON.stringify(updateStatus))
  }
  return (
    <Dialog
      open={open}
      width={518}
      onAfterClose={() => {
        setOpenAddWorkLocationDialog(false)
        setChangePriority(false)
        locationList.reset()
      }}
      onRequestClose={onRequestClose}
    >
      {openAddWorkLocationDialog || hasNoItems ? (
        <AddDialog
          open={openAddWorkLocationDialog}
          onAdd={add}
          onBack={
            hasNoItems ? undefined : () => setOpenAddWorkLocationDialog(false)
          }
          onRequestClose={onRequestClose}
        />
      ) : (
        <>
          <RemoveDialog
            onClose={() => setWorkLocationToDelete(null)}
            open={Boolean(workLocationToDelete)}
            loading={loading}
            onRemove={handleRemove}
            stateName={selectedDeleteLocation()?.state.name}
            countryName={selectedDeleteLocation()?.country.name}
            listCount={data.length}
          />
          <DialogTitle>Work Locations Interests</DialogTitle>
          <DialogContent className="flex flex-col !p-0">
            <div className="flex items-center justify-between border-b border-onSurface-200 px-1.5 py-2">
              <Button
                startAdornment={<Plus />}
                variant="text"
                onClick={() => setOpenAddWorkLocationDialog(true)}
              >
                Add New Locations
              </Button>
              <IconButton
                onClick={changePriorityFnc}
                variant={changePriority ? "filled" : "plain"}
                size="xs"
                className="!grid sm:!hidden [&>svg]:h-2.5 [&>svg]:w-2.5"
              >
                <ArrowSeparateVertical />
              </IconButton>

              <Button
                startAdornment={<ArrowSeparateVertical />}
                variant="text"
                className="!hidden sm:!grid"
                onClick={changePriorityFnc}
              >
                Change Priority
              </Button>
            </div>
            {changePriority && (
              <Instructions
                type="locations"
                title="Change Work Location Interests Priority"
                description="Drag your main preferred work location to the top to make it your primary work location interest. Similarly, your second location will be marked as your secondary work location interest."
                disclaimer="We match your profile with the right schools based on your primary and secondary work location interests."
              />
            )}
            <div>
              {locationList.data.length > 0 &&
                locationList.data.map((item, index) => (
                  <DraggableItem
                    onDelete={() => {
                      setWorkLocationToDelete(item.id)
                    }}
                    className={clsx("[&>div]:mx-3 [&>div]:py-1.5", {
                      "[&>div]:border-b [&>div]:border-onSurface-200":
                        locationList.data.length - 1 !== index,
                    })}
                    isDraggable={changePriority}
                    index={index}
                    key={item.id}
                    onDragStart={locationList.onDragStart}
                    onDrop={locationList.onDrop}
                    onDragOver={locationList.onDragOver}
                  >
                    <div className="mr-2 flex items-center">
                      <img
                        alt=""
                        className="mr-2 h-2.5 w-3.5"
                        src={`/assets/flags/${item.country.isoCode}.svg`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = "/assets/flags/placeholder.png"
                        }}
                      />
                      <div>
                        <Typography display="inline" variant="strong">
                          {item.state.name},{" "}
                        </Typography>
                        <Typography display="inline" variant="body">
                          {item.country.name}
                        </Typography>
                      </div>
                    </div>
                  </DraggableItem>
                ))}
            </div>
          </DialogContent>

          <DialogFooter>
            {changePriority && (
              <div className="flex justify-end">
                <Button
                  variant="outlined"
                  color="critical"
                  size="sm"
                  onClick={() => {
                    locationList.reset()
                    setChangePriority(false)
                  }}
                  className="mr-1"
                >
                  Discard
                </Button>
                <Button
                  onClick={() => {
                    const newData = locationList.processData(true)
                    handleUpdateSequence(newData)
                    setChangePriority(false)
                  }}
                  loading={loadingSequence}
                  size="sm"
                >
                  Save
                </Button>
              </div>
            )}
            {!changePriority && !customAction && (
              <Button
                onClick={onRequestClose}
                className="ml-auto !block"
                size="sm"
                variant="outlined"
                color="secondary"
              >
                Close
              </Button>
            )}
            {!changePriority && customAction && customAction}
          </DialogFooter>
        </>
      )}
    </Dialog>
  )
}

export default WorkLocationDialog
