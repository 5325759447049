import { Button, Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import TeachersMob from "assets/Ito/68KTeachersMob.svg"
import TeachersWeb from "assets/Ito/68KTeachersWeb.svg"
import CurvedLavenderLine from "assets/Ito/CurvedLavenderLine.svg"
import CurvedLine from "assets/Ito/CurvedLine.svg"
import GridSquare from "assets/Ito/GridSquare.svg"
import ItoBgWeb from "assets/Ito/ItoLogoBg.svg"
import AsyncBuilder from "components/AsyncBuilder"
import { isAfter } from "date-fns"
import DynamicContainer from "features/Home/DynamicContainer"
import GradientButton from "features/ITO/components/GradientButton"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import { context } from "global/Context/context"
import React, { useContext } from "react"
import { useTheme } from "react-jss"
import { Link } from "react-router-dom"
import routes from "routes"
import { generateAuthCode, getPlatformURL } from "utils/helpers"

const ITORegistrationBanner = () => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))

  const {
    itoParticipationDetails: {
      registrationStatus,
      waitlistStatus,
      itoRegistrationDetails,
      timeline,
    },
  } = useContext(context)

  const registrationEndedDate = timeline?.data?.find(
    x => x.slug === "registrations-end-2023"
  )?.date

  if (!registrationEndedDate) {
    return null
  }

  // Don't need to show ITO card on the dashboard after registration period ends.
  if (
    isAfter(new Date(), new Date(registrationEndedDate)) &&
    !itoRegistrationDetails?.registrationFeePaid
  )
    return null

  const [copy, ...actions] = ((): [
    JSX.Element,
    ...{
      label: string
      href: string
      gradient: boolean
    }[]
  ] => {
    const registerLink = getPlatformURL(
      "suraasa",
      "/international-teachers-olympiad/register"
    )
    const learnMoreLink = getPlatformURL(
      "suraasa",
      "/international-teachers-olympiad/"
    )

    if (registrationStatus === "completed") {
      return [
        <span key="">
          <span className="text-violet-600">Congratulations!</span> You&apos;
          registered for the International Teachers&apos; Olympiad
        </span>,
        {
          label: "View dashboard",
          href: routes.ito,
          gradient: true,
        },
      ]
    }

    if (registrationStatus === "pending") {
      return [
        <span key="">
          Looks like your Registration is pending. Complete Now.
        </span>,
        {
          label: "Complete Registration",
          href: registerLink,
          gradient: true,
        },
        {
          label: "Learn More",
          href: learnMoreLink,
          gradient: false,
        },
      ]
    }

    // After 15th August 2023, show "Register Now"
    if (
      isAfter(new Date(), new Date(2023, 7, 15)) &&
      registrationStatus === "notStarted"
    ) {
      return [
        <span key="">
          Register for the largest multinational teachers’ olympiad!
        </span>,
        {
          label: "Register Now",
          href: registerLink,
          gradient: true,
        },
        {
          label: "Learn More",
          href: learnMoreLink,
          gradient: false,
        },
      ]
    }

    // If we're sure that user has not yet waitlisted
    if (waitlistStatus === false) {
      return [
        <span key="">
          Join the waitlist for the largest multinational teachers’ olympiad.
        </span>,
        {
          label: "Join Waitlist",
          href: registerLink,
          gradient: true,
        },
        {
          label: "Learn More",
          href: learnMoreLink,
          gradient: false,
        },
      ]
    }

    if (waitlistStatus === true) {
      return [
        <span key="">
          <span className="text-violet-600">Congratulations!</span> you’re
          already in the waitlist for the largest multinational teachers’
          olympiad.
        </span>,
        {
          label: "Join Waitlist",
          href: registerLink,
          gradient: true,
        },
        {
          label: "Learn More",
          href: learnMoreLink,
          gradient: false,
        },
      ]
    }

    return [
      <span key="">
        Join the waitlist for the largest multinational teachers’ olympiad.
      </span>,
      {
        label: "Learn More",
        href: learnMoreLink,
        gradient: true,
      },
    ]
  })()

  const redirectWithAuth = async (href: string) => {
    try {
      const code = await generateAuthCode()
      window.location.href = `${href}?authCode=${code}`
    } catch (e) {
      window.location.href = href
      console.error(e)
    }
  }

  return (
    <DynamicContainer className="mt-4 mb-3">
      <img
        src="https://assets.suraasa.com/ito/logos/ito-original-one-line.svg"
        alt="itoLogo"
        className="mb-3"
        width={300}
      />
      <div
        className="rounded-2xl relative overflow-hidden"
        style={{
          backgroundImage: isXs
            ? `linear-gradient(90deg, rgba(255,255,255,0.29) 2%, rgba(235,72,63,0.06) 28%, rgba(235,150,77,0.16) 41%, rgba(52,109,242,0.3) 99%),url(${GridSquare})`
            : `linear-gradient(90deg, rgba(116,63,245,0.2) 0%, rgba(52,109,242,0.2) 20%, rgba(234,89,202,0.25) 45%, rgba(235,150,77,0.15) 62%, rgba(235,72,63,0.15) 70%, rgba(105,219,123,0.2) 100%),url(${GridSquare})`,
        }}
      >
        <div
          className="absolute -z-1 h-full w-full pointer-events-none"
          style={{
            background: isXs
              ? "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%)"
              : "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 30%)",
          }}
        />
        <img
          src={ItoBgWeb}
          alt="bgLogo"
          className="absolute -z-1 hidden sm:block pointer-events-none"
          style={{ right: "-122.156px", bottom: "-199px" }}
        />

        {/* Content */}
        <div className="px-[36px] py-3">
          <SwitzerTypography
            variant="title2"
            style={{ fontFamily: "Switzer Bold" }}
          >
            <span>
              <span className="relative">
                Discover
                <img
                  src={isMdDown ? CurvedLavenderLine : CurvedLine}
                  alt="line"
                  className="absolute bottom-[-2px] -z-1"
                />
              </span>{" "}
              your{" "}
              <span style={{ color: "#743FF5" }} className="relative">
                #TrueTeachingPotential
                <span className="absolute -right-[55%] top-[25%] hidden sm:block -z-1">
                  <img src={isXs ? TeachersMob : TeachersWeb} alt="arrowText" />
                </span>
              </span>
            </span>
          </SwitzerTypography>

          <Typography
            variant={isXs ? "smallBody" : "largeBody"}
            className="mt-1 sm:w-[min(400px,70%)] font-medium"
          >
            {copy}
          </Typography>

          <div className="flex items-center mt-[18px] gap-2 flex-wrap">
            {actions.map(btn => {
              const isExternalLink = btn.href.startsWith("http")
              const ButtonComponent = btn.gradient ? GradientButton : Button

              if (isExternalLink) {
                return (
                  <AsyncBuilder
                    delay={500}
                    handler={() => redirectWithAuth(btn.href)}
                    render={({ loading, onClick }) => (
                      <ButtonComponent
                        variant={btn.gradient ? undefined : "text"}
                        loading={loading}
                        onClick={onClick}
                        label={btn.label}
                      />
                    )}
                    key={btn.label}
                  />
                )
              }

              return (
                <ButtonComponent
                  variant={btn.gradient ? undefined : "text"}
                  label={btn.label}
                  key={btn.label}
                  component={Link}
                  to={btn.href}
                />
              )
            })}
          </div>
        </div>

        <img
          src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
          alt="logo"
          className="absolute bottom-3 right-[36px] -z-1"
        />
      </div>
    </DynamicContainer>
  )
}

export default ITORegistrationBanner
