import { Button, Tag, toast, Typography } from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Attempt, AttemptStatus } from "api/resources/assessments/types"
import Monitor from "assets/Fallback/monitor.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import SlidingSheet from "components/SlidingSheet"
import TruncatedText from "components/TruncatedText"
import { format } from "date-fns"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { Book, Calendar, Clock, Xmark } from "iconoir-react"
import React, { useState } from "react"
import { useTheme } from "react-jss"

import { useQualificationDetails } from ".."
import AttemptCard from "./AttemptCard"
import { getTagStyle } from "./helpers"

type Props = {
  className?: string
}

const QualificationAssessments = ({ className }: Props) => {
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<
    null | string
  >(null)
  const [openAttemptsSheet, setOpenAttemptsSheet] = useState(false)
  const [attemptsData, setAttemptsData] = useState<Attempt[]>([])
  const { qualification: overview } = useQualificationDetails()

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const {
    data: schedules,
    isLoading: isAssessmentsLoading,
    isError: isAssessmentsError,
  } = useQuery({
    enabled: Boolean(overview.id),
    queryFn: () =>
      api.assessments.listAssessmentsSchedules({
        urlParams: {
          itemId: overview.id,
          itemType: overview.type,
        },
      }),
    queryKey: queries.assessments.assessmentList(overview.id).queryKey,
  })

  const listAttempts = useMutation({
    mutationFn: (assessmentId: string) =>
      api.assessments.listAttempts({
        urlParams: { assessmentId },
      }),
    onSuccess: data => {
      handleAction(data)
    },
    onError: () => {
      toast.error("Unable to view assessment.")
      setSelectedAssessmentId(null)
    },
  })

  /**
   * if user has already passed or failed the test then it will show the result
   * else the user will be navigated to the instructions page of assessment
   */
  const handleAction = (attemptsData: Attempt[]) => {
    setAttemptsData(attemptsData)
    /**
     * if attempt is Not started & attempt is not missed or attempt is in progress
     * then only we start assessment otherwise we open the sidebar
     */
    if (attemptsData.length === 1) {
      if (
        (attemptsData[0].status === AttemptStatus.NOT_STARTED &&
          attemptsData[0].assessmentSchedule?.endTime &&
          new Date(attemptsData[0].assessmentSchedule?.endTime) > new Date()) ||
        attemptsData[0].status === AttemptStatus.IN_PROGRESS
      ) {
        startAssessment({
          assessmentId: attemptsData[0].assessment.uuid,
          onClose: reloadOnAssessmentClose,
        })
        return
      }
    }
    setOpenAttemptsSheet(true)
  }
  if (isAssessmentsLoading || isAssessmentsError) {
    return (
      <div className="pb-3">
        <Typography variant="title3" className="mb-2">
          Assessments
        </Typography>
        <Fallback
          data={{
            title: "Something went wrong",
            description: "Please contact care@suraasa.com",
            image: Monitor,
          }}
          className="pt-4"
          hasError={isAssessmentsError}
          isLoading={isAssessmentsLoading}
        />
      </div>
    )
  }

  const currentAssessment = schedules.find(
    schedule => schedule.assessment.uuid === selectedAssessmentId
  )

  return (
    <div className={clsx("pb-3", className)}>
      <Typography variant="title3" className="my-2">
        Assessments
      </Typography>

      {schedules.length > 0 ? (
        <ItemList>
          {schedules.map(schedule => {
            const tagStyle = getTagStyle({
              status: schedule.attempt?.status,
              startTime: schedule.startTime,
              endTime: schedule.endTime,
              result: schedule.attempt?.result,
            })
            const assessmentId = schedule.assessment.uuid

            return (
              <ItemRow
                key={schedule.id}
                onClick={
                  isXs
                    ? () => {
                        setSelectedAssessmentId(assessmentId)
                        listAttempts.mutate(assessmentId)
                      }
                    : undefined
                }
                infoSlot={
                  <div>
                    <div className="flex items-center mb-1">
                      <Typography variant="strong" className="me-1">
                        {schedule.assessment.title}
                      </Typography>
                      <Tag
                        size="sm"
                        className="flex-shrink-0"
                        label={tagStyle.label}
                        color={tagStyle.color}
                      />
                    </div>
                    <span className="flex items-center text-secondary-600 flex-wrap">
                      {schedule && schedule.startTime && schedule.endTime && (
                        <span className="flex items-center me-2 sm:mt-0 mt-0.5 flex-nowrap">
                          <Calendar className="me-1" fontSize={15} />
                          <Typography
                            variant="strongSmallBody"
                            className="overflow-clip"
                          >
                            {format(
                              new Date(schedule.startTime),
                              "dd LLL yyyy, p"
                            )}{" "}
                            -{" "}
                            {format(
                              new Date(schedule.endTime),
                              "dd LLL yyyy, p"
                            )}
                          </Typography>
                        </span>
                      )}
                      <span className="flex items-center me-2 sm:mt-0 mt-0.5 flex-nowrap">
                        <Book className="me-1" fontSize={15} />
                        <TruncatedText
                          maxLength={30}
                          hideActionButton
                          variant="strongSmallBody"
                          className="overflow-clip"
                        >
                          {schedule.learningItem.name}
                        </TruncatedText>
                      </span>
                    </span>
                  </div>
                }
                actionSlot={
                  isXs ? undefined : (
                    <span className={clsx({ "self-end": isXs })}>
                      <Button
                        variant="text"
                        label="View"
                        loading={
                          listAttempts.isLoading &&
                          selectedAssessmentId === assessmentId
                        }
                        onClick={() => {
                          setSelectedAssessmentId(assessmentId)
                          listAttempts.mutate(assessmentId)
                        }}
                      />
                    </span>
                  )
                }
                className="py-2"
              />
            )
          })}
        </ItemList>
      ) : (
        <Fallback
          data={{
            title: "No assessments available",
            image: Monitor,
          }}
          className="pt-4"
        />
      )}

      <SlidingSheet
        from="end"
        // isDismissible={false}
        open={openAttemptsSheet}
        onClose={() => {
          setOpenAttemptsSheet(false)
          setSelectedAssessmentId(null)
        }}
      >
        <div className="p-3">
          <Button
            variant="text"
            color="black"
            startAdornment={<Xmark />}
            onClick={() => {
              setOpenAttemptsSheet(false)
              setSelectedAssessmentId(null)
            }}
            className="mb-[30px]"
          >
            Close
          </Button>
          {currentAssessment && (
            <Typography variant="title3" className="mb-1">
              {currentAssessment.assessment.title}
            </Typography>
          )}
          <div className="flex flex-col">
            {attemptsData.length > 0 ? (
              attemptsData.map((attempt, index) => (
                <AttemptCard
                  key={index}
                  attempt={attempt}
                  attemptNumber={attemptsData.length - index}
                />
              ))
            ) : (
              <Typography
                variant="body"
                className="mt-2 border border-critical-500 bg-critical-50 rounded-lg p-1"
              >
                No attempts available.
                <br />
                Please contact care@suraasa.com.
              </Typography>
            )}
          </div>
        </div>
      </SlidingSheet>
    </div>
  )
}

export default QualificationAssessments
