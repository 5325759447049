import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  theme,
  toast,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Award } from "api/resources/profile/types"
import LoadingOverlay from "components/LoadingOverlay"
import RemoveDialog from "features/Profile/components/RemoveDialog"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"

type Props = {
  handleClose: () => void
  id?: string
} & Omit<DialogProps, "data" | "id">

const AwardDialog = ({ id, open, handleClose }: Props) => {
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const {
    achievements: {
      awards: { remove, add, update },
    },
  } = useContext(ProfileContext)

  const [data, setData] = useState<Award>()
  const [removeDialogOpen, toggleRemoveDialog] = useState(false)
  const [removeDialogLoading, setRemoveDialogLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Award>()

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.awards.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)
        reset(res.data)
      }
    }
    if (id) fetchData()
  }, [id, reset])

  const onSubmit = handleSubmit(
    async ({ title, issuer, issuedOn, description }) => {
      const apiData = {
        title,
        issuer,
        issuedOn: issuedOn || null,
        description: description || null,
      }

      if (id) {
        const res = await api.profile.awards.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          update(id, res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.awards.create({ data: apiData })
        if (res.isSuccessful) {
          add(res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    setRemoveDialogLoading(true)
    if (id) {
      const res = await api.profile.awards.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        remove(id)
        toast.success("Removed successfully.")
        toggleRemoveDialog(false)
        handleClose()
      }
    }
  }

  return (
    <>
      <Dialog
        fullScreen={isXs}
        open={open}
        width="md"
        onAfterClose={reset}
        onRequestClose={handleClose}
      >
        {id && !data && <LoadingOverlay />}

        <DialogTitle>{id ? "Edit" : "Add New"} Award</DialogTitle>

        <DialogContent>
          <form onSubmit={onSubmit}>
            <TextField
              className="mb-3"
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
              label="Title"
              inputLabelProps={{ required: true }}
              placeholder="Ex: Best Teacher Award"
              fullWidth
              charLimit={300}
              {...register("title", {
                required: { value: true, message: "Required" },
                maxLength: {
                  value: 300,
                  message: "Try to keep it short",
                },
              })}
            />

            <TextField
              className="mb-3"
              error={Boolean(errors.issuer)}
              helperText={errors.issuer?.message}
              inputLabelProps={{ required: true }}
              label="Issuer"
              placeholder="Ex: Teachers Association of India"
              fullWidth
              charLimit={300}
              {...register("issuer", {
                required: { value: true, message: "Required" },
                maxLength: {
                  value: 300,
                  message: "Try to keep it short",
                },
              })}
            />

            <TextField
              className="mb-3"
              error={Boolean(errors.issuedOn)}
              helperText={errors.issuedOn?.message}
              label="Issue Date"
              placeholder="Ex: 21 Jan 2021"
              type="date"
              fullWidth
              {...register("issuedOn")}
            />
            {/* @ts-expect-error random-types-issue */}
            <TextField
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              label="Description"
              maxRows={5}
              placeholder="Talk about your role and experience etc..."
              rows={5}
              fullWidth
              multiLine
              charLimit={300}
              {...register("description", {
                maxLength: {
                  value: 300,
                  message: "Try to keep it short",
                },
              })}
            />
          </form>
        </DialogContent>

        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              loading: isSubmitting,
              color: "primary",
              onClick: onSubmit,
            },
            tertiary: data && {
              label: "Remove",
              variant: "text",
              type: "button",
              color: "critical",
              onClick: () => toggleRemoveDialog(true),
            },
          }}
        />
      </Dialog>

      {data && (
        <RemoveDialog
          handleClose={() => toggleRemoveDialog(false)}
          loading={removeDialogLoading}
          open={removeDialogOpen}
          title="Remove Award"
          onRemove={handleRemove}
        >
          <Typography variant="smallBody">
            Are you sure you want to remove&nbsp;
            <Typography display="inline" variant="strongSmallBody">
              <strong>{data.title}</strong>
            </Typography>
            &nbsp;from your profile?
          </Typography>
        </RemoveDialog>
      )}
    </>
  )
}

export default AwardDialog
