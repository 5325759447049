import { Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { WarningTriangle } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import useNetworkStatus from "utils/hooks/useNetworkStatus"

const useStyles = createUseStyles((theme: Theme) => ({
  overlay: {
    position: "fixed",
    textAlign: "center",
    zIndex: theme.zIndex.dialog + 2,
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.30)",
    left: 0,
    top: 0,
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  warning: {
    display: "flex",
    alignItems: "center",
    background: theme.colors.critical[500],
    color: "white",
  },
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))

const InternetConnectionBanner = () => {
  const classes = useStyles()

  const { status } = useNetworkStatus()

  if (status === "offline")
    return (
      <div className={classes.overlay}>
        <div className={clsx("px-2 py-2", classes.warning)}>
          <WarningTriangle className={classes.icon} />
          <Typography variant="body">
            Lost Internet Connection. Please fix your connection to continue.
          </Typography>
        </div>
      </div>
    )

  return null
}

export default InternetConnectionBanner
