import axios from "api/axios"
import { APIResponse, Config, PaginatedAPIResponse } from "api/types"
import {
  formatErrorResponse,
  formatSuccessResponse,
  logAPIError,
} from "api/utils"

import { Claim, ClaimHistory, Overview, ReferralHistory } from "./types"
import { urls } from "./urls"

export default {
  claim: async (): Promise<APIResponse<Claim>> => {
    try {
      const res = await axios.post(urls.claims())
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      return error
    }
  },

  getReferralOverview: async ({
    params,
  }: Config): Promise<APIResponse<Overview>> => {
    try {
      const res = await axios.get(urls.getReferralOverview(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listClaimHistory: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<ClaimHistory[]>> => {
    try {
      const res = await axios.get(urls.claims(), {
        params,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listReferralHistory: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<ReferralHistory[]>> => {
    try {
      const res = await axios.get(urls.referrals(), {
        params,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
