import { Button, Container, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import SlidingSheet from "components/SlidingSheet"
import { NavArrowLeft } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import { getAuthInfo } from "utils/helpers"

import {
  SkillProfileRoutes,
  useGenerateURL,
} from "./hooks/useSkillProfileNavigation"
import Overview from "./Overview"
import EvidenceDetails from "./SlidingSheetPages/EvidenceDetails"
import Evidences from "./SlidingSheetPages/Evidences"
import EvidenceSetting from "./SlidingSheetPages/EvidenceSetting"
import SkillOverview from "./SlidingSheetPages/SkillOverview"
import VerificationProcess from "./SlidingSheetPages/VerificationProcess"

const SkillProfile = () => {
  const navigate = useNavigate()
  const { username } = useParams<{ username?: string }>()
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const loggedInUserName = getAuthInfo()?.user.username
  const isSelf = username ? loggedInUserName === username : true

  const {
    navigate: skillPortfolioNavigation,
    isOverview,
    isEvidence,
    isEvidenceDetails,
    isEvidenceSetting,
    isVerificationProcess,
  } = useGenerateURL()

  const {
    data: profile,
    isLoading,
    isError,
  } = useQuery({
    queryKey: queries.profile.retrieve().queryKey,
    queryFn: () =>
      api.profile.retrieve({
        urlParams: {
          username: username ?? "",
        },
      }),
  })

  const {
    data: skillEvidenceList,
    isLoading: isSkillLoading,
    isError: isSkillError,
  } = useQuery({
    queryKey: queries.profile.skillEvidenceList().queryKey,
    queryFn: () =>
      api.profile.skillEvidences.list({
        urlParams: {
          username: username ?? "",
        },
      }),
  })

  if (isError || isLoading) {
    return <Fallback isLoading={isLoading} hasError={isError} />
  }

  if (isSkillError || isSkillLoading) {
    return (
      <Container fullWidth={isXsDown} className="pb-8">
        <Overview data={profile} />

        <Fallback
          isLoading={isSkillLoading}
          className="mt-4"
          data={{ image: Monitor, title: "No Skill Evidences Available!" }}
        />
      </Container>
    )
  }

  const pages = (
    <>
      {isEvidence && (
        <Evidences isSelf={isSelf} skillEvidences={skillEvidenceList.skills} />
      )}

      {isEvidenceDetails && (
        <EvidenceDetails isSelf={isSelf} firstName={profile.user.firstName} />
      )}

      {isEvidenceSetting && (
        <EvidenceSetting skillEvidences={skillEvidenceList.skills} />
      )}

      {isVerificationProcess && <VerificationProcess />}
    </>
  )

  const getBackButton = () => {
    return (
      <Button
        color="black"
        variant="text"
        className="-ml-1"
        startAdornment={<NavArrowLeft />}
        onClick={() => {
          if (window.history?.state?.idx > 1) {
            navigate(-1)
          } else {
            skillPortfolioNavigation(SkillProfileRoutes.OVERVIEW)
          }
        }}
      >
        Back
      </Button>
    )
  }

  if (isXsDown) {
    return (
      <div>
        {isOverview && (
          <SkillOverview
            skillEvidences={skillEvidenceList.skills}
            isLoading={isSkillLoading}
            isError={isSkillError}
            profile={profile}
          />
        )}
        {!isOverview && <div className="mt-3 px-3">{getBackButton()}</div>}
        {pages}
      </div>
    )
  }

  return (
    <Container className="pb-8">
      <SkillOverview
        skillEvidences={skillEvidenceList.skills}
        isLoading={isSkillLoading}
        isError={isSkillError}
        profile={profile}
      />
      <SlidingSheet
        from={isXsDown ? "bottom" : "end"}
        onClose={() => skillPortfolioNavigation(SkillProfileRoutes.OVERVIEW)}
        open={!isOverview}
        className="flex h-screen flex-col rounded-l-2xl sm:w-[70%] md:w-[50%]"
      >
        <div className="mt-3 px-3 pt-1">{getBackButton()}</div>
        {pages}
      </SlidingSheet>
    </Container>
  )
}

export default SkillProfile
