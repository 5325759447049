import { useLayoutEffect } from "react"

/**
 * This hooks hides the bottom navigation wherever its called
 * @usage useHideBottomNavigation()
 */
const useHideGlobalBottomNavigation = () => {
  useLayoutEffect(() => {
    document.body.classList.add("hideGlobalBottomNavigation")
    return () => {
      document.body.classList.remove("hideGlobalBottomNavigation")
    }
  }, [])
}

export default useHideGlobalBottomNavigation
