import {
  Button,
  IconButton,
  Tag,
  toast,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { Gender } from "api/resources/profile/types"
import clsx from "clsx"
import ShareSheet from "components/ShareSheet"
import Female from "features/Profile/assets/profileFemale.png"
import Male from "features/Profile/assets/profileMale.png"
import Neutral from "features/Profile/assets/profileNeutral.png"
import { BadgeCheck, Edit, ShareAndroid } from "iconoir-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { buildUserName, saveBlobAsFile } from "utils/helpers"

import ProfileContext from "../context"
import { DEFAULT_PROFILE_COVER } from "../utils"
import Section from "./Section"

const AVATAR_SIZE = { lg: 142, sm: 91 }

const useStyles = createUseStyles(theme => ({
  root: {
    background: theme.colors.common.white,
    padding: theme.spacing(0, 3),
    paddingBottom: theme.spacing(1),
  },

  shareButton: {
    borderColor: theme.colors.onSurface[400],
    color: theme.colors.onSurface[800],
  },

  displayPictureAndFilter: {
    transform: "translateY(-60%)",
    height: AVATAR_SIZE.lg,
    width: AVATAR_SIZE.lg,
    left: theme.spacing(3),
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      transform: "translateY(calc(-60% - 40px))",
      height: AVATAR_SIZE.sm,
      width: AVATAR_SIZE.sm,
      left: theme.spacing(2),
    },
  },

  displayPicture: {
    objectFit: "cover",
    borderRadius: "50%",
    border: `3px solid ${theme.colors.common.white[500]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },

  verifiedBadge: {
    display: "inline-flex",
    color: theme.colors.primary[500],
    background: theme.colors.primary[50],
    padding: theme.spacing(1 / 2),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(1 / 2),
    "& svg": {
      fill: theme.colors.primary[500],
    },
    [theme.breakpoints.down("xs")]: {
      background: "transparent",
      padding: theme.spacing(1 / 2, 0),
      "& span": {
        display: "none",
      },
    },
  },

  title: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      /** Edit profile button */
      "& .editButton": {
        position: "absolute",
        right: theme.spacing(12 / 8),
        top: theme.spacing(12 / 8),
      },
    },
  },
  titleAndBadge: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1 / 2),
    },
  },

  position: {
    marginBottom: theme.spacing(1 / 2),
  },
  locations: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(6 / 8),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1),
    },
    marginBottom: theme.spacing(1),
  },

  cover: {
    height: 184,
    minWidth: "100%",
    backgroundColor: theme.colors.onSurface[300],
    backgroundImage: `url('${DEFAULT_PROFILE_COVER}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: theme.spacing(1, 1, 0, 0),

    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    paddingLeft: `calc(${AVATAR_SIZE.lg}px + 24px + 16px)`,
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(0),
    },

    [theme.breakpoints.down("xs")]: {
      height: 108,
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      /** Hide Video Portfolio button from cover */
      "& button:nth-child(1),button:nth-child(2)": {
        display: "none",
      },
    },
  },

  content: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    paddingLeft: `calc(${AVATAR_SIZE.lg}px + 24px + 16px)`,
    minHeight: "calc(284.1px - 175px)",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },

  footerButtons: {
    paddingTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}))

function getGenderImage(gender: Gender | null): string {
  if (!gender) return Neutral

  if (Gender.MALE === gender) {
    return Male
  }
  if (Gender.FEMALE === gender) {
    return Female
  }

  return Neutral
}

const COUNTRIES_TO_SHOW = 2

const Overview = ({
  videoPortfolioURL,
  openVideoPortfolioDialog,
  openEditProfile,
}: {
  videoPortfolioURL: string | null
  openVideoPortfolioDialog: () => void
  openEditProfile: () => void
}) => {
  const classes = useStyles()

  const [openShare, setOpenShare] = useState(false)

  const {
    profile: {
      user,
      isVerified,
      currentWorkingExperience,
      picture,
      countriesWorkedIn,
      gender,
      country,
      state,
    },
    isPublic,
  } = useContext(ProfileContext)

  const { mutate: downloadCV, isLoading } = useMutation({
    mutationFn: () => api.profile.downloadResume(),
    onSuccess: res => {
      if (res.__data) {
        saveBlobAsFile({
          data: res.__data?.data,
          type: "application/pdf",
          name: "Resume",
        })
      }
    },
    onError: err => {
      if (err) {
        toast.error("We're unable to process your request")
      }
    },
  })

  const url = `${window.location.origin}/profile/${user.username}`
  const fullName = buildUserName(user)
  return (
    <>
      <ShareSheet
        data={{
          url,
          text: isPublic
            ? `Check out ${fullName}'s profile on Suraasa, the career app for teachers: ${url}`
            : `Check out my profile on Suraasa, the career app for teachers: ${url}`,
        }}
        open={openShare}
        handleClose={() => setOpenShare(false)}
      />
      <Section>
        <header>
          <div className={classes.cover} />
          <div className={classes.content}>
            <img
              alt="avatar"
              className={clsx(
                classes.displayPicture,
                classes.displayPictureAndFilter,
                "transition-[border]"
                // {
                //   "!border-8 !border-primary-400": videoPortfolioURL,
                // }
              )}
              src={picture || getGenderImage(gender)}
            />
            {/* {lookingForJobs && (
              <LookingForJobsRibbon
                className={classes.displayPictureAndFilter}
              />
            )} */}
            <div style={{ width: "100%" }}>
              <div className="mb-1">
                <div className={classes.title}>
                  <div className={classes.titleAndBadge}>
                    <Typography
                      component="h1"
                      display="inline"
                      variant="title3"
                    >
                      {user.firstName} {user.lastName}
                    </Typography>

                    {isVerified && (
                      <span className={classes.verifiedBadge}>
                        <BadgeCheck color="white" height="22" width="22" />
                        <span>Verified</span>
                      </span>
                    )}
                    <IconButton size="sm" onClick={() => setOpenShare(true)}>
                      <ShareAndroid />
                    </IconButton>
                  </div>
                  {!isPublic && (
                    <Button
                      nudge="right"
                      className="editButton"
                      startAdornment={<Edit />}
                      variant="text"
                      onClick={openEditProfile}
                    >
                      Edit Profile
                    </Button>
                  )}
                </div>
                <Typography display="inline" variant="smallBody">
                  {[state?.name, country?.name].filter(Boolean).join(", ")}
                </Typography>
              </div>

              {currentWorkingExperience && (
                <>
                  <Typography className={classes.position} variant="body">
                    {currentWorkingExperience.title}
                  </Typography>

                  <Typography variant="body" className="mb-1">
                    {currentWorkingExperience.organisationName}
                  </Typography>

                  {currentWorkingExperience.teachingLevel && (
                    <div className="flex flex-wrap gap-1 items-center mb-1">
                      <Typography color="onSurface.500" variant="smallBody">
                        Teaching Level:
                      </Typography>
                      <Tag
                        size="sm"
                        color="onSurface"
                        label={currentWorkingExperience.teachingLevel}
                      />
                    </div>
                  )}

                  {Boolean(currentWorkingExperience.subjects?.length) && (
                    <div className="flex flex-wrap gap-1 items-center mb-1">
                      <Typography color="onSurface.500" variant="smallBody">
                        Subjects:
                      </Typography>
                      {currentWorkingExperience.subjects!.map(x => (
                        <Tag key={x} size="sm" color="onSurface" label={x} />
                      ))}
                    </div>
                  )}
                </>
              )}

              {countriesWorkedIn.length > 0 && (
                <div className={classes.locations}>
                  <Typography variant="smallBody" color="onSurface.500">
                    Worked in:
                  </Typography>
                  {countriesWorkedIn.slice(0, COUNTRIES_TO_SHOW).map(item => (
                    <Tag
                      color="onSurface"
                      key={item.name}
                      label={item.name}
                      size="sm"
                    />
                  ))}
                  {countriesWorkedIn.length - COUNTRIES_TO_SHOW > 0 && (
                    <Typography color="onSurface.400" variant="smallBody">
                      + {countriesWorkedIn.length - COUNTRIES_TO_SHOW} more
                    </Typography>
                  )}
                </div>
              )}

              <div className="flex items-center mt-1.5 flex-wrap gap-1">
                <Tooltip
                  disabled={!(isPublic && !videoPortfolioURL)}
                  title={`${user.firstName} has not added a video portfolio yet.`}
                >
                  <span>
                    <Button
                      className="me-1"
                      size="sm"
                      disabled={isPublic && !videoPortfolioURL}
                      onClick={() => {
                        if (videoPortfolioURL || !isPublic)
                          openVideoPortfolioDialog()
                      }}
                    >
                      Video Portfolio
                    </Button>
                  </span>
                </Tooltip>

                {!isPublic && (
                  <Button
                    size="sm"
                    onClick={() => downloadCV()}
                    loading={isLoading}
                    variant="outlined"
                  >
                    Download Profile as CV
                  </Button>
                )}
              </div>
            </div>
          </div>
        </header>
      </Section>
    </>
  )
}

export default Overview
