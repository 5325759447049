import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Xmark } from "iconoir-react"
import React from "react"

type Props = {
  openVideoPortfolioDialog: () => void
  onCloseBanner: () => void
}

const AddVideoBanner = ({ openVideoPortfolioDialog, onCloseBanner }: Props) => {
  return (
    <button
      className="flex w-full items-start justify-between rounded-2xl p-3 text-start"
      style={{
        background: "linear-gradient(235.38deg, #BBF0D9 4.23%, #BFD7FF 95.84%)",
      }}
      onClick={openVideoPortfolioDialog}
    >
      <div>
        <Typography variant="strong" className="mb-1">
          Add Video Portfolio
        </Typography>
        <Typography variant="body">
          Add an introduction video about yourself to make your profile stand
          out to potential recruiters.
        </Typography>
      </div>
      <div>
        <IconButton
          color="secondary"
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation()
            onCloseBanner()
          }}
        >
          <Xmark />
        </IconButton>
      </div>
    </button>
  )
}

export default AddVideoBanner
