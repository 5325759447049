import { Radio, Theme, Typography } from "@suraasa/placebo-ui"
import {
  AssessmentQuestion,
  QuestionType,
} from "api/resources/ito/assessments/types"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

import shepherdClass from "../shepherdClasses"
const useStyles = createUseStyles((theme: Theme) => ({
  scroll: {
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  radioBox: {
    boxSizing: "border-box",
    border: `2px solid ${theme.colors.secondary[400]}`,
    borderRadius: 4,
    width: "-webkit-fill-available",
    padding: theme.spacing(1),
    maxWidth: "600px",
    cursor: "pointer",
  },
  label: {
    alignItems: "center !important",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  selected: {
    border: `2px solid ${theme.colors.primary[500]}`,
    backgroundColor: theme.colors.primary[100],
  },
  question: {
    whiteSpace: "pre-line",
  },
  optionsContainer: {
    maxWidth: 605,
    display: "flex",
    flexDirection: "column",
    "& .Radio-root": {
      marginBottom: theme.spacing(1.5),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
}))

const Question = ({
  question,
  handleResponse,
}: {
  question: AssessmentQuestion
  handleResponse: (value: number) => void
}) => {
  const classes = useStyles()

  return (
    <div className={classes.scroll}>
      <Typography
        variant="preTitle"
        color="onSurface.600"
        className={clsx("pb-0.5")}
      >
        {question.question.questionType === QuestionType.SINGLE_CORRECT
          ? "Single Correct"
          : question.question.questionType === QuestionType.FILL_IN_THE_BLANKS
          ? "Fill in the Blanks"
          : "Not handled"}
      </Typography>
      <Typography variant="title3" className="pb-1.5">
        Question {question.sequence}
      </Typography>
      <Typography className={classes.question} variant="strong">
        {question.question.text.replace("__blank__", "__________")}
      </Typography>
      <Typography
        variant="strongSmallBody"
        color="onSurface.500"
        className="pt-4 pb-1.5"
      >
        Options
      </Typography>

      <span
        className={clsx(
          "py-1.5 mb-10.5",
          classes.optionsContainer,
          shepherdClass["options"]
        )}
      >
        {question.question.options.map((option, idx) => (
          <Radio
            className={clsx(classes.radioBox, {
              [classes.selected]: question.response?.includes(idx),
            })}
            onChange={() => handleResponse(idx)}
            key={option}
            color="primary"
            checked={question.response?.includes(idx)}
            label={option}
          />
        ))}
      </span>
    </div>
  )
}

export default Question
