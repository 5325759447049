/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { VerifiedParticipationHistory } from "api/resources/ito/registrations/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import styles from "features/ITO/LinkReportsBanner/Linkreports.module.css"
import { Xmark } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { handleErrors } from "utils/helpers"

import GradientButton from "../components/GradientButton"
import SuccessfullyLinkedDialog from "./SuccessfullyLinkedDialog.tsx"
import VerifyIdentity from "./VerifyIdentity"

enum Step {
  none,
  participationNumber,
  verification,
  success,
}

const LinkReportsBanner = () => {
  const [step, setStep] = useState<Step>(Step.none)

  const [otpVerificationData, setOtpVerificationData] =
    useState<VerifiedParticipationHistory>()

  const [showBanner, setShowBanner] = useState(
    localStorage.getItem(BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner) !==
      "true"
  )

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<{
    participationHistoryNumber: string
  }>()

  const participationHistory = useQuery({
    queryFn: () => api.ito.registrations.getUserParticipationHistory(),
  })

  useEffect(() => {
    if (participationHistory.data?.participationHistoryExists === false) {
      setShowBanner(true)
    }
    if (participationHistory.data?.participationHistoryExists === true) {
      setShowBanner(false)
      localStorage.setItem(
        BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner,
        "true"
      )
    }
  }, [participationHistory.data])

  const onSubmit = handleSubmit(async data => {
    try {
      const res = await api.ito.reports.verifyParticipationHistory({
        data: {
          participationHistoryNumber: data.participationHistoryNumber,
        },
      })
      setOtpVerificationData(res)
      setStep(Step.verification)
    } catch (e) {
      if (e instanceof APIError) handleErrors(e, { setter: setError })
    }
  })

  const updateParticipationHistory = async (token: string) => {
    try {
      await api.ito.reports.updateParticipationHistory({
        data: {
          encryptedToken: token,
          participationHistoryNumber: getValues("participationHistoryNumber"),
        },
      })
      setStep(Step.success)
      localStorage.setItem(
        BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner,
        "true"
      )
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e)
        console.error(e)
      }
    }
  }

  if (!showBanner) return null

  return (
    <>
      <div className="bg-highlight-500 relative">
        <IconButton
          className="absolute right-2 align-center top-2"
          color="white"
          onClick={() => {
            setShowBanner(false)
            localStorage.setItem(
              BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner,
              "true"
            )
          }}
        >
          <Xmark />
        </IconButton>
        <Container>
          <div className="md:py-1 py-2 flex md:items-center items-start justify-between md:flex-row flex-col">
            <Typography color="surface.500" className="sm:w-full w-[90%]">
              Did you participate last year? Link your last year reports for a
              more detailed report analysis.
            </Typography>
            <Button
              rounded
              color="white"
              className="md:mt-0 mt-1 !text-highlight-500"
              onClick={() => setStep(Step.participationNumber)}
            >
              Link Reports
            </Button>
          </div>
        </Container>
      </div>

      <Dialog
        open={step === Step.participationNumber}
        onRequestClose={() => setStep(Step.none)}
      >
        <DialogContent className={styles.dialogContent}>
          <div
            className={clsx(styles.whiteGradient, "absolute w-full h-full")}
          />
          <form className="px-2" onSubmit={onSubmit}>
            <div className="flex items-center justify-between">
              <img
                src="https://assets.suraasa.com/ito/logos/ito-original-two-line.svg"
                alt="smallLogo"
                className="pt-3"
                width="40%"
              />
              <IconButton className="mt-1.5" onClick={() => setStep(Step.none)}>
                <Xmark />
              </IconButton>
            </div>
            <Typography variant="title2" className="mt-3 mb-0.5 siz">
              Link your last year reports
            </Typography>
            <Typography color="onSurface.500" className="mb-3">
              This will help us create more detailed reports for you.
            </Typography>
            <TextField
              rounded
              error={Boolean(errors.participationHistoryNumber)}
              helperText={errors.participationHistoryNumber?.message}
              label="Enter your Registration/Certificate Number"
              placeholder="Ex: ITO-XXXXXXX"
              fullWidth
              {...register("participationHistoryNumber", {
                required: { value: true, message: "Required" },
              })}
            />
            <Typography
              variant="smallBody"
              color="onSurface.500"
              className="mt-2"
            >
              You can find the ITO 2022 registration number on your registered
              email, WhatsApp or previous year’s Reports. And you can find your
              certificate number on any of your ITO Certificates from last year.
            </Typography>

            <GradientButton fullWidth className="mb-[26px] mt-10" type="submit">
              Link Reports
            </GradientButton>
          </form>
        </DialogContent>
      </Dialog>

      {otpVerificationData && (
        <VerifyIdentity
          title="Verify Identity"
          onBack={() => {
            setStep(Step.none)
          }}
          close={() => {
            setOtpVerificationData(undefined)
            setStep(Step.none)
          }}
          otpData={otpVerificationData.otp}
          phoneNumber={otpVerificationData.phoneNumber}
          open={step === Step.verification}
          email={otpVerificationData.email}
          onVerify={(token: string) => {
            updateParticipationHistory(token)
          }}
        />
      )}

      <SuccessfullyLinkedDialog
        open={step === Step.success}
        onClose={() => {
          setStep(Step.none)
          setShowBanner(false)
        }}
      />
    </>
  )
}

export default LinkReportsBanner
