import { Checkbox, theme, Typography } from "@suraasa/placebo-ui"
import { Answer as AnswerType } from "api/resources/assessments/types"
import clsx from "clsx"
import React from "react"

import styles from "../MultipleCorrect/MultipleCorrect.module.css"

export const safeHTML = (html: string) => {
  return html.replace(/[\x26<>'"]/g, function (r) {
    return "&#" + r.charCodeAt(0) + ";"
  })
}

type Props = {
  question: AnswerType
}

export const displayBlank = (
  content: string,
  color: "success" | "critical" | "surface"
) => {
  const style = {
    background: theme.colors[color][50],
    borderColor: "",
  }

  if (color === "surface") style.borderColor = theme.colors.surface[300]
  else style.borderColor = theme.colors[color][500]

  return `<h4 style='display:inline;border:1px solid ${style.borderColor}; border-radius:4px;background: ${style.background}; padding:2px 8px;'>${content}</h4>`
}

function FillBlank({ question }: Props) {
  const options = Object.entries(question.options)

  const marked = question.response ?? null

  /**
   *
   * @param content string to display for blank
   * @param index optional parameter only if answers needs to be verified with correctAnswers
   * @returns blank style as a container displaying content
   */
  const getBlankDisplay = (content: string, index?: number) => {
    if (!marked || index === undefined) return displayBlank(content, "surface")

    if (question.isCorrect) return displayBlank(content, "success")

    const correctAnswer = question.correctAnswer[index]
    if (marked[index] === null) return displayBlank(content, "surface")
    if (marked[index] === correctAnswer) return displayBlank(content, "success")
    if (marked[index] !== correctAnswer)
      return displayBlank(content, "critical")
  }

  const convert = (text: string) => {
    const questionParts = []
    const questionTextArray = text.split("__")

    let answerIndex = 0

    for (let i = 0; i < questionTextArray.length; i++) {
      if (questionTextArray[i] === "blank") {
        if (!marked) {
          questionParts.push(
            getBlankDisplay(new Array(18).fill("&nbsp;").join(""))
          )
        } else {
          /**
           * If user has marked an answer, then we escape the option text and render that,
           * if user has not marked an answer, we just render whitespace to get the underline blank effect.
           */
          const optionKey = marked[answerIndex]

          const markedAnswer =
            optionKey != null
              ? safeHTML(options[optionKey][1] as string)
              : new Array(11).fill("&nbsp;").join("")

          const blank = getBlankDisplay(markedAnswer, answerIndex)
          questionParts.push(blank)
        }
        answerIndex++
      } else {
        questionParts.push(questionTextArray[i])
      }
    }

    return <div dangerouslySetInnerHTML={{ __html: questionParts.join(" ") }} />
  }

  return (
    <>
      <div>
        <Typography
          variant="body"
          className="mt-1 mb-2"
          style={{ lineHeight: "1.65rem" }}
        >
          {convert(question.question)}
        </Typography>
      </div>

      {Object.entries(question.options).map(([optionId, optionText]) => {
        const id = parseInt(optionId)
        const markedOptionIndex =
          question.response && question.response.indexOf(id)
        const correctOptionIndex = question.correctAnswer.indexOf(id)

        const isCorrect = question.ordered
          ? markedOptionIndex === correctOptionIndex && markedOptionIndex !== -1
          : markedOptionIndex !== null &&
            markedOptionIndex !== -1 &&
            correctOptionIndex !== -1

        const isMarked = question.response && markedOptionIndex !== -1

        return (
          <div className={styles.checkbox} key={optionId}>
            <Checkbox
              className={clsx("rounded-[5px] w-full my-1", {
                [styles.correctAnswer]: isCorrect,
                [styles.markedAnswer]: !isCorrect && isMarked,
              })}
              color={isCorrect ? "success" : "critical"}
              checked={isCorrect || Boolean(isMarked)}
              readOnly
              label={optionText}
            />
          </div>
        )
      })}
    </>
  )
}

export default FillBlank
