import { Button, toast, Typography } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ActivityUserResponse,
  JumbledWordsActivity,
} from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { useEffect, useState } from "react"

import ActivityButton from "../ActivityButton"
import ActivityContainer from "../ActivityContainer"
import ActivityResultBanner from "../ActivityResultBanner"

const calculatePercentage = (options: ActivityUserResponse<false>[]) => {
  const total = options.length
  if (options.length === 0) {
    return 1
  }

  const answerCount = options.filter(
    item => !item.markedAnswer.includes(null) && item.markedAnswer.length > 0
  ).length

  const percentage = (answerCount / total) * 100
  return percentage > 0 ? percentage : 1
}

const EMPTY_RESPONSE = null
const buildResponses = (
  data: JumbledWordsActivity["activity"]["activityItems"],
  responses: JumbledWordsActivity["attemptItemResponses"]
): ActivityUserResponse<false>[] => {
  let markedAnswer: ActivityUserResponse<false>["markedAnswer"]
  return data.map(item => {
    const response = responses.find(r => r.activityItem === item.id)

    if (!response?.response) {
      markedAnswer = item.options.map(() => EMPTY_RESPONSE)
    }
    if (response?.response?.length === item.options.length) {
      markedAnswer = response.response
    }
    if (response?.response?.length !== item.options.length) {
      markedAnswer = item.options.map((_, index) =>
        response?.response && response?.response[index]
          ? response?.response[index]
          : EMPTY_RESPONSE
      )
    }

    return {
      id: item.id,
      label: item.itemDetails?.sentenceType || "",
      value: item.text,
      options: item.options,
      markedAnswer: markedAnswer,
      validation:
        response?.isCorrect == null
          ? null
          : response.isCorrect
          ? "correct"
          : "incorrect",
    }
  })
}

type ActivityProps = {
  readOnly?: boolean
  markAnswer: (params: Omit<HandleJumbleWorldActivity, "id">) => void
} & ActivityUserResponse<false>

const Activity = ({
  id,
  label,
  markedAnswer,
  options,
  markAnswer,
  validation,
  readOnly = false,
}: ActivityProps) => {
  return (
    <div
      className={clsx(
        "border-2 border-highlight-100 rounded-xl overflow-hidden pb-2 select-none",
        {
          "!border-success-100": validation === "correct",
          "!border-critical-100": validation === "incorrect",
        }
      )}
    >
      <Typography
        variant="subtitle2"
        className={clsx("py-1.5 bg-highlight-100 text-center", {
          "!bg-success-100": validation === "correct",
          "!bg-critical-100": validation === "incorrect",
        })}
      >
        {label}
      </Typography>
      <div className="p-2 pt-10" draggable="false">
        <div className="flex gap-2 flex-wrap justify-center mb-4">
          {markedAnswer?.map((i, markedAnswerIndex) =>
            i !== EMPTY_RESPONSE ? (
              <ActivityButton
                disabled={validation === "correct" || readOnly}
                state={
                  validation === "correct"
                    ? "success"
                    : validation === "incorrect"
                    ? "error"
                    : "active"
                }
                key={markedAnswerIndex}
                onClick={() =>
                  markAnswer({ answerIndex: i, indexAnswerToBeInserted: -1 })
                }
              >
                {options[i]}
              </ActivityButton>
            ) : (
              <hr
                id={markedAnswerIndex.toString()}
                key={markedAnswerIndex}
                onDrop={e => {
                  e.preventDefault()
                  const data: {
                    id: ActivityProps["id"]
                    optionIndex: number
                  } = JSON.parse(e.dataTransfer.getData("data"))

                  if (id === data.id) {
                    return markAnswer({
                      answerIndex: data.optionIndex,
                      indexAnswerToBeInserted: markedAnswerIndex,
                    })
                  }
                }}
                onDragOver={e => e.preventDefault()}
                className="h-4 border-b border-t-0 border-onSurface-400 w-10"
              />
            )
          )}
        </div>
        <div className="flex gap-2 flex-wrap justify-center">
          {options.map((i, index) => {
            const isBlank = markedAnswer.includes(index)
            const disabled = isBlank || readOnly
            return (
              <ActivityButton
                draggable={!disabled}
                onDragStart={(e: any) => {
                  e.dataTransfer.dropEffect = "move"
                  e.dataTransfer.setData(
                    "data",
                    JSON.stringify({ id, optionIndex: index })
                  )
                }}
                disabled={disabled}
                state={isBlank ? "blank" : "default"}
                key={i}
                onClick={() =>
                  markAnswer({
                    answerIndex: index,
                    indexAnswerToBeInserted: -1,
                  })
                }
              >
                {i}
              </ActivityButton>
            )
          })}
        </div>
      </div>
    </div>
  )
}

type HandleJumbleWorldActivity = {
  id: number
  answerIndex: number
  indexAnswerToBeInserted: number
}
const JumbledWords = ({
  data,
  reset,
  onBack,
}: {
  onBack: () => void
  data: JumbledWordsActivity
  reset: () => Promise<void>
}) => {
  const [responses, setResponses] = useState(
    buildResponses(data.activity.activityItems, data.attemptItemResponses)
  )

  const [mode, setMode] = useState<"failed" | "success" | "attempt">("attempt")

  const markAnswer = (
    activityItemId: number,
    response: ActivityUserResponse<false>["markedAnswer"]
  ) => {
    const filteredResponse = response.filter(i => typeof i === "number")
    api.learningItems.activity.markAnswer({
      data: {
        activityItemId,
        response: filteredResponse,
      },
      urlParams: {
        attemptId: data.id,
      },
    })
  }

  const handleSetJumbleWordActivity = ({
    id,
    indexAnswerToBeInserted,
    answerIndex,
  }: HandleJumbleWorldActivity) => {
    setResponses(
      responses.map(activity => {
        if (activity.id !== id) return activity
        // If same answer is selected again then remove it
        if (activity.markedAnswer.includes(answerIndex)) {
          activity.markedAnswer = activity.markedAnswer.map(item =>
            item === answerIndex ? EMPTY_RESPONSE : item
          )
        } else {
          const firstEmptyBlankIndex = activity.markedAnswer.findIndex(
            i => i === EMPTY_RESPONSE
          )
          activity.markedAnswer[
            indexAnswerToBeInserted >= 0
              ? indexAnswerToBeInserted
              : firstEmptyBlankIndex
          ] = answerIndex
        }
        markAnswer(activity.id, activity.markedAnswer)
        return { ...activity }
      })
    )
  }

  const submitActivity = useMutation({
    mutationFn: () =>
      api.learningItems.activity.submit({
        urlParams: {
          attemptId: data.id,
        },
      }),
    onSuccess: raw => {
      const data = raw as JumbledWordsActivity
      setResponses(
        buildResponses(data.activity.activityItems, data.attemptItemResponses)
      )
      if (
        data.attemptItemResponses.every(answer => answer.isCorrect === true)
      ) {
        setMode("success")
      }

      if (data.attemptItemResponses.some(answer => answer.isCorrect !== true)) {
        setMode("failed")
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.errors.message) toast.error(err.errors.message)
      }
    },
  })

  const handleReset = async () => {
    await reset()
    setMode("attempt")
  }

  const percentage = calculatePercentage(responses)

  return (
    <ActivityContainer
      onBack={onBack}
      title={data.activity.title}
      progress={mode === "attempt" ? percentage : 100}
      endSlot={
        submitActivity.isSuccess ? undefined : (
          <Button
            disabled={percentage < 100}
            onClick={() => submitActivity.mutate()}
            loading={submitActivity.isLoading}
          >
            Submit
          </Button>
        )
      }
    >
      <Typography variant="largeBody" className="mb-3 mt-1">
        Form the required sentences by arranging the following words:
      </Typography>

      {mode === "success" && (
        <ActivityResultBanner mode="success" action={async () => onBack()} />
      )}
      {mode === "failed" && (
        <ActivityResultBanner mode="failed" action={handleReset} />
      )}

      <div className="grid md:grid-cols-2 gap-7">
        {responses.map(i => (
          <Activity
            {...i}
            key={i.id}
            readOnly={mode === "failed" || i.validation === "correct"}
            markAnswer={({ answerIndex, indexAnswerToBeInserted }) =>
              handleSetJumbleWordActivity({
                id: i.id,
                answerIndex,
                indexAnswerToBeInserted,
              })
            }
          />
        ))}
      </div>
    </ActivityContainer>
  )
}

export default JumbledWords
