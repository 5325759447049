import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getCygnusURL = getServiceURL(SERVICE_PREFIX.cygnus)
const getIrisURL = getServiceURL(SERVICE_PREFIX.iris)

export const urls = validateUrls({
  list: () => getCygnusURL(`/v1/masterclasses/`),
  overview: () => getCygnusURL(`/v1/masterclasses/overview/`),
  masterclass: slug => getCygnusURL(`/v1/masterclasses/${slug}/`),
  trackMasterclass: videoMeetingId =>
    getIrisURL(`/v1/video-meetings/attendance/${videoMeetingId}/`),
})
