import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import ShareSheet from "components/ShareSheet"
import SlidingSheet from "components/SlidingSheet"
import { ReactComponent as CertificateFrame } from "features/LearningModule/assets/certificate-frame.svg"
import { ReactComponent as CertificateStamp } from "features/LearningModule/assets/certificate-stamp.svg"
import { context } from "global/Context/context"
import { Xmark } from "iconoir-react"
import React, { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { downloadFile, getPlatformURL, handleErrors } from "utils/helpers"

interface Props {
  open: boolean
  handleClose: () => void
}

const CourseCompletionSheet = ({ open, handleClose }: Props) => {
  const { slug } = useParams() as { slug: string }
  const { enrollments } = useContext(context)

  const course = enrollments?.find(item => item.slug === slug)
  const courseId = course?.id ?? null

  const [shareSheet, setShareSheet] = useState(false)

  const { data } = useQuery({
    enabled: open && courseId !== null,
    queryFn: () =>
      api.profile.credentials.getItemCertificate({
        urlParams: {
          courseId: courseId!,
        },
      }),
    queryKey: queries.profile.getItemCertificate(courseId).queryKey,
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  return (
    <>
      <SlidingSheet
        className="relative max-w-[965px] sm:min-h-[95vh]  min-h-[72vh] overflow-x-hidden"
        open={open}
        onClose={() => handleClose()}
      >
        <IconButton
          color="black"
          className="mx-1 my-2"
          onClick={() => handleClose()}
        >
          <Xmark />
        </IconButton>
        {data ? (
          <>
            <div className="absolute z-[2] left-1/2 top-[45%] transform -translate-x-1/2 -translate-y-1/2">
              <Typography
                variant="title1"
                className="text-center mb-1 mt-9"
                style={{ fontFamily: "Nicholas-Bold" }}
              >
                Congratulations!
              </Typography>
              <Typography
                variant="largeBody"
                className="text-center mb-3 sm:mb-5"
              >
                You have successfully completed {course?.name}
              </Typography>
              <div className="flex gap-1 justify-center relative">
                <CertificateStamp
                  /**
                   * These width and height percentages are calculated from
                   * <CertificateFrame/> min height and width
                   */
                  width="99.69%"
                  height="99.69%"
                  // height="72.11%"
                  className="max-w-[332.95px] max-h-[332.34px] absolute left-[49.1%] top-[41.5%] opacity-10 z-[4]"
                />
                <CertificateFrame
                  height="100%"
                  className="w-[300px] sm:w-full min-w-[184px] min-h-[246.57px] max-h-[334px] max-w-[447px] relative"
                />
                <img
                  alt="course certificate"
                  src={data.highResImage || data.lowResImage}
                  className="max-h-[264.66px] max-w-[373.13px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[3]"
                  /**
                   * These width and height percentages are calculated from
                   * <CertificateFrame/> min height and width
                   */
                  width="88.176%"
                  height="83.47 %"
                />
              </div>
              <div className="flex mt-5 gap-1 justify-center absolute z-[5] w-full">
                <Button
                  className="!rounded-full"
                  onClick={() => downloadFile(data.pdfFile, "Certificate")}
                >
                  Download Certificate
                </Button>

                <Button
                  className="!rounded-full"
                  onClick={() => setShareSheet(true)}
                >
                  Share
                </Button>
              </div>
              <div className="min-h-[100px]"></div>
            </div>
            <svg
              className="max-w-[965px] absolute bottom-0 z-[1]"
              width="100%"
              height="410"
              viewBox="0 0 965 410"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="482" cy="964.172" r="964" fill="#F5FAFF" />
            </svg>
          </>
        ) : (
          <div className="mt-[30%] flex items-center justify-center">
            <CircularProgress />
          </div>
        )}
      </SlidingSheet>
      <ShareSheet
        open={shareSheet}
        handleClose={() => setShareSheet(false)}
        data={{
          url: getPlatformURL("suraasa", `/verify/${data?.serialNumber}`),
        }}
      />
    </>
  )
}

export default CourseCompletionSheet
