import { Button, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import clsx from "clsx"
import Card from "components/Card"
import NextUp from "features/LearningItems/Qualification/QualificationOverview/NextUp"
import CourseInfoAndProgress from "features/Library/CourseInfoAndProgress"
import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import routes from "routes"
import { getAuthInfo, getLearningItemURL, pluralize } from "utils/helpers"
import { PropsWithClassName } from "utils/types"

import { useCourseDetails } from ".."
import CompletedCourseCard from "../CompletedCourseCard"
import Curriculum from "../Curriculum"

// const overviewDetails: OverviewType = {
//   professors: [
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//   ],
//   description:
//     "Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms.",
// }

type Props = {
  className?: string
}

const NoticeCard = ({
  className,
  note,
  cta: { label, action },
}: PropsWithClassName<{
  note: string
  cta: { label: string; action: () => void }
}>) => {
  return (
    <div
      className={clsx(
        "border border-surface-200 rounded-2xl bg-warning-50 p-2 backdrop:blur flex items-center justify-between flex-wrap",
        className
      )}
    >
      <Typography variant="strong">{note}</Typography>
      <Button onClick={() => action()} className="sm:mt-0 mt-1">
        {label}
      </Button>
    </div>
  )
}

const CourseOverview = ({ className }: Props) => {
  const navigate = useNavigate()
  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug?: string
  }
  const learningItemType = "course"

  const userName = getAuthInfo()?.user.username
  const { data } = useQuery({
    queryKey: queries.profile.certificateExistence(
      userName,
      learningItemType,
      slug
    ).queryKey,
    queryFn: () =>
      api.profile.credentials.checkExistence({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          username: userName!,
          itemType: learningItemType,
          itemSlug: slug,
        },
      }),
  })

  const { course, showLearningPausedBanner: toggleBanner } = useCourseDetails()

  const { data: overview, error } = useQuery({
    queryFn: () =>
      api.learningItems.overview({
        urlParams: {
          itemType: "course",
          itemId: course.id,
        },
      }),
    enabled: Boolean(course.id),
    queryKey: queries.learningItems.overview("course", course.id).queryKey,
  })

  useEffect(() => {
    if (error instanceof APIError && error.statusCode === 404) {
      toggleBanner(true)
    }
  }, [error])

  return (
    <div className={clsx("pb-6", className)}>
      {data?.serialNumber && (
        <CompletedCourseCard
          serialNumber={data.serialNumber}
          className="mb-3"
        />
      )}

      {overview && (
        <>
          {overview.progress == null ||
            (overview.progress.completedUserPlannerItems !==
              overview.progress.totalUserPlannerItems && (
              <Card
                padding={2}
                className="mb-3 border-2 border-onSurface-300 gap-1"
                infoSlot={
                  <CourseInfoAndProgress
                    showCount
                    barColor="black"
                    title={
                      overview.progress == null ||
                      overview.progress.completedUserPlannerItems === 0
                        ? "Start your course"
                        : "Continue where you left off"
                    }
                    currentItemNumber={
                      overview.progress?.completedUserPlannerItems || 0
                    }
                    numberOfItems={
                      overview.progress?.totalUserPlannerItems || 0
                    }
                  />
                }
                actionSlot={
                  course.id ? (
                    <Button
                      component={Link}
                      to={getLearningItemURL({
                        route: parentSlug
                          ? routes.learningModuleWithParent
                          : routes.learningModule,
                        itemSlug: slug,
                        parentSlug: parentSlug,
                        parentType: learningItemType,
                        queryParams: { plannerId: course.id.toString() },
                      })}
                    >
                      {overview.progress == null ||
                      overview.progress.completedUserPlannerItems === 0
                        ? "Start Learning"
                        : "Continue Learning"}
                    </Button>
                  ) : undefined
                }
              />
            ))}

          {overview.dueAssignments > 0 && (
            <NoticeCard
              className="mb-2"
              note={`You have ${pluralize(
                "assignment",
                overview.dueAssignments
              )} due`}
              cta={{
                label: `Finish ${pluralize(
                  "Skill Evidence (Assignment)",
                  overview.dueAssignments,
                  { skipCount: true }
                )}`,
                action: () =>
                  navigate(
                    `${routes.course.replace(":slug", slug)}/assignments`
                  ),
              }}
            />
          )}

          {overview.dueAssessments > 0 && (
            <NoticeCard
              className="sm:mb-3 mb-2"
              note={`You have ${pluralize(
                "assessment",
                overview.dueAssessments
              )} due`}
              cta={{
                label: `Finish ${pluralize(
                  "Assessment",
                  overview.dueAssessments,
                  { skipCount: true }
                )}`,
                action: () =>
                  navigate(
                    `${routes.course.replace(":slug", slug)}/assessments`
                  ),
              }}
            />
          )}
        </>
      )}

      <NextUp learningItemType={course.type} learningItemId={course.id} />

      {/* <Overview data={overviewDetails} /> */}

      {course && <Curriculum plannerId={course.id} />}
    </div>
  )
}

export default CourseOverview
