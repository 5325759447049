import { addDoc, collection } from "firebase/firestore"
import { db } from "firebaseInit"
import { getAuthInfo } from "utils/helpers"

type Data = {
  url: string
  body: any
  method: "GET" | "POST" | "PUT"
}

export const logOnFirebase = async (data: Data) => {
  try {
    const COLLECTION_NAME = "ito2023Logs"

    const additionalData = {
      user: getAuthInfo()?.user.uuid,
      timestamp: new Date().toISOString(),
    }
    data = {
      ...additionalData,
      ...data,
    }
    // console.log("Logging", data)
    addDoc(collection(db, COLLECTION_NAME), data)
  } catch (e) {
    return
  }
}
