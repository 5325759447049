import { Divider, Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import { OrderItemDetails, OrderItemType } from "api/resources/orders/types"
import CertificationPlaceholder from "assets/Placeholder/certificate_placeholder.svg"
import CoursePlaceholder from "assets/Placeholder/course_placeholder.svg"
import React from "react"
import { useTheme } from "react-jss"
import useFormatPrice from "utils/hooks/useFormatPrice"

const Discount = ({
  currency,
  discountAmount,
}: Pick<OrderItemDetails, "currency" | "discountAmount">) => {
  const formatPrice = useFormatPrice()

  // const upfrontDiscountAvailable = false

  if (!discountAmount) return null
  return (
    <div>
      <Divider weight="light" color="onSurface.200" className="my-3" />
      <div className="flex items-start justify-between">
        <div className="w-[80%]">
          <div className="flex items-center">
            <Typography variant="strong" className="me-1">
              Discount
            </Typography>
          </div>
          {/* {upfrontDiscountAvailable && (
            <Typography
              variant="smallBody"
              color="onSurface.600"
              className="mt-0.5"
            >
              Additional Upfront Payment Discount of ₹30,000 if full fees is
              paid before 14 March 2023
            </Typography>
          )} */}
        </div>
        <Typography variant="strong">
          {currency.symbol} {formatPrice(discountAmount)}
        </Typography>
      </div>
    </div>
  )
}

export const ItemDetails = ({
  items,
  symbol,
}: { symbol: string } & Pick<OrderItemDetails, "items">) => {
  const theme = useTheme<Theme>()
  const formatPrice = useFormatPrice()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <>
      {items.map(product => {
        const productItem = product.productItems[0]
        const FallbackImage =
          productItem.itemType === OrderItemType.COURSE
            ? CoursePlaceholder
            : CertificationPlaceholder
        return (
          <React.Fragment key={product.id}>
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-start">
                {isSmUp && (
                  <div className="h-[53px] w-10 overflow-hidden rounded-md me-1.5">
                    <img
                      src={productItem.image ?? FallbackImage}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = FallbackImage
                      }}
                      alt="thumbnail"
                      height={53}
                      width={80}
                    />
                  </div>
                )}
                <div>
                  <Typography
                    variant="preTitle"
                    color="onSurface.400"
                    className="mb-0.75"
                  >
                    {productItem.itemType}
                  </Typography>
                  <Typography variant="strong">{product.itemName}</Typography>
                  {/* Product does not have learning mode */}
                  {/* <Typography
            variant="smallBody"
            color="onSurface.600"
            className="mt-0.5"
          >
            {productItem.learningMode}
          </Typography> */}
                </div>
              </div>
              <Typography variant="strong">
                {symbol}
                {formatPrice(product.finalAmount)}
              </Typography>
            </div>
          </React.Fragment>
        )
      })}
    </>
  )
}

const OrderBreakdown = ({
  items,
  currency,
  discountAmount,
  finalAmount,
}: Pick<
  OrderItemDetails,
  "items" | "currency" | "discountAmount" | "finalAmount"
>) => {
  const formatPrice = useFormatPrice()
  return (
    <React.Fragment>
      <Typography variant="preTitle" className="mt-3 mb-2 sm:px-0 px-2">
        Order breakdown
      </Typography>

      <div className="sm:p-3 p-2 bg-surface-50 sm:border border-y border-surface-100 sm:rounded-2xl">
        <ItemDetails items={items} symbol={currency.symbol} />

        <div className="sm:ms-[88px]">
          <Discount discountAmount={discountAmount} currency={currency} />
          <Divider weight="light" color="onSurface.200" className="my-3" />

          <div className="flex items-center justify-between">
            <span>
              <Typography variant="strong">Total</Typography>
              <Typography variant="smallBody" color="onSurface.600">
                Inclusive of taxes
              </Typography>
            </span>
            <Typography variant="title3">
              {currency.symbol}
              {formatPrice(finalAmount)}
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrderBreakdown
