import React from "react"

const BellRing = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.83 15.3075C13.1873 16.6412 12.3959 18.012 11.0622 18.3693C9.72852 18.7267 8.35768 17.9352 8.00032 16.6016M9.91373 5.07921C10.1672 4.62312 10.2507 4.07187 10.105 3.52804C9.80723 2.41665 8.66486 1.75711 7.55347 2.0549C6.44209 2.3527 5.78254 3.49507 6.08033 4.60646C6.22605 5.15029 6.574 5.58593 7.02158 5.85415M14.3674 8.16624C14.0699 7.05613 13.2758 6.12783 12.1598 5.58555C11.0437 5.04327 9.6971 4.93144 8.4162 5.27466C7.13531 5.61787 6.02503 6.38802 5.32963 7.41568C4.63423 8.44333 4.41067 9.64432 4.70812 10.7544C5.20027 12.5911 5.1047 14.0565 4.79391 15.1699C4.43969 16.439 4.26258 17.0735 4.31043 17.2009C4.36517 17.3467 4.40477 17.3867 4.54996 17.4429C4.67687 17.4921 5.21001 17.3492 6.2763 17.0635L16.1639 14.4142C17.2301 14.1285 17.7633 13.9856 17.8486 13.8796C17.9462 13.7583 17.9605 13.7038 17.935 13.5502C17.9128 13.4159 17.4421 12.955 16.5008 12.0331C15.6749 11.2242 14.8595 10.003 14.3674 8.16624Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BellRing
