import clsx from "clsx"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import React from "react"

const TestimonialCard = ({
  testimony,
  profile,
}: {
  testimony: string
  profile: {
    picture: string
    name: string
    profession: string
  }
}) => {
  const { picture, name, profession } = profile
  return (
    <div
      className={clsx(
        "px-2 py-3 rounded-lg sm:w-[202px] w-full bg-white flex flex-col justify-between shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)] sm:m-2 m-0"
      )}
    >
      <SwitzerTypography
        className="mb-2 !text-ito-midnight"
        variant="smallBody"
      >
        {testimony}
      </SwitzerTypography>
      <div className="flex gap-1">
        <img alt="profile-img" className="w-6 h-6 rounded-full" src={picture} />
        <div>
          <SwitzerTypography
            className="!text-ito-midnight"
            style={{
              fontFamily: "Switzer Bold",
            }}
            variant="strong"
          >
            {name}
          </SwitzerTypography>
          <SwitzerTypography color="onSurface.500" variant="smallBody">
            {profession}
          </SwitzerTypography>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
