import { Button } from "@suraasa/placebo-ui"
import React, { useEffect, useState } from "react"
import { useTimer } from "react-timer-hook"

type Props = {
  resendAt?: string
  text?: string
  onClick: () => void
  timerText?: string
  defaultEnabled?: boolean
}

function Timer({
  expiryTimestamp,
  onExpire,
}: {
  expiryTimestamp: string
  onExpire: () => void
}) {
  const { seconds, minutes } = useTimer({
    expiryTimestamp: new Date(expiryTimestamp),
    onExpire,
  })

  return (
    <div>
      {`${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`}
    </div>
  )
}

const ResendOTPButton = ({
  resendAt,
  timerText = "You can resend otp after",
  defaultEnabled = false,
  onClick,
  text = "Resend",
}: Props) => {
  const [canResend, setCanResend] = useState(defaultEnabled)

  useEffect(() => {
    if (resendAt) if (new Date(resendAt) > new Date()) setCanResend(false)
  }, [resendAt])

  return (
    <Button disabled={!canResend} nudge="left" variant="text" onClick={onClick}>
      {resendAt && !canResend ? (
        <>
          {timerText}&nbsp;
          <Timer
            expiryTimestamp={resendAt}
            onExpire={() => setCanResend(true)}
          />
        </>
      ) : (
        text
      )}
    </Button>
  )
}

export default ResendOTPButton
