import { Avatar, Tag, Typography } from "@suraasa/placebo-ui"
import { Comment as CommentType } from "api/resources/discussions/types"
import clsx from "clsx"
import { formatDistance } from "date-fns"
import { highlightHashtags } from "features/Discussions/highlightHastags"
import React from "react"
import { buildUserName } from "utils/helpers"

import CommentMenu from "../CommentMenu"

const Replies = ({
  data,
  commentId,
  className,
  discussionId,
}: {
  commentId: string
  data: Omit<CommentType, "replies">
  className?: string
  discussionId: string
}) => {
  const datePublishStatus =
    data.dateCreated &&
    formatDistance(new Date(data.dateCreated), new Date(), {
      addSuffix: true,
    })

  const fullName = buildUserName({
    firstName: data.user.firstName,
    lastName: data.user.lastName,
  })

  return (
    <div className={clsx("flex", className)}>
      <div className="me-1 shrink-0">
        <Avatar color="one" size="xs" name={fullName} src={data.user.photo} />
      </div>
      <div className="mb-2 flex grow">
        <div className="grow flex flex-col">
          <div className="flex mb-1 flex-wrap items-center">
            <Typography className="me-1" variant="strongSmallBody">
              {buildUserName({
                firstName: data.user.firstName,
                lastName: data.user.lastName,
              })}
            </Typography>
            {data.isAuthor && (
              <Tag label="Author" color="primary" className="me-1" />
            )}
            <Typography color="onSurface.500" variant="smallBody">
              {datePublishStatus}
            </Typography>
          </div>
          <div className="border p-1.5 bg-onSurface-50 border-onSurface-200 rounded-2xl rounded-tl">
            <Typography
              color="onSurface.800"
              className="whitespace-pre-line"
              style={{ wordBreak: "break-word" }}
              variant="smallBody"
            >
              {highlightHashtags(data.text)}
            </Typography>
          </div>
        </div>

        <CommentMenu
          commentId={commentId}
          discussionId={discussionId}
          isSelf={data.user.isSelf}
          replyId={data.id}
          firstName={data.user.firstName}
        />
      </div>
    </div>
  )
}

export default Replies
