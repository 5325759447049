import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Radio,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Cart, SavedAddress as Address } from "api/resources/payment/types"
import { APIError } from "api/utils"
import SlidingSheet from "components/SlidingSheet"
import TruncatedText from "components/TruncatedText"
import { Plus, Trash, Xmark } from "iconoir-react"
import React, { useState } from "react"
import { useTheme } from "react-jss"
import { handleErrors, pluralize } from "utils/helpers"

import AddNewAddress from "./AddNewAddress"

const BillingAddress = ({
  billingAddress,
  onSave,
  open,
  handleClose,
}: {
  onSave: () => void
  open: boolean
  handleClose: () => void
} & Pick<Cart, "billingAddress">) => {
  const [showAddressForm, setShowAddressForm] = useState(false)

  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const {
    data: savedAddresses,
    isLoading,
    isError,
    isSuccess,
    refetch: refetchSavedAddresses,
  } = useQuery({
    enabled: open,
    queryFn: () => api.payment.listSavedAddresses(),
    queryKey: queries.payment.address().queryKey,
  })

  const deleteAddress = async (uuid: string) => {
    try {
      await api.payment.deleteAddress({
        urlParams: {
          uuid,
        },
      })
      onSave()
      refetchSavedAddresses()
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e)
      }
    }
  }

  const addBillingAddressToCart = async (address: Address) => {
    try {
      await api.payment.updateCart({
        data: { billingAddress: address.uuid },
      })
      onSave()
      handleClose()
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
    }
  }

  const onBillingAddressAdd = (address: Address) => {
    refetchSavedAddresses()
    addBillingAddressToCart(address)
    setShowAddressForm(false)
  }

  return (
    <SlidingSheet
      fullScreen={isSmDown}
      open={open}
      onClose={handleClose}
      from="end"
      className="p-3 flex flex-col justify-between w-[400px]"
    >
      {isLoading && (
        <div className="flex items-center justify-center p-4 h-full">
          <CircularProgress />
        </div>
      )}
      {isError && (
        <div className="border border-critical-500 bg-critical-100 rounded-2xl p-2">
          <Typography>
            There was an error in fetching your saved addresses.
            <br /> Please contact care@suraasa.com
          </Typography>
        </div>
      )}
      {isSuccess && (
        <>
          <div>
            <Button
              startAdornment={<Xmark />}
              variant="text"
              color="black"
              onClick={handleClose}
            >
              Close
            </Button>
            {savedAddresses.length > 0 ? (
              <>
                <Typography variant="title3" className="my-2">
                  {pluralize("Saved Address", savedAddresses.length, {
                    skipCount: true,
                  })}
                </Typography>
                {savedAddresses.map((address, idx) => (
                  <div
                    key={idx}
                    className="flex items-center justify-between p-2 border border-surface-200 bg-surface-500 rounded-2xl mb-1"
                  >
                    <div className="flex items-center">
                      <Radio
                        onChange={() => {
                          addBillingAddressToCart(address)
                        }}
                        color="primary"
                        checked={billingAddress?.uuid === address.uuid}
                      />
                      <div className="mx-2">
                        <Typography variant="strong">{address.name}</Typography>

                        <TruncatedText
                          hideActionButton
                          maxLength={60}
                          style={{
                            whiteSpace: "pre-wrap",
                          }}
                          variant="smallBody"
                        >
                          {[
                            address.address,
                            address.state.name,
                            address.country.name,
                            address.pincode,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </TruncatedText>
                      </div>
                    </div>
                    <IconButton onClick={() => deleteAddress(address.uuid)}>
                      <Trash color="red" />
                    </IconButton>
                  </div>
                ))}

                {/* Add new address */}
                {showAddressForm ? (
                  <div>
                    <Divider
                      weight="light"
                      className="my-3"
                      color="onSurface.200"
                    />
                    <AddNewAddress onAdd={onBillingAddressAdd} />
                  </div>
                ) : (
                  <Button
                    variant="text"
                    startAdornment={<Plus />}
                    className="mt-1"
                    onClick={() => setShowAddressForm(true)}
                  >
                    Add New Address
                  </Button>
                )}
              </>
            ) : (
              <AddNewAddress onAdd={onBillingAddressAdd} />
            )}
          </div>
        </>
      )}
    </SlidingSheet>
  )
}

export default BillingAddress
