import {
  CircularProgress,
  Divider,
  Pagination,
  Table,
  Tag,
  TagColors,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { ReferralHistory as ReferralHistoryType } from "api/resources/ito/referAndEarn/types"
import clsx from "clsx"
import { format } from "date-fns"
import { useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { pluralize } from "utils/helpers"

const useStyles = createUseStyles((theme: Theme) => ({
  table: {
    paddingTop: "4px",
    background: "#FFFFFF",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "8px",
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(1),

    "& thead": {
      borderBottom: `1px solid ${theme.colors.surface[200]}`,
      color: theme.colors.onSurface[400],
      fontWeight: 600,
      "& .Typography-root": {
        fontSize: "14px",
      },
    },
    "& th": {
      // padding: "24px 24px 24px 8px",
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& td:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& tr": {
      height: "55px",
      borderBottom: `1px solid ${theme.colors.surface[300]}`,
    },
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
    position: "absolute",
    left: "-28px",
  },
  status: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
  },
  mobileTable: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    "& hr": {
      margin: theme.spacing(0, 1),
      background: theme.colors.onSurface[500],
      width: "0.5px",
    },
  },
  mobileTableRoot: {
    padding: theme.spacing(2, 1),
  },
  mobileTableDivider: {
    margin: theme.spacing(0, -2),
  },
  loading: {},
}))

const getTag = (
  paid: ReferralHistoryType["registrationFeePaid"]
): {
  tagColor: TagColors
  text: string
} => {
  switch (paid) {
    case true:
      return {
        tagColor: "success",
        text: "Completed",
      }
    case false:
      return {
        tagColor: "warning",
        text: "Pending",
      }
    default:
      return {
        tagColor: "primary",
        text: "",
      }
  }
}

type Props = {
  hasWithdrawnAtLeastOnce?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReferralHistory = ({ hasWithdrawnAtLeastOnce = false }: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("sm"))
  const formatDate = (date: string) => format(new Date(date), "d MMM yyyy")

  const [page, setPage] = useState(1)
  const [additionalData, setAdditionalData] = useState({
    totalReferrals: 0,
    pendingReferrals: 0,
    amount: "",
  })

  const referralHistory = useInfiniteQuery({
    queryKey: ["referralHistory", page],
    queryFn: async () => {
      const res = await api.ito.referAndEarn.listReferralHistory({
        params: {
          page,
        },
      })
      if (res.__data) {
        const { currency, pendingCount, pendingAmount, completedCount } =
          res.__data.data
        setAdditionalData({
          totalReferrals: completedCount + pendingCount,
          pendingReferrals: pendingCount,
          amount: currency ? `${currency.symbol}${pendingAmount}` : "",
        })
      }
      return res
    },
    enabled: true,
    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  return (
    <div>
      <Typography variant="title3" className="mb-1.5">
        Referral History
      </Typography>

      <div className="bg-warning-50 border rounded-lg border-warning-200 py-2 px-6 mb-2 sm:mb-0">
        {additionalData.pendingReferrals > 0 && additionalData.amount ? (
          <Typography variant="smallBody">
            You have referred a total of {additionalData.totalReferrals} people,
            out of which&nbsp;
            {pluralize("is", additionalData.pendingReferrals, {
              plural: "are",
            })}
            &nbsp;yet to complete their registration. You can still&nbsp;
            <b>
              earn&nbsp;
              {additionalData.amount}&nbsp;
            </b>
            once they finish their registration.
          </Typography>
        ) : (
          <Typography variant="smallBody">
            You have referred a total of {additionalData.totalReferrals} people.
          </Typography>
        )}
      </div>

      <div
        className={clsx("relative min-w-full", {
          "min-h-[400px]":
            referralHistory.isLoading || referralHistory.isFetching,
        })}
      >
        <div
          className={clsx(
            "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden",
            {
              "absolute !block":
                referralHistory.isLoading || referralHistory.isFetching,
            }
          )}
        >
          <CircularProgress />
        </div>
        {isXs &&
          referralHistory.isSuccess &&
          referralHistory.data.pages.map(page =>
            page.data.map(
              ({ fullName, dateCreated, registrationFeePaid }, index) => {
                const tag = getTag(registrationFeePaid)
                return (
                  <>
                    <Divider
                      className={classes.mobileTableDivider}
                      color="onSurface.300"
                      weight="light"
                    />
                    <div key={index} className={classes.mobileTableRoot}>
                      <div className={classes.mobileTable}>
                        <div>
                          <Typography variant="subtitle1">
                            {fullName}
                          </Typography>
                          <Typography variant="smallBody">
                            {formatDate(dateCreated)}
                          </Typography>
                        </div>

                        <div className={classes.status}>
                          <Tag color={tag.tagColor} label={tag.text} />
                        </div>
                      </div>
                    </div>
                    {/* Show divider for last item only */}
                    {/* {referrals.data.length - 1 === index && (
                    <Divider
                      className={classes.mobileTableDivider}
                      color="onSurface.300"
                      weight="light"
                    />
                  )} */}
                  </>
                )
              }
            )
          )}
        {!isXs && referralHistory.isSuccess && (
          <Table
            data={referralHistory.data.pages.map(i => i.data).flat()}
            className={classes.table}
            headers={[
              {
                title: "Name",
                id: "fullName",
                align: "left",
              },
              {
                title: "Date Initiated",
                id: "dateCreated",
                align: "left",
                columnComponent: ({ row }) => (
                  <td>
                    <Typography variant="body">
                      {formatDate(row.dateCreated)}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Registration Status",
                id: "registrationFeePaid",
                align: "left",
                columnComponent: ({ row }) => {
                  const tag = getTag(row.registrationFeePaid)
                  return (
                    <td>
                      <div className={classes.status}>
                        <Tag color={tag.tagColor} label={tag.text} />
                      </div>
                    </td>
                  )
                },
              },
            ]}
          />
        )}
      </div>
      {referralHistory.isSuccess &&
        referralHistory.data.pages[0].total > 10 && (
          <Pagination
            page={page}
            total={referralHistory.data.pages[0].total}
            onChange={setPage}
          />
        )}
    </div>
  )
}

export default ReferralHistory
