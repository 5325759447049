import { ValueOf } from "utils/types"

import { Country, Currency, State } from "../global/types"

export type Cart = {
  uuid: string
  promocode: {
    id: string
    code: string
  } | null
  itemTotal: number
  billingAddress: {
    name: string
    address: string | null
    pincode: string | null
    city: string
    country: string
    state: string
    uuid: string
  } | null
  promocodeDiscount: number
  totalTaxCharged: number
  cartTotal: number
  currency: Pick<Currency, "code" | "symbol">
  country: Pick<Country, "name" | "isoCode">
  cartTax: {
    tax: {
      rate: number
      name: string
    }
    taxAmount: number
  }[]
  cartItems: {
    productId: number
    productName: string
    price: {
      country: Country
      currency: Currency
      tier: {
        tierId: string
        price: number
      }
      price: number
      mrp: number
      globallyAvailable: false
      inclusiveOfTax: true
    }
    image: string
  }[]
}

export type SavedAddress = {
  id: number
  country: Pick<Country, "name" | "uuid">
  state: Pick<State, "name" | "uuid">
  uuid: string
  name: string
  address: string | null
  pincode: string | null
  city: string
  user: string
}

export const paymentMethod = {
  STRIPE: "STRIPE_1",
  RAZORPAY: "RZP_1",
  JUSPAY: "JUSPAY_1",
}

export type PaymentMethod = {
  paymentMethodId: ValueOf<typeof paymentMethod>
  paymentMethodDetails: {
    orderId: string
    amount: number
    clientSecret?: string
    paymentLink?: string
  }
  paymentGatewayOrder?: {
    isFreeOrder: boolean
  }
  orderPaymentPlanId: string
  createdAt: string
  id: string
}
