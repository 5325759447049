import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import { Tab, Tabs as TabsType } from "./hooks/useTabs"
import styles from "./TabBar.module.css"
import TabItem from "./TabItem"

type Props = {
  tabs: TabsType
  activeTab: Tab
  onChange: (newTab: string) => void
  className?: string
}

const TabBar = ({ className, tabs, activeTab, onChange }: Props) => {
  const getTabTitle = (tab: Tab) => {
    return (
      <Typography
        variant={tab.name === activeTab.name ? "strong" : "body"}
        display="initial"
        className="flex gap-0.5 items-center"
      >
        {tab.name}{" "}
        {Boolean(tab.count) && (
          <span
            style={{
              fontWeight: 500,
              fontSize: 12,
              lineHeight: "130%",
            }}
            className={clsx("px-0.5 py-0.25 rounded-md", {
              "bg-secondary-900 text-surface-500": tab.name === activeTab.name,
              "bg-onSurface-200 text-onSurface-500":
                tab.name !== activeTab.name,
            })}
          >
            {tab.count}
          </span>
        )}
      </Typography>
    )
  }

  return (
    <div className={clsx(styles.tabContainer, className)}>
      {tabs.map(tab => (
        <TabItem
          isActive={tab.name === activeTab.name}
          key={tab.name}
          onClick={() => onChange(tab.name)}
        >
          {tab.startAdornment ? (
            <div className="flex gap-1 items-center">
              <>
                {tab.startAdornment}
                {getTabTitle(tab)}
              </>
            </div>
          ) : (
            getTabTitle(tab)
          )}
        </TabItem>
      ))}
    </div>
  )
}

export default TabBar
