import { Country } from "api/resources/global/types"
import { useCallback } from "react"
import useCurrentCountry from "utils/hooks/useCurrentCountry"

import { getITOPrices, OlympiadEditions, PriceCategories } from "../utils"

type Props<T extends OlympiadEditions> = {
  country?: Country | null
  edition: T
}

export const useOlympiadPrices = <T extends OlympiadEditions>({
  edition,
  ...props
}: Props<T>) => {
  const detectCountry = useCurrentCountry()
  const country = props?.country ?? detectCountry

  const getPrice = useCallback(
    (key: PriceCategories<typeof edition>) =>
      getITOPrices(edition, key, country?.isoCode),
    [country, edition]
  )

  return { getPrice }
}
