import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
} from "api/types"
import {
  formatErrorResponse,
  formatErrorResponseOld,
  formatSuccessResponse,
  formatSuccessResponseOld,
  logAPIError,
} from "api/utils"

import {
  Country,
  Currency,
  Curricula,
  Language,
  QualificationField,
  State,
  Subject,
  TeachingLevel,
  TeachingRole,
} from "./types"
import { urls } from "./urls"

export default {
  listCountries: async ({
    params,
  }: Config): Promise<APIResponse<Country[]>> => {
    try {
      const res = await axios.get(urls.countries.list(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listStates: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<State[]>> => {
    try {
      const res = await axios.get(urls.states.list(urlParams.countryId), {
        params,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },

  listLanguages: async ({
    params,
  }: Config): Promise<APIResponse<Language[]>> => {
    try {
      const res = await axios.get(urls.languages.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listSubjects: async ({ params }: Config): Promise<APIResponse<Subject[]>> => {
    try {
      const res = await axios.get(urls.subjects.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  qualificationFields: {
    // This is bulk-create
    create: async ({
      data,
    }: Config): Promise<APIResponseOld<QualificationField[]>> => {
      try {
        const res = await axios.post(urls.qualificationFields.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    list: async ({
      params: { page = -1 },
    }: Config): Promise<APIResponseOld<QualificationField[]>> => {
      try {
        const res = await axios.get(urls.qualificationFields.list(), {
          params: { page },
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  curriculum: {
    create: async ({ data }: Config): Promise<APIResponseOld<Curricula[]>> => {
      try {
        const res = await axios.post(urls.curricula.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponseOld<Curricula[]>> => {
      try {
        const res = await axios.get(urls.curricula.list(), {
          params,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  teachingLevel: {
    create: async ({
      data,
    }: Config): Promise<APIResponseOld<TeachingLevel[]>> => {
      try {
        const res = await axios.post(urls.teachingLevels.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    list: async ({
      params,
    }: Config): Promise<APIResponseOld<TeachingLevel[]>> => {
      try {
        const res = await axios.get(urls.teachingLevels.list(), { params })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  teachingRole: {
    create: async ({
      data,
    }: Config): Promise<APIResponseOld<TeachingRole[]>> => {
      try {
        const res = await axios.post(urls.teachingRoles.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    list: async ({
      params,
    }: Config): Promise<APIResponseOld<TeachingRole[]>> => {
      try {
        const res = await axios.get(urls.teachingRoles.list(), { params })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  listCurrencies: async ({
    params,
  }: Config): Promise<APIResponseOld<Currency[]>> => {
    try {
      const res = await axios.get(urls.currencies.list(), {
        params,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },

  getCurrentCountry: async (): Promise<APIResponse<Country>> => {
    try {
      const res = await axios.get(urls.countries.current())
      return formatSuccessResponse(res)
    } catch (e) {
      const errorResponse = formatErrorResponse(e)
      logAPIError(errorResponse)
      return errorResponse
    }
  },
}
