import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Theme,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email, OTPResponse } from "api/resources/settings/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import ResendOTPButton from "components/ResendOTPButton"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import OTPInput from "react-otp-input"
import { handleErrors } from "utils/helpers"

const useStyles = createUseStyles((theme: Theme) => ({
  containerStyle: {
    justifyContent: "space-between",
  },

  inputStyle: {
    borderRadius: 4,
    width: `41px !important`,
    height: 50,
    border: `2px solid ${theme.colors.onSurface[500]}`,
    ...theme.typography.title2,
    "&:focus": {
      outline: `2px solid ${theme.colors.interactive[400]}`,
      outlineOffset: "1px",
    },
  },
  errorStyle: {
    border: `2px solid ${theme.colors.critical[500]}`,
    color: theme.colors.critical[500],
  },
}))

export default function VerifyEmail({
  dialogTitle,
  close,
  open,
  onVerify,
  onBack,
  email,
  ...props
}: {
  close: () => void
  onVerify: (updatedEmail: Email) => void
  onBack?: () => void
  otpData: OTPResponse
  email: string
  open: boolean
  dialogTitle: string
}) {
  const [loading, setLoading] = useState(false)

  const [otpData, setOtpData] = useState(props.otpData)

  const classes = useStyles()

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ otp: string }>()

  const onSubmit = handleSubmit(async ({ otp }) => {
    setLoading(true)
    try {
      if (otpData) {
        const verificationResponse = await api.settings.verifyOtp({
          data: {
            otp: otp,
            token: otpData.token,
          },
        })
        const res = await api.settings.emails.verify({
          data: {
            token: verificationResponse.token,
          },
        })
        setLoading(false)
        onVerify(res)
      }
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
        setLoading(false)
      }
    }
  })

  const resendOtp = async () => {
    try {
      const res = await api.settings.sendOTP({
        data: {
          token: otpData.token,
        },
      })
      toast.info("We've sent you a new OTP")
      setOtpData(p => ({ ...p, ...res }))
    } catch (e) {
      if (e instanceof APIError) {
        if (e.errors.fieldErrors?.resendAt && e.errors.fieldErrors?.token) {
          setOtpData(p => ({
            ...p,
            resendAt: (e as APIError).errors.fieldErrors?.resendAt as string,
            token: (e as APIError).errors.fieldErrors?.token as string,
          }))
        }
      }
    }
  }

  return (
    <Dialog
      open={open}
      width="sm"
      onAfterClose={() => reset()}
      onRequestClose={close}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <div className="p-1">
            <Typography>
              OTP sent to <b>{email}</b>
            </Typography>
            {onBack && (
              <Button
                nudge="left"
                variant="text"
                className="mt-0.5"
                onClick={onBack}
              >
                Change Email
              </Button>
            )}

            <div className="mt-2">
              <Controller
                control={control}
                name="otp"
                render={({ field }) => (
                  <OTPInput
                    numInputs={6}
                    {...field}
                    containerStyle={classes.containerStyle}
                    inputStyle={clsx(classes.inputStyle, {
                      [classes.errorStyle]: Boolean(errors.otp),
                    })}
                    inputType="number"
                    renderInput={props => <input {...props} />}
                    shouldAutoFocus
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
            </div>
            {Boolean(errors.otp) && (
              <Typography
                className="mt-1"
                color="critical.500"
                variant="smallBody"
              >
                {errors.otp?.message}
              </Typography>
            )}

            {/* <div className="flex flex-wrap items-center mt-3">
              <Typography className="me-1 shrink-0">
                Didn’t receive OTP?
              </Typography>
              <ResendOTPButton
                resendAt={otpData.resendAt}
                text="Send Again"
                onClick={() => resendOtp()}
              />
            </div> */}
          </div>
        </DialogContent>

        <DialogFooter
          actions={{
            primary: {
              label: "Verify",
              type: "submit",
              loading,
            },
            secondary: null,
          }}
        />
      </form>
    </Dialog>
  )
}
