import clsx from "clsx"
import React from "react"
import { PropsWithClassName } from "utils/types"

const Paper = ({
  children,
  className,
}: PropsWithClassName<React.PropsWithChildren>) => {
  return (
    <div
      className={clsx(
        "bg-common-white-500 border-x-0 sm:border border-surface-200 rounded-none sm:rounded-xl -mx-2 sm:mx-0 p-2 overflow-x-auto",
        className
      )}
    >
      {children}
    </div>
  )
}

export default Paper
