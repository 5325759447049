import {
  Button,
  Container,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import Fallback from "assets/Fallback/course-bg-1.png"
import clsx from "clsx"
import PageNavigation from "components/PageNavigation"
import { LearningItem } from "features/LearningItems/Nav"
import { Message } from "iconoir-react"
import React from "react"
import { Link, NavLink } from "react-router-dom"

import ImageBackgroundCard from "../ImageBackgroundCard"
import styles from "./Header.module.css"
type Props = {
  className?: string
  // To show Discussions button, defaults to false
  showDiscussionsButton?: boolean
  data: LearningItem[]
  itemSlug: string
  image: string | null
  itemName: string
}

const LearningItemHeader = ({
  image,
  className,
  showDiscussionsButton = false,
  data,
  itemSlug,
  itemName,
}: Props) => {
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const smUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <ImageBackgroundCard background={image ?? Fallback} className={className}>
      <Container>
        <div className="pt-2 pb-5 text-common-white-500">
          <PageNavigation color="light" />
          <div className="flex items-center justify-between">
            <Typography variant="title2">{itemName}</Typography>
            {showDiscussionsButton && (
              <Button
                component={Link}
                to={`/discussions/?course=${itemSlug}`}
                startAdornment={<Message />}
                variant="text"
                color="white"
              >
                Discussions
              </Button>
            )}
          </div>
        </div>
      </Container>
      <Container fullWidth={!smUp}>
        {!mdUp && (
          <div className={clsx(styles.customNav)}>
            {data.map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) =>
                  clsx(
                    "text-white pb-1.25 w-fit me-3 sm:me-5 relative shrink-0",
                    {
                      "ms-2": !smUp && index === 0,
                      [styles.customBorder]: isActive,
                    }
                  )
                }
              >
                <Typography variant="subtitle2">{item.name}</Typography>
              </NavLink>
            ))}
          </div>
        )}
      </Container>
    </ImageBackgroundCard>
  )
}

export default LearningItemHeader
