import { Typography } from "@suraasa/placebo-ui"
import { Timer } from "iconoir-react"
import React from "react"

export type CardProps = {
  img: string
  duration?: string
  title: string
  content: string
  link: string
}

const Card = ({ img, duration, title, content, link }: CardProps) => {
  return (
    <a
      href={link}
      target="_blank"
      className="overflow-hidden rounded-xl bg-white relative mr-3"
      rel="noreferrer"
    >
      {duration && (
        <div className="p-0.5 flex space-x-0.5 rounded bg-white/50 absolute top-2 left-2">
          <Timer width={20} height={20} color="#1E293B" />
          <Typography variant="smallBody" color="onSurface.800">
            {duration}
          </Typography>
        </div>
      )}
      <img
        src={img}
        alt="resource-img"
        className="h-[141px] object-cover w-full"
      />
      <div className="p-2">
        <Typography variant="title4" color="onSurface.800" className="mb-1">
          {title}
        </Typography>
        <Typography variant="smallBody" color="onSurface.800">
          {content}
        </Typography>
      </div>
    </a>
  )
}

export default Card
