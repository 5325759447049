import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Divider,
  Select,
  TextField,
  theme,
  toast,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { QualificationField } from "api/resources/global/types"
import {
  Evidence,
  modeOfLearningChoices,
  Qualification,
  QualificationLevel,
} from "api/resources/profile/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import RemoveDialog from "features/Profile/components/RemoveDialog"
import { useState } from "react"
import * as React from "react"
import { useContext, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { getAuthInfo, narrowError } from "utils/helpers"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import ProfileContext from "../../../context"
import UploadEvidenceSection from "../UploadEvidenceSection"

type FormData = Omit<Qualification, "isVerified" | "id">

type Props = Pick<DialogProps, "open"> & {
  id: Qualification["id"] | null
  toggle: () => void
}

type Option = {
  value: string
  label: string
}

const formatOptions = (options: QualificationField[]): Option[] =>
  options.map(item => ({ value: item.uuid, label: item.name }))

const EditDialog = ({ id, open, toggle }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    control,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()

  const {
    academics: { qualifications },
  } = useContext(ProfileContext)

  const [openRemoveDialog, toggleRemoveDialog] = useState(false)
  const [loading, toggleLoading] = useState(false)
  const [removeLoading, toggleRemoveLoading] = useState(false)

  const isEditable = Boolean(id)
  const [qualificationLevelChoices, setQualificationLevelChoices] =
    React.useState<QualificationLevel[]>([])

  const [qualificationFieldChoices, setQualificationFieldChoices] =
    React.useState<Option[]>([])

  const newEvidences = useArray<File | string>([])
  const evidencesFiles = useArray<Evidence>([])
  const evidencesFilesToBeDeleted = useArray<Evidence["id"]>([])
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const startDate = watch("startDate")
  const endDate = watch("endDate")

  useEffect(() => {
    clearErrors(["startDate", "endDate"])
  }, [startDate, endDate, clearErrors])

  const resetForm = () => {
    newEvidences.clear()
    evidencesFiles.clear()
    evidencesFilesToBeDeleted.clear()
    setQualificationFieldChoices([])
    reset({})
  }

  const uploadEvidence = async (data: Qualification) => {
    const evidences = new FormData()

    newEvidences.array.forEach(item => {
      if (typeof item === "string") {
        evidences.append("url[]", item)
      } else {
        evidences.append("file[]", item)
      }
    })

    const res = await api.profile.qualification.evidence.create({
      data: evidences,
      urlParams: {
        id: data.id,
      },
    })
    return res
  }

  const deleteEvidence = async (evidenceId: Qualification["id"]) =>
    api.profile.qualification.evidence.delete({
      data: {
        evidences: evidencesFilesToBeDeleted.array,
      },
      urlParams: {
        id: evidenceId,
      },
    })

  const handleRemove = async () => {
    if (!id) return

    toggleRemoveLoading(true)

    try {
      await api.profile.qualification.delete({ urlParams: { id } })
      qualifications.remove(id)
      toast.success("Removed successfully.")
      toggleRemoveDialog(false)
      toggle()
    } catch (e) {
      if (narrowError(e)) {
        handleErrors(e)
      }
    }

    toggleRemoveLoading(false)
  }

  const createQualificationField = async (
    qualificationField: QualificationField["name"]
  ) => {
    const res = await api.global.qualificationFields.create({
      data: {
        new: [
          {
            qualificationField,
            userUuid: getAuthInfo()?.user.uuid,
          },
        ],
      },
    })
    if (res.isSuccessful) {
      setQualificationFieldChoices(s => [...s, ...formatOptions(res.data)])
      setValue("qualificationFieldId", res.data[0].uuid)
    } else {
      setValue("qualificationFieldId", "")
      toast.error("You cannot add custom option at the moment.")
    }
  }

  useEffect(() => {
    const fetchQualificationLevelsAndFields = async () => {
      const resQualificationLevel = await api.profile.listQualificationLevels()
      if (resQualificationLevel.isSuccessful) {
        setQualificationLevelChoices(resQualificationLevel.data)
      } else if (resQualificationLevel.errors.message) {
        toast.error(resQualificationLevel.errors.message)
      }

      const resQualificationField = await api.global.qualificationFields.list({
        params: {
          page: -1,
        },
      })
      if (resQualificationField.isSuccessful) {
        setQualificationFieldChoices(formatOptions(resQualificationField.data))
      } else if (resQualificationField.errors.message) {
        toast.error(resQualificationField.errors.message)
      }
    }
    if (!isEditable && open) {
      fetchQualificationLevelsAndFields()
    }
  }, [open, isEditable])

  useEffect(() => {
    const fetchData = async () => {
      toggleLoading(true)
      const [resQualificationLevel, resQualificationField, resQualification] =
        await Promise.all([
          api.profile.listQualificationLevels(),
          api.global.qualificationFields.list({
            params: {
              page: -1,
            },
          }),
          api.profile.qualification.retrieve({
            urlParams: { id: id || "" },
          }),
        ])

      if (resQualificationLevel.isSuccessful) {
        setQualificationLevelChoices(resQualificationLevel.data)
      } else if (resQualificationLevel.errors.message) {
        toast.error(resQualificationLevel.errors.message)
      }

      if (resQualificationField.isSuccessful) {
        setQualificationFieldChoices(formatOptions(resQualificationField.data))
      } else if (resQualificationField.errors.message) {
        toast.error(resQualificationField.errors.message)
      }

      if (resQualification.isSuccessful) {
        const { evidences, ...data } = resQualification.data
        evidencesFiles.set(evidences)
        reset(data)
        const { qualificationField, qualificationFieldId } = data
        if (
          !qualificationFieldChoices.find(
            i => i.value === qualificationFieldId
          ) &&
          qualificationField
        ) {
          setQualificationFieldChoices(s => [
            ...s,
            {
              label: qualificationField.name,
              value: qualificationField.uuid,
            },
          ])
        }
      }
      toggleLoading(false)
    }

    if (isEditable && open) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditable, open, reset, toggleLoading])

  const onSubmit = handleSubmit(async formData => {
    const apiData = {
      ...formData,
      modeOfLearning: modeOfLearningChoices.find(
        ({ value }) => value === formData.modeOfLearning
      )?.value,
    }

    if (id) {
      if (evidencesFilesToBeDeleted.array.length !== 0) {
        const deleteCertificationFilesRes = await deleteEvidence(id)
        if (!deleteCertificationFilesRes.isSuccessful) {
          if (deleteCertificationFilesRes.errors.message) {
            toast.error(deleteCertificationFilesRes.errors.message)
          }
          return
        }
      }

      const res = await api.profile.qualification.update({
        data: apiData,
        urlParams: { id },
      })
      if (res.isSuccessful) {
        if (newEvidences.array.length > 0) {
          const evidenceRes = await uploadEvidence(res.data)
          if (evidenceRes.isSuccessful) {
            toast.success("Successfully saved.")
            qualifications.update(id, {
              ...res.data,
              evidences: [...evidencesFiles.array, ...evidenceRes.data],
            })
            return toggle()
          }
          if (evidenceRes.errors.message)
            return toast.error(evidenceRes.errors.message)
        } else {
          toast.success("Successfully saved.")
          qualifications.update(id, res.data)
          return toggle()
        }
      } else {
        handleErrors(res, { setter: setError })
      }
    } else {
      try {
        const res = await api.profile.qualification.create({
          data: apiData,
        })
        if (newEvidences.array.length > 0) {
          const evidenceRes = await uploadEvidence(res)

          if (evidenceRes.isSuccessful) {
            toast.success("Successfully saved.")
            qualifications.add({ ...res, evidences: evidenceRes.data })
            return toggle()
          }
          if (evidenceRes.errors.message)
            return toast.error(evidenceRes.errors.message)
        } else {
          toast.success("Successfully saved.")
          qualifications.add(res)
          return toggle()
        }
      } catch (e) {
        if (e instanceof APIError) handleErrors(e, { setter: setError })
      }
    }
  })

  return (
    <>
      <Dialog
        fullScreen={isXs}
        open={open}
        shouldCloseOnOverlayClick={false}
        width="md"
        onAfterClose={resetForm}
        onRequestClose={toggle}
      >
        <DialogTitle onBack={toggle}>
          {isEditable ? "Edit" : "Add New"} Qualification
        </DialogTitle>

        <DialogContent className="flex flex-col gap-3">
          {loading && <LoadingOverlay />}
          {/* <Section1 /> */}

          <TextField
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            inputLabelProps={{ required: true }}
            label="Qualification Name"
            placeholder="Ex: B. Ed."
            fullWidth
            {...register("name", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            error={Boolean(errors.organisationName)}
            helperText={errors.organisationName?.message}
            inputLabelProps={{ required: true }}
            label="Institute Name"
            placeholder="Ex: Harvard University"
            fullWidth
            {...register("organisationName", {
              required: { value: true, message: "Required" },
            })}
          />

          <Controller
            control={control}
            name="qualificationLevelId"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={Boolean(errors.qualificationLevelId)}
                getOptionLabel={obj => obj.name}
                getOptionValue={obj => String(obj.uuid)}
                helperText={errors.qualificationLevelId?.message}
                inputLabelProps={{ required: true }}
                label="Qualification Level"
                options={qualificationLevelChoices}
                placeholder="Select Level"
                value={
                  value
                    ? qualificationLevelChoices.find(c => c.uuid === value)
                    : null
                }
                fullWidth
                mountOnBody
                onBlur={onBlur}
                onChange={val => {
                  onChange(val?.uuid)
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />

          <Controller
            control={control}
            name="qualificationFieldId"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={Boolean(errors.qualificationFieldId)}
                helperText={errors.qualificationFieldId?.message}
                inputLabelProps={{ required: true }}
                label="Qualification Field"
                options={qualificationFieldChoices}
                placeholder="Select Field"
                value={
                  value
                    ? qualificationFieldChoices.find(c => c.value === value)
                    : null
                }
                createable
                fullWidth
                mountOnBody
                onBlur={onBlur}
                onChange={(option, actionMeta) => {
                  onChange(option?.value)
                  if (actionMeta.action === "create-option" && option?.value) {
                    createQualificationField(option.value)
                  }
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          <div>
            <Divider className="my-1" />
          </div>

          {/* <Section2 /> */}
          <>
            <div
              className={clsx("flex gap-3", {
                "flex-col": isXs,
              })}
            >
              <TextField
                error={Boolean(errors.startDate)}
                helperText={errors.startDate?.message}
                inputLabelProps={{ required: true }}
                label="start date"
                placeholder="Ex: Jan 2021"
                type="date"
                fullWidth
                {...register("startDate", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                error={Boolean(errors.endDate)}
                helperText={errors.endDate?.message}
                inputLabelProps={{ required: true }}
                label="end date (or expected)"
                placeholder="Ex: May 2021"
                type="date"
                fullWidth
                {...register("endDate", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
            <TextField
              error={Boolean(errors.grade)}
              helperText={errors.grade?.message}
              label="grade/CGPA"
              placeholder="Ex: 90%"
              fullWidth
              {...register("grade")}
            />
            <Controller
              control={control}
              name="modeOfLearning"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  error={Boolean(errors.modeOfLearning)}
                  helperText={errors.modeOfLearning?.message}
                  inputLabelProps={{ required: true }}
                  label="Mode of learning"
                  options={modeOfLearningChoices}
                  placeholder="Select Learning Mode"
                  value={
                    value && modeOfLearningChoices.find(c => c.value === value)
                  }
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={newValue => onChange(newValue?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </>
          <div>
            <Divider className="my-1" />
          </div>
          <div>
            <UploadEvidenceSection
              buttonLabel="Add Certificate"
              evidenceFiles={evidencesFiles}
              handleEvidenceFilesToBeDeleted={evidencesFilesToBeDeleted.push}
              inputLabel="Certifications (upload upto 3)"
              limit={3}
              maxSize={5}
              newEvidences={newEvidences}
            />
          </div>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              type: "submit",
              loading: isSubmitting,
              onClick: onSubmit,
            },
            tertiary: isEditable
              ? {
                  label: "Remove",
                  color: "critical",
                  variant: "text",
                  onClick: () => toggleRemoveDialog(true),
                }
              : null,
          }}
        />
      </Dialog>
      <RemoveDialog
        handleClose={() => toggleRemoveDialog(false)}
        loading={removeLoading}
        open={openRemoveDialog}
        title="Remove Qualification"
        keepScrollLocked
        onRemove={handleRemove}
      >
        Are you sure you want to remove{" "}
        <b>{qualifications.data.find(item => item.id === id)?.name}</b> from
        your profile?
        {qualifications.data.length === 1 && (
          <>
            <br />
            <br />
            By doing so, you will lose your access to <b>Jobs Platform</b>. To
            get back your access you’ll need to have at least one qualification
            in your profile.
          </>
        )}
      </RemoveDialog>
    </>
  )
}

export default EditDialog
