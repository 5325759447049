/* eslint-disable @typescript-eslint/no-unsafe-call */

import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Award,
  Project,
  Publication,
  TestScore,
} from "api/resources/profile/types"
import clsx from "clsx"
import { format } from "date-fns"
import AwardDialog from "features/Profile/components/Achievements/AwardDialog"
import ProjectDialog from "features/Profile/components/Achievements/ProjectDialog"
import PublicationDialog from "features/Profile/components/Achievements/PublicationDialog"
import TestScoreDialog from "features/Profile/components/Achievements/TestScoreDialog"
import Section from "features/Profile/components/Section"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import { Edit, Plus } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"
import { PropsWithClassName } from "utils/types"

import ProfileContext from "../../context"

const useStyles = createUseStyles(theme => ({
  content: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),

    [theme.breakpoints.only("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    },
  },
  accordionSummary: {
    height: "max-content",
    // paddingRight: theme.spacing(3),
  },
  accordionDetails: {
    position: "relative",
    marginTop: theme.spacing(1),
  },
  details: {
    "& .edit-button": {
      position: "absolute",
      zIndex: "1",
      background: "white",

      [theme.breakpoints.down("sm")]: {
        right: "0px",
      },
      [theme.breakpoints.up("md")]: {
        pointerEvents: "none",
        right: "-30px",
        opacity: "0",
        transition: ".2s all",
      },
    },
    "&:hover .edit-button": {
      [theme.breakpoints.up("md")]: {
        opacity: "1",
        right: "4px",
        pointerEvents: "all",
      },
    },
  },

  headerPaddingReset: {
    paddingLeft: 0,
    paddingRight: theme.spacing(0.5),
  },

  containerMarginBottom: {
    // marginBottom: theme.spacing(28),
    // [theme.breakpoints.down("xl")]: {
    //   marginBottom: theme.spacing(20),
    // },
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: theme.spacing(2),
    // },
  },
}))

const DIALOG_TYPE = {
  AWARD: "AWARD",
  TEST_SCORE: "TEST_SCORE",
  PROJECT: "PROJECT",
  PUBLICATION: "PUBLICATION",
} as const

type DialogType = keyof typeof DIALOG_TYPE

type ToggleDialogConditional<T extends DialogType> = T extends "AWARD"
  ? Award
  : T extends "TEST_SCORE"
  ? TestScore
  : T extends "PROJECT"
  ? Project
  : Publication

const Achievements = ({ className }: PropsWithClassName) => {
  const classes = useStyles()

  const [dialogType, setDialogType] = React.useState<DialogType | null>(null)
  const [dialogData, setDialogData] = React.useState<
    Award | Publication | TestScore | Project
  >()

  const {
    achievements: {
      awards: { data: awards },
      projects: { data: projects },
      publications: { data: publications },
      testScores: { data: testScores },
    },
    isPublic,
  } = React.useContext(ProfileContext)

  const toggleDialog = <
    T extends DialogType,
    K extends ToggleDialogConditional<T>
  >(
    type: T,
    data?: K
  ) => {
    setDialogType(type)
    setDialogData(data)
  }

  const mountDialog = React.useMemo(() => {
    switch (dialogType) {
      case "AWARD":
        return (
          <AwardDialog
            handleClose={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.AWARD}
          />
        )
      case "PROJECT":
        return (
          <ProjectDialog
            handleClose={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.PROJECT}
          />
        )
      case "PUBLICATION":
        return (
          <PublicationDialog
            handleClose={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.PUBLICATION}
          />
        )
      case "TEST_SCORE":
        return (
          <TestScoreDialog
            handleClose={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.TEST_SCORE}
          />
        )
      default:
    }
  }, [dialogType, dialogData])

  return (
    <Section className={clsx(classes.containerMarginBottom, className)}>
      <SectionHeading
        heading={
          <SectionTitle
            actionIcon={
              isPublic ? undefined : (
                <div>
                  <Menu
                    align="end"
                    direction="bottom"
                    menuButton={
                      <IconButton>
                        <Plus />
                      </IconButton>
                    }
                  >
                    <MenuItem onClick={() => toggleDialog("PUBLICATION")}>
                      Publication
                    </MenuItem>
                    <MenuItem onClick={() => toggleDialog("AWARD")}>
                      Award & Honour
                    </MenuItem>
                    <MenuItem onClick={() => toggleDialog("TEST_SCORE")}>
                      Test Score
                    </MenuItem>
                    <MenuItem onClick={() => toggleDialog("PROJECT")}>
                      Project
                    </MenuItem>
                  </Menu>
                </div>
              )
            }
            title="Achievements"
          />
        }
        xPadding={3}
      />

      {publications.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={publications.length}
                title={pluralize("Publication", publications.length, {
                  skipCount: true,
                })}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {publications.map((publication, index) => (
                <Typography display="inline" key={index} variant="smallBody">
                  {publication.title}
                  {index < publications.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {publications.map((publication, index) => (
                <React.Fragment key={index}>
                  <div className={clsx(classes.details, "pr-3")}>
                    {!isPublic && (
                      <IconButton
                        className={clsx("edit-button")}
                        color="black"
                        onClick={() => toggleDialog("PUBLICATION", publication)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <Typography className="mb-0.5" variant="strongSmallBody">
                      {publication.title}
                    </Typography>

                    <Typography
                      className="mb-0.25"
                      color="onSurface.600"
                      variant="smallBody"
                    >
                      {publication.publisher}
                    </Typography>

                    {publication.publishedOn && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(publication.publishedOn), "MMMM yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                      variant="smallBody"
                    >
                      {publication.description}
                    </Typography>

                    {publication.url && (
                      <Button
                        component="a"
                        href={publication.url}
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Publication
                      </Button>
                    )}
                  </div>

                  {index < publications.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {awards.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={awards.length}
                title={`${pluralize("Award", awards.length, {
                  skipCount: true,
                })} and ${pluralize("Honor", awards.length, {
                  skipCount: true,
                })}`}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {awards.map((award, index) => (
                <Typography display="inline" key={award.id} variant="smallBody">
                  {award.title}
                  {index < awards.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {awards.map((award, index) => (
                <React.Fragment key={award.id}>
                  <div className={clsx(classes.details, "pr-3")}>
                    {!isPublic && (
                      <IconButton
                        className={clsx("edit-button")}
                        color="black"
                        onClick={() => toggleDialog("AWARD", award)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <Typography className="m-b" variant="strongSmallBody">
                      {award.title}
                    </Typography>

                    <Typography
                      className="mb-0.25"
                      color="onSurface.600"
                      variant="smallBody"
                    >
                      {award.issuer}
                    </Typography>

                    {award.issuedOn && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(award.issuedOn), "MMMM yyyy")}
                      </Typography>
                    )}

                    <Typography
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="smallBody"
                    >
                      {award.description}
                    </Typography>
                  </div>

                  {index < awards.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {testScores.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={testScores.length}
                title={pluralize("Test Score", testScores.length, {
                  skipCount: true,
                })}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {testScores.map((score, index) => (
                <Typography display="inline" key={index} variant="smallBody">
                  {score.name}
                  {index < testScores.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {testScores.map((score, index) => (
                <React.Fragment key={index}>
                  <div className={clsx(classes.details, "pr-3")}>
                    {!isPublic && (
                      <IconButton
                        className={clsx("edit-button")}
                        color="black"
                        onClick={() => toggleDialog("TEST_SCORE", score)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <Typography className="m-b" variant="strongSmallBody">
                      {score.name}
                    </Typography>

                    {score.score && (
                      <Typography
                        className="mb-0.25"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        Score: {score.score}
                      </Typography>
                    )}

                    {score.testDate && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(score.testDate), "MMMM d, yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="smallBody"
                    >
                      {score.description}
                    </Typography>

                    {(score.evidenceUrl || score.evidenceDocument) && (
                      <Button
                        component="a"
                        href={
                          (score.evidenceUrl as string) ??
                          (score.evidenceDocument as string)
                        }
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Result
                      </Button>
                    )}
                  </div>

                  {index < testScores.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {projects.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={projects.length}
                title={pluralize("Project", projects.length, {
                  skipCount: true,
                })}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {projects.map((project, index) => (
                <Typography display="inline" key={index} variant="smallBody">
                  {project.title}
                  {index < projects.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {projects.map((project, index) => (
                <React.Fragment key={index}>
                  <div className={clsx(classes.details, "pr-3")}>
                    {!isPublic && (
                      <IconButton
                        className={clsx("edit-button")}
                        color="black"
                        onClick={() => toggleDialog("PROJECT", project)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <Typography className="m-b" variant="strongSmallBody">
                      {project.title}
                    </Typography>

                    {project.startDate && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(project.startDate), "MMMM yyyy")} -{" "}
                        {project.endDate
                          ? format(new Date(project.endDate), "MMMM yyyy")
                          : "Ongoing"}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="smallBody"
                    >
                      {project.description}
                    </Typography>

                    {project.url && (
                      <Button
                        component="a"
                        href={project.url}
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Project
                      </Button>
                    )}
                  </div>

                  {index < projects.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {publications.length === 0 &&
        awards.length === 0 &&
        testScores.length === 0 &&
        projects.length === 0 && (
          <Typography
            className="px-3 py-2"
            color="onSurface.400"
            variant="smallBody"
          >
            {isPublic
              ? "Looks like there's nothing here."
              : "You haven’t added any Achievements. Click on + icon to add one."}
          </Typography>
        )}

      {mountDialog}
    </Section>
  )
}

export default Achievements
