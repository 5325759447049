import { Button, Typography } from "@suraasa/placebo-ui"
import Blog from "assets/Ito/BeforeResult/CheckOut/blog.webp"
import Masterclass from "assets/Ito/BeforeResult/CheckOut/masterclass.webp"
import Youtube from "assets/Ito/BeforeResult/CheckOut/youtube.webp"
import clsx from "clsx"
import { ArrowRight } from "iconoir-react"
import React from "react"
import { getPlatformURL } from "utils/helpers"

import Card from "./Card"

const CheckOut = ({
  className,
  variant,
}: {
  className?: string
  variant: "blog" | "masterclass" | "youtube"
}) => {
  const data = {
    masterclass: {
      slug: "Masterclass",
      title: "International Masterclasses",
      outerImg: Masterclass,
      actionBtn: {
        text: "Watch All 60 Masterclasses",
        link: `${getPlatformURL(
          "suraasa",
          "/masterclasses"
        )}?utm_source=ITO Dashboard - International Masterclasses&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult`,
      },
      articles: [
        {
          img: "/assets/ito/2023/BeforeResult/Masterclass/masterclass-1.webp",
          title: "Engage Students in Diverse Classrooms",
          link: "https://www.suraasa.com/masterclasses/secrets-to-engage-students-in-diverse-classrooms?utm_source=ITO Dashboard - International Masterclasses&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
        },
        {
          img: "/assets/ito/2023/BeforeResult/Masterclass/masterclass-2.webp",
          title: "Building 21st Century Skills in the Classroom",
          link: "https://www.suraasa.com/masterclasses/teaching-for-tomorrow-building-21st-century-skills-in-the-classroom?utm_source=ITO Dashboard - International Masterclasses&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
        },
      ],
    },
    blog: {
      slug: "Blog",
      title: "5-Minute Reads",
      outerImg: Blog,
      actionBtn: {
        text: "Read All Blogs",
        link: `${getPlatformURL(
          "suraasa",
          "/blog"
        )}?utm_source=ITO Dashboard - 5 minute reads&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult`,
      },
      articles: [
        {
          img: "/assets/ito/2023/BeforeResult/Blog/article-1.webp",
          title: "Learner Behaviour Management",
          link: "https://www.suraasa.com/blog/dos-and-donts-of-learner-behaviour-management?utm_source=ITO Dashboard - 5 minute reads&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
        },
        {
          img: "/assets/ito/2023/BeforeResult/Blog/article-2.webp",
          title: "Open-ended Questions for Learning",
          link: "https://www.suraasa.com/newsletter/open-ended-questions?utm_source=ITO Dashboard - 5 minute reads&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
        },
      ],
    },
    youtube: {
      slug: "Youtube Channel",
      title: "Teaching Tales!",
      outerImg: Youtube,
      actionBtn: {
        text: "Discover Teaching Tales",
        link: "https://suraasa.co/teaching-tales?utm_source=ITO Dashboard - Teaching Tales&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
      },
      articles: [
        {
          img: "/assets/ito/2023/BeforeResult/Youtube/yt-1.webp",
          title: "The Need to Experiment with Education",
          link: "https://youtu.be/F4wfuYteo7g?si=1YMrrpEd1_3_H4pQ",
        },
        {
          img: "/assets/ito/2023/BeforeResult/Youtube/yt-2.webp",
          title: "Qualities Every Teacher Should Possess",
          link: "https://youtu.be/D1JzzcIjgho?si=k8TjBhVfNNbPAnsL",
        },
      ],
    },
  }
  const { title, slug, outerImg, articles, actionBtn } = data[variant]
  return (
    <div
      className={clsx(
        "bg-[#242424] sm:flex overflow-hidden sm:rounded-2xl text-center sm:text-start ",
        className
      )}
    >
      <div className="px-2 py-4 sm:p-3 md:p-5">
        <Typography variant="strong" className="mb-0.5" color="surface.500">
          Check out our{" "}
          <span
            className={clsx({
              "text-ito-peach-400": variant === "masterclass",
              "text-ito-iris-400": variant === "blog",
              "text-ito-lavender-400": variant === "youtube",
            })}
          >
            {slug}
          </span>
        </Typography>
        <Typography variant="title2" className="mb-3" color="surface.500">
          {title}
        </Typography>
        <div className="mb-3 overflow-x-auto w-full">
          <div className="flex space-x-3 justify-between">
            {articles.map((item, index) => (
              <Card {...item} key={index} />
            ))}
          </div>
        </div>
        <Button
          component="a"
          href={actionBtn.link}
          target="_blank"
          className={clsx("sm:!inline-block !hidden", {
            "!bg-ito-peach-600": variant === "masterclass",
            "!bg-ito-iris-600": variant === "blog",
            "!bg-ito-lavender-600": variant === "youtube",
          })}
          rounded
          endAdornment={<ArrowRight />}
        >
          {actionBtn.text}
        </Button>
        <Button
          component="a"
          href={actionBtn.link}
          target="_blank"
          className={clsx("!inline-block sm:!hidden", {
            "!bg-ito-peach-600": variant === "masterclass",
            "!bg-ito-iris-600": variant === "blog",
            "!bg-ito-lavender-600": variant === "youtube",
          })}
          rounded
          fullWidth
          endAdornment={<ArrowRight />}
        >
          {actionBtn.text}
        </Button>
      </div>
      <div className="grow relative overflow-hidden hidden sm:block">
        <img
          src={outerImg}
          className="h-full absolute top-0 right-0 object-cover xl:w-full object-[60%]"
          alt=""
        />
      </div>
    </div>
  )
}

export default CheckOut
