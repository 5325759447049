import { Dialog, Typography } from "@suraasa/placebo-ui"
import GradientButton from "features/ITO/components/GradientButton"

import HeaderXS from "./assets/HeaderXS.png"

type Props = {
  open: boolean
  onAccept: () => void
}

const ImageCaptureConsentDialog = ({ open, onAccept }: Props) => {
  return (
    <Dialog className="!rounded-2xl" open={open} width="min(439px,100%)">
      <div className="px-2.5 py-4 sm:px-4">
        <div
          style={{
            backgroundImage: `url(${HeaderXS})`,
            backgroundSize: "100% 100%",
            width: "100%",
            position: "absolute",
            left: "0px",
            bottom: "0px",
            height: "200px",
            opacity: "0.5",
            transform: "rotate(180deg)",
            borderRadius: "0px 0px 16px 16px",
          }}
        />
        <img
          alt="logo"
          className="mb-4"
          width="60%"
          src="https://assets.suraasa.com/ito/logos/ito-original-two-line.svg"
        />
        <Typography
          color="onSurface.500"
          style={{
            fontSize: "17px",
            fontWeight: "500",
            lineHeight: "21px",
            letterSpacing: "-0.02em",
          }}
        >
          By attempting this test, you agree to have your images captured and
          stored for the purpose of verification and maintaining integrity.
        </Typography>
        <GradientButton
          className="relative z-auto mt-8"
          fullWidth
          onClick={onAccept}
        >
          Agree and Continue
        </GradientButton>
      </div>
    </Dialog>
  )
}

export default ImageCaptureConsentDialog
