import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  theme,
  toast,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { APIError } from "api/utils"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

type FormData = {
  careerAspiration: string
}

type Props = {
  onUpdate: (newData: string) => void
  handleClose: () => void
  data: FormData
} & Pick<DialogProps, "open">

const EditDialog = ({
  data: { careerAspiration },
  open,
  handleClose,
  onUpdate,
}: Props) => {
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      careerAspiration,
    },
  })

  useEffect(() => {
    reset({ careerAspiration })
  }, [careerAspiration, reset])

  const onSubmit = handleSubmit(async formData => {
    const apiData = {
      careerAspiration: formData.careerAspiration || null,
    }

    api.profile
      .update({
        data: apiData,
      })
      .then(res => {
        onUpdate(formData.careerAspiration)
        toast.success("Successfully saved.")
        handleClose()
      })
      .catch(e => {
        if (e instanceof APIError) handleErrors(e, { setter: setError })
      })
  })

  return (
    <Dialog
      fullScreen={isXs}
      open={open}
      width={636}
      onAfterClose={reset}
      onRequestClose={handleClose}
    >
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {/* @ts-expect-error random-types-issue */}
          <TextField
            charLimit={400}
            error={Boolean(errors.careerAspiration)}
            helperText={errors.careerAspiration?.message}
            maxRows={20}
            rows={8}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            fullWidth
            multiLine
            {...register("careerAspiration", {
              maxLength: {
                value: 400,
                message: "Try to keep it short",
              },
            })}
          />
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            onClick: onSubmit,
            loading: isSubmitting,
          },
        }}
      />
    </Dialog>
  )
}

export default EditDialog
