import { PriceCategories } from "features/ITO/utils/utils"

export const itoFAQs = (getPrice: (key: PriceCategories<"2023">) => string) => [
  {
    title:
      "What is the eligibility to participate in the International Teachers’ Olympiad?",
    description:
      "All teachers - aspiring, in-service and retired are eligible to participate in the International Teachers’ Olympiad.",
  },
  {
    title: "Which subject teachers can participate in the Olympiad?",
    description:
      "The Olympiad is not subject-specific. This means any teacher having any subject expertise can participate in the Olympiad. It will consist of questions on teaching approaches & skills.",
  },
  {
    title: "Where can I attempt this test?",
    description:
      "You can attempt this test on your desktop, laptop, tablet, or mobile phone by signing into the Suraasa website or Android/iOS App using your registered email address or mobile number.",
  },
  {
    title: "Will I have to pay anything apart from registration fee?",
    description: `The fee to register yourself for International Teachers' Olympiad is ${getPrice(
      "registrationFee"
    )}. This is the only amount you will be paying throughout your journey. No hidden or extra charges.`,
  },
  {
    title:
      "Are previous year's question papers provided for the Olympiad preparation?",
    description: `No, previous year's question papers are not provided or necessary for the Olympiad. The Olympiad is designed for teachers to assess their current skills and knowledge. Therefore, by attempting the Olympiad unprepared, teachers can gain insights into their present standing and identify areas that require further development.`,
  },
  {
    title: "Will I be getting any certificate?",
    description: `Yes, all the participants will be getting an international certificate of participation. There will also be certificates of achievement for the top performers.`,
  },
  {
    title: "Will you share my report with my school?",
    description:
      "No, we will not share your report with your school. Your report is 100% confidential and only you will be able to access it by signing into your Suraasa account. The Olympiad has been created to be a safe space for teachers to get a fair assessment without fear of judgement.",
  },
  {
    title: "Is the Olympiad all about the assessment and the report?",
    description: `No, the Olympiad is much more about the assessment & report. If you qualify in Top 100, you will get a cash prize of ${getPrice(
      "cashPrizeBronze"
    )}. Further, as a Top 100 participant, you will get to participate in Teacher Impact Awards with a chance to win cash prizes up to ${getPrice(
      "cashPrizeFirst"
    )}.<br />
Moreover, your personalised pedagogy report opens doorways to your professional growth & gives you a chance to move onto the next level of your professional journey with data-driven insights & comprehensive inputs from education experts.`,
  },
  {
    title: "Who is the Organiser of the International Teachers’ Olympiad?",
    description: `The International Teachers’ Olympiad is being organised by Suraasa - world’s leading platform for teacher upskilling. To know more about Suraasa, <a class="hover:underline" href="https://suraasa.com/about-us">click here.</a>`,
  },
]
