import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
} from "@suraasa/placebo-ui"
import React from "react"

type Props = {
  handleClose: () => void
  onRemove: () => void
  title: string
  loading?: boolean
  children: React.ReactNode
  actionLabel?: string
} & DialogProps

const RemoveDialog = ({
  open,
  handleClose,
  loading = false,
  title,
  onRemove,
  children,
  actionLabel = "Remove",
  ...props
}: Props) => (
  <Dialog open={open} width={358} onRequestClose={handleClose} {...props}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogFooter
      actions={{
        primary: {
          label: actionLabel,
          color: "critical",
          type: "submit",
          loading,
          onClick: onRemove,
        },
      }}
    />
  </Dialog>
)

export default RemoveDialog
