import { Currency } from "../../global/types"

export enum ClaimStatus {
  REQUESTED = 1,
  IN_PROGRESS,
  SUCCESSFUL,
  FAILED,
}

export type ClaimHistory = Pick<
  Claim,
  "id" | "referenceNumber" | "status" | "amount" | "currency"
> & { requestedAt: string }

export type ReferralHistory = {
  dateCreated: string
  registrationFeePaid: boolean
  fullName: string
}

export type Expenses = {
  balance: number
  totalEarnings: number
  totalReferrals: number
}

export enum ClaimType {
  REFUND = 1,
  REWARD = 2,
}

export enum ExpenseType {
  ITO_REFERRAL_REWARD = 1,
}

export type Claim = {
  id: number
  amount: number
  status: ClaimStatus
  type: ClaimType
  referenceNumber: string
  additionalDetails?: {
    payoutLinkId: string
    payoutLink: string
  }
  currency: Currency
}

export type Overview = {
  balance: number
  totalEarning: number
  totalReferrals: number
  expiryDate: string | null
  currency?: Currency
}
