import { useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import routes from "routes"

export enum SkillProfileRoutes {
  OVERVIEW = "overview",
  EVIDENCES_LIST = "evidences",
  EVIDENCE_DETAILS = "evidence-details",
  EVIDENCE_SETTING = "evidence-setting",
  VERIFICATION_PROCESS = "verification-process",
}

export const useGenerateURL = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { username } = useParams<{ username?: string }>()

  const pathnames = pathname.split("/")

  const routeIndex = pathnames.findIndex(
    item =>
      item === SkillProfileRoutes.OVERVIEW ||
      item === SkillProfileRoutes.EVIDENCES_LIST ||
      item === SkillProfileRoutes.EVIDENCE_DETAILS ||
      item === SkillProfileRoutes.EVIDENCE_SETTING ||
      item === SkillProfileRoutes.VERIFICATION_PROCESS
  )

  useEffect(() => {
    if (routeIndex < 0) {
      const route = username
        ? routes.publicSkillProfile.overview.replace(":username", username)
        : routes.skillProfile.overview
      navigate(route)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeIndex, username])

  const isOverview = pathnames[routeIndex] === SkillProfileRoutes.OVERVIEW

  const isEvidence = pathnames[routeIndex] === SkillProfileRoutes.EVIDENCES_LIST
  const isEvidenceDetails =
    pathnames[routeIndex] === SkillProfileRoutes.EVIDENCE_DETAILS
  const isEvidenceSetting =
    pathnames[routeIndex] === SkillProfileRoutes.EVIDENCE_SETTING
  const isVerificationProcess =
    pathnames[routeIndex] === SkillProfileRoutes.VERIFICATION_PROCESS

  const customNavigation = (path: SkillProfileRoutes, slug?: string) => {
    let newURL = ""

    switch (path) {
      case SkillProfileRoutes.OVERVIEW:
        newURL = username
          ? routes.publicSkillProfile.overview.replace(":username", username)
          : routes.skillProfile.overview
        break
      case SkillProfileRoutes.EVIDENCES_LIST:
        if (slug) {
          newURL = username
            ? routes.publicSkillProfile.evidences
                .replaceAll(":username", username)
                .replaceAll(":slug", slug)
            : routes.skillProfile.evidences.replace(":slug", slug)
        }
        break
      case SkillProfileRoutes.EVIDENCE_DETAILS:
        if (slug) {
          newURL = username
            ? routes.publicSkillProfile.evidenceDetails
                .replaceAll(":username", username)
                .replaceAll(":submissionId", slug)
            : routes.skillProfile.evidenceDetails.replace(":submissionId", slug)
        }
        break
      case SkillProfileRoutes.EVIDENCE_SETTING:
        if (slug) {
          if (username) {
            // TODO: Test if we need to throw here
          }
          newURL = routes.skillProfile.evidenceSetting.replace(":slug", slug)
        }
        break
      case SkillProfileRoutes.VERIFICATION_PROCESS:
        newURL = username
          ? routes.publicSkillProfile.verificationProcess.replace(
              ":username",
              username
            )
          : routes.skillProfile.verificationProcess
        break
    }

    navigate(newURL)
  }

  return {
    navigate: customNavigation,
    isOverview,
    isEvidence,
    isEvidenceDetails,
    isEvidenceSetting,
    isVerificationProcess,
  }
}
