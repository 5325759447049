import { IconButton, LinearProgress, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { ArrowLeft } from "iconoir-react"
import React, { PropsWithChildren } from "react"

const ActivityContainer = ({
  children,
  onBack,
  title,
  className,
  endSlot,
  progress,
}: PropsWithChildren<{
  onBack?: () => void
  title: string
  className?: string
  endSlot?: JSX.Element
  progress?: number
}>) => {
  return (
    <div className={clsx("-mx-2 sm:mx-auto", className)}>
      <div className="flex justify-between items-center border-b border-onSurface-200 py-2 px-1 sm:px-2">
        <div className="flex space-x-1 items-center">
          {onBack && (
            <IconButton onClick={onBack} color="black">
              <ArrowLeft width={20} height={20} />
            </IconButton>
          )}
          <Typography variant="title3">{title}</Typography>
        </div>
        <div>{endSlot}</div>
      </div>
      {progress !== undefined && (
        <LinearProgress
          value={progress}
          color="success"
          className="!rounded-none [&>.LinearProgress-colored-bar]:rounded-[0px_4px_4px_0px]"
          size="lg"
        />
      )}

      <div className="p-2 bg-white">{children}</div>
    </div>
  )
}

export default ActivityContainer
