import { context } from "global/Context/context"
import React, { useContext, useEffect, useLayoutEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { trackingService } from "utils/tracking"

import { processAuthCode } from "../processAuthCode"

const ACCOUNTS_ORIGIN = import.meta.env.VITE_SSO_URL

const Login = () => {
  const { isAuthenticated, setAuthInfo } = useContext(context)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const next = searchParams.get("next")

  useLayoutEffect(() => {
    if (isAuthenticated) navigate("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handler = (e: MessageEvent<{ code: string | null }>) => {
      if (e.origin !== ACCOUNTS_ORIGIN) {
        return
      }
      if (!e.data.code) {
        return
      }

      processAuthCode(e.data.code).then(user => {
        setAuthInfo(user)
        trackingService.trackEvent("USER_LOGIN", { uuid: user.user.uuid }, [
          "NetCore",
        ])
        if (next) {
          return navigate(next, { replace: true })
        }

        return navigate("/", { replace: true })
      })
    }

    window.addEventListener("message", handler, false)

    return () => {
      window.removeEventListener("message", handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AUTH_URL = new URL(ACCOUNTS_ORIGIN)
  AUTH_URL.searchParams.append("iframe", "true")
  AUTH_URL.searchParams.append("origin", window.location.origin)

  return (
    <iframe
      title="accounts-signin-page-iframe"
      src={AUTH_URL.toString()}
      width="100%"
      height="100%"
      style={{ minHeight: "100vh" }}
    />
  )
}

export default Login
