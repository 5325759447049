export const DEFAULT_PROFILE_COVER = "/assets/default-cover.png"

export const Settings = {
  preferredLanguage: "preferred-language",
  pushNotifications: "push-notifications",
  emailNotifications: "email-notifications",
  eventBasedEmails: "event-based-emails",
  promotionalEmails: "promotional-emails",
} as const

export enum ProfileNavTabs {
  personalDetails = "Personal Details",
  contactDetails = "Contact Details",
  accountDetails = "Account Details",
  notificationPreferences = "Notification Preferences",
  emailPreferences = "Email Preferences",
  privacy = "Privacy",
}
