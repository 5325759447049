import {
  Button,
  Divider,
  IconButton,
  Theme,
  Typography,
} from "@suraasa/placebo-ui"
import {
  AssessmentQuestion,
  QuestionStatus,
} from "api/resources/ito/assessments/types"
import clsx from "clsx"
import { Xmark } from "iconoir-react"
import React from "react"
import { createUseStyles, useTheme } from "react-jss"

import shepherdClass from "../shepherdClasses"
import Legend from "./Legend"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: "100%",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
      backgroundColor: theme.colors.common.white[500],
      borderRadius: 8,
      padding: theme.spacing(2, 0),
    },
  },
  btnWrapper: {
    height: 48,
    width: 48,
    padding: 0,
    backgroundColor: theme.colors.secondary[50],
    border: `2px solid ${theme.colors.secondary[500]}`,
    borderRadius: 8,
    color: theme.colors.common.black[700],
    "&:hover": {
      backgroundColor: theme.colors.secondary[200],
    },
    "&:active": {
      backgroundColor: theme.colors.secondary[200],
    },
  },
  unattempted: {
    color: theme.colors.common.white[500],
    border: `2px solid ${theme.colors.warning[500]}`,
    backgroundColor: theme.colors.warning[500],
    "&:hover": {
      backgroundColor: theme.colors.warning[600],
    },
    "&:active": {
      backgroundColor: theme.colors.warning[400],
    },
  },
  attempted: {
    color: theme.colors.common.white[500],
    border: `2px solid ${theme.colors.success[500]}`,
    backgroundColor: theme.colors.success[500],
    "&:hover": {
      backgroundColor: theme.colors.success[600],
    },
    "&:active": {
      backgroundColor: theme.colors.success[400],
    },
  },
  activeBtnOutline: {
    outline: `2px solid ${theme.colors.interactive[400]}`,
    outlineOffset: "1px",
  },
  attemptedActive: {
    "&:hover": {
      backgroundColor: theme.colors.success[700],
    },
  },
  unattemptedActive: {
    "&:hover": {
      backgroundColor: theme.colors.warning[700],
    },
  },
  cancel: {
    cursor: "pointer",
    color: theme.colors.common.black[200],
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  none: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}))

const QuestionPanel = ({
  onClose,
  onQuestionChange,
  currentQuestion,
  questions,
}: {
  onClose?: () => void
  onQuestionChange: (questionNumber: number) => void
  currentQuestion: AssessmentQuestion
  questions: AssessmentQuestion[]
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const getActiveBtnColor = (question: AssessmentQuestion) => {
    if (currentQuestion.sequence === question.sequence) {
      switch (question.status) {
        case QuestionStatus.ATTEMPTED:
          return `${classes.attemptedActive} ${classes.activeBtnOutline}`
        case QuestionStatus.UNATTEMPTED:
          return `${classes.unattemptedActive} ${classes.activeBtnOutline}`
        default:
          return ""
      }
    }
  }

  return (
    <div className={clsx(classes.container, shepherdClass["questionPanel"])}>
      <div className="px-2 lg:pl-2 pt-2 lg:pt-0 grid grid-flow-col justify-between items-center">
        <Legend
          list={[
            {
              title: "Not Answered",
              backgroundColor: theme.colors.warning[500],
            },
            {
              title: "Answered",
              backgroundColor: theme.colors.success[500],
            },
            {
              title: "Not Visited",
              backgroundColor: theme.colors.secondary[50],
              borderColor: theme.colors.onSurface[500],
            },
          ]}
        />
        <IconButton className={classes.cancel}>
          <Xmark onClick={onClose} />
        </IconButton>
      </div>
      <Divider weight="light" className="my-2" />

      <Typography
        variant="preTitle"
        color="onSurface.600"
        className={clsx("lg:pl-2 px-2", classes.none)}
      >
        Questions
      </Typography>
      <div className="grid grid-cols-5 px-2 sm:grid-cols-10 lg:grid-cols-4 gap-y-2 mt-2 lg:px-2 py-0.5 mb-2">
        {questions.map((ques, quesIndex) => {
          const activeClass = getActiveBtnColor(ques)
          return (
            <Button
              onClick={() => onQuestionChange(ques.sequence)}
              key={ques.id}
              size="sm"
              className={clsx(
                classes.btnWrapper,
                activeClass,
                shepherdClass["questionId"] + quesIndex,
                "flex justify-center p-1",
                {
                  [classes.attempted]: ques.status === QuestionStatus.ATTEMPTED,
                  [classes.unattempted]:
                    ques.status === QuestionStatus.UNATTEMPTED,
                }
              )}
            >
              <Typography variant="strong">{ques.sequence}</Typography>
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(QuestionPanel)
