import { Button, Theme } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { popupWindow } from "components/ShareSheet"
import ShareReferralSheet from "features/ITO/components/ShareReferralSheet"
import WhatsappIcon from "features/ITO/Dashboard/BeforeRegistrationPhase/components/ReferTeachersCard/assets/whatsapp-icon.svg"
import { context } from "global/Context/context"
import { ShareAndroid } from "iconoir-react"
import { useContext } from "react"
import { createUseStyles } from "react-jss"
import { getITOShareReferralText, getPlatformURL } from "utils/helpers"
import useCurrentCountry from "utils/hooks/useCurrentCountry"

const useStyles = createUseStyles((theme: Theme) => ({
  backButton: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 5),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 2),
    },
    justifyItems: "center",
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      columnGap: "24px",
    },
  },
  bannerHeading: {
    fontFamily: "Reckless TRIAL",
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
    },
  },
  shareBtn: {
    backgroundColor: "#743FF5",
    "&:hover": {
      backgroundColor: "#4909e7",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  cardContainer: {
    display: "flex",
    gap: "24px",
    flexGrow: "1",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      columnGap: "24px",
    },
  },
  shareBtnWhatsapp: {
    color: "white",
    backgroundColor: `#25D366 !important`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textBtn: {
    border: `1px solid ${theme.colors.onSurface[200]}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const InviteViaWhatsappAndShare = ({
  className = "",
  onShareClick,
}: {
  className?: string
  onShareClick?: () => void
}) => {
  const classes = useStyles()

  const { itoParticipationDetails } = useContext(context)

  const country = useCurrentCountry()
  const isIndia = country?.isoCode === "IN"

  const referralCode =
    itoParticipationDetails.itoRegistrationDetails?.referralCode

  const shareURL = getPlatformURL("suraasa", `/ito/?referral=${referralCode}`)
  const WHATSAPP_URL = `https://api.whatsapp.com/send?text=${getITOShareReferralText(
    shareURL,
    { encode: true, discount: isIndia }
  )}`

  return (
    <div
      className={clsx(
        "flex gap-2 items-center sm:flex-row flex-col",
        className
      )}
    >
      <Button
        rounded
        endAdornment={<img src={WhatsappIcon} alt="whatsapp" />}
        className={classes.shareBtnWhatsapp}
        onClick={() => {
          popupWindow(WHATSAPP_URL, 650, 650)
        }}
      >
        <div className="flex items-center gap-1">
          {/* <img alt="icon" height={20} src={WhatsappIcon.src} width={20} /> */}
          Invite via WhatsApp
        </div>
      </Button>

      <div className="w-full sm:w-auto">
        <ShareReferralSheet
          url={shareURL}
          render={handleOpen => {
            return (
              <Button
                rounded
                endAdornment={<ShareAndroid />}
                onClick={() => {
                  if (onShareClick) {
                    onShareClick()
                    return
                  }
                  handleOpen()
                }}
                variant="outlined"
                color="black"
                className={classes.textBtn}
              >
                Share
              </Button>
            )
          }}
        />
      </div>
    </div>
  )
}

export default InviteViaWhatsappAndShare
