export default {
  signIn: {
    title: "Sign in",
  },
  emailVerify: {
    title: "Verify Email",
  },
  assignments: {
    title: "Skill Evidences",
  },
  assessments: {
    title: "Assessments",
  },
  attemptAssessment: {
    title: "Attempt Assessment",
  },
  assessmentResult: {
    title: "Assessment Result",
  },
  orders: {
    title: "Order History",
  },
  payment: {
    title: "Payment",
  },
  settings: {
    title: "Settings",
  },
  orderDetails: {
    title: "Order Details",
  },
  home: {
    title: "Dashboard",
  },
  learning: {
    title: "My Learning",
  },
  viewCourse: {
    title: "Course",
  },
  viewAssignment: {
    title: "Skill Evidence",
  },
  course: {
    title: "Course",
  },
  qualification: {
    title: "Qualification",
  },
  certification: {
    title: "Certification",
  },
  learningModule: {
    title: "Course",
  },
  profile: {
    title: "Profile",
  },
  discussions: {
    title: "Discussions",
  },
  viewDiscussion: {
    title: "Discussion",
  },
  notifications: {
    title: "Notifications",
  },
  schedule: {
    title: "My Schedule",
  },
  certificates: {
    title: "My Certificates",
  },
  teacherImpactAwards: {
    title: "Teacher Impact Awards",
  },
  ito: {
    title: "ITO Dashboard",
  },
  submission: {
    title: "Submission | Skill Evidence",
  },
  pageNotFound: {
    title: "Page not found",
  },
  scheduleInterview: {
    title: "Schedule Interview",
  },
  instructions: {
    title: "Test Instructions",
  },
  itoInstructions: {
    title: "Olympiad Instructions",
  },
  itoAssessment: {
    title: "Attempt Olympiad",
  },
  proctoringDemo: {
    title: "Assessment Proctoring Demo",
  },
}
