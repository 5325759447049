// import { validateUrls } from "api/types"   ?????
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getPerseusURL = getServiceURL(SERVICE_PREFIX.perseus)

export const urls = {
  assessment: (attemptId: number | string) =>
    getPerseusURL(`/v1/assessments/attempts/${attemptId}/start/`),
  submitAssessment: (attemptId: number | string) =>
    getPerseusURL(`/v1/assessments/attempts/${attemptId}/submit/`),
  markAnswer: (questionId: number | string) =>
    getPerseusURL(
      `/v1/assessments/temp-attempt-question-responses/${questionId}/`
    ),
  warningCount: (attemptId: number | string) =>
    getPerseusURL(`/v1/extras/attempt-warning/${attemptId}/`),
  violation: () => "/v1/proctoring/violations/",
  list: () => getPerseusURL("/v1/assessments/"),
  overview: () => getPerseusURL("/v1/assessments/attempts/overview/"),
  getAttempt: (assessmentId: number | string) =>
    getPerseusURL(`/v1/assessments/${assessmentId}/`),
  createAttempt: (assessmentId: number | string) =>
    getPerseusURL(`/v1/assessments/${assessmentId}/attempts/`),
}
