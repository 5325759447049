import { Typography } from "@suraasa/placebo-ui"
import { Assessment, AttemptStatus } from "api/resources/ito/assessments/types"
import { getCalculateDuration } from "features/ITO/Assessments/helpers"
import GradientButton from "features/ITO/components/GradientButton"
import { Check, Clock, PasteClipboard } from "iconoir-react"
import React from "react"

import IconBox from "./IconBox"
type Props = {
  data?: Assessment
  onStart: (id: number, attemptId?: number) => void
  onAssessmentEnd: () => any
}

const MockTestBanner = ({ data, onStart }: Props) => {
  if (!data) return null
  const isCompleted = data.attempt?.status === AttemptStatus.COMPLETED
  const isInProgress = data.attempt?.status === AttemptStatus.IN_PROGRESS
  const canAttempt = new Date(data?.startTime) < new Date()

  const getAction = () => {
    if (canAttempt && !isCompleted) {
      return (
        <GradientButton
          className="!w-full md:!w-auto"
          onClick={() => {
            onStart(data.id, data.attempt?.id)
          }}
        >
          <span className="md:px-4">
            {isInProgress ? "Resume Mock" : "Take Mock"}
          </span>
        </GradientButton>
      )
    }
    if (isCompleted)
      return (
        <Typography
          variant="button"
          color="onSurface.500"
          className="flex-shrink-0"
        >
          <Check className="text-onSurface-500 inline h-2.5 w-2.5" />
          &nbsp; You have finished the mock test
        </Typography>
      )
  }
  return (
    <div
      className="flex flex-col flex-wrap items-start justify-between gap-3 p-3 border rounded-lg sm:flex-row border-onSurface-200"
      style={{
        background:
          "linear-gradient(60deg, rgba(116,63,245,0.1) 0%, rgba(172,131,255,0.3) 100%)",
        boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div className="flex-shrink-0">
        <Typography variant="title3">{data.name}</Typography>

        <Typography variant="body" className="mt-1 sm:max-w-md md:max-w-lg">
          {data.description}
        </Typography>
        <div className="flex flex-wrap gap-2 mt-2 md:gap-4">
          <IconBox
            heading="Time Limit"
            subheading={getCalculateDuration(data.duration)}
            Icon={Clock}
          />
          <IconBox
            heading="Questions"
            subheading={`${data.totalQuestions} MCQs`}
            Icon={PasteClipboard}
          />
        </div>
      </div>

      {getAction()}
    </div>
  )
}

export default MockTestBanner
