import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getGravityURL = getServiceURL(SERVICE_PREFIX.gravity)

export const urls = validateUrls({
  // These urls do not contain slash at the end due to serverless issues on backend.
  countries: {
    list: () => getGravityURL("/countries"),
    current: () => "/global/countries/current",
  },
  states: {
    list: countryId => getGravityURL(`/countries/${countryId}/states`),
  },
  languages: {
    list: () => getGravityURL("/languages"),
  },
  subjects: {
    list: () => getGravityURL("/subjects"),
  },
  curricula: {
    list: () => getGravityURL("/curricula"),
    create: () => getGravityURL("/curricula/create"),
  },
  teachingLevels: {
    list: () => getGravityURL("/teaching-levels"),
    create: () => getGravityURL("/teaching-levels/create/"),
  },
  teachingRoles: {
    list: () => getGravityURL("/teaching-roles"),
    create: () => getGravityURL("/teaching-roles/create"),
  },
  qualificationFields: {
    list: () => getGravityURL("/qualification-fields"),
    create: () => getGravityURL("/qualification-fields/create"),
  },
  currencies: {
    list: () => getGravityURL("/currencies"),
  },
})
