import { Typography } from "@suraasa/placebo-ui"
import { LearningCertificate } from "api/resources/profile/types"
import NoCertificates from "assets/Fallback/no-certificates.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ShareSheet from "components/ShareSheet"
import { context } from "global/Context/context"
import React, { useContext, useState } from "react"

import MyLearningCard from "../Cards/MyLearningCard"
import styles from "./LearningCertificates.module.css"
type Props = {
  certificates: LearningCertificate[]
}

const isCompleted = (certificates: LearningCertificate[], slug: string) => {
  return certificates.find(x => x.item.slug === slug)
}

const LearningCertificates = (props: Props) => {
  const { enrollments } = useContext(context)
  const [certificateUrl, setCertificateUrl] = useState<string>("")

  const courses = enrollments
    ?.filter(item => item.type === "course")
    .sort((a, b) =>
      isCompleted(props.certificates, a.slug) &&
      !isCompleted(props.certificates, b.slug)
        ? -1
        : !isCompleted(props.certificates, a.slug) &&
          isCompleted(props.certificates, b.slug)
        ? 1
        : a.name.localeCompare(b.name)
    )

  const certificates = enrollments?.filter(
    item => item.type === "certification"
  )
  const qualifications = enrollments?.filter(
    item => item.type === "qualification"
  )

  if (enrollments?.length === 0) {
    return (
      <Fallback
        data={{
          image: NoCertificates,
          title: "Looks like you don’t have any certificates yet!",
          description: "Take part in courses by Suraasa to earn certificates",
        }}
        className="translate-y-[-50%] translate-x-[-50%] fixed top-1/2 left-1/2 text-center sm:w-fit w-full sm:px-0 px-2"
        hasError={false}
      />
    )
  }

  return (
    <>
      {qualifications && qualifications.length > 0 && (
        <>
          <Typography variant="title2" className="mb-2">
            Qualifications
          </Typography>
          <div
            className={clsx(
              "grid md:gap-x-2 sm:gap-x-3 sm:gap-y-2 gap-1 mb-4",
              styles.gridContainer
            )}
          >
            {qualifications?.map((item, index) => (
              <MyLearningCard
                completedEnrollment={props.certificates.find(
                  x =>
                    x.item.slug === item.slug &&
                    x.item.itemType === "qualification"
                )}
                key={index}
                inCompletedEnrollment={item}
                setCertificateUrl={url => setCertificateUrl(url)}
              />
            ))}
          </div>
        </>
      )}
      {/* {certificates && certificates.length > 0 && (
        <>
          <Typography variant="title2" className="mb-2">
            Certifications
          </Typography>
          <div
            className={clsx(
              "grid md:gap-x-2 sm:gap-x-3 sm:gap-y-2 gap-1 mb-4",
              styles.gridContainer
            )}
          >
            {certificates?.map((item, index) => (
              <MyLearningCard
                completedEnrollment={props.certificates.find(
                  x =>
                    x.item.slug === item.slug &&
                    x.item.itemType === "certification"
                )}
                key={index}
                inCompletedEnrollment={item}
                setCertificateUrl={url => setCertificateUrl(url)}
              />
            ))}
          </div>
        </>
      )} */}
      {courses && courses.length > 0 && (
        <>
          <Typography variant="title2" className="mb-2">
            Courses
          </Typography>
          <div
            className={clsx(
              "grid md:gap-x-2 sm:gap-x-3 sm:gap-y-2 gap-1 mb-4",
              styles.gridContainer
            )}
          >
            {courses?.map((item, index) => (
              <MyLearningCard
                completedEnrollment={props.certificates.find(
                  x => x.item.slug === item.slug && x.item.itemType === "course"
                )}
                key={index}
                inCompletedEnrollment={item}
                setCertificateUrl={url => setCertificateUrl(url)}
              />
            ))}
          </div>
        </>
      )}
      <ShareSheet
        data={{
          url: certificateUrl,
          text: `Checkout my certificate on Suraasa: ${certificateUrl}`,
        }}
        open={!!certificateUrl}
        handleClose={() => {
          setCertificateUrl("")
        }}
      />
    </>
  )
}

export default LearningCertificates
