import { CircularProgress, Tag, Typography } from "@suraasa/placebo-ui"
import { Attempt, AttemptStatus } from "api/resources/assessments/types"
import { format } from "date-fns"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { NavArrowRight } from "iconoir-react"
import React from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import { getTagStyle } from "../helpers"

const AttemptCard = ({
  attemptNumber,
  attempt,
}: {
  attemptNumber: number
  attempt: Attempt
}) => {
  const {
    status,
    assessmentSchedule,
    result,
    assessment,
    uuid: attemptId,
  } = attempt
  const navigate = useNavigate()

  if (!assessmentSchedule) return <CircularProgress />

  const tagStyle = getTagStyle({
    status,
    result,
    startTime: assessmentSchedule.startTime!,
    endTime: assessmentSchedule.endTime ?? undefined,
  })

  const isMissed =
    status === AttemptStatus.NOT_STARTED &&
    new Date() > new Date(assessmentSchedule.endTime ?? "")

  const handleClick = () => {
    if (attempt.status === "completed") {
      navigate(routes.assessmentResult.replace(":attemptId", attemptId))
    } else {
      startAssessment({
        assessmentId: assessment.uuid,
        onClose: reloadOnAssessmentClose,
      })
    }
  }

  const canAttempt =
    !isMissed &&
    (status === AttemptStatus.IN_PROGRESS ||
      status === AttemptStatus.NOT_STARTED) &&
    result === null

  return (
    <button
      disabled={isMissed}
      className="bg-surface-500 border border-surface-200 rounded-xl p-2 mb-1"
      onClick={handleClick}
    >
      <div className="flex items-center justify-between">
        {canAttempt && (
          <div className="bg-primary-500 w-[4px] h-[80px] sm:h-[53px] rounded-lg me-2" />
        )}
        <div>
          <Typography variant="strong" className="mb-1 text-start">
            Attempt {attemptNumber}
          </Typography>
          <div className="flex flex-wrap gap-1 items-center">
            <Tag
              label={tagStyle.label}
              color={tagStyle.color}
              className="me-1"
            />
            <Tag
              color="onSurface"
              label={`${format(
                new Date(assessmentSchedule.startTime ?? ""),
                "dd LLL"
              )},
            ${format(new Date(assessmentSchedule.startTime ?? ""), "p")} -
              ${format(new Date(assessmentSchedule.endTime ?? ""), "p")}`}
            />
          </div>
        </div>
        {!isMissed && <NavArrowRight />}
      </div>
    </button>
  )
}

export default AttemptCard
