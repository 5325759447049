import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("schedule", {
  schedule: (month: number) => ({
    queryKey: ["schedule", month],
  }),
  upcoming: (date: number) => ({
    queryKey: ["upcomingTasks", date],
  }),
})
