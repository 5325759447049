import {
  Button,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles, useTheme } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.highlight[500],
    borderRadius: 8,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },
}))

const ResumeAssessmentBanner = ({
  timer,
  disabledReason,
  onResume,
  isOlympiad,
  attemptInProgress,
}: {
  timer?: JSX.Element
  attemptInProgress: boolean
  isOlympiad: boolean
  disabledReason: string
  onResume: () => void
}) => {
  const action = attemptInProgress ? "Resume" : "Start"
  const testName = isOlympiad ? "Olympiad" : "Assessment"
  const description = attemptInProgress
    ? `You have already started the ${testName}, finish before time runs out.`
    : "Good luck!"

  const theme = useTheme<Theme>()
  const classes = useStyles()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <button
      className={clsx(
        classes.container,
        "text-start w-full grid grid-flow-row md:grid-flow-col items-center md:justify-between gap-2 md:gap-0 mt-2 mb-3"
      )}
      disabled={Boolean(disabledReason)}
      onClick={onResume}
    >
      <div>
        <Typography
          variant="title3"
          color="common.white.500"
          className="pb-0.25"
        >
          {action} the {testName}
        </Typography>
        <Typography color="common.white.500">{description}</Typography>
      </div>

      {/* This div is important for alignment */}
      <div>
        <Tooltip title={disabledReason} disabled={!disabledReason}>
          <div>
            <Button
              disabled={Boolean(disabledReason)}
              color="white"
              style={{ color: theme.colors.highlight[500] }}
              fullWidth={isSmDown}
              onClick={onResume}
            >
              {action} {testName}
            </Button>
            {timer}
          </div>
        </Tooltip>
      </div>
    </button>
  )
}

export default ResumeAssessmentBanner
