import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import React from "react"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"

import DiscussionItem from "../DiscussionItem"
import TitleBar from "../TitleBar"

const DiscussionDetailPage = () => {
  const { id } = useParams() as { id: string }
  const theme = useTheme<Theme>()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <div className="pb-10">
      <TitleBar backButton />

      <Container fullWidth={xsDown}>
        <div className="grid grid-cols-12 sm:col-start-1 sm:col-span-10 col-start-0 col-span-12">
          <div className="md:col-start-2 md:col-span-10 col-start-1 col-span-12">
            <DiscussionItem discussionId={id} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DiscussionDetailPage
