import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Mail } from "iconoir-react"
import React from "react"

import styles from "./CertificateContactUs.module.css"

const CertificateContactUs = () => {
  return (
    <a
      href="mailto:care@suraasa.com"
      className={clsx(
        styles.container,
        "flex items-center justify-between border rounded-2xl p-3 my-4"
      )}
    >
      <Typography>
        Found something wrong on certificates? Contact us at{" "}
        <span className="text-interactive-500">care@suraasa.com.</span>
      </Typography>
      <Mail
        className={clsx("p-1 h-[40px] w-[40px] flex-shrink-0", styles.icon)}
      />
    </a>
  )
}

export default CertificateContactUs
