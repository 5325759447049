import { Button, theme, Typography } from "@suraasa/placebo-ui"
import AsyncBuilder from "components/AsyncBuilder"
import {
  CheckCircleSolid,
  WarningTriangle,
  XmarkCircleSolid,
} from "iconoir-react"
import React from "react"

type Props = {
  mode: "success" | "failed" | "warn"
  action: () => Promise<void>
  handleNext?: () => void
}

const ActivityResultBanner = ({ handleNext, mode, action }: Props) => {
  return (
    <>
      {mode === "success" && (
        <div className="mb-4 mt-2 rounded-xl border-success-500 bg-success-50 p-2">
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div className="flex items-center gap-1 sm:gap-2">
              <CheckCircleSolid
                fontSize={30}
                color={theme.colors.success[500]}
              />
              <div>
                <Typography variant="strong" className="mb-0.25">
                  Great Job! You answered everything correctly.
                </Typography>
                <Typography variant="body">
                  You can move ahead in the module.
                </Typography>
              </div>
            </div>
            <Button
              onClick={action}
              className="!w-full sm:!w-max"
              color="success"
            >
              Close Activity
            </Button>
          </div>
        </div>
      )}

      {mode === "failed" && (
        <div className="mb-4 mt-2 rounded-xl bg-critical-50 p-2">
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div className="flex items-center gap-1 sm:gap-2">
              <XmarkCircleSolid
                fontSize={30}
                color={theme.colors.critical[500]}
              />
              <div>
                <Typography variant="strong" className="mb-0.25">
                  Some of the responses are incorrect!
                </Typography>
                <Typography variant="body">
                  Click &quot;Try Again&quot; to attempt the activity again
                </Typography>
              </div>
            </div>
            <AsyncBuilder
              handler={action}
              render={({ loading, onClick }) => (
                <Button
                  onClick={onClick}
                  loading={loading}
                  className="!w-full sm:!w-max"
                  color="critical"
                >
                  Try Again
                </Button>
              )}
            />
          </div>
        </div>
      )}

      {mode === "warn" && (
        <div className="mb-4 mt-2 rounded-xl bg-primary-100 p-2">
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div className="flex items-center gap-1 sm:gap-2">
              <WarningTriangle
                fontSize={30}
                color={theme.colors.primary[500]}
              />
              <div>
                <Typography variant="strong" className="mb-0.25">
                  Some of the responses are not quite right!
                </Typography>
                <Typography variant="body">
                  You can still move ahead in the module, or you can try again
                  for a better score.
                </Typography>
              </div>
            </div>
            <div className="flex gap-1">
              <AsyncBuilder
                handler={action}
                render={({ loading, onClick }) => (
                  <Button
                    onClick={onClick}
                    loading={loading}
                    variant="outlined"
                    className="!w-full sm:!w-max"
                    color="primary"
                  >
                    Try Again
                  </Button>
                )}
              />
              {handleNext && (
                <AsyncBuilder
                  handler={async () => handleNext()}
                  render={({ loading, onClick }) => (
                    <Button
                      onClick={onClick}
                      loading={loading}
                      className="!w-full sm:!w-max"
                      color="primary"
                    >
                      Next
                    </Button>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ActivityResultBanner
