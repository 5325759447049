import "@suraasa/placebo-ui/dist/esm/components/Toast/toast.css"
import "drag-drop-touch"

import { theme } from "@suraasa/placebo-ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import AssignmentResult from "features/AssignmentResult"
import Authentication from "features/Authentication"
import Login from "features/Authentication/Login"
import LearningModule from "features/LearningModule"
import Profile from "features/Profile"
import GlobalContext from "global/Context"
import { Suspense, useEffect } from "react"
import { ThemeProvider } from "react-jss"
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { ToastContainer } from "react-toastify"

import Home from "./features/Home"
import AppLayout from "./layout/AppLayout"
import { useDOMDirection } from "./utils/hooks/useDOMDirection"
const Schedule = safeLazyImport(() => import("features/Schedule"))
const Assignments = safeLazyImport(() => import("features/Assignments"))
const ITO = safeLazyImport(() => import("features/ITO/Reports"))

import * as Sentry from "@sentry/react"
import { Maintenance } from "@suraasa/error-pages"
import { PageWithMeta } from "components/Metadata"
import AttemptAssessment from "features/Assessments/Attempt/"
import AssessmentResult from "features/Assessments/Result"
import DiscussionDetailPage from "features/Discussions/DiscussionDetailPage"
import ScheduleInterview from "features/GlobalBanners/ApplicationStatusBanner/ScheduleInterview"
import CourseAssessments from "features/LearningItems/Course/Assessments"
import SentryErrorTesting from "features/Misc/SentryErrorTesting"
import MyCertificates from "features/MyCertificates"
import Orders from "features/Orders"
import OrderDetails from "features/Orders/OrderDetails"
import Payment from "features/Payment"
// import HassleFreePaymentPlan from "features/Orders/OrderDetails/Payments/HassleFreePlan"
import Settings from "features/Settings"
import AccountDetails from "features/Settings/AccountDetails"
import NotificationSettings from "features/Settings/NotificationSettings"
import Privacy from "features/Settings/Privacy"
import SettingsRouteElement from "features/Settings/SettingsRouteElement"
import SkillProfile from "features/SkillProfile"
import { db } from "firebaseInit"
import routes from "routes"
import { USER_TYPE } from "utils/constants"
const ErrorPage404 = safeLazyImport(() => import("components/ErrorPage404"))

// import SlidingSheet from "components/SlidingSheet"
// import CaptchaTest from "components/CaptchaTest"
import GlobalAuthObserver from "features/Authentication/GlobalAuthObserver"
// import DeviceInfo from "DeviceInfo"
import ITOAssessmentWrapper from "features/ITO/Assessments/Attempt/Wrapper"
import AssessmentInstructions from "features/ITO/Assessments/Instructions"
import Dashboard from "features/ITO/Dashboard"
import QualificationClasses from "features/LearningItems/Qualification/QualificationClasses"
// import ProctoringDemo from "features/Misc/ProctoringDemo"
import PublicProfile from "features/Profile/PublicProfile"
import { safeLazyImport } from "safeImport"
import { getAuthInfo } from "utils/helpers"
import { webengage } from "utils/webengage"

import Discussions from "./features/Discussions"
import Course from "./features/LearningItems/Course"
import CourseAssignment from "./features/LearningItems/Course/Assignments"
import CourseClasses from "./features/LearningItems/Course/Classes"
import CourseOverview from "./features/LearningItems/Course/Overview"
import Qualification from "./features/LearningItems/Qualification"
import QualificationAssessments from "./features/LearningItems/Qualification/Assessments"
import QualificationCertificates from "./features/LearningItems/Qualification/Certificates"
import QualificationOverview from "./features/LearningItems/Qualification/QualificationOverview"
import LearningItemResources from "./features/LearningItems/Resources"
import Library from "./features/Library"
import Notifications from "./features/Notifications"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const WEBENGAGE_KEY = import.meta.env.VITE_WEBENGAGE_LICENSE_CODE

const IS_LOCAL = import.meta.env.MODE === "development"

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.2,
  })

  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

const sentryCreateBrowserRouter = SENTRY_DSN
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <GlobalAuthObserver />,
    children: [
      {
        path: "/",
        element: <GlobalContext />,
        children: [
          {
            path: "/trigger-error",
            element: <SentryErrorTesting />,
          },
          {
            path: routes.login,
            element: (
              <PageWithMeta name="signIn">
                <Login />
              </PageWithMeta>
            ),
          },
          {
            path: routes.publicProfile,
            element: (
              <PageWithMeta name="profile">
                <PublicProfile />
              </PageWithMeta>
            ),
          },
          // {
          //   path: routes.misc.browserInfo,
          //   element: <DeviceInfo />,
          // },
          {
            path: "/",
            // Don't wrap metadata here
            element: <AppLayout />,
            children: [
              {
                path: routes.publicSkillProfile.root,
                element: <SkillProfile />,
                children: [
                  {
                    path: routes.publicSkillProfile.overview,
                  },
                  {
                    path: routes.publicSkillProfile.evidences,
                  },
                  {
                    path: routes.publicSkillProfile.evidenceDetails,
                  },
                  {
                    path: routes.publicSkillProfile.verificationProcess,
                  },
                ],
              },
              // Authenticated Routes
              {
                path: "/",
                // Don't wrap metadata here
                element: <Authentication />,
                children: [
                  {
                    path: routes.ito,
                    element: (
                      <PageWithMeta name="ito">
                        <Dashboard />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.home,
                    element: (
                      <PageWithMeta name="home">
                        <Home />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.scheduleInterview,
                    element: (
                      <PageWithMeta name="scheduleInterview">
                        <ScheduleInterview />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.learning,
                    element: (
                      <PageWithMeta name="learning">
                        <Library />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.attemptAssessment,
                    element: (
                      <PageWithMeta name="attemptAssessment">
                        <AttemptAssessment />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.instructionsITOAssessment,
                    element: (
                      <PageWithMeta name="itoInstructions">
                        <AssessmentInstructions parentSlug="ito" />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.attemptITOAssessment,
                    element: (
                      <PageWithMeta name="itoAssessment">
                        <ITOAssessmentWrapper />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.assessmentResult,
                    element: (
                      <PageWithMeta name="assessmentResult">
                        <AssessmentResult />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.settings,
                    element: (
                      <PageWithMeta name="settings">
                        <Settings />
                      </PageWithMeta>
                    ),
                    children: [
                      {
                        element: <SettingsRouteElement />,
                        index: true,
                      },
                      {
                        path: routes.accountDetails,
                        element: <AccountDetails />,
                      },
                      {
                        path: routes.privacy,
                        element: <Privacy />,
                      },
                      {
                        path: routes.notificationSettings,
                        element: <NotificationSettings />,
                      },
                    ],
                  },
                  {
                    path: routes.learningModule,
                    element: (
                      <PageWithMeta name="learningModule">
                        <LearningModule />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.learningModuleWithParent,
                    element: (
                      <PageWithMeta name="learningModule">
                        <LearningModule />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.parentItem,
                    // Can't wrap meta here. Because it is dependent on page URL.
                    // It is handled inside
                    element: <Qualification />,
                    children: [
                      {
                        element: <Navigate to="overview" replace />,
                        index: true,
                      },
                      {
                        path: routes.parentItemOverview,
                        element: <QualificationOverview />,
                      },
                      {
                        path: routes.parentItemAssessments,
                        element: <QualificationAssessments />,
                      },
                      {
                        path: routes.parentItemCertificates,
                        element: <QualificationCertificates />,
                      },
                      {
                        path: routes.parentItemClasses,
                        element: <QualificationClasses />,
                      },
                      {
                        path: routes.parentItemResources,
                        element: <LearningItemResources qualification />,
                      },
                    ],
                  },
                  {
                    path: routes.course,
                    element: (
                      <PageWithMeta name="course">
                        <Course />
                      </PageWithMeta>
                    ),
                    children: [
                      {
                        element: <Navigate to="overview" replace />,
                        index: true,
                      },
                      {
                        path: "/course/:slug/overview",
                        element: <CourseOverview />,
                      },
                      {
                        path: "/course/:slug/classes",
                        element: <CourseClasses />,
                      },
                      {
                        path: "/course/:slug/assignments",
                        element: (
                          <PageWithMeta name="assignments">
                            <CourseAssignment />
                          </PageWithMeta>
                        ),
                      },
                      {
                        path: "/course/:slug/assessments",
                        element: <CourseAssessments />,
                      },
                      {
                        path: "/course/:slug/resources",
                        element: <LearningItemResources course />,
                      },
                    ],
                  },
                  {
                    path: routes.parentItemCourse,
                    element: (
                      <PageWithMeta name="course">
                        <Course />
                      </PageWithMeta>
                    ),
                    children: [
                      {
                        element: <Navigate to="overview" replace />,
                        index: true,
                      },
                      {
                        path: `${routes.parentItemCourse}/overview`,
                        element: <CourseOverview />,
                      },
                      {
                        path: `${routes.parentItemCourse}/classes`,
                        element: <CourseClasses />,
                      },
                      {
                        path: `${routes.parentItemCourse}/assignments`,
                        element: <CourseAssignment />,
                      },
                      {
                        path: `${routes.parentItemCourse}/assessments`,
                        element: <CourseAssessments />,
                      },
                      {
                        path: `${routes.parentItemCourse}/resources`,
                        element: <LearningItemResources course />,
                      },
                    ],
                  },
                  {
                    path: routes.notifications,
                    element: (
                      <PageWithMeta name="notifications">
                        <Notifications />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: "/ito",
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.ito,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: "/",
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.home,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: "/ito-dashboard",
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.ito,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: "/library",
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.learning,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: routes.old.assignments,
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.learning,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: routes.old.assessments,
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.learning,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: routes.old.home,
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.home,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: routes.old.ito,
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.ito,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: routes.old.orders,
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.orders,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: routes.old.payment,
                    element: (
                      <Navigate
                        to={{
                          pathname: routes.payment,
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: routes.skillProfile.root,
                    element: <SkillProfile />,
                    children: [
                      {
                        path: routes.skillProfile.overview,
                      },
                      {
                        path: routes.skillProfile.evidences,
                      },
                      {
                        path: routes.skillProfile.evidenceDetails,
                      },
                      {
                        path: routes.skillProfile.evidenceSetting,
                      },
                      {
                        path: routes.skillProfile.verificationProcess,
                      },
                    ],
                  },
                  {
                    path: routes.profile,
                    element: (
                      <PageWithMeta name="profile">
                        <Profile />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.ito2022,
                    element: (
                      <Suspense
                        fallback={
                          <div className="flex justify-center p-5">
                            Loading...
                          </div>
                        }
                      >
                        <ITO />
                      </Suspense>
                    ),
                  },
                  {
                    path: routes.assignment,
                    element: (
                      <PageWithMeta name="viewAssignment">
                        <Suspense
                          fallback={
                            <div className="flex justify-center p-5">
                              Loading Skill Evidence (Assignment)...
                            </div>
                          }
                        >
                          <Assignments />
                        </Suspense>
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.submission,
                    element: (
                      <PageWithMeta name="submission">
                        <AssignmentResult />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.mySchedule,
                    element: (
                      <PageWithMeta name="schedule">
                        <Suspense
                          fallback={
                            <div className="flex justify-center p-5">
                              Loading Schedule...
                            </div>
                          }
                        >
                          <Schedule />
                        </Suspense>
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.discussions,
                    element: (
                      <PageWithMeta name="discussions">
                        <Discussions />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.certificates,
                    element: (
                      <PageWithMeta name="certificates">
                        <MyCertificates />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.orders,
                    element: (
                      <PageWithMeta name="orders">
                        <Orders />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.payment,
                    element: (
                      <PageWithMeta name="payment">
                        <Payment />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.orderDetailsPage,
                    element: (
                      <PageWithMeta name="orderDetails">
                        <OrderDetails />
                      </PageWithMeta>
                    ),
                  },
                  // {
                  //   path: routes.orderPaymentPlan,
                  //   element: <HassleFreePaymentPlan />,
                  // },
                  {
                    path: "/discussions/:id",
                    element: (
                      <PageWithMeta name="viewDiscussion">
                        <DiscussionDetailPage />
                      </PageWithMeta>
                    ),
                  },
                ],
              },
            ],
          },
          // {
          //   path: "/misc/captcha",
          //   element: <CaptchaTest />,
          // },
          // {
          //   path: routes.misc.proctoringDemo,
          //   element: (
          //     <PageWithMeta name="proctoringDemo">
          //       <ProctoringDemo />
          //     </PageWithMeta>
          //   ),
          // },
          {
            path: "/*",
            element: (
              <PageWithMeta name="pageNotFound">
                <Suspense
                  fallback={
                    <div className="flex justify-center p-5">Loading...</div>
                  }
                >
                  <ErrorPage404 />
                </Suspense>
              </PageWithMeta>
            ),
          },
        ],
      },
    ],
  },
])

const App = () => {
  const { direction } = useDOMDirection(<App />)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        /**
         * We don't want failed requests to be retried by default
         * We can enable this on a per-API basis
         */
        retry: false,
      },
    },
  })

  useEffect(() => {
    if (WEBENGAGE_KEY) {
      webengage?.init(WEBENGAGE_KEY)
      webengage?.options("safariWebPush", true)
      if (IS_LOCAL) {
        webengage?.debug(true)
      }
    }

    if (WEBENGAGE_KEY && !webengage) {
      console.log("WebEngage was not available")
    }
  }, [])

  return (
    <ThemeProvider theme={{ ...theme, direction }}>
      <Maintenance firebaseDb={db} platform={USER_TYPE}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ToastContainer position="bottom-center" />
          {/* {import.meta.env.MODE === "development" && <ReactQueryDevtools />} */}
        </QueryClientProvider>
      </Maintenance>
    </ThemeProvider>
  )
}

export default App
