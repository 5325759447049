import { Checkbox, Radio, Theme, Typography } from "@suraasa/placebo-ui"
import {
  AssessmentQuestion,
  Question as StandaloneQuestion,
  QuestionType,
  UserResponse,
} from "api/resources/assessments/types"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

import FillBlanks from "./FillBlanks"
import QuestionHeader from "./QuestionHeader"

const useStyles = createUseStyles((theme: Theme) => ({
  scroll: {
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  radioBox: {
    boxSizing: "border-box",
    border: `2px solid ${theme.colors.secondary[400]}`,
    borderRadius: 4,
    height: "100%",
    width: "-webkit-fill-available",
    padding: theme.spacing(1),
    maxWidth: "600px",
    cursor: "pointer",
  },
  label: {
    alignItems: "center !important",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  selected: {
    border: `2px solid ${theme.colors.primary[500]}`,
    backgroundColor: theme.colors.primary[100],
  },
  question: {
    whiteSpace: "pre-line",
  },
  optionsContainer: {
    maxWidth: 605,
    display: "flex",
    flexDirection: "column",
    "& .Radio-root, & .Checkbox-root": {
      marginBottom: theme.spacing(1.5),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    "& .Checkbox-input": {
      marginTop: 1,
      flexShrink: 0,
    },
    "& .Checkbox-root": {
      display: "flex",
      alignItems: "center",
    },
  },
}))

const Question = ({
  toggleMarkForReview,
  questionNumberOverride,
  question,
  handleMarkAnswer,
}: {
  questionNumberOverride?: string
  question: AssessmentQuestion
  toggleMarkForReview: (question: AssessmentQuestion) => void
  handleMarkAnswer: (
    question: StandaloneQuestion,
    newResponse: UserResponse,
    options?: {
      caseStudyId?: string
    }
  ) => void
}) => {
  const classes = useStyles()

  const handleResponseChange = (option: number) => {
    if (question.questionType === QuestionType.CASE_STUDY)
      throw new Error("Trying to mark answer on a case study root")

    if (question.questionType === QuestionType.SINGLE_CORRECT) {
      if (question.response?.includes(option)) {
        handleMarkAnswer(question, [])
      } else {
        handleMarkAnswer(question, [option])
      }
    }

    if (question.questionType === QuestionType.MULTIPLE_CORRECT) {
      if (question.response?.includes(option)) {
        handleMarkAnswer(
          question,
          question.response.filter(x => x !== option)
        )
      } else {
        handleMarkAnswer(question, [...(question.response ?? []), option])
      }
    }
  }

  if (question.questionType === QuestionType.CASE_STUDY)
    return (
      <div className={classes.scroll}>
        <QuestionHeader
          question={question}
          toggleMarkForReview={toggleMarkForReview}
        />

        <Typography className={classes.question}>{question.text}</Typography>

        <div className={clsx("ps-2 pt-3 flex flex-col")}>
          {question.questions.map((q, index) => (
            <Question
              toggleMarkForReview={toggleMarkForReview}
              questionNumberOverride={`${question.questionNumber}.${index + 1}`}
              question={q}
              handleMarkAnswer={(q, r) => {
                handleMarkAnswer(q, r, { caseStudyId: question.id })
              }}
              key={q.id}
            />
          ))}
        </div>
      </div>
    )

  return (
    <div className={classes.scroll}>
      <QuestionHeader
        toggleMarkForReview={toggleMarkForReview}
        question={question}
        questionNumberOverride={questionNumberOverride}
      />

      {question.questionType === QuestionType.FILL_IN_THE_BLANKS ? (
        <FillBlanks
          question={question}
          onChange={(_, response) => handleMarkAnswer(question, response)}
        />
      ) : (
        <>
          <Typography className={classes.question}>
            {question.question.replaceAll("__blank__", "__________")}
          </Typography>
          <Typography
            variant="strongSmallBody"
            color="onSurface.500"
            className="pt-2"
          >
            Options
          </Typography>

          <span className={clsx("py-1.5 mb-10.5", classes.optionsContainer)}>
            {Object.entries(question.options).map(option => {
              const optionLabel = option[1]
              const optionValue = Number(option[0])

              const isSelected = question.response?.includes(optionValue)

              const Component =
                question.questionType === QuestionType.SINGLE_CORRECT
                  ? Radio
                  : Checkbox

              return (
                <Component
                  className={clsx(classes.radioBox, {
                    [classes.selected]: isSelected,
                  })}
                  onChange={() => handleResponseChange(optionValue)}
                  key={optionValue}
                  color="primary"
                  checked={isSelected}
                  label={optionLabel}
                />
              )
            })}
          </span>
        </>
      )}
    </div>
  )
}

export default Question
