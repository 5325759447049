import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { XmarkCircle } from "iconoir-react"
import React from "react"

interface Props {
  open: boolean
  handleClose: () => void
  milestoneNumber: number
  text: string
}

const NextMilestoneDialog = ({
  open,
  handleClose,
  milestoneNumber,
  text,
}: Props) => {
  return (
    <Dialog open={open} onRequestClose={handleClose} width="min(100%,650px)">
      <DialogContent className="!p-3">
        <div className="flex items-center gap-1 mb-2.25">
          <Typography variant="title3" className="mb-1" color="onSurface.800">
            Next Up: Milestone {milestoneNumber}
          </Typography>
          <IconButton
            color="black"
            className="ml-auto"
            onClick={() => handleClose()}
          >
            <XmarkCircle />
          </IconButton>
        </div>
        <Typography variant="title4" className="mb-1">
          What you’ll learn in this Milestone
        </Typography>

        <div
          style={{
            marginTop: "16px",
            overflowX: "auto",
            whiteSpace: "pre-wrap",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <Button onClick={handleClose} fullWidth className="mt-3">
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default NextMilestoneDialog
