import { Button, ButtonComponentProps, Tooltip } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

type Props = {
  className?: string
  buttonProps: ButtonComponentProps<any>
  tooltipTitle?: string
}

const FullWidthButtonCard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  buttonProps,
  tooltipTitle = "",
}) => {
  return (
    <div
      className={clsx(
        "overflow-hidden rounded-2xl border-2 border-onSurface-200 bg-white",
        className
      )}
    >
      <div className="p-2">{children}</div>
      <Tooltip title={tooltipTitle}>
        <span>
          <Button
            style={{
              borderRadius: 0,
            }}
            fullWidth
            {...buttonProps}
          />
        </span>
      </Tooltip>
    </div>
  )
}

export default FullWidthButtonCard
