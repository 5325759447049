import { IconButton, Menu, MenuItem, Typography } from "@suraasa/placebo-ui"
import { Certification, Qualification } from "api/resources/profile/types"
import { Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"

import ProfileContext from "../../context"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import Certifications from "./Certifications"
import CertificationsDialog from "./Certifications/EditDialog"
import Qualifications from "./Qualifications"
import QualificationsDialog from "./Qualifications/EditDialog"

const Academics = () => {
  const {
    academics: { qualifications, certifications },
    isPublic,
  } = useContext(ProfileContext)
  const [qualificationsDialogOpen, toggleQualificationsDialog] = useState(false)

  const [certificationsDialogOpen, toggleCertificationsDialog] = useState(false)

  const [qualificationToEdit, setQualificationToEdit] = useState<
    Qualification["id"] | null
  >(null)
  const [certificationToEdit, setCertificationToEdit] = useState<
    Certification["id"] | null
  >(null)

  const handleEdit = (id: number, type: "qualification" | "certification") => {
    if (type === "qualification") {
      toggleQualificationsDialog(true)
      setQualificationToEdit(id)
    }

    if (type === "certification") {
      toggleCertificationsDialog(true)
      setCertificationToEdit(id)
    }
  }

  useEffect(() => {
    // Clear qualificationToEdit when dialog is closed
    if (qualificationToEdit && !qualificationsDialogOpen) {
      setQualificationToEdit(null)
    }
    // Clear certificationToEdit when dialog is closed
    else if (certificationToEdit && !certificationsDialogOpen) {
      setCertificationToEdit(null)
    }
  }, [
    qualificationToEdit,
    qualificationsDialogOpen,
    certificationToEdit,
    certificationsDialogOpen,
  ])

  return (
    <Section>
      <SectionHeading
        heading={
          <SectionTitle
            actionIcon={
              isPublic ? undefined : (
                <Menu
                  align="end"
                  menuButton={
                    <IconButton>
                      <Plus />
                    </IconButton>
                  }
                >
                  <MenuItem onClick={() => toggleQualificationsDialog(true)}>
                    Qualification
                  </MenuItem>
                  <MenuItem onClick={() => toggleCertificationsDialog(true)}>
                    Certification
                  </MenuItem>
                </Menu>
              )
            }
            title="Academics"
          />
        }
        xPadding={3}
      />
      <SectionContent xPadding={3}>
        <QualificationsDialog
          id={qualificationToEdit}
          open={qualificationsDialogOpen}
          toggle={() => toggleQualificationsDialog(false)}
        />

        {qualifications.data.length !== 0 && (
          <div className="pt-1">
            <Typography variant="subtitle2">Qualifications</Typography>
            <Qualifications onEdit={id => handleEdit(id, "qualification")} />
          </div>
        )}

        <CertificationsDialog
          id={certificationToEdit}
          open={certificationsDialogOpen}
          toggle={() => toggleCertificationsDialog(false)}
        />

        {certifications.data.length !== 0 && (
          <div className="pt-1">
            <Typography className="mt-1" variant="subtitle2">
              Certifications
            </Typography>
            <Certifications onEdit={id => handleEdit(id, "certification")} />
          </div>
        )}

        {qualifications.data.length === 0 &&
          certifications.data.length === 0 && (
            <Typography color="onSurface.400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any Academic Details. Click on + icon to add one."}
            </Typography>
          )}
      </SectionContent>
    </Section>
  )
}

export default Academics
