import { Button, Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import { MainColors } from "@suraasa/placebo-ui/dist/cjs/utils/createColors"
import { AttemptResult, AttemptStatus } from "api/resources/assessments/types"
import { AssessmentType, LearningItem } from "api/resources/learningItems/types"
import clsx from "clsx"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { InfoCircle } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import routes from "routes"
import { pluralize } from "utils/helpers"

import { useUpdateItemStatus } from "../hooks/useUpdateItemStatus"
import TitleCard from "./TitleCard"

const Assessment = ({
  hasOwnContainer,
  item,
}: {
  hasOwnContainer?: boolean
  item: LearningItem
}) => {
  if (item.learningContentType !== "assessment")
    throw new Error("Invalid content type passed to Assessment component")

  const {
    title,
    instructions,
    totalNumberOfQuestions,
    attempt,
    showAnswers,
    showScore,
  } = item

  const { slug } = useParams()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const isPreAssessment = item.assessmentType === AssessmentType.pre
  const isCompleted = attempt?.status == AttemptStatus.COMPLETED
  const hasPassed = attempt?.result == AttemptResult.PASS
  const hasFailed = attempt?.result == AttemptResult.FAIL

  const { updateItemStatus } = useUpdateItemStatus(item)
  const navigate = useNavigate()
  const onAssessmentClose = (event: MessageEvent) => {
    reloadOnAssessmentClose()
  }

  const openAssessment = () => {
    updateItemStatus()
    startAssessment({ assessmentId: item.uuid, onClose: onAssessmentClose })
  }

  const handleViewResult = () => {
    if (!attempt) return
    const route = routes.assessmentResult.replace(":attemptId", attempt.uuid)
    if (slug) navigate(`${route}?slug=${slug}`)
    else navigate(`${route}`)
  }

  const getAssessmentCTA = (): {
    label: string
    color: MainColors
    bgColor: string
    onClick: (() => void) | null
  } => {
    const canAttempt = isPreAssessment
      ? !isCompleted
      : !isCompleted || hasFailed

    if (!isPreAssessment && hasPassed && showAnswers == true) {
      return {
        label: "View Result",
        color: "success",
        bgColor: theme.colors.success[50],
        onClick: handleViewResult,
      }
    }

    if (canAttempt)
      return {
        label: "Start Assessment",
        color: "primary",
        bgColor: theme.colors.highlight[50],
        onClick: openAssessment,
      }

    return {
      label: "",
      color: "primary",
      bgColor: theme.colors.highlight[50],
      onClick: null,
    }
  }

  const assessmentCTA = getAssessmentCTA()

  const score = attempt?.score ? Math.floor(attempt.score) : null

  const showFailedBar = !isPreAssessment && hasFailed

  return (
    <div>
      {showFailedBar && (
        <div className="flex items-center bg-critical-50 p-2 sm:rounded-t-2xl border border-critical-100 -mx-2 sm:mx-0">
          <InfoCircle
            color={theme.colors.critical[500]}
            className="me-1 shrink-0"
          />
          <Typography
            variant="strongSmallBody"
            color={theme.colors.critical[900]}
          >
            You scored less than the passing marks needed for this assessment.
            Please reattempt the assessment.
          </Typography>
        </div>
      )}
      <div
        className={clsx({
          ["border-0 sm:border border-onSurface-200 rounded-2xl relative bg-white p-0 sm:p-3 mb-4"]:
            hasOwnContainer,
          ["!rounded-t-none !border-t-0"]: showFailedBar,
        })}
      >
        <TitleCard
          backgroundColor={assessmentCTA.bgColor}
          title={title}
          subtitle={pluralize("Question", totalNumberOfQuestions)}
          action={
            assessmentCTA.onClick ? (
              <Button
                color={assessmentCTA.color}
                onClick={assessmentCTA.onClick}
                fullWidth={isXs}
              >
                {assessmentCTA.label}
              </Button>
            ) : undefined
          }
          additionalInfo={
            !isPreAssessment && showScore && score !== null ? (
              <Typography variant="title4" color="success.900" className="me-2">
                Scored {score}%
              </Typography>
            ) : undefined
          }
        />
        <Typography variant="title4" className="my-2">
          Instructions
        </Typography>
        <Typography className="mb-10">
          {instructions && (
            <span
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: instructions }}
            />
          )}
        </Typography>
      </div>
    </div>
  )
}
export default Assessment
