import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { context } from "global/Context/context"
import React, { useContext } from "react"
import { getPlatformURL } from "utils/helpers"

import CardBgAdornment from "./assets/CardBgAdornment.png"
import SuraasaLogo from "./assets/Suraasa.svg"
import styles from "./SuraasaCertificateCard.module.css"

const SuraasaCertificateCard = ({
  learningItemType,
  serialNumber,
  slug,
}: {
  learningItemType: string
  slug: string
  serialNumber?: string
}) => {
  const hasCertificate = !!serialNumber
  const DynamicComponent = hasCertificate ? "a" : "div"
  const { enrollments } = useContext(context)
  const currentCourse = enrollments?.find(item => item.slug === slug)

  return (
    <DynamicComponent
      href={getPlatformURL("suraasa", `/verify/${serialNumber}`)}
      target="_blank"
      rel="noreferrer"
    >
      <div
        className={clsx(
          styles.root,
          "p-3 rounded-2xl relative overflow-hidden z-10 mt-3 mb-6",
          {
            [styles.hoverAnimation]: hasCertificate,
          }
        )}
      >
        {hasCertificate && (
          <img
            id="rings"
            src={CardBgAdornment}
            alt="bg"
            className={clsx(
              styles.rotate,
              "absolute right-0 h-full bottom-px -z-10"
            )}
          />
        )}
        <img src={SuraasaLogo} alt="suraasa" className="mb-2 sm:mb-1" />
        <span className={clsx("block", styles.gradientText)}>
          {currentCourse?.name}
        </span>
        {hasCertificate ? (
          <Button className={clsx("mt-1.5", styles.viewBtn)} size="sm">
            View Certificate
          </Button>
        ) : (
          <Typography
            variant="smallBody"
            color="common.white.500"
            className="mt-1.5"
          >
            You will earn this certificate automatically after you finish this{" "}
            {learningItemType}
          </Typography>
        )}
      </div>
    </DynamicComponent>
  )
}

export default SuraasaCertificateCard
