import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  theme,
  toast,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Publication } from "api/resources/profile/types"
import LoadingOverlay from "components/LoadingOverlay"
import RemoveDialog from "features/Profile/components/RemoveDialog"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"

type Props = {
  handleClose: () => void
  id?: string
} & Omit<DialogProps, "data" | "id">

const PublicationDialog = ({ open, id, handleClose }: Props) => {
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const [data, setData] = useState<Publication>()
  const [removeDialogOpen, toggleRemoveDialog] = useState(false)
  const [removeDialogLoading, setRemoveDialogLoading] = useState(false)

  const {
    achievements: {
      publications: { add, update, remove },
    },
  } = useContext(ProfileContext)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Publication>()

  const isEditing = Boolean(id)

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.publications.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)
        reset(res.data)
      }
    }
    if (id) fetchData()
  }, [id, reset])

  const onSubmit = handleSubmit(
    async ({ title, publishedOn, publisher, description, url }) => {
      const apiData = {
        title,
        publisher,
        publishedOn: publishedOn || null,
        description: description || null,
        url: url || null,
      }

      if (id) {
        const res = await api.profile.publications.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          update(id, res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.publications.create({ data: apiData })
        if (res.isSuccessful) {
          add(res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    setRemoveDialogLoading(true)
    if (id) {
      const res = await api.profile.publications.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        remove(id)
        toast.success("Removed successfully.")
        toggleRemoveDialog(false)
        handleClose()
      }
    }
  }

  return (
    <>
      <Dialog
        fullScreen={isXs}
        open={open}
        width="md"
        onAfterClose={reset}
        onRequestClose={handleClose}
      >
        {isEditing && !data && <LoadingOverlay />}

        <DialogTitle>{isEditing ? "Edit" : "Add New"} Publication</DialogTitle>

        <DialogContent>
          <form onSubmit={onSubmit}>
            <TextField
              className="mb-3"
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
              inputLabelProps={{ required: true }}
              label="Title"
              placeholder="Ex: Light detection nears its quantum limit"
              fullWidth
              {...register("title", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextField
              className="mb-3"
              error={Boolean(errors.publisher)}
              helperText={errors.publisher?.message}
              inputLabelProps={{ required: true }}
              label="Publisher"
              placeholder="Ex: Scientific Reports"
              fullWidth
              {...register("publisher", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextField
              className="mb-3"
              error={Boolean(errors.publishedOn)}
              helperText={errors.publishedOn?.message}
              label="Publication Date"
              placeholder="Ex: 21 Jan 2021"
              type="date"
              fullWidth
              {...register("publishedOn")}
            />

            <TextField
              className="mb-3"
              error={Boolean(errors.url)}
              helperText={errors.url?.message}
              label="Publication URL"
              placeholder="Ex: Harvard University"
              fullWidth
              {...register("url")}
            />
            {/* @ts-expect-error random-types-issue */}
            <TextField
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              label="Description"
              maxRows={5}
              placeholder="Talk about your role and experience etc..."
              rows={5}
              fullWidth
              charLimit={300}
              multiLine
              {...register("description", {
                maxLength: {
                  value: 300,
                  message: "Try to keep it short",
                },
              })}
            />
          </form>
        </DialogContent>

        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              onClick: onSubmit,
              loading: isSubmitting,
              color: "primary",
            },
            tertiary: isEditing
              ? {
                  label: "Remove",
                  variant: "text",
                  type: "button",
                  color: "critical",
                  onClick: () => toggleRemoveDialog(true),
                }
              : null,
          }}
        />
      </Dialog>

      {data && (
        <RemoveDialog
          handleClose={() => toggleRemoveDialog(false)}
          loading={removeDialogLoading}
          open={removeDialogOpen}
          title="Remove Publication"
          onRemove={handleRemove}
        >
          <Typography variant="smallBody">
            Are you sure you want to remove&nbsp;
            <Typography display="inline" variant="strongSmallBody">
              <strong>{data.title}</strong>
            </Typography>
            &nbsp;from your profile?
          </Typography>
        </RemoveDialog>
      )}
    </>
  )
}

export default PublicationDialog
