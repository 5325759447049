import { getSmartech, NetCore } from "utils/tracking/netcore"
const NETCORE_KEY = import.meta.env.VITE_NETCORE_KEY
const IS_LOCAL = import.meta.env.MODE === "development"

import useScript from "features/Payment/hooks/useScript"
import { useEffect } from "react"

import { trackingService } from "."

const NETCORE_ACCOUNT_ID =
  "ADGMOT35CHFLVDHBJNIG50K96AP78N8E01U57KJBDAA1CRK5GTDG"

export const useNetcore = () => {
  const netcoreScriptStatus = useScript(
    NETCORE_KEY ? "https://cdnt.netcoresmartech.com/smartechclient.js" : null
  )

  useEffect(() => {
    if (NETCORE_KEY && netcoreScriptStatus === "ready") {
      const smartech: any = getSmartech()

      if (smartech) {
        smartech("create", NETCORE_ACCOUNT_ID)
        smartech("register", NETCORE_KEY)
        smartech("identify", "")
        if (IS_LOCAL) {
          smartech("debug", "1")
        }

        // window.hansel takes some time to be defined after the script loads.
        setTimeout(() => {
          initEventListener()
        }, 1000)
      }
    }
  }, [netcoreScriptStatus])

  const initEventListener = () => {
    const hansel = NetCore.getHansel()

    const listener = (eventName: string, data: any) => {
      console.log(`Hansel event listener: ${eventName}`, data)
      trackingService.trackEvent(eventName, data, ["WebEngage"])
    }

    if (hansel) {
      hansel.internal()?.enableDebugLogs()?.debug(true)
      hansel.registerListener(listener)
      console.log("> Registered Hansel Event Listener")
    }
  }

  return { status: netcoreScriptStatus }
}
