import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getNormaUrl = getServiceURL(SERVICE_PREFIX.norma)
const getPerseusUrl = getServiceURL(SERVICE_PREFIX.perseus)
const getMercuryURL = getServiceURL(SERVICE_PREFIX.mercury)

export const urls = validateUrls({
  awardsOverview: () => getNormaUrl("/v1/extras/users/awards/"),
  registrationDetails: () => getNormaUrl("/v1/registrations/details/"),
  itoFeedback: () => getNormaUrl("/v1/extras/ito-feedback/"),
  importantDates: () => getNormaUrl("/v1/extras/important-dates/"),
  userPercentile: slug => getPerseusUrl(`/v1/reports/analytics/${slug}/`),
  list: () => "/v1/ito/reports/",
  leaderBoardList: () => getNormaUrl("/v1/extras/awards/hall-of-fame/"),
  waitlistStatus: itoWaitlistYear =>
    getMercuryURL(`/leads/ito-waitlist/${itoWaitlistYear}/status`),
  userLead: () => getMercuryURL("/leads/user-lead/"),
  participationHistory: () =>
    getNormaUrl(`/v1/registrations/participation-history/`),

  leaveRequest: () => getNormaUrl("/v1/registrations/leave-request-email/"),
})
