import {
  Button,
  theme as THEME,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import { Asana } from "iconoir-react"
import capitalize from "lodash/capitalize"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { convertHexToRGBA, getAuthInfo, pluralize } from "utils/helpers"

// import { routes } from "utils/routes"
import ProfileContext from "../context"
import Section from "./Section"
import SectionContent from "./Section/SectionContent"
import SectionHeading from "./Section/SectionHeading"
import SectionTitle from "./Section/SectionTitle"

const SKILL_COLORS = {
  Professional: THEME.colors.warning[500],
  Advance: THEME.colors.primary[800],
  Intermediate: THEME.colors.primary[600],
  Beginner: THEME.colors.primary[400],
}

const getColor = (color: Overview["text"]) => SKILL_COLORS[color]

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  section: { overflow: "hidden" },

  viewButton: {
    borderRadius: 0,
  },
}))

const skillPillStyles = createUseStyles(theme => ({
  skillPill: {
    display: "flex",
    height: 33,
    borderRadius: 100,
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(3.5 / 8, 0.5, 3.5 / 8, 2),
  },

  skillCount: {
    borderRadius: 18,
    color: theme.colors.surface[500],
    padding: theme.spacing(3 / 8, 11 / 8),
  },
}))

const SkillPill = ({
  type,
  count: skillCount,
}: {
  type: Overview["text"]
  count: number
}) => {
  const classes = skillPillStyles()

  return (
    <div
      className={classes.skillPill}
      style={{
        background: convertHexToRGBA(getColor(type), 10),
      }}
    >
      <Typography style={{ color: getColor(type) }} variant="strongSmallBody">
        {capitalize(type)}
      </Typography>
      <p
        className={classes.skillCount}
        style={{
          background: getColor(type),
        }}
      >
        {skillCount}
      </p>
    </div>
  )
}

type Overview = {
  text: "Professional" | "Advance" | "Intermediate" | "Beginner"
  count: number
}

const SkillProfileOverview = () => {
  const classes = useStyles()
  const { username } = useParams<{ username?: string }>()
  const {
    skillEvidences,
    isPublic,
    profile: {
      user: { firstName },
    },
  } = useContext(ProfileContext)

  const skillEvidencesData: Overview[] = [
    { text: "Professional", count: skillEvidences?.professional || 0 },
    { text: "Advance", count: skillEvidences?.advancedSkillCount || 0 },
    {
      text: "Intermediate",
      count: skillEvidences?.intermediateSkillCount || 0,
    },
    { text: "Beginner", count: skillEvidences?.beginnerSkillCount || 0 },
  ]

  const count = skillEvidencesData.reduce(
    (total, item) => item.count + total,
    0
  )
  return (
    <Section className={classes.section}>
      <SectionHeading
        heading={
          <SectionTitle
            itemCount={count}
            title={pluralize("Verified Skill Evidence", count, {
              skipCount: true,
            })}
          />
        }
      />
      <SectionContent className={classes.root} xPadding={2}>
        {count > 0 ? (
          skillEvidencesData.map(
            (item, index) =>
              item.count > 0 && (
                <SkillPill count={item.count} key={index} type={item.text} />
              )
          )
        ) : (
          <Typography color="onSurface.400" variant="smallBody">
            {isPublic
              ? `${firstName} has not unlocked any skills yet.`
              : `You have not unlocked any skills yet.`}
          </Typography>
        )}
      </SectionContent>

      <Tooltip
        disabled={Boolean(getAuthInfo())}
        title="Please sign in to unlock this feature"
      >
        <span>
          <Button
            className={classes.viewButton}
            component={Link}
            disabled={!getAuthInfo() || count === 0}
            startAdornment={<Asana />}
            to={
              username
                ? routes.publicSkillProfile.overview.replace(
                    ":username",
                    username
                  )
                : routes.skillProfile.overview
            }
            fullWidth
          >
            View Skill Portfolio
          </Button>
        </span>
      </Tooltip>
    </Section>
  )
}

export default SkillProfileOverview
