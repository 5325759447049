import { Error } from "@suraasa/error-pages"
import api from "api"
import { InterviewStatus, ReviewStatus } from "api/resources/application/types"
import LoadingOverlay from "components/LoadingOverlay"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import routes from "routes"
import { getAuthInfo, getPlatformURL } from "utils/helpers"

const getURL = (name: string, email: string) => {
  const url = new URL("https://go.oncehub.com/suraasa-admissions")

  url.searchParams.append("name", name)
  url.searchParams.append("email", email)
  url.searchParams.append("skip", "1")

  return url.toString()
}

const ScheduleInterview = () => {
  const auth = getAuthInfo()
  const name = auth ? `${auth.user.firstName} ${auth.user.lastName}` : ""
  const email = auth?.user.email || ""

  const url = getURL(name, email)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [errorPage, setErrorPage] = useState(false)

  const fetchApplicationOverview = async () => {
    const res = await api.application.applicationOverview()
    if (res.isSuccessful) {
      setLoading(false)
      if (res.data.length >= 1) {
        const { interview, review } = res.data[0]

        if (
          (interview &&
            interview?.status !== InterviewStatus.NO_SHOW &&
            interview?.status !== InterviewStatus.CANCELLED) ||
          review?.status !== ReviewStatus.INTERVIEW_REQUESTED
        ) {
          setErrorPage(true)
        }
      }
    } else {
      setLoading(false)
      setErrorPage(true)
      console.error(res.errors)
    }
  }
  useEffect(() => {
    fetchApplicationOverview()
  }, [])

  if (loading) return <LoadingOverlay />
  if (errorPage)
    return (
      <Error
        navigate={() => {
          window.location.href = getPlatformURL("sso", "/help")
        }}
        errorCode={404}
        options={{
          onHomepage: () => navigate(routes.home),
          onHelp: () => (window.location.href = getPlatformURL("sso", "/help")),
        }}
      />
    )
  return (
    <iframe
      title="schedule-page"
      src={url}
      style={{ width: "100%", height: "100vh", border: "0px" }}
    />
  )
}

export default ScheduleInterview
