import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("itoRegistrations", {
  details: () => ({
    queryKey: ["itoRegistrationDetails"],
  }),
  waitlistStatus: () => ({
    queryKey: ["waitlistStatus"],
  }),
  timeLineDate: () => ({
    queryKey: ["timeLineDate"],
  }),
  retrieveLeaveRequest: () => ({
    queryKey: ["retrieveLeaveRequest"],
  }),
})
