import React from "react"

export const safeLazyImport = (module: () => Promise<any>) => {
  return React.lazy(() => {
    // console.log("> Fetching module")
    return module().catch(e => {
      if (e?.message) {
        if (e.message.includes("Failed to fetch dynamically imported module")) {
          console.log("Chunk not found. Reloading.")
          window.location.reload()
          return
        }
      }
      console.error(e)
    })
  })
}
