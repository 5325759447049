import { Avatar, Button, Typography } from "@suraasa/placebo-ui"
import { UserDetails as UserDetailsType } from "api/resources/discussions/types"
import clsx from "clsx"
import { highlightHashtags } from "features/Discussions/highlightHastags"
import React from "react"
import { buildUserName } from "utils/helpers"

import UserDetails from "../UserDetails"
import styles from "./PostDetails.module.css"

type Props = {
  totalComments?: number
  user: UserDetailsType
  text: string
  truncateText?: boolean
  discussionId: string
  datePublished?: string
  onReadMore: () => void
}

const DISCUSSION_TEXT_LIMIT = 400

const PostDetails = ({
  totalComments,
  user,
  text,
  truncateText,
  discussionId,
  datePublished,
  onReadMore,
}: Props) => {
  const name = buildUserName(user)

  const getTextDisplay = () => {
    if (truncateText) {
      const isTextLong = text.length > DISCUSSION_TEXT_LIMIT
      return (
        <>
          <Typography
            className={clsx("whitespace-pre-line ", {
              "pb-1": isTextLong,
              "mb-1": !isTextLong,
            })}
            style={{ wordBreak: "break-word" }}
          >
            {isTextLong
              ? highlightHashtags(text.slice(0, DISCUSSION_TEXT_LIMIT))
              : highlightHashtags(text)}
          </Typography>
          {isTextLong && (
            <div
              className={clsx("bottom-0 absolute", styles.gradientBackground)}
            />
          )}
          <Button
            className={clsx(styles.startMargin, {
              "absolute bottom-0 left-0 mb-0.5": isTextLong,
            })}
            variant="text"
            onClick={onReadMore}
          >
            Read Full Discussion
          </Button>
        </>
      )
    }

    return (
      <Typography
        className="whitespace-pre-line mb-2.5"
        style={{ wordBreak: "break-word" }}
      >
        {highlightHashtags(text)}
      </Typography>
    )
  }

  return (
    <div
      className={clsx("flex", {
        "pb-0": totalComments === 0,
      })}
    >
      <div className="me-2 sm:block hidden shrink-0">
        <Avatar color="one" size="md" name={name} src={user.photo} />
      </div>
      <div className="w-full">
        <UserDetails
          discussionId={discussionId}
          user={user}
          datePublished={datePublished}
        />
        <div className={clsx("mb-1", { relative: truncateText })}>
          {getTextDisplay()}
        </div>
      </div>
    </div>
  )
}

export default PostDetails
