import { Location, useLocation } from "react-router-dom"
import routes from "routes"

const getPathsWithNoShadow = (location: Location) => {
  // Represents the pathnames on which shadow should *NOT* appear
  const pathnames = [routes.learning, routes.notifications]

  return pathnames.includes(location.pathname)
}

export const useExcludeShadow = () => {
  const location = useLocation()

  const disableShadows = getPathsWithNoShadow(location)

  return disableShadows
}
