import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import React from "react"
import { useTheme } from "react-jss"
import { Navigate } from "react-router-dom"
import routes from "routes"

const SettingsRouteElement = () => {
  const theme = useTheme<Theme>()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  if (isSmUp) return <Navigate to={routes.accountDetails} replace />
  return null
}

export default SettingsRouteElement
