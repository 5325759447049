import {
  Button,
  IconButton,
  SplitTextField,
  toast,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email, OTPResponse, PhoneNumber } from "api/resources/settings/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import Card from "components/Card"
import { Edit, Plus } from "iconoir-react"
import { useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import countryCodes from "utils/countryCodes"
import { handleErrors } from "utils/helpers"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"

import { useSettings } from ".."
import AddSecondaryEmail from "./AddSecondaryEmail"
import DeleteEmailConfirmation from "./DeleteEmailConfirmation"
import EmailCard from "./EmailCard"
import UpdatePrimaryEmail from "./UpdatePrimaryEmail"
import VerifyPhoneNumberDialog from "./VerifyPhoneNumberDialog"

const ContactDetails = () => {
  const { contactDetails, updateContactDetails } = useSettings()
  const {
    clearErrors,
    handleSubmit,
    setError,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<{
    phoneNumber: {
      code: string | null
      number: string | null
    }
  }>({
    defaultValues: {
      phoneNumber: {
        number: contactDetails.phoneNumber?.number,
        code: contactDetails.phoneNumber
          ? `+${contactDetails.phoneNumber?.code}`
          : null,
      },
    },
  })

  const [emailToDelete, setEmailToDelete] = useState<Email | null>(null)
  const [openAddEmail, setOpenAddEmail] = useState(false)
  const [tempPrimaryEmail, setTempPrimaryEmail] = useState<Email | null>(null)
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false)
  const [loading, setLoading] = useState(false)
  const [otpData, setOtpData] = useState<OTPResponse | null>(null)

  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState<{
    phoneNumber: PhoneNumber | null
    verify: boolean
  }>({
    phoneNumber: null,
    verify: false,
  })

  const updateUserPhoneNumber = async ({
    token,
    phoneNumber,
  }: {
    token: string
    phoneNumber: PhoneNumber | null
  }) => {
    if (!phoneNumber) return
    try {
      const data = await api.users.updateUser({
        data: {
          phoneNumber: `${phoneNumber.code}${phoneNumber.number}`,
          smsOtpToken: token,
        },
      })
      toast.success("Phone number updated successfully", {
        autoClose: 3000,
        pauseOnHover: false,
      })
      if (data.phoneNumber)
        updateContactDetails({
          ...contactDetails,
          phoneNumber: {
            code: `${data.phoneNumber.code}`,
            number: `${data.phoneNumber.number}`,
          },
        })
      setLoading(false)
      setUpdatePhoneNumber(false)
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e, { setter: setError })
        setLoading(false)
      }
    }
  }

  const { submitWithCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#phone-number-checkbox-captcha",
  })

  const onSubmit = handleSubmit(async data => {
    submitWithCaptcha(async captcha => {
      if (!data.phoneNumber.code || !data.phoneNumber.number) return

      if (!captcha) {
        toast.error("Please verify the captcha")
        return
      }

      setLoading(true)
      clearErrors()

      try {
        const res = await api.settings.addPhoneNumber({
          data: {
            captcha,
            phoneNumber: `${data.phoneNumber.code}${data.phoneNumber.number}`,
          },
        })
        setOtpData(res)
        setVerifyPhoneNumber({
          phoneNumber: {
            number: data.phoneNumber.number,
            code: data.phoneNumber.code,
          },
          verify: true,
        })
      } catch (err) {
        if (err instanceof APIError) {
          if (
            err.errors?.fieldErrors?.resendAt &&
            err.errors?.fieldErrors?.token
          ) {
            setOtpData({
              resendAt: err.errors.fieldErrors.resendAt as string,
              token: err.errors.fieldErrors.token as string,
            })

            setVerifyPhoneNumber({
              phoneNumber: {
                number: data.phoneNumber.number,
                code: data.phoneNumber.code,
              },
              verify: true,
            })
          } else if (err.errors.message) {
            toast.error(err.errors.message)
          } else {
            handleErrors(err, { setter: setError })
          }
        }
      }
      setLoading(false)
    })()
  })

  const options = useMemo(
    () =>
      countryCodes.map(({ dialCode }) => ({
        value: dialCode,
        label: dialCode,
      })),
    []
  )

  const primaryEmail = contactDetails.emails.find(data => data.isPrimary)
  const primaryEmailIsVerified = Boolean(primaryEmail?.isVerified)

  return (
    <div>
      <div className="bg-white border border-surface-200 rounded-2xl">
        {contactDetails.emails.map((email, idx) => (
          <div
            key={idx}
            className={clsx({
              ["border-b border-b-surface-200"]:
                idx !== contactDetails.emails.length - 1,
            })}
          >
            <EmailCard
              email={email}
              onSetPrimaryEmail={email => setTempPrimaryEmail(email)}
              onDelete={email => setEmailToDelete(email)}
            />
          </div>
        ))}

        {contactDetails.emails.length < 2 && primaryEmail && (
          <div
            className={clsx(
              "p-2 flex items-center justify-between border-t border-t-surface-200",
              {
                ["bg-onSurface-200 text-onSurface-400 rounded-b-2xl pointer-events-none"]:
                  !primaryEmailIsVerified,
              }
            )}
          >
            <Typography variant="strong">Add Email Address</Typography>
            <IconButton
              onClick={() => setOpenAddEmail(true)}
              color={primaryEmailIsVerified ? "primary" : "secondary"}
            >
              <Plus />
            </IconButton>
          </div>
        )}
      </div>

      {updatePhoneNumber ? (
        <Card
          infoSlot={
            <div>
              <Typography variant="strong">Phone Number</Typography>
              <form onSubmit={onSubmit} className="mt-2">
                <div>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <SplitTextField
                        error={Boolean(errors.phoneNumber)}
                        helperText={errors.phoneNumber?.message}
                        selectProps={{
                          placeholder: "+91",
                          options,
                          value: options.find(
                            c => c.value === field.value.code
                          ),
                          onChange: val => {
                            clearErrors()
                            if (val) setValue("phoneNumber.code", val.value)
                          },
                        }}
                        textFieldProps={{
                          type: "number",
                          placeholder: "Enter phone number",
                          value: field.value.number ?? undefined,
                          onChange: val => {
                            clearErrors()
                            setValue("phoneNumber.number", val.target.value)
                          },
                        }}
                        fullWidth
                      />
                    )}
                    rules={{
                      validate: v => {
                        if (!v) return "Required"
                        if (!v.code) return "Required"
                        if (!v.number) return "Required"
                      },
                    }}
                  />
                </div>

                <Typography
                  variant="smallBody"
                  color="onSurface.500"
                  className="mt-1"
                >
                  This phone number can be used to log in to your Suraasa
                  account.
                </Typography>
                <div id="phone-number-checkbox-captcha" className="mt-2" />

                <Button
                  className="mt-2.75"
                  loading={loading}
                  type="submit"
                  variant="filled"
                  size="sm"
                >
                  Save
                </Button>
                <Button
                  className="ms-1"
                  size="sm"
                  variant="text"
                  onClick={() => {
                    reset()
                    setUpdatePhoneNumber(false)
                  }}
                >
                  Cancel
                </Button>
              </form>
            </div>
          }
          padding={2}
          className="my-4 bg-white"
        />
      ) : (
        <div className="flex-row p-2 my-4 bg-white border border-surface-200 rounded-2xl">
          <div className="flex items-center justify-between">
            <div>
              <Typography variant="strong">Phone Number</Typography>
              {contactDetails.phoneNumber && (
                <Typography className="mt-0.5">
                  +{contactDetails.phoneNumber.code}{" "}
                  {contactDetails.phoneNumber.number}
                </Typography>
              )}
              <Typography
                variant="smallBody"
                color="onSurface.500"
                className="mt-1"
              >
                This phone number can be used to log in to your Suraasa account.
              </Typography>
            </div>
            <IconButton
              color="primary"
              className="self-start"
              onClick={() => {
                setUpdatePhoneNumber(true)
              }}
            >
              {contactDetails.phoneNumber ? (
                <Edit />
              ) : (
                <Tooltip title="Add phone number">
                  <Plus />
                </Tooltip>
              )}
            </IconButton>
          </div>
          {/* <Typography
            variant="smallBody"
            color="onSurface.500"
            className="mt-1"
          >
            Phone number can be used to log in to your Suraasa account.
          </Typography> */}
        </div>
      )}

      {/* Delete Email Confirmation */}
      <DeleteEmailConfirmation
        email={emailToDelete}
        open={Boolean(emailToDelete)}
        onClose={() => setEmailToDelete(null)}
        onDelete={() =>
          updateContactDetails({
            ...contactDetails,
            emails: contactDetails.emails.filter(
              item => item.id !== emailToDelete?.id
            ),
          })
        }
      />

      {/* Add New Email */}
      <AddSecondaryEmail
        close={() => setOpenAddEmail(false)}
        open={openAddEmail}
        onAdd={(newEmail: Email) => {
          updateContactDetails({
            ...contactDetails,
            emails: [...contactDetails.emails, newEmail],
          })
        }}
      />

      {/* Update Primary email */}
      <UpdatePrimaryEmail
        close={() => setTempPrimaryEmail(null)}
        email={tempPrimaryEmail}
        open={Boolean(tempPrimaryEmail)}
        onVerify={() => {
          const newSecondaryEmail = contactDetails.emails.filter(
            item => item.isPrimary === true
          )[0]
          const newPrimaryEmail = contactDetails.emails.filter(
            item => item.isPrimary === null
          )[0]
          updateContactDetails({
            ...contactDetails,
            emails: [
              { ...newSecondaryEmail, isPrimary: null },
              { ...newPrimaryEmail, isPrimary: true },
            ],
          })
        }}
      />

      {/* Verify Phone Number */}
      {otpData && (
        <VerifyPhoneNumberDialog
          onBack={() => {
            // to allow the user to at least click the save button after returning from VerifyDialog
            clearErrors()
            setVerifyPhoneNumber({
              ...verifyPhoneNumber,
              phoneNumber: null,
              verify: false,
            })
          }}
          close={() => {
            // to allow the user to at least click the save button after returning from VerifyDialog
            clearErrors()
            setVerifyPhoneNumber({
              ...verifyPhoneNumber,
              phoneNumber: null,
              verify: false,
            })
            close()
          }}
          otpData={otpData}
          phoneNumber={verifyPhoneNumber.phoneNumber}
          open={verifyPhoneNumber.verify}
          onVerify={(token: string) => {
            //  update the local contactDetails state and call update api
            updateUserPhoneNumber({
              token,
              phoneNumber: verifyPhoneNumber.phoneNumber,
            })
            setVerifyPhoneNumber({ phoneNumber: null, verify: false })
            close()
          }}
        />
      )}
    </div>
  )
}

export default ContactDetails
