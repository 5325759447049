import { Typography } from "@suraasa/placebo-ui"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import { NavArrowRight } from "iconoir-react"

import { makeId } from "./utils/helper"

const Topics = ({ topics }: { topics: string[] }) => {
  return (
    <>
      <SwitzerTypography
        variant="title2"
        style={{
          fontFamily: "Switzer Bold",
        }}
      >
        Topics
      </SwitzerTypography>

      <div className="grid mt-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
        {topics.map(item => (
          <button
            className="border border-onSurface-300 rounded-full py-2.5 px-3 flex items-center justify-between hover:bg-onSurface-100"
            key={item}
            onClick={() => {
              document
                .getElementById(makeId(item))
                ?.scrollIntoView({ behavior: "smooth", block: "start" })
            }}
          >
            <Typography variant="strong" textAlign="left" color="onSurface.700">
              {item}
            </Typography>
            <NavArrowRight className="text-onSurface-700" />
          </button>
        ))}
      </div>
    </>
  )
}

export default Topics
