import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Dialog, DialogContent } from "@suraasa/placebo-ui"
import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import { Currency } from "api/resources/global/types"
// Assets
import PoweredByStripe from "assets/logos/powered-by-stripe.svg"
import SuraasaLogo from "assets/logos/suraasa-logo.svg"
import { Xmark } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import routes from "routes"
import useFormatPrice from "utils/hooks/useFormatPrice"

type Props = {
  open: boolean
  handleClose: () => void
  itemName: string
  promocode: string | undefined
  itemId: number
  amount: number
  currency: Pick<Currency, "symbol" | "code"> | null
}

const useStyles = createUseStyles(theme => ({
  cover: {
    height: "100%",
  },

  dialogPaper: {
    position: "inherit",
  },

  closeIcon: {
    position: "absolute",
    margin: theme.spacing(1),
    top: 0,
    right: 0,
  },

  header: {
    minHeight: 128,
    color: "white",
    padding: theme.spacing(3, 3, 3, 3),
    position: "relative",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.primary[500],
  },
  suraasaLogoContainer: {
    minWidth: 80,
    minHeight: 80,
    backgroundColor: "white",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(3),

    "& > img": {
      width: 53,
      height: 42,
    },
  },

  content: {
    height: "100%",
    padding: theme.spacing(3, 14 / 8),
  },

  processingTransactionContainer: {
    padding: theme.spacing(10, 0),
    "& > div": {
      marginTop: theme.spacing(3),
    },
  },

  formContainer: {
    height: "300px",
  },

  price: {
    marginTop: theme.spacing(1),
  },

  paymentBtn: {
    height: 50,
    boxShadow: "none",
    borderRadius: 0,
  },
  poweredBy: {
    height: 60,
    marginBottom: "-36px",
  },
}))

export default function StripeCheckoutForm({
  open,
  handleClose,
  itemName,
  promocode,
  itemId,
  amount,
  currency,
}: Props) {
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const getSearchParams = () => {
    const params = new URLSearchParams()

    params.append("paymentGateway", "Stripe")
    params.append("itemName", itemName)
    if (promocode) params.append("promocode", promocode)
    if (currency) params.append("currency", currency.code)
    params.append("itemId", itemId.toString())
    params.append("amount", amount.toString())

    return params.toString()
  }

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) return
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!")
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.")
          break
        default:
          setMessage("Something went wrong.")
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${
          routes.orders
        }/?${getSearchParams()}&${window.location.search.replace("?", "")}`,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      (error.type === "card_error" || error.type === "validation_error") &&
      error.message
    ) {
      setMessage(error.message)
    } else {
      setMessage("An unexpected error occurred.")
    }

    setIsLoading(false)
  }

  const formatPrice = useFormatPrice()

  const priceStr = `${currency?.symbol}${formatPrice(amount)}`

  return (
    <Dialog open={open}>
      <div className={classes.header}>
        <div className={classes.suraasaLogoContainer}>
          <img src={SuraasaLogo} alt="Suraasa" />
        </div>
        <div>
          <Typography variant="largeBody">Suraasa</Typography>

          <Typography variant="body">{itemName}</Typography>

          <Typography className={classes.price} variant="title3">
            {priceStr}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeIcon}
            color="white"
          >
            <Xmark />
          </IconButton>
        </div>
      </div>
      <img
        src={PoweredByStripe}
        alt="Powered by Stripe"
        height={120}
        className={classes.poweredBy}
      />
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {/* Show any error or success messages */}
          <div className="mt-2 mb-2">
            {message && <Typography variant="body">{message}</Typography>}
          </div>

          <PaymentElement />
        </DialogContent>
        <div className="mt-3">
          <Button
            className={classes.paymentBtn}
            fullWidth
            color="primary"
            size="sm"
            type="submit"
            variant="filled"
            disabled={isLoading || !stripe || !elements}
            loading={isLoading}
          >
            Pay {priceStr}
          </Button>
        </div>
      </form>
    </Dialog>
  )
}
