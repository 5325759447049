import { Button } from "@suraasa/placebo-ui"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"

import CreditHistory from "./CreditHistory"

interface Props {
  drawerProps: {
    open: boolean
    toggle: () => void
  }
  forceUpdate: number
}

const CreditsHistorySidebar = ({ drawerProps, forceUpdate }: Props) => {
  return (
    <>
      <SlidingSheet
        unmountOnExit
        from="end"
        open={drawerProps.open}
        onClose={drawerProps.toggle}
        className="w-[min(100%,400px)] p-2"
      >
        <Button
          startAdornment={<Xmark />}
          onClick={() => drawerProps.toggle()}
          variant="text"
          color="black"
          className="-ml-1"
        >
          Close
        </Button>

        {/* {isEmpty && <Fallback hasError className="mt-6" />} */}
        <CreditHistory forceUpdate={forceUpdate} />
      </SlidingSheet>
    </>
  )
}

export default CreditsHistorySidebar
