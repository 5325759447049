import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Tag,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import PageNavigation from "components/PageNavigation"
import ReactHelmet from "components/ReactHelmet"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import React, { useState } from "react"
import { useTheme } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"

import Answer from "./Answer"

// const defaultBoxShadow = "2px 3px 6px rgb(0 0 0 / 13%)"
// const highlightedQuestionShadow = "2px 3px 8px rgb(0 0 0 / 39%)"

export default function AssessmentResult() {
  const { attemptId } = useParams() as { attemptId: string }
  const navigate = useNavigate()

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const [showTopicWiseScore, setShowTopicWiseScore] = useState(false)

  const scores = useQuery({
    queryFn: () =>
      api.assessments.getTopicWiseScore({
        urlParams: { attemptId },
      }),
    queryKey: queries.assessments.topicWiseScore(attemptId).queryKey,
    enabled: Boolean(attemptId) && showTopicWiseScore,
  })

  const { data, isLoading } = useQuery({
    queryFn: () =>
      api.assessments.getSolutions({
        urlParams: { attemptId },
      }),
    enabled: Boolean(attemptId),
    queryKey: queries.assessments.solutions(attemptId).queryKey,
  })

  if (!data || isLoading || !attemptId) return <FullPageLoading />

  if ("showAnswers" in data || data.score === null) {
    return (
      <div className="flex items-center flex-col">
        <Fallback
          data={{
            image: HandHoldingPen,
            title: "Result is not available for this assessment",
          }}
          className="md:mt-18 sm:mt-20 mt-9 mb-3"
        />
        <Button onClick={() => navigate(-1)}>Go back</Button>
      </div>
    )
  }

  return (
    <>
      <Container className="mt-2" fullWidth={isXs}>
        <PageNavigation />
        <ReactHelmet
          data={{ title: "Assessment Analytics" }}
          dynamicContent={data.assessmentName}
        />
        <div className="flex sm:flex-row flex-col sm:items-center justify-between mt-3 sm:px-0 px-2 flex-wrap">
          <div className="flex items-center sm:justify-start justify-between">
            <Typography variant="title3">
              {data.assessmentName} Result
            </Typography>
            {data.score != null && (
              <Tag
                size="md"
                color="primary"
                label={`Score: ${data.score.toFixed(0)}`}
                className="text-primary-500 font-bold ms-2"
              />
            )}
          </div>
          <Button
            onClick={() => setShowTopicWiseScore(true)}
            className="sm:mt-0 mt-1"
          >
            View Topic Wise Score
          </Button>
        </div>

        <div className="pt-2">
          {data.solutions !== null ? (
            data.solutions.length > 0 ? (
              <div>
                {data.solutions.map((question, index) => (
                  <div
                    key={index}
                    className="mb-2"
                    id={question.questionNumber.toString()}
                  >
                    <Answer question={question} />
                  </div>
                ))}
              </div>
            ) : (
              // This case should never come from backend.
              <div>
                <Typography variant="body">
                  Solutions for this assessment are not available.
                </Typography>
              </div>
            )
          ) : (
            <FullPageLoading />
          )}
        </div>

        {/* Topic wise score sliding sheet */}
        <SlidingSheet
          from="bottom"
          onClose={() => setShowTopicWiseScore(false)}
          open={showTopicWiseScore}
          containerClassName="grid grid-cols-12"
          className="rounded-t-none md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-1 col-span-12 w-full overflow-hidden"
        >
          <div className="flex items-center mb-3 px-3 pt-3">
            <IconButton
              onClick={() => setShowTopicWiseScore(false)}
              color="black"
            >
              <Xmark />
            </IconButton>
            <Typography variant="title2" className="ms-1">
              Topic-wise Scores
            </Typography>
          </div>

          <div className="overflow-auto max-h-[65vh] h-full">
            {scores.isLoading && (
              <div className="flex items-center justify-center py-6">
                <CircularProgress />
              </div>
            )}
            {scores.isSuccess && (
              <div className="sm:px-6 px-3">
                {scores.data.length > 0 ? (
                  scores.data.map((topic, index) => (
                    <div
                      style={{ boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)" }}
                      key={index}
                      className="flex items-center justify-between px-2 py-[20px] bg-surface-500 border border-surface-200 rounded-lg mb-[10px]"
                    >
                      <Typography variant="body">{topic.topic}</Typography>
                      <Typography
                        variant="title2"
                        color="primary.500"
                        className="sm:ms-0 ms-1"
                      >
                        {topic.score}%
                      </Typography>
                    </div>
                  ))
                ) : (
                  <Typography>Topic-wise scores not available</Typography>
                )}
              </div>
            )}
            {scores.isError && (
              <Typography variant="title4">Error loading scores</Typography>
            )}{" "}
          </div>
        </SlidingSheet>
      </Container>
    </>
  )
}
