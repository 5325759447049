import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { LearningItemDetails } from "api/resources/learningItems/types"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import React, { useState } from "react"
import { Outlet, useOutletContext, useParams } from "react-router-dom"

import LearningItemHeader from "../Header"
import LearningItemNav from "../Nav"
// import LearningPausedBanner from "./LearningPausedBanner"

type ContextType = {
  course: LearningItemDetails
  showLearningPausedBanner: (state: boolean) => void
}

const ITEM_TYPE = "course"

const Course = () => {
  const { slug } = useParams() as {
    slug: string
    parentSlug?: string
  }

  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const [showBanner, setShowBanner] = useState(false)

  const showLearningPausedBanner = (state: boolean) => {
    setShowBanner(state)
  }

  const { data, isLoading, isError } = useQuery({
    queryFn: () =>
      api.learningItems.getLearningItemDetails({
        urlParams: {
          itemType: ITEM_TYPE,
          itemSlug: slug,
        },
      }),
    queryKey: queries.learningItems.details(ITEM_TYPE, slug).queryKey,
  })

  if (isLoading) return <FullPageLoading />

  if (isError)
    return (
      <Fallback
        data={{
          title: "Something went wrong",
          description: "Please contact care@suraasa.com",
          image: Monitor,
        }}
        className="pt-4"
      />
    )

  return (
    <>
      {/* Container is handled inside */}
      {/* {showBanner && <LearningPausedBanner />} */}

      {data.type === "course" && (
        <LearningItemHeader
          itemName={data.name}
          image={data.image}
          itemSlug={slug}
          data={[
            { name: "Overview", link: "overview" },
            { name: "Classes", link: "classes" },
            { name: "Skill Evidences (Assignments)", link: "assignments" },
            { name: "Assessments", link: "assessments" },
            { name: "Resources", link: "resources" },
          ]}
          className="mb-3"
          showDiscussionsButton
        />
      )}
      <Container>
        <div className="grid grid-cols-12">
          {mdUp && (
            <div className="flex flex-col col-span-3 me-3">
              <LearningItemNav
                data={[
                  { name: "Overview", link: "overview" },
                  { name: "Classes", link: "classes" },
                  {
                    name: "Skill Evidences (Assignments)",
                    link: "assignments",
                  },
                  { name: "Assessments", link: "assessments" },
                  { name: "Resources", link: "resources" },
                ]}
              />
            </div>
          )}
          <div className="col-span-12 md:col-span-9">
            <Outlet
              context={{
                course: data,
                showLearningPausedBanner,
              }}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default Course

export function useCourseDetails() {
  return useOutletContext<ContextType>()
}
