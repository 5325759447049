import "glider-js/glider.min.css"

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Review, ReviewRubric } from "api/resources/assignments/types"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import TagWithAdornment from "features/AssignmentResult/TagWithAdornment"
import { ArrowDownCircle, ArrowUpCircle, Xmark } from "iconoir-react"
import React, { useState } from "react"
import Glider from "react-glider"
import { useTheme } from "react-jss"

import CriteriaWiseScore from "../CriteriaWiseScore"
import styles from "./RubricsPopup.module.css"

type Props = Review & { rubrics: ReviewRubric[] }

const BeforeIqaReview = ({
  iqaReviewExists,
  review,
}: {
  iqaReviewExists: boolean
  review: Props
}) => {
  if (!review) return null

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <Typography variant="title3" className="mb-2">
          {iqaReviewExists ? "Previous Review" : "Rubrics"}
        </Typography>

        <div className="flex mb-2">
          <TagWithAdornment
            label={
              review.marks != null ? `Score: ${review.marks.toFixed(1)}` : ""
            }
          />
        </div>
        <Typography variant="title4" className="mb-1">
          Professor’s Remarks
        </Typography>
        <Typography>{review.comments}</Typography>
      </div>

      {review.rubrics.length > 0 ? (
        <div>
          {review.rubrics
            .sort((a, b) =>
              `${a.task} ${a.criteria}` > `${b.task} ${b.criteria}` ? 1 : -1
            )
            .map((rubric, index) => (
              <CriteriaWiseScore {...rubric} key={index} />
            ))}
        </div>
      ) : (
        <div className="mt-4 bg-warning-50 p-3 border border-warning-300 rounded-xl">
          <Typography variant="largeBody">
            Rubrics for this submission are not available.
          </Typography>
        </div>
      )}
    </div>
  )
}

const AfterIqaReview = ({
  iqaReview,
  review,
}: {
  iqaReview: Props
  review: Props
}) => {
  if (!iqaReview || !review) return null

  if (!iqaReview.marks || !review.marks) return null

  const getAdornment = () => {
    if (!iqaReview.marks || !review.marks) return undefined

    if (iqaReview.marks > review.marks)
      return (
        <ArrowUpCircle className="bg-primary-500 text-success-50 rounded-full" />
      )
    if (iqaReview.marks < review.marks)
      return (
        <ArrowDownCircle className="bg-primary-500 text-critical-50 rounded-full" />
      )

    return undefined
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <Typography variant="title3" className="mb-2">
          Review after Quality Assurance
        </Typography>
        <div className="flex mb-2">
          <TagWithAdornment
            label={
              iqaReview.marks != null
                ? `Score: ${iqaReview.marks.toFixed(1)}`
                : ""
            }
            endAdornment={getAdornment()}
          />
        </div>
        <Typography variant="title4" className="mb-1">
          Professor’s Remarks
        </Typography>
        <Typography>{iqaReview.comments}</Typography>
      </div>

      {iqaReview.rubrics.length > 0 ? (
        <div>
          {iqaReview.rubrics
            .sort((a, b) =>
              `${a.task} ${a.criteria}` > `${b.task} ${a.criteria}` ? 1 : -1
            )
            .map((rubric, index) => (
              <CriteriaWiseScore {...rubric} key={index} />
            ))}
        </div>
      ) : (
        <div className="mt-4 bg-warning-50 p-3 border border-warning-300 rounded-xl">
          <Typography variant="largeBody">
            Rubrics for this submission are not available.
          </Typography>
        </div>
      )}
    </div>
  )
}

const RubricsPopup = ({
  review,
  iqaReview,
  open,
  onClose,
  mode = "sheet",
}: {
  open: boolean
  onClose: () => void
  review: Review
  iqaReview?: Review
  mode?: "sheet" | "dialog"
}) => {
  const [activeView, setActiveView] = useState<"beforeIQA" | "afterIQA">(
    "afterIQA"
  )
  const theme = useTheme<Theme>()
  const md = useMediaQuery(theme.breakpoints.only("md"))
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const rubrics = useQuery({
    enabled: open && !!review.uuid,
    queryFn: () =>
      api.assignments.getReviewRubrics({
        urlParams: {
          reviewId: review.uuid,
        },
      }),
    queryKey: queries.assignments.reviewRubrics(review.uuid).queryKey,
  })

  const iqaRubrics = useQuery({
    enabled: open && !!iqaReview?.uuid,
    queryFn: () =>
      api.assignments.getIQAReviewRubrics({
        urlParams: {
          reviewId: iqaReview?.uuid || "",
        },
      }),
    queryKey: queries.assignments.reviewRubrics(iqaReview?.uuid || "").queryKey,
  })

  const getContent = () => {
    if (!rubrics.isSuccess)
      return (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )

    const iqaReviewExists = !!iqaReview

    return isMdDown ? (
      <>
        <Glider
          onSlideVisible={event => {
            if (event.detail.slide === 0) setActiveView("beforeIQA")
            else setActiveView("afterIQA")
          }}
          id="glider"
          hasArrows
          draggable={iqaReviewExists}
          scrollLock={true}
          duration={0.25}
          slidesToShow={1.1}
          slidesToScroll={1}
          arrows={{
            prev: "#buttonPrev",
            next: "#buttonNext",
          }}
          scrollToSlide={iqaReviewExists ? 2 : 1}
        >
          <div className={clsx({ "me-3": iqaReview })}>
            <BeforeIqaReview
              iqaReviewExists={iqaReviewExists}
              review={{ ...review, rubrics: rubrics.data }}
            />
          </div>
          {iqaReview && (
            <AfterIqaReview
              iqaReview={{ ...iqaReview, rubrics: iqaRubrics.data || [] }}
              review={{ ...review, rubrics: rubrics.data }}
            />
          )}
        </Glider>
        {/* Bottom Toggle */}
        {iqaReview && md && (
          <div
            className={clsx(
              styles.toggle,
              "py-2 rounded-[35px] absolute w-[360px] bottom-6 z-0"
            )}
            style={{ left: "calc(50% - 180px)" }}
          >
            {/* Highlight toggle bar  */}
            <div
              className={clsx(
                styles.highlightBar,
                "rounded-[35px] absolute h-[45px] w-[172px] z-2 transition-[left] duration-100 ease-linear flex justify-center items-center",
                {
                  "ms-[1px] left-0.75": activeView === "beforeIQA",
                  [styles.toggleLeftPos]: activeView === "afterIQA",
                }
              )}
              style={{
                bottom: "calc(50% - 22px)",
              }}
            >
              {activeView === "beforeIQA" ? (
                <Typography variant="strong" className={styles.whiteText}>
                  Before IQA
                </Typography>
              ) : (
                <Typography variant="strong" className={styles.whiteText}>
                  After IQA
                </Typography>
              )}
            </div>

            <div className="flex items-center justify-around">
              <button id="buttonPrev">
                <Typography variant="strong">Before IQA</Typography>
              </button>
              <button id="buttonNext">
                <Typography variant="strong">After IQA</Typography>
              </button>
            </div>
          </div>
        )}
      </>
    ) : (
      <div
        className={clsx({
          "grid sm:grid-cols-2 sm:grid-flow-col sm:gap-[60px] sm:p-2":
            iqaReview,
        })}
      >
        <BeforeIqaReview
          iqaReviewExists={iqaReviewExists}
          review={{ ...review, rubrics: rubrics.data }}
        />
        {iqaReview && (
          <AfterIqaReview
            iqaReview={{ ...iqaReview, rubrics: iqaRubrics.data || [] }}
            review={{ ...review, rubrics: rubrics.data }}
          />
        )}
      </div>
    )
  }

  if (mode === "dialog")
    return (
      <Dialog
        onClose={onClose}
        open={open}
        width={iqaReview ? 1260 : md ? 750 : 650}
        fullScreen={Boolean(iqaReview) || isXs}
      >
        <div className={styles.dialogTitleOverride}>
          <DialogTitle>
            <Button
              variant="text"
              onClick={onClose}
              startAdornment={<Xmark />}
              color="black"
              size="sm"
              nudge="left"
            >
              Close
            </Button>
          </DialogTitle>
        </div>
        <DialogContent className="p-2">{getContent()}</DialogContent>
      </Dialog>
    )

  return (
    <SlidingSheet
      from="end"
      onClose={onClose}
      open={open}
      className={clsx("md:w-[750px] w-[500px]", { ["w-[1260px]"]: iqaReview })}
      fullScreen={Boolean(iqaReview) || isSmDown}
    >
      <div className="p-3">
        <Button
          variant="text"
          onClick={onClose}
          startAdornment={<Xmark />}
          color="black"
          size="sm"
          nudge="left"
          className="mb-3"
        >
          Close
        </Button>
        {getContent()}
      </div>
    </SlidingSheet>
  )
}

export default RubricsPopup
