import {
  Button,
  IconButton,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import Laptop from "assets/UploadVideo/laptop.png"
import { Xmark } from "iconoir-react"
import React from "react"

type Props = {
  onClose: () => void
  onContinue: () => void
}

const Introduction = ({ onClose, onContinue }: Props) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div className="relative h-[90vh] overflow-auto">
      <IconButton
        onClick={onClose}
        color="black"
        className="absolute md:left-2 md:top-3 sm:left-2 sm:top-2 left-1 top-1"
      >
        <Xmark />
      </IconButton>
      <img
        src={Laptop}
        alt="laptop"
        width="100%"
        className="rounded-t-xl mb-4 max-h-[40vh] object-contain bg-[#F4F4F4]"
      />
      <div className="mb-4 max-w-[492px] mx-auto sm:px-0 px-2">
        <Typography
          textAlign="center"
          variant={isSmDown ? "title3" : "title2"}
          className="mb-1"
        >
          Video Portfolio
        </Typography>
        <Typography
          textAlign="center"
          variant={isSmDown ? "body" : "largeBody"}
          className="mb-1"
        >
          Video Portfolio is the quickest way to showcase your skills and
          experience to employers across the world. Teachers with video
          portfolio are hired much faster.
        </Typography>
      </div>
      <Button
        className="block mx-auto mb-8"
        style={{
          display: "block",
        }}
        onClick={onContinue}
      >
        Continue
      </Button>
    </div>
  )
}

export default Introduction
