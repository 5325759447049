import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("profile", {
  profileData: () => ({
    queryKey: ["profileData"],
  }),
  retrieve: () => ({
    queryKey: ["retrieve"],
  }),
  learningCertificatesList: () => ({
    queryKey: ["learningCertificatesList"],
  }),
  itoCertificatesList: () => ({
    queryKey: ["itoCertificatesList"],
  }),
  masterclassCertificatesList: () => ({
    queryKey: ["masterclassCertificatesList"],
  }),
  certificateExistence: (username, itemType, itemSlug) => ({
    queryKey: ["certificateExistence", username, itemType, itemSlug],
  }),
  getItemCertificate: courseId => ({
    queryKey: ["getItemCertificate", courseId],
  }),
  skillEvidenceList: () => ({
    queryKey: ["skillEvidenceList"],
  }),
})
