import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import Step1Img from "assets/UpdateProfile/step-1.webp"
import ProfileContext from "features/Profile/context"
import { Xmark } from "iconoir-react"
import React, { useContext } from "react"

type Props = { open: boolean; onClick: () => void; onClose: () => void }

const Step1 = ({ onClick, onClose, open }: Props) => {
  const { subjects, workLocationInterest } = useContext(ProfileContext)
  const subjectPriority =
    subjects.data.length >= 2
      ? `We have automatically marked ${subjects.data[0].subject.name} as primary and ${subjects.data[1].subject.name} as your secondary subject in your profile.`
      : subjects.data.length === 1
      ? `We have automatically marked ${subjects.data[0].subject.name} as primary subject in your profile.`
      : null

  const locationInterestPriority =
    workLocationInterest.data.length >= 2
      ? `Similarly ${workLocationInterest.data[0].state.name} as primary and ${workLocationInterest.data[1].state.name} as your secondary work location interest.`
      : workLocationInterest.data.length === 1
      ? `Similarly ${workLocationInterest.data[0].state.name} as primary work location interest.`
      : null

  const displayText =
    subjects.data.length === 0
      ? workLocationInterest.data.length >= 2
        ? `We have automatically marked ${workLocationInterest.data[0].state.name} as primary and ${workLocationInterest.data[1].state.name} as your secondary work location interest.`
        : workLocationInterest.data.length === 1
        ? `We have automatically marked ${workLocationInterest.data[0].state.name} as primary work location interest.`
        : null
      : subjectPriority || locationInterestPriority
      ? [subjectPriority, locationInterestPriority].filter(Boolean).join(" ")
      : null

  return (
    <Dialog open={open} onRequestClose={undefined}>
      <DialogContent className="!p-0">
        <div className="relative w-full h-full px-2 pt-4 pb-2 md:px-6">
          <img
            src={Step1Img}
            alt="step-1-img"
            className="object-contain max-h-7 sm:max-h-8 mb-2"
          />
          <IconButton
            className="absolute top-3 right-3 z-1"
            onClick={onClose}
            color="black"
          >
            <Xmark />
          </IconButton>

          <Typography
            className="mb-2 leading-tight"
            variant="title2"
            color="onSurface.900"
          >
            Update in how we recommend jobs on Suraasa
          </Typography>
          <Typography className="mb-1" variant="body" color="onSurface.500">
            We now match your profile with the right schools based on your
            primary and secondary subjects and work location interests from your
            Suraasa profile.
          </Typography>
          {displayText && (
            <Typography className="mb-1" variant="body" color="onSurface.500">
              {displayText}
            </Typography>
          )}
          <Typography variant="body" color="onSurface.500" className="mb-4">
            If you wish to change this, please review subjects and work location
            interests on your Suraasa profile.
          </Typography>
          <Button fullWidth className="mb-1" onClick={onClick}>
            Review Profile
          </Button>
          <Button fullWidth variant="text" onClick={onClose}>
            I’ll do it later
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default Step1
