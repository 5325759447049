import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui"
import React, { PropsWithChildren } from "react"
import { useAnchorMargin } from "utils/hooks/useAnchorMargin"

export const CUSTOM_BREAKPOINT =
  "@media (min-width:960px) and (max-width:2160px)"

export const DRAWER_WIDTH = 420
export const PADDING = 30

export const CONTAINER_ANCHOR_REF = "home-anchor-ref"

export const CustomContainer = (
  props: PropsWithChildren & {
    className?: string
    paddingRight?: number
    removeMarginRight?: boolean
  }
) => {
  const { marginLeft } = useAnchorMargin(`#${CONTAINER_ANCHOR_REF}`)

  return (
    <>
      <Container>
        <div id={CONTAINER_ANCHOR_REF} />
      </Container>
      <div
        className={props.className}
        style={{
          marginLeft,
          marginRight: !props.removeMarginRight ? DRAWER_WIDTH : undefined,
          paddingRight: props.paddingRight,
        }}
      >
        {props.children}
      </div>
    </>
  )
}

const DynamicContainer = (
  props: PropsWithChildren & { className?: string }
) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isCustomBreakpoint = useMediaQuery(CUSTOM_BREAKPOINT)

  const CurrentContainer =
    !isCustomBreakpoint || isSmDown ? Container : CustomContainer

  return (
    <CurrentContainer
      className={props.className}
      paddingRight={!isCustomBreakpoint || isSmDown ? undefined : PADDING}
    >
      {props.children}
    </CurrentContainer>
  )
}

export default DynamicContainer
