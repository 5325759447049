import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("orders", {
  list: () => ({
    queryKey: ["orders"],
  }),
  orderItem: (orderId: string) => ({
    queryKey: ["orderDetails", orderId],
  }),
  invoice: (transactionId: string) => ({
    queryKey: ["invoice", transactionId],
  }),
})
