import { Button, theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Discussion } from "api/resources/discussions/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import Comment from "../Comment"
import CommentField from "../CommentField"
import LikesAndComments from "./LikesAndComments"
import PostDetails from "./PostDetails"

type Props = {
  data: Discussion
  className?: string
  interactionHandler?: (id: string) => void
  additionalData?: {
    userPlannerItemId: number
    discussion: number
    course: string
  }
  courseId?: string | null
}

const Post = ({
  additionalData,
  data,
  className,
  interactionHandler: customInteractionHandler,
  courseId,
}: Props) => {
  const navigate = useNavigate()

  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [showReplyField, setShowReplyField] = useState(false)
  const [commentCount, setCommentCount] = useState(1)

  const { data: commentList } = useInfiniteQuery({
    queryKey: queries.discussions.commentList(data.id).queryKey,

    queryFn: x => {
      return api.discussions.comments({
        params: {
          id: data.id,
          page: x.pageParam || 1,
          fields: "replies",
        },
      })
    },

    onError: err => {
      if (err instanceof APIError) {
        console.log(err.statusCode)
        console.log(err.is500)
        console.log(err.__errors)
      }
    },

    // This needs to be updated
    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
    // enabled: !!data.totalComments,
  })

  const interactionHandler = () => {
    if (customInteractionHandler) {
      customInteractionHandler(data.id)
      return
    }

    navigate(`/discussions/${data.id}`)
  }

  return (
    <div
      className={clsx(
        "border border-onSurface-200 sm:rounded-2xl rounded-none bg-white overflow-hidden",
        className
      )}
    >
      <div
        className={clsx("p-2 flex", {
          "pb-0": data.totalComments === 0,
        })}
      >
        <div className="grow">
          <PostDetails
            user={data.user}
            text={data.text}
            truncateText
            datePublished={data.dateCreated}
            discussionId={data.id}
            totalComments={data.totalComments}
            onReadMore={interactionHandler}
          />
          <LikesAndComments
            discussionId={data.id}
            hasVoted={data.voted}
            noOfLikes={data.upvotes}
            noOfComments={data.totalComments}
            className={clsx("pt-1 border-t", {
              "mb-2 pb-1 border-b": data.totalComments > 0 || showReplyField,
              "sm:ms-8": true,
            })}
            courseId={courseId || undefined}
            handleCommentsClick={interactionHandler}
          />

          <div className="sm:ms-8">
            {(data.totalComments > 0 || showReplyField) && (
              <div>
                <Typography variant="title4" className="mb-2">
                  Replies
                </Typography>
                <CommentField
                  additionalData={additionalData}
                  discussionId={data.id}
                  fullWidth
                  onAdd={() => {
                    setCommentCount(prevState => prevState + 1)
                  }}
                  courseId={courseId || undefined}
                  name={data?.user.firstName}
                  className="mb-3"
                />
              </div>
            )}
            {commentList &&
              commentList.pages
                .slice(0, 1)
                .map(item =>
                  item.data
                    .slice(0, commentCount)
                    .map((comment, index) => (
                      <Comment
                        additionalData={additionalData}
                        key={index}
                        discussionId={data.id}
                        comment={comment}
                        onShowMore={interactionHandler}
                      />
                    ))
                )}
          </div>
        </div>
      </div>

      {data.totalComments > 1 && (
        <div className="py-0.5 bg-onSurface-100 text-center">
          <Button onClick={interactionHandler} variant="text" color="black">
            View all comments
          </Button>
        </div>
      )}

      {data.totalComments === 0 && !showReplyField && (
        <>
          {xsDown ? (
            <div className="p-2 flex justify-center bg-highlight-50 text-highlight-900 items-center w-full">
              <Button
                variant="text"
                onClick={() => {
                  setShowReplyField(true)
                }}
              >
                Initiate discussion by replying
              </Button>
            </div>
          ) : (
            <div className="pl-10 py-1.25 pr-2 flex justify-between bg-highlight-50 text-highlight-900 items-center w-full">
              <Typography variant="smallBody" className="text-highlight-900">
                Initiate discussion by replying
              </Typography>
              <Button
                onClick={() => {
                  setShowReplyField(true)
                }}
                variant="text"
              >
                Reply
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Post
