import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { PropsWithClassName } from "utils/types"

const ContentContainer = ({
  children,
  className,
}: PropsWithChildren<PropsWithClassName>) => {
  return (
    <div
      className={clsx(
        "grid grid-cols-12 p-2 sm:px-0 h-[80vh] overflow-auto",
        className
      )}
    >
      <div className="sm:col-span-10 sm:col-start-2 col-span-12 col-start-1">
        {children}
      </div>
    </div>
  )
}

export default ContentContainer
