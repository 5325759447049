import { Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import Submission from "features/AssignmentResult/Submission"
import { useCopyDisable } from "features/ITO/Assessments/Proctoring/hooks/useCopyDisable"
import {
  SkillProfileRoutes,
  useGenerateURL,
} from "features/SkillProfile/hooks/useSkillProfileNavigation"
import { InfoCircle } from "iconoir-react"
import { useParams } from "react-router-dom"
import { getAuthInfo } from "utils/helpers"

import SubmissionPreview from "../../../AssignmentResult/Submission/SubmissionPreview"

const EvidenceDetails = ({
  firstName,
  isSelf,
}: {
  firstName: string
  isSelf: boolean
}) => {
  const { submissionId } = useParams() as { submissionId: string }

  const { navigate: customNavigation } = useGenerateURL()

  useCopyDisable()

  const {
    data: submissionData,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () =>
      api.assignments.retrieveSubmission({
        urlParams: { submissionId: submissionId },
      }),
    queryKey: queries.assignments.submissionsByUUID(submissionId).queryKey,
  })

  const isLoggedIn = !!getAuthInfo()
  const isViewingOwnSubmission = isSelf

  const isSelfSubmission =
    submissionData?.user.uuid === getAuthInfo()?.user.uuid

  if (!isViewingOwnSubmission && isLoggedIn) {
    return (
      <div className="flex flex-col justify-content h-full mt-5">
        <Fallback
          data={{ image: Monitor, title: "Skill Evidence not available" }}
        />
      </div>
    )
  }

  if (isLoading || isError) {
    return (
      <div className="flex flex-col justify-content h-full">
        <Fallback
          isLoading={isLoading}
          hasError={isError}
          data={{ image: Monitor, title: "Skill Evidence not available" }}
        />
      </div>
    )
  }

  return (
    <div className="mt-2.5 px-3 pb-4">
      <Typography className="mb-2" color="onSurface.800">
        Following content represents {firstName}’s original work on{" "}
        <strong>{submissionData?.assignment.title}</strong> which has undergone
        multiple levels of quality assurance and plagiarism checks by Suraasa.
      </Typography>
      <button
        onClick={() =>
          customNavigation(SkillProfileRoutes.VERIFICATION_PROCESS)
        }
        className="flex items-center w-full px-2 py-1.5 bg-highlight-50 border-highlight-100 border rounded-xl mb-3"
      >
        <InfoCircle
          className="me-2 text-highlight-500 shrink-0 hidden sm:block"
          width={20}
          height={20}
        />
        <Typography
          color="highlight.900"
          variant="strongSmallBody"
          textAlign="left"
        >
          Learn how Suraasa verifies every single skill evidence (assignment)
        </Typography>
      </button>

      <Typography className="mb-2" variant="title3" color="onSurface.800">
        Submission
      </Typography>

      <div className="select-none relative">
        {isSelf && isSelfSubmission ? (
          <Submission
            attachments={submissionData.attachments}
            references={submissionData.references}
            content={submissionData.submission}
          />
        ) : (
          <SubmissionPreview
            content={`<p style="font-size: 1em; font-weight: bold; margin-bottom: 24px;">Assignment Submission</p>
          <p style="font-size: 1.5em; font-weight: bold;">Submitted By: ${firstName}</p>
          <p style="font-size: 2em; font-weight: bold;">${submissionData?.assignment.title}</p>
          <p style="font-size: 1em; margin-top: 32px;">${submissionData?.assignment.title} refers to...</p>`}
          />
        )}
      </div>
    </div>
  )
}

export default EvidenceDetails
