import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import React from "react"
import { browserName, osName } from "react-device-detect"

import BrowserSpecificAsset from "../BrowserSpecificAsset"
import styles from "./AllowCameraDialog.module.css"

type Props = {
  open: boolean
}

const AllowCameraDialog = ({ open }: Props) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="subtitle2">
          Allow camera before proceeding ({browserName} on {osName})
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className="mb-3">
          Looks like access to your camera is blocked. Follow these steps to
          allow camera access:
        </Typography>

        <div className={styles.list}>
          <ol>
            <BrowserSpecificAsset
              render={steps => (
                <>
                  {steps.map(({ text, img }, i) => (
                    <li key={i}>
                      <Typography>{text}</Typography>
                      {img && (
                        <img
                          width="100%"
                          src={img}
                          alt="camera block icon"
                          className="mt-1"
                        />
                      )}
                    </li>
                  ))}
                </>
              )}
              data={{
                SamsungBrowser: {
                  Android: [
                    {
                      text: "Click on the 'Menu' button on the bottom right of the browser.",
                      img: "/assets/camera-permission-steps/samsung-browser-step-1.png",
                    },
                    {
                      text: "Click on 'Settings'.",
                      img: "/assets/camera-permission-steps/samsung-browser-step-2.png",
                    },
                    {
                      text: "Go to 'Sites and downloads'.",
                      img: null,
                    },
                    {
                      text: "Go to 'Site permissions'.",
                      img: "/assets/camera-permission-steps/samsung-browser-step-3.png",
                    },
                    {
                      text: "Click on 'Camera'.",
                      img: "/assets/camera-permission-steps/samsung-browser-step-4.png",
                    },
                    {
                      text: "Find 'learn.suraasa.com' and turn on both switches as shown.",
                      img: "/assets/camera-permission-steps/samsung-browser-step-5.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                },
                MobileSafari: {
                  IOS: [
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                    {
                      text: "You will be presented with a prompt as displayed below. Press 'Allow'.",
                      img: "/assets/camera-permission-steps/ios-safari-prompt.png",
                    },
                  ],
                },
                Safari: {
                  MAC_OS: [
                    {
                      text: `Click on 'Safari' at the top-left corner of your screen and then click on 'Settings for ${window.location.origin}'.`,
                      img: "/assets/camera-permission-steps/macos-safari-menu.jpeg",
                    },
                    {
                      text: "Now change Camera setting from 'Ask' to 'Allow'.",
                      img: "/assets/camera-permission-steps/macos-safari-settings.jpeg",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                },
                Firefox: {
                  Windows: [
                    {
                      text: "Click on the camera icon at the left end of the address bar of your browser.",
                      img: "/assets/camera-permission-steps/firefox-address-bar.png",
                    },
                    {
                      text: "Click on the 'Blocked' button to reset the permission.",
                      img: "/assets/camera-permission-steps/firefox-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                  MAC_OS: [
                    {
                      text: "Click on the camera icon at the right end of the address bar of your browser.",
                      img: "/assets/camera-permission-steps/firefox-address-bar.png",
                    },
                    {
                      text: "Click on the 'Blocked' button to reset the permission.",
                      img: "/assets/camera-permission-steps/firefox-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                },
                Chrome: {
                  IOS: [
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                    {
                      text: "You will be presented with a prompt as displayed below. Press 'Allow'.",
                      img: "/assets/camera-permission-steps/ios-safari-prompt.png",
                    },
                  ],
                  Android: [
                    {
                      text: "Click on the lock icon on the address bar.",
                      img: "/assets/camera-permission-steps/mobile-chrome-step-1.png",
                    },
                    {
                      text: "Click on ‘Permissions’.",
                      img: "/assets/camera-permission-steps/mobile-chrome-step-2.png",
                    },
                    {
                      text: "Change the Camera setting from 'Blocked' to 'Allow'.",
                      img: "/assets/camera-permission-steps/mobile-chrome-step-3.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                  Windows: [
                    {
                      text: "Click on the camera icon at the right end of the address bar of your browser.",
                      img: "/assets/camera-permission-steps/chrome-address-bar.png",
                    },
                    {
                      text: `Now select the 'Always allow ${window.location.origin} to access your camera' button and then click 'Done'.`,
                      img: "/assets/camera-permission-steps/chrome-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                  MAC_OS: [
                    {
                      text: "Click on the camera icon at the right end of the address bar of your browser.",
                      img: "/assets/camera-permission-steps/chrome-address-bar.png",
                    },
                    {
                      text: "Now change Camera setting from 'Ask' to 'Allow'.",
                      img: "/assets/camera-permission-steps/chrome-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                },
              }}
            />

            <li>
              <Typography>
                If your camera still does not work after following the steps
                above, then please make sure your device has a camera and it is
                not blocked by the operating system.{" "}
                <a
                  href={`https://www.google.com/search?q=how+to+turn+camera+on+${osName}+${browserName}`}
                  target="_blank"
                  className={styles.troubleShootLink}
                  rel="noreferrer"
                >
                  Click here to learn more
                </a>{" "}
                or try using a different device.
              </Typography>
            </li>
          </ol>
        </div>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Refresh page",
            onClick: () => {
              window.location.reload()
            },
          },
          secondary: null,
        }}
      />
    </Dialog>
  )
}

export default AllowCameraDialog
