import { Container, toast } from "@suraasa/placebo-ui"
import { Assessment, AssessmentType } from "api/resources/ito/assessments/types"
import GeneralInstruction from "features/ITO/Assessments/Instructions/GeneralInstructions"
import SystemRequirements from "features/ITO/Assessments/Instructions/SystemRequirement"
import { useOlympiadPrices } from "features/ITO/utils/hooks/useOlympiadPrices"
import { useTestWindowOpen } from "features/ITO/utils/hooks/useRedirectTest"

import ContactUs from "../BeforeRegistrationPhase/components/ContactUs"
import FAQs from "../BeforeRegistrationPhase/components/FAQs"
import { itoFAQs } from "../BeforeRegistrationPhase/components/FAQs/data"
import LiveTestBanner from "./components/LiveTestBanner"
import MockTestBanner from "./components/MockTestBanner"

type Props = {
  mode: "mock" | "olympiad"
  refreshState: () => void
  assessments: Assessment[]
}

const TestPhase = ({ mode, refreshState, assessments = [] }: Props) => {
  const { getPrice } = useOlympiadPrices({ edition: "2023" })
  const { isInExam, openTestWindow } = useTestWindowOpen()

  const onAssessmentEnd = async () => {
    refreshState()
  }

  const takeMockTest = (item: Assessment) => {
    if (isInExam) {
      toast.error("Assessment is already open in a different tab")
      return
    }
    openTestWindow({
      assessmentId: item.id,
      onTabClose: onAssessmentEnd,
      attemptId: item.attempt?.id,
    })
  }

  return (
    <div className="mt-4">
      <Container>
        <div className="mb-3">
          <SystemRequirements />
        </div>
      </Container>

      <Container>
        <div className="flex flex-col space-y-2">
          {mode === "mock" &&
            assessments
              .filter(item => item.assessmentType === AssessmentType.MOCK)
              .map(item => (
                <MockTestBanner
                  key={item.id}
                  data={item}
                  onStart={() => takeMockTest(item)}
                  onAssessmentEnd={onAssessmentEnd}
                />
              ))}
          {assessments
            .filter(item => item.assessmentType === AssessmentType.LIVE)
            .map(item => {
              return (
                <LiveTestBanner
                  isItoExam={mode === "olympiad"}
                  key={item.id}
                  onStart={() => takeMockTest(item)}
                  data={item}
                  onAssessmentEnd={onAssessmentEnd}
                />
              )
            })}
        </div>
        <GeneralInstruction
          className="mt-4"
          isProctored
          totalQuestions={50}
          isOlympiad
        />
        <div className="mt-5" />
        <FAQs data={itoFAQs(getPrice)} />
      </Container>
      <div className="mt-5" />
      <ContactUs />
    </div>
  )
}

export default TestPhase
