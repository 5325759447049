import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { IncompleteItemsCount } from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import { CheckCircle, Circle, Xmark } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { handleErrors } from "utils/helpers"

import Check from "../assets/icons/green-check.svg"
import CourseCompletionSheet from "../Course/CourseCompletionSheet"

type Props = {
  courseName: string
  courseSlug: string
  onClose: () => void
  open: boolean
}

enum DialogType {
  none,
  pendingItems,
  certificate,
}

const PlannerCompleted = (props: Props) => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const plannerId = searchParams.get("plannerId")

  const [dialogType, setDialogType] = useState<DialogType>(DialogType.none)
  const [overview, setOverview] = useState<IncompleteItemsCount | null>(null)

  const handleClose = () => {
    setDialogType(DialogType.none)
    props.onClose()
  }

  useEffect(() => {
    const getOverview = async () => {
      try {
        const res = await api.learningItems.incompleteItemsCount({
          urlParams: {
            itemType: "course",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            itemId: plannerId!,
          },
        })
        setOverview(res)

        if (
          res.assessments === 0 &&
          res.assignments === 0 &&
          res.classes === 0
        ) {
          setDialogType(DialogType.certificate)
        } else {
          setDialogType(DialogType.pendingItems)
        }
      } catch (e) {
        if (e instanceof APIError) handleErrors(e)
      }
    }

    if (props.open) {
      getOverview()
    }
  }, [plannerId, props.open])

  return (
    <>
      <Dialog
        open={dialogType === DialogType.pendingItems}
        onRequestClose={handleClose}
      >
        <DialogContent>
          <div className="sm:px-2 sm:py-3 px-1 py-2">
            <div className="flex items-start justify-between mb-2">
              <img src={Check} alt="icon" />
              <IconButton onClick={handleClose} color="black">
                <Xmark />
              </IconButton>
            </div>
            <Typography
              variant="title1"
              style={{ fontFamily: "Nicholas-Bold" }}
            >
              Great Job!
            </Typography>
            <Typography className="mb-3">
              You have successfully completed the self-paced part of the course{" "}
              <b>{`"${props.courseName}"`}</b>.<br />
              <br />
              However, you still have some things left to complete.
            </Typography>
            {overview ? (
              [
                {
                  count: 0,
                  name: "Self-paced course",
                },
                {
                  count: overview.assessments,
                  name: "Assessments",
                },
                {
                  count: overview.assignments,
                  name: "Skill Evidences (Assignments)",
                },
                {
                  count: overview.classes,
                  name: "Classes",
                },
              ].map((item, idx) => (
                <div className="flex items-center mb-1.5" key={idx}>
                  {item.count > 0 ? (
                    <Circle color={`${theme.colors.onSurface[200]}`} />
                  ) : (
                    <CheckCircle
                      color="white"
                      className="bg-success-500 rounded-full"
                    />
                  )}
                  <Typography
                    variant="strong"
                    className="ms-1"
                    color={item.count > 0 ? "onSurface.500" : undefined}
                  >
                    {item.name}
                  </Typography>
                </div>
              ))
            ) : (
              <div className="pt-3 flex items-center justify-center">
                <CircularProgress />
              </div>
            )}

            <Button
              fullWidth
              style={{ borderRadius: "50px" }}
              className="mt-3"
              onClick={() =>
                navigate(routes.course.replace(":slug", props.courseSlug))
              }
            >
              Continue
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <CourseCompletionSheet
        open={dialogType === DialogType.certificate}
        handleClose={handleClose}
      />
    </>
  )
}

export default PlannerCompleted
