import { useEffect, useState } from "react"
import { isRTL } from "utils/constants"

import { renderWithRTL } from "../renderWithRTL"

type Direction = "ltr" | "rtl"

export const useDOMDirection = (app: any) => {
  const [direction, setCurrentDir] = useState<Direction>(isRTL ? "rtl" : "ltr")

  const changeDirection = (newDir: Direction) => {
    setCurrentDir(newDir)
    document.body.dir = newDir
    renderWithRTL(app, newDir === "rtl")
  }

  useEffect(() => {
    changeDirection(isRTL ? "rtl" : "ltr")
  }, [])

  return { direction, changeDirection }
}
