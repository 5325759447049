import React from "react"
import { browserIsSupportedForITO } from "utils/constants"

import UnsupportedBrowserDialog from "../../Instructions/SystemRequirement/UnsupportedBrowserDialog"
import AttemptITOAssessment from ".."

const ITOAssessmentWrapper = () => {
  if (browserIsSupportedForITO === "supported") {
    return <AttemptITOAssessment />
  }

  return (
    <>
      <UnsupportedBrowserDialog open />
    </>
  )
}

export default ITOAssessmentWrapper
