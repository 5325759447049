import { Button, Container } from "@suraasa/placebo-ui"
import { useExcludeShadow } from "components/Navigation/hooks/useExcludeShadow"
import { Link } from "react-router-dom"
import routes from "routes"

const WithoutAuth = () => {
  const omitShadow = useExcludeShadow()
  return (
    <nav
      id="topNavbar"
      className="z-navbar sticky top-0 bg-white border-b border-surface-200"
      style={{
        boxShadow: omitShadow ? "initial" : "1px 1px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <div className="flex justify-between items-center py-2">
          <Link to={routes.home}>
            <img
              src="https://assets.suraasa.com/logos/suraasa-logo-black-full.svg"
              alt="suraasa logo"
              width={150}
            />
          </Link>

          <div>
            <Button component={Link} to={routes.login} variant="text">
              Sign In
            </Button>
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default WithoutAuth
