import { useEffect, useState } from "react"
import { isAndroid, isIOS } from "react-device-detect"

type Props = {
  disabled?: boolean
}

export function useTabUnfocusDetection(
  { disabled }: Props = { disabled: false }
) {
  const [tabUnfocusStatus, setTabUnfocusStatus] = useState(false)

  const visibilityChange = () => {
    setTabUnfocusStatus(document.hidden)
  }

  const onBlur = () => {
    setTabUnfocusStatus(true)
  }
  const onFocus = () => {
    setTabUnfocusStatus(false)
  }

  useEffect(() => {
    if (disabled) return

    if (isAndroid || isIOS) {
      document.addEventListener("visibilitychange", visibilityChange)
    } else {
      window.addEventListener("blur", onBlur)
      window.addEventListener("focus", onFocus)
    }

    return () => {
      window.removeEventListener("blur", onBlur)
      window.removeEventListener("focus", onFocus)
      document.removeEventListener("visibilitychange", visibilityChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { tabUnfocusStatus } as const
}
