import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import {
  formatErrorResponse,
  formatErrorResponseOld,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import {
  Award,
  Certification,
  CourseCompletionCertificate,
  Evidence,
  Interest,
  ITOCertificate,
  LanguageResponse,
  LearningCertificate,
  MasterclassCertificate,
  Profile,
  Project,
  Publication,
  Qualification,
  QualificationLevel,
  SkillEvidence,
  SkillEvidenceOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "./types"
import { urls } from "./urls"

export default {
  retrieve: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.profile.public(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({
    data,
    headers,
  }: Config): Promise<APIResponseOld<Profile>> => {
    try {
      const res = await axios.put(urls.profile.detail(), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },

  downloadResume: async (): Promise<APIResponse<Blob>> => {
    try {
      const res = await axios.get(urls.profile.resume(), {
        responseType: "blob",
      })
      return formatSuccessResponse(res, { keepOriginalResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listAchievements: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<
    APIResponse<{
      publications: Publication[]
      awards: Award[]
      testScores: TestScore[]
      projects: Project[]
    }>
  > => {
    try {
      const res = await axios.get(urls.achievements.list(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  languages: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<LanguageResponse[]>> => {
      try {
        const res = await axios.get(urls.languages.list(urlParams.username))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({
      data,
    }: Config): Promise<APIResponseOld<LanguageResponse>> => {
      try {
        const res = await axios.post(urls.languages.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.languages.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  locations: {
    create: async ({
      data,
    }: Config): Promise<APIResponseOld<WorkLocationInterest>> => {
      try {
        const res = await axios.post(urls.locations.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.locations.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    updateSequence: async ({
      data,
    }: Config): Promise<APIResponseOld<WorkExperienceType>> => {
      try {
        const res = await axios.put(urls.locations.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  awards: {
    create: async ({ data }: Config): Promise<APIResponseOld<Award>> => {
      try {
        const res = await axios.post(urls.awards.list(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.awards.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Award>> => {
      try {
        const res = await axios.put(urls.awards.detail(urlParams.id), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Award>> => {
      try {
        const res = await axios.get(urls.awards.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  publications: {
    create: async ({ data }: Config): Promise<APIResponseOld<Publication>> => {
      try {
        const res = await axios.post(urls.publications.list(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Publication>> => {
      try {
        const res = await axios.get(urls.publications.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Publication>> => {
      try {
        const res = await axios.put(
          urls.publications.detail(urlParams.id),
          data
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.publications.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  testScores: {
    create: async ({ data }: Config): Promise<APIResponseOld<TestScore>> => {
      try {
        const res = await axios.post(urls.testScores.list(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<TestScore>> => {
      try {
        const res = await axios.get(urls.testScores.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<TestScore>> => {
      try {
        const res = await axios.put(urls.testScores.detail(urlParams.id), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.testScores.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  projects: {
    create: async ({ data }: Config): Promise<APIResponseOld<Project>> => {
      try {
        const res = await axios.post(urls.projects.list(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Project>> => {
      try {
        const res = await axios.get(urls.projects.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Project>> => {
      try {
        const res = await axios.put(urls.projects.detail(urlParams.id), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.projects.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  interests: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        preferredWorkLocations: WorkLocationInterest[]
        interests: Interest[]
        subjects: SubjectResponse[]
      }>
    > => {
      try {
        const res = await axios.get(urls.interests.list(urlParams.username))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({ data }: Config): Promise<APIResponseOld<Interest>> => {
      try {
        const res = await axios.post(urls.interests.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.interests.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  subjects: {
    create: async ({
      data,
    }: Config): Promise<APIResponseOld<SubjectResponse>> => {
      try {
        const res = await axios.post(urls.subjects.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.subjects.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    updateSequence: async ({
      data,
    }: Config): Promise<APIResponseOld<WorkExperienceType>> => {
      try {
        const res = await axios.put(urls.subjects.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  listAcademics: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<
    APIResponse<{
      certifications: Certification[]
      qualifications: Qualification[]
    }>
  > => {
    try {
      const res = await axios.get(urls.academics.list(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listQualificationLevels: async (): Promise<
    APIResponseOld<QualificationLevel[]>
  > => {
    try {
      const res = await axios.get(urls.qualificationLevels.list())
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },

  certification: {
    create: async ({
      data,
    }: Config): Promise<APIResponseOld<Certification>> => {
      try {
        const res = await axios.post(urls.certification.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(urls.certification.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Certification>> => {
      try {
        const res = await axios.put(
          urls.certification.detail(urlParams.id),
          data
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponseOld<Certification & { evidences: Evidence[] }>
    > => {
      try {
        const res = await axios.get(urls.certification.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },

    evidence: {
      create: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponseOld<Evidence[]>> => {
        try {
          const res = await axios.post(
            urls.certification.evidence(urlParams.id),
            data
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponseOld(e)
        }
      },
      delete: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponseOld<Certification>> => {
        try {
          const res = await axios.delete(
            urls.certification.evidence(urlParams.id),
            {
              data,
            }
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponseOld(e)
        }
      },
    },
  },

  qualification: {
    create: async ({ data }: Config): Promise<APIResponse<Qualification>> => {
      try {
        const res = await axios.post(urls.qualification.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.qualification.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Qualification>> => {
      try {
        const res = await axios.put(
          urls.qualification.detail(urlParams.id),
          data
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponseOld<Qualification & { evidences: Evidence[] }>
    > => {
      try {
        const res = await axios.get(urls.qualification.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    evidence: {
      create: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponseOld<Evidence[]>> => {
        try {
          const res = await axios.post(
            urls.qualification.evidence(urlParams.id),
            data
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponseOld(e)
        }
      },
      delete: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponseOld<Certification>> => {
        try {
          const res = await axios.delete(
            urls.qualification.evidence(urlParams.id),
            {
              data,
            }
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponseOld(e)
        }
      },
    },
  },

  videoPortfolio: {
    retrieveSelf: async (): Promise<
      APIResponse<{ videoPortfolio: string | null }>
    > => {
      try {
        const res = await axios.get(urls.videoPortfolio.detail())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<{ videoPortfolio: string | null }>
    > => {
      try {
        const res = await axios.get(
          urls.videoPortfolio.retrieve(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    create: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<{ videoPortfolio: string }>> => {
      try {
        const res = await axios.post(
          urls.videoPortfolio.detail(),
          data,
          headers
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async (): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.videoPortfolio.detail())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  workExperiences: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<WorkExperienceType[]>> => {
      try {
        const res = await axios.get(
          urls.workExperiences.list(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({
      data,
    }: Config): Promise<APIResponseOld<WorkExperienceType>> => {
      try {
        const res = await axios.post(urls.workExperiences.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(
          urls.workExperiences.detail(urlParams.id)
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<WorkExperienceType>> => {
      try {
        const res = await axios.put(
          urls.workExperiences.detail(urlParams.id),
          data
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<WorkExperienceType>> => {
      try {
        const res = await axios.get(urls.workExperiences.detail(urlParams.id))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },

  skillEvidences: {
    overview: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<SkillEvidenceOverview>> => {
      try {
        const res = await axios.get(
          urls.skillEvidences.overview(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        skills: SkillEvidence[]
        user: { fullName: string; photo: string | null } | null
      }>
    > => {
      try {
        const res = await axios.get(
          urls.skillEvidences.list(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    toggleVisibility: async ({ data }: Config): Promise<APIResponse<any>> => {
      try {
        const res = await axios.put(
          urls.skillEvidences.toggleVisibility(),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  credentials: {
    list: async ({
      params,
    }: Config): Promise<
      APIResponse<LearningCertificate[] | ITOCertificate[]>
    > => {
      try {
        const res = await axios.get(urls.credentials.listCertificates(), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listMasterclassCertificates: async (): Promise<
      APIResponse<MasterclassCertificate[]>
    > => {
      try {
        const res = await axios.get(
          urls.credentials.listMasterclassCertificates()
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    getItemCertificate: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<CourseCompletionCertificate>
    > => {
      try {
        const res = await axios.get(
          urls.credentials.getItemCertificate(urlParams.courseId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    download: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Blob>> => {
      try {
        const res = await axios.get(
          urls.credentials.download(urlParams.itemType, urlParams.itemSlug),
          {
            responseType: "blob",
          }
        )
        return formatSuccessResponse(res, { keepOriginalResponse: true })
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    checkExistence: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        serialNumber: string
      }>
    > => {
      try {
        const res = await axios.get(
          urls.credentials.checkExistence(
            urlParams.username,
            urlParams.itemType,
            urlParams.itemSlug
          )
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
