/* eslint-disable react/no-unescaped-entities */
import { Button, Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import { useOlympiadPrices } from "features/ITO/utils/hooks/useOlympiadPrices"
import { ArrowRight } from "iconoir-react"
import { createUseStyles } from "react-jss"

import GridSquare from "./assets/grid-square.svg"
import ICM from "./assets/ICM.png"
import firstImage from "./assets/image280.png"
import ITU from "./assets/ITU.png"
import secondImage from "./assets/Prize.png"
import fourthImage from "./assets/Top100.png"
import thirdImage from "./assets/Top100-2.png"
import YSAA from "./assets/YSAA.png"
import WhatYouGetWhatYouPay from "./WhatYouGetWhatYouPay"
const meritAchievers = [
  {
    title: "Your Skill Assessment and Analysis",
    imageSrc: YSAA,
    linearGradient:
      "linear-gradient(to right top, #eee0e5, #efe1e5, #f0e2e4, #f1e3e4, #f2e4e4, #f3e6e4, #f3e7e5, #f4e9e6, #f5ece8, #f7eeea, #f8f1ec, #f9f3ee)",
  },
  {
    title: "International Certificates of Merit",
    imageSrc: ICM,
    linearGradient:
      "linear-gradient(to right top, #edf0f9, #f0f1f8, #f2f2f8, #f4f3f7, #f5f4f7, #f6f4f7, #f7f4f6, #f8f4f6, #faf3f4, #fbf2f1, #fbf2ee, #faf2eb)",
  },
]

const data = [
  {
    title: "International Certificate of Excellence",
    imageSrc: firstImage,
  },
  {
    title: "Cash Prize",
    imageSrc: secondImage,
  },
  {
    title: "Recognition at State & National Level",
    imageSrc: thirdImage,
  },
  {
    title: "Earned Merit Badge",
    imageSrc: fourthImage,
  },
]

const useStyles = createUseStyles({
  containerBg: {
    backgroundImage: `url(${GridSquare}), radial-gradient(at 20% -28%, rgba(249, 205, 94, 1) 0,#050124 48%)`,
  },
})

const AwardsAndPrizes = () => {
  const classes = useStyles()
  const { getPrice } = useOlympiadPrices({ edition: "2023" })

  const TIA = [
    { title: "1st Position", prize: getPrice("cashPrizeFirst") },
    { title: "2nd Position", prize: getPrice("cashPrizeSecond") },
    { title: "3rd Position", prize: getPrice("cashPrizeThird") },
    { title: "Top 10", prize: getPrice("cashPrizeSilver") },
  ]

  const top100Prize = getPrice("cashPrizeBronze")

  return (
    <div>
      <div
        className={clsx(
          classes.containerBg,
          "bg-[#050124] backdrop-blur-lg backdrop-filter !text-white min-h-[828px] py-4 relative"
        )}
      >
        <Container>
          <SwitzerTypography
            style={{
              fontFamily: "Switzer Bold",
              fontSize: 40,
              fontWeight: 700,
              lineHeight: 1.2,
              letterSpacing: "-0.8px",
            }}
          >
            Top 100
          </SwitzerTypography>
          <SwitzerTypography
            className="mt-2 mb-4"
            style={{
              maxWidth: 541,
              fontSize: 18,
              fontWeight: 400,
              lineHeight: 1.3,
            }}
          >
            Triumph in style with cash rewards, certificates of excellence,
            badge of honour & media recognition.
          </SwitzerTypography>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {data.map(item => (
              <div
                key={item.title}
                className="border border-[#5E5A64] bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 rounded-2xl p-2 md:p-3 pb-0 md:pb-0 flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0 min-h-[265px]"
              >
                <SwitzerTypography
                  style={{
                    wordBreak: "inherit",
                    fontSize: 24,
                    fontWeight: 500,
                    letterSpacing: "-0.48px",
                    lineHeight: 1.2,
                  }}
                >
                  {item.title === "Cash Prize"
                    ? `${top100Prize} ${item.title}`
                    : item.title}
                </SwitzerTypography>
                {item.title === "Cash Prize" ? (
                  <div className="relative md:ml-5">
                    <Typography
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap"
                      style={{
                        color: "#EFAE4A",
                        fontSize: 27.498,
                        fontWeight: 700,
                        letterSpacing: "-0.55px",
                      }}
                    >
                      {top100Prize}
                    </Typography>
                    <img src={item.imageSrc} alt={item.title} />
                  </div>
                ) : (
                  <img
                    src={item.imageSrc}
                    alt={item.title}
                    className="md:ml-5"
                  />
                )}
              </div>
            ))}
          </div>
        </Container>
      </div>
      <div
        style={{
          background: "#F7F7F7",
        }}
      >
        <Container className="pt-9">
          <SwitzerTypography
            style={{
              fontSize: 40,
              fontWeight: 700,
              lineHeight: 1.2,
              letterSpacing: "-0.8px",
            }}
          >
            Merit Achievers
          </SwitzerTypography>
          <SwitzerTypography
            color="onSurface.800"
            className="mb-5"
            style={{
              fontSize: 18,
              fontWeight: 400,
              lineHeight: 1.3,
            }}
          >
            Celebrate your success with your badge of achievement and
            certificate of merit with pride.
          </SwitzerTypography>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pb-8 md:pb-12">
            {meritAchievers.map(item => (
              <div
                key={item.title}
                className="rounded-2xl pl-3 pt-3"
                style={{
                  background: item.linearGradient,
                }}
              >
                <SwitzerTypography
                  className="mb-2"
                  style={{
                    fontSize: 24,
                    fontWeight: 500,
                    letterSpacing: "-0.48px",
                    lineHeight: 1,
                    maxWidth: "280px",
                  }}
                >
                  {item.title}
                </SwitzerTypography>
                <img
                  src={item.imageSrc}
                  className={clsx("ml-auto mt-auto rounded-br-2xl", {
                    "md:relative md:-bottom-[19.5px]":
                      item.title === "Your Skill Assessment and Analysis",
                  })}
                  alt={item.imageSrc}
                />
              </div>
            ))}
          </div>
        </Container>
      </div>
      <div
        className="py-3 overflow-hidden"
        style={{
          background: "#FFD2E0",
        }}
      >
        <Container className="[&>div]:relative">
          <img
            src={ITU}
            alt="ITU"
            className="absolute -right-1/4 sm:right-0 opacity-25 sm:opacity-50 z-1"
          />
          <SwitzerTypography
            className="z-2 relative"
            style={{
              fontFamily: "Switzer Bold",
              color: "#820027",
              fontSize: 32,
              letterSpacing: "-1.28px",
              lineHeight: 1.2,
            }}
          >
            Top 800
          </SwitzerTypography>
          <SwitzerTypography
            color="onSurface.800"
            className="mb-3 mt-1 z-2 relative sm:w-[calc(100%-292px)]"
            style={{
              color: "#050124",
              fontSize: 18,
              fontWeight: 400,
              lineHeight: 1.3,
            }}
          >
            Get a {getPrice("ituScholarship")} scholarship grant for admission
            into the online Master's in Education degree by International
            Teachers' University (Approved by US Govt).
          </SwitzerTypography>
          <Button
            variant="text"
            component="a"
            href="https://internationalteachers.university/"
            target="_blank"
            className="flex gap-0.5 p-0.5 items-center z-2 relative"
            style={{
              color: "#820027",
            }}
            nudge="left"
            endAdornment={<ArrowRight height={16} width={16} />}
            rel="noreferrer"
          >
            <Typography variant="button">Learn more about ITU</Typography>
          </Button>
        </Container>
      </div>
      <div className="h-[50px] md:h-[100px] w-full"></div>
      <WhatYouGetWhatYouPay getPrice={getPrice} />
      <div
        style={{
          background: "#F7F7F7",
        }}
      >
        <Container className="py-8">
          {/* Update this to use `Space Grotesk` */}
          <Typography
            variant="title2"
            style={{
              fontSize: 40,
              fontWeight: 700,
              letterSpacing: "-1.6px",
            }}
          >
            Teacher Impact Awards
          </Typography>
          <Typography
            className="mt-0.5 mb-4.5"
            style={{
              color: "#050124",
              fontFamily: "Inter",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: 1.3,
              maxWidth: "528px",
            }}
          >
            Global initiative by Suraasa to give teachers their well-deserved
            credit & recognition by sharing their success stories with the
            world.
          </Typography>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-6">
            {TIA.map(item => {
              return (
                <div key={item.title}>
                  <Typography variant="preTitle" className="mb-0.5">
                    {item.title}
                  </Typography>
                  <Typography
                    className="flex-shrink-0"
                    style={{
                      color: "#050124",
                      fontSize: 24,
                      fontWeight: 700,
                      lineHeight: 1.3,
                      letterSpacing: "-0.48px",
                    }}
                  >
                    {item.prize}
                  </Typography>
                </div>
              )
            })}
          </div>
          {/* <Button rounded endAdornment={<ArrowRight />} className="mt-4.5">
            Learn More About TIA
          </Button> */}
        </Container>
      </div>
      <div style={{ background: "#050124" }}>
        <Container className="py-1.25">
          <img
            src={fourthImage}
            alt="top 100"
            className="inline h-3 w-3 md:w-4 md:h-4"
          />
          &nbsp;<span className="hidden md:inline">&nbsp;</span>
          <Typography
            display="inline"
            style={{
              color: "#FFF",
              fontFamily: "Inter",
              fontSize: 18,
              fontWeight: 400,
              lineHeight: 1.3,
              letterSpacing: "-0.36px",
            }}
          >
            Top 100 winners of ITO will be nominated for Teacher Impact Awards
          </Typography>
        </Container>
      </div>
    </div>
  )
}

export default AwardsAndPrizes
