import { Typography } from "@suraasa/placebo-ui"
import { ImportantDates } from "api/resources/ito/registrations/types"
import clsx from "clsx"
import { isAfter, isToday } from "date-fns"
import React from "react"
import { createUseStyles } from "react-jss"
import { formatDate } from "utils/helpers"

const useStyles = createUseStyles({
  cardBgInProgress: {
    background: "linear-gradient(135deg, #8F96FF 0%, #C580FF 100%)",
  },
  cardBgCompleted: {
    background: "linear-gradient(135deg, #373A68 0%, #52346A 100%)",
  },
  verticalLineCompleted: {
    background: "linear-gradient(270deg, #44386A 0%, #6067B8 100%)",
  },
  verticalLineInProgress: {
    background: "linear-gradient(270deg, #A98CFF 0%, #946ECF 100%)",
  },
})

type Props = {
  data: ImportantDates[]
}

const Timeline = ({ data }: Props) => {
  const classes = useStyles()

  const progressLine = (
    event: ImportantDates
  ): "not-started" | "in-progress" | "completed" => {
    const eventDate = new Date(event.date)
    const now = new Date()

    if (isToday(eventDate)) {
      return "in-progress"
    }

    if (isAfter(now, eventDate)) {
      return "completed"
    }

    return "not-started"
  }

  if (data.length === 0) return null
  return (
    <div className="flex overflow-auto">
      {data.map((item, index) => {
        const nextStatus =
          index < data.length - 1 ? progressLine(data[index + 1]) : null

        const progressStatus =
          nextStatus === "in-progress" ? "completed" : progressLine(item)

        return (
          <div key={index} className="w-full flex flex-col items-center">
            <div
              className={clsx("relative h-1 bg-ito-lavender-100 w-full", {
                "rounded-l-full": index === 0,
                "rounded-r-full": index === data.length - 1,
              })}
            >
              <div
                className={clsx("h-full", {
                  "rounded-r-full w-[50%] bg-ito-lavender-500":
                    progressStatus === "in-progress",
                  "w-full bg-ito-lavender-500": progressStatus === "completed",
                  "rounded-l-full": index === 0,
                  "rounded-r-full":
                    progressStatus === "completed" && index === data.length - 1,
                })}
              />
              <div
                className={clsx(
                  "h-1 w-1 rounded-full absolute top-0 left-[calc(50%_-_4px)]",
                  {
                    "bg-[#9061C5]": progressStatus !== "not-started",
                    "bg-white": progressStatus === "not-started",
                  }
                )}
              />
            </div>
            <div
              className={clsx("h-3 w-0.5", {
                "bg-transparent": progressStatus === "not-started",
                [classes.verticalLineCompleted]: progressStatus === "completed",
                [classes.verticalLineInProgress]:
                  progressStatus === "in-progress",
              })}
            />
            <div
              className={clsx(
                "px-3 py-2 min-w-[180px] w-fit rounded-full flex flex-col",
                {
                  [classes.cardBgInProgress]: progressStatus === "in-progress",
                  [classes.cardBgCompleted]: progressStatus === "completed",
                  "!text-white": progressStatus !== "not-started",
                  "bg-surface-100 !text-onSurface-500":
                    progressStatus === "not-started",
                  "sm:mr-0 mr-4": index === 0,
                  // "ml-4": index === data.length - 1,
                  "mx-4": index > 0 && index < data.length - 1,
                }
              )}
            >
              <Typography className="w-max" variant="smallBody">
                {formatDate(item.date, "do LLL. yy")}
              </Typography>
              <Typography className="w-max" variant="strong">
                {item.displayText}
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Timeline
