import {
  Button,
  Tag,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/learningItems/queries"
import {
  LearningItemType,
  ScheduleType,
} from "api/resources/learningItems/types"
import FullWidthButtonCard from "components/FullWidthButtonCard"
import { format } from "date-fns"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { Calendar, Clock } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { formatDate } from "utils/helpers"

const Timeline = ({ startTime }: { startTime: string }) => (
  <div className="flex">
    <div className="flex items-center me-2">
      <Calendar className="me-1" />
      <Typography variant="strongSmallBody">
        {formatDate(new Date(startTime).toISOString(), "LLL dd, Y")}
      </Typography>
    </div>
    <div className="flex items-center">
      <Clock className="me-1" />
      <Typography variant="strongSmallBody">
        {formatDate(new Date(startTime).toISOString(), "p")}
      </Typography>
    </div>
  </div>
)

const NextUp = ({
  learningItemType,
  learningItemId,
}: {
  learningItemType: LearningItemType
  learningItemId: number
}) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const { data, isSuccess } = useQuery({
    queryFn: () =>
      api.learningItems.getUpcomingPlannerItems({
        urlParams: { learningItemType, learningItemId },
      }),
    queryKey: queries.upcomingPlannerItem(learningItemType, learningItemId)
      .queryKey,
  })

  if (!isSuccess) return null

  if (data.length === 0) return null

  const upcomingAssessment = data.find(
    upNext => upNext.scheduleType === ScheduleType.ASSESSMENT
  )

  const upcomingClass = data.find(
    upNext => upNext.scheduleType === ScheduleType.CLASS
  )

  return (
    <div className="mb-3">
      <Typography variant="title3" className="mb-2">
        Next Up
      </Typography>
      {upcomingClass && upcomingClass.scheduleType === ScheduleType.CLASS && (
        <FullWidthButtonCard
          className="mb-3"
          buttonProps={{
            label: upcomingClass.videoMeeting?.joiningUrl
              ? "Join"
              : `Scheduled for ${format(
                  new Date(upcomingClass.startTime),
                  "d MMMM yyyy, p"
                )}`,
            component: "a",
            target: "_blank",
            disabled: !upcomingClass.videoMeeting?.joiningUrl,
            href: upcomingClass.videoMeeting?.joiningUrl,
          }}
        >
          <div>
            <div className="flex mb-0.5">
              <Typography
                variant="title4"
                color="onSurface.800"
                className="me-1"
              >
                Class {upcomingClass.classId.name}
              </Typography>
            </div>
            <div className="mt-1 me-1">
              <Typography
                variant="smallBody"
                color="onSurface.500"
                className="mb-0.5"
              >
                {formatDate(upcomingClass.startTime, "do MMMM Y, p")}
              </Typography>
              <Typography variant="smallBody" color="onSurface.500">
                Conducted by Prof. {upcomingClass.professor.fullName}
              </Typography>
            </div>
            {upcomingClass.lectures.length > 0 && (
              <>
                <Typography className="my-1 me-2" variant="strong">
                  Topics Covered:
                </Typography>
                <div>
                  {upcomingClass.lectures.map((lecture, index) => {
                    const [tag, name] = lecture.name.split(":")
                    return (
                      <div key={index} className="flex items-center mb-0.5">
                        <Tag
                          color="onSurface"
                          className="me-2 flex-shrink-0 uppercase"
                          label={tag}
                        />
                        <p>{name}</p>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </FullWidthButtonCard>
      )}

      {upcomingAssessment &&
        upcomingAssessment.scheduleType === ScheduleType.ASSESSMENT && (
          <div className="sm:p-2 border border-surface-200 bg-surface-500 rounded-2xl flex sm:items-center justify-between sm:flex-row flex-col overflow-hidden">
            <div className="sm:px-0 px-2 sm:pt-0 pt-2 sm:pb-0 pb-2">
              <Typography variant="title4" className="mb-1">
                {upcomingAssessment.assessment.title}
              </Typography>
              <Timeline startTime={upcomingAssessment.startTime} />
            </div>
            <Button
              fullWidth={isXs}
              style={{
                borderRadius: isXs ? 0 : 4,
              }}
              onClick={() =>
                startAssessment({
                  assessmentId: upcomingAssessment.assessment.uuid,
                  onClose: reloadOnAssessmentClose,
                })
              }
            >
              Attempt Assessment
            </Button>
          </div>
        )}
    </div>
  )
}

export default NextUp
