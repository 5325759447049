import React from "react"

const BookOpen = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3178_24907)">
        <path
          d="M10.9232 17.7949L10.8398 17.6699C10.2609 16.8016 9.9715 16.3674 9.58909 16.0531C9.25056 15.7749 8.86048 15.5661 8.44118 15.4388C7.96755 15.2949 7.44577 15.2949 6.4022 15.2949H5.25651C4.32309 15.2949 3.85638 15.2949 3.49986 15.1133C3.18626 14.9535 2.93129 14.6985 2.7715 14.3849C2.58984 14.0284 2.58984 13.5617 2.58984 12.6283V5.46159C2.58984 4.52817 2.58984 4.06146 2.7715 3.70494C2.93129 3.39133 3.18626 3.13637 3.49986 2.97658C3.85638 2.79492 4.32309 2.79492 5.25651 2.79492H5.58984C7.45669 2.79492 8.39011 2.79492 9.10315 3.15823C9.73035 3.47781 10.2403 3.98775 10.5599 4.61495C10.9232 5.32799 10.9232 6.26141 10.9232 8.12826M10.9232 17.7949V8.12826M10.9232 17.7949L11.0066 17.6699C11.5854 16.8016 11.8749 16.3674 12.2573 16.0531C12.5958 15.7749 12.9859 15.5661 13.4052 15.4388C13.8788 15.2949 14.4006 15.2949 15.4442 15.2949H16.5898C17.5233 15.2949 17.99 15.2949 18.3465 15.1133C18.6601 14.9535 18.9151 14.6985 19.0749 14.3849C19.2565 14.0284 19.2565 13.5617 19.2565 12.6283V5.46159C19.2565 4.52817 19.2565 4.06146 19.0749 3.70494C18.9151 3.39133 18.6601 3.13637 18.3465 2.97658C17.99 2.79492 17.5233 2.79492 16.5898 2.79492H16.2565C14.3897 2.79492 13.4562 2.79492 12.7432 3.15823C12.116 3.47781 11.6061 3.98775 11.2865 4.61495C10.9232 5.32799 10.9232 6.26141 10.9232 8.12826"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3178_24907">
          <rect
            width="1.5em"
            height="1.5em"
            fill="currentColor"
            transform="translate(0.923828 0.294922)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BookOpen
