import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getDopplerURL = getServiceURL(SERVICE_PREFIX.doppler)

export const urls = validateUrls({
  notification: {
    list: () => getDopplerURL("/v1/inapp-notifications/"),
    overview: () => getDopplerURL("/v1/inapp-notifications/overview/"),
    markAsRead: () => getDopplerURL("/inapp-notifications/"),
    markAsOpened: id => getDopplerURL(`/v1/inapp-notifications/${id}/`),
  },
})
