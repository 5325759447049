import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { VerifiedParticipationHistory } from "../registrations/types"
import { ITOReport } from "./types"
import { urls } from "./urls"

export default {
  listReports: async ({
    params,
  }: Config): Promise<APIResponse<ITOReport[]>> => {
    try {
      const res = await axios.get(urls.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  verifyParticipationHistory: async ({
    data,
  }: Config): Promise<APIResponse<VerifiedParticipationHistory>> => {
    try {
      const res = await axios.post(urls.verifyParticipationHistory(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateParticipationHistory: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.participationHistory(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
