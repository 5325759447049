import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { UserDetails } from "api/resources/discussions/types"
import { APIError } from "api/utils"
import ReportDialog from "features/Discussions/ReportDialog"
import { MoreVert } from "iconoir-react"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { buildUserName, handleErrors } from "utils/helpers"

type Props = {
  discussionId: string
  user: UserDetails
}

const DiscussionMenu = ({ discussionId, user }: Props) => {
  const { id: isPostDetailsPage } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [openReportDialog, setOpenReportDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openBlockDialog, setOpenBlockDialog] = useState(false)

  const { mutate, isLoading } = useMutation({
    mutationFn: ({
      type,
      data,
    }: {
      type: "delete" | "report" | "block"
      data?: { reason: string; report_type: number }
    }) => {
      if (type === "delete") {
        return api.discussions.deleteDiscussion({
          params: { id: discussionId },
        })
      }
      if (type === "report") {
        return api.discussions.reportDiscussion({
          params: { id: discussionId },
          ...(data && { data }),
        })
      }
      return api.discussions.block({
        data: { blocked_user: user.uuid },
      })
    },
    onSuccess: () => {
      if (openReportDialog) {
        setOpenReportDialog(false)
      }

      if (openDeleteDialog) {
        setOpenDeleteDialog(false)

        // Invalidate discussions list cache so it can re-fetch excluding the deleted discussion.
        queryClient.invalidateQueries({
          queryKey: queries.discussions.list().queryKey,
        })

        if (isPostDetailsPage) {
          navigate("/discussions")
        }
      }

      if (openBlockDialog) {
        setOpenBlockDialog(false)

        // Invalidate discussions list cache so it can re-fetch excluding the content of the blocked user.
        queryClient.invalidateQueries({
          queryKey: queries.discussions.list().queryKey,
        })

        if (isPostDetailsPage) {
          navigate("/discussions")
        } else {
          window.location.reload()
        }
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const handleSubmitDelete = () => {
    mutate({ type: "delete" })
  }
  const handleSubmitBlock = () => {
    mutate({ type: "block" })
  }

  return (
    <>
      <div>
        {user.isSelf ? (
          <Menu
            menuButton={
              <IconButton>
                <MoreVert />
              </IconButton>
            }
          >
            <MenuItem
              color="critical"
              onClick={() => setOpenDeleteDialog(true)}
            >
              Delete
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            menuButton={
              <IconButton>
                <MoreVert />
              </IconButton>
            }
          >
            <MenuItem
              color="critical"
              onClick={() => setOpenReportDialog(true)}
            >
              Report
            </MenuItem>
            <MenuItem color="critical" onClick={() => setOpenBlockDialog(true)}>
              Block {user.firstName}
            </MenuItem>
          </Menu>
        )}
      </div>

      <ReportDialog
        id={discussionId}
        open={openReportDialog}
        type="discussion"
        handleClose={() => setOpenReportDialog(false)}
        firstName={user.firstName}
      />
      <Dialog
        open={openDeleteDialog}
        onRequestClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Discussion</DialogTitle>

        <DialogContent>
          <Typography>
            Are you sure you want to delete this discussion?
          </Typography>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Delete Discussion",
              color: "critical",
              type: "submit",
              loading: isLoading,
              onClick: handleSubmitDelete,
            },
          }}
        />
      </Dialog>
      <Dialog
        open={openBlockDialog}
        onRequestClose={() => setOpenBlockDialog(false)}
      >
        <DialogTitle>Block {user.firstName}</DialogTitle>

        <DialogContent>
          <Typography>
            Are you sure you want to block {buildUserName(user)}? You will not
            be able to see any discussions, comments & replies posted by them.
          </Typography>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Block",
              color: "critical",
              type: "submit",
              loading: isLoading,
              onClick: handleSubmitBlock,
            },
          }}
        />
      </Dialog>
    </>
  )
}

export default DiscussionMenu
