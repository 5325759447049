import SuraasaLogo from "assets/SuraasaLogo.svg"
import clsx from "clsx"
import React from "react"

import styles from "./Navbar.module.css"

export type NavbarProps = {
  className?: string
  gutterBottom?: boolean
  slotEnd?: React.ReactNode
  navBarTitle?: string
}

const Navbar = ({ gutterBottom = true, className, slotEnd }: NavbarProps) => {
  return (
    <div
      className={clsx("w-full", {
        ["mb-4 md:mb-8"]: gutterBottom,
      })}
    >
      <nav
        className={clsx(
          "bg-onSurface-50 border-b-onSurface-200 border-b h-[62px] relative",
          className,
          styles.root,
          "py-1 flex items-center"
        )}
      >
        <div
          className={clsx(
            "flex items-center w-full justify-between py-2 sm:px-5 px-2 md:flex-nowrap flex-wrap",
            styles.container
          )}
        >
          <div className="flex flex-grow items-center justify-start md:flex-grow-0 md:block">
            <img alt="" src={SuraasaLogo} />
          </div>
          {slotEnd}
        </div>
      </nav>
    </div>
  )
}

export default Navbar
