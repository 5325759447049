import * as Sentry from "@sentry/react"
import { ErrorBoundary } from "@sentry/react"
import { useClick, useMenuState } from "@szhsin/react-menu"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Enrollment } from "api/resources/learningItems/types"
import { GenderMapping } from "api/resources/profile/types"
import { Email } from "api/resources/settings/types"
import ErrorPage from "components/ErrorPage"
import { useITOAccess } from "features/ITO/useITOAccess"
import { useSetPlatformLanguage } from "global/useSetPlatformLanguage"
import { useEffect, useState } from "react"
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom"
import { PGCTL_SLUG } from "utils/constants"
import { clearAuthInfo, getAuthInfo, saveAuthInfo } from "utils/helpers"
import { trackingService } from "utils/tracking"
import { useNetcore } from "utils/tracking/useNetcore"

import { context, defaultContextValue } from "./context"

const GlobalContext = () => {
  const notificationsPopupMenuState = useMenuState({
    transition: true,
  })
  const [authInfo, setAuthInfo] = useState(getAuthInfo())
  const [reMountingApp, setRemountingApp] = useState(false)

  const location = useLocation()

  const isAuthenticated = !!authInfo
  const itoParticipationDetails = useITOAccess({ isAuthenticated })

  useSetPlatformLanguage({ isAuthenticated })

  const [emails, setEmails] = useState<Email[]>(defaultContextValue.emails)
  const [enrollments, setEnrollments] = useState<Enrollment[] | null>(
    defaultContextValue.enrollments
  )
  const [showCourseFeedbackCard, setShowCourseFeedbackCard] = useState(
    defaultContextValue.showCourseFeedbackCard
  )

  const profileAPI = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.profile.profileData().queryKey,
    queryFn: () =>
      api.profile.retrieve({
        urlParams: {},
      }),
    initialData: defaultContextValue.profile,
  })

  const emailsAPI = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.settings.emailList().queryKey,
    queryFn: () => api.settings.emails.list(),
    onSuccess: data => setEmails(data),
  })

  const enrollmentsAPI = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.learningItems.enrollmentList().queryKey,
    queryFn: () => api.learningItems.listEnrollments(),
    onSuccess: data => setEnrollments(data),
  })

  useEffect(() => {
    if (reMountingApp) {
      profileAPI.refetch()
      emailsAPI.refetch()
      enrollmentsAPI.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reMountingApp])

  useEffect(() => {
    const profile = profileAPI.data
    if (profile.id) {
      if (profile.phoneNumber) {
        trackingService.setUserAttribute(
          "mobile",
          `+${profile.phoneNumber.code}${profile.phoneNumber.number}`
        )
      }
      if (profile.country) {
        trackingService.setUserAttribute("COUNTRY", profile.country.name)
      }
      if (profile.state) {
        trackingService.setUserAttribute("STATE", profile.state.name)
      }
      if (profile.gender) {
        trackingService.setUserAttribute(
          "GENDER",
          GenderMapping[profile.gender]
        )
      }
      trackingService.setUserAttribute(
        "looking_for_jobs",
        profile.lookingForJobs
      )
      trackingService.setUserAttribute("is_verified", profile.isVerified)
    }
  }, [profileAPI.data])

  useEffect(() => {
    if (itoParticipationDetails.registrationStatus === "completed") {
      trackingService.setUserAttribute("ito_2023_registrant", true)
    }
  }, [itoParticipationDetails.registrationStatus])

  useEffect(() => {
    if (enrollments) {
      const isPGCTLUser =
        enrollments.length > 0
          ? enrollments.some(item => item.slug === PGCTL_SLUG)
          : false

      if (isPGCTLUser)
        trackingService.setUserAttribute("is_pgctl_user", isPGCTLUser)
    }
  }, [enrollments])

  useEffect(() => {
    if (authInfo) {
      trackingService.initUser(authInfo)
    }
    if (authInfo)
      Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
  }, [authInfo])

  const { status } = useNetcore()

  useEffect(() => {
    if (status === "ready")
      trackingService.trackEvent(
        "APP_SCREEN_LOAD",
        {
          url: window.location.href,
          is_user_logged_in: isAuthenticated,
        },
        ["NetCore"]
      )
    trackingService.trackEvent("PAGE_VIEW", { url: window.location.href }, [
      "WebEngage",
    ])
  }, [location, isAuthenticated, status])

  const remountApp = () => {
    if (reMountingApp) return

    setRemountingApp(true)

    setTimeout(() => {
      setRemountingApp(false)
    }, 150)
  }

  return (
    <ErrorBoundary fallback={e => <ErrorPage data={e} />} showDialog>
      <context.Provider
        value={{
          notificationsPopupMenuState,
          itoParticipationDetails,
          authInfo,
          setAuthInfo: newAuth => {
            if (
              authInfo &&
              newAuth &&
              authInfo.user.uuid !== newAuth.user.uuid
            ) {
              remountApp()
            }

            saveAuthInfo(newAuth)
            setAuthInfo(newAuth)
          },
          logout: () => {
            clearAuthInfo()
            setAuthInfo(null)
          },
          showCourseFeedbackCard,
          isAuthenticated,
          emails,
          enrollments,
          updateEmails: emails => setEmails(emails),
          setShowCourseFeedbackCard,
          profile: profileAPI.data,
          fetchProfile: profileAPI.refetch,
        }}
      >
        <ScrollRestoration />
        {!reMountingApp && <Outlet />}
      </context.Provider>
    </ErrorBoundary>
  )
}

export default GlobalContext
