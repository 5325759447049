import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
  root: {
    overflow: "hidden",
  },

  "@keyframes marquee": {
    from: {
      transform: "translate3d(0% , 0, 0)",
    },

    to: {
      transform: "translate3d(-50%, 0, 0)",
    },
  },

  sliderContainer: {
    position: "relative",
    height: "16px",
  },

  slider: {
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",
    "& .slider-inner": {
      width: "max-content",
      display: "flex",
      position: "relative",
      animation: "$marquee 80s linear infinite",
    },
    "& .slider-outer": {
      width: "max-content",
      display: "flex",
      position: "relative",
      animation: "$marquee  80s linear infinite",
      animationDirection: "reverse",
    },
  },

  countryBanner: {
    maxHeight: 16,
    maxWidth: "100%",
  },
  countryName: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12.8474px",
    lineHeight: "16px",
    letterSpacing: "-0.04em",
    // color: customTheme.midnight,
  },
}))

export type SlidingImagesType = { name: string; img: string }[]
const SlidingBanner = ({
  className: userClassName,
  images,
  reverseAnimation,
}: {
  className?: string
  images: SlidingImagesType
  reverseAnimation?: boolean
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, userClassName)}>
      <div className={classes.sliderContainer}>
        <div className={clsx(classes.slider)}>
          <div className={reverseAnimation ? "slider-outer" : "slider-inner"}>
            {images.map(({ name, img }, i) => (
              <div key={i} className="mx-1 flex items-center gap-0.5">
                <img alt={name} className={classes.countryBanner} src={img} />
                <span className={classes.countryName}>{name}</span>
              </div>
            ))}
            {images.map(({ name, img }, i) => (
              <div key={i} className="mx-1 flex items-center gap-0.5">
                <img alt={name} className={classes.countryBanner} src={img} />
                <span className={classes.countryName}>{name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlidingBanner
