import clsx from "clsx"
import React, { PropsWithChildren } from "react"

type Props = {
  isActive: boolean
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const TabItem = ({
  children,
  isActive,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <button
      className={clsx(
        "text-onSurface-500 mr-4 pt-0 pr-0.25 pb-1 pl-0.25 shrink-0 whitespace-nowrap",
        {
          "text-secondary-900 border-b-2 border-secondary-900": isActive,
        }
      )}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

export default TabItem
