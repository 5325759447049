import { Typography } from "@suraasa/placebo-ui"
import { MainColors } from "@suraasa/placebo-ui/dist/cjs/utils/createColors"
import clsx from "clsx"
import React from "react"

type TagWithAdornmentProps = {
  color?: MainColors
  label: string
  className?: string
  endAdornment?: React.ReactElement<any>
}

const TagWithAdornment = ({
  color = "primary",
  label,
  className,
  endAdornment,
}: TagWithAdornmentProps) => {
  return (
    <Typography
      variant="subtitle2"
      className={clsx(
        "rounded-lg text-center !flex items-center justify-center h-[36px] gap-1.5",
        className,
        {
          "!text-critical-600 bg-critical-50 px-2": color === "critical",
        },
        {
          "!text-primary-600 bg-primary-50 px-2": color === "primary",
        },
        {
          "!text-success-600 bg-success-50 px-2": color === "success",
        },
        {
          "!text-warning-600 bg-warning-50 px-2": color === "warning",
        },
        {
          "!text-secondary-600 bg-secondary-50 px-2": color === "secondary",
        }
      )}
    >
      <span>{label}</span>
      {endAdornment}
    </Typography>
  )
}

export default TagWithAdornment
