import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("masterclasses", {
  masterclassesList: () => ({
    queryKey: ["masterclassesList"],
  }),
  upcomingMasterclass: () => ({
    queryKey: ["upcomingMasterclass"],
  }),
})
