import { Typography } from "@suraasa/placebo-ui"
import { Clock } from "iconoir-react"
import React from "react"

const IconBox = ({
  Icon = Clock,
  heading,
  subheading,
}: {
  Icon: typeof Clock
  heading: string
  subheading: string | number
}) => {
  return (
    <div className="flex gap-2 items-center">
      <span className="bg-white border rounded-lg border-onSurface-200 p-1.25">
        <Icon />
      </span>
      <div>
        <Typography variant="strongSmallBody" display="block">
          {heading}
        </Typography>
        <Typography variant="smallBody" display="block">
          {subheading}
        </Typography>
      </div>
    </div>
  )
}

export default IconBox
