import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { LearningItemDetails } from "api/resources/learningItems/types"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import { PageWithMeta } from "components/Metadata"
import React, { PropsWithChildren } from "react"
import { Outlet, useOutletContext, useParams } from "react-router-dom"

import LearningItemHeader from "../Header"
import LearningItemNav from "../Nav"

type ContextType = {
  qualification: LearningItemDetails
}

const MetaWrapper = ({ children }: PropsWithChildren) => {
  const { learningItemType } = useParams() as {
    learningItemType: string
  }

  if (
    learningItemType === "qualification" ||
    learningItemType === "certification"
  )
    return <PageWithMeta name={learningItemType}>{children}</PageWithMeta>

  return <>{children}</>
}

const Qualification = () => {
  const { slug, learningItemType } = useParams() as {
    slug: string
    learningItemType: string
  }
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const { data, isLoading, isError } = useQuery({
    queryFn: () =>
      api.learningItems.getLearningItemDetails({
        urlParams: {
          itemType: learningItemType,
          itemSlug: slug,
        },
      }),
    queryKey: queries.learningItems.details(learningItemType, slug).queryKey,
  })

  if (isLoading)
    return (
      <MetaWrapper>
        <FullPageLoading />
      </MetaWrapper>
    )

  if (isError)
    return (
      <MetaWrapper>
        <Fallback
          data={{
            title: "Something went wrong",
            description: "Please contact care@suraasa.com",
            image: Monitor,
          }}
          className="pt-4"
        />
      </MetaWrapper>
    )

  const tabs = [
    { name: "Overview", link: "overview" },
    { name: "Assessments", link: "assessments" },
    ...(learningItemType === "qualification"
      ? [{ name: "Classes", link: "classes" }]
      : []),
    { name: "Certificates", link: "certificates" },
    { name: "Resources", link: "resources" },
  ]

  return (
    <MetaWrapper>
      {/* Container is handled inside */}
      {data.type !== "course" && (
        <LearningItemHeader
          image={data.bannerImage}
          itemSlug={data.slug}
          itemName={data.name}
          data={tabs}
          className="mb-3"
        />
      )}

      <Container>
        <div className="grid grid-cols-12">
          {mdUp && (
            <div className="flex flex-col col-span-3 me-3">
              <LearningItemNav data={tabs} />
            </div>
          )}
          <div className="col-span-12 md:col-span-9">
            <Outlet
              context={{
                qualification: data,
              }}
            />
          </div>
        </div>
      </Container>
    </MetaWrapper>
  )
}

export default Qualification

export function useQualificationDetails() {
  return useOutletContext<ContextType>()
}
