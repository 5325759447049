import { ITORegistrationDetails } from "api/resources/ito/registrations/types"

import { Edition2023PriceCategories, edition2023Prices } from "./2023/constants"

export type OlympiadEditions = "2023"

const currencyCode = {
  IN: {
    code: "INR",
    symbol: "₹",
  },
  AE: {
    code: "AED",
    symbol: "AED",
  },
  US: {
    code: "USD",
    symbol: "$",
  },
} as const

export type PriceCategories<T> = T extends "2023"
  ? Edition2023PriceCategories
  : never

export const getITOPrices = <T extends OlympiadEditions>(
  edition: T,
  priceKey: PriceCategories<T>,
  countryCode?: string | null
) => {
  let price
  switch (edition) {
    // case "2022":
    //   price = edition2022Prices[priceKey as PriceCategories<"2022">]
    //   break
    case "2023":
      price = edition2023Prices[priceKey as PriceCategories<"2023">]
      break
    default:
      throw new Error("ITO Edition not handled")
  }

  const defaultPrice = `${currencyCode.US.symbol}${price.US}`

  if (typeof window === "undefined") return defaultPrice

  if (!countryCode) {
    return defaultPrice
  }

  if (countryCode === "IN") {
    return `${currencyCode[countryCode].symbol}${price[countryCode]}`
  }

  if (countryCode === "AE") {
    return `${price[countryCode]} ${currencyCode[countryCode].symbol}`
  }

  return defaultPrice
}

export const getRegistrationStatus = (
  itoDetails: ITORegistrationDetails | null | undefined
) => {
  if (!itoDetails) return "notStarted"

  const {
    registrationFeePaid,
    registeredUserDetail: details,
    olympiadSchool,
    state,
    city,
  } = itoDetails

  if (
    !registrationFeePaid ||
    !details ||
    !details.educationLevel ||
    !details.currentlyTeaching ||
    !details.dateOfBirth ||
    !city ||
    !state
  )
    return "pending"

  if (details.currentlyTeaching === "yes") {
    if (
      !details.teachingExperience ||
      !details.teachingLevel ||
      !olympiadSchool
    )
      return "pending"
  }

  return "completed"
}

export const getCurrentTimezoneName = (() => {
  // Get a specific point in time (here, the current date/time):
  const d = new Date()

  // Get a DateTimeFormat object for the user's current culture (via undefined)
  // Ask specifically for the long-form of the time zone name in the options
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: "long" })

  // Format the date to parts, and pull out the value of the time zone name
  const timeZoneName = dtf
    .formatToParts(d)
    ?.find(part => part.type == "timeZoneName")

  const fallBack = new Date()
    .toString()
    .match(/\((.*)\)/)
    ?.pop()

  const result = timeZoneName
    ? {
        long: timeZoneName.value,
        abbr: timeZoneName.value
          .split(" ")
          .map(i => i[0])
          .join(""),
      }
    : {
        long: fallBack,
        abbr: fallBack
          ?.split(" ")
          .map(i => i[0])
          .join(""),
      }
  return result
})()
