import { Elements } from "@stripe/react-stripe-js"
import type { StripeElementsOptions } from "@stripe/stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { CheckoutOrder } from "api/resources/orders/types"
import { paymentMethod } from "api/resources/payment/types"
import { APIError } from "api/utils"
import CourseFallback from "assets/Placeholder/course_placeholder.svg"
import Card from "components/Card"
import LoadingOverlay from "components/LoadingOverlay"
import TalkToMentorBanner from "components/TalkToMentorBanner"
import TruncatedText from "components/TruncatedText"
import ImageBackgroundCard from "features/LearningItems/ImageBackgroundCard"
import { context } from "global/Context/context"
import { NavArrowRight } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import routes from "routes"
import { getPlatformURL, handleErrors } from "utils/helpers"
import useFormatPrice from "utils/hooks/useFormatPrice"

import BillingAddress from "./BillingAddress"
import { openRazorpayCheckoutForm } from "./helpers"
import useScript from "./hooks/useScript"
import StripeCheckoutForm from "./StripeCheckoutForm"

const Payment = () => {
  const { authInfo } = useContext(context)

  const [selectAddress, setSelectAddress] = useState(false)
  const [clientSecret, setClientSecret] = useState("")
  const [paymentMethodData, setPaymentMethodData] =
    useState<CheckoutOrder | null>(null)

  const [canChangeAddress, setCanChangeAddress] = useState(true)
  const [intermediateLoading, setIntermediateLoading] = useState(false)

  const [stripeFormOpen, setStripeFormOpen] = useState(false)
  const [juspayPaymentURL, setJuspayPaymentURL] = useState<string | null>(null)

  const options: StripeElementsOptions = {
    clientSecret,
  }

  const [stripePromise, setStripePromise] = useState<any>()

  useEffect(() => {
    setStripePromise(loadStripe(import.meta.env.VITE_STRIPE_PAYMENT_KEY))
  }, [])

  const formatPrice = useFormatPrice()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ promocode: string }>()

  const onSubmit = handleSubmit(async promocode => {
    applyPromocode(promocode.promocode)
  })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {
    data: cart,
    isLoading,
    isError,
    refetch: fetchCart,
  } = useQuery({
    enabled: false,
    queryFn: () => api.payment.getCart(),
    queryKey: queries.payment.cart().queryKey,
  })

  const rzpScriptStatus = useScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  )

  useEffect(() => {
    const processPromo = async () => {
      const productId = searchParams.get("product_id")
      const promo = searchParams.get("promo")

      if (!productId) return
      setIntermediateLoading(true)

      try {
        await api.payment.addItemToCart({
          data: {
            productId: productId,
          },
        })
      } catch (e) {
        if (e instanceof APIError) {
          const msg = e.message || ""
          if (msg.toLowerCase().includes("already enrolled in this item")) {
            toast.error("You are already enrolled in this programme")
            navigate(routes.learning)
          }
        }
      }
      setIntermediateLoading(false)

      if (promo) {
        applyPromocode(promo)
      }
    }

    processPromo().then(() => fetchCart())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const applyPromocode = async (promocode: string | null) => {
    if (!promocode) return
    try {
      await api.payment.updateCart({ data: { promocode } })
      fetchCart()
      toast.success("Promocode applied!")
    } catch (e) {
      if (e instanceof APIError) handleErrors(e, { setter: setError })
    }
  }

  const removePromocode = async () => {
    try {
      await api.payment.updateCart({
        data: {
          promocode: null,
        },
      })
      fetchCart()
      reset()
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
    }
  }

  const successCallback = ({ isFree } = { isFree: false }) => {
    let redirectUrl = routes.orders

    if (isFree) {
      redirectUrl = routes.learning
      navigate({
        pathname: redirectUrl,
        search: window.location.search,
      })
      return
    }
    const searchParams = new URLSearchParams(window.location.search)

    try {
      const itemId = cart?.cartItems[0].productId
      if (itemId) searchParams.append("item_id", `${itemId}`)
    } catch (e) {
      console.error("> Error while generating redirect url to orders page", e)
    }

    navigate({
      pathname: redirectUrl,
      search: searchParams.toString(),
    })
  }

  const fetchPaymentMethod = async (data: CheckoutOrder) => {
    setPaymentMethodData(data)
    try {
      const res = await api.payment.listPaymentMethod({
        params: {
          callback_url: `${window.location.origin}${routes.orders}`,
        },
        urlParams: {
          paymentPlanId: data.paymentPlans[0].id,
        },
      })
      const paymentMethodData = res[0]
      // If order is free then direct redirect to orders page
      if (paymentMethodData.paymentGatewayOrder?.isFreeOrder) {
        successCallback({ isFree: true })
        return
      }
      setCanChangeAddress(false)

      if (paymentMethodData.paymentMethodId === paymentMethod.JUSPAY) {
        const { paymentLink } = paymentMethodData.paymentMethodDetails
        if (paymentLink) {
          setJuspayPaymentURL(paymentLink)
        }
      }

      // Payment gateway specific code
      if (paymentMethodData.paymentMethodId === paymentMethod.RAZORPAY) {
        if (rzpScriptStatus !== "ready") {
          toast.error("Failed to load payment gateway. Please refresh the page")
          return
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { user } = authInfo!

        openRazorpayCheckoutForm(
          paymentMethodData.paymentMethodDetails.orderId,
          paymentMethodData.paymentMethodDetails.amount,
          data.id,
          {
            fullName: `${user.firstName} ${user.lastName}`,
            email: user.email,
          },
          () => {
            // GA.trackEvent(GA_EVENTS.purchase, {
            //   payment_gateway: "Razorpay",
            //   currency: state.cart.currency?.code,
            //   value: state.cart.cart_total,
            //   coupon: state.cart.promocode?.code,
            //   items: state.cart.cartItems.map(item => ({
            //     item_id: item.product_id,
            //     item_name: item.product_name,
            //   })),
            // })
            successCallback()
          }
        )
      }

      if (
        paymentMethodData.paymentMethodId === paymentMethod.STRIPE &&
        paymentMethodData.paymentMethodDetails.clientSecret
      ) {
        sessionStorage.setItem(
          "stripeClientSecret",
          paymentMethodData.paymentMethodDetails.clientSecret
        )
        setClientSecret(paymentMethodData.paymentMethodDetails.clientSecret)
        setStripeFormOpen(true)
      }
      setIntermediateLoading(false)
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
    }
  }

  const openCheckout = async () => {
    if (paymentMethodData) {
      fetchPaymentMethod(paymentMethodData)
      return
    }

    if (!cart) throw Error("Cart not found")

    setIntermediateLoading(true)
    try {
      const res = await api.orders.checkout({
        data: {
          cartId: cart.uuid,
        },
      })
      if (res.finalAmount === 0) {
        navigate({
          pathname: routes.orders,
          search: window.location.search,
        })
      } else {
        fetchPaymentMethod(res)
      }
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
      setIntermediateLoading(false)
    }
  }

  if (juspayPaymentURL) {
    return (
      <Dialog open={Boolean(juspayPaymentURL)} fullScreen>
        <DialogContent className="flex items-center justify-center">
          <iframe
            allow="payment *;"
            className="w-full h-screen"
            src={juspayPaymentURL}
            title="Juspay"
          />
        </DialogContent>
      </Dialog>
    )
  }

  if (isError || cart?.cartItems.length === 0) {
    return (
      <Container>
        <div className="flex flex-col justify-center my-3">
          <Typography variant="title2" className="mb-3" textAlign="center">
            Seems like your cart is empty!
          </Typography>

          <TalkToMentorBanner />
        </div>
      </Container>
    )
  }

  const canAddPromocode = cart?.billingAddress !== null

  return (
    <>
      {intermediateLoading && <LoadingOverlay />}
      <Container>
        <Typography variant="title2" className="my-3">
          Payment Details
        </Typography>

        {isLoading && (
          <div className="flex justify-center items-center pt-5">
            <CircularProgress />
          </div>
        )}
        {cart && (
          <>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <StripeCheckoutForm
                  itemId={cart.cartItems[0].productId}
                  itemName={cart.cartItems[0].productName}
                  amount={cart.cartTotal}
                  currency={cart.currency}
                  promocode={cart.promocode?.code}
                  open={stripeFormOpen}
                  handleClose={() => setStripeFormOpen(false)}
                />
              </Elements>
            )}

            <div className="flex w-[100%] md:flex-row flex-col mb-2">
              <div className="md:w-[50%] md:me-3">
                <ImageBackgroundCard
                  background={cart.cartItems[0].image}
                  className="rounded-2xl"
                >
                  <Card padding={2}>
                    <div className="flex sm:items-center grow sm:flex-row flex-col">
                      <img
                        alt="item"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = CourseFallback
                        }}
                        src={cart.cartItems[0].image || CourseFallback}
                        className="border border-surface-100 rounded-xl sm:me-2 sm:h-[86px] h-[153px] sm:mb-0 mb-2 object-cover flex-shrink-0"
                      />
                      <div>
                        <Typography variant="strong" color="surface.500">
                          {cart.cartItems[0].productName}
                        </Typography>
                        <Typography
                          variant="title4"
                          color="surface.500"
                          className="mt-1"
                        >
                          {cart.currency.symbol}
                          {formatPrice(cart.cartTotal)}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </ImageBackgroundCard>

                <Card
                  className="mt-2"
                  onClick={() =>
                    canChangeAddress ? setSelectAddress(true) : undefined
                  }
                >
                  <div className="flex items-center justify-between p-2">
                    <div>
                      <Typography variant="title4" className="ms-0.5">
                        Billing Address
                      </Typography>
                      {cart.billingAddress ? (
                        <div className="flex items-center ms-0.5 mt-0.75 flex-wrap">
                          <Typography
                            variant="strong"
                            className="me-0.5 flex flex-nowrap"
                          >
                            {cart.billingAddress.name}
                          </Typography>
                          <TruncatedText
                            hideActionButton
                            maxLength={40}
                            style={{
                              whiteSpace: "pre-wrap",
                            }}
                            variant="body"
                            color="onSurface.600"
                          >
                            {[
                              cart.billingAddress.country,
                              cart.billingAddress.state,
                              cart.billingAddress.city,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </TruncatedText>
                        </div>
                      ) : (
                        <Button nudge="left" variant="text" className="mt-0.5">
                          Add Billing Address
                        </Button>
                      )}
                    </div>
                    <IconButton color="black">
                      <NavArrowRight />
                    </IconButton>
                  </div>
                </Card>
              </div>
              <div className="md:w-[50%] flex flex-col mb-10 md:mt-0 mt-3">
                {cart.promocode !== null ? (
                  <div className="bg-[#F7F7F7] flex items-center justify-between border border-surface-200 rounded-2xl px-2 py-1.25">
                    <Typography variant="strong">
                      {cart.promocode.code}
                    </Typography>
                    <Button
                      variant="text"
                      onClick={removePromocode}
                      color="critical"
                    >
                      Remove
                    </Button>
                  </div>
                ) : (
                  <Tooltip
                    disabled={canAddPromocode}
                    title="Please add a billing address first"
                  >
                    <form onSubmit={onSubmit}>
                      <div className="bg-[#F7F7F7] flex items-center justify-between border border-surface-200 rounded-2xl px-2 py-1.25">
                        <TextField
                          error={Boolean(errors.promocode)}
                          helperText={errors.promocode?.message}
                          className="me-2"
                          placeholder="Promocode"
                          fullWidth
                          {...register("promocode")}
                          disabled={!canAddPromocode}
                        />
                        <Button
                          variant="text"
                          type="submit"
                          disabled={!canAddPromocode}
                        >
                          Apply
                        </Button>
                      </div>
                    </form>
                  </Tooltip>
                )}
                <div className="bg-[#F7F7F7] border border-surface-200 rounded-2xl px-2 py-1.25 mt-2">
                  {[
                    { name: "Item", amount: cart.itemTotal },
                    { name: "Discount", amount: cart.promocodeDiscount },
                    { name: "Taxes", amount: cart.totalTaxCharged },
                  ].map((item, idx) => (
                    <div
                      key={idx}
                      className="mb-2 flex items-center justify-between"
                    >
                      <Typography variant="strong">{item.name}</Typography>
                      <Typography
                        variant="strong"
                        color={
                          item.name === "Discount"
                            ? "primary.500"
                            : "onSurface.900"
                        }
                      >
                        {item.name === "Discount" && "-"}
                        {cart.currency.symbol}
                        {formatPrice(item.amount)}
                      </Typography>
                    </div>
                  ))}
                  <Divider weight="light" color="onSurface.200" />
                  <span className="flex items-center justify-between mt-1">
                    <span className="flex items-center">
                      <Typography variant="strong" className="me-0.5">
                        Total
                      </Typography>
                    </span>
                    <Typography variant="title2">
                      {cart.currency.symbol}
                      {formatPrice(cart.cartTotal)}
                    </Typography>
                  </span>
                </div>
                <Tooltip
                  title="Please add a billing address first"
                  disabled={cart.billingAddress !== null}
                >
                  <div>
                    <Button
                      color="primary"
                      onClick={() => openCheckout()}
                      variant="filled"
                      fullWidth
                      className="mt-2"
                      disabled={cart.billingAddress === null}
                    >
                      {cart.cartTotal === 0
                        ? "Claim"
                        : `Pay ${cart.currency.symbol}${formatPrice(
                            cart.cartTotal
                          )}`}
                    </Button>
                  </div>
                </Tooltip>
                <Typography
                  variant="smallBody"
                  className="mt-1 w-[80%] text-center self-center"
                  color="secondary.500"
                >
                  By confirming this purchase, I agree to the
                  <a
                    className="text-interactive-500"
                    href={getPlatformURL("suraasa", "/terms-of-use/")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    terms of use
                  </a>{" "}
                  and
                  <a
                    className="text-interactive-500"
                    href={getPlatformURL("suraasa", "/privacy-policy/")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    privacy policy.
                  </a>
                </Typography>
              </div>
            </div>
            <BillingAddress
              billingAddress={cart.billingAddress}
              onSave={() => fetchCart()}
              open={selectAddress}
              handleClose={() => {
                setSelectAddress(false)
              }}
            />
          </>
        )}
      </Container>
    </>
  )
}

export default Payment
