import axios from "api/axios"
import {
  APIResponse,
  Config,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Comment, Discussion, VoteType } from "./types"
import { urls } from "./urls"

export default {
  list: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Discussion[]>> => {
    try {
      const res = await axios.get(urls.discussion.post(), {
        params,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  retrieve: async ({ params }: Config): Promise<APIResponse<Discussion>> => {
    try {
      const res = await axios.get(urls.discussion.retrieve(params.id))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  comments: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Comment[]>> => {
    try {
      const res = await axios.get(urls.discussion.comments(params.id), {
        params: {
          fields: params.fields,
          page: params.page,
        },
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  upvote: async ({ params }: Config): Promise<APIResponse<VoteType>> => {
    try {
      const res = await axios.put(urls.discussion.upvote(params.id))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  postComment: async ({
    params,
    data,
  }: Config): Promise<APIResponse<Comment>> => {
    try {
      const res = await axios.post(urls.discussion.postComment(params.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  postReply: async ({
    params,
    data,
  }: Config): Promise<APIResponse<Comment>> => {
    try {
      const res = await axios.post(urls.discussion.postReply(params.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  deleteDiscussion: async ({
    params,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.delete(urls.delete.discussion(params.id))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  deleteComment: async ({
    params,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.delete(urls.delete.comment(params.id))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  deleteReply: async ({
    params,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.delete(urls.delete.reply(params.id))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  reportComment: async ({
    params,
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.report.comment(params.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  reportReply: async ({
    params,
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.report.reply(params.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  reportDiscussion: async ({
    params,
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.report.discussion(params.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  block: async ({ data }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.block.create(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  postDiscussion: async ({
    params,
    data,
  }: Config): Promise<APIResponse<Discussion>> => {
    try {
      const res = await axios.post(urls.discussion.post(), data, { params })
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
