import React from "react"

type TextProps = {
  text: string
  style?: React.CSSProperties
}

const getTextStyle = (
  marker: string,
  baseStyle: React.CSSProperties
): React.CSSProperties => {
  switch (marker) {
    case "u":
      return { ...baseStyle, textDecoration: "underline" }
    case "b":
      return { ...baseStyle, fontWeight: "bold" }
    default:
      return baseStyle
  }
}

const ActivityFormattedText: React.FC<TextProps> = ({ text, style = {} }) => {
  const exp = /_([ub])_(.*?)_([ub])_/gs
  let match = exp.exec(text)
  let currentIndex = 0
  const textSpans: JSX.Element[] = []

  while (match) {
    if (match.index > currentIndex) {
      textSpans.push(
        <span style={style}>{text.substring(currentIndex, match.index)}</span>
      )
    }

    const marker = match[1]
    const content = match[2]

    const markerStyle = getTextStyle(marker, style)
    textSpans.push(<span style={markerStyle}>{content}</span>)

    currentIndex = (match.index || 0) + match[0].length
    match = exp.exec(text)
  }

  if (currentIndex < text.length) {
    textSpans.push(<span style={style}>{text.substring(currentIndex)}</span>)
  }

  return <>{textSpans}</>
}

export default ActivityFormattedText
