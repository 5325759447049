import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Cart, PaymentMethod, SavedAddress } from "./types"
import { urls } from "./urls"

export default {
  getCart: async (): Promise<APIResponse<Cart>> => {
    try {
      const res = await axios.get(urls.cart.getCart())
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  updateCart: async ({ data }: Config): Promise<APIResponse<Cart>> => {
    try {
      const res = await axios.put(urls.cart.getCart(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  addItemToCart: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.cart.addItem(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  listSavedAddresses: async (): Promise<APIResponse<SavedAddress[]>> => {
    try {
      const res = await axios.get(urls.billingAddress.list())
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  deleteAddress: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.delete(urls.billingAddress.detail(urlParams.uuid))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  createAddress: async ({
    data,
  }: Config): Promise<APIResponse<SavedAddress>> => {
    try {
      const res = await axios.post(urls.billingAddress.list(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  listPaymentMethod: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<PaymentMethod[]>> => {
    try {
      const res = await axios.post(
        urls.payment.methods(urlParams.paymentPlanId),
        {},
        { params }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
