import { useEffect, useState } from "react"

export const useAnchorMargin = (selector: string) => {
  const [marginLeft, setMarginLeft] = useState(0)

  useEffect(() => {
    const listener = () => {
      const anchor = document.querySelector(selector)

      if (!anchor) return

      const xPos = anchor.getBoundingClientRect().x
      setMarginLeft(xPos)
    }

    listener()

    window.addEventListener("resize", listener)

    return () => {
      window.removeEventListener("resize", listener)
    }
  }, [selector])

  return { marginLeft }
}
