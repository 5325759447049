import { captureException } from "@sentry/react"
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Tag,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/orders/queries"
import { OrderItemType } from "api/resources/orders/types"
import Monitor from "assets/Fallback/monitor.svg"
import TagFallback from "assets/Fallback/tag.svg"
import CertificationPlaceholder from "assets/Placeholder/certificate_placeholder.svg"
import CoursePlaceholder from "assets/Placeholder/course_placeholder.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import TalkToMentorBanner from "components/TalkToMentorBanner"
import TopHeadingBar from "components/TopHeadingBar"
import { format } from "date-fns"
import React, { useEffect } from "react"
import { useTheme } from "react-jss"
import { useNavigate } from "react-router"
import routes from "routes"
import useFormatPrice from "utils/hooks/useFormatPrice"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import { getStatusTag } from "./utils/helpers"

const OrderItemCard = ({
  itemName,
  itemType,
  image,
}: {
  itemName: string
  itemType: OrderItemType
  image: string | null
}) => {
  const FallbackImage =
    itemType === OrderItemType.COURSE
      ? CoursePlaceholder
      : CertificationPlaceholder

  return (
    <div className="flex items-start">
      <div className="h-[53px] w-10 overflow-hidden rounded-md">
        <img
          src={image ?? FallbackImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = FallbackImage
          }}
          alt="thumbnail"
          height={53}
          width={80}
        />
      </div>
      <div className="ms-1.5">
        <Typography
          variant="preTitle"
          color="onSurface.400"
          className="mb-0.75"
        >
          {itemType}
        </Typography>
        <Typography variant="strong">{itemName}</Typography>
      </div>
    </div>
  )
}

const Orders = () => {
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const formatPrice = useFormatPrice()

  const navigate = useNavigate()

  const { isLoading, hasNextPage, fetchNextPage, data, isError, isSuccess } =
    useInfiniteQuery({
      queryKey: queries.list().queryKey,
      queryFn: x =>
        api.orders.listOrders({
          params: {
            page: x.pageParam || 1,
          },
        }),
    })

  const { ref } = usePaginationScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  // TODO(annie): revert
  // const [requestInvoice, setRequestInvoice] = useState({
  //   enable: false,
  //   transactionId: "",
  // })

  useEffect(() => {
    if (data) {
      const exception = data.pages.some(orders => {
        orders.data.some(order =>
          order.items.some(product => product.productItems.length > 1)
        )
      })
      if (exception) {
        captureException(new Error("More than 1 Product Items exist"))
      }
    }
  }, [data])

  return (
    <>
      {!isMdDown && (
        <TopHeadingBar
          heading="Orders and Payments"
          subheading="See your orders and payment history"
        />
      )}
      {isError && (
        <>
          {isMdDown && (
            <Container>
              <Typography
                variant="title2"
                className="mt-2"
                color="onSurface.800"
              >
                Orders and Payments
              </Typography>
              <Typography color="onSurface.500" className="">
                See your orders and payment history
              </Typography>
              <Divider weight="light" color="onSurface.200" className="my-3" />
            </Container>
          )}
          <Fallback
            data={{
              image: Monitor,
              title: "Something went wrong.",
              description: "Please contact care@suraasa.com",
            }}
            className="md:my-8 sm:my-20 my-6"
            hasError={false}
          />
        </>
      )}
      {isLoading && (
        <div className="text-center pt-10">
          <CircularProgress />
        </div>
      )}
      {isSuccess && (
        <>
          <Container className="mb-6">
            {isMdDown && (
              <>
                <Typography
                  variant="title2"
                  className="mt-2"
                  color="onSurface.800"
                >
                  Orders and Payments
                </Typography>
                <Typography color="onSurface.500" className="">
                  See your orders and payment history
                </Typography>
                <Divider
                  weight="light"
                  color="onSurface.200"
                  className="my-3"
                />
              </>
            )}

            {data && (
              <>
                {data.pages[0].total > 0 ? (
                  data.pages.map(orders =>
                    orders.data.map(order => {
                      const statusTag = getStatusTag(order.status)
                      return (
                        <div
                          ref={ref}
                          key={order.id}
                          className="bg-surface-500 border border-surface-200 rounded-2xl mt-2"
                        >
                          <div className="p-2">
                            {order.items.map((item, idx) => (
                              <div
                                key={idx}
                                className={clsx({
                                  ["mb-2"]: idx < order.items.length - 1,
                                })}
                              >
                                <OrderItemCard
                                  itemName={item.itemName}
                                  itemType={item.productItems[0].itemType}
                                  image={item.productItems[0].image}
                                />
                              </div>
                            ))}
                          </div>
                          <Divider weight="light" color="onSurface.200" />
                          {isSmDown ? (
                            <div className="p-2 flex flex-col">
                              <div className="flex items-start justify-between flex-wrap">
                                <span>
                                  <Typography
                                    variant="strongSmallBody"
                                    className="me-1 mb-0.5"
                                  >
                                    #{order.serialNumber}
                                  </Typography>
                                  <Typography
                                    variant="smallBody"
                                    color="onSurface.500"
                                  >
                                    Ordered on{" "}
                                    {format(
                                      new Date(order.createdAt),
                                      "dd MMM yyyy"
                                    )}
                                  </Typography>
                                </span>
                                <Tag
                                  label={statusTag.label}
                                  color={statusTag.color}
                                />
                              </div>
                              <Button
                                fullWidth
                                className="mt-3 mb-2"
                                onClick={() =>
                                  navigate(
                                    routes.orderDetailsPage.replace(
                                      ":id",
                                      order.id
                                    )
                                  )
                                }
                              >
                                View Details
                              </Button>
                              {/* TODO(annie): revert */}
                              {/* <Button
                          variant="text"
                          className="self-center"
                          onClick={() =>
                            setRequestInvoice({
                              enable: true,
                              transactionId: order.id,
                            })
                          }
                        >
                          Request Invoice
                        </Button> */}
                            </div>
                          ) : (
                            <div className="p-2 flex items-center justify-between">
                              <div className="flex flex-col">
                                <span className="flex items-center">
                                  <Typography
                                    variant="strongSmallBody"
                                    className="me-1"
                                  >
                                    #{order.serialNumber}
                                  </Typography>
                                  {/* TODO(annie): revert */}
                                  {/* <Button
                              variant="text"
                              onClick={() =>
                                setRequestInvoice({
                                  enable: true,
                                  transactionId: order.id,
                                })
                              }
                            >
                              Request Invoice
                            </Button> */}
                                </span>
                                <Typography
                                  variant="smallBody"
                                  color="onSurface.500"
                                  className="mt-0.75"
                                >
                                  Ordered on{" "}
                                  {format(
                                    new Date(order.createdAt),
                                    "dd MMM yyyy"
                                  )}
                                </Typography>
                              </div>
                              <div className="flex items-center">
                                <Tag
                                  label={statusTag.label}
                                  color={statusTag.color}
                                />
                                <Typography
                                  variant="strong"
                                  className="me-2 ms-2"
                                >
                                  {order.currency.symbol}
                                  {formatPrice(order.finalAmount)}
                                </Typography>
                                <Button
                                  onClick={() =>
                                    navigate(
                                      routes.orderDetailsPage.replace(
                                        ":id",
                                        order.id
                                      )
                                    )
                                  }
                                >
                                  View Details
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    })
                  )
                ) : (
                  <>
                    <Fallback
                      data={{
                        image: TagFallback,
                        title: "Looks like you haven’t ordered anything yet!",
                        description:
                          "Head to the store and check out the courses we offer to start your upskilling journey today!",
                      }}
                      className="md:my-8 sm:my-20 my-6"
                      hasError={false}
                    />
                    <TalkToMentorBanner />
                  </>
                )}
              </>
            )}
          </Container>
          {/* TODO(annie): revert Request Invoice */}
          {/* <RequestInvoiceDialog
        reset={() => setRequestInvoice({ enable: false, transactionId: "" })}
        invoiceDetails={requestInvoice}
      /> */}
        </>
      )}
    </>
  )
}

export default Orders
