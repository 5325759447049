export default {
  login: "/login",

  old: {
    home: "/dashboard/library",
    assessments: "/dashboard/assessments",
    assignments: "/dashboard/assignments",
    payment: "/dashboard/orders/payment",
    orders: "/dashboard/orders",
    ito: "/dashboard/ito",
  },
  editProfile: "/profile/edit",
  profile: "/profile",
  publicProfile: "/profile/:username",

  skillProfile: {
    root: "/profile/skill-profile/",
    overview: "/profile/skill-profile/overview",
    evidences: "/profile/skill-profile/evidences/:slug",
    evidenceDetails: "/profile/skill-profile/evidence-details/:submissionId",
    evidenceSetting: "/profile/skill-profile/evidence-setting/:slug",
    verificationProcess: "/profile/skill-profile/verification-process",
  },
  publicSkillProfile: {
    root: "/profile/skill-profile/:username/",
    overview: "/profile/skill-profile/:username/overview",
    evidences: "/profile/skill-profile/:username/evidences/:slug",
    evidenceDetails:
      "/profile/skill-profile/:username/evidence-details/:submissionId",
    verificationProcess:
      "/profile/skill-profile/:username/verification-process",
  },

  ito2022: "/past-events/international-teachers-olympiad-2022",
  ito: "/international-teachers-olympiad",

  notifications: "/notifications",

  home: "/home",
  learning: "/learning",
  certificates: "/certificates",
  mySchedule: "/my-schedule",

  discussions: "/discussions",

  // Learning Module Routes
  learningModule: "/learning/course/:slug",
  learningModuleWithParent:
    "/learning/:learningItemType/:parentSlug/course/:slug",

  // Course Routes
  course: "/course/:slug",
  scheduleInterview: "/application-form/schedule-interview",

  // Certification Routes
  certification: "/certification/:slug/",
  certificationCourse: "/qualification/:parentSlug/course/:slug",
  certificationOverview: "/certification/:slug/overview",
  certificationAssessments: "/certification/:slug/assessments",
  certificationCertificates: "/certification/:slug/certificates",
  certificationResources: "/certification/:slug/resources",

  // Qualification Routes
  parentItem: "/:learningItemType/:slug",
  parentItemCourse: "/:learningItemType/:parentSlug/course/:slug",
  parentItemOverview: "/:learningItemType/:slug/overview",
  parentItemAssessments: "/:learningItemType/:slug/assessments",
  parentItemCertificates: "/:learningItemType/:slug/certificates",
  parentItemResources: "/:learningItemType/:slug/resources",
  parentItemClasses: "/:learningItemType/:slug/classes",

  // Assessment Routes
  attemptAssessment: "/assessments/attempt/:assessmentId",
  assessmentResult: "/assessments/result/:attemptId",
  instructionsITOAssessment:
    "/ito/assessments/instructions/:assessmentId/:attemptId?",
  attemptITOAssessment: "/ito/assessments/attempt/:attemptId",

  // Assignment Routes
  assignment: "/assignments/:assignmentId",
  submission: "/assignments/:assignmentId/:submissionId",

  // Orders Routes
  orders: "/orders",
  orderDetailsPage: "/orders/:id",
  orderPaymentPlan: "/orders/:id/payment-plan",
  payment: "/orders/payment",

  // Settings Routes
  settings: "/settings",
  privacy: "/settings/privacy",
  accountDetails: "/settings/account-details",
  notificationSettings: "/settings/notifications",

  misc: {
    proctoringDemo: "/testing/proctoring/",
    browserInfo: "/browser-info",
  },
}
