import axios from "api/axios"
import {
  APIResponse,
  Config,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { NotificationResponse, NotificationsOverview } from "./types"
import { urls } from "./urls"

export default {
  list: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<NotificationResponse>> => {
    try {
      const res = await axios.get(urls.notification.list(), { params })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  overview: async (): Promise<APIResponse<NotificationsOverview>> => {
    try {
      const res = await axios.get(urls.notification.overview())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  markAsRead: async ({ data }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(urls.notification.markAsRead(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  markAsOpened: async ({ params }: Config): Promise<NoContentType> => {
    try {
      const res = await axios.put(urls.notification.markAsOpened(params.id))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
