import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("discussions", {
  list: (course?: string) => ({
    queryKey: ["discussionList", course],
  }),
  post: (discussionId: string) => ({
    queryKey: ["discussionPost", discussionId],
  }),
  commentList: (discussionId: string) => ({
    queryKey: ["commentList", discussionId],
  }),
  postComment: (discussionId: string) => ({
    queryKey: ["postComment", discussionId],
  }),
  postReply: (discussionId: string) => ({
    queryKey: ["postReply", discussionId],
  }),
})
