import { CircularProgress, Menu, MenuItem } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import ViewPreviousSubmissionDialog from "features/AssignmentResult/ViewPreviousSubmissionDialog"
import { NavArrowDown } from "iconoir-react"
import React, { useState } from "react"

type Props = {
  assignmentId: string
  currentSubmissionId?: string
}

const PreviousSubmissionsDropdown = ({
  currentSubmissionId,
  assignmentId,
}: Props) => {
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryFn: () =>
      api.assignments.previousSubmissionsList({ urlParams: { assignmentId } }),
    queryKey:
      queries.assignments.submissionsListByAssignmentId(assignmentId).queryKey,
  })

  const [viewPreviousSubmissionIndex, setViewPreviousSubmissionIndex] =
    useState<string | null>(null)

  const getMenuItems = () => {
    if (!data) return null
    if (data.length === 0) {
      return <MenuItem>No submissions found</MenuItem>
    }

    const orderedData = data.sort((a, b) =>
      new Date(a.dateCreated) < new Date(b.dateCreated) ? 1 : -1
    )

    return orderedData.map((x, i) => (
      <MenuItem
        key={x.uuid}
        onClick={() => setViewPreviousSubmissionIndex(x.uuid)}
      >
        Submission {orderedData.length - i}{" "}
        {currentSubmissionId === x.uuid && "(current)"}
      </MenuItem>
    ))
  }

  return (
    <>
      <ViewPreviousSubmissionDialog
        open={!!viewPreviousSubmissionIndex}
        onClose={() => setViewPreviousSubmissionIndex(null)}
        title={`Previous Submission`}
        submissionId={viewPreviousSubmissionIndex}
      />

      <Menu
        menuButton={
          <button>
            <p className="flex text-sm font-semibold items-center">
              <span className="me-0.25">View Previous Submissions</span>
              {isLoading ? (
                <span className="ms-1">
                  <CircularProgress size="xs" />
                </span>
              ) : (
                <NavArrowDown />
              )}
            </p>
          </button>
        }
      >
        {isError && <MenuItem>Error!</MenuItem>}
        {isSuccess && getMenuItems()}
      </Menu>
    </>
  )
}

export default PreviousSubmissionsDropdown
