import { Button } from "@suraasa/placebo-ui"
import React, { useEffect, useState } from "react"
import { BROWSER_STORAGE_KEYS } from "utils/constants"

import SubjectsDialog from "../Subjects/SubjectsDialog"
import WorkLocationDialog from "../WorkLocationInterests/WorkLocationDialog"
import Step1 from "./Step1"

enum Steps {
  Step1,
  Step2,
  Step3,
}
const { reorderOnboarding: updateProfileDialog } = BROWSER_STORAGE_KEYS

const ReOrderingFeatureOnboarding = () => {
  const updateStatus = localStorage.getItem(updateProfileDialog)

  const [step, setStep] = useState<Steps | null>(null)

  const onCloseDialog = () => {
    setStep(null)
    localStorage.setItem(updateProfileDialog, JSON.stringify([]))
  }
  const onFinish = () => {
    setStep(null)
  }

  const onClick = () => {
    if (step !== null) {
      setStep(step + 1)
    }
  }

  useEffect(() => {
    if (updateStatus === null) {
      setStep(Steps.Step1)
    }
  }, [updateStatus])

  return (
    <>
      <Step1
        onClick={onClick}
        onClose={onCloseDialog}
        open={step === Steps.Step1}
      />

      <SubjectsDialog
        open={step === Steps.Step2}
        onRequestClose={undefined}
        customAction={
          <Button
            onClick={onClick}
            className="ml-auto !block"
            size="sm"
            variant="outlined"
          >
            Continue
          </Button>
        }
      />
      <WorkLocationDialog
        open={step === Steps.Step3}
        onRequestClose={undefined}
        customAction={
          <Button
            onClick={onFinish}
            className="ml-auto !block"
            size="sm"
            variant="outlined"
          >
            Continue
          </Button>
        }
      />
    </>
  )
}

export default ReOrderingFeatureOnboarding
