import camelCase from "jss-plugin-camel-case"
import defaultUnit from "jss-plugin-default-unit"
import global from "jss-plugin-global"
import nested from "jss-plugin-nested"
import propsSort from "jss-plugin-props-sort"
import functions from "jss-plugin-rule-value-function"
import vendorPrefixer from "jss-plugin-vendor-prefixer"

export default function jssPreset() {
  return {
    plugins: [
      functions(),
      global(),
      nested(),
      camelCase(),
      defaultUnit(),
      vendorPrefixer(),
      propsSort(),
    ],
  }
}
