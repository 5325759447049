import { Checkbox, Divider, Radio, Tag, Typography } from "@suraasa/placebo-ui"
import {
  Answer as AnswerType,
  QuestionSolution,
  QuestionType,
} from "api/resources/assessments/types"
import clsx from "clsx"
import {
  isCaseStudySolution,
  isQuestionOfType,
} from "features/Assessments/helpers"
import React from "react"

import FillBlank, { displayBlank, safeHTML } from "../FillBlank"
import MultipleCorrect from "../MultipleCorrect"
import SingleCorrect from "../SingleCorrect"
import styles from "./Answer.module.css"

export default function Answer({ question }: { question: QuestionSolution }) {
  return (
    <div className="bg-surface-500 border-t-surface-200 border-b-surface-200 border-t border-b sm:border-r sm:border-l sm:border-r-surface-200 sm:border-l-surface-200 sm:rounded-2xl p-2 sm:p-3">
      {isCaseStudySolution(question) ? (
        <React.Fragment>
          <Header question={question} />
          {question.questions.map((ques, index) => (
            <React.Fragment key={index}>
              <RenderAnswer
                question={ques}
                key={index}
                number={`${question.questionNumber}.${index + 1}`}
                isSubQuestion
              />
              {index < question.questions.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : (
        <RenderAnswer question={question} number={question.questionNumber} />
      )}
    </div>
  )
}

const getQuestionTypeString = (type: QuestionType) => {
  switch (type) {
    case QuestionType.CASE_STUDY:
      return "Case Study"
    case QuestionType.FILL_IN_THE_BLANKS:
      return "Fill in the blanks"
    case QuestionType.MULTIPLE_CORRECT:
      return "Multi Correct"
    case QuestionType.SINGLE_CORRECT:
      return "Single Correct"
    default:
      return ""
  }
}

const Header = ({ question }: { question: QuestionSolution }) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <Typography
            variant="preTitle"
            color="onSurface.500"
            className="mb-0.5"
          >
            {getQuestionTypeString(question.questionType)}
          </Typography>
          <Typography variant="title4">
            {/* 
            questionNumber can be a string as well (in case of subQuestions)
            Make sure we don't do any operations number operations on this questionNumber without explicit checks
          */}
            Question {question.questionNumber}
          </Typography>
        </div>

        {!isCaseStudySolution(question) && (
          <div>
            {question.response && question.response.length > 0 ? (
              question.isCorrect ? (
                <Tag color="success" label="Correct" />
              ) : (
                <Tag color="critical" label="Incorrect" />
              )
            ) : (
              <Tag color="critical" label="Not Attempted" />
            )}
          </div>
        )}
      </div>

      {isCaseStudySolution(question) && (
        <div>
          <Typography
            variant="body"
            className="break-words whitespace-pre-wrap mt-1"
          >
            {question.text}
          </Typography>
        </div>
      )}
    </div>
  )
}

const convert = (
  text: string,
  correctAnswer: number[],
  options: Record<string, string>
) => {
  const questionParts = []
  const questionTextArray = text.split("__")
  let answerIndex = 0
  for (let i = 0; i < questionTextArray.length; i++) {
    if (questionTextArray[i] === "blank") {
      const optionKey = correctAnswer[answerIndex]
      const answer =
        optionKey != null
          ? safeHTML(options[optionKey] as string)
          : new Array(11).fill("&nbsp;").join("")
      questionParts.push(displayBlank(answer, "success"))
      answerIndex += 1
    } else {
      questionParts.push(questionTextArray[i])
    }
  }
  return <div dangerouslySetInnerHTML={{ __html: questionParts.join(" ") }} />
}

const RenderAnswer = ({
  question,
  number,
  isSubQuestion = false,
}: {
  question: AnswerType
  number: number | string
  isSubQuestion?: boolean
}) => {
  return (
    <div className={clsx({ ["ms-3 mt-3"]: isSubQuestion })}>
      {/*
        In case of subQuestions, we get numbers like 12.01, 12.09, 12.11 and so on...
        However
        Number(12.01) = 12.1
        Number(12.10) = 12.1

        To avoid it we pass a string like '12.01' or '12.10'
        Due to this, we cannot keep 'number' prop as number type.

        But now it blows up the entire project because QuestionType does not expect questionNumber to be a string.
        This is an edge case and would require a lot of effort to execute properly
      */}
      <Header question={{ ...question, questionNumber: number as number }} />

      <div>
        {isQuestionOfType(question, "SINGLE_CORRECT") && (
          <SingleCorrect question={question} />
        )}
        {isQuestionOfType(question, "MULTIPLE_CORRECT") && (
          <MultipleCorrect question={question} />
        )}
        {isQuestionOfType(question, "FILL_IN_THE_BLANKS") && (
          <FillBlank question={question} />
        )}
      </div>
      {!question.isCorrect && (
        <>
          <div className={clsx(styles.autoHeight, "mt-2")}>
            <Typography
              variant="strongSmallBody"
              display="block"
              className="mb-1"
            >
              Correct Answer:
            </Typography>
            {question.correctAnswer &&
            question.questionType === QuestionType.FILL_IN_THE_BLANKS ? (
              <div>
                {convert(
                  question.question,
                  question.correctAnswer,
                  question.options
                )}
              </div>
            ) : (
              question.correctAnswer.map((answer: number, index: number) => {
                const DynamicComponent =
                  question.questionType === QuestionType.SINGLE_CORRECT
                    ? Radio
                    : Checkbox

                return (
                  <DynamicComponent
                    readOnly
                    checked
                    className="mt-1"
                    key={index}
                    label={`${
                      Object.entries(question.options)[answer][1] as string
                    }`}
                  />
                )
              })
            )}
          </div>
        </>
      )}
      <div className="bg-surface-50 border border-surface-100 rounded-2xl p-2 my-2">
        <Typography variant="strong">Explanation</Typography>
        <Typography>{question.explanation}</Typography>
      </div>
    </div>
  )
}
