import {
  Button,
  CircularProgress,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ActivityCategoryEnum,
  ActivityTypeEnum,
  JumbledWordsActivity,
  LearningItem,
  MultipleChoiceActivity,
  PronunciationActivity,
  TextMatchingActivity,
} from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import { LearningModuleContext } from "features/LearningModule/context"
import TitleCard from "features/LearningModule/Course/TitleCard"
import { useUpdateItemStatus } from "features/LearningModule/hooks/useUpdateItemStatus"
import { CheckCircle } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { isMacOs } from "react-device-detect"
import { isAppleDevice } from "utils/constants"

import JumbledWords from "./components/JumbledWords"
import MultipleChoice from "./components/MultipleChoice"
import Pronunciation from "./components/Pronunciation"
import TextMatching from "./components/TextMatching"

const getBrowserDownloadLink = () => {
  if (isAppleDevice) {
    if (isMacOs) {
      return "https://www.google.com/intl/en_in/chrome/"
    }
    return "https://apps.apple.com/us/app/google-chrome/id535886823"
  }
}

const Activity = ({ item }: { item: LearningItem }) => {
  if (item.learningContentType !== "activity")
    throw new Error("Invalid content type passed to Activity component")

  const { updateItemStatus } = useUpdateItemStatus(item)

  const { setImmersiveView, switchItem } = useContext(LearningModuleContext)

  const { instructions } = item

  const [showInstructions, setShowInstructions] = useState(true)

  useEffect(() => {
    setImmersiveView(!showInstructions)
  }, [showInstructions])

  const startActivity = useMutation<unknown, unknown, { retry: boolean }>({
    mutationFn: async ({ retry }) => {
      const data = await api.learningItems.activity.start({
        data: {
          activityId: item.learningContentId,
          retry,
        },
      })

      if (
        data.activity.activityItems.length === data.attemptItemResponses.length
      ) {
        data.attemptItemResponses = data.attemptItemResponses.filter(
          x => x.isCorrect === true
        )
      }

      return data
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.errors.message) toast.error(err.errors.message)
      }
    },
  })

  const disablePronunciationInSafari = false

  const activityStatus = item.status

  if (showInstructions) {
    return (
      <>
        <div className="sm:p-2">
          <TitleCard
            title={item.title || "Activity"}
            action={
              activityStatus === "completed" &&
              item.activityCategory ===
                ActivityCategoryEnum.PRE_COURSE ? undefined : (
                <Button
                  className="w-full sm:w-auto"
                  onClick={() => {
                    setShowInstructions(false)
                    updateItemStatus()
                    startActivity.mutate({ retry: true })
                  }}
                  loading={startActivity.isLoading}
                  disabled={disablePronunciationInSafari}
                >
                  {item.status === "in_progress"
                    ? "Resume Activity"
                    : item.status === "completed"
                    ? "Try Activity Again"
                    : "Start Activity"}
                </Button>
              )
            }
          />
          {item.activityCategory === ActivityCategoryEnum.PRE_COURSE &&
            item.attempt &&
            item.attempt.status === "completed" && (
              <div className="my-2 flex items-center gap-1 rounded-xl border border-success-300 bg-success-50 p-2 text-success-500">
                <CheckCircle className="shrink-0" />
                <Typography variant="strong">
                  You have completed this activity!
                </Typography>
              </div>
            )}
          {item.activityCategory !== ActivityCategoryEnum.PRE_COURSE &&
            item.attempt &&
            item.attempt.status === "completed" &&
            item.attempt.score === 1 && (
              <div className="my-2 flex items-center gap-1 rounded-xl border border-success-300 bg-success-50 p-2 text-success-500">
                <CheckCircle className="shrink-0" />
                <Typography variant="strong">
                  You have completed this activity!
                </Typography>
              </div>
            )}
          {disablePronunciationInSafari && (
            <Typography
              variant="largeBody"
              className="my-2"
              color="critical.500"
              textAlign="center"
            >
              This activity is not yet supported on Safari Web Browser.
              <br />
              Please download the&nbsp;
              <a
                href="https://suraasa.com/get-app"
                className="text-interactive-400 underline"
              >
                Suraasa app
              </a>
              &nbsp; or use&nbsp;&nbsp;
              <a
                className="text-interactive-400 underline"
                href={getBrowserDownloadLink()}
              >
                Google Chrome
              </a>
            </Typography>
          )}
          <Typography variant="title4" color="onSurface.800" className="my-2">
            Instructions
          </Typography>
          <Typography color="onSurface.800">
            <span dangerouslySetInnerHTML={{ __html: instructions }} />
          </Typography>
        </div>
      </>
    )
  }

  const reset = async () => await startActivity.mutate({ retry: true })

  if (!startActivity.data)
    return (
      <div className="flex justify-center p-5">
        <CircularProgress />
      </div>
    )

  const onBack = () => {
    switchItem({ mode: "jump", userPlannerItemId: item.id })
    setShowInstructions(true)
  }

  return (
    <>
      {item.activityType === ActivityTypeEnum.TEXT_MATCHING && (
        <TextMatching
          onBack={onBack}
          data={startActivity.data as TextMatchingActivity}
          reset={reset}
        />
      )}
      {item.activityType === ActivityTypeEnum.MULTIPLE_CHOICE && (
        <MultipleChoice
          onBack={onBack}
          data={startActivity.data as MultipleChoiceActivity}
          reset={reset}
        />
      )}
      {item.activityType === ActivityTypeEnum.JUMBLED_WORDS && (
        <JumbledWords
          onBack={onBack}
          data={startActivity.data as JumbledWordsActivity}
          reset={reset}
        />
      )}
      {item.activityType === ActivityTypeEnum.PRONUNCIATION && (
        <Pronunciation
          onBack={onBack}
          data={startActivity.data as PronunciationActivity}
          reset={reset}
          category={item.activityCategory}
        />
      )}
    </>
  )
}

export default Activity
