import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("notifications", {
  notificationList: (tag: string) => ({
    queryKey: ["notificationList", tag],
  }),
  notificationOverview: () => ({
    queryKey: ["notificationOverview"],
  }),
})
