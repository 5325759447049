import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import { PlannerListItem } from "api/resources/learningItems/types"
import CourseFallback from "assets/Placeholder/course_placeholder.svg"
import clsx from "clsx"
import Card from "components/Card"
import ImageBackgroundCard from "features/LearningItems/ImageBackgroundCard"
import React from "react"
import { useTheme } from "react-jss"
import { Link } from "react-router-dom"
import routes from "routes"
import { PropsWithClassName } from "utils/types"

import CourseInfoAndProgress from "../CourseInfoAndProgress"
import styles from "./ResumeCourseCard.module.css"

type Props = {
  item: PlannerListItem
  className?: string
}

const ResumeCourseCard = ({ item, className }: PropsWithClassName<Props>) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const getLink = () => {
    return routes.parentItem
      .replace(":learningItemType", item.learningItemType)
      .replace(":slug", item.slug)
  }

  const completedItems = item.progress?.completedUserPlannerItems ?? 0
  const totalItems = item.progress?.totalUserPlannerItems ?? 0

  return (
    <Link to={getLink()} className={clsx("block", className)}>
      <ImageBackgroundCard background={item.image} className="rounded-2xl">
        <Card padding={2}>
          <div className="flex sm:items-center justify-between sm:flex-row flex-col">
            <div className="flex sm:items-center grow sm:flex-row flex-col">
              <img
                alt="course"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = CourseFallback
                }}
                src={item.image ?? CourseFallback}
                className="md:w-[130px] border border-surface-100 rounded-xl sm:me-2 sm:h-[86px] h-[153px] sm:mb-0 mb-2 object-cover flex-shrink-0"
              />

              <CourseInfoAndProgress
                currentItemNumber={completedItems}
                numberOfItems={totalItems}
                title={item.name}
                showCount={item.learningItemType === "course"}
                highlightCourse
              />
            </div>
            <Button
              className={clsx("sm:mt-0 mt-2", styles.cardButtonBg)}
              fullWidth={isXs}
            >
              {completedItems === 0 ? "Start Learning" : "Continue Learning"}
            </Button>
          </div>
        </Card>
      </ImageBackgroundCard>
    </Link>
  )
}

export default ResumeCourseCard
