import React from "react"

type Props = {
  list: {
    title: string
    backgroundColor: string
    borderColor?: string
  }[]
}

const Legend = ({ list }: Props) => {
  return (
    <div className="flex flex-wrap">
      {list.map(item => (
        <div
          key={item.title}
          className="grid grid-flow-col gap-0.75 justify-start items-center mr-2.5 mb-0.5"
        >
          <div
            style={{
              height: 16,
              width: 16,
              borderRadius: 4,
              backgroundColor: item.backgroundColor,
              border: `1.5px solid ${
                item.borderColor ? item.borderColor : item.backgroundColor
              }`,
            }}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  )
}

export default Legend
