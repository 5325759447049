import { MasterclassCertificate } from "api/resources/profile/types"
import NoCertificates from "assets/Fallback/no-certificates.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ShareSheet from "components/ShareSheet"
import React, { useState } from "react"

import MasterclassCard from "../Cards/MasterclassCard"
import styles from "./Masterclasses.module.css"

type Props = { masterclassesList: MasterclassCertificate[] }

const Masterclasses = (props: Props) => {
  const [certificateUrl, setCertificateUrl] = useState<string>("")

  if (props.masterclassesList.length === 0) {
    return (
      <Fallback
        data={{
          image: NoCertificates,
          title: "Looks like you don’t have any certificates yet!",
          description: "Attend masterclasses to earn certificates.",
        }}
        className="translate-y-[-50%] translate-x-[-50%] fixed top-1/2 left-1/2 text-center sm:w-fit w-full sm:px-0 px-2"
        hasError={false}
      />
    )
  }
  return (
    <div
      className={clsx(
        "grid md:gap-x-2 sm:gap-x-3 sm:gap-y-2 gap-1 mb-4",
        styles.masterClassGridContainer
      )}
    >
      {props.masterclassesList.map((item, index) => (
        <MasterclassCard
          setCertificateUrl={url => setCertificateUrl(url)}
          key={index}
          data={item}
        />
      ))}
      <ShareSheet
        data={{
          url: certificateUrl,
          text: `Checkout my certificate on Suraasa: ${certificateUrl}`,
        }}
        open={!!certificateUrl}
        handleClose={() => {
          setCertificateUrl("")
        }}
      />
    </div>
  )
}

export default Masterclasses
