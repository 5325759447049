import { Button, Typography } from "@suraasa/placebo-ui"
import { Download } from "iconoir-react"

const Instructions = () => {
  return (
    <>
      <Typography variant="title3" className="mb-1">
        Instructions
      </Typography>
      <Typography className="mb-4">
        <div className="flex flex-col">
          <div className="mb-1">
            <Typography variant="smallBody">
              <ul className="ps-1 md:ps-2.5 list-disc">
                <li>The duration of video must be less than 3 minutes</li>

                <li>
                  It is advised to use the following sequence for creating the
                  video
                </li>
                <ul className="ps-1 md:ps-2.5 list-[circle]">
                  <li>
                    <b>Introduction:</b> State your name and give a brief
                    background, a short story about you as a professional (1
                    min)
                  </li>

                  <li>
                    <b>Experience:</b> Demonstrate key highlights of your
                    experience as a teacher and/or your professional career as
                    related to teaching and children
                  </li>

                  <li>
                    <b>Impact:</b> Share an example(s) to showcase the impact of
                    your experience, a positive outcome or resulting effect
                    related to children/learning/classroom or a life experience
                    related to children or demonstrating positive
                    characteristics
                  </li>
                </ul>
              </ul>
              <br />
              Note: Link pedagogy to any relevant part of the three sections
              above.
            </Typography>
          </div>

          <Button
            color="primary"
            component="a"
            href="https://suraasa.com/assets/documents/Video Portfolio Instructions.pdf"
            startAdornment={<Download />}
            target="_blank"
            variant="text"
            nudge="left"
          >
            Download detailed instructions
          </Button>
        </div>
      </Typography>
    </>
  )
}

export default Instructions
