import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { LanguageProficiency } from "api/resources/profile/types"
import clsx from "clsx"
import { Plus, Trash } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"
import RemoveDialog from "../RemoveDialog"
import AddDialog from "./AddDialog"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  addLanguages: {
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  languageContainer: {
    display: "flex",
    padding: theme.spacing(1.5, 3, 1.5, 0),
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.colors.critical[500],
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    "&:last-child": {
      border: "none",
    },
  },
  addLanguageContainer: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    display: "flex",
    gap: 24,
    flexDirection: "column",
  },
  removeLanguageContent: {
    padding: theme.spacing(2),
  },
}))

const LanguageDialog = ({
  open,
  onRequestClose,
}: Pick<DialogProps, "open" | "onRequestClose"> &
  Pick<DialogTitleProps, "onBack">) => {
  const classes = useStyles()

  const {
    languages: { data, add, remove },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const [openAddLanguageDialog, setOpenAddLanguageDialog] = useState(false)
  const [languageToDelete, setLanguageToDelete] = useState<number | null>(null)

  const [loading, setLoading] = useState(false)

  const handleRemove = async () => {
    if (!languageToDelete) return

    setLoading(true)

    const res = await api.profile.languages.delete({
      urlParams: { id: languageToDelete },
    })
    if (res.isSuccessful) {
      setLoading(false)
      remove(languageToDelete)
      toast.success("Removed successfully.")
      setLanguageToDelete(null)
    } else {
      handleErrors(res)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) setOpenAddLanguageDialog(hasNoItems)
  }, [open, hasNoItems])

  return (
    <Dialog
      open={open}
      width={518}
      onAfterClose={() => setOpenAddLanguageDialog(false)}
      onRequestClose={onRequestClose}
    >
      {openAddLanguageDialog || hasNoItems ? (
        <AddDialog
          open={openAddLanguageDialog}
          onAdd={add}
          onBack={
            hasNoItems ? undefined : () => setOpenAddLanguageDialog(false)
          }
          onRequestClose={onRequestClose}
        />
      ) : (
        <>
          <RemoveDialog
            handleClose={() => setLanguageToDelete(null)}
            loading={loading}
            open={Boolean(languageToDelete)}
            title="Remove Language"
            keepScrollLocked
            onRemove={handleRemove}
          >
            <Typography variant="smallBody">
              Are you sure you want to remove{" "}
              <b>
                {data.find(item => item.id === languageToDelete)?.language.name}
              </b>{" "}
              language from your profile?
            </Typography>
          </RemoveDialog>
          <DialogTitle>Languages</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={clsx(classes.addLanguages, "py-2 px-1.5")}>
              <Button
                startAdornment={<Plus />}
                variant="text"
                onClick={() => setOpenAddLanguageDialog(true)}
              >
                Add New Language
              </Button>
            </div>
            <div className="py-1.5 pr-0 pl-3 flex flex-col gap-1.5">
              {data.map(item => (
                <div className={classes.languageContainer} key={item.id}>
                  <div>
                    <Typography
                      className="pb-0.5"
                      color="onSurface.800"
                      variant="strong"
                    >
                      {item.language.name}
                    </Typography>
                    <Typography color="onSurface.500" variant="smallBody">
                      {capitalize(
                        LanguageProficiency[item.proficiency].replace("_", " ")
                      )}
                    </Typography>
                  </div>
                  <IconButton
                    color="critical"
                    onClick={() => {
                      setLanguageToDelete(item.id)
                    }}
                  >
                    <Trash />
                  </IconButton>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogFooter
            actions={{
              primary: null,
              secondary: {
                variant: "outlined",
                color: "secondary",
                label: "Close",
              },
            }}
          />
        </>
      )}
    </Dialog>
  )
}

export default LanguageDialog
