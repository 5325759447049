import { Typography } from "@suraasa/placebo-ui"
import { ArrowRight } from "iconoir-react"
import React from "react"

const SomethingNew = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className="p-2 py-0.5 w-full flex items-center justify-between bg-gradient-to-r from-interactive-200 to-interactive-200/52 "
    >
      <Typography variant="smallBody" color="highlight.700">
        See what’s new
      </Typography>

      <div className="p-0.5 text-highlight-700">
        <ArrowRight width={20} height={20} />
      </div>
    </button>
  )
}

export default SomethingNew
