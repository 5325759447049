import {
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { CoreAreaSlug, ITOReport } from "api/resources/ito/reports/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { Download } from "iconoir-react"
import React from "react"
import { downloadFile, handleErrors, saveBlobAsFile } from "utils/helpers"
import { trackingService } from "utils/tracking"

import styles from "./DomainReports.module.css"
import NotYetReady from "./NotYetReady"

const getAssets = (string?: string) => {
  return string
    ? {
        web: `/assets/ito/2023/AfterResultPhase/OverallReport/web/${string}.webp`,
        mobile: `/assets/ito/2023/AfterResultPhase/OverallReport/mobile/${string}.webp`,
        smallMobile: `/assets/ito/2023/AfterResultPhase/OverallReport/smallMobile/${string}.png`,
      }
    : undefined
}

const handleDownload = (
  e: { stopPropagation: () => void },
  pdf?: string | null
) => {
  e.stopPropagation()
  if (pdf) downloadFile(pdf, "Report.pdf")
}
const openCertificate = (pdf: string | null, domain: string | undefined) => {
  trackingService.trackEvent(
    "element_clicked",
    {
      meta: {
        element: "report_tile",
        domain: domain,
      },
      text: "",
      intent: "download_domain_report",
      page_url: window.location.href,
    },
    ["WebEngage"]
  )
  if (pdf) window.open(pdf, "_blank")
}

const WebCard = ({ data }: { data: ITOReport }) => {
  return (
    <button
      className={clsx(
        "overflow-hidden rounded-xl max-h-[261px] relative shadow-[0px_8px_25px_0px_rgba(0,0,0,0.15)]",
        styles.container
      )}
      onClick={() => {
        openCertificate(data.pdf, data.coreArea?.slug)
      }}
    >
      <img
        src={getAssets(data.coreArea?.slug)?.web}
        alt={`domain-${data.id}`}
        className="w-full h-full"
      />

      <Button
        fullWidth
        className={clsx(
          "absolute bottom-0 bg-onSurface-500 w-full left-0",
          styles.downloadButton
        )}
        onClick={(e: { stopPropagation: () => void }) => {
          handleDownload(e, data.pdf)
        }}
      >
        Download
      </Button>
    </button>
  )
}

const MobileCard = ({ data }: { data: ITOReport }) => {
  const themeMapping = {
    [CoreAreaSlug.CURRICULUM_PEDAGOGY_TECH_REPORT]: "!text-ito-peach-600",
    [CoreAreaSlug.STUDENT_ENGAGEMENT_REPORT]: "!text-ito-mint-600",
    [CoreAreaSlug.LEARNING_ENVIRONMENT_REPORT]: "!text-ito-iris-600",
    [CoreAreaSlug.LEARNING_ASSESSMENT_DATA_REPORT]: "!text-ito-lavender-600",
    [CoreAreaSlug.ETHICS_VALUES_REPORT]: "!text-ito-bubblegum-600",
    [CoreAreaSlug.TEACHER_GROWTH_AND_PROFESSIONAL_DEVELOPMENT]:
      "!text-ito-salmon-600",
  }
  return (
    <button
      className=" rounded-xl max-h-[261px] relative"
      onClick={() => {
        openCertificate(data.pdf, data.coreArea?.slug)
      }}
    >
      <img
        src={getAssets(data.coreArea?.slug)?.mobile}
        alt={`domain-${data.id}`}
        className="w-full h-full"
      />

      <IconButton
        onClick={(e: { stopPropagation: () => void }) => {
          handleDownload(e, data.pdf)
        }}
        size="sm"
        className={clsx(
          "absolute top-1 right-1",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          themeMapping[data.coreArea!.slug]
        )}
      >
        <Download />
      </IconButton>
    </button>
  )
}
const SmallMobileCard = ({ data }: { data: ITOReport }) => {
  const themeMapping = {
    [CoreAreaSlug.CURRICULUM_PEDAGOGY_TECH_REPORT]:
      "bg-ito-peach-100 border-[#F7DCC2]",
    [CoreAreaSlug.STUDENT_ENGAGEMENT_REPORT]:
      "bg-ito-mint-100 border-[#BAF1CA]",
    [CoreAreaSlug.LEARNING_ENVIRONMENT_REPORT]:
      "bg-ito-iris-100 border-[#AFC9FA]",
    [CoreAreaSlug.LEARNING_ASSESSMENT_DATA_REPORT]:
      "bg-ito-lavender-100 border-[#D2C2FB]",
    [CoreAreaSlug.ETHICS_VALUES_REPORT]:
      "bg-ito-bubblegum-100 border-[#F7CDEF]",
    [CoreAreaSlug.TEACHER_GROWTH_AND_PROFESSIONAL_DEVELOPMENT]:
      "bg-ito-salmon-100 border-[#F5BFBD]",
  }
  return (
    <button
      onClick={() => {
        openCertificate(data.pdf, data.coreArea?.slug)
      }}
      className="flex items-center justify-between w-full px-2 py-1 border rounded bg-surface-50 border-surface-100"
    >
      <div className="flex items-center">
        <div
          className={clsx(
            "mr-0.75 rounded-lg flex items-center justify-center shrink-0 border-2 border-solid w-[60px] h-[60px]",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            themeMapping[data.coreArea!.slug]
          )}
        >
          <img
            src={getAssets(data.coreArea?.slug)?.smallMobile}
            className="w-[38.175px] h-[40.959px] shrink-0"
            alt=""
          />
        </div>
        <Typography textAlign="left" variant="strong">
          {data.coreArea?.name}
        </Typography>
      </div>
      <IconButton
        onClick={(e: { stopPropagation: () => void }) => {
          handleDownload(e, data.pdf)
        }}
      >
        <Download width={20} height={20} className="text-interactive-500" />
      </IconButton>
    </button>
  )
}

const DomainReports = ({ data }: { data: ITOReport[] }) => {
  const isAvailable = data.length === 0
  const isCustomDown = useMediaQuery(
    "@media only screen and (max-width: 320px)"
  )

  const { mutate: downloadReports, isLoading } = useMutation({
    mutationFn: () =>
      api.profile.credentials.download({
        urlParams: {
          itemType: "olympiad_report",
          itemSlug: "international-teacher-olympiad-2023",
        },
      }),
    onSuccess: res => {
      if (res.__data) {
        saveBlobAsFile({
          data: res.__data?.data,
          type: "application/zip",
          name: `Reports.zip`,
        })
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const downloadAllReports = () => {
    trackingService.trackEvent(
      "element_clicked",
      {
        text: "Download All Plans",
        intent: "download_all_reports",
        page_url: window.location.href,
      },
      ["WebEngage"]
    )
    downloadReports()
  }

  if (isAvailable) {
    return <NotYetReady />
  }

  return (
    <div>
      <div className="mb-5">
        <div className="flex items-start justify-between mb-1">
          <Typography variant="title2" className="mr-1">
            Your Personalised Development Plans
          </Typography>
          {!isAvailable && (
            <Button
              size="sm"
              onClick={downloadAllReports}
              rounded
              startAdornment={<Download />}
              loading={isLoading}
              className="sm:!inline-block !hidden"
            >
              Download All Plans
            </Button>
          )}
        </div>
        <Typography color="onSurface.500" className="max-w-[717px]">
          Your monthly growth plans contain guidance and resources to help you
          achieve excellence in each pedagogy area. While you have access to all
          of your plans now, we will be focusing on one pedagogy area each month
          over the next 6 months. November will be focused on Curriculum,
          Pedagogy, & Technology.
        </Typography>
      </div>

      {isAvailable ? (
        <Typography variant="title2" textAlign="center">
          Your personalised reports are arriving here soon!
        </Typography>
      ) : (
        <>
          <div className="hidden sm:grid grid-cols-[repeat(3,minmax(0,185px))] justify-center gap-3">
            {data
              .sort((a, b) =>
                new Date(a.dateVisibleFrom) > new Date(b.dateVisibleFrom)
                  ? 1
                  : -1
              )
              .map((item, index) => (
                <WebCard data={item} key={index} />
              ))}
          </div>

          {!isCustomDown && (
            <div className="grid sm:hidden grid-cols-[repeat(3,minmax(0,185px))] justify-center gap-1">
              {data
                .sort((a, b) =>
                  new Date(a.dateVisibleFrom) > new Date(b.dateVisibleFrom)
                    ? 1
                    : -1
                )
                .map((item, index) => (
                  <MobileCard data={item} key={index} />
                ))}
            </div>
          )}

          {isCustomDown && (
            <div className="flex flex-col space-y-2 sm:hidden">
              {data
                .sort((a, b) =>
                  new Date(a.dateVisibleFrom) > new Date(b.dateVisibleFrom)
                    ? 1
                    : -1
                )
                .map((item, index) => (
                  <SmallMobileCard data={item} key={index} />
                ))}
            </div>
          )}
        </>
      )}

      {!isAvailable && (
        <Button
          rounded
          startAdornment={<Download />}
          className="!inline-block sm:!hidden mt-6"
          fullWidth
          onClick={downloadAllReports}
          loading={isLoading}
        >
          Download All Plans
        </Button>
      )}
    </div>
  )
}

export default DomainReports
