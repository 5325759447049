import { Typography } from "@suraasa/placebo-ui"
import { Assessment, AttemptStatus } from "api/resources/ito/assessments/types"
import clsx from "clsx"
import { format } from "date-fns"
import { getCalculateDuration } from "features/ITO/Assessments/helpers"
import GradientButton from "features/ITO/components/GradientButton"
import { useTestWindowOpen } from "features/ITO/utils/hooks/useRedirectTest"
import { Calendar, Check, Clock, PasteClipboard } from "iconoir-react"
import React from "react"
import { useTimer } from "react-timer-hook"

import IconBox from "./IconBox"

type Props = {
  data: Assessment
  isItoExam?: boolean
  onStart: (id: number, attemptId?: number) => void
  onAssessmentEnd: () => any
}

const Timer = ({
  startTime,
  onExpire,
  className,
  isTransparent = false,
  text,
}: {
  startTime: string
  onExpire?: () => void
  className?: string
  isTransparent?: boolean
  text?: string
}) => {
  const { hours, days, minutes, seconds } = useTimer({
    expiryTimestamp: new Date(startTime),
    ...(onExpire && { onExpire }),
  })

  if (hours === 0 && days === 0 && minutes === 0 && seconds === 0) return null

  let result = ""
  result += days > 0 ? `${days}d, ` : ""
  result += hours >= 0 ? `${hours}h, ` : ""
  result += minutes >= 0 ? `${minutes}m` : ""

  // We need to show a big counter up until 12 hours left for the olympiad
  if ((days === 0 && hours >= 12) || days > 0) {
    return (
      <Typography
        variant="subtitle2"
        className={clsx("shrink-0 block", className, {
          "!text-onSurface-500": isTransparent,
          "!text-white px-1 rounded": !isTransparent,
        })}
        style={{
          background: isTransparent ? undefined : "rgba(15, 23, 42, 0.75)",
        }}
      >
        {result}
      </Typography>
    )
  }

  // When less than 12 hours are remaining we show a small timer
  result = ""
  result += hours > 0 ? `${hours}hr : ` : ""
  result += minutes > 0 ? `${minutes}m : ` : ""
  result += seconds >= 0 ? `${seconds}s` : ""

  return (
    <Typography
      variant="subtitle2"
      className={clsx("shrink-0 block", className, {
        "!text-onSurface-500": isTransparent,
        "!text-white px-1 rounded": !isTransparent,
      })}
      style={{
        background: isTransparent ? undefined : "rgba(15, 23, 42, 0.75)",
      }}
    >
      {text}
      {result}
    </Typography>
  )
}

const LiveTestBanner = ({ data, isItoExam, onAssessmentEnd }: Props) => {
  const { openTestWindow } = useTestWindowOpen()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canStartOlympiad, setCanStartOlympiad] = React.useState(false)

  if (!data) {
    return null
  }

  const isCompleted = data?.attempt?.status === AttemptStatus.COMPLETED
  const isInProgress = data?.attempt?.status === AttemptStatus.IN_PROGRESS

  const getAction = () => {
    if (isCompleted) {
      return (
        <Typography
          variant="button"
          color="onSurface.500"
          className="flex-shrink-0"
        >
          <Check className="text-onSurface-500 inline h-2.5 w-2.5" />
          &nbsp; You have completed the olympiad
        </Typography>
      )
    }

    if (isInProgress) {
      return (
        <GradientButton
          className="!w-full md:!w-auto"
          // {...GA.trackElement("ito-live-test-resume-btn", {
          //   feature: "ito",
          //   purpose: "attempt_assessment",
          // })}
          onClick={() => {
            if (data?.attempt?.id) {
              openTestWindow({
                assessmentId: data.id,
                attemptId: data?.attempt.id,
                onTabClose: onAssessmentEnd,
              })
            }
          }}
        >
          Resume {isItoExam ? "Olympiad" : "Assessment"}
        </GradientButton>
      )
    }

    if (data?.attempt) {
      return (
        <div className="w-full sm:w-auto">
          <GradientButton
            className="!w-full md:!w-auto"
            onClick={() => {
              if (data?.attempt?.id) {
                openTestWindow({
                  assessmentId: data.id,
                  attemptId: data?.attempt.id,
                  onTabClose: onAssessmentEnd,
                })
              }
            }}
          >
            Enter the {isItoExam ? "Olympiad" : "Assessment"}
          </GradientButton>

          <Timer
            text="Starts in "
            isTransparent
            className="text-center mt-0.5"
            startTime={data.startTime}
            onExpire={() => setCanStartOlympiad(true)}
          />
        </div>
      )
    }
  }

  return (
    <div
      className="p-3 border rounded-lg md:flex md:justify-between border-onSurface-200"
      style={{
        background: isItoExam
          ? "linear-gradient(41deg, rgba(227,237,222,1) 0%, rgba(239,230,221,1) 18%, rgba(247,233,239,1) 46%, rgba(229,230,253,1) 100%, rgba(143,117,69,1) 100%)"
          : undefined,
        boxShadow: isItoExam
          ? undefined
          : "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div>
        <div className="flex justify-between w-full mb-3 md:hidden">
          <img
            src="https://assets.suraasa.com/ito/logos/ito-original-two-line.svg"
            alt="itoLogo"
            className="h-3"
          />
          {!isItoExam && <Timer startTime={data.startTime} />}
        </div>
        <img
          src="https://assets.suraasa.com/ito/logos/ito-original-one-line.svg"
          alt="itoLogo"
          className="hidden mb-3 md:block"
          width={300}
        />

        <Typography variant="body" className="-mt-2 sm:max-w-md md:max-w-lg">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {data.description}
        </Typography>
        <div className="flex flex-wrap gap-2 mt-2 md:gap-4">
          <IconBox
            heading="Time Limit"
            subheading={getCalculateDuration(data.duration)}
            Icon={Clock}
          />
          <IconBox
            heading="Questions"
            subheading={`${data.totalQuestions} MCQs`}
            Icon={PasteClipboard}
          />
        </div>
      </div>
      <div className="hidden md:block">
        {isItoExam ? (
          getAction()
        ) : (
          <>
            <Timer
              startTime={data.startTime}
              className="!text-white rounded text-center mb-1 ml-auto"
            />
            <Typography
              color="onSurface.400"
              textAlign="right"
              variant="largeBody"
            >
              Scheduled for <br />
              {format(new Date(data.startTime), "do MMM, yyyy")}
              <br />
              {format(new Date(data.startTime), "h:mm a")}
            </Typography>
          </>
        )}
      </div>
      <div className="mt-2 md:hidden">
        {isItoExam ? (
          getAction()
        ) : (
          <div className="flex items-center gap-1">
            <Calendar className="flex-shrink-0" />
            <Typography className="mt-0.25">
              {format(new Date(data.startTime), "do MMM yyyy, h:mm a")}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default LiveTestBanner
