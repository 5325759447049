import { LearningItem, VideoBookmark } from "api/resources/learningItems/types"
import { createContext } from "react"

import { SwitchItemOptions } from "./usePlanner"

type LearningModuleContext = {
  switchItem: (options: SwitchItemOptions) => Promise<void>
  hydrateStatusUpdate: (
    userPlannerItemId: number,
    status: LearningItem["status"]
  ) => void
  handleArticleBookmark: (articleId: string) => void
  addVideoBookmarkInState: (data: VideoBookmark) => void
  removeVideoBookmarkFromState: (id: string) => void
  enableVideoNextButton: () => void
  immersiveView: boolean
  setImmersiveView: (value: boolean) => void
  plannerList: LearningItem[] | undefined
}
const defaultValues: LearningModuleContext = {
  switchItem: async () => {},
  hydrateStatusUpdate: () => undefined,
  handleArticleBookmark: () => undefined,
  addVideoBookmarkInState: () => undefined,
  removeVideoBookmarkFromState: () => undefined,
  enableVideoNextButton: () => undefined,
  immersiveView: false,
  setImmersiveView: () => undefined,
  plannerList: undefined,
}

export const LearningModuleContext = createContext(defaultValues)
