import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("payment", {
  cart: () => ({
    queryKey: ["cart"],
  }),
  address: () => ({
    queryKey: ["savedAddresses"],
  }),
})
