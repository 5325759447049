import { Button, Typography } from "@suraasa/placebo-ui"
import { SkillEvidence } from "api/resources/profile/types"
import {
  SkillProfileRoutes,
  useGenerateURL as useSkillPortfolioNavigation,
} from "features/SkillProfile/hooks/useSkillProfileNavigation"
import React from "react"
import { useParams } from "react-router-dom"

import EvidenceList from "./EvidenceList"

const Evidences = ({
  skillEvidences,
  isSelf,
}: {
  isSelf: boolean
  skillEvidences: SkillEvidence[]
}) => {
  const { slug } = useParams() as { slug: string }

  const { navigate } = useSkillPortfolioNavigation()

  const course = skillEvidences.find(item => item.slug === slug)

  return (
    <div className="mt-2.5 px-3 pb-4">
      <Typography variant="title3" className="mb-2">
        Evidences
      </Typography>

      <EvidenceList
        canView
        data={
          (isSelf
            ? course?.evidences.filter(x => x.visibility === true)
            : course?.evidences) ?? []
        }
      />

      {isSelf && (
        <>
          <EvidenceList
            canView={isSelf}
            data={course?.evidences.filter(x => x.visibility === false) ?? []}
            isHiddenEvidence
          />

          <Button
            className="mx-auto"
            style={{
              display: "block",
            }}
            variant="text"
            onClick={() => {
              navigate(SkillProfileRoutes.EVIDENCE_SETTING, slug)
            }}
          >
            Edit Visibility
          </Button>
        </>
      )}
    </div>
  )
}

export default Evidences
