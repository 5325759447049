import { Typography } from "@suraasa/placebo-ui"
import { LearningItem } from "api/resources/learningItems/types"
import clsx from "clsx"
import { ReactComponent as CompletedIcon } from "features/LearningModule/assets/icons/check-circled-outline.svg"
import { ReactComponent as InProgressIcon } from "features/LearningModule/assets/icons/in-progress.svg"
// import { ReactComponent as WarningIcon } from "features/LearningModule/assets/icons/warning-triangle-outline.svg"
import { LearningModuleContext } from "features/LearningModule/context"
import { Bookmark, Lock, LockSlash } from "iconoir-react"
import React, { useContext, useEffect, useRef } from "react"

const getStatusIcon = (status: LearningItem["status"]): JSX.Element => {
  switch (status) {
    case "in_progress":
      return <InProgressIcon />
    case "completed":
      return <CompletedIcon />
    case "locked":
      return <Lock />
    case "unlocked":
      return <LockSlash />
    case "incomplete":
      return <LockSlash />
    // TODO: Handle this case where previous item is locked (ex: deleted a previous discussion)
    // case "error":
    // return <Warning />
  }
}

type Props = {
  item: LearningItem
  onItemSelect: () => void
  isActive?: boolean
}

const MilestoneItem = ({ item, onItemSelect, isActive }: Props) => {
  const { switchItem } = useContext(LearningModuleContext)
  const isLocked = item.status === "locked"

  const getTitle = (): string => {
    switch (item.learningContentType) {
      case "article":
      case "video":
      case "assessment":
        return item.title
      case "activity":
        return item.activityType
          ? `${item.title} (${item.activityType.replaceAll("_", " ")})`
          : item.title
      case "discussion":
        return item.discussionType === "post_discussion"
          ? "Start Discussion"
          : "Reply Discussion"

      // These case should ideally not get triggered
      case "class":
      case "class_schedule":
      case "assignment":
      case "course":
      case "assessment_schedule":
        throw new Error("Invalid content type in milestone items list")
    }
  }

  const isBookmarked =
    (item.learningContentType === "article" &&
      item.bookmarkArticle.length > 0) ||
    (item.learningContentType === "video" && item.bookmarkVideo.length > 0)

  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (isActive) {
      timeout = setTimeout(() => {
        if (ref.current)
          ref.current.scrollIntoView({ behavior: "smooth", block: "center" })
      }, 300)
    }

    return () => clearTimeout(timeout)
  }, [isActive])

  return (
    <button
      ref={ref}
      disabled={isLocked}
      className={clsx(
        "flex w-full gap-1 px-2 py-1 text-start first:pt-1.5 last:pb-1.5 hover:bg-highlight-50",
        {
          "hover:bg-inherit": isLocked,
          "bg-highlight-50": isActive,
        }
      )}
      onClick={() => {
        switchItem({ mode: "jump", userPlannerItemId: item.id })
        onItemSelect()
      }}
    >
      {getStatusIcon(item.status)}
      <div className="flex-1">
        <Typography
          variant="strongSmallBody"
          className="capitalize leading-none"
        >
          {getTitle()}
        </Typography>
        <Typography
          variant="smallBody"
          className="capitalize"
          color="onSurface.500"
        >
          {item.learningContentType}
        </Typography>
      </div>
      {isBookmarked && (
        <div className="shrink-0 self-center p-1">
          <Bookmark className="fill-onSurface-800" width={16} height={16} />
        </div>
      )}
    </button>
  )
}

export default MilestoneItem
