import {
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { OTPResponse } from "api/resources/settings/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import ResendOTPButton from "components/ResendOTPButton"
import GradientButton from "features/ITO/components/GradientButton"
import styles from "features/ITO/LinkReportsBanner/Linkreports.module.css"
import { Xmark } from "iconoir-react"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import OTPInput from "react-otp-input"
import { handleErrors } from "utils/helpers"

const useStyles = createUseStyles((theme: Theme) => ({
  containerStyle: {
    justifyContent: "space-between",
  },

  inputStyle: {
    borderRadius: 4,
    width: `41px !important`,
    height: 50,
    border: `2px solid ${theme.colors.onSurface[500]}`,
    ...theme.typography.title2,

    "&:focus": {
      outline: `2px solid ${theme.colors.interactive[400]}`,
      outlineOffset: "1px",
    },
  },
  errorStyle: {
    border: `2px solid ${theme.colors.critical[500]}`,
    color: theme.colors.critical[500],
  },
}))

const VerifyIdentity = ({
  title,
  otpData,
  close,
  open,
  onVerify,
  phoneNumber,
  email,
}: {
  title?: string
  otpData: OTPResponse
  close: () => void
  onVerify: (token: string) => void
  onBack: () => void
  phoneNumber: string
  email?: string
  open: boolean
}) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [otpResponse, setOtpResponse] = useState<OTPResponse>(otpData)

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ otp: string }>()

  if (!otpData) return null

  const onSubmit = handleSubmit(async ({ otp }) => {
    setLoading(true)
    try {
      const res = await api.settings.verifyOtp({
        data: {
          token: otpResponse.token,
          otp: otp,
        },
      })
      onVerify(res.token)
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
      }
    }
    setLoading(false)
  })

  const resendOtp = async () => {
    // Add gRecaptcha in future. Currently not being used.
    try {
      const res = await api.settings.sendOTP({
        data: {
          token: otpData.token,
        },
      })
      toast.info("We've sent you a new OTP")
      setOtpResponse(res)
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e)
      }
    }
  }

  return (
    <Dialog
      open={open}
      onAfterClose={() => reset()}
      onRequestClose={() => close()}
    >
      <DialogContent className={styles.dialogContent}>
        <div className={clsx(styles.whiteGradient, "absolute w-full h-full")} />
        <form onSubmit={onSubmit} className="px-1 sm:px-2">
          <div className="flex items-center justify-between">
            <img
              src="https://assets.suraasa.com/ito/logos/ito-original-two-line.svg"
              alt="smallLogo"
              className="pt-3"
              width="50%"
            />
            <IconButton className="mt-1.5" onClick={close}>
              <Xmark />
            </IconButton>
          </div>

          <Typography variant="title2" className="mt-3 mb-1 siz">
            {title ?? "Verify Phone Number"}
          </Typography>

          <Typography
            className="mb-2 whitespace-pre-wrap"
            color="onSurface.800"
          >
            OTP sent to{" "}
            {[
              phoneNumber ? <b key="phoneNumber">{phoneNumber}</b> : null,
              email && phoneNumber ? " and " : null,
              email ? <b key="email">{email}</b> : null,
            ]}
          </Typography>

          <Controller
            control={control}
            name="otp"
            render={({ field }) => (
              <OTPInput
                numInputs={6}
                {...field}
                containerStyle={classes.containerStyle}
                inputStyle={clsx(classes.inputStyle, {
                  [classes.errorStyle]: Boolean(errors.otp),
                })}
                inputType="number"
                renderInput={props => <input {...props} />}
                shouldAutoFocus
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          {Boolean(errors.otp) && (
            <Typography
              className="mt-1"
              color="critical.500"
              variant="smallBody"
            >
              {errors.otp?.message}
            </Typography>
          )}

          <div className="flex flex-wrap items-center mt-3">
            <Typography className="me-1 shrink-0">
              Didn’t receive OTP?
            </Typography>
            <ResendOTPButton
              resendAt={otpResponse.resendAt}
              text="Send Again"
              onClick={() => resendOtp()}
            />
          </div>

          <GradientButton
            fullWidth
            className="mb-[26px] mt-8"
            type="submit"
            loading={loading}
          >
            Verify
          </GradientButton>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default VerifyIdentity
