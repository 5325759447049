import {
  CircularProgress,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Award,
  Certification,
  Interest,
  LanguageResponse,
  Profile as ProfileType,
  Project,
  Publication,
  Qualification,
  SkillEvidenceOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "api/resources/profile/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
// import { GlobalContext } from "components/GlobalState"
import About from "features/Profile/components/About"
import Academics from "features/Profile/components/Academics"
import Achievements from "features/Profile/components/Achievements"
import Interests from "features/Profile/components/Interests"
import Languages from "features/Profile/components/Languages"
import Overview from "features/Profile/components/Overview"
import PersonalInfo from "features/Profile/components/PersonalInfo"
import SkillProfileOverview from "features/Profile/components/SkillProfileOverview"
import Subjects from "features/Profile/components/Subjects"
import WorkExperience from "features/Profile/components/WorkExperience"
import WorkLocationInterests from "features/Profile/components/WorkLocationInterests"
import { context } from "global/Context/context"
// import Navbar from "components/shared/Navbar"
import React, { useContext, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { handleErrors } from "utils/helpers"
// import { trackUser } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import CheckListBanner from "./components/CheckListBanner"
import ReOrderingFeatureOnboarding from "./components/ReOrderingFeatureOnboarding"
// import ReOrderingFeatureOnboarding from "./components/ReOrderingFeatureOnboarding"
import ProfileContext from "./context"
import PersonalDetailsSheet from "./Edit/PersonalDetailsSheet"
import VideoProfile from "./VideoProfile"
import AddVideoBanner from "./VideoProfile/AddVideoBanner"

const Profile = () => {
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const { fetchProfile } = useContext(context)
  const [hideVideoBanner, setHideVideoBanner] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  const { username } = useParams<{ username?: string }>()
  const isPublic = Boolean(username)

  const [profile, setProfile] = useState<ProfileType>({
    id: "",
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: null,
      middleName: "",
      username: "",
    },
  })

  const workExperiences = useArray<WorkExperienceType>([])
  const languages = useArray<LanguageResponse>([])
  const awards = useArray<Award>([])
  const projects = useArray<Project>([])
  const publications = useArray<Publication>([])
  const testScores = useArray<TestScore>([])
  const subjects = useArray<SubjectResponse>([])
  const qualifications = useArray<Qualification>([])
  const certifications = useArray<Certification>([])
  const workLocationInterest = useArray<WorkLocationInterest>([])
  const interests = useArray<Interest>([])
  const [skillEvidences, setSkillEvidences] = useState<SkillEvidenceOverview>({
    advancedSkillCount: 0,
    beginnerSkillCount: 0,
    intermediateSkillCount: 0,
    professional: 0,
  })
  const [videoPortfolioURL, setVideoPortfolioURL] = useState<string | null>(
    null
  )

  const [editProfile, setEditProfile] = useState(false)

  const [videoPortfolioDialogOpen, setVideoPortfolioDialogOpen] =
    useState(false)

  const handleUpdate = async () => {
    try {
      const profile = await api.profile.retrieve({
        urlParams: {
          username: username ?? "",
        },
      })

      setProfile(profile)
    } catch (e) {
      console.error(e)
    }
  }

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get("edit") === "true") {
      setEditProfile(true)
    }
  }, [searchParams])

  useEffect(() => {
    const fetchApi = (
      api: Promise<any>,
      onSuccess: (res: APIResponse<any>) => void,
      skipHandleError?: boolean
    ) => {
      api
        .then(res => {
          onSuccess(res)
        })
        .catch(err => {
          if (skipHandleError) {
            return
          }
          if (err instanceof APIError) {
            handleErrors(err)
          }
        })
    }

    const getData = async () => {
      const apis: {
        api: Promise<any>
        onSuccess: (res: APIResponse<any>) => void
        skipHandleError?: boolean
      }[] = [
        {
          api: api.profile.retrieve({
            urlParams: {
              username: username ?? "",
            },
          }),
          onSuccess(res) {
            setProfile(res)
          },
        },
        {
          api: api.profile.listAcademics({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            qualifications.set(res.qualifications)
            certifications.set(res.certifications)
          },
        },
        {
          api: api.profile.workExperiences.list({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            workExperiences.set(res)
          },
        },
        {
          api: api.profile.listAchievements({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            awards.set(res.awards)
            publications.set(res.publications)
            projects.set(res.projects)
            testScores.set(res.testScores)
          },
        },
        {
          api: api.profile.languages.list({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            languages.set(res)
          },
        },
        {
          api: api.profile.interests.list({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            workLocationInterest.set(res.preferredWorkLocations)
            subjects.set(res.subjects)
            interests.set(res.interests)
          },
        },
        {
          api: username
            ? api.profile.videoPortfolio.retrieve({
                urlParams: { username: username },
              })
            : api.profile.videoPortfolio.retrieveSelf(),
          onSuccess(res) {
            setVideoPortfolioURL(res.videoPortfolio)
          },
          skipHandleError: true,
        },
        {
          api: api.profile.skillEvidences.overview({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            setSkillEvidences(res)
          },
        },
      ]

      apis.forEach(item =>
        fetchApi(item.api, item.onSuccess, item.skipHandleError)
      )
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ProfileContext.Provider
      value={{
        isPublic,
        profile,
        updateProfile: newState => {
          setProfile(prevState => ({
            ...prevState,
            ...newState,
          }))
          fetchProfile()
        },
        languages: {
          data: languages.array,
          update: languages.updateByKey,
          remove: languages.removeByKey,
          add: languages.push,
        },
        achievements: {
          awards: {
            data: awards.array,
            update: awards.updateByKey,
            remove: awards.removeByKey,
            add: awards.push,
          },
          projects: {
            data: projects.array,
            update: projects.updateByKey,
            remove: projects.removeByKey,
            add: projects.push,
          },
          publications: {
            data: publications.array,
            update: publications.updateByKey,
            remove: publications.removeByKey,
            add: publications.push,
          },
          testScores: {
            data: testScores.array,
            update: testScores.updateByKey,
            remove: testScores.removeByKey,
            add: testScores.push,
          },
        },
        workExperiences: {
          data: workExperiences.array,
          update: workExperiences.updateByKey,
          remove: workExperiences.removeByKey,
          add: workExperiences.unshift,
        },
        academics: {
          qualifications: {
            data: qualifications.array,
            update: qualifications.updateByKey,
            remove: qualifications.removeByKey,
            add: qualifications.unshift,
          },
          certifications: {
            data: certifications.array,
            update: certifications.updateByKey,
            remove: certifications.removeByKey,
            add: certifications.unshift,
          },
        },
        subjects: {
          data: subjects.array,
          update: subjects.updateByKey,
          remove: subjects.removeByKey,
          add: subjects.push,
          set: subjects.set,
        },
        workLocationInterest: {
          data: workLocationInterest.array,
          update: workLocationInterest.updateByKey,
          remove: workLocationInterest.removeByKey,
          add: workLocationInterest.push,
          set: workLocationInterest.set,
        },
        interests: {
          data: interests.array,
          update: interests.updateByKey,
          remove: interests.removeByKey,
          add: interests.push,
        },
        skillEvidences,
      }}
    >
      {/* <Navbar gutterBottom={useMediaQuery(theme.breakpoints.up("md"))} /> */}

      {loading ? (
        <div className="mt-5 flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Container className="md:my-3" fullWidth={smDown}>
          <div className="mb-2 grid md:hidden">
            <Overview
              openVideoPortfolioDialog={() => {
                setVideoPortfolioDialogOpen(true)
              }}
              videoPortfolioURL={videoPortfolioURL}
              openEditProfile={() => {
                setEditProfile(true)
              }}
            />
          </div>
          <div className="grid grid-cols-12 sm:gap-2 md:gap-3">
            <div className="col-span-12 flex flex-col gap-2 sm:col-span-7 md:col-span-8">
              <section className="hidden md:block">
                <Overview
                  openVideoPortfolioDialog={() => {
                    setVideoPortfolioDialogOpen(true)
                  }}
                  videoPortfolioURL={videoPortfolioURL}
                  openEditProfile={() => {
                    setEditProfile(true)
                  }}
                />
              </section>
              {!videoPortfolioURL && !hideVideoBanner && !isPublic && (
                <AddVideoBanner
                  onCloseBanner={() => setHideVideoBanner(false)}
                  openVideoPortfolioDialog={() =>
                    setVideoPortfolioDialogOpen(true)
                  }
                />
              )}

              {!isPublic && (
                <CheckListBanner
                  handleOpenPersonalDetailsSheet={() => {
                    setEditProfile(true)
                  }}
                />
              )}

              <About />
              <Academics />
              <WorkExperience onUpdate={handleUpdate} />
              <Achievements />
            </div>

            <div className="col-span-12 flex flex-col gap-2 sm:col-span-5 md:col-span-4">
              <SkillProfileOverview />
              <Languages />
              <Subjects />
              <WorkLocationInterests />
              <Interests />
              <PersonalInfo />
            </div>
          </div>
        </Container>
      )}
      <VideoProfile
        handleClose={() => setVideoPortfolioDialogOpen(false)}
        openSheet={videoPortfolioDialogOpen}
        onUpload={url => {
          setVideoPortfolioURL(url)
        }}
        onDelete={() => {
          setVideoPortfolioURL(null)
        }}
        videoURL={videoPortfolioURL}
      />
      <ReOrderingFeatureOnboarding />
      <PersonalDetailsSheet
        openSheet={editProfile}
        handleOnClose={() => {
          setEditProfile(false)
        }}
      />
    </ProfileContext.Provider>
  )
}

export default Profile
