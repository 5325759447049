import SlidingSheet from "components/SlidingSheet"
import React, { useContext, useEffect, useState } from "react"

import ProfileContext from "../context"
import { Steps } from "./config"
import Introduction from "./Introduction"
import Upload from "./Upload"
import View from "./View"

type Props = {
  handleClose: () => void
  openSheet: boolean
  videoURL?: string | null
  onDelete: () => void
  onUpload: (url: string) => void
}

const VideoProfile = ({
  handleClose,
  openSheet,
  videoURL,
  onDelete,
  onUpload,
}: Props) => {
  const [step, setStep] = useState<Steps>(Steps.INTRO)
  const { isPublic } = useContext(ProfileContext)
  const [localURL, setLocalURL] = useState<string | null>(null)

  useEffect(() => {
    if (videoURL) {
      setStep(Steps.VIEW)
    } else {
      setStep(Steps.INTRO)
    }
  }, [videoURL])

  return (
    <SlidingSheet
      unmountOnExit
      containerClassName="grid grid-cols-12"
      className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-1 col-span-12 w-full"
      from="bottom"
      open={openSheet}
      onClose={handleClose}
    >
      {step === Steps.INTRO && !isPublic && (
        <Introduction
          onClose={handleClose}
          onContinue={() => {
            setStep(Steps.UPLOAD)
          }}
        />
      )}
      {step === Steps.UPLOAD && !isPublic && (
        <Upload
          onClose={handleClose}
          onUpload={(localURL, serverURL) => {
            setLocalURL(localURL)
            onUpload(serverURL)
          }}
        />
      )}
      {step === Steps.VIEW && (
        <View
          onClose={handleClose}
          videoURL={localURL ?? videoURL}
          handleUpdate={() => setStep(Steps.UPLOAD)}
          onDelete={() => {
            onDelete()
            handleClose()
          }}
        />
      )}
    </SlidingSheet>
  )
}

export default VideoProfile
