import { mergeQueryKeys } from "@lukemorales/query-key-factory"

import assessments from "./resources/assessments/queries"
import assignments from "./resources/assignments/queries"
import discussions from "./resources/discussions/queries"
import global from "./resources/global/queries"
import itoAssessments from "./resources/ito/assessments/queries"
import itoRegistrations from "./resources/ito/registrations/queries"
import itoReports from "./resources/ito/reports/queries"
import learningItems from "./resources/learningItems/queries"
import masterclasses from "./resources/masterclasses/queries"
import notifications from "./resources/notifications/queries"
import orders from "./resources/orders/queries"
import payment from "./resources/payment/queries"
import profile from "./resources/profile/queries"
import schedule from "./resources/schedule/queries"
import settings from "./resources/settings/queries"

export const queries = mergeQueryKeys(
  discussions,
  assignments,
  profile,
  global,
  masterclasses,
  settings,
  orders,
  learningItems,
  assessments,
  schedule,
  payment,
  itoAssessments,
  itoReports,
  itoRegistrations,
  notifications
)
