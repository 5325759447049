import axios from "api/axios"
import {
  APIResponse,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  Assignment,
  AssignmentAttachment,
  AssignmentList,
  ReviewRubric,
  Rubric,
  Submission,
  SubmissionsList,
} from "./types"
import { urls } from "./urls"

export default {
  list: async ({
    urlParams,
    params,
  }: ConfigWithURLParams): Promise<PaginatedAPIResponse<AssignmentList[]>> => {
    try {
      const res = await axios.get(
        urls.list(urlParams.learningItemType, urlParams.learningItemId),
        {
          params,
        }
      )
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  retrieve: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Assignment>> => {
    try {
      const res = await axios.get(urls.retrieve(urlParams.assignmentId), {
        params: {
          fields: "faqs",
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getRubrics: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Rubric[]>> => {
    try {
      const res = await axios.get(urls.getRubrics(urlParams.assignmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getReviewRubrics: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<ReviewRubric[]>> => {
    try {
      const res = await axios.get(urls.getReviewRubrics(urlParams.reviewId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getIQAReviewRubrics: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<ReviewRubric[]>> => {
    try {
      const res = await axios.get(urls.getIQAReviewRubrics(urlParams.reviewId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  previousSubmissionsList: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<SubmissionsList>> => {
    try {
      const res = await axios.get(urls.submissions(urlParams.assignmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  createSubmission: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Pick<Submission, "uuid">>> => {
    try {
      const res = await axios.post(urls.submissions(urlParams.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  editSubmission: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Pick<Submission, "uuid">>> => {
    try {
      const res = await axios.put(urls.retrieveSubmission(urlParams.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  retrieveSubmission: async ({
    urlParams,
    params,
  }: ConfigWithURLParams): Promise<APIResponse<Submission>> => {
    try {
      const res = await axios.get(
        urls.retrieveSubmission(urlParams.submissionId),
        { params }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getAttachment: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<AssignmentAttachment>> => {
    try {
      const res = await axios.get(urls.attachment(urlParams.attachmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  deleteAttachment: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.delete(urls.attachment(urlParams.attachmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
