import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Pause, SoundLow } from "iconoir-react"
import React, { useEffect, useState } from "react"

import { AudioStateType } from "./PronunciationComponent"

type Props = {
  text: string
  preRecordedFile?: string | null
  additionalDescription?: string | null
  highlightIndex: number[]
  variant: "primary" | "success" | "error"
  type: "sentence" | "words"
  audioState?: AudioStateType
  disableListenButton?: boolean
  setAudioState?: React.Dispatch<React.SetStateAction<AudioStateType>>
}

const textClasses = {
  primary: {
    bg: "bg-primary-50",
    default: "text-primary-800",
    highlight: "bg-primary-800 text-white",
  },
  success: {
    bg: "bg-success-50",
    default: "text-success-800",
    highlight: "!text-success-800",
  },
  error: {
    bg: "bg-surface-100",
    default: "!text-success-800",
    highlight: "bg-critical-800 text-white",
  },
} as const

const listenEndAdornment: Record<NonNullable<AudioStateType>, JSX.Element> = {
  play: <Pause className="text-white" />,
  pause: <SoundLow className="text-white" />,
  ended: <SoundLow className="text-white" />,
} as const

function TextPreview({
  type,
  text: rawText,
  highlightIndex,
  variant,
  preRecordedFile,
  audioState,
  setAudioState,
  additionalDescription,
  disableListenButton = false,
}: Props) {
  const [audio, setAudio] = useState<HTMLAudioElement>()

  const playRecording = () => {
    if (!audio) {
      throw new Error("Response file is not available")
    }

    audio.play()
  }
  const pauseRecording = () => {
    if (!audio) {
      throw new Error("Response file is not available")
    }

    audio.pause()
  }

  useEffect(() => {
    if (preRecordedFile) {
      setAudio(new Audio(preRecordedFile))
    } else {
      setAudio(undefined)
    }
  }, [preRecordedFile])

  useEffect(() => {
    if (!setAudioState) return
    const playListener = () => setAudioState("play")
    const pauseListener = () => setAudioState("pause")
    const endedListener = () => setAudioState("ended")
    audio?.addEventListener("play", playListener)
    audio?.addEventListener("pause", pauseListener)
    audio?.addEventListener("ended", endedListener)

    return () => {
      // Pause the playback on unmount
      if (!audio?.paused) {
        audio?.pause()
      }
      audio?.removeEventListener("play", playListener)
      audio?.removeEventListener("pause", pauseListener)
      audio?.removeEventListener("ended", endedListener)
    }
  }, [audio, setAudioState])

  useEffect(() => {
    if (audioState === "pause") {
      audio?.pause()
    }
  }, [audio, audioState])

  const text = rawText.split(" ")

  return (
    <div className="flex flex-col items-center">
      <Typography className="self-start" variant="strong">
        {additionalDescription}
      </Typography>
      <div className="flex flex-col items-center space-y-1 md:flex-row md:space-x-2 md:space-y-0">
        <div className={clsx("rounded-xl p-2", textClasses[variant].bg)}>
          <Typography variant="title2">
            {text.map((word, i) => {
              return (
                <React.Fragment key={i}>
                  <span
                    key={i}
                    className={clsx({
                      [textClasses[variant].default]:
                        !highlightIndex.includes(i),
                      [textClasses[variant].highlight]:
                        highlightIndex.includes(i),
                      "rounded-lg font-semibold px-0.75 py-0.25":
                        highlightIndex.includes(i),
                    })}
                  >
                    {word}
                    {i !== text.length - 1}
                  </span>{" "}
                </React.Fragment>
              )
            })}
          </Typography>
        </div>
        {preRecordedFile && (
          <Button
            endAdornment={listenEndAdornment[audioState ?? "ended"]}
            size="sm"
            rounded
            color="black"
            disabled={disableListenButton}
            className="bg-black hover:!bg-gray-700"
            onClick={audioState === "play" ? pauseRecording : playRecording}
          >
            Listen
          </Button>
        )}
      </div>
      {/* {delayStarted && !isRecording && (
        <div className="flex items-center mt-4 h-18">
          <CountdownCircleTimer
            isPlaying={true}
            duration={recordingDelay / 1000}
            colors={["#3852C5", "#3852C5"]}
            colorsTime={[0, 1]}
            strokeWidth={6}
          >
            {({ remainingTime }) => (
              <Typography variant="title3" className="text-center">
                Recording <br />
                starts in {remainingTime}
              </Typography>
            )}
          </CountdownCircleTimer>
        </div>
      )} */}
      {/* {isRecording && (
        <div className="flex items-center mt-4 h-18">
          <CountdownCircleTimer
            isPlaying={true}
            duration={perWordRecordingTime}
            colors={["#3852C5", "#3852C5"]}
            colorsTime={[0, 1]}
            strokeWidth={6}
            onComplete={() => {
              if (index < totalWords) {
                return {
                  shouldRepeat: true,
                  newInitialRemainingTime: perWordRecordingTime,
                }
              }
            }}
          >
            {() => (
              <Typography variant="title2">
                {sentence.split(" ")[index]}
              </Typography>
            )}
          </CountdownCircleTimer>
        </div>
      )} */}
      {/* {!haveRecording && !isRecording && !delayStarted && (
        <div className="mt-4 h-18"></div>
      )} */}
    </div>
  )
}

export default TextPreview
