import {
  IconButton,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { Xmark } from "iconoir-react"
import { PropsWithChildren } from "react"

type Props = {
  handleClose: () => void
}

const TopBar = ({ children, handleClose }: PropsWithChildren<Props>) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <>
      <div className="flex items-center justify-between sm:py-2 pt-2 pb-0 pl-1 pr-2">
        {isXsDown ? (
          <>
            <IconButton onClick={handleClose} color="black" size="xs">
              <Xmark />
            </IconButton>
            {children}
          </>
        ) : (
          <>
            <div className="flex items-center">
              <IconButton
                onClick={handleClose}
                color="black"
                className="me-0.5"
              >
                <Xmark />
              </IconButton>
              <Typography variant="title3">Video Portfolio</Typography>
            </div>
            {children}
          </>
        )}
      </div>
      {/* {!isXsDown && <Divider weight="light" />} */}
    </>
  )
}

export default TopBar
