import {
  CircularProgress,
  Divider,
  Theme,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { ClaimStatus } from "api/resources/ito/referAndEarn/types"
import clsx from "clsx"
import NoDataCard from "components/NoDataCard"
import { format } from "date-fns"
import { RefreshDouble, WarningCircle } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

const useStyles = createUseStyles((theme: Theme) => ({
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
    position: "absolute",
    left: "-28px",
  },

  mobileTable: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    "& hr": {
      margin: theme.spacing(0, 1),
      background: theme.colors.onSurface[500],
      width: "0.5px",
    },
  },
  mobileTableRoot: {
    padding: theme.spacing(2, 1),
  },
  mobileTableDivider: {
    margin: theme.spacing(0, -2),
  },
}))

const getTag = (
  status: ClaimStatus
): {
  color: "highlight" | "success" | "critical" | "primary"
  text: string
  tooltip: string | null
  icon?: JSX.Element
} => {
  switch (status) {
    case ClaimStatus.SUCCESSFUL:
      return {
        color: "success",
        text: "Success",
        tooltip: null,
      }
    case ClaimStatus.REQUESTED:
      return {
        color: "primary",
        text: "Requested",
        tooltip: "Please fill in your bank details or UPI ID for payout.",
      }
    case ClaimStatus.IN_PROGRESS:
      return {
        color: "highlight",
        text: "Processing",
        tooltip:
          "Payment may take upto 24-48 hours to reflect into your bank account.",
        icon: <RefreshDouble />,
      }
    case ClaimStatus.FAILED:
      return {
        color: "critical",
        text: "Failed",
        tooltip: null,
        icon: <WarningCircle />,
      }

    default:
      return {
        color: "primary",
        text: "",
        tooltip: null,
      }
  }
}
type Props = {
  forceUpdate: number
}

const CreditHistory = ({ forceUpdate }: Props) => {
  const classes = useStyles()

  const {
    isLoading,
    data,
    isSuccess,
    hasNextPage,
    isFetching,
    fetchNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: ["claimHistory", forceUpdate],
    queryFn: x =>
      api.ito.referAndEarn.listClaimHistory({
        params: {
          type: "reward",
          page: x.pageParam || 1,
          source: "ito_referral_reward_2023",
        },
      }),

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  if (isLoading) {
    return (
      <div className="flex justify-center mt-5 w-full">
        <CircularProgress />
      </div>
    )
  }

  if (isSuccess && data.pages[0].total === 0) {
    return (
      <NoDataCard className="mt-3 w-full" message="No credit history found" />
    )
  }
  if (error) {
    return (
      <NoDataCard
        className="mt-3 w-full"
        message="We are having some trouble fetchng your history, try again later"
      />
    )
  }

  return (
    <div
      style={{
        paddingTop: "4px",
        paddingBottom: "20px",
        width: "min(100%,400px)",
      }}
    >
      <Typography variant="title3" className="mb-2">
        History
      </Typography>

      {data?.pages.map((page, pageNumber) =>
        page.data.map(
          (
            { id, amount, currency, referenceNumber, requestedAt, status },
            index
          ) => {
            const PAGE_SIZE = 10
            // Doing +1 because index starts from zero
            const currentIndex = pageNumber * PAGE_SIZE + (index + 1)

            // Doing -1 because page length should start from 0
            const totalLoadedItems =
              (data.pages.length - 1) * PAGE_SIZE + page.data.length

            const isLastItem = currentIndex === totalLoadedItems
            const tag = getTag(status)

            return (
              <div key={id} ref={isLastItem ? ref : null}>
                <Divider
                  className={classes.mobileTableDivider}
                  color="onSurface.300"
                  weight="light"
                />
                <div key={id} className={classes.mobileTableRoot}>
                  <div className={classes.mobileTable}>
                    <div>
                      {/* TODO: update this text once backend returns data */}
                      <Typography variant="strong">
                        Withdrawal Requested
                      </Typography>
                      <div className="flex">
                        <Typography variant="smallBody">
                          {format(new Date(requestedAt), "d MMM yyyy '• '")}
                        </Typography>
                        <Typography variant="smallBody">
                          {referenceNumber}
                        </Typography>
                      </div>
                    </div>

                    <div>
                      {/* {tag.tooltip && (
                        <Tooltip title={tag.tooltip} maxWidth="400px">
                          <span className={classes.icon}>
                            <InfoCircle
                              color={theme.colors[tag.iconColor][500]}
                            />
                          </span>
                        </Tooltip>
                      )} */}
                      <Typography
                        variant="subtitle2"
                        textAlign="right"
                      >{`${currency.symbol} ${amount}`}</Typography>
                      <div className="flex gap-0.5 items-center">
                        {tag.icon &&
                          React.cloneElement(tag.icon, {
                            className: clsx("h-2 w-2", {
                              "text-critical-500": tag.color === "critical",
                              "text-highlight-500": tag.color === "highlight",
                            }),
                          })}
                        <Typography
                          color={`${tag.color}.500`}
                          variant="smallBody"
                        >
                          {tag.text}
                        </Typography>
                      </div>
                      {/* <Tag color={tag.color} label={tag.text} /> */}
                    </div>
                  </div>
                </div>
                {/* {status === ClaimStatus.REQUESTED && (
                  <Button
                    style={{
                      marginBottom: "8px",
                    }}
                    fullWidth
                    size="sm"
                    onClick={() =>
                      window.open(`${additionalDetails?.payoutLink}`, "_blank")
                    }
                  >
                    Continue
                  </Button>
                )} */}
                {/* Show divider for last item only */}
                {isLastItem && (
                  <Divider
                    className={classes.mobileTableDivider}
                    color="onSurface.300"
                    weight="light"
                  />
                )}
              </div>
            )
          }
        )
      )}
      {isFetching && (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default CreditHistory
