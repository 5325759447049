import React from "react"

const JobsSearch = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70008 3.66635V1.86626H11.7727V3.66635H6.70008ZM13.2454 1.71899V3.66635H15.4545C16.9456 3.66635 18.1544 4.87516 18.1544 6.36631V8.49369C18.1544 8.90037 17.8248 9.23005 17.4181 9.23005C17.0114 9.23005 16.6817 8.90037 16.6817 8.49369V6.36631C16.6817 5.68852 16.1323 5.13905 15.4545 5.13905H12.5091H5.96373H3.01832C2.34053 5.13905 1.79107 5.68852 1.79107 6.36631V13.8939C1.79107 14.5717 2.34053 15.1211 3.01832 15.1211H10.2188C10.6255 15.1211 10.9552 15.4508 10.9552 15.8575C10.9552 16.2642 10.6255 16.5938 10.2188 16.5938H3.01832C1.52717 16.5938 0.318359 15.385 0.318359 13.8939V6.36631C0.318359 4.87516 1.52717 3.66635 3.01832 3.66635H5.22737V1.71899C5.22737 0.986974 5.82079 0.393555 6.55281 0.393555H11.92C12.652 0.393555 13.2454 0.986972 13.2454 1.71899ZM13.5037 9.47578C12.3224 9.47578 11.3648 10.4334 11.3648 11.6148C11.3648 12.7962 12.3224 13.7538 13.5037 13.7538C14.0956 13.7538 14.6301 13.5144 15.0182 13.1253C15.4048 12.7376 15.6426 12.2047 15.6426 11.6148C15.6426 10.4334 14.685 9.47578 13.5037 9.47578ZM9.89206 11.6148C9.89206 9.62011 11.509 8.00305 13.5037 8.00305C15.4984 8.00305 17.1153 9.62011 17.1153 11.6148C17.1153 12.3452 16.898 13.0257 16.5252 13.594L17.8589 14.9278C18.1465 15.2154 18.1465 15.6816 17.8589 15.9692C17.5713 16.2567 17.1051 16.2567 16.8175 15.9691L15.484 14.6356C14.9154 15.0089 14.2346 15.2265 13.5037 15.2265C11.509 15.2265 9.89206 13.6095 9.89206 11.6148Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default JobsSearch
