import clsx from "clsx"
import React from "react"

type Props = {
  background: string
  className?: string
}

const ImageBackgroundCard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  background,
  className,
}) => {
  return (
    <div className={clsx("relative overflow-hidden", className)}>
      <div
        className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-auto scale-150 -z-[2] blur "
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <div
        className="absolute top-0 left-0 w-full h-full -z-[1]"
        style={{ background: "rgba(0,0,0,0.3)" }}
      />
      {children}
    </div>
  )
}

export default ImageBackgroundCard
