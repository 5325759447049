import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Divider,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { APIError } from "api/utils"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

function ChangePasswordDialog({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) {
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{
    oldPassword: string
    newPassword: string
    confirmPassword: string
  }>()

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    try {
      await api.settings.changePassword({
        data,
      })
      setLoading(false)
      toast.success("Password changed successfully", {
        autoClose: 3000,
        pauseOnHover: false,
      })
      close()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
        setLoading(false)
      }
    }
  })

  return (
    <Dialog open={open} width={518} onAfterClose={reset} onRequestClose={close}>
      <DialogTitle>Change Password</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <div className="flex flex-col pt-1.75 pb-5 sm:px-1">
            <TextField
              placeholder="Enter Current Password"
              error={Boolean(errors.oldPassword)}
              helperText={errors.oldPassword?.message}
              label="Current Password"
              type="password"
              fullWidth
              {...register("oldPassword", {
                required: { value: true, message: "Required" },
              })}
            />

            <Divider color="onSurface.200" weight="light" className="my-4" />

            <TextField
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword?.message}
              label="Create New Password"
              placeholder="Enter New Password"
              type="password"
              fullWidth
              {...register("newPassword", {
                required: { value: true, message: "Required" },
              })}
            />
            <Typography
              variant="smallBody"
              color="onSurface.500"
              className="mt-0.5"
            >
              New password must be at least 6 characters long, and contain at
              least one special character and one number.
            </Typography>
            <TextField
              placeholder="Re-enter New Password"
              className="mt-2"
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
              label="Confirm New Password"
              type="password"
              fullWidth
              {...register("confirmPassword", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Submit",
              type: "submit",
              loading,
            },
            secondary: {
              label: "Cancel",
              onClick: close,
            },
          }}
        />
      </form>
    </Dialog>
  )
}

export default ChangePasswordDialog
