import { ValueOf } from "utils/types"

export enum ITOReportType {
  OVERALL = 1,
  DOMAIN,
}

export const CoreAreaSlug = {
  CURRICULUM_PEDAGOGY_TECH_REPORT: "curriculum-pedagogy-and-technology", // october
  STUDENT_ENGAGEMENT_REPORT: "student-engagement", // november
  LEARNING_ENVIRONMENT_REPORT: "learning-environment", // december
  LEARNING_ASSESSMENT_DATA_REPORT: "learning-assessment-data", // january
  ETHICS_VALUES_REPORT: "ethics-and-values", // february
  TEACHER_GROWTH_AND_PROFESSIONAL_DEVELOPMENT:
    "teacher-growth-professional-development", // march
} as const

export type ITOReport = {
  id: number
  reportType: ITOReportType
  coreArea: {
    id: number
    name: string
    slug: ValueOf<typeof CoreAreaSlug>
    number: string
  } | null
  dateVisibleFrom: string
  pdf: string | null
}
