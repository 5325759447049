import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { popupWindow } from "components/ShareSheet"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import React from "react"
import { createUseStyles } from "react-jss"
import { getPlatformURL } from "utils/helpers"

import LinkedInIcon from "./assets/linkedin-icon.svg"
import LinkedInPost from "./assets/linkedin-post.png"

const useStyles = createUseStyles(theme => ({
  container: {
    border: "1px solid #F8FAFC",
    boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: 16,
    background:
      "linear-gradient(80deg, rgba(255,255,255,1) 45%, rgba(204,186,250,0.5) 75%, rgba(245,198,236,0.25) 100%)",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto",
    alignItems: "center",
    gap: "0px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      gap: "30px",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto auto",
      background:
        "linear-gradient(80deg, rgba(204,186,250,0.5) 0%, rgba(245,198,236,0.35) 100%)",
    },
  },
}))

const ShowcaseDedication = ({ referralCode }: { referralCode: string }) => {
  const classes = useStyles()

  const shareURL = getPlatformURL("suraasa", `/ito/?referral=${referralCode}`)
  const LINKEDIN_URL = `https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`

  return (
    <div className={clsx(classes.container)}>
      <div className="px-4 sm:py-2 md:py-0 pb-3">
        <SwitzerTypography
          className="!text-ito-midnight mb-1 font-bold"
          style={{
            fontFamily: "Switzer Bold",
          }}
          variant="title2"
        >
          Showcase Your Dedication to Professional Growth
        </SwitzerTypography>
        <Typography
          className="!text-ito-midnight sm:max-w-[349px] w-full block mb-4"
          variant="largeBody"
        >
          Share your commitment to continuous improvement and garner recognition
          from your peers and employers.
        </Typography>
        <Button
          rounded
          className="!bg-[#0A66C2]"
          startAdornment={<img src={LinkedInIcon} alt="linkedin" />}
          onClick={() => {
            popupWindow(LINKEDIN_URL, 650, 650)
          }}
        >
          Share Now
        </Button>
      </div>
      <div className="sm:row-auto row-[1]">
        <div
          className={clsx(
            "md:h-[338px] sm:h-[243px] h-[224px] relative rounded-2xl overflow-hidden"
          )}
        >
          <img
            alt="linkedin-post"
            className="absolute h-full sm:pt-3 pt-2 object-contain bottom-0 sm:w-full w-[435px]"
            src={LinkedInPost}
          />
        </div>
      </div>
    </div>
  )
}

export default ShowcaseDedication
