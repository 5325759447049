import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getCastorURL = getServiceURL(SERVICE_PREFIX.castor)
const getPhotonURL = getServiceURL(SERVICE_PREFIX.photon)

export const urls = validateUrls({
  startActivity: () => getPhotonURL(`/v1/attempts/`),
  getItemResponse: itemResponseId =>
    getPhotonURL(`/v1/attempts/item-responses/${itemResponseId}/`),
  submitActivity: attemptId => getPhotonURL(`/v1/attempts/${attemptId}/`),
  markActivityAnswer: attemptId =>
    getPhotonURL(`/v1/attempts/${attemptId}/item-response/`),
  overview: (itemType, itemId) =>
    `/v1/${itemType}/${itemId}/users/planners/overview/`,
  incompleteItems: (itemType, itemId) =>
    getCastorURL(`/v1/planners/${itemType}/${itemId}/users/items/incomplete/`),

  getDetails: (itemType, itemSlug) =>
    `/catalogue/learning-items/${itemType}/${itemSlug}/`,

  listClasses: (learningItemType, learningItemId) =>
    `v1/${learningItemType}/${learningItemId}/classes/`,
  listPlanner: () => `/v1/users/planners/`,
  listPlannerItems: plannerId =>
    `/v1/course/${plannerId}/users/planners/items/`,
  getLastAccessedItem: () => `/v1/users/planners/last-accessed/`,

  getLastUnlockedItem: learningItemId =>
    `/v1/course/${learningItemId}/users/planners/items/latest/`,
  getNextItem: userPlannerItemId =>
    `/v1/users/planners/items/${userPlannerItemId}/next/`,
  getPreviousItem: userPlannerItemId =>
    `/v1/users/planners/items/${userPlannerItemId}/previous/`,
  getItemById: userPlannerItemId =>
    `/v1/users/planners/items/${userPlannerItemId}/`,
  getArticle: milestoneId => `/article/${milestoneId}/milestones/`,

  updateUserPlannerItem: userPlannerItemId =>
    `/castor/v1/planners/users/items/${userPlannerItemId}/`,

  articleBookmarks: () => `/saved/bookmark-articles/`,
  articleBookmarkDetail: (courseId, articleId) =>
    `/saved/bookmark-articles/${courseId}/${articleId}/`,

  videoBookmarks: () => `/saved/bookmark-videos/`,
  videoBookmarkDetail: id => `/saved/bookmark-videos/${id}/`,

  enrollmentsList: () => `/v1/users/enrollments/`,

  upcomingItems: (learningItemType, learningItemId) =>
    `/v1/${learningItemType}/${learningItemId}/users/planners/items/upcoming/`,
  listResources: (learningItemType, learningItemId) =>
    `/course/${learningItemType}/${learningItemId}/additional-resources/`,
})
