import { Button, Select, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { State } from "api/resources/global/types"
import { SavedAddress } from "api/resources/payment/types"
import { APIError } from "api/utils"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"

export type NewAddress = {
  name: string
  address: string | null
  pincode: string | null
  city: string
  country: string
  state: string
}

type Props = {
  onAdd: (address: SavedAddress) => void
}

const AddNewAddress = (props: Props) => {
  const [states, setStates] = useState<State[]>([])

  const {
    register,
    control,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<NewAddress>()

  const fetchStates = async (countryId: string | undefined) => {
    if (!countryId) return
    const res = await api.global.listStates({
      urlParams: {
        countryId,
      },
      params: {
        page: -1,
      },
    })
    if (res.isSuccessful) {
      setStates(res.data)
    }
  }

  const createBillingAddress = async (data: NewAddress) => {
    try {
      const res = await api.payment.createAddress({ data })
      reset()
      props.onAdd(res)
    } catch (e) {
      if (e instanceof APIError) handleErrors(e, { setter: setError })
    }
  }

  const onSubmit = handleSubmit(data => {
    createBillingAddress(data)
  })

  const { countries } = useResource(["countries"])

  return (
    <form className="my-3" onSubmit={onSubmit}>
      <Typography variant="title3">New Address</Typography>
      <TextField
        inputLabelProps={{ required: true }}
        className="my-3"
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
        placeholder="Ex: Home"
        label="Name of Billing Address"
        fullWidth
        {...register("name", {
          required: { value: true, message: "Required" },
          maxLength: {
            value: 300,
            message: "Try to keep it short",
          },
        })}
      />
      {/* @ts-expect-error random-types-issue */}
      <TextField
        inputLabelProps={{ required: true }}
        className="mb-3"
        rows={3}
        error={Boolean(errors.address)}
        helperText={errors.address?.message}
        placeholder="Building number, street name"
        label="Address"
        multiLine
        fullWidth
        {...register("address", {
          required: { value: true, message: "Required" },
        })}
      />

      <div className="flex items-start justify-between gap-2">
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, onBlur } }) => (
            <Select
              error={Boolean(errors.country)}
              getOptionLabel={obj => obj.name}
              getOptionValue={obj => String(obj.uuid)}
              helperText={errors.country?.message}
              inputLabelProps={{ required: true }}
              label="Country"
              options={countries}
              placeholder="Ex: India"
              fullWidth
              mountOnBody
              onBlur={onBlur}
              onChange={newValue => {
                onChange(newValue?.uuid)
                fetchStates(newValue?.uuid)
              }}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, onBlur } }) => (
            <Select
              error={Boolean(errors.state)}
              getOptionLabel={obj => obj.name}
              getOptionValue={obj => String(obj.uuid)}
              helperText={errors.state?.message}
              inputLabelProps={{ required: true }}
              label="State"
              options={states}
              placeholder="Ex: Delhi"
              fullWidth
              mountOnBody
              onBlur={onBlur}
              onChange={val => {
                onChange(val?.uuid)
              }}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </div>
      <div className="flex items-start justify-between gap-2 mt-2">
        <TextField
          inputLabelProps={{ required: false }}
          placeholder="Ex: New Delhi"
          label="City"
          fullWidth
          error={Boolean(errors.city)}
          helperText={errors.city?.message}
          {...register("city", { required: false })}
        />
        <TextField
          inputLabelProps={{ required: true }}
          placeholder="Ex: 680025"
          label="Pincode"
          fullWidth
          error={Boolean(errors.pincode)}
          helperText={errors.pincode?.message}
          {...register("pincode", {
            required: { value: true, message: "Required" },
          })}
        />
      </div>
      <Button fullWidth type="submit" className="mt-5">
        Save
      </Button>
    </form>
  )
}

export default AddNewAddress
