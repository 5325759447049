import { PriceCategories } from "features/ITO/utils/utils"
import { getPlatformURL } from "utils/helpers"

export const getData = (getPrice: (key: PriceCategories<"2023">) => string) => {
  return [
    {
      category: "ITO & Its Relevance",
      question: "What is this ITO all about?",
      answer:
        "The International Teachers' Olympiad (ITO) is an opportunity for teachers to assess their pedagogical skills.\n\nDuring the Olympiad, you undergo a one-hour online assessment. Based on your performance, you receive a pedagogy report to track your progress year-on-year & growth plans to become even better educators.\n\nYou can attempt the Olympiad without the fear of getting judged. Your report will be 100% confidential & only accessible to you.",
      whatsapp: "Yes",
      keywords: "What is ITO, ITO details",
    },
    {
      category: "ITO & Its Relevance",
      question: "Who is conducting the ITO?",
      answer: `The Olympiad is being organised by Suraasa, world's leading platform for teacher upskilling and growth. The team has trained teachers from 47+ nationalities, conducted over 3,05,000+ teacher assessments and delivered over 100+ million minutes of learning in the past few years.\n\nTo learn more about Suraasa, you can visit our website by <a href="${getPlatformURL(
        "suraasa",
        "/about-us"
      )}">clicking here</a>`,
      whatsapp: "Yes",
      keywords: "organiser",
    },
    {
      category: "ITO & Its Relevance",
      question: "Is ITO organised anywhere else?",
      answer:
        "No, the 'International Teachers' Olympiad' is exclusively organised by Suraasa annually.",
      whatsapp: "Yes",
      keywords: "when ITO started",
    },
    {
      category: "ITO & Its Relevance",
      question: "How do I get recognised after ITO?",
      answer: `After ITO, you get recognition at various levels:\n\n1) As a participant: Certificate of Participation\n2) As a Top 30 Percentile / Top 10 Percentile / Top 1 Percentile scorer: Certificate and Badges of Merit\n3) Top 100 Teachers Globally: Cash Prize of ${getPrice(
        "cashPrizeBronze"
      )} + Certificate of Excellence + Media Recognition`,
      whatsapp: "Yes",
      keywords: "recognition",
    },
    {
      category: "ITO & Its Relevance",
      question: "Why should I participate in ITO?",
      answer: `The International Teachers' Olympiad (ITO) identifies the strengths of teachers and those aspects of their knowledge and skills which could be further developed. Each participant gets a comprehensive personalised pedagogy report for their professional development & growth. It gives you a chance to move onto the next level of your professional journey with data-based insights and a comprehensive 6-months career growth plan. You also have a chance to win cash prizes upto ${getPrice(
        "cashPrizeFirst"
      )}.`,
      whatsapp: "Yes",
      keywords: "participate",
    },
    {
      category: "ITO & Its Relevance",
      question:
        "Is there any approving/regulatory body or it is a generic teacher proficiency Olympiad? (Who endorses)",
      answer:
        "The Olympiad is being organised and hosted by Suraasa, a prominent and recognised teacher career growth platform. Suraasa's teacher upskilling programmes, qualifications, and teacher skill frameworks are recognised and endorsed by many educational authorities, including Ofqual UK.",
      whatsapp: "Yes",
      keywords: "about ito, olympiad",
    },
    {
      category: "ITO & Its Relevance",
      question: "Is ITO an online assessment?",
      answer:
        "Yes, teachers need to attempt the Olympiad online on their respective mobile phones/laptop/PC by signing into the Suraasa portal with their registered email address and mobile number.",
      whatsapp: "Yes",
      keywords: "online, offline",
    },
    {
      category: "ITO & Its Relevance",
      question: "What are the topics/syllabus for the Olympiad?",
      answer:
        "The ITO framework will assess a teacher on 31 indicators developed around 6 domains:\nEthics And Values\nCurriculum, Pedagogy And Technology\nStudent Engagement\nLearning Environment\nLearning Assessment & Data\nTeacher Growth And Professional Development\n\nIt benefits you the most when you attempt it unprepared to know where you stand at present & get an unbiased, fair pedagogy report.",
      whatsapp: "Yes",
      keywords: "syllabus",
    },
    {
      category: "ITO & Its Relevance",
      question: "Is the ITO report valid for a lifetime?",
      answer:
        "The relevance of the report and recommendations would majorly remain for only 1 year. Once you put the provided recommendations in practice, you will definitely evolve as an educator & hence your score will shift. \nWe conduct ITO every year so that you can measure how you are growing year by year.",
      whatsapp: "Yes",
      keywords: "validity",
    },
    {
      category: "ITO & Its Relevance",
      question: "Do you guarantee that the Olympiad is fair and impartial?",
      answer:
        "We understand that the results of the Olympiad will only be reliable when it is conduced fairly. We are taking multiple steps to protect the academic integrity of the Olympiad and prevent any sort of cheating.",
      whatsapp: "Yes",
      keywords: "results, judgement criteria",
    },
    {
      category: "About registration",
      question: "How can I register?",
      answer: `The registration process for ITO is simple and online. It's a simple 3 step process:\n1) Enter your contacts details\n2) Pay the registration fee\n3) Answer some questions about your career to help us personalise your report\n\nTo register, you can <a href="${getPlatformURL(
        "suraasa",
        "/ito/register"
      )}">clicking here</a>`,
      whatsapp: "Yes",
      keywords: "register, how to register",
    },
    {
      category: "About registration",
      question: "How do I pay my registration fee?",
      answer:
        "The registration process for ITO is simple and online. It's a simple 3 step process:\n1) Enter your contacts details\n2) Pay the registration fee\n3) Answer some questions about your career to help us personalise your report\n\nPaying your registration fee is Step 2 of the registration process. You can pay via various digital payment methods like Debit Cards, Credit Cards, Mobile Wallets, Net Banking, UPI (only for India) among others.",
      whatsapp: "Yes",
      keywords: "fee, pay fee, registration fee",
    },
    {
      category: "About registration",
      question: "Can I use someone else's email address to register?",
      answer:
        "No, you cannot use someone else's email address to register. You can use your official work email but you should be the only one who should have access to that email address. Your email would be used to create your Suraasa account and to share sensitive information with you.",
      whatsapp: "Yes",
      keywords: "register, how to register",
    },
    {
      category: "About registration",
      question: "When is the deadline for registration?",
      answer: "The deadline to register is 30th September 2023.",
      whatsapp: "Yes",
      keywords: "deadline, registration deadline",
    },
    {
      category: "About registration",
      question: "What is the registration fee for ITO?",
      answer: `The fee to register yourself for ITO is ${getPrice(
        "registrationFee"
      )}. This is the only amount you will be paying throughout your journey. No hidden or extra charges.`,
      whatsapp: "Yes",
      keywords: "fee, pay fee, registration fee",
    },
    {
      category: "About registration",
      question: "How do I get confirmation if my registration is successful?",
      answer:
        "Upon successful completion of Registration, you will receive a confirmation email and WhatsApp message along with your registration number. In case you have registered but can't find the confirmation, you can email to <a href='mailto:care@teachersolympiad.com'>care@teachersolympiad.com</a>.",
      whatsapp: "Yes",
      keywords: "confirmation, fee paid",
    },
    {
      category: "About registration",
      question: "Can I get a discount on registration fees?",
      answer:
        "Yes, you can get a referee discount if you register through a link shared by your friend(s). Additionally, if your friend/colleague registers using your unique referral link that you get after registration, you get a referral bonus.",
      whatsapp: "Yes",
      keywords: "discount, fee",
    },
    {
      category: "About registration",
      question: "Can I register offline?",
      answer:
        "No, you cannot register offline for the olympiad. You can only register online for the International Teachers’ Olympiad.",
      whatsapp: "Yes",
      keywords: "offline, register, registration",
    },
    {
      category: "Attempting the Olympiad",
      question:
        "What is the marking/grading scheme for ITO? Is there negative marking for a wrong answer?",
      answer:
        "You will be answering questions from different domains in pedagogy with different weightage. There would be no negative marking. In the report, you will get a percentile score.",
      whatsapp: "Yes",
      keywords: "checking, marking, negative marking",
    },
    {
      category: "Attempting the Olympiad",
      question:
        "Can the Olympiad fee be refunded if I do not take the Olympiad?",
      answer:
        "No. Once you've paid the registration fee, it won't be refunded.",
      whatsapp: "Yes",
      keywords: "refund, fee",
    },
    {
      category: "Attempting the Olympiad",
      question: "I have registered, now what is the next step?",
      answer:
        "That's perfect. We have planned a series of initiatives to keep supporting your growth even before the Olympiad:\n\n1. Refer & Earn Programme: Invite your teacher friends to register via your unique link. Get rewarded when they complete their registration.\n\n2. Social Media Handles for Regular Updates:  Follow the Olympiad social media pages for regular updates.",
      whatsapp: "Yes",
      keywords: "registered, details",
    },
    {
      category: "Attempting the Olympiad",
      question: "Is the Olympiad an MCQ or descriptive?",
      answer: "The Olympiad will be an MCQ-based assessment.",
      whatsapp: "Yes",
      keywords: "test, olympiad questions, questions",
    },
    {
      category: "Attempting the Olympiad",
      question: "What would be the timings of the Olympiad?",
      answer:
        "The Olympiad will be held on 7th of October 2023. Timings are yet to be announced.",
      whatsapp: "Yes",
      keywords: "time, olympiad time",
    },
    {
      category: "Attempting the Olympiad",
      question: "How and where do I take the Olympiad?",
      answer:
        "You can attempt the Olympiad through the Suraasa mobile app or website on your phone / tablet / PC.",
      whatsapp: "Yes",
      keywords: "where, how to take test, mobile, laptop, desktop",
    },
    {
      category: "Attempting the Olympiad",
      question: "Is there any offline centre where I can attempt the Olympiad?",
      answer:
        "No, you can attempt the olympiad online only. In case you have internet connectivity issues, you can attempt the Olympiad from a cyber cafe.",
      whatsapp: "Yes",
      keywords: "offline centre, olympiad centre",
    },
    {
      category: "Attempting the Olympiad",
      question: "Is there any sample question paper for the Olympiad?",
      answer:
        "No, there are no sample papers for the Olympiad. We recommend that you come without any extra preparation for the Olympiad, in order to get a fair and unbiased report of teaching potential.",
      whatsapp: "Yes",
      keywords: "sample paper",
    },
    {
      category: "Attempting the Olympiad",
      question: "Would I need pen and paper while attempting the Olympiad?",
      answer:
        "No, you won't need pen and paper during the Olympiad. It is completely online.",
      whatsapp: "Yes",
      keywords: "pen, paper, olympiad",
    },
    {
      category: "Eligibility",
      question: "What is the eligibility to participate?",
      answer:
        "Any Aspiring teacher, in-service teacher or a retired teacher is eligible to participate. The olympiad is being conducted to recognise the teaching potential, passion, & scope of professional development in teachers of all age groups & levels.",
      whatsapp: "Yes",
      keywords: "eligibility, participate",
    },
    {
      category: "Eligibility",
      question:
        "I don't have B.Ed or any teaching experience. Am I eligible to apply?",
      answer:
        "Yes, you are eligible to apply if you have passion to teach & want to take teaching as a profession in the future. The Olympiad will help you identify the next step with a comprehensive analysis report and a 6-month career growth plan based on it.",
      whatsapp: "Yes",
      keywords: "apply, eligibility",
    },
    {
      category: "Eligibility",
      question:
        "I am a college teacher/private tuition teacher, Am I eligible to participate?",
      answer: "Yes, College teachers and private tutors can also participate.",
      whatsapp: "Yes",
      keywords: "eligibility, participate, tuition, college teacher",
    },
    {
      category: "Eligibility",
      question: "Is there any age restriction for taking the Olympiad?",
      answer:
        "There is no age restriction for you to be eligible to participate.",
      whatsapp: "Yes",
      keywords: "age limit",
    },
    {
      category: "Eligibility",
      question:
        "I am a teacher but my medium of language is not English. Can I take the Olympiad?",
      answer:
        "Sorry. Currently, we only support English as a language in the Olympiad.",
      whatsapp: "Yes",
      keywords: "english, english medium",
    },
    {
      category: "Result & Report",
      question: "What are the dates of the Olympiad & results?",
      answer:
        "The Olympiad would be held on 7th October 2023 and the result will be announced on 31st October 2023 (tentatively).",
      whatsapp: "Yes",
      keywords: "date, olympiad date, result",
    },
    {
      category: "Result & Report",
      question: "Will you share my Olympiad Report with my school?",
      answer:
        "No, we will not share your Olympiad report with your school.\nIt will remain confidential and we will share the report with only you. Schools will get a separate report with aggregated, overall score of all their teachers combined. That report will not have any personal information linked to the teachers.",
      whatsapp: "Yes",
      keywords: "report, olympiad report",
    },
    {
      category: "Result & Report",
      question: "What is the qualifying score?",
      answer:
        "There would be no qualifying score. Each teacher would get their score in percentile to determine where they stand as a teacher on the state, national & multinational front. This is not a regular test but a tool for reflection, self-evaluation, and growth.",
      whatsapp: "Yes",
      keywords: "score, olympiad score",
    },
    {
      category: "Result & Report",
      question: "How will I get my certificate of participation?",
      answer:
        "You will receive a certificate of participation if you attempt the Olympiad. It will be available on your ITO Dashboard on Suraasa platform once the results are announced.",
      whatsapp: "Yes",
      keywords: "certificate",
    },
    {
      category: "Result & Report",
      question: "What is the amount of the prize money?",
      answer: `Top 100 teachers from ITO win cash prizes of ${getPrice(
        "cashPrizeBronze"
      )} each, with a chance to win further cash prizes upto ${getPrice(
        "cashPrizeFirst"
      )} by participating in Teacher Impact Awards.`,
      whatsapp: "Yes",
      keywords: "amount, prize money",
    },
    {
      category: "Result & Report",
      question: `How can I win the ${getPrice("cashPrizeFirst")} prize?`,
      answer: `The 100 candidates will get a direct entry into Suraasa’s Teacher Impact Awards, with the final winner winning a cash prize of ${getPrice(
        "cashPrizeFirst"
      )}.\n\nTeacher Impact Awards is a global initiative by Suraasa to give teachers their well-deserved credit & recognition by sharing their success stories with the world.`,
      whatsapp: "Yes",
      keywords: "amount, prize money",
    },
    {
      category: "Result & Report",
      question: "Will I get a job after the Olympiad?",
      answer:
        "There is no direct relationship between attempting the olympiad and getting a job. However, the olympiad will help you recognise your areas of strength & scope where you can work upon to get a teaching job faster & at a higher pay scale.",
      whatsapp: "Yes",
      keywords: "job",
    },
    {
      category: "Result & Report",
      question: "When & how shall we get the result?",
      answer:
        "Your result (report) will be accessible on your ITO Dashboard on 31st October 2023 (tentative). You will be notified about the reports release via email, WhatsApp, and the ITO chat group on Suraasa community.",
      whatsapp: "Yes",
      keywords: "results, judgement criteria",
    },
  ]
}
