import { Button, Menu, MenuItem, Typography } from "@suraasa/placebo-ui"
import { MainColors } from "@suraasa/placebo-ui/dist/cjs/utils/createColors"
import { Review, REVIEW_STATUS } from "api/resources/assignments/types"
import RubricsPopup from "features/Assignments/RubricsPopup"
import VerificationProcess from "features/Assignments/VerificationProcess"
import { InfoCircle } from "iconoir-react"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Paper from "../Paper"
import TagWithAdornment from "../TagWithAdornment"

type Props = {
  review: Review | null
  iqaReview?: Review | null
  hideAction?: boolean
  useDialogForRubrics?: boolean
}

const getScoreProps = (review: Review) => {
  const label = review.marks != null ? `Score: ${review.marks.toFixed(1)}` : ""
  const color: MainColors = "primary"

  return { label, color }
}

const getStatusTagProps = (
  review: Review
): { label: string; color?: MainColors } => {
  switch (review.status) {
    case REVIEW_STATUS.accepted:
      return { label: "Accepted", color: "success" }
    case REVIEW_STATUS.rejected:
      return { label: "Rejected", color: "critical" }
    case REVIEW_STATUS.underReview:
      return { label: "Under Review", color: "secondary" }
    case REVIEW_STATUS.notReviewed:
      return { label: "Not Reviewed", color: "secondary" }
    case REVIEW_STATUS.plagiarised:
      return { label: "Plagiarised", color: "critical" }
  }
}

const getComments = (review: Review) => review.comments

const ProfessorReviewCard = ({
  review,
  iqaReview,
  hideAction,
  useDialogForRubrics,
}: Props) => {
  const navigate = useNavigate()
  const { assignmentId, submissionId } = useParams() as {
    assignmentId: string
    submissionId: string
  }

  const [showRubrics, setShowRubrics] = useState(false)
  const [showVerificationProcess, setShowVerificationProcess] = useState(false)

  const isRejected =
    iqaReview?.status === REVIEW_STATUS.rejected ||
    review?.status === REVIEW_STATUS.rejected ||
    review?.status === REVIEW_STATUS.plagiarised ||
    iqaReview?.status === REVIEW_STATUS.plagiarised

  const isAccepted =
    iqaReview?.status === REVIEW_STATUS.accepted ||
    review?.status === REVIEW_STATUS.accepted

  if (review === null || (!isAccepted && !isRejected)) {
    return (
      <Paper className="bg-interactive-50 sm:border border-t border-b border-interactive-100 flex items-center">
        <InfoCircle className="shrink-0 me-1 text-interactive-500" />
        <Typography variant="strongSmallBody" color="interactive.900">
          Your submission is currently under review
        </Typography>
      </Paper>
    )
  }

  return (
    <div>
      {iqaReview?.status === REVIEW_STATUS.underReview && (
        <Paper className="bg-interactive-50  sm:border border-t border-b border-interactive-100 flex items-center mb-3">
          <InfoCircle className="shrink-0 me-1 text-interactive-500" />
          <Typography variant="strongSmallBody" color="interactive.900">
            Your submission is currently under Internal Quality Assurance (IQA).
            You can learn more about IQA{" "}
            <button
              onClick={() => setShowVerificationProcess(true)}
              className="underline underline-offset-2"
            >
              here.
            </button>
          </Typography>
        </Paper>
      )}
      <Paper>
        <Button
          onClick={() => setShowRubrics(true)}
          size="sm"
          className="!inline-block sm:!hidden mb-2"
          fullWidth
        >
          View Rubric
        </Button>
        <div className="flex items-center justify-between mb-2">
          <div className="flex gap-2">
            <TagWithAdornment {...getScoreProps(iqaReview || review)} />
            <Button
              size="sm"
              className="!hidden sm:!inline-block"
              onClick={() => setShowRubrics(true)}
            >
              View Rubrics
            </Button>
          </div>
          <TagWithAdornment {...getStatusTagProps(iqaReview || review)} />
        </div>
        <Typography variant="title4" className="mb-1">
          Professor’s Remarks
        </Typography>
        <Typography>{getComments(iqaReview || review)}</Typography>

        {!hideAction && isRejected && (
          <Menu
            menuButton={
              <Button size="sm" className="mt-1.5" variant="outlined">
                Re-attempt Skill Evidence (Assignment)
              </Button>
            }
          >
            <MenuItem
              onClick={() => {
                navigate(`/assignments/${assignmentId}`)
              }}
            >
              Start from Scratch
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(
                  `/assignments/${assignmentId}?copyFrom=${submissionId}`
                )
              }}
            >
              Copy from Previous Submission
            </MenuItem>
          </Menu>
        )}
      </Paper>

      {review && (
        <RubricsPopup
          mode={useDialogForRubrics ? "dialog" : "sheet"}
          open={showRubrics}
          onClose={() => setShowRubrics(false)}
          review={review}
          iqaReview={iqaReview || undefined}
        />
      )}

      <VerificationProcess
        open={showVerificationProcess}
        onClose={() => setShowVerificationProcess(false)}
      />
    </div>
  )
}

export default ProfessorReviewCard
