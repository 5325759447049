import { context } from "global/Context/context"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

const ACCOUNTS_ORIGIN = import.meta.env.VITE_SSO_URL

const Logout = () => {
  const { logout } = useContext(context)

  const navigate = useNavigate()

  useEffect(() => {
    const iframe = document.getElementById(
      "accounts-sso-iframe"
    ) as HTMLIFrameElement

    let timeout: ReturnType<typeof setTimeout>
    const listener = (e: MessageEvent<any>) => {
      if (e.data.logoutSuccess) {
        clearTimeout(timeout)
        handleLogout()
      }
    }
    window.addEventListener("message", listener, false)

    if (iframe) {
      iframe.contentWindow?.postMessage({ logout: true }, ACCOUNTS_ORIGIN)

      // setTimeout is a fallback (if the logoutSuccess is not received until 2 seconds)
      timeout = setTimeout(() => {
        handleLogout()
      }, 5000)
    } else {
      logout()
      // This is just a fallback for cases where iframe might not load
      // TODO: Setup code processing when web-accounts redirects us back to this app.
      window.location.href = `${ACCOUNTS_ORIGIN}/logout?platform=Student&origin=${encodeURI(
        window.location.origin
      )}`
    }

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogout = () => {
    logout()
    navigate(routes.login)
  }
  return null
}

export default Logout
