import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import styles from "./Step1.module.css"

type Props = {
  onClickRateCourse: () => void
  onClickNotNow: () => void
}

const textList: { text: string; highlight?: boolean }[] = [
  { text: "clear" },
  { text: "interesting" },
  { text: "engaging" },
  { text: "infomational" },
  { text: "awesome" },
  { text: "intuitive" },
  { text: "nice" },
  { text: "fun" },
  { text: "helpful", highlight: true },
]

const Step1 = ({ onClickRateCourse, onClickNotNow }: Props) => {
  return (
    <div
      className={clsx(
        "px-2 pt-2 pb-1 overflow-hidden flex justify-between flex-col relative",
        styles.container
      )}
    >
      <span className={clsx("px-0.5 py-0.25 w-fit", styles.feedbackTag)}>
        Feedback
      </span>
      <div className="absolute w-full top-0 left-0">
        <div className="relative w-full">
          {textList.map((item, index) => (
            <span
              key={index}
              className={clsx(
                "absolute py-1.5 px-2",
                styles.floatingTagFont,
                styles[item.text],
                {
                  [styles.floatingTag]: !item.highlight,
                  [styles.floatingTagHighlight]: item.highlight,
                }
              )}
            >
              {item.text}
            </span>
          ))}
        </div>
      </div>

      <div>
        <Typography variant="title3" color="common.white.500" className="mb-2">
          Did you like this course? Please take some time out to rate it.
        </Typography>
        <Button
          fullWidth
          className={clsx("mb-1", styles.rateButton)}
          onClick={onClickRateCourse}
        >
          Rate this Course
        </Button>
        <Button
          color="white"
          variant="text"
          className="mx-auto"
          style={{
            display: "block",
          }}
          onClick={onClickNotNow}
        >
          Not Now
        </Button>
      </div>
    </div>
  )
}

export default Step1
