import { Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Check } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    border: "1px solid #E2E8F0",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: 12,
    display: "grid",
    gridAutoFlow: "column",
    [theme.breakpoints.down("sm")]: {
      gridAutoFlow: "row",
    },
  },
  checkSymbolContainer: {
    width: 20,
    height: 20,
    color: theme.colors.success[500],
    borderRadius: 2,
  },
  title: {
    "& .title1": {
      color: theme.colors.onSurface[800],
    },
    "& .title2": {
      color: theme.colors.onSurface[500],
    },
    "& .title3": {
      color: theme.colors.onSurface[200],
    },
  },
}))

const CheckBox = ({ item }: { item: string }) => {
  const classes = useStyles()

  return (
    <div className="grid grid-flow-col gap-1.5 justify-start mb-2.5">
      <div
        className={clsx(
          "grid justify-center items-center",
          classes.checkSymbolContainer
        )}
      >
        <Check />
      </div>
      <Typography>{item}</Typography>
    </div>
  )
}
const AchieveMore = () => {
  const classes = useStyles()

  const data = [
    "Share your success on social media",
    "Update your resume",
    "Take it your school newsletter/magazine",
    "Connect with your regional publication and garner media coverage",
  ]
  return (
    <div className="sm:mb-8 mb-3">
      <Typography className={clsx("mb-1", classes.title)} variant="title1">
        <span className="title1">Achieve More.</span>{" "}
        <span className="title2">And More.</span>{" "}
        <span className="title3">And More.</span>
      </Typography>
      <Typography
        color="onSurface.800"
        style={{ maxWidth: 531 }}
        className="mb-3"
        variant="subtitle1"
      >
        You’ve achieved so much and you should be very proud. It’s time you go
        beyond.
      </Typography>
      <div className="mb-4">
        {data.map((item, index) => (
          <CheckBox key={index} item={item} />
        ))}
      </div>
      <Typography className="mb-3" variant="strong">
        You’re amazing. Let’s tell everyone!
      </Typography>
    </div>
  )
}

export default AchieveMore
