import { Button, theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import Confetti from "assets/Course/confetti.json"
import { ReactComponent as Wave1 } from "assets/Course/wave-1.svg"
import { ReactComponent as Wave2 } from "assets/Course/wave-2.svg"
import clsx from "clsx"
import React from "react"
import Lottie from "react-lottie"
import { Link } from "react-router-dom"
import { getPlatformURL } from "utils/helpers"

import styles from "./CompletedCourseCard.module.css"
type Props = { className?: string; serialNumber: string }

const CompletedCourseCard = ({ className, serialNumber }: Props) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <div
      className={clsx(
        "!cursor-default sm:p-3 p-0 flex sm:flex-row flex-col  justify-between items-center overflow-hidden rounded-lg relative",
        styles.containerBackground,
        className
      )}
    >
      <div className="absolute z-[2] !cursor-default">
        <Lottie
          isClickToPauseDisabled
          style={{
            cursor: "default",
          }}
          options={{
            loop: true,
            autoplay: true,
            animationData: Confetti,
          }}
        />
      </div>

      <Wave1 className="absolute right-0 z-[1]" />
      <Wave2 className="absolute right-0" />

      <Typography
        className="sm:mb-0 mb-2 sm:mx-0 sm:mt-0 mx-2 mt-2.5 z-[3]"
        variant="title3"
        color="success.500"
      >
        Congratulations! You have {!isXsDown && <br />}
        completed the course
      </Typography>
      <Button
        className={clsx("z-[4]", styles.viewCertificate)}
        size="sm"
        component={Link}
        to={getPlatformURL("suraasa", `/verify/${serialNumber}`)}
        fullWidth={isXsDown}
      >
        View Certificate
      </Button>
    </div>
  )
}

export default CompletedCourseCard
