import {
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import DiscussionItem from "features/Discussions/DiscussionItem"
import Post from "features/Discussions/Post"
import { ArrowLeft, Xmark } from "iconoir-react"
import React, { useEffect, useRef, useState } from "react"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import styles from "./Discussions.module.css"

type Props = {
  open: boolean
  handleClose: () => void
  params?: { userPlannerItemId: number; discussion: number; course: string }
}

const DiscussionSlidingSheet = ({ open, handleClose, params }: Props) => {
  const [discussionId, setDiscussionId] = useState("")

  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleSetDiscussionId = (id: string) => {
    sessionStorage.setItem(
      BROWSER_STORAGE_KEYS.replyDiscussionSheetScrollPosition,
      JSON.stringify(containerRef.current?.scrollTop)
    )
    setDiscussionId(id)
  }

  const courseId = params?.course ?? null

  const { isFetching, hasNextPage, fetchNextPage, data } = useInfiniteQuery({
    queryKey: queries.discussions.list(courseId ?? undefined).queryKey,
    queryFn: x => {
      return api.discussions.list({
        params: { page: x.pageParam || 1, course: courseId },
      })
    },
    onError: err => {
      if (err instanceof APIError) {
        console.log(err.statusCode)
        console.log(err.is500)
        console.log(err.__errors)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  useEffect(() => {
    if (!discussionId) {
      const scrollPosition = sessionStorage.getItem(
        BROWSER_STORAGE_KEYS.replyDiscussionSheetScrollPosition
      )
      if (scrollPosition) {
        containerRef.current?.scrollTo(0, parseInt(scrollPosition, 10))
        sessionStorage.removeItem(
          BROWSER_STORAGE_KEYS.replyDiscussionSheetScrollPosition
        )
      }
    }
  }, [discussionId])

  return (
    <SlidingSheet
      containerClassName="grid grid-cols-12"
      className={clsx(
        "md:col-start-3 md:col-span-8  col-start-1 col-span-12 w-full",
        styles.overflowHidden
      )}
      open={open}
      onClose={handleClose}
    >
      <div>
        <div className="flex items-center py-2 bg-white z-30 px-2">
          <IconButton
            className="me-0.75"
            aria-label="close"
            color="black"
            size="sm"
            onClick={() => {
              if (discussionId) {
                setDiscussionId("")
              } else {
                handleClose()
              }
            }}
          >
            {discussionId ? <ArrowLeft /> : <Xmark />}
          </IconButton>
          <Typography variant="title3">
            {discussionId ? "Back to Discussions" : "Reply to a discussion"}
          </Typography>
        </div>
        <div
          ref={containerRef}
          className="sm:px-5 px-0  h-[75vh] overflow-auto"
        >
          {discussionId ? (
            <Container className={styles.container} fullWidth>
              <DiscussionItem
                discussionId={discussionId}
                additionalData={params}
                courseId={courseId || undefined}
              />
            </Container>
          ) : (
            <>
              {data?.pages.map((page, pageNumber) =>
                page.data.map((discussion, index) => {
                  const PAGE_SIZE = 10
                  // Doing +1 because index starts from zero
                  const currentIndex = pageNumber * PAGE_SIZE + (index + 1)

                  // Doing -1 because page length should start from 0
                  const totalLoadedItems =
                    (data.pages.length - 1) * PAGE_SIZE + page.data.length

                  const isLastItem = currentIndex === totalLoadedItems

                  return (
                    <div key={discussion.id} ref={isLastItem ? ref : null}>
                      <Post
                        additionalData={params}
                        data={discussion}
                        className="mb-2"
                        interactionHandler={handleSetDiscussionId}
                        courseId={courseId}
                      />
                    </div>
                  )
                })
              )}
            </>
          )}
          {isFetching && (
            <div className="text-center">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </SlidingSheet>
  )
}

export default DiscussionSlidingSheet
