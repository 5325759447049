import { useEffect, useRef, useState } from "react"

type Props<T> = {
  data: T[]
}

const useDraggable = <T,>({ data }: Props<T>) => {
  const [items, setItems] = useState<T[]>([])

  useEffect(() => {
    if (data.length > 0) {
      setItems(data)
    }
  }, [data])

  const draggedItemIndex = useRef<number | null>(null)

  const reset = () => {
    if (data.length > 0) setItems(data)
  }

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    draggedItemIndex.current = index
  }

  const onDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    if (draggedItemIndex.current !== null) {
      const draggedItem = items[draggedItemIndex.current]

      const allItems = [...items]
      allItems.splice(draggedItemIndex.current, 1)
      allItems.splice(index, 0, draggedItem)

      setItems(allItems)
    }
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault() // Necessary for drop to work
  }
  function processData<
    G extends boolean,
    R = G extends true ? { id: number; sequence: number }[] : T[]
  >(shallowCopy?: G): R {
    if (shallowCopy) {
      return items.map((obj, index) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore it is safe to do because every T will have id
        id: obj.id,
        sequence: index + 1,
      })) as R
    }

    return items.map((obj, index) => ({
      ...obj,
      sequence: index + 1,
    })) as R
  }
  return {
    data: items,
    onDragStart,
    onDrop,
    onDragOver,
    reset,
    setItems,
    processData,
  }
}

export default useDraggable
