import { useEffect, useState } from "react"

const calculateWords = (text: string) =>
  text.split("\n").join(" ").split("\r").join(" ").split(" ").filter(Boolean)
    .length

type Props = {
  initialValue: string
}

export const useWordCountText = ({ initialValue }: Props) => {
  const [value, setValue] = useState(initialValue)
  const [wordCount, setWordCount] = useState(calculateWords(value))

  useEffect(() => {
    setWordCount(calculateWords(value))
  }, [value])

  return { value, setValue, wordCount }
}
