import { Typography } from "@suraasa/placebo-ui"
import { ReviewRubric, Rubric } from "api/resources/assignments/types"
import clsx from "clsx"
import React from "react"
import { pluralize } from "utils/helpers"

import styles from "./CriteriaWiseScore.module.css"

type Comment = Rubric["allComments"][number]

const sort = (a: Comment, b: Comment) => (a.scale > b.scale ? 1 : -1)

const CriteriaWiseScore = (rubric: Rubric | ReviewRubric) => {
  return (
    <div className="cursor-default">
      <Typography
        variant="strong"
        className="first-letter-capitalize mb-2 mt-3"
      >
        {rubric.criteria}
      </Typography>

      <div className="grid sm:grid-cols-3 gap-1 min-w-[180px]">
        {rubric.allComments.sort(sort).map((point, index) => (
          <div
            key={index}
            className={clsx(
              "bg-white border-onSurface-200 border rounded-lg p-2",
              styles.pointCard,
              {
                [styles.pointCardSelected]:
                  "marked" in rubric && point.uuid === rubric.marked.uuid,
              }
            )}
          >
            <Typography variant="title4" className="mb-[10px]">
              {pluralize("Point", point.scale)}
            </Typography>
            <Typography className="first-letter-capitalize">
              {point.comments}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CriteriaWiseScore
