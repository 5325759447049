import { Theme } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  card: {
    width: "50%",
    lineHeight: "32px",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",

    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const CardWrapper = ({
  content,
}: {
  content: (className: string) => JSX.Element
}) => {
  const classes = useStyles()
  return content(classes.card)
}

export default CardWrapper
