export interface CustomWindow extends Window {
  webengage?: {
    user: {
      login: (value: string) => void
      logout: () => void
      setAttribute: (key: string, value: string) => void
    }
    track: (eventName: string, data?: object) => void
    init: (key: string) => void
    debug: (debug: boolean) => void
    options: (key: string, value: any) => void
  }
}

declare let window: CustomWindow

export const webengage = window.webengage
