import { toast } from "@suraasa/placebo-ui"
import api from "api"
import { AuthData } from "api/resources/users/types"
import { APIError, transformKeys } from "api/utils"
import { format } from "date-fns"
import camelCase from "lodash/camelCase"
import { FieldValues, UseFormSetError } from "react-hook-form"

import {
  BROWSER_STORAGE_KEYS,
  DEFAULT_LANGUAGE_CODE,
  USER_TYPE,
} from "./constants"

export const formatDate = (
  date: string | undefined,
  formatStr = "MMMM d, yyyy"
) => (date ? format(new Date(date), formatStr) : "")

// check session and local storage support
export function isStorageSupported() {
  try {
    localStorage.setItem("__TEST_LOCAL_STORAGE", "true")
    localStorage.removeItem("__TEST_LOCAL_STORAGE")
    sessionStorage.setItem("__TEST_SESSION_STORAGE", "true")
    sessionStorage.removeItem("__TEST_SESSION_STORAGE")
  } catch (e) {
    // alerting the user to prevent further errors or App issues
    alert(
      "Your browser has cookies disabled. Make sure that your cookies are enabled. Press OK to reload"
    )
    // reload browser to prevent further error popups and check if user enabled storage
    window.location.reload()
    return false
  }
  // returns true if storages are supported
  return true
}

export function getAuthInfo() {
  const { auth } = BROWSER_STORAGE_KEYS
  // checking storage access before accessing token
  const authInfo = isStorageSupported()
    ? localStorage.getItem(auth) || sessionStorage.getItem(auth)
    : null
  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}

export function getUserLanguage() {
  const { language } = BROWSER_STORAGE_KEYS
  // checking storage access before accessing default language
  return isStorageSupported()
    ? localStorage.getItem(language) || DEFAULT_LANGUAGE_CODE
    : DEFAULT_LANGUAGE_CODE
}

export function saveAuthInfo(authInfo: AuthData) {
  const { auth } = BROWSER_STORAGE_KEYS

  const oldAuthToken = getAuthInfo()?.accessToken
  const newAuthToken = authInfo.accessToken
  // checking storage access before saving token
  if (isStorageSupported()) {
    localStorage.setItem(
      auth,
      JSON.stringify({
        ...authInfo,
        expiresAt:
          oldAuthToken !== newAuthToken
            ? // ? moment(Date.now())
              //     // Reduce expiry time by 10 minutes so we can refresh prematurely
              //     .add(authInfo.expiresIn - 10 * 60, "seconds")
              //     .toISOString()
              new Date().toISOString()
            : authInfo.expiresAt,
      })
    )
  }
}

export function clearAuthInfo() {
  const AllKeys = BROWSER_STORAGE_KEYS
  const keysToNotRemove = [AllKeys.suraasaOnboardingCompleted, AllKeys.language]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(AllKeys).forEach(([_, value]) => {
    if (!keysToNotRemove.includes(value)) {
      localStorage.removeItem(value)
    }
  })

  sessionStorage.clear()
}

export function isUUIDString(str: string) {
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export const buildUserName = (user: {
  firstName: string
  lastName: string | null
}) => {
  return [user.firstName, user.lastName].filter(Boolean).join(" ")
}

export const generateObjectURL = (file: File) => {
  const URL = window.URL || window.webkitURL
  return URL.createObjectURL(file)
}

export const handleErrors = <T extends FieldValues>(
  errors: APIError,
  options?: {
    setter?: UseFormSetError<T>
  }
) => {
  const {
    errors: { message, fieldErrors },
  } = errors
  if (message) {
    toast.error(message, { autoClose: 3000 })
  }

  if (options?.setter && fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      if (v)
        options.setter(k as any, { message: typeof v === "string" ? v : v[0] })
    }
    return
  }

  // if there was no message, but there were fieldErrors without a setter, we need to show them to the user
  if (!message && fieldErrors && !options?.setter) {
    const firstError = Object.entries(fieldErrors)[0]
    if (firstError) {
      const [k, v] = firstError
      toast.error({
        title: `Error on key '${k}'`,
        subtitle: typeof v === "string" ? v : v[0],
      })
    }
  }
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  downloadFile(blobData, name)
}

export const downloadFile = (href: string, name: string) => {
  const link = document.createElement("a")
  link.href = href
  link.download = name
  link.target = "_blank"
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(href)
  }, 100)
}

export function convertHexToRGBA(hex: string, opacity: number) {
  const tempHex: string = hex.replace("#", "")

  const r = parseInt(tempHex.substring(0, 2), 16)
  const g = parseInt(tempHex.substring(2, 4), 16)
  const b = parseInt(tempHex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity / 100})`
}

export function narrowError(e: unknown): e is APIError {
  return e instanceof APIError
}

export const getPlatformURL = (
  platform: "jobs" | "suraasa" | "sso" | "hire",
  url: string
) => {
  let domain

  if (platform === "hire") {
    domain = import.meta.env.VITE_HIRE_PLATFORM_URL
  }
  if (platform === "jobs") {
    domain = import.meta.env.VITE_JOBS_PLATFORM_URL
  }
  if (platform === "suraasa") {
    domain = import.meta.env.VITE_SURAASA_PLATFORM_URL
  }
  if (platform === "sso") {
    domain = import.meta.env.VITE_SSO_URL
  }

  return `${domain}${url}`
}

export const MBToBytes = (mb: number) => mb * 1000000

export const generateAuthCode = async () => {
  try {
    const res = await api.users.generateAuthCode({
      data: { platform: USER_TYPE },
    })
    return res.code
  } catch (e) {
    return null
  }
}

export const getLearningItemURL = ({
  itemSlug,
  parentSlug,
  parentType,
  route,
  queryParams,
}: {
  itemSlug: string
  parentSlug?: string
  parentType?: string
  route: string
  queryParams?: Record<string, string>
}) => {
  let url = route.replace(":slug", itemSlug)
  if (parentSlug) {
    url = url.replace(":parentSlug", parentSlug)
  }
  if (parentType) {
    url = url.replace(":learningItemType", parentType)
  }

  if (queryParams) {
    const params = new URLSearchParams(queryParams)
    url = `${url}?${params.toString()}`
  }

  return url
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getITOShareReferralText = (
  link: string,
  options?: {
    discount?: boolean
    encode?: boolean
  }
) => {
  const text = `Hey! 😊

I'll be participating in the International Teachers' Olympiad 2023.
It's a fantastic opportunity for us teachers to get feedback on our teaching skills and get recognition for it!!

I found all the details in this video here: https://suraasa.co/ito-details

If you like it too, ${
    options?.discount
      ? "you can use my referral link for ₹50 discount"
      : "you can use this link to register"
  }.
${link}`

  if (options?.encode) return encodeURIComponent(text)
  return text
}

export const getITOCertificateShareText = (type: string, url: string) => {
  if (type === "international-teacher-olympiad-2023-participation") {
    return `Glad to have attempted the International Teachers’ Olympiad 2023 by Suraasa. It was a truly enriching experience to test my pedagogy skills!😊

I look forward to keep growing and learning more!

Here is a memoir of my participation: ${url}

#TrueTeachingPotential #Suraasa`
  }

  let opener
  switch (type) {
    case "international-teacher-olympiad-2023-participation":
      opener =
        "Super happy to share that I am one of the highly motivated teachers globally."
      break
    case "international-teacher-olympiad-2023-excellence":
      opener =
        "Super happy to share that I stand among the top 100 teachers globally."
      break
    case "international-teacher-olympiad-2023-merit-30-percentile":
      opener =
        "Super happy to share that I stand among the top 30% teachers globally."
      break
    case "international-teacher-olympiad-2023-merit-10-percentile":
      opener =
        "Super happy to share that I stand among the top 10% teachers globally."
      break
    case "international-teacher-olympiad-2023-merit-1-percentile":
      opener =
        "Super happy to share that I stand among the top 1% teachers globally."
      break
    default:
      break
  }

  return `${opener} Special thanks to the International Teachers’ Olympiad by Suraasa for giving all teachers such a wonderful opportunity of assessing their skills and getting celebrated for it. I am thrilled to proceed to the next level of growth in my teaching career and discover my #TrueTeachingPotential!\nCheck out my certificate: ${url}`
}

export function scrollToElementAdjusted(selector: string, offset: number) {
  const element = document.querySelector(selector)
  if (!element) return

  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}

/**
 * @param inputString "%%Bravo%%!! ";
 * @returns ["%%Bravo%%", "!!"]
 */
export function splitStringWithPattern(inputString: string) {
  const regex = /(%%[^%]+%%)\s*/
  return inputString.split(regex).filter(Boolean)
}
