import { Button, Container, theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import MasterclassCard from "../../AfterResultPhase/MasterclassCard"
// import UpcomingEvent from "./UpcomingEvent"
// import AfterOlympiadForm from "../../AfterOlympiadForm"
import CommunityCard from "../../BeforeRegistrationPhase/components/CommunityCard"
import ContactUs from "../../BeforeRegistrationPhase/components/ContactUs"
import FAQs from "../../BeforeRegistrationPhase/components/FAQs"
import CheckOut from "./CheckOut"
import GoodJob from "./GoodJob"
import MissedOlympiadBanner from "./MissedOlympiadBanner"
import PedagogyReports from "./PedagogyReports"
import RecommendedResources from "./RecommendedResources"
import TeacherResource from "./TeacherResource"
type Props = {
  resultDate?: Date
  gotoFAQ?: () => void
  gotoAwardsAndPrize?: () => void
  hasMissedIto?: boolean
}

const SPACING = "mb-5 sm:mb-6"
const AwaitingResultPhase = ({
  resultDate,
  gotoFAQ,
  gotoAwardsAndPrize,
  hasMissedIto,
}: Props) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const Banners = hasMissedIto ? (
    <div className="mb-8">
      <Container fullWidth={isXsDown} className={SPACING}>
        <MissedOlympiadBanner />
      </Container>
    </div>
  ) : (
    resultDate && (
      <div className="mb-8">
        <GoodJob resultDate={resultDate} />
        {gotoAwardsAndPrize && (
          <PedagogyReports
            resultDate={resultDate}
            gotoAwardsAndPrize={gotoAwardsAndPrize}
          />
        )}
      </div>
    )
  )

  const faqs = hasMissedIto
    ? [
        {
          title:
            "Can I get another opportunity to participate in the Olympiad?",
          description:
            "As much as we would love to ensure all participants get to attempt the Olympiad, we can only conduct the assessment once to ensure a fair experience & report for everyone.",
        },

        {
          title:
            "Can I still access the resources provided on the Suraasa portal?",
          description:
            "Absolutely! You can continue to avail all the other benefits you got from your ITO Registration like the Global Teacher Community, Online Masterclasses, and other resources.",
        },
        {
          title:
            "How can I stay updated about upcoming Olympiad events or similar opportunities?",
          description:
            "Just keep an eye on your registered email and WhatsApp number. Also ensure that you download the Suraasa app and turn on notifications.",
        },
      ]
    : [
        {
          title: "How do I get recognised after the Olympiad?",
          description:
            "After the Olympiad, you will get certificates based on where you stand. There are certificates for Top 100, Top 1 Percentile, Top 10 Percentile, Top 30 Percentile, Others (Participation Certificate). You will further have a chance to get recognised in your city & state media along with your school. You will also get a chance to participate in Teacher Impact Awards to win cash prizes up to INR 3 Lakhs.",
        },

        {
          title:
            "What is the marking/grading scheme for ITO? Is there a negative marking for a wrong answer?",
          description:
            "You will be answering questions from 6 pedagogy topics with different weightage. There will be no negative marking. In the final report, you will get a percentile score.",
        },
        {
          title: "What are the dates & timings of the Olympiad & results?",
          description:
            "The Olympiad will be tentatively held on the 7th of October 2023. Timings are yet to be announced. The result will be announced on 30th October 2023 (tentatively).",
        },

        {
          title: "Will you share my Olympiad Report with my school?",
          description:
            "No, we will not share your Olympiad report with your school. It will remain confidential and we will share the report with only you. Schools will get a separate report with aggregated, overall scores of all teachers combined, not of individual teachers.",
        },

        {
          title: "Will I get my certificate of Participation?",
          description:
            "Yes, you will receive a certificate of participation if you attempt the Olympiad.",
        },
      ]
  return (
    <div className={clsx({ "mt-4": hasMissedIto || !resultDate })}>
      {/* {!hasMissedIto && <AfterOlympiadForm />} */}

      {Banners}

      <Container>
        <TeacherResource className={SPACING} />
        <MasterclassCard className={SPACING} />
        <CommunityCard className={SPACING} />
      </Container>
      {/* <Container fullWidth={isXsDown} className={SPACING}>
        <UpcomingEvent />
      </Container> */}
      <RecommendedResources className={SPACING} />
      <Container fullWidth={isXsDown}>
        <CheckOut variant="masterclass" className={SPACING} />
        <CheckOut variant="blog" className={SPACING} />
        <CheckOut variant="youtube" className={SPACING} />
      </Container>

      {hasMissedIto && (
        <Container>
          <FAQs
            data={faqs}
            endSlot={
              <Button className="mt-4" onClick={gotoFAQ} variant="ghost">
                See All FAQs
              </Button>
            }
          />
        </Container>
      )}

      <div className="sm:mb-[185px] mb-4" />
      <ContactUs />
    </div>
  )
}
export default AwaitingResultPhase
