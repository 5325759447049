import { Container } from "@suraasa/placebo-ui"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import React from "react"

type Props = {
  children?: React.ReactNode
}
const Header = ({ children }: Props) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(41deg, rgba(227,237,222,1) 0%, rgba(239,230,221,1) 18%, rgba(247,233,239,1) 46%, rgba(229,230,253,1) 100%, rgba(143,117,69,1) 100%)",
      }}
    >
      <Container className="min-h-[200px] flex items-center max-w-full py-2">
        <SwitzerTypography
          variant="title1"
          className="mb-3"
          style={{
            fontFamily: "Switzer Bold",
          }}
        >
          Frequently Asked Questions.
        </SwitzerTypography>
        {children}
      </Container>
    </div>
  )
}

export default Header
