import { useQuery, UseQueryResult } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  ImportantDates,
  ITORegistrationDetails,
} from "api/resources/ito/registrations/types"
import { APIResponse } from "api/types"
import { useState } from "react"

import { getRegistrationStatus } from "./utils/utils"

export type ITOParticipationDetails = {
  pastYears: {
    isITO2022Participant: boolean | undefined
  }
  currentITOAttemptStatus: boolean | undefined
  itoRegistrationDetails: ITORegistrationDetails | undefined | null
  itoRegistrationDetailsLoaded: boolean
  registrationStatus: "completed" | "pending" | "notStarted"
  waitlistStatus: boolean | undefined
  timeline: UseQueryResult<APIResponse<ImportantDates[]>, unknown> | undefined
}

type Props = {
  isAuthenticated: boolean
}

/**
 * All ITO checks will be driven by this hook
 */
const useITOAccess = ({ isAuthenticated }: Props): ITOParticipationDetails => {
  const [isITO2022Participant, setIsITO2022Participant] = useState<boolean>()
  const [ito2023AttemptStatus, setITO2023AttemptStatus] = useState<boolean>()
  const [itoRegistrationDetails, setItoRegistrationDetails] = useState<
    ITORegistrationDetails | undefined | null
  >(undefined)

  const registrationStatus = getRegistrationStatus(itoRegistrationDetails)

  // const waitlistStatus = useQuery({
  //   queryKey: queries.itoRegistrations.waitlistStatus().queryKey,
  //   queryFn: () =>
  //     api.ito.registrations.getWaitlistStatus({
  //       urlParams: {
  //         itoWaitlistYear: 2024,
  //       },
  //     }),
  // })

  const timeline = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.itoRegistrations.timeLineDate().queryKey,
    queryFn: () =>
      api.ito.registrations.getDates({ params: { tag: "dashboard" } }),
  })

  const details = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.itoRegistrations.details().queryKey,
    queryFn: () => api.ito.registrations.getRegistrationDetails(),
    onSuccess: data => setItoRegistrationDetails(data),
  })

  useQuery({
    enabled: isAuthenticated,
    queryKey: queries.itoAssessments.overview().queryKey,
    queryFn: () => api.ito.assessments.overview(),
    onSuccess: data => {
      const ito2022 = data.find(
        i => i.olympiad === "international-teacher-olympiad-2022"
      )
      const ito2023 = data.find(
        i => i.olympiad === "international-teacher-olympiad-2023"
      )
      if (ito2022 && ito2022.attempt) {
        setIsITO2022Participant(true)
      }
      if (ito2023 && ito2023.attempt) {
        setITO2023AttemptStatus(true)
      }
    },
  })

  return {
    pastYears: {
      isITO2022Participant,
    },
    itoRegistrationDetailsLoaded: details.isLoading === false,
    currentITOAttemptStatus: ito2023AttemptStatus,
    waitlistStatus: undefined,
    registrationStatus,
    itoRegistrationDetails,
    timeline,
  }
}

export { useITOAccess }
