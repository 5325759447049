import { useLayoutEffect, useState } from "react"

const useNetworkStatus = () => {
  const [status, setStatus] = useState<"online" | "offline" | null>(null)

  useLayoutEffect(() => {
    const onlineListener = () => {
      setStatus("online")
    }
    const offlineListener = () => {
      setStatus("offline")
    }

    window.addEventListener("online", onlineListener)
    window.addEventListener("offline", offlineListener)

    return () => {
      window.removeEventListener("online", onlineListener)
      window.removeEventListener("offline", offlineListener)
    }
  }, [])

  return { status }
}

export default useNetworkStatus
