import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Edit, Plus } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { BROWSER_STORAGE_KEYS, UpdateProfileEnum } from "utils/constants"
import { pluralize } from "utils/helpers"

import ProfileContext from "../../context"
import SomethingNew from "../ReOrderingFeatureOnboarding/SomethingNew"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import WorkLocationDialog from "./WorkLocationDialog"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
  },
  flagImg: {
    width: 28,
    height: 20,
    marginRight: theme.spacing(10 / 8),
  },
}))
const { reorderOnboarding: updateProfileDialog } = BROWSER_STORAGE_KEYS

const WorkLocationInterests = () => {
  const classes = useStyles()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateStatus: string[] = JSON.parse(
    localStorage.getItem(updateProfileDialog) ?? "[]"
  )

  const showSomethingNew = !updateStatus.includes(UpdateProfileEnum.LOCATIONS)

  const [openDialog, setOpenDialog] = useState(false)
  const toggle = () => setOpenDialog(!openDialog)

  const {
    workLocationInterest: { data },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      <WorkLocationDialog
        open={openDialog}
        onBack={toggle}
        onRequestClose={toggle}
      />
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={toggle}>
                    {data.length > 0 ? <Edit /> : <Plus />}
                  </IconButton>
                )
              }
              itemCount={data.length}
              title={pluralize("Work Location Interest", data.length, {
                skipCount: true,
              })}
            />
          }
        />
        {showSomethingNew && <SomethingNew onClick={toggle} />}
        <SectionContent className={classes.root} xPadding={2}>
          {data.length > 0 ? (
            data.map((item, i) => (
              <div className={classes.locationContainer} key={i}>
                <img
                  alt=""
                  className={classes.flagImg}
                  src={`/assets/flags/${item.country.isoCode}.svg`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = "/assets/flags/placeholder.png"
                  }}
                />
                <div>
                  <Typography display="inline" variant="strong">
                    {item.state.name},{" "}
                  </Typography>
                  <Typography display="inline" variant="body">
                    {item.country.name}
                  </Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography color="onSurface.400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any work location interests. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default WorkLocationInterests
