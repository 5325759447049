import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Masterclass } from "./types"
import { urls } from "./urls"

export default {
  list: async ({ params }: Config): Promise<APIResponse<Masterclass[]>> => {
    try {
      const res = await axios.get(urls.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  overview: async (): Promise<
    APIResponse<{ upcomingMasterclass: Masterclass | null }>
  > => {
    try {
      const res = await axios.get(urls.overview())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getItoMasterclass: async ({
    urlParams: { slug },
  }: ConfigWithURLParams): Promise<APIResponse<Masterclass>> => {
    try {
      const res = await axios.get(urls.masterclass(slug))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  trackItoMasterclass: async ({
    urlParams: { videoMeetingId },
    data,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.trackMasterclass(videoMeetingId), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
