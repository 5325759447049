import { Typography } from "@suraasa/placebo-ui"
import FullWidthButtonCard from "components/FullWidthButtonCard"
import React from "react"

import AtheLogo from "./assets/Athe.png"
import DubaiLogo from "./assets/Dubai.png"
import OfEqualLogo from "./assets/OfEqual.png"

const AdditionalCertificates = () => {
  // TODO if no additional certificates then return null
  return (
    <div>
      <Typography variant="title4">
        Apply for Additional Qualifications
      </Typography>
      <div className="grid grid-cols-12 gap-3 mt-2">
        <FullWidthButtonCard
          tooltipTitle="Coming soon!"
          buttonProps={{
            disabled: true,
            label: "Apply Now",
          }}
          className="col-span-12 sm:col-span-4"
        >
          <div className="flex justify-center items-center h-[96px]">
            <img src={OfEqualLogo} alt="ofEqualLogo" />
          </div>
        </FullWidthButtonCard>
        <FullWidthButtonCard
          tooltipTitle="Coming soon!"
          buttonProps={{
            disabled: true,
            label: "Apply Now",
          }}
          className="col-span-12 sm:col-span-4"
        >
          <div className="flex justify-center items-center h-[96px]">
            <img src={AtheLogo} alt="AtheLogo" />
          </div>
        </FullWidthButtonCard>
        <FullWidthButtonCard
          tooltipTitle="Coming soon!"
          buttonProps={{
            disabled: true,
            label: "Apply Now",
          }}
          className="col-span-12 sm:col-span-4"
        >
          <div className="flex justify-center items-center h-[96px]">
            <img src={DubaiLogo} alt="DubaiLogo" />
          </div>
        </FullWidthButtonCard>
      </div>
    </div>
  )
}

export default AdditionalCertificates
