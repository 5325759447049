import api from "api"
import { Country } from "api/resources/global/types"
import { APIError } from "api/utils"
import { useLayoutEffect, useState } from "react"

type Props = {
  cache?: boolean
}

const STORAGE_KEY_NAME = "currentCountry"

const getInitialValue = () => {
  if (typeof window === "undefined") return null
  const cachedValue = sessionStorage.getItem(STORAGE_KEY_NAME)
  if (cachedValue) {
    return JSON.parse(cachedValue) as Country
  }
  return null
}

const useCurrentCountry = ({ cache }: Props = { cache: true }) => {
  const [country, setCountry] = useState<Country | null>(
    cache ? getInitialValue() : null
  )

  useLayoutEffect(() => {
    const cachedValue = sessionStorage.getItem(STORAGE_KEY_NAME)
    if (cache && cachedValue) {
      setCountry(JSON.parse(cachedValue) as Country)
      return
    }

    const detectCountry = async () => {
      try {
        const res = await api.global.getCurrentCountry()
        setCountry(res)
        sessionStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(res))
      } catch (e) {
        if (e instanceof APIError) {
          console.log(e)
        }
      }
    }
    detectCountry()
  }, [cache])

  return country
}

export default useCurrentCountry
