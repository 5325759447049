import { TagColors } from "@suraasa/placebo-ui"
import { OrderStatus, PaymentPlanStatus } from "api/resources/orders/types"

export const getStatusTag = (
  status: OrderStatus
): { label: string; color: TagColors } => {
  switch (status) {
    case OrderStatus.SUCCESSFUL:
      return {
        label: "Successful",
        color: "success",
      }
    case OrderStatus.IN_PROGRESS:
      return {
        label: "In Progress",
        color: "primary",
      }
    case OrderStatus.NOT_INITIATED:
      return {
        label: "Not Initiated",
        color: "warning",
      }
    case OrderStatus.CANCELLED:
      return {
        label: "Cancelled",
        color: "critical",
      }
    case OrderStatus.FAILED:
      return {
        label: "Failed",
        color: "critical",
      }
  }
}

export const getPaymentPlanTag = (
  status: PaymentPlanStatus
): { label: string; color: TagColors } => {
  switch (status) {
    case PaymentPlanStatus.PAID:
      return { label: "Paid", color: "success" }
    case PaymentPlanStatus.UNPAID:
      return { label: "Unpaid", color: "critical" }
    case PaymentPlanStatus.DUE:
      return { label: "Due", color: "onSurface" }
  }
}
