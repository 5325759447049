import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Theme,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { Currency } from "api/resources/global/types"
import { APIError } from "api/utils"
import { ReactComponent as Refresh } from "assets/icons/refresh.svg"
import { ReactComponent as BaseAvatar } from "assets/Ito/baseAvatar.svg"
import clsx from "clsx"
import ShareSheet from "components/ShareSheet"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import CardWrapper from "features/ITO/Dashboard/ReferAndEarn/CardWrapper"
import { NavArrowRight, Xmark } from "iconoir-react"
import { useState } from "react"
import { createUseStyles } from "react-jss"
import {
  getITOShareReferralText,
  getPlatformURL,
  pluralize,
} from "utils/helpers"

import InviteViaWhatsappAndShare from "./InviteViaWhatsappAndShare"

const useStyles = createUseStyles((theme: Theme) => ({
  withdrawAmt: {
    position: "relative",
    background: "#fff",
  },
  content: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  withdrawBtn: {
    borderRadius: "0px 0px 4px 4px",
    backgroundColor: "",
    "&:hover": {
      backgroundColor: "#4909e7",
    },
    "&:disabled": {
      backgroundColor: "#BCA3F9 !important",
      color: "white !important",
    },
  },
  icon: {
    marginBottom: "-6px",
    paddingLeft: theme.spacing(1),
  },
}))

const MINIMUM_WITHDRAW_BALANCE = 200
const REWARD_PER_REFERRAL = 100

type Props = {
  balance?: number
  currency?: Currency
  onCreditHistory: () => void
  onClaimSuccess: () => void
  referralCode?: string
}

const BalanceCard = ({
  balance,
  currency,
  onClaimSuccess,
  onCreditHistory,
  referralCode,
}: Props) => {
  const classes = useStyles()
  const canWithdraw = balance ? balance >= MINIMUM_WITHDRAW_BALANCE : false

  const [showProgressDialog, setShowProgressDialog] = useState(false)

  const teachersCount = (function (MIN, PER, bal) {
    const amountNeeded = MIN - bal
    const quotient = Math.floor(amountNeeded / PER)
    const remainder = amountNeeded % PER
    const teachers = quotient + (remainder > 0 ? 1 : 0)
    return teachers
  })(MINIMUM_WITHDRAW_BALANCE, REWARD_PER_REFERRAL, balance ?? 0)

  const claimMutation = useMutation(api.ito.referAndEarn.claim, {
    onError: error => {
      if (error instanceof APIError) {
        toast.error(error.errors.message || "Something went wrong")
      }
    },
    onSuccess: data => {
      onClaimSuccess()
      if (data.additionalDetails)
        window.open(data.additionalDetails.payoutLink, "_blank")
    },
  })

  // Call handleClaim when you want to initiate the claim process
  const handleClaim = () => {
    claimMutation.mutate()
  }

  const [shareSheetOpen, setShareSheetOpen] = useState(false)
  const shareURL = getPlatformURL("suraasa", `/ito/?referral=${referralCode}`)

  const safeToUseBalance = balance ?? 0

  return (
    <>
      <Dialog
        open={showProgressDialog}
        onRequestClose={() => setShowProgressDialog(false)}
        width="min(100vw,408px)"
      >
        <DialogContent className="!rounded-2xl !p-0">
          <IconButton
            onClick={() => setShowProgressDialog(false)}
            className="absolute right-1 top-1"
            color="black"
          >
            <Xmark />
          </IconButton>

          <div
            className="border-onSurface-200 border px-3 h-[143px] flex items-center w-full"
            style={{
              boxShadow: "0px 6px 15px 0px #0000000D",
            }}
          >
            <div className="w-full flex gap-1 relative">
              <div className="w-full">
                <LinearProgress value={safeToUseBalance / 2} size="md" />
                <span
                  className={clsx("absolute -top-5 z-auto inline", {
                    hidden: safeToUseBalance > 151,
                  })}
                  style={{
                    left: safeToUseBalance / 4 + "%",
                  }}
                >
                  <Typography variant="subtitle2" className="mb-0.5">
                    {currency ? currency.symbol : "₹"}
                    {safeToUseBalance}
                  </Typography>
                  <BaseAvatar />
                </span>
              </div>
              <div className="w-[10%] ml-2 sm:ml-5">
                <LinearProgress value={0} size="md" />
              </div>
              <div className="w-[13%]">
                <LinearProgress value={0} size="md" />
              </div>
              <div className="w-[13%]">
                <LinearProgress value={0} size="md" />
              </div>
              <div className="w-[10%]">
                <LinearProgress value={0} size="md" />
              </div>

              <div className="absolute -top-1.5 z-auto left-[57%] sm:left[calc(46%+40px)]">
                <Typography
                  variant="subtitle2"
                  className="mb-0.5 absolute -top-3.5 -left-1 whitespace-nowrap"
                >
                  {currency ? currency.symbol : "₹"}
                  {MINIMUM_WITHDRAW_BALANCE}
                </Typography>
                <div className="max-h-4 max-w-4 border-[4.5px] border-primary-200 rounded-full animate-pulse">
                  <div className="h-3 w-3 rounded-full border-[4.5px] border-white bg-primary-500" />
                </div>
              </div>
            </div>
          </div>
          {!canWithdraw ? (
            <div className="mt-2 px-3 pb-3">
              <SwitzerTypography
                variant="title2"
                style={{ fontFamily: "Switzer Bold", lineHeight: "29.05px" }}
              >
                <span className="text-primary-500" style={{ fontSize: 27 }}>
                  {currency ? currency.symbol : "₹"}
                  {MINIMUM_WITHDRAW_BALANCE - safeToUseBalance}&nbsp;
                </span>
                more required for withdrawal
              </SwitzerTypography>
              <Typography variant="largeBody" className="mt-1">
                Refer at least {teachersCount} more&nbsp;
                {pluralize("teacher", teachersCount, { skipCount: true })} to
                claim your cashback.
              </Typography>
            </div>
          ) : (
            <div className="mt-2 px-3 pb-3">
              <SwitzerTypography
                variant="title2"
                style={{ fontFamily: "Switzer Bold", lineHeight: "29.05px" }}
              >
                Awesome! You can withdraw your cashback now.
              </SwitzerTypography>
              <Typography variant="largeBody" className="mt-1">
                Continue referring teachers and to earn more and more cashback!
              </Typography>
            </div>
          )}
          <InviteViaWhatsappAndShare
            className="m-3 mt-0"
            onShareClick={() => {
              setShowProgressDialog(false)
              setShareSheetOpen(true)
            }}
          />
        </DialogContent>
      </Dialog>
      <CardWrapper
        content={className => (
          <div className={clsx(className, classes.withdrawAmt)}>
            <div className={classes.content}>
              <Typography variant="preTitle">Balance</Typography>

              <IconButton
                className="absolute right-3 pl-0.5"
                onClick={onCreditHistory}
              >
                <Refresh />
              </IconButton>
              <div className="flex justify-between items-end mt-auto flex-wrap gap-1">
                <Typography
                  variant="title1"
                  color="onSurface.800"
                  style={{ fontSize: "40px" }}
                >
                  {currency ? currency.symbol : "₹"}
                  {balance ?? "-"}
                </Typography>
              </div>
            </div>
            <Button
              color="black"
              fullWidth
              onClick={
                canWithdraw ? handleClaim : () => setShowProgressDialog(true)
              }
              endAdornment={<NavArrowRight />}
              className="block !md:hidden !rounded-tl-none !rounded-tr-none [&>span]:justify-between"
            >
              Withdraw
            </Button>
            <ShareSheet
              data={{
                url: shareURL,
                text: getITOShareReferralText(shareURL, { discount: true }),
              }}
              open={shareSheetOpen}
              platforms={["whatsapp", "telegram", "linkedin", "facebook"]}
              handleClose={() => setShareSheetOpen(false)}
            />
          </div>
        )}
      />
    </>
  )
}

export default BalanceCard
