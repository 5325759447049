import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import styles from "./CustomCircularProgress.module.css"
type Props = {
  currentValue: number
  totalValue: number
  className?: string
  circularGradient?: string
  hideValue?: boolean
}

const CustomCircularProgress = ({
  currentValue,
  totalValue,
  className,
  circularGradient,
  hideValue,
}: Props) => {
  const percentage = (currentValue / totalValue) * 100
  return (
    <div className={className}>
      <div
        className={clsx(styles.container)}
        style={
          {
            "--circularGradient":
              circularGradient ||
              "conic-gradient( from 0deg at 50% 50%, #eec239 0deg, #138d75 360deg)",
          } as React.CSSProperties
        }
      >
        {hideValue ? (
          <div className="z-[1]" />
        ) : (
          <Typography variant="smallBody" className="z-[1]">
            {currentValue}
          </Typography>
        )}
        <div
          style={
            {
              "--degValue": `${percentage * 3.6}deg`,
            } as React.CSSProperties
          }
          className={styles.layerContainer}
        />
      </div>
    </div>
  )
}

export default CustomCircularProgress
