import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  ImportantDates,
  ITORegistrationDetails,
  LeaderBoardDetailsType,
  ParticipationHistory,
  ReportDetails,
  RetrieveLeaveRequestType,
  UserAwardsOverview,
  UserPercentileType,
  WaitlistStatus,
} from "./types"
import { urls } from "./urls"

export default {
  getRegistrationDetails: async (): Promise<
    APIResponse<ITORegistrationDetails>
  > => {
    try {
      const res = await axios.get(urls.registrationDetails())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getWaitlistStatus: async ({
    urlParams: { itoWaitlistYear },
  }: ConfigWithURLParams): Promise<APIResponse<WaitlistStatus>> => {
    try {
      const res = await axios.get(urls.waitlistStatus(itoWaitlistYear))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },
  userLead: async ({
    data,
    ...options
  }: Config): Promise<
    APIResponse<{
      email: string
      firstName: string
      lastName: string
      phoneNumber: string
      user: string
    }>
  > => {
    try {
      const res = await axios.post(urls.userLead(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getDates: async ({
    params,
  }: Config): Promise<APIResponse<ImportantDates[]>> => {
    try {
      const res = await axios.get(urls.importantDates(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getUserPercentile: async ({
    urlParams: { slug },
  }: ConfigWithURLParams): Promise<APIResponse<UserPercentileType>> => {
    try {
      const res = await axios.get(urls.userPercentile(slug))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getUserAwardsOverview: async ({
    params,
  }: Config): Promise<APIResponse<UserAwardsOverview>> => {
    try {
      const res = await axios.get(urls.awardsOverview(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getReports: async (): Promise<APIResponse<ReportDetails[]>> => {
    try {
      const res = await axios.get(urls.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getLeaderBoardList: async ({
    params,
  }: Config): Promise<APIResponse<LeaderBoardDetailsType[]>> => {
    try {
      const res = await axios.get(urls.leaderBoardList(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getUserParticipationHistory: async (): Promise<
    APIResponse<ParticipationHistory>
  > => {
    try {
      const res = await axios.get(urls.participationHistory())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  submitITOFeedback: async ({ data }: Config): Promise<APIResponse<void>> => {
    try {
      const res = await axios.post(urls.itoFeedback(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieveLeaveRequest: async (): Promise<
    APIResponse<RetrieveLeaveRequestType>
  > => {
    try {
      const res = await axios.get(urls.leaveRequest())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  createLeaveRequest: async ({
    data,
  }: Config): Promise<APIResponse<RetrieveLeaveRequestType>> => {
    try {
      const res = await axios.post(urls.leaveRequest(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
