import {
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Select,
  toast,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  LanguageProficiency,
  LanguageResponse,
} from "api/resources/profile/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
// import { LanguageProficiency } from "utils/constants"
import { handleErrors } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"

const useStyles = createUseStyles(theme => ({
  addLanguageContainer: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    "& > form": {
      display: "flex",
      gap: 24,
      flexDirection: "column",
    },
  },
}))

type Props = {
  onAdd: (data: LanguageResponse) => void
  onBack?: () => void
}

const AddDialog = ({
  onBack,
  onAdd,
}: Props &
  Pick<DialogProps, "open" | "onRequestClose"> &
  Pick<DialogTitleProps, "onBack">) => {
  const classes = useStyles()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setError,
  } = useForm<{ languageId: string; proficiency: LanguageProficiency }>()
  const [loading, setLoading] = useState(false)

  const { languages } = useResource(["languages"])

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const res = await api.profile.languages.create({ data })
    if (res.isSuccessful) {
      onAdd(res.data)
      setLoading(false)
      toast.success("Added successfully.")
      reset()
      if (onBack) onBack()
    } else {
      handleErrors(res, { setter: setError })
      setLoading(false)
    }
  })

  return (
    <>
      <DialogTitle onBack={onBack}>Add New Language</DialogTitle>

      <DialogContent className={classes.addLanguageContainer}>
        <form onSubmit={onSubmit}>
          <div>
            <Controller
              control={control}
              name="languageId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  error={Boolean(errors.languageId)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.uuid}
                  helperText={errors.languageId?.message}
                  label="Language"
                  options={languages}
                  placeholder="Select Language"
                  value={
                    value
                      ? languages.find(language => language.uuid === value)
                      : null
                  }
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val?.uuid)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="proficiency"
              render={({ field: { onChange, onBlur } }) => (
                <Select
                  error={Boolean(errors.proficiency)}
                  helperText={errors.proficiency?.message}
                  label="Proficiency"
                  options={[
                    {
                      label: "Elementary",
                      value: LanguageProficiency.ELEMENTARY,
                    },
                    {
                      label: "Limited Working",
                      value: LanguageProficiency.LIMITED_WORKING,
                    },
                    {
                      label: "Professional Working",
                      value: LanguageProficiency.PROFESSIONAL_WORKING,
                    },
                    {
                      label: "Full Professional",
                      value: LanguageProficiency.FULL_PROFESSIONAL,
                    },
                    {
                      label: "Native/Bi-lingual",
                      value: LanguageProficiency.NATIVE,
                    },
                  ]}
                  placeholder="Select Language Proficiency"
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
        </form>
      </DialogContent>

      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            variant: "filled",
            color: "primary",
            onClick: onSubmit,
            loading,
          },
          secondary: {
            label: "Close",
          },
        }}
      />
    </>
  )
}

export default AddDialog
