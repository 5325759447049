import { Button, Typography } from "@suraasa/placebo-ui"
import { Profile, SkillEvidence } from "api/resources/profile/types"
import Monitor from "assets/Fallback/monitor.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import {
  SkillProfileRoutes,
  useGenerateURL,
} from "features/SkillProfile/hooks/useSkillProfileNavigation"
import Overview from "features/SkillProfile/Overview"
import React from "react"

type Props = {
  profile: Profile
  skillEvidences: SkillEvidence[]
  isLoading: boolean
  isError: boolean
}

const SkillOverview = ({
  profile,
  skillEvidences,
  isLoading,
  isError,
}: Props) => {
  const { navigate: customNavigation } = useGenerateURL()

  const handleViewEvidence = (courseSlug: string) => {
    customNavigation(SkillProfileRoutes.EVIDENCES_LIST, courseSlug)
  }

  return (
    <div>
      <Overview data={profile} />
      {(isLoading || isError) && (
        <Fallback isLoading={isLoading} hasError={isError} className="mt-4" />
      )}

      {skillEvidences && (
        <div className="sm:rounded-2xl border border-surface-200 bg-white overflow-hidden mt-3">
          <div className="px-2.5 py-1.5 border-b border-surface-200">
            <Typography variant="title3">Skills</Typography>
          </div>
          {skillEvidences.length > 0 ? (
            skillEvidences.map((item, index) => (
              <div
                key={item.slug}
                className={clsx(
                  "mx-2 py-2 flex flex-wrap items-center justify-between gap-1",
                  {
                    "border-b border-surface-200":
                      index !== skillEvidences.length - 1,
                  }
                )}
              >
                <Typography color="onSurface.800" variant="title4">
                  {item.name}
                </Typography>
                <Button
                  onClick={() => {
                    handleViewEvidence(item.slug)
                  }}
                  variant="text"
                >
                  View Evidences
                </Button>
              </div>
            ))
          ) : (
            <Fallback
              className="my-4"
              data={{ image: Monitor, title: "No Skill Evidences Available!" }}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default SkillOverview
