import * as Sentry from "@sentry/browser"
import { Button } from "@suraasa/placebo-ui"
import React, { useState } from "react"

const SentryErrorTesting = () => {
  const items = null

  const [mount, setMount] = useState(false)

  return (
    <div className="h-full flex flex-col items-center justify-center p-5 gap-2">
      <Button onClick={() => setMount(true)}>Mount error</Button>
      <Button
        onClick={() => {
          Sentry.captureException("generated error")
        }}
      >
        Error
      </Button>
      {/* @ts-expect-error this is error */}
      {mount && items.length}
    </div>
  )
}

export default SentryErrorTesting
