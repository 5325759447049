import { differenceInMilliseconds } from "date-fns"
import React, { useEffect, useState } from "react"
import { useTimer } from "react-timer-hook"

const DurationTimer = ({
  toDate,
  timeLeftCallback,
  notifyOnTimeLeft,
  onEnd,
}: {
  toDate: string
  trim?: boolean
  onEnd?: () => void
  timeLeftCallback?: (time: number) => void
  /**
   * In milliseconds
   */
  notifyOnTimeLeft?: number[]
}) => {
  const { days, hours, minutes, seconds, start } = useTimer({
    onExpire: () => (onEnd ? onEnd() : undefined),
    autoStart: false,
    expiryTimestamp: new Date(toDate),
  })

  const addPadStart = (time: number) => {
    return `${time}`.padStart(2, "0")
  }

  const currentTime = (() => {
    if (days)
      return `${addPadStart(days)}:${addPadStart(hours)}:${addPadStart(
        minutes
      )}:${addPadStart(seconds)}`

    if (hours)
      return `${addPadStart(hours)}:${addPadStart(minutes)}:${addPadStart(
        seconds
      )}`

    return `${addPadStart(minutes)}:${addPadStart(seconds)}`
  })()

  useEffect(() => {
    start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [timeRemainingNotifiedCount, setTimeRemainingNotifiedCount] =
    useState(0)

  useEffect(() => {
    setTimeRemainingNotifiedCount(Number(notifyOnTimeLeft?.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const duration = setInterval(() => {
      const total = differenceInMilliseconds(new Date(toDate), new Date())
      if (
        notifyOnTimeLeft &&
        timeLeftCallback &&
        timeRemainingNotifiedCount !== 0
      ) {
        notifyOnTimeLeft.forEach((time, index) => {
          if (total < time) {
            timeLeftCallback(time)
            setTimeRemainingNotifiedCount(prev => prev - 1)
            notifyOnTimeLeft.splice(index, 1)
          }
        })
      }
    }, 1000)

    return () => {
      clearInterval(duration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate, timeRemainingNotifiedCount, notifyOnTimeLeft])

  return <>{currentTime}</>
}

export default DurationTimer
