import { UserResponse } from "api/resources/assessments/types"

export type Assessment = {
  id: number
  name: string
  totalQuestions: number
  duration: string
  startTime: string
  endTime: string | null
  assessmentType: AssessmentType
  endTimeStrict: boolean
  isProctored: boolean
  slug: string
  description: string | null
  maxWarningCount: number | null
  attempt: {
    id: number
    status: AttemptStatus
    startTime: string | null
  } | null
}

export enum AssessmentType {
  LIVE = 1,
  MOCK = 2,
}

export enum AttemptStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
}

export type Attempt = {
  id: number
  status: AttemptStatus
  startTime: string | null
  responses: AssessmentQuestion[]
  assessment: Pick<
    Assessment,
    | "id"
    | "duration"
    | "name"
    | "slug"
    | "startTime"
    | "isProctored"
    | "endTime"
    | "endTimeStrict"
    | "assessmentType"
    | "maxWarningCount"
  >
}

export enum QuestionType {
  SINGLE_CORRECT = 1,
  MULTIPLE_CORRECT = 2,
  FILL_IN_THE_BLANKS = 3,
}

export enum QuestionStatus {
  UNATTEMPTED = 1,
  ATTEMPTED,
  MARKED_FOR_REVIEW,
  UNREAD,
}

export type AssessmentQuestion = {
  id: number
  sequence: number
  status: QuestionStatus
  question: {
    id: number
    text: string
    options: string[]
    ordered: boolean
    questionType: QuestionType
  }
  response: UserResponse | null
}

export type AutoSubmitted = {
  autoSubmitted: boolean
}

export type Warning = {
  id: number
  attempt: number
  totalWarningCount: number
  warningData?: {
    cameraCloseCount: number
    noFaceDetectedCount: number
    unfairMeansDetectedCount: number
    tabSwitchCount: number
  }
}

export enum AssessmentCategory {
  ITO = 1,
}

export type AssessmentOverview = [
  {
    olympiad:
      | "international-teacher-olympiad-2022"
      | "international-teacher-olympiad-2023"
    attempt: boolean
  }
]
