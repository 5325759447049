import React from "react"

const HomeSmile = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.11914 9.09866C3.11914 8.62004 3.11914 8.38073 3.18083 8.16034C3.23547 7.96512 3.32528 7.78151 3.44582 7.61852C3.58191 7.43452 3.77081 7.2876 4.14862 6.99375L9.80045 2.59788C10.0932 2.37017 10.2396 2.25632 10.4012 2.21256C10.5439 2.17394 10.6942 2.17394 10.8368 2.21256C10.9984 2.25632 11.1448 2.37017 11.4376 2.59788L17.0894 6.99375C17.4672 7.2876 17.6561 7.43452 17.7922 7.61852C17.9128 7.78151 18.0026 7.96512 18.0572 8.16034C18.1189 8.38073 18.1189 8.62004 18.1189 9.09866V15.1277C18.1189 16.0611 18.1189 16.5278 17.9373 16.8843C17.7775 17.1979 17.5225 17.4529 17.2089 17.6126C16.8524 17.7943 16.3857 17.7943 15.4523 17.7943H5.78577C4.85236 17.7943 4.38566 17.7943 4.02914 17.6126C3.71554 17.4529 3.46058 17.1979 3.30079 16.8843C3.11914 16.5278 3.11914 16.0611 3.11914 15.1277V9.09866Z" />
      <path
        d="M7.39076 11.9611C7.76079 13.3987 9.06585 14.461 10.619 14.461C12.1722 14.461 13.4773 13.3987 13.8473 11.9611M9.80045 2.59788L4.14862 6.99375C3.77081 7.2876 3.58191 7.43452 3.44582 7.61852C3.32528 7.78151 3.23547 7.96512 3.18083 8.16034C3.11914 8.38073 3.11914 8.62004 3.11914 9.09866V15.1277C3.11914 16.0611 3.11914 16.5278 3.30079 16.8843C3.46058 17.1979 3.71554 17.4529 4.02914 17.6126C4.38566 17.7943 4.85236 17.7943 5.78577 17.7943H15.4523C16.3857 17.7943 16.8524 17.7943 17.2089 17.6126C17.5225 17.4529 17.7775 17.1979 17.9373 16.8843C18.1189 16.5278 18.1189 16.0611 18.1189 15.1277V9.09866C18.1189 8.62004 18.1189 8.38073 18.0572 8.16034C18.0026 7.96512 17.9128 7.78151 17.7922 7.61852C17.6561 7.43452 17.4672 7.2876 17.0894 6.99375L11.4376 2.59788C11.1448 2.37017 10.9984 2.25632 10.8368 2.21256C10.6942 2.17394 10.5439 2.17394 10.4012 2.21256C10.2396 2.25632 10.0932 2.37017 9.80045 2.59788Z"
        stroke="currentColor"
        strokeWidth="1.49998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HomeSmile
