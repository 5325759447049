import { captureException } from "@sentry/react"
import { toast } from "@suraasa/placebo-ui"
import api from "api"
import {
  AssessmentQuestion,
  Attempt,
  QuestionStatus,
} from "api/resources/ito/assessments/types"
import { APIError } from "api/utils"
import { addMilliseconds, differenceInMinutes } from "date-fns"
import { closeWindow } from "features/ITO/Assessments/helpers"
import { useEffect, useState } from "react"
import { pluralize } from "utils/helpers"
// import { GA } from "shared/utils/googleAnalytics"

const parseDuration = (duration: string) => {
  const dayStamp = duration.split(" ")
  let seconds = 0
  if (dayStamp.length > 1) {
    seconds = +dayStamp[0] * 24 * 60 * 60
  }
  const timeStamp = dayStamp[dayStamp.length - 1].split(":")
  for (let i = 0; i < timeStamp.length; i++) {
    seconds += +timeStamp[i] * Math.pow(60, timeStamp.length - 1 - i)
  }

  return seconds * 1000
}

export function useItoAssessment({
  // assessmentCategory,
  attemptId,
  onInvalidAttempt,
}: {
  onInvalidAttempt?: (reason: string) => void
  // assessmentCategory: AssessmentCategory
  attemptId: string
}) {
  const [questionBank, setQuestionBank] = useState<AssessmentQuestion[]>([])
  const [attempt, setAttempt] = useState<Attempt>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleInvalidAttempt = (reason?: string) => {
    if (reason && onInvalidAttempt) {
      onInvalidAttempt(reason)
    } else {
      closeWindow(3000)
    }
  }

  let endTime = ""
  let strictEndTime = ""
  if (attempt) {
    if (attempt.startTime) {
      endTime = addMilliseconds(
        new Date(attempt.startTime),
        parseDuration(attempt.assessment.duration)
      ).toISOString()
    }
    if (attempt.assessment.endTime)
      strictEndTime = new Date(attempt.assessment.endTime).toISOString()
  }

  /**
   * Duration for which exam will be active for user
   */
  const testDuration = attempt?.assessment.endTimeStrict
    ? endTime > strictEndTime
      ? strictEndTime
      : endTime
    : endTime

  const getAssessmentData = async () => {
    try {
      const res = await api.ito.assessments.getAttempt({
        urlParams: {
          attemptId,
        },
      })
      if ("autoSubmitted" in res) {
        handleInvalidAttempt("Assessment already submitted.")
        return
      }
      if ("responses" in res) {
        // GA.trackEvent("ito_assessment_started", {
        //   assessment_id: res.data.assessment.id,
        //   assessment_type: res.data.assessment.assessmentType,
        // })
        if (res.responses.length === 0) {
          captureException(
            new Error("Assessment questions length 0"),
            scope => {
              scope.setExtras({
                attemptId,
              })
              return scope
            }
          )
          toast.error("We're having trouble getting the questions")
          handleInvalidAttempt()
        }
        setQuestionBank(res.responses)
        setAttempt(res)
      }
    } catch (err) {
      if (err instanceof APIError) {
        if (err.errors.fieldErrors?.startsIn) {
          const minutesLeft = Math.floor(
            differenceInMinutes(
              new Date(),
              new Date(err.errors.fieldErrors?.startsIn[0])
            )
          )
          handleInvalidAttempt(
            `The Olympiad will start in ${pluralize("minute", minutesLeft)}`
          )
        } else {
          toast.error("Assessment not found!")
          handleInvalidAttempt()
        }
      }
    }
  }

  const submit = async (options?: { autoSubmitReason?: string }) => {
    setIsSubmitting(true)
    // For testing, don't remove
    // await new Promise(resolve => setTimeout(resolve, 3000))
    // setIsSubmitting(false)
    // return true

    try {
      await api.ito.assessments.submit({
        urlParams: {
          attemptId,
        },
      })
      //   const eventData = options?.autoSubmitReason
      //     ? {
      //         reason: options?.autoSubmitReason,
      //         mode: "auto",
      //       }
      //     : {}
      //   GA.trackEvent("ito_assessment_submitted", {
      //     assessment_id: attempt?.assessment.id,
      //     assessment_type: attempt?.assessment.assessmentType,
      //     mode: "self",
      //     ...eventData,
      //   })
      setIsSubmitting(false)
      return true
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(
          "Could not submit your test. Please try again or contact admin"
        )
        setIsSubmitting(false)
        return false
      }
    }
  }

  const markAnswer = async (question: AssessmentQuestion, value: number[]) => {
    let status: QuestionStatus = QuestionStatus.UNREAD
    let response: number[] = []

    if (value.length > 0) {
      status = QuestionStatus.ATTEMPTED
      response = value
    } else {
      status = QuestionStatus.UNATTEMPTED
      response = []
    }

    let originalResponse: number[] | null
    setQuestionBank(qb =>
      qb.map(item => {
        if (item.id === question.id) {
          // @ts-expect-error broken types
          originalResponse = item.response
          return { ...item, status, response }
        }
        return item
      })
    )

    // GA.trackEvent("ito_assessment_mark_answer", {
    //   question_id: question.question.id,
    //   response: value,
    //   assessment_id: attempt?.assessment.id,
    // })

    try {
      const res = await api.ito.assessments.markAnswer({
        urlParams: {
          questionId: question.id,
        },
        data: {
          status,
          response,
        },
      })
      if (res && "autoSubmitted" in res) {
        toast.error("Assessment already submitted.")
        handleInvalidAttempt()
        return
      }
      return res
    } catch (err) {
      if (err instanceof APIError) {
        if (err.statusCode === 404) {
          handleInvalidAttempt()
        }
        // Revert answer to original state if API fails
        setQuestionBank(qb =>
          qb.map(item => {
            if (item.id === question.id) {
              return { ...item, status, response: originalResponse }
            }
            return item
          })
        )
      }
    }
  }

  useEffect(() => {
    getAssessmentData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isSubmitting,
    testDuration,
    questionBank,
    submit,
    markAnswer,
    attempt,
  }
}
