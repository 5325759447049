import { LinearProgress, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import styles from "./CourseInfoAndProgress.module.css"

export const getPercentage = ({
  total,
  completed,
}: {
  total: number | null
  completed: number | null
}) => {
  if (!total || !completed) return 0
  return Math.floor((completed / total) * 100)
}

const CourseInfoAndProgress = ({
  className,
  highlightCourse = false,
  showCount = false,
  title,
  currentItemNumber,
  numberOfItems,
  barColor,
}: {
  highlightCourse?: boolean
  showCount?: boolean
  className?: string
  title: string
  currentItemNumber: number
  numberOfItems: number
  barColor?: "black"
}) => {
  const percentage = getPercentage({
    total: numberOfItems,
    completed: currentItemNumber,
  })
  return (
    <div
      className={clsx("grow pe-1", className, {
        "text-common-white-500": highlightCourse,
      })}
    >
      <Typography variant="strong" className="mb-1">
        {title}
      </Typography>
      <div className="w-full max-w-[350px] ">
        <LinearProgress
          value={percentage}
          className={clsx({
            [styles.progressBar]: highlightCourse,
            [styles.blackLinearProgress]: barColor === "black",
          })}
        />
        <div className="flex justify-between items-center mt-0.5">
          <Typography variant="strongSmallBody">
            {showCount ? `${currentItemNumber}/${numberOfItems} Done` : ""}
          </Typography>
          <Typography variant="strongSmallBody">{percentage}%</Typography>
        </div>
      </div>
    </div>
  )
}

export default CourseInfoAndProgress
