import axios from "api/axios"
import { APIResponseOld } from "api/types"
import { formatErrorResponseOld, formatSuccessResponseOld } from "api/utils"

import { ApplicationOverview } from "./types"
import { urls } from "./urls"

export default {
  applicationOverview: async (): Promise<
    APIResponseOld<ApplicationOverview[]>
  > => {
    try {
      const res = await axios.get(urls.applicationOverview())
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
}
