import {
  Button,
  Theme,
  toast,
  Toggle,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { SkillEvidence } from "api/resources/profile/types"
import { APIError } from "api/utils"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import React, { useState } from "react"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import styles from "./EvidenceSetting.module.css"

const EvidenceSetting = ({
  skillEvidences = [],
}: {
  skillEvidences?: SkillEvidence[]
}) => {
  const { slug } = useParams() as { slug: string }

  const queryClient = useQueryClient()
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const evidences = skillEvidences.find(item => item.slug === slug)
  const [evidenceList, setEvidenceList] = useState<SkillEvidence | undefined>(
    evidences
  )

  const updatedList = useArray<{
    visibleOnProfile: boolean
    assignment: string
  }>([])

  const updateVisibility = (uuid: string, newVisibility: boolean) => {
    setEvidenceList(prevState => {
      if (!prevState) {
        return prevState
      }
      return {
        ...prevState,
        evidences: prevState.evidences.map(evidence => {
          if (evidence.uuid === uuid) {
            return {
              ...evidence,
              visibility: newVisibility,
            }
          }
          return evidence
        }),
      }
    })
  }

  const { mutate: toggleVisibility, isLoading } = useMutation({
    mutationFn: () =>
      api.profile.skillEvidences.toggleVisibility({
        data: {
          assignments: updatedList.array,
        },
      }),
    onSuccess: () => {
      toast.success("Preferences saved")
      queryClient.invalidateQueries(
        queries.profile.skillEvidenceList().queryKey
      )
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  if (!evidenceList) {
    return (
      <Fallback
        className="my-4"
        data={{ image: Monitor, title: "There are no evidences available" }}
      />
    )
  }

  return (
    <div className="pt-2.5 mx-3 relative flex flex-col">
      <div className="flex-grow mb-2">
        <Typography className="mb-1.5" variant="title3">
          Evidences
        </Typography>
        <div className="p-2 mb-2 border border-onSurface-200 rounded-2xl">
          <Typography className="mb-0.5" variant="title3">
            Show evidences on your skill portfolio
          </Typography>
          <Typography>
            Evidences will be visible to everyone who has access to your Suraasa
            profile or skill portfolio.
          </Typography>
        </div>
        <ItemList className="mb-2">
          {evidenceList.evidences.map((item, index) => (
            <ItemRow
              key={index}
              className={styles.rowContainer}
              infoSlot={
                <div className="py-1">
                  {/* <Typography
                    className="mb-0.25"
                    color="onSurface.500"
                    variant="smallBody"
                  >
                    {item.code}
                  </Typography> */}
                  <TruncatedText
                    maxLength={70}
                    hideActionButton
                    variant="strong"
                  >
                    {item.title}
                  </TruncatedText>
                </div>
              }
              actionSlot={() => {
                return (
                  <Toggle
                    checked={item.visibility}
                    onChange={e => {
                      updateVisibility(item.uuid, e.target.checked)
                      const hasUpdated = updatedList.array.findIndex(
                        i => i.assignment === item.uuid
                      )
                      if (hasUpdated < 0) {
                        updatedList.push({
                          visibleOnProfile: e.target.checked,
                          assignment: item.uuid,
                        })
                      } else {
                        updatedList.remove(hasUpdated)
                      }
                    }}
                  />
                )
              }}
            />
          ))}
        </ItemList>
      </div>
      {isXsDown ? (
        <Button
          onClick={() => {
            toggleVisibility()
          }}
          fullWidth
          loading={isLoading}
        >
          Save Changes
        </Button>
      ) : (
        <div className="sm:flex-row flex-col mx-auto p-3 rounded-2xl bg-onSurface-900 flex sm:justify-between justify-center items-center w-full">
          <Typography color="common.white.500" className="sm:mb-0 mb-2">
            Done making changes?
          </Typography>
          <Button
            size="sm"
            onClick={() => {
              toggleVisibility()
            }}
            loading={isLoading}
          >
            Save Changes
          </Button>
        </div>
      )}
    </div>
  )
}

export default EvidenceSetting
