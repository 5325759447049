import {
  Button,
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import SlidingSheet from "components/SlidingSheet"
import CriteriaWiseScore from "features/Assignments/CriteriaWiseScore"
import { Xmark } from "iconoir-react"
import { useTheme } from "react-jss"

type Props = {
  assignmentId: string
  open: boolean
  onClose: () => void
}

const MarkingCriteriaSheet = ({ assignmentId, open, onClose }: Props) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const { data, isSuccess, isError, isLoading } = useQuery({
    enabled: open,
    queryFn: () =>
      api.assignments.getRubrics({ urlParams: { assignmentId: assignmentId } }),
    queryKey: queries.assignments.rubrics(assignmentId).queryKey,
  })

  return (
    <SlidingSheet
      open={open}
      onClose={onClose}
      from="end"
      className="sm:w-[600px]"
      fullScreen={isXs}
    >
      <div className="sm:p-3 p-2">
        <Button
          variant="text"
          onClick={onClose}
          startAdornment={<Xmark />}
          color="black"
          size="sm"
          nudge="left"
        >
          Close
        </Button>
        {isSuccess &&
          data
            .sort((a, b) =>
              `${a.task} ${a.criteria}` > `${b.task} ${b.criteria}` ? 1 : -1
            )
            .map((criteria, index) => (
              <CriteriaWiseScore {...criteria} key={index} />
            ))}
        {isLoading && (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        )}
        {isError && (
          <Typography>Error loading rubrics. Please try again later</Typography>
        )}
      </div>
    </SlidingSheet>
  )
}

export default MarkingCriteriaSheet
