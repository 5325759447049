// import devTools, { DevToolsEvent } from "devtools-detect"
import { useCallback, useEffect, useState } from "react"
import { isChrome, isIOS, isMobileSafari } from "react-device-detect"

type Props = {
  disabled?: boolean
}

export function useDevToolDetection({ disabled }: Props = { disabled: false }) {
  const [devToolsOpen, setDevToolsOpen] = useState<boolean>(false)

  /**
   * Dev tools Access:
   * Prevent all short cuts from opening dev tools
   */
  const listener = useCallback((e: KeyboardEvent) => {
    if (e.key === "F12") {
      e.preventDefault()
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      e.preventDefault()
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      e.preventDefault()
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      e.preventDefault()
    }
    if (e.ctrlKey && e.key === "U") {
      e.preventDefault()
    }
  }, [])

  useEffect(() => {
    if (disabled || isMobileSafari || (isIOS && isChrome)) return
    window.addEventListener("keydown", listener)

    return () => {
      window.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileSafari, isIOS, isChrome])

  return { devToolsOpen } as const
}
