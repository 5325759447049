import { Button, ButtonComponentProps } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  gradientButton: {
    animation: "$gradient 20s linear infinite",
    backgroundSize: "200% 200%",
    borderRadius: "999px",
    borderBottom: "solid 4px transparent",
    background:
      "linear-gradient(90deg, #EB964D, #EB483F, #EA59CA, #743FF5, #346DF2, #69DB7B,#EB964D)",
    boxShadow: "2px 1000px 1px #050124 inset",
    "&:focus": {
      outline: "none !important",
    },
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0px 0",
    },
    "100%": {
      backgroundPosition: "100em 0",
    },
  },
})

const GradientButton = ({
  children,
  className,
  ...otherProps
}: ButtonComponentProps<any> & { className?: string }) => {
  const classes = useStyles()

  return (
    <Button className={clsx(classes.gradientButton, className)} {...otherProps}>
      {children}
    </Button>
  )
}

export default GradientButton
