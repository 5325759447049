import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { ScheduleItem } from "./types"
import { urls } from "./urls"

export default {
  getSchedule: async ({
    params,
  }: Config): Promise<APIResponse<ScheduleItem[]>> => {
    try {
      const res = await axios.get(urls.schedule(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
