import { LearningItem } from "api/resources/learningItems/types"
import groupBy from "lodash/groupBy"

import MilestoneAccordion from "./MilestoneAccordion"

interface Props {
  items: LearningItem[]
  onItemSelect: () => void
  currentItem: LearningItem | undefined
}

const MilestoneList = ({ currentItem, items, onItemSelect }: Props) => {
  const milestones = groupBy(items, "milestone.id")

  return (
    <div>
      {Object.entries(milestones).map(([id, items], index) => {
        return (
          <MilestoneAccordion
            currentItem={currentItem}
            key={id}
            items={items}
            totalItems={items.length}
            name={`Milestone ${index + 1}`}
            id={id}
            onItemSelect={onItemSelect}
          />
        )
      })}
    </div>
  )
}

export default MilestoneList
