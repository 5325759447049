import React from "react"

const BookClosed = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3957 16.4956V13.9956H6.56247C5.18178 13.9956 4.0625 15.1149 4.0625 16.4956M8.06246 18.9956H14.7291C15.6625 18.9956 16.1292 18.9956 16.4857 18.8139C16.7993 18.6541 17.0542 18.3992 17.214 18.0856C17.3957 17.7291 17.3957 17.2624 17.3957 16.3289V4.99574C17.3957 4.06233 17.3957 3.59562 17.214 3.23911C17.0542 2.92551 16.7993 2.67054 16.4857 2.51076C16.1292 2.3291 15.6625 2.3291 14.7291 2.3291H8.06246C6.66234 2.3291 5.96228 2.3291 5.42751 2.60158C4.95711 2.84126 4.57466 3.22371 4.33498 3.69411C4.0625 4.22888 4.0625 4.92894 4.0625 6.32906V14.9956C4.0625 16.3957 4.0625 17.0958 4.33498 17.6306C4.57466 18.101 4.95711 18.4834 5.42751 18.7231C5.96228 18.9956 6.66234 18.9956 8.06246 18.9956Z"
        stroke="currentColor"
        strokeWidth="1.49998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BookClosed
