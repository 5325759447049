import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from "@suraasa/placebo-ui"
// import OlympiadLogo from "assets/logos/international-olympiad-logo.svg"
import React from "react"
import { createUseStyles } from "react-jss"
import { getPlatformURL } from "utils/helpers"

const useStyles = createUseStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
  },
  dialogText: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  btn: {
    width: "100%",
  },
}))

type Props = {
  isVisible: boolean
  onClose: () => void
}

const RegisterDialog = ({ isVisible, onClose }: Props) => {
  const classes = useStyles()
  return (
    <Dialog open={isVisible} width="sm" onRequestClose={onClose}>
      <DialogTitle>
        <img
          src="https://assets.suraasa.com/ito/logos/ito-original-two-line.svg"
          alt="logo"
          style={{ marginTop: 6 }}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogText}>
          <Typography variant="title3">Register Now!</Typography>
          <Typography variant="body">
            Looks like you haven’t registered for International Teachers’
            Olympiad yet. Register first to share your referral code with your
            fellow teachers!
          </Typography>
        </div>
        <Button
          component="a"
          variant="filled"
          href={getPlatformURL(
            "suraasa",
            "/international-teachers-olympiad/register"
          )}
          className={classes.btn}
        >
          Register
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default RegisterDialog
