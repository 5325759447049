import { CircularProgress } from "@suraasa/placebo-ui"
import { context } from "global/Context/context"
import React, { useContext, useEffect } from "react"
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { USER_TYPE } from "utils/constants"

import { processAuthCode } from "./processAuthCode"

const ACCOUNTS_ORIGIN = import.meta.env.VITE_SSO_URL

const Authentication = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { isAuthenticated, setAuthInfo } = useContext(context)

  const [searchParams, setSearchParams] = useSearchParams()
  const authCode = searchParams.get("code")

  useEffect(() => {
    const handler = (e: MessageEvent<any>) => {
      if (e.origin !== ACCOUNTS_ORIGIN) {
        return
      }
      if (!isAuthenticated) {
        // Preserve pathname and query params during redirects
        const params = new URLSearchParams()
        params.append("next", `${pathname}${search}`)
        return navigate(`/login?${params.toString()}`, { replace: true })
      }

      if (e.data.code) {
        processAuthCode(e.data.code).then(user => {
          setAuthInfo(user)
        })
      }
    }

    window.addEventListener("message", handler, false)

    // If we have authCode in the URL then we don't need to render the iframe
    if (authCode) {
      processAuthCode(authCode).then(user => {
        setAuthInfo(user)
      })

      searchParams.delete("code")
      setSearchParams(searchParams)
    }

    return () => {
      window.removeEventListener("message", handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const iframeURL = new URL(`${ACCOUNTS_ORIGIN}/iframe-sso`)
  iframeURL.searchParams.append("platform", USER_TYPE)
  iframeURL.searchParams.append("origin", window.location.origin)

  return (
    <>
      {/* TODO: We have an issue here, we're requesting a code on every reload. I think we need to implement a message for "login" event as well. To reduce unwanted calls */}
      {/* // If we have authCode in the URL then we don't need to render the iframe for messaging */}
      {!authCode && (
        <iframe
          title="accounts-sso-iframe"
          id="accounts-sso-iframe"
          src={iframeURL.toString()}
          style={{ display: "none" }}
        />
      )}
      {!isAuthenticated && (
        <div className="flex items-center justify-center p-4">
          <CircularProgress />
        </div>
      )}
      {isAuthenticated && <Outlet />}
    </>
  )
}

export default Authentication
