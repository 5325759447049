import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import UpcomingTasks from "components/UpcomingTasks"
import { Calendar } from "iconoir-react"
import React, { useState } from "react"

import styles from "./ScheduleBar.module.css"

const ScheduleBar = () => {
  const [showSchedule, setShowSchedule] = useState(false)
  return (
    <div>
      <UpcomingTasks
        slideIn={showSchedule}
        onClose={() => setShowSchedule(false)}
      />
      <button
        className={clsx(
          styles.root,
          "px-3 py-2 flex items-center justify-between rounded-2xl mt-4 w-full flex-wrap"
        )}
        onClick={() => setShowSchedule(true)}
      >
        <Typography
          color="common.white.500"
          variant="body"
          className="me-1 text-left"
        >
          See what your next 7 days look like
        </Typography>
        <Calendar
          className={clsx("p-1 shrink-0 hidden sm:block", styles.icon)}
        />
      </button>
    </div>
  )
}

export default ScheduleBar
