import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Theme,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email } from "api/resources/settings/types"
import { APIError } from "api/utils"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
  },
}))

const DeleteEmailConfirmation = ({
  email,
  onDelete,
  open,
  onClose,
}: {
  email: Email | null
  onDelete: () => void
  open: boolean
  onClose: () => void
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    if (email) {
      try {
        await api.settings.emails.delete({ urlParams: { id: email.id } })
        toast.success("Email deleted successfully.", {
          pauseOnHover: false,
          autoClose: 3000,
        })
        onDelete()
        onClose()
      } catch (err) {
        if (err instanceof APIError) {
          onClose()
          toast.error("Something went wrong", {
            autoClose: 3000,
            pauseOnHover: false,
          })
        }
      }
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} width="sm" onRequestClose={onClose}>
      <DialogTitle>Remove Email</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="smallBody">
          Are you sure you want to remove <b>{email?.email}</b>?
        </Typography>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Remove",
            variant: "filled",
            color: "critical",
            onClick: handleDelete,
            loading,
          },
          secondary: {
            label: "Cancel",
          },
        }}
      />
    </Dialog>
  )
}
export default DeleteEmailConfirmation
