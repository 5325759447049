import { Typography, TypographyProps } from "@suraasa/placebo-ui"
import React, { PropsWithChildren } from "react"

const SwitzerTypography = ({
  children,
  style,
  ...props
}: PropsWithChildren<TypographyProps>) => (
  <Typography
    style={{
      fontFamily: "Switzer Regular",
      ...style,
    }}
    {...props}
  >
    {children}
  </Typography>
)

export default SwitzerTypography
