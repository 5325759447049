import { Tag, Typography } from "@suraasa/placebo-ui"
import {
  AssignmentList,
  IQA_STATUS,
  SUBMISSION_STATUS,
} from "api/resources/assignments/types"

import styles from "./Assignments.module.css"

export const getTags = (item: AssignmentList) => {
  if (item.dueDate === null)
    return <Tag size="sm" color="onSurface" label="Not allocated" />

  const submissionTag = getSubmissionTag(item)
  const iqaTag = getIQATag(item)

  return [submissionTag, iqaTag].filter(Boolean)
}

const getSubmissionTag = (item: AssignmentList) => {
  const isOverdue = item.dueDate && new Date() > new Date(item.dueDate)

  if (!item.submissionStatus) {
    if (isOverdue) {
      return <Tag size="sm" color="warning" label="Overdue" />
    }
    return <Tag size="sm" color="onSurface" label="Due" />
  }

  if (item.submissionStatus === SUBMISSION_STATUS.savedForLater && isOverdue) {
    return (
      <div className="flex">
        <Tag size="sm" color="warning" label="Overdue" />
      </div>
    )
  }

  if (item.submissionStatus === SUBMISSION_STATUS.accepted) {
    return <Tag size="sm" color="success" label="Accepted" />
  }

  if (item.submissionStatus === SUBMISSION_STATUS.notReviewed) {
    return <Tag size="sm" color="primary" label="Submitted" />
  }

  if (item.submissionStatus === SUBMISSION_STATUS.underReview) {
    return <Tag size="sm" color="onSurface" label="Under Review" />
  }

  if (item.submissionStatus === SUBMISSION_STATUS.rejected) {
    return <Tag size="sm" color="critical" label="Redo" />
  }
  if (item.submissionStatus === SUBMISSION_STATUS.plagiarised) {
    return <Tag size="sm" color="critical" label="Plagiarised" />
  }

  return null
}

const getIQATag = (item: AssignmentList) => {
  if (item.iqaReviewStatus === null) {
    return null
  }

  if (item.iqaReviewStatus === IQA_STATUS.underReview) {
    return (
      <div className={styles.iqaTag}>
        <span className={styles.iqaInProgressRing} />
        <Typography>IQA in Progress</Typography>
      </div>
    )
  }

  if (item.iqaReviewStatus === IQA_STATUS.accepted) {
    return (
      <div className={styles.iqaTag}>
        <span className={styles.iqaAcceptedRing} />
        <Typography>IQA Done</Typography>
      </div>
    )
  }

  if (item.iqaReviewStatus === IQA_STATUS.rejected) {
    return (
      <div className={styles.iqaTag}>
        <span className={styles.iqaRejectedRing} />
        <Typography>IQA Done</Typography>
      </div>
    )
  }

  return null
}
