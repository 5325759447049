import { theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import GradientButton from "features/ITO/components/GradientButton"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import React from "react"
import { createUseStyles } from "react-jss"
import { getPlatformURL } from "utils/helpers"

import CommunityPng from "./assets/community.png"
import CommunityMobPng from "./assets/community-mob.png"
import CommunityPeople from "./assets/community-people.png"

const useStyles = createUseStyles(theme => ({
  container: {
    backgroundColor: "rgb(255, 255, 255)",
    backgroundImage:
      "radial-gradient(at 100% 15%, rgba(84, 230, 144, 0.2) 0px, transparent 35%), radial-gradient(at 100% 15%, rgba(82, 149, 252, 0.5) 0px, transparent 35%)",
    backgroundSize: "100% 300%",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "rgb(255, 255, 255)",
      backgroundImage:
        "radial-gradient(at 75% 0%, rgba(84, 230, 144, 0.1) 0px, transparent 35%), radial-gradient(at 75% 0%, rgba(82, 149, 252, 0.3) 0px, transparent 35%)",
      backgroundSize: "100% 300%",
    },
  },
}))
const CommunityCard = ({ className }: { className?: string }) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()
  return (
    <div
      className={clsx(
        className,
        classes.container,
        "grid sm:grid-cols-[50%_50%] sm:grid-rows-[auto] items-center grid-cols-[auto] grid-rows-[auto_auto] shadow-[1px_1px_5px_0px_rgba(0,0,0,0.10)] sm:rounded-2xl"
      )}
    >
      <div className="sm:p-4 px-2 py-3">
        <SwitzerTypography
          className="!text-ito-midnight mb-1 font-bold"
          style={{
            fontFamily: "Switzer Bold",
          }}
          variant="title2"
        >
          Your Exclusive Access to ITO Community
        </SwitzerTypography>
        <SwitzerTypography
          className="!text-ito-midnight sm:max-w-[349px] w-full block mb-2"
          variant="largeBody"
          style={{
            fontFamily: "Switzer Regular",
          }}
        >
          Join the International Teachers’ Chat Group to connect with Olympiad
          teacher registrants from different countries, and share your
          excitement for ITO 2023.
        </SwitzerTypography>

        <div className="flex items-center my-2 flex-wrap gap-1">
          <div className="relative mr-4">
            <img src={CommunityPeople} alt="people" className="max-h-[48px]" />
            <div className="absolute top-0 right-[-30px] flex items-center justify-center h-[48px] w-[48px] bg-ito-iris-100  border-2 border-white rounded-full">
              <Typography
                variant="preTitle"
                style={{
                  fontSize: 9,
                }}
              >
                21000+
              </Typography>
            </div>
          </div>
          <Typography variant="preTitle">TEACHERS ALREADY CHATTING</Typography>
        </div>

        <GradientButton
          label="Join Community"
          size="lg"
          component="a"
          target="_blank"
          href={getPlatformURL("suraasa", "/get-app")}
        />
        <Typography variant="smallBody" color="onSurface.500" className="mt-1">
          Access community on the Suraasa mobile app!
        </Typography>
      </div>
      <div className="sm:row-auto row-[1] relative h-full sm:bg-transparent bg-white">
        <img
          alt="linkedin-post"
          className="sm:absolute sm:h-full object-contain pt-0 sm:pt-3.5 bottom-0 left-0 right-0 mx-auto px-2"
          src={isXsDown ? CommunityMobPng : CommunityPng}
        />
      </div>
    </div>
  )
}

export default CommunityCard
