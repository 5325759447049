import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("assignments", {
  list: (itemType, itemId) => ({
    queryKey: ["assignmentList", itemType, itemId],
  }),
  byUUID: (assignmentId: string) => ({
    queryKey: ["assignment", assignmentId],
  }),
  rubrics: (assignmentId: string) => ({
    queryKey: ["assignmentRubrics", assignmentId],
  }),
  reviewRubrics: (reviewId: string) => ({
    queryKey: ["submissionReviewRubrics", reviewId],
  }),
  submissionsListByAssignmentId: (assignmentId: string) => ({
    queryKey: ["assignmentSubmissionsList", assignmentId],
  }),
  submissionsByUUID: (submissionId: string) => ({
    queryKey: ["submission", submissionId],
  }),
})
