import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Divider,
  Select,
  TextField,
  theme,
  toast,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Certification,
  Evidence,
  modeOfLearningChoices,
} from "api/resources/profile/types"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import RemoveDialog from "features/Profile/components/RemoveDialog"
import React, { useState } from "react"
import { useContext, useEffect } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import ProfileContext from "../../../context"
import UploadEvidenceSection from "../UploadEvidenceSection"

type FormData = Omit<Certification, "isVerified" | "id" | "evidences">

type Props = Pick<DialogProps, "open"> & {
  id: Certification["id"] | null
  toggle: () => void
}

const EditDialog = ({ id, open, toggle }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()

  const {
    academics: { certifications },
  } = useContext(ProfileContext)

  const [openRemoveDialog, toggleRemoveDialog] = useState(false)
  const [removeLoading, toggleRemoveLoading] = useState(false)
  const [loading, toggleLoading] = useState(false)

  const isEditable = Boolean(id)

  /**
   * These evidences are uploaded by the user while they are using the application
   * They get converted into `evidenceFiles` when user hits submit.
   */
  const newEvidences = useArray<File | string>([])

  /**
   * These evidences come from the API
   */
  const evidencesFiles = useArray<Evidence>([])
  const evidencesFilesToBeDeleted = useArray<Evidence["id"]>([])

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const resetForm = () => {
    newEvidences.clear()
    evidencesFiles.clear()
    evidencesFilesToBeDeleted.clear()
    reset({})
  }

  const uploadEvidence = async (data: Certification) => {
    const evidences = new FormData()

    newEvidences.array.forEach(item => {
      if (typeof item === "string") {
        evidences.append("url[]", item)
      } else {
        evidences.append("file[]", item)
      }
    })

    const res = await api.profile.certification.evidence.create({
      data: evidences,
      urlParams: {
        id: data.id,
      },
    })
    return res
  }

  const deleteEvidence = async (evidenceId: Certification["id"]) =>
    api.profile.certification.evidence.delete({
      data: {
        evidences: evidencesFilesToBeDeleted.array,
      },
      urlParams: {
        id: evidenceId,
      },
    })

  const handleRemove = async () => {
    if (!id) return

    toggleRemoveLoading(true)
    const res = await api.profile.certification.delete({ urlParams: { id } })

    if (res.isSuccessful) {
      certifications.remove(id)
      toast.success("Removed successfully.")
      toggleRemoveDialog(false)
      toggle()
    } else if (res.errors.message) {
      toast.error(res.errors.message)
    }

    toggleRemoveLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      toggleLoading(true)
      const res = await api.profile.certification.retrieve({
        urlParams: { id: id || "" },
      })

      if (res.isSuccessful) {
        const { evidences, ...data } = res.data
        evidencesFiles.set(evidences)
        reset({ ...data, willExpire: !data.willExpire })
      }
      toggleLoading(false)
    }

    if (isEditable) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditable, reset, toggleLoading])

  const onSubmit = handleSubmit(async formData => {
    const apiData = {
      ...formData,
      willExpire: !formData.willExpire,
      modeOfLearning: modeOfLearningChoices.find(
        ({ value }) => value === formData.modeOfLearning
      )?.value,
      expirationDate: !formData.willExpire ? formData.expirationDate : null,
    }

    if (id) {
      if (evidencesFilesToBeDeleted.array.length > 0) {
        const res = await deleteEvidence(id)
        if (!res.isSuccessful) {
          if (res.errors.message) {
            toast.error(res.errors.message)
          }
          return
        }
      }

      const res = await api.profile.certification.update({
        data: apiData,
        urlParams: { id },
      })

      if (res.isSuccessful) {
        if (newEvidences.array.length > 0) {
          const evidenceRes = await uploadEvidence(res.data)
          if (evidenceRes.isSuccessful) {
            toast.success("Successfully saved.")
            certifications.update(id, {
              ...res.data,
              evidences: [...evidencesFiles.array, ...evidenceRes.data],
            })
            return toggle()
          }
          if (evidenceRes.errors.message)
            return toast.error(evidenceRes.errors.message)
        } else {
          toast.success("Successfully saved.")
          certifications.update(id, res.data)
          return toggle()
        }
      } else {
        handleErrors(res, { setter: setError })
      }
    } else {
      const res = await api.profile.certification.create({
        data: apiData,
      })

      if (res.isSuccessful) {
        if (newEvidences.array.length > 0) {
          const evidenceRes = await uploadEvidence(res.data)
          if (evidenceRes.isSuccessful) {
            toast.success("Successfully saved.")

            certifications.add({ ...res.data, evidences: evidenceRes.data })
            return toggle()
          }
          if (evidenceRes.errors.message)
            return toast.error(evidenceRes.errors.message)
        } else {
          toast.success("Successfully saved.")

          certifications.add(res.data)
          return toggle()
        }
      } else {
        handleErrors(res, { setter: setError })
      }
    }
  })

  const Section1 = () => (
    <>
      <TextField
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
        inputLabelProps={{
          required: true,
        }}
        label="certification Name"
        placeholder="Ex: B. Ed."
        fullWidth
        {...register("name", {
          required: { value: true, message: "Required" },
        })}
      />

      <TextField
        error={Boolean(errors.organisationName)}
        helperText={errors.organisationName?.message}
        inputLabelProps={{
          required: true,
        }}
        label="institute Name"
        placeholder="Ex: Harvard University"
        fullWidth
        {...register("organisationName", {
          required: { value: true, message: "Required" },
        })}
      />
    </>
  )

  const Section2 = () => {
    const willExpire = useWatch({ name: "willExpire", control })
    return (
      <>
        <Checkbox
          {...register("willExpire")}
          label="This certificate does not expire"
        />
        <div
          className={clsx("flex gap-3", {
            "flex-col": isXs,
          })}
        >
          <TextField
            error={Boolean(errors.completionDate)}
            helperText={errors.completionDate?.message}
            inputLabelProps={{
              required: true,
            }}
            label="Completion Date"
            placeholder="Ex: Jan 2021"
            type="date"
            fullWidth
            {...register("completionDate", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            disabled={willExpire}
            error={Boolean(errors.expirationDate)}
            helperText={errors.expirationDate?.message}
            label="Expiration Date (if any)"
            placeholder="Ex: May 2021"
            type="date"
            fullWidth
            {...register("expirationDate", {
              required: { value: !willExpire, message: "Required" },
            })}
          />
        </div>
        <Controller
          control={control}
          name="modeOfLearning"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              error={Boolean(errors.modeOfLearning)}
              helperText={errors.modeOfLearning?.message}
              inputLabelProps={{
                required: true,
              }}
              label="Mode of learning"
              options={modeOfLearningChoices}
              placeholder="Select Learning Mode"
              value={
                value && modeOfLearningChoices.find(c => c.value === value)
              }
              fullWidth
              mountOnBody
              onBlur={onBlur}
              onChange={newValue => onChange(newValue?.value)}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </>
    )
  }

  return (
    <>
      <Dialog
        fullScreen={isXs}
        open={open}
        shouldCloseOnOverlayClick={false}
        width="md"
        onAfterClose={resetForm}
        onRequestClose={toggle}
      >
        <DialogTitle onBack={toggle}>
          {isEditable ? "Edit" : "Add New"} Certification
        </DialogTitle>
        <DialogContent>
          {loading && <LoadingOverlay />}
          <form className="flex flex-col gap-3 mb-3" onSubmit={onSubmit}>
            <Section1 />
            <div>
              <Divider className="my-1" color="onSurface.200" />
            </div>
            <Section2 />
            <div>
              <Divider className="my-1" color="onSurface.200" />
            </div>
          </form>
          <UploadEvidenceSection
            buttonLabel="Add Certificate"
            evidenceFiles={evidencesFiles}
            handleEvidenceFilesToBeDeleted={evidencesFilesToBeDeleted.push}
            inputLabel="Certifications (upload upto 3)"
            limit={3}
            maxSize={5}
            newEvidences={newEvidences}
          />
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              type: "submit",
              loading: isSubmitting,
              onClick: onSubmit,
            },
            tertiary: isEditable
              ? {
                  label: "Remove",
                  color: "critical",
                  variant: "text",
                  onClick: () => toggleRemoveDialog(true),
                }
              : null,
          }}
        />
      </Dialog>
      <RemoveDialog
        handleClose={() => toggleRemoveDialog(false)}
        loading={removeLoading}
        open={openRemoveDialog}
        title="Remove Certification"
        keepScrollLocked
        onRemove={handleRemove}
      >
        Are you sure you want to remove{" "}
        <b>{certifications.data.find(item => item.id === id)?.name}</b> from
        your profile?
      </RemoveDialog>
    </>
  )
}

export default EditDialog
