import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { getCalculateDuration } from "features/ITO/Assessments/helpers"
import React from "react"
import { PropsWithClassName } from "utils/types"

import ProctoringDuringAssessment from "../ProctoringDuringAssessment"
import styles from "./GeneralInstructions.module.css"

type Props = PropsWithClassName<{
  endSlot?: React.ReactNode
  totalQuestions?: number
  duration?: string
  isOlympiad?: boolean
  isProctored?: boolean
  autoCheckCamera?: boolean
}>

const GeneralInstruction = ({
  totalQuestions,
  duration,
  isOlympiad,
  className,
  isProctored,
}: Props) => {
  const instruction = [
    `There will be ${
      totalQuestions || 50
    } multiple choice questions (MCQs) in total.`,
    `The total duration of the ${
      isOlympiad ? "Olympiad" : "Assessment"
    } is ${getCalculateDuration(
      duration || "1:00:00"
    ).trim()}. If you fail to submit it by the deadline, the test will be submitted automatically.`,
    "Unanswered questions or wrong answers will not result in any negative marking.",
    "Using external means to answer questions is strictly forbidden.",
    "Each question will have four options out of which any one can be selected as the answer. Each answer is automatically saved whenever you select an option. You can always change your answer by selecting another option.",
  ]

  return (
    <div className={className}>
      <div className="mb-4">
        <Typography className="mb-1" variant="title2" color="onSurface.800">
          Instructions for the {isOlympiad ? "Olympiad" : "Assessment"}
        </Typography>
        <ol className="pl-3 mb-3 list-decimal">
          {instruction.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
        <div>
          <Typography className="mb-2">
            The Questions Tray displayed on the right side of the screen will
            show the status of each question using one of the following symbols:
          </Typography>
          <div className="flex items-start sm:items-center justify-start gap-3 sm:gap-6 flex-col sm:flex-row">
            <div>
              <div
                className={clsx(
                  "flex items-center justify-center mb-0.5",
                  styles.questionBox,
                  styles.questionNotVisited
                )}
              >
                <Typography variant="strong">1</Typography>
              </div>
              <Typography>Question not yet visited</Typography>
            </div>

            <div>
              <div
                className={clsx(
                  "flex items-center justify-center mb-0.5",
                  styles.questionBox,
                  styles.questionAnswered
                )}
              >
                <Typography variant="strong">1</Typography>
              </div>
              <Typography>Question Answered</Typography>
            </div>

            <div>
              <div
                className={clsx(
                  "flex items-center justify-center mb-0.5",
                  styles.questionBox,
                  styles.questionUnanswered
                )}
              >
                <Typography variant="strong">1</Typography>
              </div>
              <Typography>Question Not Answered</Typography>
            </div>
          </div>
        </div>
      </div>

      {isProctored && (
        <div className="mb-4">
          <Typography variant="title2" className="mb-1">
            Proctoring During the Olympiad
          </Typography>
          <ProctoringDuringAssessment
            hideHeading
            isOlympiad
            maxWarningCount={10}
          />
        </div>
      )}
    </div>
  )
}

export default GeneralInstruction
