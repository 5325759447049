import {
  AssessmentQuestion,
  CaseStudyAnswer,
  CaseStudyQuestion,
  Question,
  QuestionSolution,
  QuestionStatus,
  QuestionType,
} from "api/resources/assessments/types"
import routes from "routes"

export const isCaseStudy = (
  question: AssessmentQuestion
): question is CaseStudyQuestion =>
  question.questionType === QuestionType.CASE_STUDY

export const isCaseStudySolution = (
  question: QuestionSolution
): question is CaseStudyAnswer =>
  question.questionType === QuestionType.CASE_STUDY

export const getQuestionStatus = (
  question: AssessmentQuestion,
  options?: { skipMarkedForReviewCheck?: boolean }
) => {
  if (isCaseStudy(question)) {
    if (
      question.questions.some(
        q => q.status === QuestionStatus.MARKED_FOR_REVIEW
      )
    )
      return QuestionStatus.MARKED_FOR_REVIEW
    if (question.questions.every(q => q.status === QuestionStatus.ANSWERED))
      return QuestionStatus.ANSWERED

    return QuestionStatus.UNATTEMPTED
  }

  if (
    !options?.skipMarkedForReviewCheck &&
    question.status == QuestionStatus.MARKED_FOR_REVIEW
  ) {
    return QuestionStatus.MARKED_FOR_REVIEW
  }

  if (
    question.response != null &&
    question.response.filter(element => element != null).length > 0
  ) {
    return QuestionStatus.ANSWERED
  }

  return QuestionStatus.UNATTEMPTED
}

export const isQuestionOfType = (
  question: QuestionSolution | Question,
  type: keyof typeof QuestionType
) => [QuestionType[type]].includes(question.questionType)

export const startAssessment = ({
  assessmentId,
  onClose,
}: {
  assessmentId: string
  onClose: (ev: MessageEvent) => void
}) => {
  const win = window.open(
    routes.attemptAssessment.replace(":assessmentId", assessmentId)
  )
  if (win)
    win.opener.onmessage = (ev: MessageEvent<{ assessmentId: string }>) => {
      if (ev.origin === window.location.origin) {
        onClose(ev)
      }
    }
}

export const reloadOnAssessmentClose = () => {
  window.location.reload()
}
