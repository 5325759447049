import {
  CircularProgress,
  IconButton,
  TextField,
  TextFieldProps,
  toast,
  Tooltip,
} from "@suraasa/placebo-ui"
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Comment, Discussion } from "api/resources/discussions/types"
import { PaginatedAPIResponse } from "api/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import CustomCircularProgress from "components/CustomCircularProgress"
import { ArrowUp } from "iconoir-react"
import React, { ChangeEvent } from "react"
import { handleErrors, pluralize } from "utils/helpers"
import { useWordCountText } from "utils/hooks/useWordCountText"

import styles from "./CommentField.module.css"

type Props = {
  additionalData?: {
    userPlannerItemId: number
    discussion: number
    course: string
  }
  courseId?: string
  discussionId: string
  name?: string
  className?: string
  gradientClass?: string
  onAdd?: () => void
  showProgressOnInside?: boolean
} & Omit<
  TextFieldProps<any>,
  "onChange" | "placeholder" | "className" | "ref"
> &
  (
    | { isReplyField: true; commentId: string }
    | { isReplyField?: false; commentId?: never }
  )

const MIN_WORD_COUNT = 15

const CommentField = ({
  commentId,
  discussionId,
  name,
  additionalData,
  className,
  gradientClass,
  onAdd,
  isReplyField = false,
  showProgressOnInside,
  courseId,
  ...props
}: Props) => {
  const queryClient = useQueryClient()

  const { setValue, value, wordCount } = useWordCountText({ initialValue: "" })

  const requiredAPI = isReplyField
    ? api.discussions.postReply
    : api.discussions.postComment

  const requiredQueryKey = isReplyField
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      queries.discussions.postReply(commentId!).queryKey
    : queries.discussions.postComment(discussionId).queryKey

  const requiredParams = isReplyField ? commentId : discussionId

  const clearInputField = () => {
    setValue("")
  }

  const { mutate, isLoading } = useMutation({
    mutationKey: requiredQueryKey,
    mutationFn: () =>
      requiredAPI({
        params: {
          id: requiredParams,
        },
        data: {
          text: value,
          ...additionalData,
        },
      }),
    onSuccess: res => {
      if (onAdd) {
        onAdd()
      }

      if (additionalData) {
        toast.success(
          "Your reply has been posted. You can proceed to the next item now."
        )
      }

      clearInputField()

      if (isReplyField) {
        queryClient.setQueryData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          queries.discussions.commentList(discussionId!).queryKey,
          (oldQueryData?: InfiniteData<PaginatedAPIResponse<Comment[]>>) => {
            if (oldQueryData) {
              const oldDataCopy: InfiniteData<PaginatedAPIResponse<Comment[]>> =
                JSON.parse(JSON.stringify(oldQueryData))

              const pageIndex = oldDataCopy.pages.findIndex(item =>
                item.data.map(comment => comment.id === commentId)
              )

              const commentDataIndex = oldDataCopy.pages[
                pageIndex
              ].data.findIndex(item => item.id === commentId)

              oldDataCopy.pages[pageIndex].data[
                commentDataIndex
              ].replies?.unshift(res)

              return oldDataCopy
            }
            return oldQueryData
          }
        )
      } else {
        queryClient.setQueryData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          queries.discussions.commentList(discussionId!).queryKey,
          (oldQueryData?: InfiniteData<PaginatedAPIResponse<Comment[]>>) => {
            if (oldQueryData) {
              const oldDataCopy: InfiniteData<PaginatedAPIResponse<Comment[]>> =
                JSON.parse(JSON.stringify(oldQueryData))

              oldDataCopy.pages[0].data.unshift({ ...res, replies: [] })

              return oldDataCopy
            }
            return oldQueryData
          }
        )
        // TODO: Also need to update discussionList here

        queryClient.setQueryData(
          queries.discussions.list(courseId).queryKey,
          (oldQueryData?: InfiniteData<PaginatedAPIResponse<Discussion[]>>) => {
            if (oldQueryData) {
              const oldDataCopy: InfiniteData<
                PaginatedAPIResponse<Discussion[]>
              > = JSON.parse(JSON.stringify(oldQueryData))

              const pageIndex = oldDataCopy.pages.findIndex(page =>
                page.data.some(discussion => discussion.id === discussionId)
              )

              const discussionDataIndex = oldDataCopy.pages[
                pageIndex
              ].data.findIndex(discussion => discussion.id === discussionId)

              oldDataCopy.pages[pageIndex].data[discussionDataIndex]
                .totalComments++

              return oldDataCopy
            } else {
              return oldQueryData
            }
          }
        )

        queryClient.setQueryData(
          queries.discussions.post(discussionId).queryKey,
          (oldQueryData?: Discussion) => {
            if (!oldQueryData) return

            return {
              ...oldQueryData,
              totalComments: oldQueryData.totalComments + 1,
            }
          }
        )
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const isAllowedToPost = wordCount >= MIN_WORD_COUNT

  const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isAllowedToPost) {
      mutate()
    }
  }

  const getActionButton = () => {
    if (isAllowedToPost) {
      return (
        <div
          className={clsx("z-10", {
            "absolute bottom-1 right-1.5": showProgressOnInside,
          })}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <IconButton
              type="submit"
              size="sm"
              variant="filled"
              className={styles.submitButton}
            >
              <ArrowUp width={20} height={20} />
            </IconButton>
          )}
        </div>
      )
    }
    return (
      <Tooltip
        maxWidth="164px"
        title={`${MIN_WORD_COUNT - wordCount} more ${pluralize(
          "word",
          MIN_WORD_COUNT - wordCount,
          { skipCount: true }
        )} needed to reply`}
      >
        <div
          className={clsx("z-10", {
            "absolute bottom-1 right-1.5": showProgressOnInside,
          })}
        >
          <CustomCircularProgress
            totalValue={MIN_WORD_COUNT}
            circularGradient={gradientClass}
            currentValue={wordCount}
          />
        </div>
      </Tooltip>
    )
  }

  return (
    <form
      onSubmit={onSubmit}
      className={clsx("flex items-center relative", className)}
    >
      {/* @ts-expect-error random-types-issue */}
      <TextField
        onChange={(event: { target: { value: string } }) => {
          setValue(event.target.value)
        }}
        multiLine
        rows={1}
        maxRows={3}
        value={value}
        name="comment"
        placeholder={name ? `Reply to ${name}` : "Reply..."}
        className={clsx(styles.replyTextField, {
          "me-1": !showProgressOnInside,
        })}
        {...props}
      />

      {getActionButton()}
    </form>
  )
}

export default CommentField
