import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"

import { Menu, useMediaQuery } from "@suraasa/placebo-ui"
import { ControlledMenu, MenuButton, useClick } from "@szhsin/react-menu"
import clsx from "clsx"
import { context } from "global/Context/context"
import { NavArrowDown } from "iconoir-react"
import { useContext, useRef } from "react"
import { NavLink, useMatch } from "react-router-dom"
import routes from "routes"

import styles from "./Link.module.css"

type LinkProps = {
  icon: React.ReactNode
  activeIcon?: React.ReactElement
  text?: string
  className?: string
} & (
  | {
      href?: never
      isMenu: true
      arrow?: boolean
      menuChildren: React.ReactNode
      isNotification?: boolean
    }
  | {
      href: string
      isMenu?: false
      menuChildren?: never
      isNotification?: false
    }
)

const Link = ({
  menuChildren,
  icon,
  activeIcon,
  text,
  href,
  className,
  isNotification = false,
  ...props
}: LinkProps) => {
  const is2XS = useMediaQuery("@media only screen and (max-width: 500px)")
  const notificationRouteMatch = useMatch(routes.notifications)
  const ref = useRef(null)

  const [menuState, toggleMenu] =
    useContext(context).notificationsPopupMenuState

  const anchorProps = useClick(menuState.state, toggleMenu)

  if (is2XS) {
    text = ""
  }

  if (props.isMenu && isNotification) {
    const active =
      menuState.state === "open" ||
      menuState.state === "opening" ||
      notificationRouteMatch

    return (
      <>
        <div>
          <button
            type="button"
            ref={ref}
            {...anchorProps}
            className={clsx(
              styles.link,
              "flex flex-col items-center justify-center px-1.5 py-0.75 text-onSurface-500"
            )}
          >
            <div
              className={clsx("active-icon absolute top-1", {
                active: active,
                "text-primary-500": active,
              })}
            >
              {activeIcon}
            </div>
            <div
              className={clsx("icon", {
                active: active,
              })}
            >
              {icon}
            </div>
            {text && (
              <p
                className={clsx("flex text-sm font-semibold", {
                  "text-primary-500": active,
                })}
              >
                <span className="me-0.25">{text}</span>
                {props.arrow && <NavArrowDown />}
              </p>
            )}
          </button>
        </div>

        <ControlledMenu
          className={clsx(styles.menuWrapper, className, {
            [styles.isNotification]: isNotification,
          })}
          {...menuState}
          anchorRef={ref}
          onClose={() => toggleMenu(false)}
        >
          {menuChildren}
        </ControlledMenu>
      </>
    )
  }

  if (props.isMenu) {
    if (props.arrow === undefined) {
      props.arrow = true
    }
    return (
      <div
        className={clsx(styles.menuWrapper, className, {
          [styles.isNotification]: isNotification,
        })}
      >
        <Menu
          menuButton={({ open }) => {
            const active = open || (notificationRouteMatch && isNotification)
            return (
              <MenuButton
                className={clsx(
                  styles.link,
                  "flex flex-col items-center justify-center px-1.5 py-0.75 text-onSurface-500"
                )}
              >
                <div
                  className={clsx("active-icon absolute top-1", {
                    active: active,
                    "text-primary-500": active,
                  })}
                >
                  {activeIcon ?? icon}
                </div>
                <div
                  className={clsx("icon", {
                    active: active,
                  })}
                >
                  {icon}
                </div>
                {text && (
                  <p
                    className={clsx("flex text-sm font-semibold", {
                      "text-primary-500": active,
                    })}
                  >
                    <span className="me-0.25">{text}</span>
                    {props.arrow && <NavArrowDown />}
                  </p>
                )}
              </MenuButton>
            )
          }}
        >
          {menuChildren}
        </Menu>
      </div>
    )
  }

  if (!href) throw new Error("Link supplied without href")

  return (
    <NavLink to={href}>
      {({ isActive }) => (
        <div
          className={clsx(
            styles.link,
            "relative flex flex-col items-center justify-center px-1.5 py-0.75 text-onSurface-500",
            {
              "text-primary-500": isActive,
            }
          )}
        >
          <div
            className={clsx("active-icon absolute top-1", {
              active: isActive,
            })}
          >
            {activeIcon ?? icon}
          </div>
          <div
            className={clsx("icon", {
              active: isActive,
            })}
          >
            {icon}
          </div>
          {text && <p className="text-sm font-semibold">{text}</p>}
        </div>
      )}
    </NavLink>
  )
}

export default Link
