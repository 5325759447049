import { useEffect, useRef, useState } from "react"
import Webcam from "react-webcam"

export type Status = "on" | "off" | "blocked"
export type Violation = "noFace"

type Props = {
  disabled?: boolean
}

export const releaseMediaControl = (stream: MediaStream) => {
  stream.getTracks().forEach(track => track.stop())
}

type CameraStatus =
  | {
      status: Exclude<Status, "off" | "blocked">
      stream: MediaStream
    }
  | {
      status: Exclude<Status, "on">
      stream?: undefined
    }

export const getCameraPermission: (options?: {
  initiate?: boolean
}) => Promise<CameraStatus> = async options => {
  let stream: MediaStream
  if (options?.initiate) {
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      })
      return { status: "on", stream }
    } catch (e) {
      console.error("Camera permission blocked")
    }
  }

  const devices = await navigator.mediaDevices.enumerateDevices()

  if (
    devices
      .filter(({ kind }) => kind === "videoinput")
      .some(({ label }) => label === "")
  ) {
    return { status: "blocked" }
  } else {
    /**
     * Phone does not allow to re-initiate this request
     */
    // if (isMobile) {
    //   return { status: "on", stream: stream }
    // } else {

    // }
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      })
      return { status: "on", stream: stream }
    } catch {
      return { status: "blocked" }
    }
  }
}

export function useCamDetection({ disabled }: Props = { disabled: false }) {
  const [webCamStatus, setWebCamStatus] = useState<Status | null>(null)

  const videoRef = useRef<Webcam>(null)
  /**
   * To get all the input devices list and check their permissions
   * if label of videoinput is empty means that permission to access that device is not granted
   */

  // useEffect(() => {
  //   // if proctoring is disabled then simply return
  //   if (disabled) return

  //   // if user has given permission for camera, video is played
  //   if (webCamStatus === "on") playVideo(videoRef)
  // }, [disabled, videoRef, webCamStatus])

  /**
   * if user pauses the video then video is forced to replay
   */
  // const replayVideo = useCallback(() => {
  //   if (disabled) return

  //   const video = videoRef.current
  //   if (webCamStatus === "off") {
  //     video?.play()
  //     setWebCamStatus("on")
  //   }
  // }, [webCamStatus, disabled])

  useEffect(() => {
    /**
     * on every reload call this before enumerateDevices()
     * otherwise it will return empty array and webCamStatus will always stay blocked
     */
    let getPermissionsInterval: ReturnType<typeof setInterval>

    const requestCam = async () => {
      const camera = await getCameraPermission({ initiate: true })
      setWebCamStatus(camera.status)
      // Check camera permissions every minute
      getPermissionsInterval = setInterval(() => {
        if (webCamStatus !== "on")
          getCameraPermission().then(camera => setWebCamStatus(camera.status))
      }, 20 * 1000)
    }

    if (!disabled && webCamStatus !== "on") {
      requestCam()
    }

    return () => {
      clearInterval(getPermissionsInterval)
    }
  }, [disabled, webCamStatus])

  return {
    webCamStatus,
    videoRef,
  } as const
}
