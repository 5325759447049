import { intervalToDuration } from "date-fns"

export const getTimestampFromSeconds = (timeInSeconds: number) => {
  const customPadStart = (number = 0) => number.toString().padStart(2, "0")

  const { minutes, seconds, hours } = intervalToDuration({
    start: 0,
    end: timeInSeconds * 1000,
  })

  if (timeInSeconds <= 60 * 60) {
    return `${customPadStart(minutes)}:${customPadStart(seconds)}`
  } else {
    return `${customPadStart(hours)}:${customPadStart(
      minutes
    )}:${customPadStart(seconds)}`
  }
}
