import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Divider,
  Select,
  TextField,
  theme,
  toast,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Country, State } from "api/resources/global/types"
import {
  EmploymentType,
  OrganisationType,
  WorkExperienceType,
} from "api/resources/profile/types"
import { Config } from "api/types"
import clsx from "clsx"
// import { GlobalContext } from "components/GlobalState"
import LoadingOverlay from "components/LoadingOverlay"
import RemoveDialog from "features/Profile/components/RemoveDialog"
import pick from "lodash/pick"
import React, { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ActionMeta } from "react-select"
import { getAuthInfo } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"

import ProfileContext from "../../context"

const formDataKeys = [
  "title",
  "description",
  "organisationName",
  "currentlyWorking",
  "startDate",
  "endDate",
  "currencyId",
  "salary",
  "employmentType",
  "subjects",
  "organisationType",
  "curriculumId",
  "teachingLevelId",
  "teachingRoleId",
  "curriculum",
  "teachingLevel",
  "teachingRole",
  "countryId",
  "stateId",
  "otherOrganisationType",
  "otherCurriculum",
  "otherTeachingLevel",
  "otherTeachingRole",
] as const

type FormData = Omit<WorkExperienceType, "subjects" | "curriculum"> & {
  subjects: string[]
  curriculumId: string | null
  teachingLevelId: string | null
  teachingRoleId: string | null
  otherTeachingLevel: string | null
  otherTeachingRole: string | null
  otherCurriculum: string | null
}

type Option = {
  label: string
  value: string
  isCustom?: boolean
}

type Props = Pick<DialogProps, "open"> & {
  id: WorkExperienceType["id"] | null
  handleClose: () => void
}

const defaultOrganizationOptions = [
  { label: "School", value: OrganisationType.SCHOOL },
  {
    label: "College/University",
    value: OrganisationType.COLLEGE_OR_UNIVERSITY,
  },
  {
    label: "Tutoring",
    value: OrganisationType.TUTORING,
  },
]

const employmentTypeOptions = [
  { label: "Full Time", value: EmploymentType.FULL_TIME },
  { label: "Part time", value: EmploymentType.PART_TIME },
  { label: "Fresher", value: EmploymentType.FRESHER },
  { label: "Intern", value: EmploymentType.INTERN },
]

const EditDialog = ({
  id,
  open,
  onUpdate,
  handleClose: toggle,
}: Props & { onUpdate: () => void }) => {
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    control,
    watch,
    reset,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()

  const authInfo = getAuthInfo()

  // TODO(profile):
  // const { countries, subjects } = useContext(GlobalContext)

  const { subjects, countries } = useResource(["subjects", "countries"])
  const { workExperiences } = useContext(ProfileContext)

  const [organisationTypeOptions, setOrganisationTypeOptions] = useState<
    {
      label: string
      value: string | number
    }[]
  >(defaultOrganizationOptions)
  /**
   * undefined --> options are being fetched(loading...)
   * null --> default || api call failed || options don't exist
   */
  const [states, setStates] = useState<State[] | null | undefined>(null)
  const [curriculumIdOptions, setCurriculumIdOptions] = useState<
    Option[] | null | undefined
  >(null)
  const [teachingLevelIdOptions, setTeachingLevelIdOptions] = useState<
    Option[] | null | undefined
  >(null)
  const [teachingRoleIdOptions, setTeachingRoleIdOptions] = useState<
    Option[] | null | undefined
  >(null)

  const [currencySymbol, setCurrencySymbol] = useState<string>()

  const [openRemoveDialog, toggleRemoveDialog] = useState(false)
  const [removeLoading, toggleRemoveLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const isEditable = Boolean(id)
  const organisationType = watch("organisationType")

  /**
   * Creates options for these keys
   * otherOrganisationType,otherTeachingLevel,* otherTeachingRole
   */
  const createOtherOptions = (
    key: keyof Pick<
      FormData,
      "otherOrganisationType" | "otherTeachingLevel" | "otherTeachingRole"
    >,
    option: Option
  ) => {
    // const option: Option = { label: value, value }

    switch (key) {
      case "otherOrganisationType":
        setOrganisationTypeOptions(prevState => [...prevState, option])
        break
      case "otherTeachingLevel":
        setTeachingLevelIdOptions(prevState => [...(prevState ?? []), option])
        break
      case "otherTeachingRole":
        setTeachingRoleIdOptions(prevState => [...(prevState ?? []), option])
        break
      default:
        break
    }
  }
  const fetchStates = async (countryId: Country["uuid"]) => {
    setStates(undefined)
    setValue("stateId", null)
    const res = await api.global.listStates({
      urlParams: {
        countryId,
      },
      params: {
        page: -1,
      },
    })
    if (res.isSuccessful) {
      setStates(res.data)
    } else {
      setStates(null)
    }
  }

  const fetchTeachingLevels = async ({ params }: Config["params"]) => {
    setTeachingLevelIdOptions(undefined)
    const res = await api.global.teachingLevel.list({
      params,
    })
    if (res.isSuccessful) {
      setTeachingLevelIdOptions(s => [
        ...(s ?? []),
        ...res.data.map(item => ({ label: item.name, value: item.uuid })),
      ])
    } else {
      setTeachingLevelIdOptions(null)
    }
    return res
  }

  const fetchTeachingRoles = async ({ params }: Config["params"]) => {
    setTeachingRoleIdOptions(undefined)
    const res = await api.global.teachingRole.list({
      params,
    })
    if (res.isSuccessful) {
      setTeachingRoleIdOptions(
        res.data.map(item => ({ label: item.name, value: item.uuid }))
      )
    } else {
      setTeachingRoleIdOptions([])
    }
    return res
  }

  const fetchCurricula = async (option?: Option) => {
    setCurriculumIdOptions(undefined)
    const res = await api.global.curriculum.list({ params: { page: -1 } })
    if (res.isSuccessful) {
      const data = res.data.map(item => ({
        label: item.name,
        value: item.uuid,
      }))
      if (option) {
        data.push(option)
      }
      setCurriculumIdOptions(data)
    }
  }

  const setCurrency = async (countryID: FormData["countryId"]) => {
    const res = await api.global.listCurrencies({
      params: {
        countryID,
      },
    })
    if (res.isSuccessful) {
      if (res.data[0]) {
        setValue("currencyId", res.data[0].uuid)
        setCurrencySymbol(res.data[0].symbol)
      }
    }
  }

  useEffect(() => {
    if (!isEditable)
      if (organisationType === OrganisationType.SCHOOL) {
        fetchCurricula()
      }
  }, [organisationType, isEditable])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await api.profile.workExperiences.retrieve({
        urlParams: { id: id || "" },
      })
      if (res.isSuccessful) {
        const data = pick(res.data, formDataKeys)

        if (data.otherOrganisationType) {
          /**
           * We are using otherOrganisationType as organisationType
           * because the <Select> is hooked to 'organisationType' and we need to
           * display 'otherOrganisationType' as its value
           */
          // @ts-expect-error if user types other option then it will be a string
          data.organisationType = data.otherOrganisationType
          createOtherOptions("otherOrganisationType", {
            label: data.otherOrganisationType,
            value: data.otherOrganisationType,
          })
        }

        const { curriculum } = data
        let isCurriculumCustom = false
        if (curriculum) {
          // This means that curriculum was custom
          if (curriculum.isApproved === false) {
            isCurriculumCustom = true

            fetchCurricula({
              value: curriculum.uuid,
              label: curriculum.name,
            })
            await fetchTeachingLevels({
              params: { curriculum__is_default: true },
            })
          } else {
            fetchCurricula()
            await fetchTeachingLevels({
              params: { curriculumID: curriculum.uuid },
            })
          }
        }

        const { teachingLevel } = data
        let isTLCustom = false

        if (teachingLevel) {
          // This means that Teaching Level was custom
          if (
            !teachingLevelIdOptions?.find(
              ({ value }) => value === teachingLevel.uuid
            )
          ) {
            isTLCustom = true
            createOtherOptions("otherTeachingLevel", {
              label: teachingLevel.name,
              value: teachingLevel.uuid,
            })
          }
          if (isCurriculumCustom && !isTLCustom)
            await fetchTeachingRoles({
              params: {
                curriculum__is_default: true,
                teachingLevelID: teachingLevel.uuid,
              },
            })

          if (!isCurriculumCustom && !isTLCustom)
            await fetchTeachingRoles({
              params: {
                curriculumID: curriculum?.uuid,
                teachingLevelID: teachingLevel.uuid,
              },
            })
        }

        const { teachingRole } = data
        if (teachingRole) {
          createOtherOptions("otherTeachingRole", {
            label: teachingRole.name,
            value: teachingRole.uuid,
          })
        }

        if (data.countryId) {
          setCurrency(data.countryId)
          fetchStates(data.countryId)
        }

        reset({
          ...data,
          curriculumId: curriculum?.uuid,
          teachingLevelId: teachingLevel?.uuid,
          teachingRoleId: teachingRole?.uuid,
          subjects: data.subjects ? data.subjects.map(i => i.subjectId) : [],
        })
      }
      setLoading(false)
    }

    if (isEditable) {
      fetchData()
    } else {
      reset({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditable, reset, setLoading])

  const formatData = (data: FormData) => {
    if (data.curriculumId === undefined) {
      data.curriculumId = null
    }
    if (data.teachingLevelId === undefined) {
      data.teachingLevelId = null
    }
    if (data.teachingRoleId === undefined) {
      data.teachingRoleId = null
    }
    return {
      ...data,
      organisationType: data.otherOrganisationType
        ? null
        : data.organisationType,
      currentlyWorking: Boolean(data.currentlyWorking),
      endDate: data.currentlyWorking ? null : data.endDate,

      curriculumId: data.curriculumId,
      teachingLevelId: data.teachingLevelId,
      teachingRoleId: data.teachingRoleId,

      otherOrganisationType: data.otherOrganisationType
        ? data.otherOrganisationType
        : null,

      otherCurriculum: data.otherCurriculum ? data.otherCurriculum : null,

      otherTeachingLevel: data.otherTeachingLevel
        ? data.otherTeachingLevel
        : null,

      otherTeachingRole: data.otherTeachingRole ? data.otherTeachingRole : null,
    }
  }

  const createCurriculumBoard = (curriculum: string) =>
    api.global.curriculum.create({
      data: {
        new: [
          {
            curriculum,
            userUuid: authInfo?.user.uuid,
          },
        ],
      },
    })
  const createTeachingLevel = (teachingLevel: string) =>
    api.global.teachingLevel.create({
      data: {
        new: [
          {
            teachingLevel,
            curriculumId: getValues("curriculumId") || undefined,
            userUuid: authInfo?.user.uuid,
          },
        ],
      },
    })
  const createTeachingRole = (teachingRole: string) =>
    api.global.teachingRole.create({
      data: {
        new: [
          {
            teachingRole,
            teachingLevelId: getValues("teachingLevelId") || undefined,
            curriculumId: getValues("curriculumId") || undefined,
            userUuid: authInfo?.user.uuid,
          },
        ],
      },
    })

  const handleRemove = async () => {
    if (!id) return

    toggleRemoveLoading(true)
    const res = await api.profile.workExperiences.delete({ urlParams: { id } })

    if (res.isSuccessful) {
      workExperiences.remove(id)
      toast.success("Removed successfully.")
      onUpdate()
      toggleRemoveDialog(false)
      toggle()
    } else if (res.errors.message) {
      toast.error(res.errors.message)
    }

    toggleRemoveLoading(false)
  }

  const resetForm = () => {
    reset({})
    setOrganisationTypeOptions(defaultOrganizationOptions)
    setStates(null)
    setCurriculumIdOptions(null)
    setTeachingLevelIdOptions(null)
    setTeachingRoleIdOptions(null)
  }

  const getCBParams = (): any => {
    const params: any = {}

    if (getValues("curriculumId") === getValues("otherCurriculum")) {
      params.curriculum__is_default = true
    } else {
      params.curriculumID = getValues("curriculumId")
    }
    return params
  }

  const handleOrganizationSchoolFields = (
    option: Option | null,

    actionMeta: ActionMeta<Option>
  ) => {
    switch (
      actionMeta.name as keyof Pick<
        WorkExperienceType,
        "curriculumId" | "teachingLevelId" | "teachingRoleId"
      >
    ) {
      case "curriculumId":
        if (actionMeta.action === "create-option") {
          createCurriculumBoard(actionMeta.option.value).then(res => {
            if (res.isSuccessful) {
              const { name, uuid } = res.data[0]
              setValue("otherCurriculum", uuid)
              setValue("curriculumId", uuid)
              setCurriculumIdOptions(
                i => i && [{ label: name, value: uuid, isCustom: true }, ...i]
              )
              fetchTeachingLevels({ params: getCBParams() })
            }
          })
        }

        if (actionMeta.action === "select-option") {
          setValue("curriculumId", option?.value ?? null)
          setValue("otherCurriculum", null)
          setCurriculumIdOptions(c => c?.filter(i => !i.isCustom))
          fetchTeachingLevels({
            params: getCBParams(),
          })
        }

        if (actionMeta.action === "clear") {
          setValue("otherCurriculum", null)
          setValue("curriculumId", null)
          setCurriculumIdOptions(c => c?.filter(i => !i.isCustom))
          setTeachingLevelIdOptions([])
          setTeachingRoleIdOptions([])
        }
        break

      case "teachingLevelId":
        if (actionMeta.action === "create-option") {
          createTeachingLevel(actionMeta.option.value).then(res => {
            if (res.isSuccessful) {
              const { name, uuid } = res.data[0]
              setValue("teachingLevelId", uuid)
              setValue("otherTeachingLevel", uuid)
              setTeachingLevelIdOptions(
                i => i && [{ label: name, value: uuid, isCustom: true }, ...i]
              )
              setTeachingRoleIdOptions([])
            }
          })
        }

        if (actionMeta.action === "select-option") {
          setValue("teachingLevelId", option?.value ?? null)
          setValue("otherTeachingLevel", null)

          setTeachingLevelIdOptions(c => c?.filter(i => !i.isCustom))
          fetchTeachingRoles({
            params: {
              ...getCBParams(),
              teachingLevelID: getValues("teachingLevelId"),
            },
          })
        }

        if (actionMeta.action === "clear") {
          setValue("teachingLevelId", null)
          setValue("otherTeachingLevel", null)
          setTeachingLevelIdOptions(c => c?.filter(i => !i.isCustom))
          setTeachingRoleIdOptions([])
        }
        break

      case "teachingRoleId":
        if (actionMeta.action === "create-option") {
          createTeachingRole(actionMeta.option.value).then(res => {
            if (res.isSuccessful) {
              const { name, uuid } = res.data[0]
              setValue("teachingRoleId", uuid)
              setValue("otherTeachingRole", uuid)
              setTeachingRoleIdOptions(
                i => i && [{ label: name, value: uuid, isCustom: true }, ...i]
              )
            }
          })
        }

        if (actionMeta.action === "select-option") {
          setValue("teachingRoleId", option?.value ?? null)
          setValue("otherTeachingRole", null)
          setTeachingRoleIdOptions(c => c?.filter(i => !i.isCustom))
        }

        if (actionMeta.action === "clear") {
          setValue("teachingRoleId", null)
          setValue("otherTeachingRole", null)
          setTeachingLevelIdOptions(c => c?.filter(i => !i.isCustom))
        }
        break

      default:
        break
    }

    return null
  }

  const onSubmit = handleSubmit(async formData => {
    if (id) {
      const res = await api.profile.workExperiences.update({
        data: formatData(formData),
        urlParams: { id },
      })
      if (res.isSuccessful) {
        workExperiences.update(id, res.data)
        toast.success("Successfully saved.")
        onUpdate()
        return toggle()
      }

      if (res.errors.message) toast.error(res.errors.message)
      if (res.errors.fieldErrors)
        for (const [key, value] of Object.entries(
          pick(res.errors.fieldErrors, formDataKeys)
        )) {
          // @ts-expect-error TODO(profile): test this
          setError(key as keyof FormData, { message: value })
        }
    } else {
      const res = await api.profile.workExperiences.create({
        data: formatData(formData),
      })
      if (res.isSuccessful) {
        workExperiences.add(res.data)
        toast.success("Added successfully.")
        onUpdate()
        return toggle()
      }
      if (res.errors.message) toast.error(res.errors.message)
      if (res.errors.fieldErrors)
        for (const [key, value] of Object.entries(res.errors.fieldErrors)) {
          // @ts-expect-error TODO(profile): test this
          setError(key as keyof FormData, { message: value })
        }
    }
  })

  const TitleAndDescription = () => (
    <>
      <TextField
        error={Boolean(errors.title)}
        helperText={errors.title?.message}
        inputLabelProps={{ required: true }}
        label="Title"
        placeholder="Ex: Head of Department"
        fullWidth
        {...register("title", {
          required: { value: true, message: "Required" },
        })}
      />

      <Controller
        control={control}
        name="employmentType"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            error={Boolean(errors.employmentType)}
            helperText={errors.employmentType?.message}
            inputLabelProps={{ required: true }}
            label="Employment Type"
            options={employmentTypeOptions}
            value={value && employmentTypeOptions.find(c => c.value === value)}
            fullWidth
            isClearable
            mountOnBody
            onBlur={onBlur}
            onChange={val => onChange(val?.value)}
          />
        )}
        rules={{
          required: { value: true, message: "Required" },
        }}
      />
      <Controller
        control={control}
        name="subjects"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            error={Boolean(errors.subjects)}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            helperText={errors.subjects && errors.subjects.message}
            inputLabelProps={{ required: true }}
            label="subjects"
            options={subjects}
            value={value && subjects.filter(c => value.includes(c.uuid))}
            fullWidth
            isClearable
            isMulti
            mountOnBody
            onBlur={onBlur}
            onChange={val => onChange(val?.map(item => item.uuid))}
          />
        )}
        rules={{
          required: { value: true, message: "Required" },
        }}
      />
      {/* @ts-expect-error random-types-issue */}
      <TextField
        charLimit={400}
        error={Boolean(errors.description)}
        helperText={errors.description?.message}
        label="Description"
        maxRows={20}
        rows={3}
        fullWidth
        multiLine
        {...register("description", {
          maxLength: {
            value: 400,
            message: "Try to keep it short",
          },
        })}
      />
    </>
  )

  const currentlyWorking = watch("currentlyWorking") || false

  const curriculumId = watch("curriculumId")
  const teachingLevelId = watch("teachingLevelId")

  return (
    <>
      <Dialog
        fullScreen={isXs}
        open={open}
        width="md"
        onAfterClose={resetForm}
        onRequestClose={toggle}
      >
        <DialogTitle onBack={toggle}>
          {isEditable ? "Edit" : "Add"} Work Experience
        </DialogTitle>

        <DialogContent>
          <form className="flex flex-col gap-3" onSubmit={onSubmit}>
            {loading && <LoadingOverlay />}

            <TitleAndDescription />
            <div className="my-1">
              <Divider />
            </div>

            <TextField
              error={Boolean(errors.organisationName)}
              helperText={errors.organisationName?.message}
              inputLabelProps={{ required: true }}
              label="Organisation/institute name"
              placeholder="Ex: Harvard University or Byjus"
              {...register("organisationName", {
                required: {
                  value: true,
                  message: "Required",
                },
              })}
              fullWidth
            />

            <Controller
              control={control}
              name="organisationType"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  error={Boolean(errors.organisationType)}
                  helperText={errors.organisationType?.message}
                  inputLabelProps={{ required: true }}
                  label="organisation type"
                  options={organisationTypeOptions}
                  value={
                    value
                      ? organisationTypeOptions.find(c => c.value === value)
                      : null
                  }
                  createable
                  fullWidth
                  isClearable
                  mountOnBody
                  onBlur={onBlur}
                  onChange={(option, actionMeta) => {
                    onChange(option?.value)

                    clearErrors("curriculumId")
                    clearErrors("teachingLevelId")
                    clearErrors("teachingRoleId")

                    if (actionMeta.action === "create-option") {
                      if (option) {
                        setOrganisationTypeOptions(i => [option, ...i])
                        setValue("otherOrganisationType", String(option.value))
                      }
                    }
                    if (actionMeta.action === "clear") {
                      setOrganisationTypeOptions(defaultOrganizationOptions)
                      setValue("otherOrganisationType", null)
                    }
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />

            {organisationType === OrganisationType.SCHOOL && (
              <>
                <Controller
                  control={control}
                  name="curriculumId"
                  render={({ field: { onBlur, value } }) => (
                    <Select
                      error={Boolean(errors.curriculumId)}
                      helperText={errors.curriculumId?.message}
                      inputLabelProps={{ required: true }}
                      isLoading={curriculumIdOptions === undefined}
                      label="Curriculum Board"
                      name="curriculumId"
                      options={curriculumIdOptions ?? []}
                      placeholder="Ex: IB"
                      value={
                        value
                          ? curriculumIdOptions &&
                            curriculumIdOptions.find(c => c.value === value)
                          : null
                      }
                      createable
                      fullWidth
                      isClearable
                      mountOnBody
                      onBlur={onBlur}
                      onChange={(option, actionMeta) => {
                        setValue("teachingLevelId", "")
                        setValue("teachingRoleId", "")
                        handleOrganizationSchoolFields(option, actionMeta)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />

                <Controller
                  control={control}
                  name="teachingLevelId"
                  render={({ field: { onBlur, value } }) => (
                    <Select
                      error={Boolean(errors.teachingLevelId)}
                      helperText={errors.teachingLevelId?.message}
                      inputLabelProps={{ required: true }}
                      isDisabled={!curriculumId}
                      isLoading={teachingLevelIdOptions === undefined}
                      label="teaching level"
                      name="teachingLevelId"
                      options={teachingLevelIdOptions ?? []}
                      placeholder="Ex: Senior Secondary"
                      value={
                        value
                          ? teachingLevelIdOptions &&
                            teachingLevelIdOptions.find(c => c.value === value)
                          : null
                      }
                      createable
                      fullWidth
                      isClearable
                      mountOnBody
                      onBlur={onBlur}
                      onChange={(option, actionMeta) => {
                        setValue("teachingRoleId", "")
                        handleOrganizationSchoolFields(option, actionMeta)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
                <Controller
                  control={control}
                  name="teachingRoleId"
                  render={({ field: { onBlur, value } }) => (
                    <Select
                      error={Boolean(errors.teachingRoleId)}
                      helperText={errors.teachingRoleId?.message}
                      inputLabelProps={{ required: true }}
                      isDisabled={!teachingLevelId || !curriculumId}
                      isLoading={teachingRoleIdOptions === undefined}
                      label="teaching role"
                      name="teachingRoleId"
                      options={teachingRoleIdOptions ?? []}
                      placeholder="Ex: Class Teacher"
                      value={
                        value
                          ? teachingRoleIdOptions &&
                            teachingRoleIdOptions.find(c => c.value === value)
                          : null
                      }
                      createable
                      fullWidth
                      isClearable
                      mountOnBody
                      onBlur={onBlur}
                      onChange={(option, actionMeta) => {
                        handleOrganizationSchoolFields(option, actionMeta)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
              </>
            )}

            <div
              className={clsx("flex gap-3", {
                "flex-col": isXs,
              })}
            >
              <Controller
                control={control}
                name="countryId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    error={Boolean(errors.countryId)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    helperText={errors.countryId?.message}
                    inputLabelProps={{ required: true }}
                    label="country"
                    options={countries}
                    value={value ? countries.find(c => c.uuid === value) : null}
                    fullWidth
                    isClearable
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => {
                      if (val) {
                        fetchStates(val.uuid)
                        setCurrency(val.uuid)
                      }
                      onChange(val ? val.uuid : "")
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />

              <Controller
                control={control}
                name="stateId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    error={Boolean(errors.stateId)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    helperText={errors.stateId?.message}
                    inputLabelProps={{ required: true }}
                    isLoading={states === undefined}
                    label="State/Region/Province"
                    options={states ?? []}
                    placeholder="Ex: Dubai"
                    value={
                      value
                        ? states && states.find(c => c.uuid === value)
                        : null
                    }
                    fullWidth
                    isClearable
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val ? val.uuid : "")}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
            </div>
            <div className="my-1">
              <Divider />
            </div>
            <span>
              <Controller
                control={control}
                name="currentlyWorking"
                render={({ field: { onChange, onBlur, value } }) => (
                  // TODO: placebo-issue gives console warning if we don't specify defaultChecked={undefined}
                  <Checkbox
                    checked={value}
                    label="Currently Working"
                    onBlur={onBlur}
                    onChange={e => onChange(e.target.checked)}
                  />
                )}
              />
            </span>

            <div
              className={clsx("flex gap-3", {
                "flex-col": isXs,
              })}
            >
              <TextField
                error={Boolean(errors.startDate)}
                helperText={errors.startDate?.message}
                inputLabelProps={{ required: true }}
                label="start date"
                placeholder="Ex: Jan 2021"
                type="date"
                fullWidth
                {...register("startDate", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                disabled={currentlyWorking}
                error={!currentlyWorking && Boolean(errors.endDate)}
                helperText={
                  !currentlyWorking ? errors.endDate?.message : undefined
                }
                inputLabelProps={{ required: !currentlyWorking }}
                label="end date"
                placeholder="Ex: May 2021"
                type="date"
                fullWidth
                {...register("endDate", {
                  required: { value: !currentlyWorking, message: "Required" },
                })}
              />
            </div>
            <TextField
              className="mb-2"
              error={Boolean(errors.salary)}
              helperText={errors.salary?.message}
              label="Salary P.A. (Will not show on your profile)"
              placeholder="20,000"
              startAdornment={currencySymbol || "₹"}
              type="number"
              fullWidth
              {...register("salary")}
            />
          </form>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              type: "submit",
              loading: isSubmitting,
              onClick: onSubmit,
            },
            tertiary: isEditable
              ? {
                  label: "Remove",
                  variant: "text",
                  color: "critical",
                  onClick: () => toggleRemoveDialog(true),
                }
              : null,
          }}
        />
      </Dialog>
      <RemoveDialog
        handleClose={() => toggleRemoveDialog(false)}
        loading={removeLoading}
        open={openRemoveDialog}
        title="Remove Work Experience"
        keepScrollLocked
        onRemove={handleRemove}
      >
        Are you sure you want to remove{" "}
        <b>{workExperiences.data.find(item => item.id === id)?.title}</b> from
        your profile?
      </RemoveDialog>
    </>
  )
}

export default EditDialog
