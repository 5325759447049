import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import { Button, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import Monitor from "assets/Fallback/monitor.svg"
import CourseFallback from "assets/Placeholder/course_placeholder.svg"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import CourseInfoAndProgress from "features/Library/CourseInfoAndProgress"
import ResumeCourseCard from "features/Library/ResumeCourseCard"
import { Lock } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"

import { useQualificationDetails } from "../../"

const Modules = ({ slug }: { slug: string }) => {
  const { learningItemType } = useParams() as { learningItemType: string }

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { qualification } = useQualificationDetails()

  const {
    isLoading,
    isError,
    data: plannerList,
  } = useQuery({
    enabled: Boolean(qualification.id),
    queryFn: () =>
      api.learningItems.getPlannerList({
        params: {
          learningItemType: learningItemType,
          learningItemId: qualification.id,
        },
      }),
    queryKey: queries.learningItems.listPlanners(qualification.id).queryKey,
  })

  const { data: lastAccessedItem, isSuccess: isLastItemSuccess } = useQuery({
    enabled: Boolean(qualification.id),
    queryKey: queries.learningItems.lastAccessed(qualification.id).queryKey,
    queryFn: () =>
      api.learningItems.getLastAccessedItem({
        params: {
          learningItemType: learningItemType,
          learningItemId: qualification.id,
        },
      }),
  })

  if (isError || isLoading) {
    return (
      <div className="mb-6">
        <Typography variant="title3" className="mb-2">
          Modules
        </Typography>
        <Fallback
          data={{
            title: "Something went wrong!",
            description: "Please contact care@suraasa.com",
            image: Monitor,
          }}
          className="md:my-8 sm:my-20 my-6 sm:px-0 px-2"
          hasError={isError}
          isLoading={isLoading}
        />
      </div>
    )
  }

  const filteredList = plannerList.filter(item => {
    if (plannerList.length > 1 && lastAccessedItem) {
      return item.learningItemId !== lastAccessedItem?.learningItemId
    }
    return true
  })

  return (
    <div className="mb-6">
      <Typography variant="title3" className="mb-2">
        Modules
      </Typography>
      {isLastItemSuccess && plannerList.length > 1 && (
        <ResumeCourseCard className="mb-2" item={lastAccessedItem} />
      )}
      {filteredList.length > 0 ? (
        <ItemList>
          {filteredList.map(item => {
            const isLocked = item.status === "locked"
            return (
              <ItemRow
                key={item.uuid}
                component={isLocked ? "div" : Link}
                to={
                  isLocked
                    ? undefined
                    : routes.parentItemCourse
                        .replace(":learningItemType", learningItemType)
                        .replace(":slug", item.slug)
                        .replace(":parentSlug", slug)
                }
                infoSlot={
                  <div className="flex items-center grow">
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = CourseFallback
                      }}
                      alt="course"
                      src={item.image || CourseFallback}
                      className="flex-shrink-0 h-[86px] rounded-xl border border-surface-100 me-2 object-cover"
                      width={130}
                    />

                    <CourseInfoAndProgress
                      showCount={item.learningItemType === "course"}
                      title={item.name}
                      currentItemNumber={
                        item.progress
                          ? item.progress.completedUserPlannerItems
                          : 0
                      }
                      numberOfItems={
                        item.progress ? item.progress.totalUserPlannerItems : 0
                      }
                    />
                  </div>
                }
                actionSlot={() => {
                  if (isXs) return null
                  if (isLocked) return <Lock />
                  return <Button variant="text">View</Button>
                }}
              />
            )
          })}
        </ItemList>
      ) : (
        <Fallback
          data={{
            image: HandHoldingPen,
            title: "No modules found.",
          }}
          className="md:mt-18 sm:mt-20 mt-9 mb-3"
        />
      )}
    </div>
  )
}

export default Modules
