import { Typography } from "@suraasa/placebo-ui"
import { ITOCertificate } from "api/resources/profile/types"
import NoCertificates from "assets/Fallback/no-certificates.svg"
import { ReactComponent as Ito } from "assets/MyCertificates/ItoColor.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ShareSheet from "components/ShareSheet"
import React, { useState } from "react"

import ItoCard from "../Cards/ItoCard"
import styles from "./OtherCertificates.module.css"

type Props = { othersList: ITOCertificate[] }

const OtherCertificates = (props: Props) => {
  const [certificateUrl, setCertificateUrl] = useState<string>("")

  if (props.othersList.length === 0) {
    return (
      <Fallback
        data={{
          image: NoCertificates,
          title: "Looks like you don’t have any certificates yet!",
          description:
            "Participate in events conducted by Suraasa to earn certificates",
        }}
        className="translate-y-[-50%] translate-x-[-50%] fixed top-1/2 left-1/2 text-center sm:w-fit w-full sm:px-0 px-2"
        hasError={false}
      />
    )
  }

  const data2022 = props.othersList.filter(x => x.item.olympiad.year === 2022)
  const data2023 = props.othersList.filter(x => x.item.olympiad.year === 2023)

  return (
    <>
      {data2023.length > 0 && (
        <div className="mb-3">
          <div className="flex items-center mb-2">
            <Ito className="me-2" />
            <Typography variant="title2">
              International Teachers’ Olympiad 2023
            </Typography>
          </div>
          <div
            className={clsx(
              "grid md:gap-x-2 sm:gap-x-3 sm:gap-y-2 gap-1 mb-4",
              styles.gridContainer
            )}
          >
            {data2023.map((item, index) => (
              <ItoCard
                setCertificateUrl={url => setCertificateUrl(url)}
                key={index}
                data={item}
              />
            ))}
          </div>
        </div>
      )}

      {data2022.length > 0 && (
        <div className="mb-3">
          <div className="flex items-center mb-2">
            <Ito className="me-2" />
            <Typography variant="title2">
              International Teachers’ Olympiad 2022
            </Typography>
          </div>
          <div
            className={clsx(
              "grid md:gap-x-2 sm:gap-x-3 sm:gap-y-2 gap-1 mb-4",
              styles.gridContainer
            )}
          >
            {data2022.map((item, index) => (
              <ItoCard
                setCertificateUrl={url => setCertificateUrl(url)}
                key={index}
                data={item}
              />
            ))}
          </div>
        </div>
      )}

      <ShareSheet
        data={{
          url: certificateUrl,
          text: `Checkout my certificate on Suraasa: ${certificateUrl}`,
        }}
        open={!!certificateUrl}
        handleClose={() => {
          setCertificateUrl("")
        }}
      />
    </>
  )
}

export default OtherCertificates
