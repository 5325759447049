const shepherdClass = {
  answerOptionOne: "answerOptionOne",
  options: "options",
  questionPanel: "questionPanel",
  questionId: "questionId-",
  offenseBar: "offenseBar",
  offenseCount: "offenseCount",
  nextButton: "nextButton",
  prevButton: "prevButton",
  timeContainer: "timeContainer",
  submitTestButton: "submitTestButton",
  questionContainer: "questionContainer",
  cameraContainer: "cameraContainer",
  questionsTrayButton: "questionTrayButton",
} as const

export default shepherdClass
