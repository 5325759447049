import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { InfoCircle, Xmark } from "iconoir-react"
import { useState } from "react"

type InstructionType = "subjects" | "locations"

type VideoType = {
  [key in InstructionType]: string
}

const web: VideoType = {
  subjects: "https://assets.suraasa.com/lms/reordering-gifs/subjects%20web.mp4",
  locations:
    "https://assets.suraasa.com/lms/reordering-gifs/countries%20web.mp4",
}
const mobile: VideoType = {
  subjects:
    "https://assets.suraasa.com/lms/reordering-gifs/subjects%20mobile.mp4",
  locations:
    "https://assets.suraasa.com/lms/reordering-gifs/countries%20mobile.mp4",
}

const Instructions = ({
  title,
  description,
  disclaimer,
  type,
}: {
  title?: string
  description?: string
  disclaimer?: string
  type: InstructionType
}) => {
  const [showProcess, setShowProcess] = useState(false)
  const finalTitle = title ? title : `Change Priority`
  const finalDescription = description
    ? description
    : `Drag your main preferred item to the top to make it your primary item interest. Similarly, your second location will be marked as your secondary item interest.`

  if (!open) {
    return null
  }

  return (
    <>
      <button
        onClick={() => {
          setShowProcess(true)
        }}
        className="mb-1 mt-2 flex space-x-1.5 border border-highlight-200 bg-highlight-50 p-2 text-start  sm:mx-2 sm:rounded-lg"
      >
        <InfoCircle
          width={20}
          height={20}
          className="shrink-0 text-highlight-700"
        />
        <Typography variant="smallBody">
          {disclaimer}{" "}
          <span className="text-primary-500">Click here to know how</span>
        </Typography>
      </button>

      <Dialog open={showProcess}>
        <DialogContent>
          <div className="h-full rounded-lg bg-white p-1">
            <div className="mb-2 flex w-full items-center justify-between">
              <Typography variant="title3" className="mr-1.5">
                {finalTitle}
              </Typography>
              <IconButton
                variant="plain"
                color="black"
                onClick={() => {
                  setShowProcess(false)
                }}
              >
                <Xmark />
              </IconButton>
            </div>
            <Typography variant="smallBody" className="mb-2">
              {finalDescription}
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              src={web[type]}
              className="hidden sm:block"
              controls={false}
              width="100%"
              autoPlay
              disablePictureInPicture
              loop
              muted
              playsInline
            />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              src={mobile[type]}
              className="block sm:hidden"
              controls={false}
              width="100%"
              autoPlay
              disablePictureInPicture
              loop
              muted
              playsInline
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Instructions
