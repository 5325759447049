import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { createUseStyles, useTheme } from "react-jss"

const useStyles = createUseStyles(({ spacing }) => ({
  root: ({ xPadding }: Required<Pick<Props, "xPadding">>) => ({
    padding: spacing(2, xPadding),
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
}))

type Props = { className?: string; xPadding?: 1.5 | 2 | 3 }

const SectionContent = ({
  children,
  className,
  xPadding = 2,
}: PropsWithChildren<Props>) => {
  const theme = useTheme<Theme>()
  if (useMediaQuery(theme.breakpoints.only("xs"))) {
    xPadding = 2
  }

  const classes = useStyles({ xPadding })

  return <div className={clsx(classes.root, className)}>{children}</div>
}

export default SectionContent
