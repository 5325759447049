import React from "react"

const User = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3098 18.1502C17.3098 16.9873 17.3098 16.4058 17.1662 15.9326C16.8431 14.8673 16.0094 14.0336 14.9441 13.7105C14.4709 13.5669 13.8894 13.5669 12.7265 13.5669H8.55985C7.39689 13.5669 6.81541 13.5669 6.34225 13.7105C5.27693 14.0336 4.44326 14.8673 4.12009 15.9326C3.97656 16.4058 3.97656 16.9873 3.97656 18.1502M14.3931 6.90035C14.3931 8.9714 12.7142 10.6503 10.6432 10.6503C8.57211 10.6503 6.8932 8.9714 6.8932 6.90035C6.8932 4.8293 8.57211 3.15039 10.6432 3.15039C12.7142 3.15039 14.3931 4.8293 14.3931 6.90035Z"
        stroke="currentColor"
        strokeWidth="1.49998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default User
