import { Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import OlympiadLogo from "features/ITO/Assessments/assets/olympiad-logo.svg"
import React from "react"
import { useTheme } from "react-jss"

import styles from "./Navbar.module.css"

export type NavbarProps = {
  className?: string
  gutterBottom?: boolean
  slotEnd?: React.ReactNode
  navBarTitle?: string
}

const Navbar = ({
  navBarTitle,
  gutterBottom = true,
  className,
  slotEnd,
}: NavbarProps) => {
  const theme = useTheme<Theme>()

  const isSm = useMediaQuery(theme.breakpoints.down("sm"))

  const getTitle = () => {
    if (isSm) {
      return <img alt="" src={OlympiadLogo} width={40} height={40} />
    }
    if (
      navBarTitle &&
      navBarTitle.includes("international-teachers-olympiad")
    ) {
      return (
        <img
          alt=""
          src="/src/features/ITO/Assessments/assets/olympiad-logo.svg"
          width="80%"
        />
      )
    }

    return <Typography variant="title3">{navBarTitle}</Typography>
  }

  return (
    <div
      className={clsx("w-full fixed top-0 z-navbar", {
        ["mb-4 md:mb-8"]: gutterBottom,
      })}
    >
      <nav
        className={clsx(
          "bg-onSurface-50 border-b-onSurface-200 border-b h-[62px] relative",
          className,
          styles.root,
          "py-1 flex items-center"
        )}
      >
        <div
          className={clsx(
            "flex items-center w-full justify-between py-2 sm:px-5 px-2 md:flex-nowrap flex-wrap",
            styles.container
          )}
        >
          <div className="flex flex-grow items-center justify-start md:flex-grow-0 md:block">
            {getTitle()}
          </div>
          {slotEnd}
        </div>
      </nav>
    </div>
  )
}

export default React.memo(Navbar)
