import React from "react"
import { useParams } from "react-router-dom"

import { useQualificationDetails } from ".."
import Modules from "./Modules"
import NextUp from "./NextUp"

// const overviewDetails = {
//   professors: [
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//   ],
//   description:
//     "Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms.",
// }

const QualificationOverview = () => {
  const { slug } = useParams() as { slug: string }
  const { qualification } = useQualificationDetails()

  return (
    <div>
      {/* <Overview data={overviewDetails} /> */}
      <NextUp
        learningItemId={qualification.id}
        learningItemType={qualification.type}
      />
      <Modules slug={slug} />
    </div>
  )
}

export default QualificationOverview
