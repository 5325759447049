import { Button, toast } from "@suraasa/placebo-ui"
import api from "api"
import { Email, OTPResponse } from "api/resources/settings/types"
import { APIError } from "api/utils"
import { context } from "global/Context/context"
import { useContext, useState } from "react"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"

import VerifyEmail from "../VerifyEmail"

const VerifyEmailButton = () => {
  const { emails, updateEmails } = useContext(context)
  const [openVerifyEmailDialog, setOpenVerifyEmailDialog] = useState(false)
  const [otpData, setOtpData] = useState<OTPResponse>()

  const primaryEmail = emails.find(email => email.isPrimary)

  const { submitWithCaptcha, resetCaptcha } = useGRecaptcha({
    checkboxContainer: "#verify-email-checkbox-captcha",
    action: "auth",
  })

  const sendOTP = submitWithCaptcha(async captcha => {
    try {
      if (primaryEmail) {
        const res = await api.settings.emails.create({
          data: { email: primaryEmail.email, captcha },
        })
        setOtpData(res)
        setOpenVerifyEmailDialog(true)
      }
    } catch (err) {
      console.error(err)
      if (err instanceof APIError) {
        if (err.errors.fieldErrors?.invalidCaptcha) {
          toast.error("Verification failed, please try again later")
        }
      }
    }
  })

  const onVerification = (newEmailData: Email) => {
    const tempEmails = emails.filter(
      email => email.email !== newEmailData.email
    )
    updateEmails([...tempEmails, newEmailData])
  }

  if (primaryEmail)
    return (
      <div>
        {otpData && (
          <VerifyEmail
            dialogTitle="Verify Primary Email"
            close={() => {
              setOpenVerifyEmailDialog(false)
              resetCaptcha()
            }}
            email={primaryEmail.email}
            otpData={otpData}
            open={openVerifyEmailDialog && Boolean(otpData)}
            onVerify={updatedEmail => {
              if (otpData) onVerification(updatedEmail)
              toast.success("Email Verified successfully", {
                autoClose: 3000,
                pauseOnHover: false,
              })
              setOpenVerifyEmailDialog(false)
            }}
          />
        )}
        <Button variant="text" onClick={sendOTP}>
          Verify Email
        </Button>
        <div id="verify-email-checkbox-captcha" />
      </div>
    )
  return null
}

export default VerifyEmailButton
