import { CircularProgress, IconButton, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import React from "react"

import ProfessorReviewCard from "../ProfessorReviewCard"
import Submission from "../Submission"

type Props = {
  open: boolean
  onClose: () => void
  title: string
  submissionId: null | string
}

const ViewPreviousSubmissionDialog = ({
  open,
  onClose,
  title,
  submissionId,
}: Props) => {
  const submission = useQuery({
    enabled: !!submissionId,
    queryFn: () =>
      api.assignments.retrieveSubmission({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { submissionId: submissionId! },
        params: { fields: "iqa_review" },
      }),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: queries.assignments.submissionsByUUID(submissionId!).queryKey,
  })

  return (
    <>
      <SlidingSheet
        open={open}
        onClose={onClose}
        className="py-3 max-w-[965px] overflow-hidden"
      >
        <div className="flex items-center gap-1 sm:gap-1.5 mb-4">
          <IconButton color="black" className="ms-1" onClick={onClose}>
            <Xmark />
          </IconButton>
          <Typography variant="title3">{title}</Typography>
        </div>
        <div className="px-2 sm:px-5 overflow-auto max-h-[80vh] h-full">
          {submission.isSuccess && (
            <div className="mb-3">
              <ProfessorReviewCard
                useDialogForRubrics
                hideAction
                review={submission.data.review}
                iqaReview={submission.data.iqaReview}
              />
            </div>
          )}

          <Typography variant="title3" className="mb-2">
            Your Submission
          </Typography>
          {submission.isSuccess && (
            <Submission
              content={submission.data.submission}
              references={submission.data.references}
              attachments={submission.data.attachments}
            />
          )}
          {submission.isLoading && (
            <div className="flex justify-center p-3">
              <CircularProgress />
            </div>
          )}
          {submission.isError && (
            <Submission
              content={"Some error occurred while loading submission"}
              attachments={[]}
            />
          )}
        </div>
      </SlidingSheet>
    </>
  )
}

export default ViewPreviousSubmissionDialog
