import { Button, CircularProgress, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  AssessmentType,
  AttemptStatus,
} from "api/resources/ito/assessments/types"
import TabBar from "components/TabBar"
import useTabs, { Tabs } from "components/TabBar/hooks/useTabs"
import TopHeadingBar from "components/TopHeadingBar"
import { context } from "global/Context/context"
import { useContext, useEffect } from "react"
import { generateAuthCode, getPlatformURL } from "utils/helpers"
import useCurrentCountry from "utils/hooks/useCurrentCountry"
import { trackingService } from "utils/tracking"

import ShareReferralSheet from "../components/ShareReferralSheet"
import AwardsAndPrizes from "./AwardsAndPrizes"
import FAQ from "./FAQ"
import HandleDashboardTab from "./HandleDashboardTab"
import AwaitingResultPhase from "./HandleDashboardTab/AwaitingResultPhase"
import ReferAndEarn from "./ReferAndEarn"

const Dashboard = () => {
  const country = useCurrentCountry()
  const isIndia = country?.isoCode === "IN"

  const assessments = useQuery({
    queryKey: queries.itoAssessments.list().queryKey,
    queryFn: () => api.ito.assessments.list(),
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const olympiad = assessments.data?.find(
    x => x.assessmentType === AssessmentType.LIVE
  )

  const hasMissedIto =
    !olympiad?.attempt ||
    olympiad?.attempt?.status === AttemptStatus.NOT_STARTED

  const tabs: Tabs = [
    {
      name: "Dashboard",
      content: HandleDashboardTab,
    },
    ...(hasMissedIto
      ? []
      : [
          {
            name: "Resources",
            content: AwaitingResultPhase,
          },
        ]),
    ...(isIndia
      ? [
          {
            name: "Refer & Earn",
            content: ReferAndEarn,
          },
        ]
      : []),
    {
      name: "Awards & Prizes",
      content: AwardsAndPrizes,
    },
    {
      name: "FAQs",
      content: FAQ,
    },
  ]

  const [activeTab, setActiveTab] = useTabs({
    tabs,
    initialTab: tabs[0].name,
  })
  const { itoParticipationDetails } = useContext(context)

  const referralCode =
    itoParticipationDetails.itoRegistrationDetails?.referralCode

  const shareURL = referralCode
    ? getPlatformURL("suraasa", `/ito/?referral=${referralCode}`)
    : getPlatformURL("suraasa", `/ito/`)

  useEffect(() => {
    trackingService.trackEvent(
      "ITO_DASHBOARD_LOAD",
      {
        registration_status: itoParticipationDetails.registrationStatus,
        attempt_status: null,
      },
      ["NetCore"]
    )
  }, [itoParticipationDetails])

  useEffect(() => {
    const exec = async () => {
      if (
        itoParticipationDetails.registrationStatus === "pending" &&
        itoParticipationDetails.itoRegistrationDetails?.registrationFeePaid ===
          true
      ) {
        const url = new URL(
          getPlatformURL("suraasa", "/international-teachers-olympiad/register")
        )
        const code = await generateAuthCode()
        if (code) {
          url.searchParams.append("authCode", code)
        }
        url.searchParams.append("mustCompleteRegistration", "true")
        window.location.href = url.toString()
      }
    }

    exec()
  }, [itoParticipationDetails])

  const gotoFAQ = () => {
    setActiveTab("FAQs")
  }
  const gotoAwardsAndPrize = () => {
    setActiveTab("Awards & Prizes")
  }

  if (!itoParticipationDetails.itoRegistrationDetailsLoaded)
    return (
      <div className="justify-center px-2 py-5 sm:p-5 flex items-center">
        <CircularProgress />
      </div>
    )

  const hasPaid =
    itoParticipationDetails.itoRegistrationDetails?.registrationFeePaid === true

  if (!hasPaid)
    return (
      <div className="justify-center px-2 py-5 sm:p-5 flex items-center">
        <Typography variant="title1" textAlign="center">
          Registrations for ITO 2023 are closed. See you next year!
        </Typography>
      </div>
    )

  return (
    <div>
      <TopHeadingBar
        heading="International Teachers’ Olympiad 2023"
        endSlot={
          <ShareReferralSheet
            url={shareURL}
            render={handleOpen => {
              if (!referralCode) return <></>

              return (
                <div className="hidden sm:block">
                  <Button rounded onClick={handleOpen}>
                    Share Referral
                  </Button>
                </div>
              )
            }}
          />
        }
      >
        <TabBar
          className="pt-0.5"
          activeTab={activeTab}
          tabs={tabs}
          onChange={setActiveTab}
        />
      </TopHeadingBar>
      <activeTab.content
        gotoFAQ={gotoFAQ}
        gotoAwardsAndPrize={gotoAwardsAndPrize}
        assessments={assessments}
      />
    </div>
  )
}

export default Dashboard
