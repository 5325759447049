import { Container, Typography } from "@suraasa/placebo-ui"
import React from "react"
import { formatDate } from "utils/helpers"

type Props = {
  resultDate: Date
}

const GoodJob = ({ resultDate }: Props) => {
  return (
    <div className="bg-ito-mint-700 border border-success-300 ">
      <Container>
        <div className="flex flex-col sm:flex-row sm:gap-0 gap-2 justify-between rounded-xl sm:py-1.5 py-2.5 items-center">
          <div className="w-full">
            <Typography variant="title2" color="surface.500">
              Good Job!
            </Typography>
            <Typography variant="largeBody" color="surface.500">
              You have successfully attempted the Olympiad.
            </Typography>
          </div>

          <div className="flex flex-col sm:items-end items-start w-full">
            <Typography variant="smallBody" color="surface.500">
              Olympiad result arriving on
            </Typography>
            <Typography variant="title4" color="surface.500">
              {formatDate(resultDate.toISOString(), "d MMMM, yyyy")}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default GoodJob
