import React from "react"

const Bell = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="currentColor"
      {...props}
    >
      <path
        d="M12.8361 16.4956C12.8361 17.8763 11.7168 18.9956 10.3361 18.9956C8.95542 18.9956 7.83614 17.8763 7.83614 16.4956M11.8332 5.86119C12.1961 5.48627 12.4194 4.97542 12.4194 4.41241C12.4194 3.26183 11.4867 2.3291 10.3361 2.3291C9.18554 2.3291 8.25281 3.26183 8.25281 4.41241C8.25281 4.97542 8.47614 5.48627 8.83905 5.86119M15.3361 9.99568C15.3361 8.84643 14.8093 7.74424 13.8716 6.93159C12.9339 6.11894 11.6622 5.6624 10.3361 5.6624C9.01005 5.6624 7.73829 6.11894 6.80062 6.93159C5.86295 7.74424 5.33617 8.84643 5.33617 9.99568C5.33617 11.8972 4.86462 13.2878 4.27624 14.2829C3.60564 15.417 3.27034 15.984 3.28358 16.1195C3.29873 16.2745 3.32662 16.3234 3.45231 16.4153C3.56217 16.4956 4.11411 16.4956 5.218 16.4956H15.4542C16.5581 16.4956 17.1101 16.4956 17.2199 16.4153C17.3456 16.3234 17.3735 16.2745 17.3887 16.1195C17.4019 15.984 17.0666 15.417 16.396 14.2829C15.8076 13.2878 15.3361 11.8972 15.3361 9.99568Z"
        stroke="currentColor"
        strokeWidth="1.49998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Bell
