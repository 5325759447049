import useCurrentCountry from "./useCurrentCountry"

const useFormatPrice = () => {
  const country = useCurrentCountry()

  const formatPrice = (amount: number) => {
    try {
      if (country?.isoCode) {
        return amount.toLocaleString(
          `${navigator.language.split("-")[0]}-${country.isoCode}`
        )
      }
      throw Error("country iso code not found")
    } catch (e) {
      return amount.toLocaleString()
    }
  }

  return formatPrice
}

export default useFormatPrice
