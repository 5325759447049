import { IconButton, Typography } from "@suraasa/placebo-ui"
import { LanguageProficiency } from "api/resources/profile/types"
import Section from "features/Profile/components/Section"
import SectionContent from "features/Profile/components/Section/SectionContent"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import { Edit, Plus } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"

import ProfileContext from "../../context"
import LanguageDialog from "./LanguageDialog"

const useStyles = createUseStyles(theme => ({
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
}))

const Languages = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const toggle = () => setOpenDialog(!openDialog)

  const {
    languages: { data },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      <LanguageDialog
        open={openDialog}
        onBack={toggle}
        onRequestClose={toggle}
      />

      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={toggle}>
                    {data.length > 0 ? <Edit /> : <Plus />}
                  </IconButton>
                )
              }
              itemCount={data.length}
              title={pluralize("Language", data.length, { skipCount: true })}
            />
          }
        />

        <SectionContent className={classes.sectionContent} xPadding={2}>
          {data.length > 0 ? (
            data.map(item => (
              <div className="flex flex-col" key={item.id}>
                <Typography color="onSurface.800" variant="strong">
                  {item.language.name}
                </Typography>

                <Typography color="onSurface.500" variant="smallBody">
                  {capitalize(
                    LanguageProficiency[item.proficiency].replace("_", " ")
                  )}
                </Typography>
              </div>
            ))
          ) : (
            <Typography color="onSurface.400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any languages. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default Languages
