export const urls = {
  list: (itemType: string | number, itemId: string | number) =>
    `/v1/${itemType}/${itemId}/assignments/`,
  retrieve: (assignmentId: string | number) => `/assignments/${assignmentId}/`,
  getRubrics: (assignmentId: string | number) =>
    `/assignments/marking-criteria/${assignmentId}/`,
  getReviewRubrics: (reviewId: string | number) =>
    `/assignments/review-rubrics/${reviewId}/`,
  getIQAReviewRubrics: (reviewId: string | number) =>
    `/assignments/iqa-review-rubrics/${reviewId}/`,
  submissions: (assignmentId: string | number) =>
    `/assignments/${assignmentId}/submissions/`,
  retrieveSubmission: (submissionId: string | number) =>
    `/assignments/submissions/${submissionId}/`,
  attachment: (attachmentId: string | number) =>
    `/assignments/submissions/attachments/${attachmentId}/`,
}

export default urls
