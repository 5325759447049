import api from "api"
import { LearningItem } from "api/resources/learningItems/types"
import { useCallback, useContext, useEffect } from "react"

import { LearningModuleContext } from "../context"

export const useUpdateItemStatus = (item: LearningItem) => {
  const { hydrateStatusUpdate } = useContext(LearningModuleContext)

  const updateItemStatus = useCallback(async () => {
    // We only want to update status of unlocked items
    if (item.status !== "unlocked") return

    try {
      await api.learningItems.updateUserPlannerItem({
        urlParams: { userPlannerItemId: item.id },
        data: {
          status: "in_progress",
        },
      })
      hydrateStatusUpdate(item.id, "in_progress")
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.learningContentId, item.status])

  useEffect(() => {
    // Auto-update on mount only for article
    if (item.learningContentType === "article") {
      updateItemStatus()
    }
  }, [updateItemStatus, item.learningContentType])

  return { updateItemStatus }
}
