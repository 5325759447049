import {
  Button,
  CircularProgress,
  Tag,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/masterclasses/queries"
import { Masterclass } from "api/resources/masterclasses/types"
import MasterClassPlaceholder from "assets/Placeholder/default-masterclass-thumbnail.svg"
import clsx from "clsx"
import Card from "components/Card"
import { format } from "date-fns"
import { Calendar, Clock } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { getPlatformURL } from "utils/helpers"

import styles from "./UpcomingMasterclass.module.css"

const MasterclassCard = ({ data }: { data: Masterclass }) => {
  const { title, thumbnail, slug, isRegistered, startTime, joiningUrl } = data

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const trackMasterclass = () => {
    api.masterclasses.trackItoMasterclass({
      urlParams: { videoMeetingId: data.videoMeetingId },
      data: {
        joinTime: new Date().toISOString(),
      },
    })
  }

  return (
    <Card padding={isXs ? 2 : 0}>
      <div className="grid sm:grid-cols-12 sm:items-center relative">
        <img
          src={thumbnail || MasterClassPlaceholder}
          alt="thumbnail"
          className={clsx(
            styles.thumbnail,
            "sm:col-span-4 w-full h-[190px] rounded-2xl sm:rounded-none sm:rounded-l-2xl sm:mb-0 mb-2 object-cover"
          )}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = MasterClassPlaceholder
          }}
        />
        <Tag
          label="Free Masterclass"
          className={clsx(styles.tag, "absolute left-2 top-2")}
        />
        <div className="sm:col-span-7 sm:ms-3">
          <Typography variant={isXs ? "strong" : "title3"} className="mb-1 ">
            {title}
          </Typography>
          <div className="flex items-center gap-1 sm:mb-2.75 mb-3.5 flex-wrap text-onSurface-600">
            <Calendar />
            <Typography variant="smallBody">
              {format(new Date(startTime), "dd MMMM yyyy")}
            </Typography>
            <div className="h-1 w-1 rounded-full bg-onSurface-200" />
            <div className="flex items-center">
              <Clock className="me-1" />
              <Typography variant="smallBody">
                {format(new Date(startTime), "h:mm a")}
              </Typography>
            </div>
          </div>
          {joiningUrl ? (
            <Button
              fullWidth={isXs}
              component="a"
              target="_blank"
              href={joiningUrl}
              onClick={() => {
                trackMasterclass()
              }}
            >
              Join Now
            </Button>
          ) : isRegistered ? (
            <div>
              <Tag label="Registered" color="success" className="mb-1" />
              <Typography variant="smallBody" color="onSurface.500">
                We have emailed you the joining link for this masterclass
              </Typography>
            </div>
          ) : (
            <Button
              fullWidth={isXs}
              component="a"
              href={getPlatformURL(
                "suraasa",
                `/masterclasses/register/${slug}`
              )}
            >
              Register Now
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}
type UpcomingMasterclass = {
  title: string
  date: string
  thumbnail: string
}

const UpcomingMasterclass = () => {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: queries.upcomingMasterclass().queryKey,
    queryFn: () => api.masterclasses.overview(),
  })

  return (
    <div className="mb-6">
      <Typography variant="title3" className="mb-2">
        Upcoming Masterclass
      </Typography>
      {isLoading && (
        <div className="flex items-center justify-center p-4">
          <CircularProgress />
        </div>
      )}
      {isSuccess && (
        <>
          {data.upcomingMasterclass ? (
            <MasterclassCard data={data.upcomingMasterclass} />
          ) : (
            <div
              className="flex items-center justify-between border flex-wrap border-surface-200 rounded-2xl p-3
            gap-1
            "
            >
              <div className="flex flex-col">
                <Typography variant="title3">
                  Register for upcoming masterclasses
                </Typography>
                <Typography color="onSurface.500" className="mt-1">
                  Sign up for upcoming masterclasses and get notified first!
                </Typography>
              </div>
              <Button
                size="sm"
                component="a"
                className="!w-full sm:!w-auto"
                href={getPlatformURL("suraasa", "/masterclasses#pre-register")}
              >
                Pre Register
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default UpcomingMasterclass
