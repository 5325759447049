import { Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import React from "react"
import { useParams } from "react-router-dom"
import { getAuthInfo } from "utils/helpers"

import AdditionalCertificates from "./AdditionalCertificates"
import CertificateActionButtons from "./CertificateActionButtons"
import CertificateContactUs from "./CertificateContactUs"
import SuraasaCertificateCard from "./SuraasaCertificateCard"

const QualificationCertificates = () => {
  const { slug, learningItemType } = useParams() as {
    slug: string
    learningItemType: string
  }

  const userName = getAuthInfo()?.user.username
  const { data } = useQuery({
    queryKey: queries.profile.certificateExistence(
      userName,
      learningItemType,
      slug
    ).queryKey,
    queryFn: () =>
      api.profile.credentials.checkExistence({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          username: userName!,
          itemType: learningItemType,
          itemSlug: slug,
        },
      }),
  })

  return (
    <div>
      <div className="flex flex-wrap gap-1 justify-between items-center">
        <Typography variant="title3">Certificates</Typography>
        {data?.serialNumber && (
          <CertificateActionButtons
            slug={slug}
            learningItemType={learningItemType}
          />
        )}
      </div>
      <SuraasaCertificateCard
        serialNumber={data?.serialNumber}
        slug={slug}
        learningItemType={learningItemType}
      />
      <AdditionalCertificates />
      <CertificateContactUs />
    </div>
  )
}

export default QualificationCertificates
