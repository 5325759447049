import "glider-js/glider.min.css"

import { Button, Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Masterclass } from "api/resources/masterclasses/types"
import MasterClassPlaceholder from "assets/Placeholder/default-masterclass-thumbnail.svg"
import clsx from "clsx"
import { format } from "date-fns"
import { ArrowLeft, ArrowRight } from "iconoir-react"
import React, { useEffect, useState } from "react"
import Glider from "react-glider"
import { useTheme } from "react-jss"
import { getPlatformURL } from "utils/helpers"

import DynamicContainer, { CustomContainer } from "../DynamicContainer"

const Card = ({ title, startTime, slug, thumbnail }: Masterclass) => {
  return (
    <a
      href={getPlatformURL("suraasa", `/masterclasses/${slug}`)}
      className="relative rounded-2xl max-h-[177px] text-surface-default overflow-hidden me-3"
    >
      <div
        style={{
          background:
            "linear-gradient(178.02deg, rgba(30, 6, 6, 0) -21.81%, #1E0606 98.33%)",
        }}
        className="absolute top-0 left-0 h-full w-full z-10 "
      />
      <img
        src={thumbnail || MasterClassPlaceholder}
        alt="thumbnail"
        className="rounded-2xl w-full"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = MasterClassPlaceholder
        }}
      />
      <div className="absolute bottom-1.5 left-1.5 right-1.5 text-white z-20">
        <Typography variant="subtitle2" className="mb-1">
          {title}
        </Typography>
        <div className="flex items-center flex-wrap gap-1">
          <Typography variant="smallBody">
            {format(new Date(startTime), "dd MMMM yyyy")}
          </Typography>
          {/* <div className="h-1 w-1 rounded-full bg-surface-200" /> */}
          <Typography variant="smallBody">
            {/* <span dangerouslySetInnerHTML={{ __html: description }} /> */}
          </Typography>
        </div>
      </div>
    </a>
  )
}

const RecentMasterclasses = () => {
  const masterclasses = useQuery({
    queryFn: () =>
      api.masterclasses.list({
        params: {
          ordering: "-start_time",
        },
      }),
    queryKey: queries.masterclasses.masterclassesList().queryKey,
  })
  const [currentSlide, setCurrentSlide] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setForceRender] = useState(0)

  const theme = useTheme<Theme>()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const GliderContainer = isSmDown ? "div" : CustomContainer

  const initGliderListeners = () => {
    const gliderElement = document.getElementById("glider")
    if (!gliderElement) return
    const listener = (event: any) => {
      setCurrentSlide(event.detail.slide)
    }
    gliderElement.addEventListener("glider-slide-visible", listener)

    return () => {
      gliderElement.removeEventListener("glider-slide-visible", listener)
    }
  }

  useEffect(() => {
    const listener = () => {
      const anchor = document.querySelector(".anchor-ref")
      const sliderContainer = document.querySelector(
        ".slider-container"
      ) as HTMLDivElement

      if (!anchor || !sliderContainer) return

      sliderContainer.style.marginLeft = `${anchor.getBoundingClientRect().x}px`
    }

    listener()

    window.addEventListener("resize", listener)

    return () => {
      window.removeEventListener("resize", listener)
    }
  }, [masterclasses.isSuccess])

  if (!masterclasses.isSuccess) {
    return null
  }

  const isLeftArrowDisabled = currentSlide === 0
  const isRightArrowDisabled = currentSlide === masterclasses.data.length - 1

  return (
    <div>
      <DynamicContainer>
        <div className="flex items-center justify-between flex-wrap mb-[19px] anchor-ref lg:pe-5">
          <Typography variant="title3">Recent Masterclasses</Typography>
          <Button
            variant="text"
            component="a"
            href={getPlatformURL("suraasa", "/masterclasses/all")}
          >
            View More
          </Button>
        </div>
      </DynamicContainer>

      <GliderContainer className="sm:mb-0 mb-4 overflow-x-hidden slider-container rounded-l-2xl">
        <Glider
          id="glider"
          hasArrows
          onLoad={() => {
            initGliderListeners()
            setForceRender(x => x + 1)
          }}
          draggable={true}
          slidesToShow={3.5}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 3000,
              settings: {
                slidesToShow: 5.1,
              },
            },
            {
              breakpoint: 2500,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4.1,
              },
            },
            {
              breakpoint: 1560,
              settings: {
                slidesToShow: 3.1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2.1,
              },
            },
            {
              breakpoint: 300,
              settings: {
                slidesToShow: 1.1,
              },
            },
          ]}
          arrows={{
            prev: "#buttonPrev",
            next: "#buttonNext",
          }}
        >
          {masterclasses.data.map((masterclass, i) => (
            <Card key={i} {...masterclass} />
          ))}
        </Glider>
      </GliderContainer>

      {isSmUp && (
        <DynamicContainer className="mt-3 mb-8">
          <div className="flex justify-between items-center lg:pe-5">
            <div className="border border-solid border-onSurface-200 bg-white rounded-lg h-[6px] w-[100px] relative">
              <div
                className="absolute w-[48px] h-[4px] left-0 bg-[#050124] rounded-lg transition-[left] ease-linear duration-150"
                style={{
                  left: `${(currentSlide / masterclasses.data.length) * 52}%`,
                }}
              />
            </div>
            <div className="flex items-center">
              <button
                disabled={isLeftArrowDisabled}
                id="buttonPrev"
                className={clsx(
                  "w-4 h-4 bg-onSurface-900 text-white flex items-center justify-center me-1 lg:me-1 !rounded-full p-0.5",
                  { "bg-surface-200 !text-onSurface-400": isLeftArrowDisabled }
                )}
              >
                <ArrowLeft strokeWidth={2.5} />
              </button>
              <button
                id="buttonNext"
                disabled={isRightArrowDisabled}
                className={clsx(
                  "w-4 h-4 bg-onSurface-900 text-white flex items-center justify-center !rounded-full p-0.5",
                  { "bg-surface-200 !text-onSurface-400": isRightArrowDisabled }
                )}
              >
                <ArrowRight strokeWidth={2.5} />
              </button>
            </div>
          </div>
        </DynamicContainer>
      )}
    </div>
  )
}

export default RecentMasterclasses
