import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import { VideoBookmark } from "api/resources/learningItems/types"
import { MoreVert, Trash } from "iconoir-react"
import React from "react"

import { getTimestampFromSeconds } from "./getTimestampFromSeconds"

type NoteProps = {
  note: VideoBookmark
  onClick: () => void
  onDelete: () => void
}

const Note = ({ onClick, note, onDelete }: NoteProps) => {
  return (
    <div className="flex gap-1  bg-primary-50 rounded-lg p-1.5">
      <button onClick={onClick} className="w-full flex gap-1 items-center">
        <Chip label={getTimestampFromSeconds(note.timestamp)} />
        <Typography className="flex-1 text-start whitespace-pre-wrap">
          {note.comment}
        </Typography>
      </button>
      <Menu
        portal
        menuButton={
          <IconButton color="black">
            <MoreVert />
          </IconButton>
        }
      >
        <MenuItem
          startAdornment={<Trash />}
          color="critical"
          onClick={onDelete}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  )
}

interface Props {
  notes: VideoBookmark[]
  onDelete: (id: string) => void
  gotoTimeStamp: (time: number) => void
}

const Notes = ({ notes, onDelete, gotoTimeStamp }: Props) => {
  return (
    <div className="flex flex-col gap-1">
      {notes?.length === 0 ? (
        <Typography
          variant="body"
          color="onSurface.500"
          className="text-center"
        >
          Click on &quot;Add note&quot; to create notes for this video.
        </Typography>
      ) : (
        notes
          .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
          .map(i => (
            <Note
              note={i}
              key={i.uuid}
              onClick={() => gotoTimeStamp(i.timestamp)}
              onDelete={() => onDelete(i.uuid)}
            />
          ))
      )}
    </div>
  )
}

export default Notes
