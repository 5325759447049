import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import BackButton from "components/BackButton"
import Breadcrumbs, { BreadcrumbProps } from "components/Breadcrumbs"
import React from "react"
import { useTheme } from "react-jss"
import { useNavigate } from "react-router"
import { PropsWithClassName } from "utils/types"

type Props = {
  endSlot?: JSX.Element
  showBackButton?: boolean
} & BreadcrumbProps

const PageNavigation = ({
  showBackButton = true,
  endSlot,
  skipLastItem,
  color,
  className,
}: PropsWithClassName<Props>) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const navigate = useNavigate()

  if (isXs && showBackButton)
    return (
      <div className={clsx(className, "flex items-center justify-between")}>
        <BackButton
          goBack={() => navigate(-1)}
          color={color === "light" ? "white" : undefined}
        />
        {endSlot && endSlot}
      </div>
    )

  return (
    <Breadcrumbs
      skipLastItem={skipLastItem}
      color={color}
      className={className}
    />
  )
}

export default PageNavigation
