import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Xmark } from "iconoir-react"

type Option = string

type Props = {
  onClose: () => void
  options: Option[]
  selectedOption: Option | null
  onSelect: (value: Option) => void
  openFor: string | null
}
const Options = ({
  options,
  selectedOption,
  onSelect,
  onClose,
  openFor,
}: Props) => {
  return (
    <div className="w-full py-2 min-h-[30vh] bg-white shadow-[0px_-8px_25px_0px_rgba(0,0,0,0.10)] rounded-[16px_16px_0px_0px]">
      <div className="relative">
        <IconButton
          size="xs"
          color="black"
          variant="plain"
          className="absolute top-0 right-1"
          onClick={() => {
            onClose()
          }}
        >
          <Xmark />
        </IconButton>

        <div className="pr-5 pl-1">
          {!openFor && (
            <Typography textAlign="center">
              Identify the correct part of speech
            </Typography>
          )}
          {openFor && (
            <Typography
              textAlign="center"
              className={clsx({
                "mb-4": true,
              })}
            >
              Identify the correct part of speech for{" "}
              <strong>“{openFor}”</strong>
            </Typography>
          )}
        </div>
        {openFor && (
          <div className="flex flex-wrap justify-center gap-2 items-end px-1">
            {options.map(option => {
              return (
                <Button
                  onClick={() => {
                    onSelect(option)
                    onClose()
                  }}
                  variant={selectedOption === option ? "filled" : "outlined"}
                  size="sm"
                  key={option}
                >
                  {option}
                </Button>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Options
