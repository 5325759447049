import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import { LearningItem } from "api/resources/learningItems/types"
import clsx from "clsx"
import { ReactComponent as CompletedIcon } from "features/LearningModule/assets/icons/check-circled-outline.svg"
import { NavArrowDown } from "iconoir-react"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"

import MilestoneItem from "./MilestoneItem"

const useStyle = createUseStyles({
  container: {
    display: "grid",
    gridTemplateRows: "0fr",
    transition: "all 0.5s",
    "&.active": {
      gridTemplateRows: "1fr",
    },
  },
  icon: {
    transition: "all 0.5s",
    "&.active": {
      transform: "rotate(180deg)",
    },
  },
})

type Props = {
  items: LearningItem[]
  name: string
  totalItems: number
  id: string
  onItemSelect: () => void
  defaultExpanded?: boolean
  currentItem: LearningItem | undefined
}

const CustomAccordion = ({
  header,
  children,
  className,
  headerClassName,
  open,
}: {
  header: React.ReactNode
  children: React.ReactNode
  className?: string
  headerClassName?: string
  open: boolean
}) => {
  const classes = useStyle()
  const [expand, setExpand] = useState(open)
  const toggle = () => {
    setExpand(!expand)
  }
  return (
    <>
      <button
        onClick={toggle}
        className={clsx(
          "flex w-full justify-between px-2 py-1",
          headerClassName
        )}
      >
        {header}
        <NavArrowDown
          className={clsx("shrink-0 text-onSurface-900", classes.icon, {
            active: expand,
          })}
        />
      </button>
      <div
        className={clsx(className, classes.container, {
          active: expand,
        })}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </>
  )
}

const MilestoneAccordion = ({
  items,
  name,
  id,
  totalItems,
  onItemSelect,
  currentItem,
}: Props) => {
  const completedItemsLength = items.filter(
    i => i.status === "completed"
  ).length

  const currentMilestoneId = items.find(x => x.id === currentItem?.id)
    ?.milestone.id

  return (
    <CustomAccordion
      open={currentMilestoneId?.toString() === id}
      header={
        <div className="flex w-full items-center justify-between gap-4">
          <div className="shrink-0">{name}</div>

          {completedItemsLength === totalItems ? (
            <CompletedIcon />
          ) : (
            <div className="me-2 flex gap-1">
              <CircularProgress
                color="black"
                value={(completedItemsLength / totalItems) * 100}
                size="xs"
              />
              <Typography variant="strongSmallBody">
                {completedItemsLength}/{totalItems}
              </Typography>
            </div>
          )}
        </div>
      }
    >
      {items.map(item => (
        <MilestoneItem
          isActive={currentItem?.id === item.id}
          item={item}
          key={`${item.learningContentType}-${item.sequence}`}
          onItemSelect={onItemSelect}
        />
      ))}
    </CustomAccordion>
  )
}

export default MilestoneAccordion
