// Assets
import SuraasaLogo from "assets/SuraasaLogo.svg"

export function openRazorpayCheckoutForm(
  rzpOrderId: string,
  amount: number,
  backendOrderId: string,
  userDetails: { fullName: string; email: string },
  cb: () => void
) {
  // function handleRazorpayPaymentSuccess(
  //   backendOrderId,
  //   rzpPaymentId,
  //   rzpSignature
  // ) {
  //   axios
  //     .post(urls.payment.transactionsDetail(backendOrderId), {
  //       payment_gateway_transaction_id: rzpPaymentId,
  //       payment_gateway_signature: rzpSignature,
  //       payment_gateway_order_id: rzpOrderId,
  //       payment_mode: "suraasa-web",
  //     })
  //     .then(response => {
  //       cb && cb(response)
  //     })
  // }

  const options = {
    key: import.meta.env.VITE_RAZORPAY_KEY,
    order_id: rzpOrderId,
    amount: amount,
    name: "Suraasa",
    description: "Purchase Description",
    image: SuraasaLogo,
    handler: cb,
    prefill: {
      name: userDetails.fullName,
      email: userDetails.email,
    },
    theme: {
      // Suraasa primary color
      color: "#315EFB",
    },
    notes: {
      suraasa_order_id: backendOrderId,
    },
  }
  // @ts-expect-error window.Razorpay will be defined
  const rzp = new window.Razorpay(options)
  rzp.open()
}
