import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("itoAssessments", {
  list: () => ({
    queryKey: ["itoAssessmentsList"],
  }),
  overview: () => ({
    queryKey: ["itoAssessmentsOverview"],
  }),
})
