import { validateUrls } from "api/types"

export const urls = validateUrls({
  discussion: {
    post: () => `/discussions/posts/`,
    retrieve: discussionId => `/discussions/posts/${discussionId}/`,
    comments: discussionId => `/discussions/posts/${discussionId}/comments/`,
    postComment: discussionId => `/discussions/posts/${discussionId}/comments/`,
    postReply: commentId => `/discussions/comments/${commentId}/replies/`,
    upvote: discussionId => `/discussions/posts/${discussionId}/upvote/`,
    hide: discussionId => `/discussions/posts/${discussionId}/`,
    unhide: discussionId => `/discussions/posts/${discussionId}/`,
    getHiddenDiscussions: page =>
      `/discussions/posts/?page=${page}&hidden=true`,

    delete: discussionId => `/discussions/posts/${discussionId}/`,
    deleteComment: commentId => `/discussions/comments/${commentId}/`,
    deleteReply: replyId => `/discussions/replies/${replyId}/`,
  },
  report: {
    discussion: discussionId => `/discussions/posts/${discussionId}/report/`,
    comment: commentId => `/discussions/comments/${commentId}/report/`,
    reply: replyId => `/discussions/replies/${replyId}/report/`,
  },
  delete: {
    discussion: discussionId => `/discussions/posts/${discussionId}/`,
    comment: commentId => `/discussions/comments/${commentId}/`,
    reply: replyId => `/discussions/replies/${replyId}/`,
  },
  block: {
    create: () => "/user/block/",
  },
})

export default urls
