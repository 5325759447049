import { theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { context } from "global/Context/context"
import React, { useContext, useState } from "react"
import { CSSTransition } from "react-transition-group"

import styles from "./CourseFeedbackCard.module.css"
import Step1 from "./Step1"
import Step2 from "./Step2"

const duration = 300

const CourseFeedbackCard = () => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const { showCourseFeedbackCard, setShowCourseFeedbackCard } =
    useContext(context)

  const [cardAnimation, setCardAnimation] = useState(false)
  const [step, setStep] = useState<"step1" | "step2" | null>("step1")

  const toggleCardAnimation = (enabled: boolean) => {
    setCardAnimation(enabled)
    setStep(null)
  }

  const onClose = () => {
    setShowCourseFeedbackCard({ courseId: null })
  }

  if (!showCourseFeedbackCard.courseId) return null

  if (isXsDown) {
    return (
      <SlidingSheet
        className={clsx(styles.slidingSheetContainer, {
          [styles.step1]: step === "step1",
          [styles.step2]: step === "step2",
        })}
        open
        onClose={onClose}
      >
        <>
          {step === "step1" && (
            <Step1
              onClickRateCourse={() => setStep("step2")}
              onClickNotNow={onClose}
            />
          )}
          {step === "step2" && (
            <Step2
              courseId={showCourseFeedbackCard.courseId}
              onClose={onClose}
            />
          )}
        </>
      </SlidingSheet>
    )
  }
  return (
    <div className="fixed right-4 bottom-2 z-[10]">
      <div className="relative">
        <CSSTransition
          in={cardAnimation}
          timeout={duration}
          classNames={{
            enterActive: styles.opacityEnter,
            enterDone: styles.opacityEnterActive,
            exitDone: styles.opacityExit,
            exitActive: styles.opacityExitActive,
          }}
          onEntered={() => setStep("step2")}
          onExited={() => setStep("step1")}
        >
          <div
            className={clsx("absolute bottom-0 right-0", styles.verticalCard, {
              [styles.horizontalCard]: cardAnimation,
            })}
          />
        </CSSTransition>

        {step === "step1" && (
          <Step1
            onClickRateCourse={() => toggleCardAnimation(true)}
            onClickNotNow={onClose}
          />
        )}
        {step === "step2" && (
          <Step2 courseId={showCourseFeedbackCard.courseId} onClose={onClose} />
        )}
      </div>
    </div>
  )
}

export default CourseFeedbackCard
