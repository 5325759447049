import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import BottomNavigation from "components/Navigation/BottomNavigation"
import TopNavigation from "components/Navigation/TopNavigation"
import CourseFeedbackCard from "features/CourseFeedbackCard"
import GlobalBanners from "features/GlobalBanners"
// import Onboarding from "features/Onboarding"
import { useTheme } from "react-jss"
import { Outlet, useSearchParams } from "react-router-dom"

const AppLayout = () => {
  const theme = useTheme<Theme>()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [searchParams] = useSearchParams()

  const isApp = searchParams.get("suraasa-learn-app") === "true"

  return (
    <>
      {!isApp && (
        <>
          <GlobalBanners />
          {/* <Onboarding /> */}
          <TopNavigation />
          <CourseFeedbackCard />
          {!mdUp && <BottomNavigation />}
        </>
      )}
      {/* Do not put <BottomNavigation/> below <Outlet/> */}
      <div id="mainContent">
        <Outlet />
      </div>
    </>
  )
}

export default AppLayout
