import {
  CircularProgress,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect } from "react"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import Post from "./Post"
import TitleBar from "./TitleBar"

const Discussions = () => {
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const { isFetching, hasNextPage, fetchNextPage, data } = useInfiniteQuery({
    queryKey: queries.discussions.list().queryKey,
    queryFn: x => api.discussions.list({ params: { page: x.pageParam || 1 } }),
    onError: err => {
      if (err instanceof APIError) {
        console.log(err.statusCode)
        console.log(err.is500)
        console.log(err.__errors)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  // TODO: Implement cursor pagination to fix this issue
  // useEffect(() => {
  //   if (!import.meta.env.DEV) return

  //   const allData = data?.pages
  //     .map(d => d.data)
  //     .flat()
  //     .map(d => d.id)

  //   if (allData) {
  //     if (allData.length > new Set(allData).size) {
  //       window.alert("Duplicates found")
  //     }
  //   }
  // }, [data])

  return (
    <div className="pb-10">
      <TitleBar />
      <Container fullWidth={xsDown}>
        {data?.pages.map((page, pageNumber) =>
          page.data.map((discussion, index) => {
            const PAGE_SIZE = 10
            // Doing +1 because index starts from zero
            const currentIndex = pageNumber * PAGE_SIZE + (index + 1)

            // Doing -1 because page length should start from 0
            const totalLoadedItems =
              (data.pages.length - 1) * PAGE_SIZE + page.data.length

            const isLastItem = currentIndex === totalLoadedItems

            return (
              <div
                key={discussion.id}
                // id={isLastItem ? "ref-testing" : undefined}
                ref={isLastItem ? ref : null}
                className="grid grid-cols-12"
              >
                <Post
                  data={discussion}
                  className="mb-2 md:col-start-2 md:col-span-10 col-start-1 col-span-12"
                />
              </div>
            )
          })
        )}
        {isFetching && (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
      </Container>
    </div>
  )
}

export default Discussions
