import { Button, Typography } from "@suraasa/placebo-ui"
import Paper from "features/AssignmentResult/Paper"
import { Lock } from "iconoir-react"
import { getPlatformURL } from "utils/helpers"

import styles from "./Submission.module.css"

const SubmissionPreview = ({ content = "" }) => {
  return (
    <>
      <Paper>
        <div
          className={styles.contentDivBlocked}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Paper>
      <div className="flex flex-col left-0 bg-white w-full items-center absolute">
        <span className="p-1 mb-1 rounded-full shadow-md bg-onSurface-200">
          <Lock />
        </span>
        <Typography variant="largeBody">
          To view the full submission, please login as school
        </Typography>
        <Button
          rounded
          className="mt-2"
          component="a"
          href={getPlatformURL(
            "sso",
            "/school/login" +
              `?redirect-url=${window.encodeURIComponent(
                getPlatformURL(
                  "hire",
                  window.location.pathname.replace("/profile/", "/teacher/")
                )
              )}`
          )}
        >
          Login as School
        </Button>
      </div>
    </>
  )
}

export default SubmissionPreview
