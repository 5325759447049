import { useMediaQuery } from "@suraasa/placebo-ui"
import { useLayoutEffect } from "react"

type Props = {
  query: string
}
/**
 * This hooks hides the top navigation wherever its called
 * @usage useHideTopNavigation()
 */
const useHideTopNavigation = (props?: Props) => {
  const shouldHide = useMediaQuery(props?.query || "")

  useLayoutEffect(() => {
    if (props?.query && shouldHide)
      document.body.classList.add("hideTopNavigation")

    if (!props) document.body.classList.add("hideTopNavigation")

    return () => {
      document.body.classList.remove("hideTopNavigation")
    }
  }, [props, shouldHide])
}

export default useHideTopNavigation
