import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { NavLink } from "react-router-dom"

export type LearningItem = {
  name: string
  link: string
}
type Props = {
  data: LearningItem[]
  className?: string
}

const LearningItemNav = ({ data, className }: Props) => {
  return (
    <div className={clsx(className)}>
      {data.map((item, index) => (
        <NavLink
          key={index}
          to={item.link}
          className={({ isActive }) =>
            isActive
              ? "block text-primary-500 mx-auto bg-primary-50 rounded-lg"
              : "block mx-auto text-primary-900"
          }
        >
          <Typography className="p-2" variant="subtitle2">
            {item.name}
          </Typography>
        </NavLink>
      ))}
    </div>
  )
}

export default LearningItemNav
