import {
  Button,
  CircularProgress,
  Container,
  Theme,
  toast,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  Assessment,
  AssessmentType,
  AttemptStatus,
} from "api/resources/ito/assessments/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import BackButton from "components/BackButton"
import {
  closeWindow,
  playVideo,
  triggerFullscreen,
} from "features/ITO/Assessments/helpers"
import { useCamDetection } from "features/ITO/Assessments/Proctoring/hooks/useCamDetection"
import { useEffect, useState } from "react"
import { isDesktop } from "react-device-detect"
import { useTheme } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import { browserIsSupportedForITO } from "utils/constants"

// import { useDuplicateTabDetection } from "utils/hooks/useDuplicateTabDetection"
import DurationTimer from "../Attempt/DurationTimer"
import GeneralInstruction from "./GeneralInstructions"
import ImageCaptureConsentDialog from "./ImageCaptureConsentDialog"
import styles from "./Instructions.module.css"
import ResumeAssessmentBanner from "./ResumeAssessmentBanner"
import SystemRequirements from "./SystemRequirement"

const AssessmentInstructions = ({ parentSlug }: { parentSlug?: string }) => {
  const { assessmentId, attemptId: attemptIdUseParam } = useParams()
  const [attemptId, setAttemptId] = useState(attemptIdUseParam)
  // useDuplicateTabDetection({ uniqueId: attemptId })
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const [timerOver, setTimerOver] = useState(false)
  const [loading, setLoading] = useState(false)

  const [assessmentDetails, setAssessmentDetails] = useState<Assessment | null>(
    null
  )
  const navigate = useNavigate()

  const proctoringEnabled = assessmentDetails?.isProctored === true

  const { videoRef, webCamStatus } = useCamDetection({
    disabled: !proctoringEnabled,
  })

  const webCamIsOff = webCamStatus !== "on"

  const attemptInProgress =
    assessmentDetails?.attempt?.status === AttemptStatus.IN_PROGRESS

  const isOlympiad =
    Boolean(assessmentDetails) &&
    assessmentDetails?.assessmentType === AssessmentType.LIVE

  const testType = isOlympiad ? "Olympiad" : "Assessment"

  const startTime = assessmentDetails?.startTime
    ? assessmentDetails?.startTime
    : new Date().toISOString()

  const createAttemptMutation = useMutation({
    mutationFn: () =>
      api.ito.assessments.createAttempt({
        params: {
          assessmentId,
        },
      }),
    onSuccess: createAttemptMutation => {
      setAttemptId(createAttemptMutation.id.toString())
    },
    onError: error => {
      console.log(error)
    },
  })

  useEffect(() => {
    if (!attemptId) {
      createAttemptMutation.mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getAssessmentDetails = async () => {
      setLoading(true)
      try {
        const res = await api.ito.assessments.getAssessment({
          params: {
            assessmentId,
          },
        })
        setAssessmentDetails(res)
        if (new Date(res.startTime) <= new Date()) {
          setTimerOver(true)
        }
      } catch (err) {
        if (err instanceof APIError) {
          toast.error(`${testType} not found.`)
          closeWindow(3000)
          console.error(err.errors)
        }
      }

      setLoading(false)
    }
    if (assessmentId) getAssessmentDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId])

  useEffect(() => {
    if (!proctoringEnabled) return
    if (webCamIsOff) {
      playVideo(videoRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayToolTipWarnings = () => {
    if (browserIsSupportedForITO !== "supported") {
      return "Your browser is not supported to attempt the olympiad"
    }

    if (webCamStatus === null) {
      return "Checking your system. Please wait"
    }

    if (webCamIsOff) {
      return "We are unable to access your camera. Find instructions in the 'System Requirements' section above."
    }

    if (!timerOver) {
      return `${testType} hasn't started yet`
    }

    return ""
  }

  const [showConsentDialog, setShowConsentDialog] = useState(false)

  const enterAssessment = (skipModal = false) => {
    if (
      import.meta.env.VITE_ITO_VIOLATION_STORAGE_API_BASE_URL &&
      skipModal === false
    ) {
      setShowConsentDialog(true)
      return
    }

    setShowConsentDialog(false)
    if (isDesktop) triggerFullscreen()

    navigate(
      `${parentSlug ? `/${parentSlug}` : ""}/assessments/attempt/${attemptId}`,
      {
        replace: true,
      }
    )
  }

  const warning = displayToolTipWarnings()

  const startOlympiadSection = () => {
    if (attemptId) {
      return (
        <div className="w-full">
          <div className="gap-3 items-center justify-center sm:mt-0 mt-6">
            <Tooltip title={warning} disabled={!warning}>
              <div className="w-full">
                <Button
                  onClick={() => enterAssessment()}
                  disabled={Boolean(warning)}
                  fullWidth
                >
                  {attemptInProgress ? "Resume" : "Start"}{" "}
                  {isOlympiad ? "Olympiad" : "Assessment"}
                </Button>
              </div>
            </Tooltip>
            {!timerOver && (
              <Typography
                color="onSurface.500"
                textAlign="center"
                className="mt-1"
              >
                Starts in{" "}
                <DurationTimer
                  toDate={startTime}
                  onEnd={() => setTimerOver(true)}
                />
              </Typography>
            )}
          </div>
        </div>
      )
    }
    return null
  }

  if (loading)
    return (
      <div className="pt-4 lg:pb-8 sm:pb-4 pb-11">
        <Container>
          <BackButton
            className="my-2"
            goBack={() => {
              window.close()
            }}
          />
          <div className="flex justify-center mt-5">
            <CircularProgress />
          </div>
        </Container>
      </div>
    )

  return (
    <div>
      {/* <IncompleteRegistrationsDialog
        open={status === "paidButPending"}
        dismissible={false}
      /> */}

      <ImageCaptureConsentDialog
        open={showConsentDialog}
        onAccept={() => {
          enterAssessment(true)
        }}
      />

      <div className="pt-4 lg:pb-8 sm:pb-4 pb-11">
        <Container fullWidth={isXs}>
          <BackButton
            className="my-2"
            goBack={() => {
              window.close()
            }}
          />

          <div className="mb-3">
            <SystemRequirements className="px-2 sm:px-0" autoCheckCamera />
          </div>

          <ResumeAssessmentBanner
            timer={
              !timerOver ? (
                <Typography textAlign="center" className="!text-white mt-1">
                  Starts in{" "}
                  <DurationTimer
                    toDate={startTime}
                    onEnd={() => setTimerOver(true)}
                  />
                </Typography>
              ) : undefined
            }
            attemptInProgress={attemptInProgress}
            isOlympiad={Boolean(isOlympiad)}
            onResume={() => enterAssessment()}
            disabledReason={displayToolTipWarnings()}
          />
          <div
            className={clsx("p-2 sm:p-3 lg:p-4 sm:mb-9 mb-6", styles.container)}
          >
            <GeneralInstruction
              duration={assessmentDetails?.duration}
              totalQuestions={assessmentDetails?.totalQuestions}
              isOlympiad={isOlympiad}
              isProctored={assessmentDetails?.isProctored}
            />
            {startOlympiadSection()}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default AssessmentInstructions
