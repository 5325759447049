import { CircularProgress, IconButton } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Pause, Play } from "iconoir-react"
import { RefObject, useCallback, useEffect, useRef, useState } from "react"
import WaveSurfer from "wavesurfer.js"
import RecordPlugin from "wavesurfer.js/dist/plugins/record"

type Props = {
  stream: MediaStream | string | undefined
  height?: number
  width?: string
  progressColor?: string
  waveColor?: string
  isRecording?: boolean
  className?: string
}

const useWavesurfer = (
  containerRef: RefObject<HTMLDivElement>,
  options: { [x: string]: any } = {}
) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null)
  const isRecording = options.isRecording

  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    let otherOptions: any = {
      waveColor: options.waveColor || "#4666F6",
      progressColor: options.progressColor || "#6B85F8",
      interact: false,
      cursorWidth: 2,
      barRadius: 10,
      autoCenter: true,
    }
    if (isRecording) {
      otherOptions = {
        ...otherOptions,
        barWidth: 10,
        barGap: 15,
      }
    } else {
      otherOptions = {
        ...otherOptions,
        barWidth: 5,
        barGap: 4,
      }
    }
    const ws = WaveSurfer.create({
      ...options,
      url: options.stream,
      container: containerRef.current,
      ...otherOptions,
    })
    if (isRecording) {
      const record = ws.registerPlugin(RecordPlugin.create())
      record.startRecording()
    }
    setWavesurfer(ws)

    return () => {
      ws.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, containerRef])

  return wavesurfer
}

const WaveForm = (props: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isProcessing, setIsProcessed] = useState(true)
  const wavesurfer = useWavesurfer(containerRef, props)
  const isRecording = props.isRecording

  const onPlayClick = useCallback(() => {
    if (!wavesurfer) {
      return
    }
    wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
  }, [wavesurfer])

  useEffect(() => {
    if (!wavesurfer) {
      return
    }

    setIsPlaying(false)

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("finish", () => wavesurfer.stop()),
      wavesurfer.on("ready", () => setIsProcessed(false)),
    ]

    return () => {
      subscriptions.forEach(unSubscribe => unSubscribe())
    }
  }, [wavesurfer])

  return (
    <div
      className={clsx(
        "flex items-center w-full py-1 pl-1.5 bg-primary-50 rounded-xl",
        props.className
      )}
    >
      {!isRecording && isProcessing && <CircularProgress />}
      <div ref={containerRef} className="grow" />
      {!isRecording && (
        <div>
          <IconButton
            onClick={onPlayClick}
            variant="plain"
            className="bg-black !rounded-full hover:!bg-gray-700 mx-1"
          >
            {isPlaying ? (
              <Pause className="text-white" />
            ) : (
              <Play className="text-white" />
            )}
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default WaveForm
