import React, { useCallback, useEffect, useRef, useState } from "react"
import { MBToBytes } from "utils/helpers"

type FileType = FileList | null | string

type Props = {
  /**
   * Example: [".pdf", ".xlsx", ".docx"]
   */
  allowedExtensions?: string[]
  /**
   * In bytes
   * Default: 1024 (1KB)
   */
  maxSize?: number
  /**
   * Any information that the user should know before uploading files
   * TODO: Implement auto generated instructions for file size and format
   */
  onChange?: (files: FileType) => void
  onFileUpload: (files: File) => void

  inputComponent: (item: InputComponentProps) => React.ReactNode
}

type InputComponentProps = {
  onClick: () => void
  errors: string[]
  validateFiles: (files: FileType) => void
}

const FileUpload = ({
  allowedExtensions = [],
  maxSize = 1,
  onChange,
  onFileUpload,
  inputComponent,
}: Props) => {
  const [files, setFiles] = useState<FileType>(null)
  const [errors, setErrors] = useState<string[]>([])

  const ref = useRef<HTMLInputElement>(null)

  const validateFiles = useCallback(
    (file: FileType) => {
      if (!file || typeof file === "string") return

      if (file.length === 0) return

      setErrors([])

      const fileExt = file[0].name.split(".").pop()
      const fileSize = file[0].size

      if (
        allowedExtensions.length > 0 &&
        !allowedExtensions.includes(`.${fileExt}`)
      ) {
        setErrors(e => [...e, "Invalid file format"])
        return
      }

      if (fileSize > MBToBytes(maxSize)) {
        setErrors(e => [...e, `File size is too large`])
        return
      }

      onFileUpload(file[0])
      setFiles(file)
    },
    [allowedExtensions, maxSize, onFileUpload]
  )

  useEffect(() => {
    if (typeof onChange === "function") onChange(files)
  }, [files, onChange])

  const onClick = () => {
    if (ref.current) {
      ref.current.click()
    }
  }

  return (
    <>
      <input
        ref={ref}
        type="file"
        accept={
          allowedExtensions.length > 0 ? allowedExtensions.join() : undefined
        }
        hidden
        onChange={e => validateFiles(e.target.files)}
      />
      {inputComponent({
        onClick,
        errors,
        validateFiles,
      })}
    </>
  )
}

export default FileUpload
