import { Button, Container, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import React from "react"
import { useTheme } from "react-jss"

type Props = {
  onPrev?: () => void
  onNext?: () => void
  disabledNext: boolean
  disabledPrev: boolean
}

const BottomNavigation = ({
  onNext,
  onPrev,
  disabledNext,
  disabledPrev,
}: Props) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className="bottom-0 fixed z-[6] w-full left-0">
      <Container fullWidth={isSmDown}>
        <div className="flex justify-between py-2 px-3 bg-onSurface-900 rounded-t-2xl">
          <Button
            onClick={onPrev}
            disabled={!onPrev || disabledPrev}
            size={isXs ? "sm" : "md"}
          >
            Previous
          </Button>
          <Button
            onClick={onNext}
            disabled={!onNext || disabledNext}
            size={isXs ? "sm" : "md"}
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default BottomNavigation
