import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getNormaUrl = getServiceURL(SERVICE_PREFIX.norma)

export const urls = {
  list: () => "/v1/ito/reports/",
  verifyParticipationHistory: () =>
    getNormaUrl(`/v1/registrations/participation-history/verify/`),
  participationHistory: () =>
    getNormaUrl(`/v1/registrations/participation-history/`),
}
