/* eslint-disable jsx-a11y/media-has-caption */
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  LinearProgress,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { QuestionStatus } from "api/resources/ito/assessments/types"
import clsx from "clsx"
import FullPageLoading from "components/FullPageLoading"
import { closeWindow } from "features/ITO/Assessments/helpers"
// import { GA } from "shared/utils/googleAnalytics"
import { Status as WebcamStatus } from "features/ITO/Assessments/Proctoring/hooks/useCamDetection"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { browserIsSupportedForITO } from "utils/constants"
// import { useDuplicateTabDetection } from "utils/hooks/useDuplicateTabDetection"
import useHideGlobalBottomNavigation from "utils/hooks/useGlobalHideBottomNavigation"
import useHideTopNavigation from "utils/hooks/useHideTopNavigation"
import { useItoAssessment } from "utils/hooks/useItoAssessment"

import UnsupportedBrowserDialog from "../Instructions/SystemRequirement/UnsupportedBrowserDialog"
import Navbar from "../Navbar"
import Proctoring from "../Proctoring"
// import { PageFPS } from "../Proctoring/hooks/usePageFPS"
import AssessmentFooter from "./AssessmentFooter"
// import AssessmentShepherd from "./AssessmentShepherd"
import styles from "./Attempt.module.css"
import CameraPanel from "./CameraPanel"
import { ASSESSMENT_ROOT_DIV_ID } from "./constants"
import DarkOverlayLoading from "./DarkOverlayLoading"
import DurationTimer from "./DurationTimer"
import InternetConnectionBanner from "./InternetConnectionBanner"
import OffenceBar from "./OffenceBar"
import Question from "./Question"
import QuestionPanel from "./QuestionPanel"
import shepherdClass from "./shepherdClasses"
import SubmitConfirmationDialog from "./SubmitConfirmationDialog"
import SubmittedSuccessfullyDialog from "./SubmittedSuccessfullyDialog"
import TimeUpDialog from "./TimeUpDialog"

const AttemptITOAssessment = () => {
  useHideTopNavigation()
  useHideGlobalBottomNavigation()

  const { attemptId } = useParams()
  // useDuplicateTabDetection({ uniqueId: attemptId })
  const [questionNumber, setQuestionNumber] = useState(1)

  const [webCamStatus, setWebCamStatus] = useState<
    WebcamStatus | null | undefined
  >()

  const [invalidAttemptReason, setInvalidAttemptReason] = useState("")
  const [fiveMinutesLeft, setFiveMinutesLeft] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [oneMinuteLeft, setOneMinuteLeft] = useState(false)

  const [congratulationsDialog, setCongratulationsDialog] = useState(false)

  const onTimeEnd = async () => {
    if (
      import.meta.env.VITE_ITO_DISABLE_AUTO_SUBMIT === "true" ||
      import.meta.env.VITE_ITO_TEST_MODE === "true"
    )
      return
    const submitted = await submit({ autoSubmitReason: "time_up" })
    if (submitted) setAutoSubmitDialogOpen(true)
  }

  // Wake lock
  useEffect(() => {
    const init = async () => {
      try {
        if ("wakeLock" in navigator) {
          const wakeLockManager: any = navigator.wakeLock
          wakeLockManager?.request("screen")
        }
      } catch (err) {
        // console.error(err)
      }
    }

    init()
  }, [])

  const {
    testDuration,
    questionBank,
    markAnswer,
    submit,
    attempt,
    isSubmitting,
  } = useItoAssessment({
    onInvalidAttempt: reason => {
      setInvalidAttemptReason(reason)
      closeWindow(5000)
    },
    attemptId: attemptId ?? "",
  })

  const isITO = attempt?.assessment.slug.includes(
    "international-teachers-olympiad"
  )

  const getNavBarTitle = () => {
    if (!attempt) return ""

    if (isITO) {
      return attempt.assessment.slug
    }

    return attempt.assessment.name
  }

  const submitAssessment = async () => {
    const submitted = await submit()

    if (submitted) {
      setCongratulationsDialog(true)
      closeWindow(5000)
    }
  }

  const unattemptedQuestionsCount = questionBank.filter(
    ({ status }) => status === QuestionStatus.UNATTEMPTED
  ).length

  const unreadQuestionsCount = questionBank.filter(
    ({ status }) => status === QuestionStatus.UNREAD
  ).length

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const [autoSubmitDialogOpen, setAutoSubmitDialogOpen] = useState(false)
  const [maxWarningReached, setMaxWarningReached] = useState(false)

  useEffect(() => {
    const autoSubmit = async () => {
      if (
        import.meta.env.VITE_ITO_DISABLE_AUTO_SUBMIT === "true" ||
        import.meta.env.VITE_ITO_TEST_MODE === "true"
      )
        return

      const submitted = await submit({ autoSubmitReason: "max_violations" })

      if (submitted) {
        closeWindow(3000)
      }
    }

    if (maxWarningReached) {
      autoSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWarningReached])

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))

  const handleTimeCallback = (time: number) => {
    if (time === 300 * 1000) setFiveMinutesLeft(true)
    if (time === 60 * 1000) setOneMinuteLeft(true)
  }

  const currentQuestion = questionBank.find(q => q.sequence === questionNumber)
  useEffect(() => {
    if (currentQuestion?.status === QuestionStatus.UNREAD)
      markAnswer(currentQuestion, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion])

  if (!currentQuestion || !attemptId || invalidAttemptReason)
    return <FullPageLoading message={invalidAttemptReason} />

  const handleResponseChange = (value: number) => {
    if (currentQuestion.response?.includes(value)) {
      markAnswer(currentQuestion, [])
    } else {
      markAnswer(currentQuestion, [value])
    }
  }

  const scrollIntoView = () => {
    try {
      const element = document.querySelector(".scroll-to-hook")
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const trackQuestionChange = (
    number: number,
    mode: "previous" | "next" | "jump"
  ) => {
    const q = questionBank.find(({ sequence }) => sequence === number)
    if (!q) return

    // GA.trackEvent("ito_assessment_view_question", {
    //   question_id: q.question.id,
    //   question_number: q.sequence,
    //   assessment_id: attempt?.id,
    //   mode,
    // })
  }

  const handleQuestionChange = (number: number) => {
    scrollIntoView()
    setQuestionNumber(number)
    trackQuestionChange(number, "jump")
  }

  const onPrevious = () => {
    if (questionNumber === 1) return
    setQuestionNumber(questionNumber - 1)
    scrollIntoView()
    trackQuestionChange(questionNumber - 1, "previous")
  }

  const onNext = () => {
    if (questionNumber === questionBank.length) return
    setQuestionNumber(questionNumber + 1)
    scrollIntoView()
    trackQuestionChange(questionNumber + 1, "next")
  }

  const proctoringEnabled = attempt?.assessment.isProctored === true
  const maxWarningCount = attempt?.assessment.maxWarningCount ?? null

  /**
   * Goal is to only show the user 10 warnings but in reality we have like...40
   * We want to prevent users from actually getting auto-submitted because that is a hassle for operations
   */
  const fakeWarningCount = maxWarningCount ? 10 : null

  const testType = isITO ? "Olympiad" : "Assessment"

  return (
    <DarkOverlayLoading
      enabled={isSubmitting || (proctoringEnabled && webCamStatus === null)}
      message={
        proctoringEnabled && webCamStatus === null
          ? "Checking your system"
          : isSubmitting
          ? "Submitting"
          : ""
      }
    >
      {/* <AssessmentShepherd isSkippable={isITO} isProctored={proctoringEnabled}> */}
      <Proctoring
        isITO={isITO}
        // This is safe to do because everywhere inside, this count is only used for presentation layer
        maxWarningCount={fakeWarningCount}
        disabled={!proctoringEnabled}
        attemptId={attemptId}
        setWebCamStatus={setWebCamStatus}
        assessmentId={attempt?.assessment.id || -1}
        onTotalWarningsChange={warningsIncurred => {
          // Do not use fakeWarningCount here because this drives logic!
          if (maxWarningCount)
            setMaxWarningReached(warningsIncurred >= maxWarningCount)
        }}
        render={({
          totalWarnings,
          testIsAccessible,
          videoRef,
          proctoringAlerts,
          warnings,
        }) => {
          return (
            <>
              {/* <PageFPS /> */}
              {browserIsSupportedForITO !== "supported" && (
                <UnsupportedBrowserDialog open />
              )}
              <span
                className="scroll-to-hook"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />

              {congratulationsDialog && (
                <SubmittedSuccessfullyDialog
                  open={congratulationsDialog}
                  parentSelector={() =>
                    document.querySelector(`#${ASSESSMENT_ROOT_DIV_ID}`)
                  }
                />
              )}

              {submitDialogOpen && (
                <SubmitConfirmationDialog
                  warnings={warnings}
                  totalWarnings={totalWarnings}
                  markedForReviewQuestionCount={0}
                  unattemptedQuestionsCount={unattemptedQuestionsCount}
                  unreadQuestionsCount={unreadQuestionsCount}
                  open={submitDialogOpen}
                  parentSelector={() =>
                    document.querySelector(`#${ASSESSMENT_ROOT_DIV_ID}`)
                  }
                  handleClose={() => {
                    setSubmitDialogOpen(false)
                  }}
                  onSubmit={() => {
                    setSubmitDialogOpen(false)
                    submitAssessment()
                  }}
                />
              )}
              {autoSubmitDialogOpen && (
                <TimeUpDialog
                  parentSelector={() =>
                    document.querySelector(`#${ASSESSMENT_ROOT_DIV_ID}`)
                  }
                  open={autoSubmitDialogOpen}
                  handleClose={() => {
                    window.close()
                  }}
                />
              )}

              {maxWarningReached && (
                <Dialog
                  parentSelector={() =>
                    document.querySelector(`#${ASSESSMENT_ROOT_DIV_ID}`)
                  }
                  open={maxWarningReached}
                  onRequestClose={() => {
                    window.close()
                  }}
                >
                  <DialogTitle>
                    <Typography variant="subtitle2">
                      {testType} Auto-submitted
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Typography variant="body" className="mb-1.5">
                      Your {testType} has been auto-submitted as you have
                      exceeded the maximum allowed violation count.
                    </Typography>
                    {fakeWarningCount && (
                      <div
                        className={clsx(
                          "grid gap-0.75 p-1.5 mb-1.25",
                          styles.offenceAutoSubmit
                        )}
                      >
                        <Typography variant="preTitle" color="onSurface.600">
                          Violation count
                        </Typography>
                        <LinearProgress
                          color="warning"
                          value={(totalWarnings / fakeWarningCount) * 100}
                          linearGradient="linear-gradient(to right,#F2D16A,#CE3E3E)"
                        />
                        <div className={styles.onSurface600}>
                          <Typography variant="strongSmallBody">
                            {totalWarnings} out of {fakeWarningCount} times
                          </Typography>
                          <Typography variant="smallBody">triggered</Typography>
                        </div>
                      </div>
                    )}
                  </DialogContent>
                  <DialogFooter
                    actions={{
                      primary: {
                        label: "Okay",
                        onClick: () => {
                          window.close()
                        },
                      },
                      secondary: null,
                    }}
                  />
                </Dialog>
              )}

              <InternetConnectionBanner />

              <div
                id={ASSESSMENT_ROOT_DIV_ID}
                className={clsx(styles.mainBody, "noselect")}
              >
                <Navbar
                  navBarTitle={getNavBarTitle()}
                  gutterBottom={false}
                  slotEnd={
                    <div className="grid gap-2 items-center grid-flow-col">
                      <div className="grid items-end">
                        <div
                          className={clsx(
                            { [styles.redText]: fiveMinutesLeft },
                            styles.timerContainer
                          )}
                        >
                          <DurationTimer
                            toDate={testDuration}
                            timeLeftCallback={handleTimeCallback}
                            // [1 minute, 5 minutes] (in milliseconds)
                            notifyOnTimeLeft={[60 * 1000, 300 * 1000]}
                            // eslint-disable-next-line no-console
                            onEnd={onTimeEnd}
                          />
                        </div>
                        <Typography variant="smallBody" color="onSurface.500">
                          time left
                        </Typography>
                      </div>
                      <Button
                        // {...GA.trackElement("ito-assessment-submit-btn", {
                        //   feature: "ito",
                        //   purpose: "attempt_assessment",
                        // })}
                        className={shepherdClass["submitTestButton"]}
                        size="sm"
                        onClick={() => setSubmitDialogOpen(true)}
                      >
                        Submit
                      </Button>
                    </div>
                  }
                />
                {proctoringAlerts}

                <div className={clsx("h-full pt-7")}>
                  <div
                    className={clsx({
                      [styles.none]: !isMdDown || !proctoringEnabled,
                    })}
                  >
                    {fakeWarningCount !== null && (
                      <OffenceBar
                        maxWarningCount={fakeWarningCount}
                        videoRef={videoRef}
                        totalWarnings={totalWarnings}
                      />
                    )}
                  </div>
                  <div className="grid gap-1 h-100">
                    <div className={`${styles.content} row-span-1 grid-cols-5`}>
                      {proctoringEnabled &&
                        fakeWarningCount !== null &&
                        !isMdDown && (
                          <div
                            className={clsx("col-span-1", {
                              [styles.none]: isMdDown,
                            })}
                          >
                            <CameraPanel
                              maxWarningCount={fakeWarningCount}
                              videoRef={videoRef}
                              totalWarnings={totalWarnings}
                            />
                          </div>
                        )}
                      <div
                        className={clsx("col-span-5 ", {
                          "lg:col-span-3": proctoringEnabled,
                          "lg:col-span-4": !proctoringEnabled,
                        })}
                      >
                        <div className={clsx(styles.test, "grid")}>
                          {testIsAccessible && (
                            <Question
                              question={currentQuestion}
                              handleResponse={handleResponseChange}
                            />
                          )}
                        </div>
                      </div>

                      {!isMdDown && testIsAccessible && (
                        <div className="col-span-1">
                          <QuestionPanel
                            onQuestionChange={handleQuestionChange}
                            questions={questionBank}
                            currentQuestion={currentQuestion}
                          />
                        </div>
                      )}
                    </div>
                    {testIsAccessible && (
                      <div
                        className={clsx(
                          "grid grid-cols-5 gap-7",
                          styles.footerContainer
                        )}
                      >
                        <div
                          className={clsx("col-span-5", {
                            "lg:col-span-3 lg:col-start-2 lg:pl-1":
                              proctoringEnabled,
                            "lg:col-span-4 lg:pl-3": !proctoringEnabled,
                          })}
                        >
                          <AssessmentFooter
                            onQuestionChange={handleQuestionChange}
                            onNext={onNext}
                            onPrevious={onPrevious}
                            questions={questionBank}
                            currentQuestion={currentQuestion}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )
        }}
      />
      {/* </AssessmentShepherd> */}
    </DarkOverlayLoading>
  )
}

export default AttemptITOAssessment
