import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import React from "react"
import { createUseStyles } from "react-jss"

import ImageOne from "./assets/1.png"
import ImageTwo from "./assets/2.png"
import ImageThree from "./assets/3.png"
import FacebookIcon from "./assets/facebook-icon.svg"
import InstagramIcon from "./assets/instagram-icon.svg"
import LinkedInIcon from "./assets/linkedin-icon.svg"
import YouTubeIcon from "./assets/youtube-icon.svg"

const useStyles = createUseStyles(theme => ({
  container: {
    border: "1px solid #F8FAFC",
    background: "#FFF",
    boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: 16,
    padding: theme.spacing(0, 4),

    backgroundImage: `
    radial-gradient(at 0% 90%, rgb(204, 186, 250, 0.5) 0, transparent 80%),
    radial-gradient(at 100% 0%, rgb(221, 209, 252, 0.35) 0, transparent 45%),
    radial-gradient(at 100% 100%, rgb(245, 198, 236, 0.35) 0, transparent 45%)`,
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      gap: "30px",
      padding: theme.spacing(2, 2, 0, 2),
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto auto",
    },
  },
  btn: {
    background:
      "linear-gradient(138deg, #A666FF 0%, #D757FF 27.35%, #FF6A7E 70.31%, #FFE052 100%)",
  },
}))

const StayConnected = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>
        <SwitzerTypography
          className="!text-ito-midnight mb-1 font-bold"
          style={{
            fontFamily: "Switzer Bold",
          }}
          variant="title2"
        >
          Stay Connected
        </SwitzerTypography>
        <Typography
          className="!text-ito-midnight sm:max-w-[349px] w-full block mb-4"
          variant="largeBody"
        >
          Follow us on social media for the latest updates & the buzz around the
          Olympiad.
        </Typography>
        <div className="flex gap-2 items-center flex-wrap">
          <Button
            rounded
            className={classes.btn}
            component="a"
            startAdornment={<img src={InstagramIcon} alt="instagram" />}
            href="https://www.instagram.com/teachersolympiad/"
          >
            Follow on Instagram
          </Button>
          <div className="shrink-0 flex gap-1.5">
            <IconButton
              color="black"
              variant="filled"
              className="!rounded-full shrink-0"
              size="md"
              component="a"
              href="https://www.facebook.com/teachersolympiad"
            >
              <img src={FacebookIcon} alt="facebook" />
            </IconButton>
            <IconButton
              color="black"
              variant="filled"
              className="!rounded-full shrink-0"
              size="md"
              component="a"
              href="https://www.youtube.com/@SuraasaTeach"
            >
              <img src={YouTubeIcon} alt="youtube" />
            </IconButton>
            <IconButton
              color="black"
              variant="filled"
              className="!rounded-full shrink-0"
              size="md"
              component="a"
              href="https://www.linkedin.com/company/international-teachers-olympiad/"
            >
              <img src={LinkedInIcon} alt="linkedin" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="overflow-hidden flex justify-center">
        <img width={144} alt="social-post" className="" src={ImageOne} />
        <img width={144} alt="social-post" className="" src={ImageTwo} />
        <img width={144} alt="social-post" className="" src={ImageThree} />
      </div>
    </div>
  )
}

export default StayConnected
