import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import {
  formatErrorResponse,
  formatSuccessResponse,
  logAPIError,
} from "api/utils"
import Axios from "axios"
import { logOnFirebase } from "features/ITO/utils/logging"
import { AUTHORIZATION_HEADER_PREFIX } from "utils/config"
import { getAuthInfo } from "utils/helpers"

import {
  Assessment,
  AssessmentOverview,
  Attempt,
  AutoSubmitted,
  Warning,
} from "./types"
import { urls } from "./urls"
export default {
  getAttempt: async ({
    urlParams: { attemptId },
  }: ConfigWithURLParams): Promise<APIResponse<Attempt | AutoSubmitted>> => {
    const url = urls.assessment(attemptId)
    logOnFirebase({ url, body: null, method: "POST" })

    try {
      const res = await axios.post(url)
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      throw error
    }
  },

  list: async (): Promise<APIResponse<Assessment[]>> => {
    try {
      const res = await axios.get(urls.list())
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      throw error
    }
  },
  createAttempt: async ({
    params,
  }: Config): Promise<APIResponse<Pick<Attempt, "id" | "status">>> => {
    try {
      const res = await axios.post(urls.createAttempt(params.assessmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      throw error
    }
  },
  getAssessment: async ({
    params,
  }: Config): Promise<APIResponse<Assessment>> => {
    try {
      const res = await axios.get(urls.getAttempt(params.assessmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      throw error
    }
  },

  submit: async ({
    urlParams: { attemptId },
  }: ConfigWithURLParams): Promise<
    APIResponse<NoContentType | AutoSubmitted>
  > => {
    const url = urls.submitAssessment(attemptId)
    logOnFirebase({ url, body: null, method: "POST" })

    try {
      const res = await axios.post(url)
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      throw error
    }
  },

  markAnswer: async ({
    urlParams: { questionId },
    data,
  }: ConfigWithURLParams): Promise<
    APIResponse<NoContentType | AutoSubmitted>
  > => {
    const url = urls.markAnswer(questionId)
    logOnFirebase({ url, body: data, method: "PUT" })

    try {
      const res = await axios.put(url, data)
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      throw error
    }
  },

  updateWarningCount: async ({
    urlParams: { attemptId },
    data,
  }: ConfigWithURLParams): Promise<
    APIResponse<NoContentType | AutoSubmitted>
  > => {
    const url = urls.warningCount(attemptId)
    logOnFirebase({ url, body: data, method: "POST" })

    try {
      const res = await axios.post(url, data)
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error)
      throw error
    }
  },

  getWarningCount: async ({
    urlParams: { attemptId },
  }: ConfigWithURLParams): Promise<APIResponse<Warning | AutoSubmitted>> => {
    try {
      const res = await axios.get(urls.warningCount(attemptId))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      logAPIError(error, [401, 404])
      throw error
    }
  },

  overview: async (): Promise<APIResponse<AssessmentOverview>> => {
    try {
      const res = await axios.get(urls.overview())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  uploadViolation: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    const authInfo = getAuthInfo()
    const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${authInfo?.accessToken}`
    try {
      const baseURL = import.meta.env.VITE_ITO_VIOLATION_STORAGE_API_BASE_URL
      if (!baseURL)
        throw new Error(
          "VITE_ITO_VIOLATION_STORAGE_API_BASE_URL not found in .env"
        )

      const axiosApi = Axios.create({
        baseURL,
        withCredentials: true,
        headers: {
          Authorization: tokenString,
        },
      })
      const res = await axiosApi.post(urls.violation(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
