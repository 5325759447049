export const MAX_VIDEO_PORTFOLIO_SIZE = 200

export const MAX_VIDEO_PORTFOLIO_DURATION = 3

export const VIDEO_PORTFOLIO_FORMATS = [
  ".mp4",
  ".mov",
  ".avi",
  ".heif",
  ".hevc",
]

export const BYTES_IN_1_MB = 1000 * 1000

export enum Steps {
  INTRO,
  UPLOAD,
  VIEW,
}
