import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import ReportsLinked from "assets/Ito/ReportsLinkedAsset.png"
import GradientButton from "features/ITO/components/GradientButton"
import styles from "features/ITO/LinkReportsBanner/Linkreports.module.css"
import { Xmark } from "iconoir-react"
import React from "react"

const SuccessfullyLinkedDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  return (
    <Dialog open={open} onRequestClose={() => onClose()}>
      <DialogContent className={styles.noPadding}>
        <div className="grid grid-row-2">
          <div
            className="row-span-1 bg-surface-50 relative rounded-t-lg pt-5 px-5"
            style={{
              boxShadow: "0px -1px 5px 0px rgba(0, 0, 0, 0.10) inset",
            }}
          >
            <img alt="comparative-analysis" src={ReportsLinked} />
            <IconButton
              className="absolute top-[10px] right-[8px]"
              color="black"
              onClick={() => onClose()}
            >
              <Xmark />
            </IconButton>
          </div>
          <div className="row-span-1 text-center px-3 py-5">
            <Typography variant="title2" className="mb-1">
              Reports Linked Successfully!
            </Typography>
            <Typography>
              Your 2023 ITO reports now will have a detailed comparative
              analysis of your growth.
            </Typography>
          </div>
          <div className="pb-3 px-3">
            <GradientButton fullWidth onClick={() => onClose()}>
              Great!
            </GradientButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SuccessfullyLinkedDialog
