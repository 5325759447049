import { Button, Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import OverlayWrapper from "features/ITO/Assessments/Proctoring/OverlayWrapper"
import { Expand } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"

type Props = {
  onContinue: () => void
  testType: string
}

const useStyles = createUseStyles((theme: Theme) => ({
  color: {
    color: "#FFFFFF",
  },
  box: {
    backgroundColor: "#EFEFF7",
    borderRadius: "14px",
  },
  text: {
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const FullscreenOverlay = ({ onContinue, testType }: Props) => {
  const classes = useStyles()

  return (
    <>
      <OverlayWrapper backgroundImg="url('/src/features/ITO/Assessments/Attempt/Proctoring/assets/attemptAssessment.png')">
        <div className="flex flex-col justify-center items-center gap-8 sm:p-4 p-2">
          <div>
            <div
              className={clsx(
                classes.box,
                " flex justify-center sm:p-8 xs:p-6 p-4"
              )}
            >
              <Expand height={75} width={75} />
            </div>
          </div>
          <Typography
            variant="title1"
            className={clsx(classes.color, classes.text)}
            textAlign="center"
          >
            You need to be in fullscreen mode to attempt the {testType}
          </Typography>
          <Button onClick={onContinue}>Continue {testType}</Button>
        </div>
      </OverlayWrapper>
    </>
  )
}

export default FullscreenOverlay
