import {
  Button,
  Tag,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import {
  LearningItem,
  UserPlannerItemStatus,
} from "api/resources/learningItems/types"
import clsx from "clsx"
import Card from "components/Card"
import TruncatedText from "components/TruncatedText"
import {
  ChatLines,
  JournalPage,
  Lock,
  PageFlip,
  Play,
  SoccerBall,
} from "iconoir-react"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { pluralize } from "utils/helpers"

const getIcon = (contentType: string, status?: UserPlannerItemStatus) => {
  const colors = {
    success: "text-success-500 bg-success-100",
    interactive: "text-interactive-900 bg-interactive-100",
    onSurface: "text-onSurface-900 bg-onSurface-200",
  }

  const iconStyle = clsx("p-1 rounded-md me-1.5", {
    [colors.success]: status === "completed",
    [colors.interactive]: status === "in_progress" || status === "incomplete",
    [colors.onSurface]: status === "locked" || status === "unlocked",
  })

  if (contentType === "video") {
    return (
      <div className={iconStyle}>
        <Play />
      </div>
    )
  }
  if (contentType === "article") {
    return (
      <div className={iconStyle}>
        <JournalPage />
      </div>
    )
  }
  if (contentType === "discussion") {
    return (
      <div className={iconStyle}>
        <ChatLines />
      </div>
    )
  }
  if (contentType === "assessment") {
    return (
      <div className={iconStyle}>
        <PageFlip />
      </div>
    )
  }
  if (contentType === "activity") {
    return (
      <div className={iconStyle}>
        <SoccerBall />
      </div>
    )
  }
  return null
}

const Title = ({
  title,
  description,
  hasSavedItems = false,
  contentType,
}: {
  title: string
  description?: string
  hasSavedItems?: boolean
  contentType: string
}) => {
  return (
    <div className="flex flex-col align-middle">
      <div className="flex">
        <TruncatedText
          maxLength={50}
          hideActionButton
          variant="strongSmallBody"
        >
          {title}
        </TruncatedText>
        {hasSavedItems && <Tag label="Saved" size="md" className="ms-1" />}
      </div>
      <div className="flex items-center text-onSurface-500">
        <Typography variant="smallBody" className="capitalize">
          {contentType}
        </Typography>
        {description && (
          <>
            <div className="bg-onSurface-500 rounded-full h-0.5 w-0.5 mx-1" />
            <Typography variant="smallBody" className="capitalize">
              {description}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}

const MilestoneItem = ({
  item,
  plannerId,
}: {
  item: LearningItem
  plannerId: number
}) => {
  const { slug } = useParams()
  const smUp = useMediaQuery(theme.breakpoints.up("sm"))

  const getAction = () => {
    if (!smUp || !slug) return null

    if (item.status === "locked") return <Lock width={20} height={20} />

    const text =
      item.status === "unlocked"
        ? "Start"
        : item.status === "in_progress"
        ? "Continue"
        : "View"

    return (
      <Button
        variant="text"
        component={Link}
        to={`${routes.learningModule.replace(
          ":slug",
          slug
        )}?plannerId=${plannerId}&itemId=${item.id}`}
      >
        {text}
      </Button>
    )
  }

  return (
    <Link
      to={`${routes.learningModule.replace(
        ":slug",
        slug as string
      )}?plannerId=${plannerId}&itemId=${item.id}`}
    >
      <Card
        border={0}
        padding={0}
        infoSlot={
          <div className="flex items-center grow">
            {getIcon(item.learningContentType, item.status)}

            <div className="flex">
              {item.learningContentType === "article" && (
                <Title
                  title={item.title}
                  hasSavedItems={item.bookmarkArticle.length > 0}
                  contentType={item.learningContentType}
                  description={
                    item.optimumTime
                      ? pluralize("min", Math.ceil(item.optimumTime / 60))
                      : undefined
                  }
                />
              )}

              {item.learningContentType === "video" && (
                <Title
                  title={item.title}
                  hasSavedItems={item.bookmarkVideo.length > 0}
                  contentType={item.learningContentType}
                  description={
                    item.duration
                      ? pluralize("min", Math.ceil(item.duration / 60))
                      : undefined
                  }
                />
              )}

              {item.learningContentType === "assessment" && (
                <Title
                  title={item.title}
                  description={pluralize(
                    "Question",
                    item.totalNumberOfQuestions
                  )}
                  contentType={item.learningContentType}
                />
              )}

              {item.learningContentType === "discussion" && (
                <Title
                  title={item.milestone.title}
                  contentType={item.learningContentType}
                />
              )}

              {item.learningContentType === "activity" && (
                <Title
                  title={item.title}
                  contentType={item.learningContentType}
                  description={
                    import.meta.env.MODE === "development"
                      ? item.activityType.replaceAll("_", " ")
                      : undefined
                  }
                />
              )}
            </div>
          </div>
        }
        actionSlot={getAction}
      />
    </Link>
  )
}

export default MilestoneItem
