import {
  Button,
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ApplicationOverview,
  ApplicationStatus,
  InterviewStatus,
  ReviewStatus,
} from "api/resources/application/types"
import clsx from "clsx"
import React, { useCallback, useEffect, useState } from "react"
import { useTheme } from "react-jss"
import routes from "routes"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { formatDate, generateAuthCode, getPlatformURL } from "utils/helpers"

import styles from "./ApplicationStatusBanner.module.css"

const SCHEDULE_INTERVIEW_LINK = routes.scheduleInterview

const WaveLoader = () => {
  return (
    <div className={styles.waveLoader}>
      <span />
      <span />
      <span />
    </div>
  )
}

const ApplicationStatusBanner = () => {
  const { suraasaApplicationFormBannerDismissed } = BROWSER_STORAGE_KEYS
  const theme = useTheme<Theme>()

  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const smUp = useMediaQuery(theme.breakpoints.up("sm"))

  const [overview, setOverview] = useState<ApplicationOverview[]>([])
  const [loadingBtn, setLoadingBtn] = useState(false)

  const [isNewTabOpen, setIsNewTabOpen] = useState(false)

  const hasAlreadyDismissed =
    localStorage.getItem(suraasaApplicationFormBannerDismissed) === "true"

  const [showBanner, setShowBanner] = useState(!hasAlreadyDismissed)

  const fetchApplicationOverview = async () => {
    const res = await api.application.applicationOverview()
    if (res.isSuccessful) {
      setOverview(res.data)

      if (res.data.length > 0) {
        const { interview, review } = res.data[0]

        if (
          (interview &&
            interview?.status !== InterviewStatus.NO_SHOW &&
            interview?.status !== InterviewStatus.CANCELLED) ||
          review?.status !== ReviewStatus.INTERVIEW_REQUESTED
        ) {
          clearListener()
        }
      }
    } else {
      console.error(res.errors)
    }
  }

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      fetchApplicationOverview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearListener = () => {
    window.document.removeEventListener(
      "visibilitychange",
      handleVisibilityChange
    )
  }

  const handleOnClick = async ({
    link,
    buttonName,
  }: Exclude<ReturnType<typeof getBannerDetails>, null>) => {
    if (isNewTabOpen) {
      window.alert("Application is already open in different tab")
      return
    }

    if (buttonName === "Dismiss") {
      localStorage.setItem(suraasaApplicationFormBannerDismissed, "true")
      setShowBanner(false)
      return
    }

    if (link) {
      window.document.addEventListener(
        "visibilitychange",
        handleVisibilityChange
      )

      const scheduleWindow = window.open(link, "_blank")

      if (scheduleWindow) {
        const timer = setInterval(function () {
          if (scheduleWindow.closed) {
            clearInterval(timer)
            clearListener()
          }
        }, 1000)
      }
      return
    }

    setLoadingBtn(true)
    const redirectLink = "/application-form/pgctl"
    const code = await generateAuthCode()

    const newTab = window.open(
      getPlatformURL(
        "suraasa",
        code
          ? `${redirectLink}/?authCode=${code}&redirect=${getPlatformURL(
              "suraasa",
              redirectLink
            )}`
          : redirectLink
      ),
      "_blank"
    )
    setIsNewTabOpen(true)
    setLoadingBtn(false)

    const timer = setInterval(checkNewTab, 500)
    function checkNewTab() {
      if (newTab && newTab.closed) {
        clearInterval(timer)
        setIsNewTabOpen(false)
        window.location.reload()
      }
    }
  }

  const getBannerDetails = useCallback((): {
    title: string
    subTitle?: string
    icon?: "success" | "critical"
    link?: string
    isUnderReview?: boolean
    buttonName?:
      | "Proceed to pay"
      | "Dismiss"
      | "Get Started"
      | "Complete Form"
      | "Schedule Interview"
      | "Join Interview"
      | "Schedule Interview"
      | "Update changes"
      | "Schedule Interview"
    theme: "primary" | "success" | "critical" | "warning"
  } | null => {
    if (overview.length >= 1) {
      const { interview, review, status } = overview[0]

      switch (status) {
        case ApplicationStatus.ACCEPTED:
          // return {
          //   title: "Congrats! Your application has been accepted",
          //   subTitle:
          //     "Your application for PgCTL has been accepted. Pay registration fee to start PgCTL.",
          //   icon: "success",
          //   theme: "success",
          //   buttonName: "Proceed to pay",
          // }
          return {
            title: "Congrats! Your application has been accepted",
            subTitle:
              "Our team has reviewed your application and we’d like to inform you that you are eligible for PgCTL certification.",
            icon: "success",
            theme: "success",
            buttonName: "Dismiss",
          }

        case ApplicationStatus.REJECTED:
          return {
            title: "Your application has been rejected!",
            subTitle:
              "Our team has reviewed your application and we’d like to inform you that you are not eligible for PgCTL right now. We encourage you to apply again in future",
            theme: "critical",
            buttonName: "Dismiss",
          }

        case ApplicationStatus.NOT_STARTED:
          return {
            title: "Application Form is ready!",
            subTitle: "Now you can fill the form.",
            buttonName: "Get Started",
            theme: "success",
          }

        case ApplicationStatus.IN_PROGRESS:
          return {
            title: "Complete your application form",
            subTitle: "Please submit your remaining details",
            buttonName: "Complete Form",
            theme: "warning",
          }

        case ApplicationStatus.SUBMITTED:
          return {
            title: "Your PgCTL application is under review",
            theme: "primary",
            isUnderReview: true,
          }
      }

      if (
        interview === null &&
        review?.status === ReviewStatus.INTERVIEW_REQUESTED
      ) {
        return {
          title: "Schedule your PgCTL interview!",
          subTitle:
            "Please pick a day and time of interview from the given calendar",

          theme: "primary",
          buttonName: "Schedule Interview",
          link: SCHEDULE_INTERVIEW_LINK,
        }
      }

      if (interview) {
        if (interview?.meetingUrl && interview.scheduledDate) {
          return {
            title: "Join your PgCTL interview!",
            link: interview.meetingUrl,
            subTitle: `on ${formatDate(
              interview.scheduledDate,
              "dd MMM yyyy"
            )} at ${formatDate(interview.scheduledDate, "h:m aaa")}`,
            theme: "primary",
            buttonName: "Join Interview",
          }
        }
        switch (interview.status) {
          case InterviewStatus.NO_SHOW:
          case InterviewStatus.CANCELLED: {
            return {
              title: "Schedule your PgCTL interview!",
              subTitle:
                "Please pick a day and time of interview from the given calendar",

              theme: "primary",
              buttonName: "Schedule Interview",
              link: SCHEDULE_INTERVIEW_LINK,
            }
          }
        }
      }

      if (review) {
        switch (review.status) {
          case ReviewStatus.CHANGES_STARTED:
          case ReviewStatus.CHANGES_REQUESTED:
            return {
              title: "Update requested changes!",
              subTitle: "Changes requested by the Admissions Team",
              theme: "critical",
              buttonName: "Update changes",
            }

          case ReviewStatus.INTERVIEW_REQUESTED: {
            return {
              title: "Schedule your PgCTL interview!",
              subTitle:
                "Please pick a day and time of interview from the given calendar",

              theme: "primary",
              buttonName: "Schedule Interview",
              link: SCHEDULE_INTERVIEW_LINK,
            }
          }
          case ReviewStatus.NOT_STARTED:
          case ReviewStatus.REVIEW_STARTED:
          case ReviewStatus.CHANGES_DONE: {
            return {
              title: "Your PgCTL application is under review",
              theme: "primary",
              isUnderReview: true,
            }
          }
        }
      }
    }
    return null
  }, [overview])

  useEffect(() => {
    fetchApplicationOverview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const overviewData = getBannerDetails()

  const isVisible = overviewData !== null

  if (!showBanner) return null

  if (!isVisible) return null

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={clsx("py-2", {
        "bg-critical-100 text-critical-700": overviewData.theme === "critical",
        "bg-primary-100 text-primary-700": overviewData.theme === "primary",
        "bg-success-100 text-success-700": overviewData.theme === "success",
        "bg-warning-100 text-warning-700": overviewData.theme === "warning",
        "cursor-auto sm:cursor-pointer": overviewData.buttonName,
      })}
      onClick={
        mdUp
          ? undefined
          : () => {
              if (overviewData.buttonName) {
                handleOnClick(overviewData)
              }
            }
      }
      role="button"
      tabIndex={-1}
    >
      <Container>
        <div className={clsx("flex justify-between items-center")}>
          <div
            className={clsx({
              "flex items-center justify-between gap-2":
                overviewData.isUnderReview,
            })}
          >
            <div>
              <Typography variant="strongSmallBody">
                {overviewData.title}
              </Typography>
              <Typography variant="smallBody">
                {overviewData.subTitle}
              </Typography>
            </div>
          </div>
          {overviewData.isUnderReview && smUp && <WaveLoader />}
          {overviewData.buttonName && mdUp && (
            <Button
              color={overviewData.theme}
              loading={loadingBtn}
              size="sm"
              onClick={() => handleOnClick(overviewData)}
            >
              {overviewData.buttonName}
            </Button>
          )}
        </div>
      </Container>
    </div>
  )
}

export default ApplicationStatusBanner
