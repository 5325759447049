import { IconButton, Tag, Typography } from "@suraasa/placebo-ui"
import { Edit, Plus } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { BROWSER_STORAGE_KEYS, UpdateProfileEnum } from "utils/constants"
import { pluralize } from "utils/helpers"

import ProfileContext from "../../context"
import SomethingNew from "../ReOrderingFeatureOnboarding/SomethingNew"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import SubjectsDialog from "./SubjectsDialog"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}))
const { reorderOnboarding: updateProfileDialog } = BROWSER_STORAGE_KEYS

const Subjects = () => {
  const classes = useStyles()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateStatus: string[] = JSON.parse(
    localStorage.getItem(updateProfileDialog) ?? "[]"
  )
  const showSomethingNew = !updateStatus.includes(UpdateProfileEnum.SUBJECTS)

  const {
    subjects: { data },
    isPublic,
  } = useContext(ProfileContext)

  const [openDialog, setOpenDialog] = useState(false)

  const toggle = () => setOpenDialog(!openDialog)

  return (
    <>
      <SubjectsDialog
        open={openDialog}
        onBack={toggle}
        onRequestClose={toggle}
      />
      <Section>
        <SectionHeading
          heading={
            <>
              <SectionTitle
                actionIcon={
                  isPublic ? undefined : (
                    <IconButton onClick={toggle}>
                      {data.length > 0 ? <Edit /> : <Plus />}
                    </IconButton>
                  )
                }
                itemCount={data.length}
                title={pluralize("Subject", data.length, { skipCount: true })}
              />
            </>
          }
        />
        {showSomethingNew && <SomethingNew onClick={toggle} />}
        <SectionContent className={classes.root} xPadding={2}>
          {data.length > 0 ? (
            data.map(item => (
              <Tag key={item.id} label={item.subject.name} size="sm" />
            ))
          ) : (
            <Typography color="onSurface.400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any subjects. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}
export default Subjects
