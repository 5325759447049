import { theme, Typography } from "@suraasa/placebo-ui"
import React from "react"

interface Props {
  title?: string
  additionalInfo?: JSX.Element
  subtitle?: string
  action?: JSX.Element
  backgroundColor?: string
}

const TitleCard = ({
  title,
  additionalInfo,
  subtitle,
  action,
  backgroundColor = theme.colors.highlight[50],
}: Props) => {
  return (
    <div
      className="px-2 py-3 rounded-none sm:rounded-lg -mx-2 sm:mx-0 flex flex-col"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="flex justify-between gap-2 items-center flex-wrap">
        <div className="flex flex-col">
          <Typography variant="title3">{title}</Typography>
          {subtitle && (
            <Typography
              variant="smallBody"
              color="onSurface.500"
              className="mt-1"
            >
              {subtitle}
            </Typography>
          )}
        </div>

        {additionalInfo}

        {action}
      </div>
    </div>
  )
}

export default TitleCard
