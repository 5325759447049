import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  TextField,
  Theme,
  theme,
  toast,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email } from "api/resources/settings/types"
import { APIError } from "api/utils"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { handleErrors } from "utils/helpers"

const useStyles = createUseStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(2, 2, 4.75, 2),
  },
}))

const UpdatePrimaryEmail = ({
  email,
  open,
  close,
  onVerify,
}: {
  email: Email | null
  open: boolean
  onVerify: () => void
  close: () => void
}) => {
  const classes = useStyles()
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ password: string }>()

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = handleSubmit(async data => {
    if (!email) return

    setLoading(true)
    try {
      await api.settings.emails.update({
        urlParams: {
          id: email.id,
        },
        data: {
          isPrimary: true,
          password: data.password,
        },
      })

      toast.success("Primary email updated.")
      onVerify()
      close()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
      }
    }
    setLoading(false)
  })
  if (!email) return null
  return (
    <Dialog
      width={smDown ? "sm" : "md"}
      open={open}
      shouldReturnFocusAfterClose={false}
      onAfterClose={() => reset()}
      onRequestClose={close}
    >
      <DialogTitle>New Primary Email</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent className={classes.dialogContent}>
          <Typography className="mb-3" variant="smallBody">
            Please enter your password to set <b>{email.email}</b> as your
            primary email
          </Typography>

          <TextField
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            placeholder="Enter Password"
            type="password"
            fullWidth
            {...register("password", {
              required: { value: true, message: "Required" },
            })}
          />
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Submit",
              onClick: onSubmit,
              loading,
            },
            secondary: {
              label: "Cancel",
              onClick: close,
            },
          }}
        />
      </form>
    </Dialog>
  )
}

export default UpdatePrimaryEmail
