import { toast } from "@suraasa/placebo-ui"
import { useState } from "react"
import routes from "routes"
/**
 * This function is to check constantly using interval if the user is still in test/assessment/exam
 * and based on it handle and prevent user from entering exam twice
 * reference:-https://stackoverflow.com/questions/5712195/how-to-listen-for-child-window-closing **/

export const useTestWindowOpen = () => {
  const [isInExam, setIsInExam] = useState(false)

  const openTestWindow = ({
    assessmentId,
    onTabClose,
    attemptId,
  }: {
    assessmentId: number | string
    attemptId?: number | string
    onTabClose: () => Promise<void>
  }) => {
    if (isInExam) {
      toast.error("Assessment is already open in a different tab")
      return
    }

    const timer = setInterval(checkNewTab, 500)

    const url = routes.instructionsITOAssessment
      .replace(":assessmentId", assessmentId.toString())
      .replace(":attemptId?", attemptId ? attemptId.toString() : "")

    const newTab = window.open(url, "_blank")
    function checkNewTab() {
      if (newTab && newTab.closed) {
        clearInterval(timer)
        setIsInExam(false)
        onTabClose()
      }
    }
    setIsInExam(true)
  }

  return { isInExam, openTestWindow }
}
