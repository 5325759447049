import TopNavigation from "components/Navigation/TopNavigation"
import React from "react"

import Profile from "."

const PublicProfile = () => {
  return (
    <>
      <TopNavigation />
      <Profile />
    </>
  )
}

export default PublicProfile
