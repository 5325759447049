import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  PaginatedAPIResponse,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  CheckoutOrder,
  InvoiceAttachment,
  Order,
  OrderItemDetails,
} from "./types"
import { urls } from "./urls"

export default {
  listOrders: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Order[]>> => {
    try {
      const res = await axios.get(urls.orders.base(), { params })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getOrderDetails: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<OrderItemDetails>> => {
    try {
      const res = await axios.get(urls.orders.detail(urlParams.id))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getInvoice: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<InvoiceAttachment>> => {
    try {
      const res = await axios.get(urls.orders.invoice(urlParams.transactionId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  checkout: async ({ data }: Config): Promise<APIResponse<CheckoutOrder>> => {
    try {
      const res = await axios.post(urls.orders.base(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
